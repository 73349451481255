import { configureStore } from '@reduxjs/toolkit';

import utilisateurReducer from '../redux/model/slice/UtilisateurReducer';
import authentificationReducer from '../redux/model/slice/AuthentificationReducer';
import messageReducer from '../redux/model/slice/MessageReducer';
import i18nReducer from '../redux/model/slice/i18nReducer';
import machinesReducer from '../redux/model/slice/MachinesReducer';
import secteursReducer from '../redux/model/slice/SecteurReducer';
import fonctionReducer from '../redux/model/slice/FonctionReducer';
import paysReducer from '../redux/model/slice/PaysReducer';
import formulaireReducer from '../redux/model/slice/FormulaireReducer';
import agressionsReducer from '../redux/model/slice/AgressionsReducer';
import childFormikPropsReducer from '../redux/model/slice/ChildFormikPropsReducer';
import espaceClientReducer from '../redux/model/slice/EspaceClientReducer';

/*https://github.com/reduxjs/redux/issues/1436#issuecomment-187646474
    We don’t encourage you to have multiple stores in Redux unless you 
    have very good reasons to separate them. Logical separation is not 
    such a reason: you should use reducer composition instead and keep 
    it as a single store with a single root reducer that calls other 
    reducers.
*/
const reducer = {
    i18n: i18nReducer,
    utilisateur: utilisateurReducer,
    authentification: authentificationReducer,
    message: messageReducer,
    machines: machinesReducer,
    secteurs: secteursReducer,
    fonctions: fonctionReducer,
    pays: paysReducer,
    formulaire: formulaireReducer, 
    agressions: agressionsReducer,
    childFormikProps: childFormikPropsReducer,
    reference: formulaireReducer,
    traductions: i18nReducer,
    espaceClient: espaceClientReducer,
}

const store = configureStore({
    reducer: reducer,
    devTools: true, //redux devTool extension
});

export default store;