import * as Yup from "yup";
import { controllerMenuStep } from "../../../formulaires/ControllerMenuStep";
import {
  ADRESSE_MAIL,
  CODE_POSTAL,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  FONCTION,
  NOM,
  PRENOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
} from "../../../../../constantes/symbols/SymbolsCommon";
const phoneRegExp = /^\+|0(?:[0-9] ?){6,14}[0-9]$/;

/* A Yup validation schema. */
export const validCoordonnees = (
  secteursList,
  fonctionsList,
  paysList,
  t,
  formulary,
  etape
) => {
  if (
    etape !==
    controllerMenuStep(
      formulary[FAMILLE_PROTECTION_INDUSTRIELLE.description],
      t
    ).length -
      1
  ) {
    return Yup.object().shape({});
  }

  return Yup.object().shape({
    [SOCIETE.description]: Yup.string().required(t.renseignerChamp),
    [CODE_POSTAL.description]: Yup.number().required(t.renseignerChamp),
    [NOM.description]: Yup.string().required(t.renseignerChamp),
    [PRENOM.description]: Yup.string().required(t.renseignerChamp),
    [ADRESSE_MAIL.description]: Yup.string()
      .email(t.emailNonValide)
      .required(t.renseignerChamp),
    [TELEPHONE.description]: Yup.string()
      .matches(phoneRegExp, t.numeroInvalide)
      .required(t.renseignerChamp),
    [SECTEUR.description]: Yup.mixed()
      .test(
        "SecteurActiviteInList",
        () => {
          return t.selectionSecteurActivite;
        },
        (selectedValue) =>
          selectedValue
            ? secteursList
                .map((value) => value.code)
                .includes(selectedValue.code)
            : false
      )
      .required(t.renseignerChamp),
    [FONCTION.description]: Yup.mixed().test(
      "FonctionInList",
      () => {
        return t.selectionFonction;
      },
      (selectedValue) => {
        if (selectedValue === null) {
          return true;
        }
        return selectedValue
          ? fonctionsList
              .map((value) => value.code)
              .includes(selectedValue.code)
          : false;
      }
    ),
    [PAYS.description]: Yup.mixed()
      .test(
        "PaysInList",
        () => {
          return t.selectionPays;
        },
        (selectedValue) =>
          selectedValue
            ? paysList.map((value) => value.code).includes(selectedValue.code)
            : false
      )
      .required(t.renseignerChamp),
  });
};
