import { 
    initChampsFormikToJsonDefaultArray, 
    initChampsFormikToJsonDefaultCustom, 
    initChampsFormikToJsonDefaultString 
} from "../../../../../helper/InitialisationChampsFormik";
import { 
    EXTREMITE_IDENTIQUE,
    TYPE_EXTREMITES_IT1,
    BRIDE_PERCEE_IT1,
    MANCHETTE_IT1,
    MANCHETTE_LARGEUR_IT1,
    BRIDE_IT1,
    MANCHETTE_DIAMETRE_IT1,
    MANCHETTE_A_IT1,
    MANCHETTE_B_IT1,
    BRIDE_DIAMETRE_IT1,
    BRIDE_A_IT1,
    BRIDE_B_IT1,
    JOINDRE_PLAN_BRIDE_PERCEE_IT1,
    FIXATION_EXTREMITES_IT1,
    TYPE_EXTREMITES_IT2,
    MANCHETTE_LARGEUR_IT2,
    MANCHETTE_IT2,
    MANCHETTE_DIAMETRE_IT2,
    MANCHETTE_A_IT2,
    MANCHETTE_B_IT2,
    BRIDE_IT2,
    BRIDE_DIAMETRE_IT2,
    BRIDE_A_IT2,
    BRIDE_B_IT2,
    BRIDE_PERCEE_IT2,
    JOINDRE_PLAN_BRIDE_PERCEE_IT2,
    FIXATION_EXTREMITES_IT2,
} from "../../../../../constantes/symbols/SymbolsS01";

const champsString = [
    TYPE_EXTREMITES_IT1,
    MANCHETTE_IT1,
    MANCHETTE_LARGEUR_IT1,
    MANCHETTE_DIAMETRE_IT1,
    MANCHETTE_A_IT1,
    MANCHETTE_B_IT1,
    BRIDE_IT1,
    BRIDE_DIAMETRE_IT1,
    BRIDE_A_IT1,
    BRIDE_B_IT1,
    FIXATION_EXTREMITES_IT1,
    TYPE_EXTREMITES_IT2,
    MANCHETTE_IT2,
    MANCHETTE_LARGEUR_IT2,
    MANCHETTE_DIAMETRE_IT2,
    MANCHETTE_A_IT2,
    MANCHETTE_B_IT2,
    BRIDE_IT2,
    BRIDE_DIAMETRE_IT2,
    BRIDE_A_IT2,
    BRIDE_B_IT2,
    FIXATION_EXTREMITES_IT2
];

const champsArray = [
    JOINDRE_PLAN_BRIDE_PERCEE_IT1,
    JOINDRE_PLAN_BRIDE_PERCEE_IT2
];

export const initialValuesDimensionsExtremites = (formulaire) => {
    return Object.assign({
        ...initChampsFormikToJsonDefaultString(champsString, formulaire),
        ...initChampsFormikToJsonDefaultArray(champsArray, formulaire),
        ...initChampsFormikToJsonDefaultCustom([EXTREMITE_IDENTIQUE], formulaire, 0),
        ...initChampsFormikToJsonDefaultCustom([BRIDE_PERCEE_IT1], formulaire, 0),
        ...initChampsFormikToJsonDefaultCustom([BRIDE_PERCEE_IT2], formulaire, 0),
    }, {});
}