export const TooltipEC = ({ texte, color }) => {
  return (
    <div
      style={{
        padding: 8,
        background: "white",
        borderRadius: 4,
        overflow: "hidden",
        border: color ? "1px " + color + " solid" : "1px #EC8812 solid",
        gap: 8,
        display: "inline-flex",
        marginBottom: "2rem",
      }}
    >
      <div style={{ display: "flex" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.2em"
          height="1.2em"
          viewBox="0 0 24 24"
        >
          <path
            fill={color ? color : "#EC8812"}
            d="M11 9h2V7h-2m1 13c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m-1 15h2v-6h-2z"
          />
        </svg>
      </div>
      <div
        style={{
          color: "#2A375C",
          fontSize: 16,
          fontFamily: "Cabin",
          fontWeight: "400",
        }}
      >
        {texte}
      </div>
    </div>
  );
};
