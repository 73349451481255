import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllSupportedLangs } from "../../model/slice/i18nReducer";
import { StyledSelectHeader } from "../styledComponents/StyledSelect";
import { useLocation } from "react-router-dom";
import { useTranslations } from "./hook/useTranslations";
import { StyledSpanHeader } from "../styledComponents/StyledSpan";
// ...
export const LangSwitcher = () => {
  //HOOKS
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const dispatch = useDispatch();
  const { lang, supportedLangs, setLang } = useTranslations();

  useEffect(() => {
    dispatch(getAllSupportedLangs());
  }, []);

  useEffect(() => {
    if (!supportedLangs || !query.get("langue")) {
      return;
    }
    let langParam = query.get("langue");
    urlLangHandler(langParam);
  }, [supportedLangs]);

  if (!supportedLangs) {
    return null;
  }

  const urlLangHandler = (langParam) => {
    if (langParam.toUpperCase() === "en".toUpperCase()) {
      langParam = "GB";
    }
    const value = supportedLangs.filter(
      (el) => el.code.toUpperCase() === langParam.toUpperCase()
    )[0]?.code;
    if (!value) {
      return;
    }
    setLang({ target: { value } });
  };

  return (
    <StyledSpanHeader>
      <StyledSelectHeader
        value={lang}
        onChange={(newLang) => setLang(newLang)}
        size="small"
      >
        {supportedLangs.map(({ code, name }) => (
          <MenuItem value={code} key={code}>
            <ListItemIcon style={{ minWidth: "30px" }}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
                alt={`Flag of ${code.label}`}
              />
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </MenuItem>
        ))}
      </StyledSelectHeader>
    </StyledSpanHeader>
  );
};
