import axios from 'axios';
import { Serveur } from '../../constantes/services/Serveur';


const getAllTraductions = async () => {
    
    const config = {
        headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    };

    return await axios.get(Serveur.URL_TRADUCTIONS, config);

}

const getAllTradInfoBulles = async () => {
    
    const config = {
        headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    };

    return await axios.get(Serveur.URL_TRADUCTIONS_INFOBULLES, config);

}

const traductionsService = {
    getAllTraductions,
    getAllTradInfoBulles,
}

export default traductionsService;

