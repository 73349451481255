export const anchorCheck = (errors) => {
    if (Object.keys(errors).length > 0 
        &&document.getElementsByName(Object.keys(errors)[0]).length > 0) {
        
        document.getElementsByName(Object.keys(errors)[0])[0].focus();
    }
}

export const anchorEtape = (titleToSelect) => {
    if(titleToSelect!==undefined
        &&document.getElementsByName(titleToSelect).length>0){
        document.getElementsByName(titleToSelect)[0].scrollIntoView();
    }
}