export const FormulaireActionType = Object.freeze({
  END_POINT: "formulaire",
  MERGE_ETAPE: "formulaire/mergeEtape",
  SEND_FORMULARY: "formulaire/sendFormulary",
  SEND_FORMULARY_CONTACT: "formulaire/sendFormularyContact",
  SEND_FORMULARY_RECHERCHER: "formulaire/sendFormularyRechercher",
  SEND_FORMULARY_REFERENCE: "formulaire/sendReference",
  GET_ONE_FORM_BY_REFERENCE: "formulaire/getOneFormByReference",
  SEND_FORMULARY_NULL: "formulaire/setAllInfoByRefNull",
  GET_ALL: "formulaire/getAllReference",
  RESET_IS_FORMULARY_SENT: "formulaire/resetIsFormularySent",
  DELETE_DDP_BY_REF: "formulaire/deleteDDPByRef",
  GET_ODP_BY_ID: "formulaire/SylobOffresDePrix",
  GET_ARTICLE_BY_ID_FPS: "formulaire/SylobCaracteristiquesArticlesFps",
  GET_ARTICLE_BY_ID_ROLE: "formulaire/SylobCaracteristiquesArticlesRole",
  FORCE_SEND_MAIL_AND_FILES_BY_DDP: "formulaire/forceSendMailAndFilesByDDP",
  TRIGGER_FORCE_SEND_MAIL_AND_FILES_BY_DDP: "formulaire/triggerForceSendMailAndFilesByDDP",
  SEND_FORMULARY_CONTACT_SOS: "formulaire/sendFormularyContactSOS",
});
