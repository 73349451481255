const OUTLINED = Symbol("outlined");
const TYPE_NUMBER = Symbol("number");
const CONTAINED = Symbol("contained");
const OUTLINED_PRIMARY_BUTTON_GROUP = Symbol("outlined primary button group");
const ROW = Symbol(5);

export {
    OUTLINED,
    TYPE_NUMBER,
    CONTAINED,
    OUTLINED_PRIMARY_BUTTON_GROUP,
    ROW
};