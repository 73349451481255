import {
  JOINDRE_PLAN_EXTREMITES_IT1,
  JOINDRE_PLAN_EXTREMITES_IT2,
  S04_EXTREMITE_IDENTIQUE,
  S04_FIXATION_EXTREMITES_IT1,
  S04_FIXATION_EXTREMITES_IT2,
} from "../../../../../constantes/symbols/SymbolsS04";
import {
  initChampsFormikToJsonDefaultArray,
  initChampsFormikToJsonDefaultCustom,
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";

const champsArray = [S04_FIXATION_EXTREMITES_IT1, S04_FIXATION_EXTREMITES_IT2, JOINDRE_PLAN_EXTREMITES_IT1, JOINDRE_PLAN_EXTREMITES_IT2];

export const initialValuesDimensionsExtremitesS04 = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultArray(champsArray, formulaire),
      ...initChampsFormikToJsonDefaultCustom(
        [S04_EXTREMITE_IDENTIQUE],
        formulaire,
        1
      ),
    },
    {}
  );
};
