import {
  AUTRE,
  AXE_EMPLACEMENT,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  NUMERO_DE_PLAN,
  POSITION_DE_TRAVAIL,
  TYPE_DE_MACHINE,
  VITESSE_DEPLACEMENT,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  arrayPositionTravailS01,
  arrayPositionTravailS04_5_8,
  arrayProductFamilly0_1_3,
  arrayProductFamilly2_4_5,
} from "../../../../../constantes/symbols/SymbolsServicesCommon";
import { getImageInformation } from "../../../../../helper/ImportAll";
import {
  BUTTON_GROUP,
  TEXT,
  TEXTFIELD,
  TEXTFIELD_NUMBER,
  TEXTFIELD_SEARCHBAR_CODE,
  TEXT_AND_TEXTFIELD_ALIGN,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";
import { CODE_ARTICLE } from "../../RechercherChamps";

const TITRE_POSITION_DE_TRAVAIL = Symbol("Position de travail : *");

export const formulaireInformationsMachine = (
  typeMachineList,
  marqueMachineList,
  styles,
  t,
  formik
) => [
  {
    nom: TYPE_DE_MACHINE,
    label: t.typeMachine,
    type: TEXTFIELD_SEARCHBAR_CODE,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
    donnees: typeMachineList,
    textInformation: {
      text: t.SQ0001_Q00030,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: MARQUE_MACHINE,
    label: t.marqueMachine,
    type: TEXTFIELD_SEARCHBAR_CODE,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
    donnees: marqueMachineList,
    textInformation: {
      text: t.SQ0001_Q00040,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: AUTRE,
    label: t.autreMarque,
    type: TEXT_AND_TEXTFIELD_ALIGN,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
  },
  {
    nom: MODELE_MACHINE,
    label: t.modeleMachine,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
    textInformation: {
      text: t.SQ0001_Q00051,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: AXE_EMPLACEMENT,
    label: t.axeEmplacement,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
    textInformation: {
      text: t.SQ0001_Q00061,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: TITRE_POSITION_DE_TRAVAIL,
    label: t.positionDeTravail + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormSansBg0Margin,
    textInformation: {
      text: t.SQ0001_Q00070,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: POSITION_DE_TRAVAIL,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormSansBg,
    donnees: selectOptionListPositionTravail(formik.values, t),
  },
  arrayProductFamilly2_4_5(t).includes(
    formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description]
  )
    ? {
        nom: VITESSE_DEPLACEMENT,
        label: t.vitesseDeplacement + t.asterisque,
        type: TEXTFIELD_NUMBER,
        cssTextfield: styles.cssTextfield,
        cssMarginFormSection: styles.sectionFormSansBg,
        textInformation: {
          text: t.SQ0001_Q00071,
          cssIcon: styles.helperTextIcon,
          // images: getImageInformation(),
        },
      }
    : null,
  {
    nom: NUMERO_DE_PLAN,
    label: t.numeroDePlan,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
    textInformation: {
      text: t.SQ0001_Q00090,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: CODE_ARTICLE,
    label: t.codeArticle,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
    textInformation: {
      text: t.REF_CLI,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

const selectOptionListPositionTravail = (formikValues, t) => {
  if (
    arrayProductFamilly2_4_5(t).includes(
      formikValues[FAMILLE_PROTECTION_INDUSTRIELLE.description]
    )
  ) {
    return arrayPositionTravailS04_5_8(t);
  }
  if (
    arrayProductFamilly0_1_3(t).includes(
      formikValues[FAMILLE_PROTECTION_INDUSTRIELLE.description]
    )
  ) {
    return arrayPositionTravailS01(t);
  }
};
