import { styled } from "@mui/system";

export const StyledHeaderCollaborateur = styled('header')({
    backgroundColor: '#2A375C',
    fontFamily: 'Dosis',
    color: 'white',
    fontSize: '2em',
    fontWeight: '500',
    borderRadius: '0',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.7em',
    '& :last-child': {
        justifyContent: 'space-around',
    },
    '@media (max-width: 576px)':{
        rowGap: '1.5em',
        fontSize: '1.5em',
    },
});

