import * as Yup from "yup";
import {
  LARGEUR_TOILE,
  L_MAX,
  MAXIMUM_ENROULE,
  SECTION_MAX_A,
  SECTION_MAX_B,
  SECTION_MAX_C,
  TYPE_ENROULEUR,
} from "../../../../../constantes/symbols/SymbolsS05";
import { S05_STEP4_arrayTypeEnrouleur } from "../../../../../constantes/symbols/SymbolsServicesS05";

const otherwiseSchema = Yup.mixed().optional();

/* A Yup validation schema. */
export const validDimensionsS05 = (t, etape) => {
  if (etape !== 5) {
    return Yup.object().shape({});
  }
  return Yup.object({
    [L_MAX.description]: Yup.number()
      .positive()
      .min(1, t.superieurA0)
      .required(t.renseignerChamp),

    [LARGEUR_TOILE.description]: Yup.number()
      .positive()
      .min(1, t.superieurA0)
      .required(t.renseignerChamp),

    [SECTION_MAX_A.description]: Yup.mixed().when(
      [TYPE_ENROULEUR.description].toString(),
      {
        is: (typeEnrouleur) =>
          parseInt(typeEnrouleur) === S05_STEP4_arrayTypeEnrouleur()[0].code,
        then: Yup.number().positive().min(1, t.superieurA0),
        otherwise: otherwiseSchema,
      }
    ),
    [SECTION_MAX_B.description]: Yup.mixed().when(
      [TYPE_ENROULEUR.description].toString(),
      {
        is: (typeEnrouleur) =>
          parseInt(typeEnrouleur) === S05_STEP4_arrayTypeEnrouleur()[0].code,
        then: Yup.number().positive().min(1, t.superieurA0),
        otherwise: otherwiseSchema,
      }
    ),
    // [SECTION_MAX_C.description]: Yup.mixed().when(
    //   [TYPE_ENROULEUR.description].toString(),
    //   {
    //     is: (typeEnrouleur) =>
    //       parseInt(typeEnrouleur) === S05_STEP4_arrayTypeEnrouleur()[0].code,
    //     then: Yup.number().positive().min(1, t.superieurA0),
    //     otherwise: otherwiseSchema,
    //   }
    // ),
    [MAXIMUM_ENROULE.description]: Yup.mixed().when(
      [TYPE_ENROULEUR.description].toString(),
      {
        is: (typeEnrouleur) =>
          parseInt(typeEnrouleur) !== S05_STEP4_arrayTypeEnrouleur()[0].code,
        then: Yup.number().positive().min(1, t.superieurA0),
        otherwise: otherwiseSchema,
      }
    ),
  });
};
