import { S08_ACCESSOIRES } from "../../../../../constantes/symbols/SymbolsS08";
import { arrayAccessoiresS08 } from "../../../../../constantes/symbols/SymbolsServicesS08";
import { importAll } from "../../../../../helper/ImportAll";
import {
  CHECKBOX_BOX_SHADOW,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";


export const formulaireAccessoiresS08 = (styles, t) => [
  {
    nom: S08_ACCESSOIRES,
    label: "",
    type: CHECKBOX_BOX_SHADOW,
    images: importAll(),
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.noSectionForm,
    cssSurroundGridItem: styles.cssSurroundGridItem,
    cssCheckbox: styles.cssCheckboxAccessoires,
    donnees: arrayAccessoiresS08(t, styles),
  },
];
