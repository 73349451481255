import * as Yup from "yup";
import {
  COTE_AGRESSE_TOILE,
  DIMENSIONS_EXTREMITE,
  FIXATION_CAISSON,
  MATIERE_EXTREMITE,
  POSITION_EXTREMITE,
  POSITION_FIXATION_CAISSON,
  TYPE_ENROULEUR,
} from "../../../../../constantes/symbols/SymbolsS05";
import {
  S05_STEP4_arrayTypeEnrouleur,
  S05_STEP6_arrayCoteAgresseToile,
  S05_STEP6_arrayDimensionExtremiteIt1,
  S05_STEP6_arrayDimensionExtremiteIt2,
  S05_STEP6_arrayMatiereExtremite,
  S05_STEP6_arrayPositionExtremite,
  S05_STEP6_arrayPositionFixationCaisson,
  S05_STEP6_arraySystemeFixationCaisson,
} from "../../../../../constantes/symbols/SymbolsServicesS05";

const otherwiseSchema = Yup.mixed().optional();

/* A Yup validation schema. */
export const validExtremitesS05 = (t, etape) => {
  if (etape !== 6) {
    return Yup.object().shape({});
  }
  return Yup.object({
    [MATIERE_EXTREMITE.description]: Yup.mixed().test(
      "matiereExtremite",
      () => {
        return t.erreurSelectionMatiere;
      },
      (value) =>
        S05_STEP6_arrayMatiereExtremite(t).some(
          (e) => e.code === parseInt(value)
        )
    ),

    [POSITION_EXTREMITE.description]: Yup.mixed().test(
      "positionExtremite",
      () => {
        return t.erreurSelectionPositionExtremite;
      },
      (selectedValue) =>
        S05_STEP6_arrayPositionExtremite(t).some(
          (e) => e.code === parseInt(selectedValue)
        )
    ),

    [DIMENSIONS_EXTREMITE.description]: Yup.mixed().when(
      [POSITION_EXTREMITE.description].toString(),
      {
        is: (position) => [0, 1, 2].includes(position),
        then: Yup.mixed().test(
          "fixationCaisson",
          () => t.erreurSelectionFixationCaisson,
          (selectedValue) =>
            S05_STEP6_arrayDimensionExtremiteIt1(t).some(
              (e) => e.code === parseInt(selectedValue)
            )
        ),
        otherwise: Yup.mixed().test(
          "fixationCaisson",
          () => t.erreurSelectionFixationCaisson,
          (selectedValue) =>
            S05_STEP6_arrayDimensionExtremiteIt2(t).some(
              (e) => e.code === parseInt(selectedValue)
            )
        ),
      }
    ),
    [COTE_AGRESSE_TOILE.description]: Yup.mixed().test(
      "coteAgresseToile",
      () => {
        return t.erreurSelectionCoteAgresse;
      },
      (selectedValue) =>
        S05_STEP6_arrayCoteAgresseToile(t).some(
          (e) => e.code === parseInt(selectedValue)
        )
    ),

    [FIXATION_CAISSON.description]: Yup.mixed().when(
      [TYPE_ENROULEUR.description].toString(),
      {
        is: (typeEnrouleur) =>
          parseInt(typeEnrouleur) === S05_STEP4_arrayTypeEnrouleur()[0].code,
        then: Yup.mixed()
          .required(t.erreurSelectionFixationCaisson)
          .test(
            "fixationCaisson",
            t.erreurSelectionFixationCaisson,
            (selectedValue) => {
              if (selectedValue === null || selectedValue === undefined) {
                return false; // champ requis
              }
              return S05_STEP6_arraySystemeFixationCaisson(t).some(
                (e) => e.code === parseInt(selectedValue)
              );
            }
          ),
        otherwise: Yup.mixed().required(t.erreurSelectionFixationCaisson),
      }
    ),

    [POSITION_FIXATION_CAISSON.description]: Yup.mixed().when(
      [TYPE_ENROULEUR.description].toString(),
      {
        is: (typeEnrouleur) =>
          parseInt(typeEnrouleur) === S05_STEP4_arrayTypeEnrouleur()[0].code,
        then: Yup.mixed()
          .required(t.erreurSelectionPositionCaisson)
          .test(
            "positionFixationCaisson",
            t.erreurSelectionPositionCaisson,
            (selectedValue) => {
              if (selectedValue === null || selectedValue === undefined) {
                return false; // champ requis
              }
              return S05_STEP6_arrayPositionFixationCaisson(t).some(
                (e) => e.code === parseInt(selectedValue)
              );
            }
          ),
        otherwise: Yup.mixed().required(t.erreurSelectionPositionCaisson), // toujours requis dans les autres cas
      }
    ),
  });
};
