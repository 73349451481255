import {
  CP,
  DEMANDE,
  EMAIL,
  FONCTION,
  NOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TEL,
} from "../enum/champs/ChampsCommunFormulairesEnum";
import {
  DROPZONE,
  TEXTFIELD,
  TEXTFIELD_ZONE,
  TEXTFIELD_SEARCHBAR,
} from "../components/generateFormulary/types/TypeFieldsFormulary";
import { PRENOM } from "../../../constantes/symbols/SymbolsCommon";

export const DROPZONE_CONTACT = Symbol("dropzone");

/**
 *
 * @param {*} paysList La liste des pays a afficher
 * @param {*} secteurList La liste des secteurs a afficher
 * @param {*} fonctionList La liste des fonctions a afficher
 * @returns Le tableau des champs à afficher pour la page RechercherChamps
 */
export const formulaireContact = (
  paysList,
  secteurList,
  fonctionList,
  styles,
  t
) => [
  {
    nom: DEMANDE,
    label: t.precisionCasEcheant,
    type: TEXTFIELD_ZONE,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: DROPZONE_CONTACT,
    label: t.ajouterFichier,
    type: DROPZONE,
    styles: styles,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: SOCIETE,
    label: t.titreSociete + t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: PAYS,
    label: t.titrePays + t.asterisque,
    type: TEXTFIELD_SEARCHBAR,
    donnees: paysList,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: CP,
    label: t.titreCodePostal + t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: SECTEUR,
    label: t.titreSecteurActiviteSansObli + t.asterisque,
    type: TEXTFIELD_SEARCHBAR,
    donnees: secteurList,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: NOM,
    label: t.titreNom + t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: PRENOM,
    label: t.titrePrenom + t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: FONCTION,
    label: t.titreFonctionSansObli,
    type: TEXTFIELD_SEARCHBAR,
    donnees: fonctionList,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: EMAIL,
    label: t.titreAdresseMail + t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: TEL,
    label: t.titreTelephone + t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
];
