import * as Yup from "yup";
import {
  S08_ANGLE_J,
  S08_ANGLE_K,
  S08_ANGLE_L,
  S08_LONGUEUR_A,
  S08_LONGUEUR_B,
  S08_LONGUEUR_C,
  S08_LONGUEUR_D,
  S08_LONGUEUR_E,
  S08_LONGUEUR_F,
  S08_LONGUEUR_G,
  S08_LONGUEUR_H,
  S08_LONGUEUR_I,
  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
  S08_SECTION_ENVELOPPE_PLAT,
  S08_SECTION_ENVELOPPE_S,
  S08_SECTION_ENVELOPPE_TOIT,
  S08_SECTION_ENVELOPPE_TOIT_PLAT,
  S08_TYPE_PENTE,
  S08_TYPE_SECTION_ENVELOPPE,
  S08_TYPE_TOIT,
} from "../../../../../constantes/symbols/SymbolsS08";
import {
  S08_STEP5_arrayShape_pente_C_descendante,
  S08_STEP5_arrayShape_pente_C_montante,
  S08_STEP5_arrayShape_plat_A,
  S08_STEP5_arrayShape_toitS_D,
  S08_STEP5_arrayShape_toit_B_toit,
  S08_STEP5_arrayShape_toit_B_toit_plat,
  S08_STEP5_arrayTypePente,
  S08_STEP5_arrayTypeSection,
  S08_STEP5_arrayTypeToit,
} from "../../../../../constantes/symbols/SymbolsServicesS08";

/* Setting the maximum size of a file to 10MB. */
const FILE_SIZE = 10000000;
/* A constant that contains the list of supported formats. */
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/bmp",
  "image/png",
];

const otherwiseSchema = Yup.mixed().optional();

const testPositiveAndMin = (t) => {
  return Yup.number()
    .positive()
    .min(0, t.superieurA0)
    .required(t.renseignerChamp);
};

const testBouttonGroupChecked = (t, typeSection, optionList, schema) => {
  return Yup.mixed().when([S08_TYPE_SECTION_ENVELOPPE.description].toString(), {
    is: (type) => parseInt(type) === typeSection,
    then: Yup.mixed()
      .test(
        "TypeSelectionInSelect",
        () => {
          return t.erreurSelectionType;
        },
        (value) => optionList.some((e) => e.code === parseInt(value))
      )
      .required(t.erreurSelectionType),
    otherwise: schema,
  });
};

const testBouttonGroupCheckedIt2 = (
  t,
  typeSection,
  typePente,
  formeSection,
  optionList1,
  optionList2,
  schema
) => {
  return Yup.mixed().when(
    [
      [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
      [typePente.description].toString(),
    ],
    {
      is: (section, pente) =>
        parseInt(section) === typeSection &&
        section === formeSection.description,
      then: Yup.mixed()
        .test(
          "TypeSelectionInSelect",
          () => {
            return t.erreurSelectionType;
          },
          (value) => optionList1.some((e) => e.code === parseInt(value))
        )
        .required(t.erreurSelectionType),
      otherwise: testBouttonGroupChecked(t, typeSection, optionList2, schema),
    }
  );
};

/* A Yup validation schema. */
export const validFormeS08 = (t, etape) => {
  if (etape !== 5) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [S08_TYPE_SECTION_ENVELOPPE.description]: Yup.mixed()
      .test(
        "TypeSelectionInSelect",
        () => {
          return t.erreurSelectionType;
        },
        (value) =>
          S08_STEP5_arrayTypeSection(t).some((e) => e.code === parseInt(value))
      )
      .required(t.erreurSelectionType),

    // Test A
    [S08_SECTION_ENVELOPPE_PLAT.description]: testBouttonGroupChecked(
      t,
      S08_STEP5_arrayTypeSection(t)[0].code,
      S08_STEP5_arrayShape_plat_A(t),
      otherwiseSchema
    ),

    //Test B toit
    [S08_TYPE_TOIT.description]: testBouttonGroupChecked(
      t, 
      S08_STEP5_arrayTypeSection(t)[1].code,
      S08_STEP5_arrayTypeToit(t), 
      otherwiseSchema),
      
    [S08_SECTION_ENVELOPPE_TOIT.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_TYPE_TOIT.description].toString(),
      ],
      {
        is: (section, toit) =>
          parseInt(section) === S08_STEP5_arrayTypeSection(t)[1].code &&
          parseInt(toit) === S08_STEP5_arrayTypeToit(t)[0].code,
        then: Yup.mixed()
          .test(
            "TypeSelectionInSelect",
            () => {
              return t.erreurSelectionType;
            },
            (value) =>
              S08_STEP5_arrayShape_toit_B_toit(t).some(
                (e) => e.code === parseInt(value)
              )
          )
          .required(t.erreurSelectionType),
        otherwise: otherwiseSchema,
      }
    ),
    //Test B toit plat
    [S08_SECTION_ENVELOPPE_TOIT.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_TYPE_TOIT.description].toString(),
      ],
      {
        is: (section, toit) =>
          parseInt(section) === S08_STEP5_arrayTypeSection(t)[1].code &&
          parseInt(toit) === S08_STEP5_arrayTypeToit(t)[1].code,
        then: Yup.mixed()
          .test(
            "TypeSelectionInSelect",
            () => {
              return t.erreurSelectionType;
            },
            (value) =>
              S08_STEP5_arrayShape_toit_B_toit_plat(t).some(
                (e) => e.code === parseInt(value)
              )
          )
          .required(t.erreurSelectionType),
        otherwise: otherwiseSchema,
      }
    ),
    
    //Type pente
    [S08_TYPE_PENTE.description]: testBouttonGroupChecked(
      t, 
      S08_STEP5_arrayTypeSection(t)[2].code,
      S08_STEP5_arrayTypePente(t), 
      otherwiseSchema),

    //Test C pente descendante
    [S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_TYPE_PENTE.description].toString(),
      ],
      {
        is: (section, pente) =>
          parseInt(section) === S08_STEP5_arrayTypeSection(t)[2].code &&
          parseInt(pente) === S08_STEP5_arrayTypePente(t)[0].code,
        then: Yup.mixed()
          .test(
            "TypeSelectionInSelect",
            () => {
              return t.erreurSelectionType;
            },
            (value) =>
              S08_STEP5_arrayShape_pente_C_descendante(t).some(
                (e) => e.code === parseInt(value)
              )
          )
          .required(t.erreurSelectionType),
        otherwise: otherwiseSchema,
      }
    ),

    //Test C pente montante
    [S08_SECTION_ENVELOPPE_PENTE_MONTANTE]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_TYPE_PENTE.description].toString(),
      ],
      {
        is: (section, pente) =>
          parseInt(section) === S08_STEP5_arrayTypeSection(t)[2].code &&
          parseInt(pente) === S08_STEP5_arrayTypePente(t)[1].code,
        then: Yup.mixed()
          .test(
            "TypeSelectionInSelect",
            () => {
              return t.erreurSelectionType;
            },
            (value) =>
              S08_STEP5_arrayShape_pente_C_montante(t).some(
                (e) => e.code === parseInt(value)
              )
          )
          .required(t.erreurSelectionType),
        otherwise: otherwiseSchema,
      }
    ),

    //Test D
    [S08_SECTION_ENVELOPPE_S.description]: Yup.mixed().when(
      [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
      {
        is: (type) => parseInt(type) === S08_STEP5_arrayTypeSection(t)[3].code,
        then: Yup.mixed()
          .test(
            "ToileSelectionInSelect",
            () => {
              return t.erreurSelectionType;
            },
            (value) =>
            S08_STEP5_arrayShape_toitS_D(t).some((e) => e.code === parseInt(value))
          )
          .required(t.erreurSelectionType),
        otherwise: otherwiseSchema,
      }
    ),

    [S08_SECTION_ENVELOPPE_TOIT.description]: Yup.mixed().when(
      [S08_TYPE_TOIT.description].toString(),
      {
        is: (typeToit) => parseInt(typeToit) === S08_STEP5_arrayTypeToit(t)[0].code,
        then: Yup.mixed()
          .test(
            "ToileSelectionInSelect",
            () => {
              return t.erreurSelectionType;
            },
            (value) =>
            S08_STEP5_arrayShape_toit_B_toit(t).some((e) => e.code === parseInt(value))
          )
          .required(t.erreurSelectionType),
        otherwise: otherwiseSchema,
      }
    ),

    [S08_SECTION_ENVELOPPE_TOIT_PLAT.description]: Yup.mixed().when(
      [S08_TYPE_TOIT.description].toString(),
      {
        is: (typeToit) => parseInt(typeToit) === S08_STEP5_arrayTypeToit(t)[1].code,
        then: Yup.mixed()
        .test(
          "ToileSelectionInSelect",
          () => {
            return t.erreurSelectionType;
          },
          (value) =>
          S08_STEP5_arrayShape_toit_B_toit_plat(t).some((e) => e.code === parseInt(value))
        )
        .required(t.erreurSelectionType),
        otherwise: otherwiseSchema,
      }
    ),

    [S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description]: Yup.mixed().when(
      [S08_TYPE_PENTE.description].toString(),
      {
        is: (typePente) => parseInt(typePente) === S08_STEP5_arrayTypePente(t)[0].code,
        then: Yup.mixed()
          .test(
            "ToileSelectionInSelect",
            () => {
              return t.erreurSelectionType;
            },
            (value) =>
            S08_STEP5_arrayShape_pente_C_descendante(t).some((e) => e.code === parseInt(value))
          )
          .required(t.erreurSelectionType),
        otherwise: otherwiseSchema,
      }
    ),

    [S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description]: Yup.mixed().when(
      [S08_TYPE_PENTE.description].toString(),
      {
        is: (typePente) => parseInt(typePente) === S08_STEP5_arrayTypePente(t)[1].code,
        then: Yup.mixed()
          .test(
            "ToileSelectionInSelect",
            () => {
              return t.erreurSelectionType;
            },
            (value) =>
            S08_STEP5_arrayShape_pente_C_montante(t).some((e) => e.code === parseInt(value))
          )
          .required(t.erreurSelectionType),
        otherwise: otherwiseSchema,
      }
    ),

    [S08_LONGUEUR_A.description]: Yup.mixed().when(
      [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
      {
        is: (type) =>
          [
            S08_STEP5_arrayTypeSection(t)[0].code,
            S08_STEP5_arrayTypeSection(t)[1].code,
            S08_STEP5_arrayTypeSection(t)[2].code,
            S08_STEP5_arrayTypeSection(t)[3].code,
          ].includes(parseInt(type)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [S08_LONGUEUR_B.description]: Yup.mixed().when(
      [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
      {
        is: (type) =>
          [
            S08_STEP5_arrayTypeSection(t)[0].code,
            S08_STEP5_arrayTypeSection(t)[1].code,
            S08_STEP5_arrayTypeSection(t)[2].code,
            S08_STEP5_arrayTypeSection(t)[3].code,
          ].includes(parseInt(type)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [S08_LONGUEUR_C.description]: Yup.mixed().when(
      [[S08_TYPE_SECTION_ENVELOPPE.description].toString()],
      {
        is: (type) =>
          [
            S08_STEP5_arrayTypeSection(t)[0].code,
            S08_STEP5_arrayTypeSection(t)[1].code,
            S08_STEP5_arrayTypeSection(t)[2].code,
            S08_STEP5_arrayTypeSection(t)[3].code,
          ].includes(parseInt(type)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [S08_LONGUEUR_D.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_SECTION_ENVELOPPE_PLAT.description].toString(),
      ],
      {
        is: (type, section) =>
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[0].code &&
            [
              S08_STEP5_arrayShape_plat_A(t)[1].code,
              S08_STEP5_arrayShape_plat_A(t)[2].code,
            ].includes(parseInt(section))) ||
          [
            S08_STEP5_arrayTypeSection(t)[1].code,
            S08_STEP5_arrayTypeSection(t)[2].code,
            S08_STEP5_arrayTypeSection(t)[3].code,
          ].includes(parseInt(type)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [S08_LONGUEUR_E.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_SECTION_ENVELOPPE_PLAT.description].toString(),
        [S08_SECTION_ENVELOPPE_TOIT.description].toString(),
        [S08_TYPE_TOIT.description].toString(),
      ],
      {
        is: (type, sectionPlat, sectionToit, toit) =>
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[0].code &&
            [
              S08_STEP5_arrayShape_plat_A(t)[1].code,
              S08_STEP5_arrayShape_plat_A(t)[2].code,
            ].includes(parseInt(sectionPlat))) ||
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[1].code &&
            S08_STEP5_arrayTypeToit(t)[0].code === parseInt(toit) &&
            [
              S08_STEP5_arrayShape_toit_B_toit(t)[1].code,
              S08_STEP5_arrayShape_toit_B_toit(t)[2].code,
            ].includes(parseInt(sectionToit))) ||
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[1].code &&
            S08_STEP5_arrayTypeToit(t)[1].code === parseInt(toit)) ||
          parseInt(type) === S08_STEP5_arrayTypeSection(t)[2].code ||
          parseInt(type) === S08_STEP5_arrayTypeSection(t)[3].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [S08_LONGUEUR_F.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_SECTION_ENVELOPPE_TOIT.description].toString(),
        [S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description].toString(),
        [S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description].toString(),
        [S08_TYPE_TOIT.description].toString(),
        [S08_TYPE_PENTE.description].toString(),
      ],
      {
        is: (type, sectionToit, sectionPenteDescendante, sectionPenteMontante, toit, pente) =>
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[1].code &&
            S08_STEP5_arrayTypeToit(t)[0].code === parseInt(toit) &&
            [
              S08_STEP5_arrayShape_toit_B_toit(t)[1].code,
              S08_STEP5_arrayShape_toit_B_toit(t)[2].code,
            ].includes(parseInt(sectionToit))) ||
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[1].code &&
            S08_STEP5_arrayTypeToit(t)[1].code === parseInt(toit)) ||
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[2].code &&
            S08_STEP5_arrayTypePente(t)[0].code === parseInt(pente) &&
            [
              S08_STEP5_arrayShape_pente_C_descendante(t)[1].code,
              S08_STEP5_arrayShape_pente_C_descendante(t)[2].code,
            ].includes(parseInt(sectionPenteDescendante))) ||
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[2].code &&
            S08_STEP5_arrayTypePente(t)[1].code === parseInt(pente) &&
            [
              S08_STEP5_arrayShape_pente_C_montante(t)[1].code,
              S08_STEP5_arrayShape_pente_C_montante(t)[2].code,
            ].includes(parseInt(sectionPenteMontante))) ||
          parseInt(type) === S08_STEP5_arrayTypeSection(t)[3].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [S08_LONGUEUR_G.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_SECTION_ENVELOPPE_TOIT.description].toString(),
        [S08_SECTION_ENVELOPPE_TOIT_PLAT.description].toString(),
        [S08_TYPE_TOIT.description].toString(),
      ],
      {
        is: (type, sectionToit, sectionToitPlat, toit) =>
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[1].code &&
            S08_STEP5_arrayTypeToit(t)[0].code === parseInt(toit) &&
            [
              S08_STEP5_arrayShape_toit_B_toit(t)[1].code,
              S08_STEP5_arrayShape_toit_B_toit(t)[2].code,
            ].includes(parseInt(sectionToit))) ||
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[1].code &&
            S08_STEP5_arrayTypeToit(t)[1].code === parseInt(toit) &&
            [
              S08_STEP5_arrayShape_toit_B_toit_plat(t)[1].code,
              S08_STEP5_arrayShape_toit_B_toit_plat(t)[2].code,
            ].includes(parseInt(sectionToitPlat))) ||
          parseInt(type) === S08_STEP5_arrayTypeSection(t)[3].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [S08_LONGUEUR_H.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_SECTION_ENVELOPPE_TOIT.description].toString(),
        [S08_SECTION_ENVELOPPE_TOIT_PLAT.description].toString(),
        [S08_SECTION_ENVELOPPE_S.description].toString(),
        [S08_TYPE_TOIT.description].toString(),
        [S08_TYPE_PENTE.description].toString(),
      ],
      {
        is: (type, sectionToit, sectionToitPlat, sectionS, toit, pente) =>
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[1].code &&
            S08_STEP5_arrayTypeToit(t)[0].code === parseInt(toit) &&
            [
              S08_STEP5_arrayShape_toit_B_toit(t)[1].code,
              S08_STEP5_arrayShape_toit_B_toit(t)[2].code,
            ].includes(parseInt(sectionToit))) ||
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[1].code &&
            S08_STEP5_arrayTypeToit(t)[1].code === parseInt(toit) &&
            [
              S08_STEP5_arrayShape_toit_B_toit_plat(t)[1].code,
              S08_STEP5_arrayShape_toit_B_toit_plat(t)[2].code,
            ].includes(parseInt(sectionToitPlat))) ||
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[3].code &&
            [
              S08_STEP5_arrayShape_toitS_D(t)[2].code,
              S08_STEP5_arrayShape_toitS_D(t)[3].code,
            ].includes(parseInt(sectionS))),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [S08_LONGUEUR_I.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_SECTION_ENVELOPPE_TOIT_PLAT.description].toString(),
        [S08_SECTION_ENVELOPPE_S.description].toString(),
        [S08_TYPE_TOIT.description].toString(),
      ],
      {
        is: (type, sectionToitPlat, sectionS, toit) =>
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[1].code &&
            S08_STEP5_arrayTypeToit(t)[1].code === parseInt(toit) &&
            [
              S08_STEP5_arrayShape_toit_B_toit_plat(t)[1].code,
              S08_STEP5_arrayShape_toit_B_toit_plat(t)[2].code,
            ].includes(parseInt(sectionToitPlat))) ||
          (parseInt(type) === S08_STEP5_arrayTypeSection(t)[3].code &&
            [
              S08_STEP5_arrayShape_toitS_D(t)[2].code,
              S08_STEP5_arrayShape_toitS_D(t)[3].code,
            ].includes(parseInt(sectionS))),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [S08_ANGLE_J.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_SECTION_ENVELOPPE_S.description].toString(),
      ],
      {
        is: (type, sectionS) =>
          parseInt(type) === S08_STEP5_arrayTypeSection(t)[3].code &&
          [
            S08_STEP5_arrayShape_toitS_D(t)[2].code,
            S08_STEP5_arrayShape_toitS_D(t)[3].code,
          ].includes(parseInt(sectionS)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [S08_ANGLE_K.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_SECTION_ENVELOPPE_S.description].toString(),
      ],
      {
        is: (type, sectionS) =>
          parseInt(type) === S08_STEP5_arrayTypeSection(t)[3].code &&
          [
            S08_STEP5_arrayShape_toitS_D(t)[2].code,
            S08_STEP5_arrayShape_toitS_D(t)[3].code,
          ].includes(parseInt(sectionS)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [S08_ANGLE_L.description]: Yup.mixed().when(
      [
        [S08_TYPE_SECTION_ENVELOPPE.description].toString(),
        [S08_SECTION_ENVELOPPE_S.description].toString(),
      ],
      {
        is: (type, sectionS) =>
          parseInt(type) === S08_STEP5_arrayTypeSection(t)[3].code &&
          [
            S08_STEP5_arrayShape_toitS_D(t)[2].code,
            S08_STEP5_arrayShape_toitS_D(t)[3].code,
          ].includes(parseInt(sectionS)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    //     [JOINDRE_PLAN_FORME_QUELCONQUE.description]: Yup
    //     .mixed()
    //     .when(
    //         [TYPE_SECTION.description],
    //         {
    //             is: (type) => type===FormeQuelconque.description,
    //             then: Yup
    //                 .mixed()
    //                 .test(
    //                     "fileSize",
    //                     (fileList) => {
    //                         return t.erreurFichierVolumineux
    //                             + getNameFilesTooBig(fileList.originalValue).map((file) => {
    //                                 return file.name+"\n";
    //                             });
    //                     },
    //                     (fileList) => {
    //                         return getNameFilesTooBig(fileList).length===0;
    //                 })
    //                 .test(
    //                     "fileCount",
    //                     (fileList) => {
    //                         return t.erreurFichierMaxAtteint
    //                             + fileList.length;
    //                     },
    //                     (fileList) => {
    //                         return fileList.length<=10;
    //                     }
    //                 )
    //                 .test(
    //                     'fileFormat',
    //                     (fileList) => {
    //                         return t.erreurFormatFichier
    //                             + getNameFileWrongFormat(fileList.originalValue).map((file) => {
    //                                 return file.name+'\n';
    //                             })
    //                     },
    //                     (fileList) => {
    //                         return getNameFileWrongFormat(fileList).length===0;
    //                 })
    //                 /*.test(
    //                     'fileCountZero',
    //                     'Merci de fournir un plan dans la dropbox ci-dessus.',
    //                     (fileList) => !fileList.length===0
    //                 )*/,
    //             otherwise: otherwiseSchema,
    //         })
  });
};

/**
 * Given a list of files, return a list of files that are too big
 * @param listFiles - the list of files to be filtered
 * @returns An array of objects.
 */
const getNameFilesTooBig = (listFiles) => {
  return listFiles.filter((file) => file.size > FILE_SIZE);
};

/**
 * Given a list of files, return a list of files that are not of a supported format
 * @param listFiles - The list of files to be filtered.
 * @returns An array of files that are not supported.
 */
const getNameFileWrongFormat = (listFiles) => {
  return listFiles.filter((file) => !SUPPORTED_FORMATS.includes(file.type));
};
