import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultLang } from "../../../config/i18nConfig";
import { TraductionsActionType } from "../../../constantes/actionType/TraductionsActionType";
import { errorHandlerMessageProvider } from "../../../helper/ErrorHandlerMessageProvider";
import traductionsService from "../../service/TraductionsService";
import i18nService from "../../service/i18nService";
import { langForService } from "../../../helper/ServiceCleaner";

export const setLangAsync = createAsyncThunk(
  "i18n/setLangAsync",
  async (lang, thunkAPI) => {
    let resolvedLang = langForService(lang, thunkAPI);
    const translationsFromLocal = await i18nService.fetchTranslations("FR");
    const translationsFromService =
      await traductionsService.getAllTraductions();
    const translationsInfoBulleFromService =
      await traductionsService.getAllTradInfoBulles();
    if (
      [translationsFromService, translationsInfoBulleFromService].includes(
        undefined
      )
    ) {
      return translationsFromLocal;
    }

    const serviceInfoBullesListBaseLang =
      translationsInfoBulleFromService.data.reduce(
        (obj, item) => Object.assign(obj, { [item["Code site"]]: item["FR"] }),
        {}
      );

    const serviceTranslationsListBaseLang = translationsFromService.data.reduce(
      (obj, item) => Object.assign(obj, { [item["Code site"]]: item["FR"] }),
      {}
    );

    const serviceInfoBullesListResolvedLang =
      translationsInfoBulleFromService.data.reduce(
        (obj, item) =>
          Object.assign(obj, { [item["Code site"]]: item[resolvedLang] }),
        {}
      );

    const serviceTranslationsListResolvedLang =
      translationsFromService.data.reduce(
        (obj, item) =>
          Object.assign(obj, { [item["Code site"]]: item[resolvedLang] }),
        {}
      );

    const textTranslationsListBaseLang = Object.assign(
      {},
      serviceTranslationsListBaseLang,
      serviceInfoBullesListBaseLang
    );

    const textTranslationsListResolvedLang = Object.assign(
      {},
      serviceTranslationsListResolvedLang,
      serviceInfoBullesListResolvedLang
    );

    //On fusionne les langues de base (FR)
    const textTranslationsListBaseLangAndLocal = Object.fromEntries(
      Object.entries(translationsFromLocal).map((el) =>
        textTranslationsListBaseLang[el[0]] !== "" &&
        textTranslationsListBaseLang[el[0]] !== undefined
          ? Object.entries(textTranslationsListBaseLang).find(
              (obj) => obj[0] === el[0]
            )
          : el
      )
    );

    const textTranslationsListMerge = {
      ...textTranslationsListResolvedLang,
      ...textTranslationsListBaseLangAndLocal,
    };
    //On fusionne la nouvelle langue
    const textTranslationsList = Object.fromEntries(
      Object.entries(textTranslationsListMerge).map((el) => {
        return textTranslationsListResolvedLang[el[0]] !== "" &&
          textTranslationsListResolvedLang[el[0]] !== undefined
          ? Object.entries(textTranslationsListResolvedLang).find(
              (obj) => obj[0] === el[0]
            )
          : el;
      })
    );

    if (resolvedLang === "EN") {
      resolvedLang = "GB";
    }
    thunkAPI.dispatch(i18nReducer.actions.setLang(resolvedLang));
    return textTranslationsList;
  }
);

export const getAllTraductions = createAsyncThunk(
  TraductionsActionType.GET_ALL_TRADUCTIONS,
  async (thunkAPI) => {
    try {
      const reponse = await traductionsService.getAllTraductions();
      return reponse.data;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllSupportedLangs = createAsyncThunk(
  TraductionsActionType.GET_ALL_SUPPORTED_LANGS,
  async (thunkAPI) => {
    try {
      const translationsFromService =
        await traductionsService.getAllTraductions();
      if ([translationsFromService].includes(undefined)) {
        return [{ code: "FR", name: "Français" }];
      }
      const languageNameList = translationsFromService.data.find((obj) => {
        return obj["Code site"] === "languageName" ? obj : null;
      });
      const existingCodeLang = Object.entries(languageNameList)
        .filter((el) => el[0].length === 2)
        .map((el) => {
          return { code: el[0], name: el[1] };
        });
      const fieldsDeterminingLangOk = translationsFromService.data.filter(
        (obj) =>
          [
            "souffletEnduit",
            "souffletPlisse",
            "enrouleur",
            "protectionTelescopique",
            "compensateur",
          ].includes(obj["Code site"])
      );
      const langReadyToDisplay = existingCodeLang
        .filter(
          (el) =>
            fieldsDeterminingLangOk.filter((obj) => obj[el.code]).length === 5
        )
        .map((el) => {
          if (el.code === "EN") {
            return { code: "GB", name: el.name };
          }
          return el;
        });
      return langReadyToDisplay;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const initialState = {
  status: "loading",
  lang: defaultLang, // "fr" when app loads
  translations: {},
};

export const i18nReducer = createSlice({
  name: "i18n",
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLangAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(setLangAsync.fulfilled, (state, action) => {
        state.translations = action.payload;
        state.status = "idle";
      })
      .addCase(getAllTraductions.fulfilled, (state, action) => {
        state.traductionsList = action.payload.map((element) => {
          return {
            code: element["Code site"],
            name: element["FR"],
          };
        });
      })
      .addCase(getAllTraductions.rejected, (state, action) => {
        state.traductionsList = [];
      })
      .addCase(getAllSupportedLangs.fulfilled, (state, action) => {
        state.supportedLangs = action.payload;
      })
      .addCase(getAllSupportedLangs.rejected, (state, action) => {
        state.supportedLangs = [];
      });
  },
});
export const { actions } = i18nReducer.actions;
export default i18nReducer.reducer;
