import { FormLabel } from "@mui/material";
import { styled } from "@mui/system";

export const StyledFormLabelQuestion = styled(FormLabel)({
    '&.MuiFormLabel-root':{
        fontSize: '1.125em',
        fontWeight: '600',
        color: '#2A375C',
        // padding: '0.7em 2em 0.5em 0',
        '&.Mui-focused': {
            color: '#2A375C',
        },
        '@media (max-width: 600px)':{
            padding: '0.7em 0 0.5em 0',
        },
    },
});