import { useNavigate } from "react-router-dom";

import { GuestRoutes } from "../../../../constantes/routes/GuestRoutes";

import { Grid } from "@mui/material";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
  StyledDivPhotoAccueil,
  StyledDivPhotoFamille,
  StyledDivPhotoFirstStep,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";

import { formulaireFamilleProtectionIndustrielle } from "../../ChampsFormulaires/S01/etape1/ChampsFamilleProtectionIndustrielle";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import { importAllSpec } from "../../../../helper/ImportAll";

export const StepFamilly = ({
  state: {
    booleanIdentite,
    formik,
    formulary,
    styles,
    styles: { cssFontFamily, cssButton, cssButtonPrec, activeStepTitle },
    t,
  },
  action: { setFormulary, setMaxReachedEtape, setFamilleProt },
  nom,
}) => {
  const navigate = useNavigate();

  const handleRetour = () => {
    navigate(GuestRoutes.ACCUEIL);
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodySelectionnezLaFamille}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(
              formulaireFamilleProtectionIndustrielle(styles, t),
              formik,
              t,
              booleanIdentite,
              false,
              { formulary, setFormulary, setMaxReachedEtape, setFamilleProt }
            )}
            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  className={cssButton}
                  variant="contained"
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivPhotoFamille
          style={{
            backgroundImage:
              "url(" +
              importAllSpec(booleanIdentite)["LandingPageImage.jpg"] +
              ")",
          }}
        />
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
