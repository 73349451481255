import { equalsCodeOrLabel } from "../../../../../constantes/symbols/HelperSymbolsServices";
import {
  FAMILLE_PROTECTION_INDUSTRIELLE,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  NUMERO_DE_PLAN,
  POSITION_DE_TRAVAIL,
  TYPE_DE_MACHINE,
  VITESSE_DEPLACEMENT,
  ENVIRONNEMENT,
  COMMENTAIRE,
  QUANTITEE_SOUHAITEE,
  ADRESSE_MAIL,
  CODE_POSTAL,
  FONCTION,
  NOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
  AXE_EMPLACEMENT,
  PRENOM,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  COTE_AGRESSE_TOILE,
  DIMENSIONS_EXTREMITE,
  FIXATION_CAISSON,
  LARGEUR_TOILE,
  L_MAX,
  MATIERE_EXTREMITE,
  MATIERE_TOILE,
  MAXIMUM_ENROULE,
  POSITION_EXTREMITE,
  POSITION_FIXATION_CAISSON,
  SECTION_MAX,
  TYPE_ENROULEUR,
} from "../../../../../constantes/symbols/SymbolsS05";
import { S05_STEP4_arrayTypeEnrouleur } from "../../../../../constantes/symbols/SymbolsServicesS05";
import { RECAP_S05 } from "../../../components/generateFormulary/types/TypeFieldsFormulary";
import { CODE_ARTICLE } from "../../RechercherChamps";
import {
  FICHIERS,
  FICHIER_RECAPITULATIF,
} from "../../S01/recapitulatif/ChampsRecapitulatif";

export const formulaireRecapitulatifTypeProtectionS05 = (t) => [
  {
    nom: FAMILLE_PROTECTION_INDUSTRIELLE,
    label: t.champTypeDeProtection,
    type: RECAP_S05,
  },
];

export const formulaireConstructionS05 = (t) => [
  {
    nom: TYPE_ENROULEUR,
    label: t.titreEnrouleur,
    type: RECAP_S05,
  },
  {
    nom: MATIERE_TOILE,
    label: t.champToile,
    type: RECAP_S05,
  },
];

export const formulaireDimensions = (t, formulaire) => [
  {
    nom: L_MAX,
    label: t.champLongueurDeployee,
    type: RECAP_S05,
  },
  {
    nom: LARGEUR_TOILE,
    label: t.champLargeurToile,
    type: RECAP_S05,
  },
  equalsCodeOrLabel(
    formulaire[TYPE_ENROULEUR.description],
    S05_STEP4_arrayTypeEnrouleur(),
    0
  )
    ? {
        nom: SECTION_MAX,
        label: t.sectionMax,
        type: RECAP_S05,
      }
    : {
        nom: MAXIMUM_ENROULE,
        label: t.champMaxEnroule,
        type: RECAP_S05,
      },
];

export const formulaireExtremite = (t, formulaire) => [
  {
    nom: MATIERE_EXTREMITE,
    label: t.champMatiereExtremite,
    type: RECAP_S05,
  },
  {
    nom: DIMENSIONS_EXTREMITE,
    label: t.champDimensionExtremite,
    type: RECAP_S05,
  },
  {
    nom: POSITION_EXTREMITE,
    label: t.champPositionnementExtremite,
    type: RECAP_S05,
  },
  equalsCodeOrLabel(
    formulaire[TYPE_ENROULEUR.description],
    S05_STEP4_arrayTypeEnrouleur(),
    0
  )
    ? {
        nom: FIXATION_CAISSON,
        label: t.champSystemeFixationExtremite,
        type: RECAP_S05,
      }
    : null,
  equalsCodeOrLabel(
    formulaire[TYPE_ENROULEUR.description],
    S05_STEP4_arrayTypeEnrouleur(),
    0
  )
    ? {
        nom: POSITION_FIXATION_CAISSON,
        label: t.champPositionFixationExtremite,
        type: RECAP_S05,
      }
    : null,
  {
    nom: COTE_AGRESSE_TOILE,
    label: t.champCoteAgresseToile,
    type: RECAP_S05,
  },
];

export const formulaireInformationsMachineS05 = (
  t,
  serviceTypeMachine,
  serviceMarqueMachine
) => [
  {
    nom: TYPE_DE_MACHINE,
    label: t.champTypeDeMachine,
    type: RECAP_S05,
    service: serviceTypeMachine,
  },
  {
    nom: MARQUE_MACHINE,
    label: t.champMarqueDeMachine,
    type: RECAP_S05,
    service: serviceMarqueMachine,
  },
  {
    nom: MODELE_MACHINE,
    label: t.champModeleDeMachine,
    type: RECAP_S05,
  },
  {
    nom: AXE_EMPLACEMENT,
    label: t.champAxeEmplacement,
    type: RECAP_S05,
  },
  {
    nom: POSITION_DE_TRAVAIL,
    label: t.champPositionDeTravail,
    type: RECAP_S05,
  },
  {
    nom: VITESSE_DEPLACEMENT,
    label: t.champVitesseDeplacement,
    type: RECAP_S05,
  },
  {
    nom: NUMERO_DE_PLAN,
    label: t.champNDePlan,
    type: RECAP_S05,
  },
  {
    nom: CODE_ARTICLE,
    label: t.champCodeArticle,
    type: RECAP_S05,
  },
];

export const formulaireAggressionsS05 = (t, agressionsList, formulaire) => [
  {
    nom: ENVIRONNEMENT,
    label: t.champEnvironnement,
    type: RECAP_S05,
    nomChamps: agressionsList,
  },
];

export const formulaireQuantitésS05 = () => [
  {
    nom: QUANTITEE_SOUHAITEE,
    type: RECAP_S05,
  },
];

export const formulaireCoordonneesS05 = (
  t,
  upToDatePaysList,
  upToDateSecteursList,
  upToDateFonctionsList
) => [
  {
    nom: SOCIETE,
    label: t.champSociete,
    type: RECAP_S05,
  },
  {
    nom: PAYS,
    label: t.champPays,
    type: RECAP_S05,
    service: upToDatePaysList,
  },
  {
    nom: CODE_POSTAL,
    label: t.champCodePostal,
    type: RECAP_S05,
  },
  {
    nom: SECTEUR,
    label: t.champSecteurActivite,
    type: RECAP_S05,
    service: upToDateSecteursList,
  },
  {
    nom: NOM,
    label: t.champNom,
    type: RECAP_S05,
  },
  {
    nom: PRENOM,
    label: t.champPrenom,
    type: RECAP_S05,
  },
  {
    nom: FONCTION,
    label: t.champFonction,
    type: RECAP_S05,
    service: upToDateFonctionsList,
  },
  {
    nom: ADRESSE_MAIL,
    label: t.champAdresseMail,
    type: RECAP_S05,
  },
  {
    nom: TELEPHONE,
    label: t.champTelephone,
    type: RECAP_S05,
  },
];

export const formulaireCommentaireS05 = (t, booleanIdentite) => [
  {
    nom: COMMENTAIRE,
    label: t.champCommentaire,
    type: RECAP_S05,
  },
];

export const formulaireFichiersS05 = () => [
  {
    nom: FICHIERS,
    type: RECAP_S05,
  },
];

export const formulaireFichiersRecapitulatif = () => [
  {
    nom: FICHIER_RECAPITULATIF,
    type: RECAP_S05,
  },
];
