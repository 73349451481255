import InfoIcon from "@mui/icons-material/Info";
import React, { useEffect, useState } from "react";
import {
  ClickAwayListener,
  Grid,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { StyledDivInformationIconHtml } from "../styledComponents/StyledDiv";
import Fade from "@mui/material/Fade";
import { ThemeTooltipInformationIcon } from "../styledComponents/StyledTooltip";

const HelperText = ({ name, textInformation: { text, cssIcon, images }, gridStyle, md }) => {
  const [htmlContent, setHtmlContent] = useState("");
  const [openByOver, setOpenByOver] = useState(false);
  const [openByClick, setOpenByClick] = useState("");

  const handleTooltipCloseByOver = () => {
    setOpenByOver(false);
  };

  const handleTooltipOpenByOver = () => {
    setOpenByOver(true);
  };

  const handleTooltipOpenByClick = () => {
    setOpenByClick(name);
    setOpenByOver(false);
  };

  const handleTooltipCloseByClick = () => {
    setOpenByClick(null);
    setOpenByOver(false);
  };

  useEffect(() => {
    // (?<=^).*?(?={{) debut
    // /(?<=}}).*?(?={{)/gm entre
    // (?:.(?<!}}))*$ fin
    // /(?<=^).*?(?={{)|(?<=}}).*?(?={{)|(?:.(?<!}}))*$/gm sans nom images
    // /((?<=^).*?(?={{)|(?<=}}).*?(?={{)|(?:.(?<!}}))*$)|(?<={{).*?(?=}})/gm total 
    const textWithoutImageToReplace = text.split(
      /(?:{{)+|(?:}})+/gm
    );

    const textToDisplay = textWithoutImageToReplace
      .filter((element) => element && element !==' ')
      .map((element) => {
        if (images && images[element] !== undefined) {
          return '<img src="' + images[element] + '"/>';
        }
        return element;
      })
      .join("");

    setHtmlContent(textToDisplay);
  }, [text, images]);

  return (
    <Grid item style={gridStyle?gridStyle:null} md={md?md:12}>
      <ThemeProvider theme={ThemeTooltipInformationIcon}>
        <ClickAwayListener onClickAway={handleTooltipCloseByClick}>
          <Tooltip
            arrow
            placement="bottom"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            onClose={handleTooltipCloseByOver}
            open={openByOver || openByClick === name}
            title={
              <StyledDivInformationIconHtml
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              />
            }
          >
            <div
              onClick={handleTooltipOpenByClick}
              onMouseOver={handleTooltipOpenByOver}
            >
              <InfoIcon className={cssIcon} style={{color:"#C0C0C0"}}/>
            </div>
          </Tooltip>
        </ClickAwayListener>
      </ThemeProvider>
    </Grid>
  );
};

//How to use mui tooltip ?

export { HelperText };
