import { usedForDisplay } from "./HelperSymbolsServices";

//Etape 4
const S04_STEP4_arrayTypeCorps = (t) => [
  {
    code: 1,
    label: "Forme A",
    ...usedForDisplay(t, { name: t?.typeCorpsA }),
  },
  {
    code: 2,
    label: "Forme B (fermé)",
    ...usedForDisplay(t, { name: t?.typeCorpsB }),
  },
  {
    code: 3,
    label: "Forme C (en L)",
    ...usedForDisplay(t, { name: t?.typeCorpsC }),
  },
  {
    code: 4,
    label: "Forme D (en U)",
    ...usedForDisplay(t, { name: t?.typeCorpsD }),
  },
  {
    code: 5,
    label: "Forme E (pente)",
    ...usedForDisplay(t, { name: t?.typeCorpsE }),
  },
  {
    code: 6,
    label: "Forme F (toit)",
    ...usedForDisplay(t, { name: t?.typeCorpsF }),
  },
  {
    code: 14,
    label: "Forme quelconque",
    ...usedForDisplay(t, { name: t?.quelconque }),
  },
];

const S04_STEP4_arrayFormeCorpsSymA = (t) => [
  {
    code: 1,
    label: "A1S",
    ...usedForDisplay(t, { name: t?.formeA1S }),
  },
  {
    code: 2,
    label: "A2S",
    ...usedForDisplay(t, { name: t?.formeA2S }),
  },
  {
    code: 3,
    label: "A3S",
    ...usedForDisplay(t, { name: t?.formeA3S }),
  },
];

const S04_STEP4_arrayFormeCorpsSymB = (t) => [
  {
    code: 1,
    label: "B1S",
    ...usedForDisplay(t, { name: t?.formeB1S }),
  },
];

const S04_STEP4_arrayFormeCorpsSymC = (t) => [
  {
    code: 1,
    label: "C1S",
    ...usedForDisplay(t, { name: t?.formeC1S }),
  },
];

const S04_STEP4_arrayFormeCorpsSymD = (t) => [
  {
    code: 1,
    label: "D1S",
    ...usedForDisplay(t, { name: t?.formeD1S }),
  },
  {
    code: 3,
    label: "D3S",
    ...usedForDisplay(t, { name: t?.formeD3S }),
  },
];

const S04_STEP4_arrayFormeCorpsD = (t) => [
  {
    code: 1,
    label: "D1",
    ...usedForDisplay(t, { name: t?.shapeFormeD1 }),
  },
  {
    code: 2,
    label: "D2",
    ...usedForDisplay(t, { name: t?.shapeFormeD2}),
  },
  {
    code: 3,
    label: "D3",
    ...usedForDisplay(t, { name: t?.shapeFormeD3}),
  },
];

const S04_STEP4_arrayFormeCorpsSymE = (t) => [
  {
    code: 1,
    label: "E1S",
    ...usedForDisplay(t, { name: t?.formeE1S }),
  },
  {
    code: 2,
    label: "E2S",
    ...usedForDisplay(t, { name: t?.formeE2S }),
  },
];

const S04_STEP4_arrayFormeCorpsE = (t) => [
  {
    code: 1,
    label: "E1",
    ...usedForDisplay(t, { name: t?.formeE1 }),
  },
  {
    code: 2,
    label: "E2",
    ...usedForDisplay(t, { name: t?.formeE2 }),
  },
];

const S04_STEP4_arrayFormeCorpsSymF = (t) => [
  {
    code: 1,
    label: "F1S",
    ...usedForDisplay(t, { name: t?.formeF1S}),
  },
  {
    code: 2,
    label: "F2S",
    ...usedForDisplay(t, { name: t?.formeF2S }),
  },
];

const S04_STEP4_arrayFormeCorpsF = (t) => [
  {
    code: 1,
    label: "F1",
    ...usedForDisplay(t, { name: t?.formeF1 }),
  },
  {
    code: 2,
    label: "F2",
    ...usedForDisplay(t, { name: t?.formeF2 }),
  },
];

const S04_STEP4_arrayToile = (t) => [
  {
    code: 99,
    label: "Non renseignée",
    ...usedForDisplay(t, { name: t?.matiereNonRenseignee }),
  },
  {
    code: 0,
    label: "KEVLAR (TKVB ou PUR/Kevlar/PUR)",
    ...usedForDisplay(t, { name: t?.tkvb }),
  },
  {
    code: 1,
    label: "TKVB",
    ...usedForDisplay(t, { name: t?.tkva }),
  },
  {
    code: 2,
    label: "PU 0.21 (PUR/PES/PUR 0.21 mm)",
    ...usedForDisplay(t, { name: t?.pu1 }),
  },
  {
    code: 3,
    label: "PU 0.34 (PUR/PES/PUR 0.34 mm)",
    ...usedForDisplay(t, { name: t?.pu2 }),
  },
  {
    code: 4,
    label: "PVC noir (0.5 mm)",
    ...usedForDisplay(t, { name: t?.pvc }),
  },
];

//Etape 6
const S04_STEP6_arrayFixationExtremites = (t) => [
  {
    code: 0,
    label: "Aucune",
    ...usedForDisplay(t, { name: t?.aucune }),
  },
  {
    code: 1,
    label: "Double face",
    ...usedForDisplay(t, { name: t?.doubleFace }),
  },
  {
    code: 2,
    label: "Velcro",
    ...usedForDisplay(t, { name: t?.velcro }),
  },
  {
    code: 3,
    label: "Dual lock",
    ...usedForDisplay(t, { name: t?.dualLock }),
  },
  {
    code: 5,
    label: "Pressions",
    ...usedForDisplay(t, { name: t?.pressions }),
  },
  {
    code: 6,
    label: "Bride",
    ...usedForDisplay(t, { name: t?.bride }),
  },
  {
    code: 7,
    label: "Caisson",
    ...usedForDisplay(t, { name: t?.caisson }),
  },
  {
    code: 4,
    label: "Vis (trous lisses)",
    ...usedForDisplay(t, { name: t?.visTrousLisses }),
  },
  {
    code: 8,
    label: "Vis (taraudages)",
    ...usedForDisplay(t, { name: t?.visTaraudages }),
  },
];

//Etape7
const S04_STEP7_arrayAccessories = (t) => [
  {
    code: 0,
    label: "Oui",
    ...usedForDisplay(t, { name: t?.ouiMajuscule }),
  },
  {
    code: 1,
    label: "Non",
    ...usedForDisplay(t, { name: t?.nonMajuscule }),
  },
  /*{
    code: 9,
    label: "Ne sait pas",
    name: "Ne sait pas",
  },*/
];

const S04_STEP7_arrayA = (t) => [
  {
    code:"A",
    ...usedForDisplay(t, { name: t?.ecaillesA })
  }
];
const S04_STEP7_arrayB = (t) => [
  {
    code:"B",
    ...usedForDisplay(t, { name: t?.ecaillesB })
  }
];
const S04_STEP7_arrayC = (t) => [
  {
    code:"C",
    ...usedForDisplay(t, { name: t?.ecaillesC })
  }
];
const S04_STEP7_arrayD = (t) => [
  {
    code:"D",
    ...usedForDisplay(t, { name: t?.ecaillesD })
  }
];
const S04_STEP7_arrayE = (t) => [
  {
    code:"E",
    ...usedForDisplay(t, { name: t?.ecaillesE })
  }
];
const S04_STEP7_arrayF = (t) => [
  {
    code:"F",
    ...usedForDisplay(t, { name: t?.ecaillesF })
  }
];

const S04_STEP7_arrayAPrime = (t) => [
  {
    code:"A'",
    ...usedForDisplay(t, { name: t?.ecaillesAprime }),
  }
];
const S04_STEP7_arrayBPrime = (t) => [
  {
    code:"B'",
    ...usedForDisplay(t, { name: t?.ecaillesBprime }),
  }
];

const S04_STEP7_arrayEcaillesA2S = (t) => [...S04_STEP7_arrayA(t)];

const S04_STEP7_arrayEcaillesA3S = (t) => [...S04_STEP7_arrayA(t)];

const S04_STEP7_arrayEcaillesB1S = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayAPrime(t),
  ...S04_STEP7_arrayBPrime(t),
];

const S04_STEP7_arrayEcaillesC1S = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
];

const S04_STEP7_arrayEcaillesD1 = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayC(t),
];

const S04_STEP7_arrayEcaillesD2 = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayC(t),
  ...S04_STEP7_arrayD(t),
];

const S04_STEP7_arrayEcaillesD3 = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayC(t),
  ...S04_STEP7_arrayD(t),
  ...S04_STEP7_arrayE(t),
];

const S04_STEP7_arrayEcaillesD1S = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayAPrime(t),
];

const S04_STEP7_arrayEcaillesD3S = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayC(t),
  ...S04_STEP7_arrayAPrime(t),
  ...S04_STEP7_arrayBPrime(t),
];

const S04_STEP7_arrayEcaillesE1 = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayC(t),
];

const S04_STEP7_arrayEcaillesE2 = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayC(t),
  ...S04_STEP7_arrayD(t),
  ...S04_STEP7_arrayE(t),
];

const S04_STEP7_arrayEcaillesE1S = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayC(t),
];

const S04_STEP7_arrayEcaillesE2S = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayC(t),
  ...S04_STEP7_arrayD(t),
  ...S04_STEP7_arrayE(t),
];

const S04_STEP7_arrayEcaillesF1 = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayD(t),
  ...S04_STEP7_arrayE(t),
];

const S04_STEP7_arrayEcaillesF2 = (t) => [
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayE(t),
  ...S04_STEP7_arrayF(t),
];

const S04_STEP7_arrayEcaillesF1S = (t) => [
  ...S04_STEP7_arrayA(t),
  ...S04_STEP7_arrayC(t),
  ...S04_STEP7_arrayAPrime(t),
];

const S04_STEP7_arrayEcaillesF2S = (t) => [
  ...S04_STEP7_arrayB(t),
  ...S04_STEP7_arrayD(t),
  ...S04_STEP7_arrayBPrime(t),
];

export {
  //Etape 4
  S04_STEP4_arrayTypeCorps,
  S04_STEP4_arrayFormeCorpsSymA,
  S04_STEP4_arrayFormeCorpsSymB,
  S04_STEP4_arrayFormeCorpsSymC,
  S04_STEP4_arrayFormeCorpsSymD,
  S04_STEP4_arrayFormeCorpsD,
  S04_STEP4_arrayFormeCorpsSymE,
  S04_STEP4_arrayFormeCorpsE,
  S04_STEP4_arrayFormeCorpsSymF,
  S04_STEP4_arrayFormeCorpsF,
  S04_STEP4_arrayToile,
  //Etape 6
  S04_STEP6_arrayFixationExtremites,
  //Etape 7
  S04_STEP7_arrayAccessories,
  S04_STEP7_arrayEcaillesA2S,
  S04_STEP7_arrayEcaillesA3S,
  S04_STEP7_arrayEcaillesB1S,
  S04_STEP7_arrayEcaillesC1S,
  S04_STEP7_arrayEcaillesD1,
  S04_STEP7_arrayEcaillesD2,
  S04_STEP7_arrayEcaillesD3,
  S04_STEP7_arrayEcaillesD1S,
  S04_STEP7_arrayEcaillesD3S,
  S04_STEP7_arrayEcaillesE1,
  S04_STEP7_arrayEcaillesE2,
  S04_STEP7_arrayEcaillesE1S,
  S04_STEP7_arrayEcaillesE2S,
  S04_STEP7_arrayEcaillesF1,
  S04_STEP7_arrayEcaillesF2,
  S04_STEP7_arrayEcaillesF1S,
  S04_STEP7_arrayEcaillesF2S,
  S04_STEP7_arrayA,
  S04_STEP7_arrayB,
  S04_STEP7_arrayC,
  S04_STEP7_arrayD,
  S04_STEP7_arrayE,
  S04_STEP7_arrayF,
  S04_STEP7_arrayAPrime,
  S04_STEP7_arrayBPrime,
};
