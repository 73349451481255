import { CONFIGURATEUR, CONTACT, RECHERCHER, SOS } from "../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { StyledCircularProgress } from "../styledComponents/StyledIcon";
import { StyledPaperBody, StyledPaperConfirmation } from "../styledComponents/StyledPaper";
import { genererFormulaire } from "./generateFormulary/CasesGeneration";
import { TEXT } from "./generateFormulary/types/TypeFieldsFormulary";

const TITRE_WAITING = Symbol("titreWaiting");

const afficherTitreMessageWaiting = (from, t) => {
  if (from === CONFIGURATEUR) {
    return t.waitingMessageDdp;
  }
  if (from === RECHERCHER || from === CONTACT || from === SOS) {
    return t.waitingMessage;
  }
  return "!!!ERROR:WaitingDbInsert!!!";
};

const messageWaiting = (t, styles, from) => [
    {
        nom: TITRE_WAITING,
        label: afficherTitreMessageWaiting(from, t),
        type: TEXT,
        csslabelQuestionConfirmation: styles.labelQuestionConfirmation,
        cssMarginFormSection: styles.noSectionForm,
    },
];

export const WaitingDbInsert = ({state:{t, styles}, from}) => {
    const logoWaiting = styles.logoWaiting;
    return (
        <StyledPaperBody elevation={0}>
            <StyledPaperConfirmation elevation={0}>
                    <div>
                        <div>
                            {genererFormulaire(messageWaiting(t, styles, from))}
                        </div>
                        <StyledCircularProgress className={logoWaiting} size='1em'/>
                    </div>
            </StyledPaperConfirmation>
        </StyledPaperBody>
    );
}
