import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Pagination } from "@mui/material";
import {
  StyledGridLignesTabRoseEC,
} from "../../../styledComponents/StyledGridBackoffice";
import { StyledDivPagination } from "../../../styledComponents/StyledDivBackoffice";
import { CommandeByArtItem } from "./CommandeByArtItem";

export const ListeCommandeByArticle = ({ actions, state }) => {
  const [searchBarContentCodeOffre, setSearchBarContentCodeOffre] = useState();
  const [searchBarContentNumeroLigne, setSearchBarContentNumeroLigne] =
    useState();
  const [searchBarContentMarque, setSearchBarContentMarque] = useState();
  const [searchBarContentQuantite, setSearchBarContentQuantite] = useState();
  const [searchBarContentPrixUnitaire, setSearchBarContentPrixUnitaire] =
    useState();
  const [searchBarContentTotalHT, setSearchBarContentTotalHT] = useState();
  const [searchBarContentLivrPrev, setSearchBarContentLivrPrev] = useState();
  const [searchBarContentStatut, setSearchBarContentStatut] = useState();
  const [searchBarContentCodeCommande, setSearchBarContentCodeCommande] =
    useState();
  const [searchBarContentDesignation, setSearchBarContentDesignation] =
    useState();
  const [searchBarContentFamilleCode, setSearchBarContentFamilleCode] =
    useState();
  const [searchBarContentEtatLivraison, setSearchBarContentEtatLivraison] =
    useState();
  const [searchBarContentQuantiteCde, setSearchBarContentQuantiteCde] =
    useState();

  const [sortedCodeOffre, setSortedCodeOffre] = useState();
  const [sortedNumeroLigne, setSortedNumeroLigne] = useState();
  const [sortedMarque, setSortedMarque] = useState();
  const [sortedQuantite, setSortedQuantite] = useState();
  const [sortedTotalHT, setSortedTotalHT] = useState();
  const [sortedPrixUnitaire, setSortedPrixUnitaire] = useState();
  const [sortedLivrPrev, setSortedLivrPrev] = useState();
  const [sortedStatut, setSortedStatut] = useState();
  const [sortedCodeCommande, setSortedCodeCommande] = useState();
  const [sortedDesignation, setSortedDesignation] = useState();
  const [sortedFamilleCode, setSortedFamilleCode] = useState();
  const [sortedEtatLivraison, setSortedEtatLivraison] = useState();
  const [sortedQuantiteCde, setSortedQuantiteCde] = useState();

  const { commandesByArtIdList } = useSelector((state) => state.espaceClient);
  const [filteredList, setFilteredList] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  const defineFilteredList = (searchBarContent, key) => {
    if (!commandesByArtIdList) {
      return;
    }
    return commandesByArtIdList.filter((item) => {
      if (searchBarContent === undefined) {
        return item;
      } else {
        const itemValue = item[key];
        if (Array.isArray(itemValue)) {
          // Vérifiez si l'une des valeurs du tableau correspond au contenu de la barre de recherche
          return itemValue.some((value) =>
            value
              .toString()
              .toLowerCase()
              .includes(searchBarContent.toLowerCase())
          )
            ? item
            : null;
        } else {
          // Vérifiez directement la valeur
          return itemValue
            .toString()
            .toLowerCase()
            .includes(searchBarContent.toLowerCase())
            ? item
            : null;
        }
      }
    });
  };

  const defineSortedList = (sortedDownOrUp, key) => {
    if (sortedDownOrUp === "up") {
      setFilteredList(
        [...commandesByArtIdList].sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    }
    if (sortedDownOrUp === "down") {
      setFilteredList(
        [...commandesByArtIdList].sort((a, b) => (b[key] > a[key] ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentCodeOffre, "offreCode"));
  }, [searchBarContentCodeOffre, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentNumeroLigne, "ligne"));
  }, [searchBarContentNumeroLigne, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentMarque, "marque"));
  }, [searchBarContentMarque, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentQuantite, "quantiteLivr")
    );
  }, [searchBarContentQuantite, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentTotalHT, "totalHT"));
  }, [searchBarContentTotalHT, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentLivrPrev, "livraisonPrevue")
    );
  }, [searchBarContentLivrPrev, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentPrixUnitaire, "prixUnitaireHT")
    );
  }, [searchBarContentPrixUnitaire, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentCodeCommande, "commandeCode")
    );
  }, [searchBarContentCodeCommande, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentDesignation, "designation")
    );
  }, [searchBarContentDesignation, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentFamilleCode, "familleCode")
    );
  }, [searchBarContentFamilleCode, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentEtatLivraison, "etatLivraison")
    );
  }, [searchBarContentEtatLivraison, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList(sortedNumeroLigne, "ligne");
  }, [sortedNumeroLigne, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList(sortedMarque, "marque");
  }, [sortedMarque, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList(sortedQuantite, "quantiteLivr");
  }, [sortedQuantite, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList(sortedTotalHT, "totalHT");
  }, [sortedTotalHT, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList(sortedPrixUnitaire, "prixUnitaire");
  }, [sortedPrixUnitaire, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    if (sortedLivrPrev === "up") {
      setFilteredList(
        [...commandesByArtIdList].sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )
      );
    }
    if (sortedLivrPrev === "down") {
      setFilteredList(
        [...commandesByArtIdList].sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        )
      );
    }
  }, [sortedLivrPrev, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList(sortedCodeCommande, "commandeCode");
  }, [sortedCodeCommande, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList(sortedDesignation, "designation");
  }, [sortedDesignation, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList(sortedFamilleCode, "familleCode");
  }, [sortedFamilleCode, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList(sortedEtatLivraison, "etatLivraison");
  }, [sortedEtatLivraison, commandesByArtIdList]);

  useEffect(() => {
    if (!commandesByArtIdList) {
      return;
    }
    defineSortedList("up", "ligne");
  }, [commandesByArtIdList]);

  useEffect(() => {
    if (!filteredList) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const handlePageClick = (event, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const afficherListeCommandeByArt = pagination.currentData.map((item) => {
    return (
      <CommandeByArtItem
        key={item.ligneCommandeId}
        id={item.ligneCommandeId}
        state={state}
        {...item}
        searchBarAction={{
          setSearchBarContentCodeOffre,
          setSearchBarContentNumeroLigne,
          setSearchBarContentMarque,
          setSearchBarContentQuantite,
          setSearchBarContentTotalHT,
          setSearchBarContentPrixUnitaire,
          setSearchBarContentLivrPrev,
          setSearchBarContentStatut,
          setSearchBarContentCodeCommande,
          setSearchBarContentDesignation,
          setSearchBarContentFamilleCode,
          setSearchBarContentEtatLivraison,
          setSearchBarContentQuantiteCde,
        }}
        sortAction={{
          setSortedCodeOffre,
          setSortedNumeroLigne,
          setSortedMarque,
          setSortedQuantite,
          setSortedTotalHT,
          setSortedPrixUnitaire,
          setSortedLivrPrev,
          setSortedStatut,
          setSortedCodeCommande,
          setSortedDesignation,
          setSortedFamilleCode,
          setSortedEtatLivraison,
          setSortedQuantiteCde,
        }}
        modale={actions}
      />
    );
  });

  return (
    <div>
      <StyledGridLignesTabRoseEC
        container
        style={{
          boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        }}
      >
        <CommandeByArtItem
          key={0}
          id={0}
          offreCode={state.t.titleTabCodeOffre}
          ligne={state.t.titleTabLigne}
          marque={state.t.titleTabMarque}
          quantiteLivr={state.t.titleTabQuantiteLivr}
          quantiteCde={state.t.titleTabQuantiteCde}
          prixUnitaireHT={state.t.titleTabPrixUnitaireHT}
          totalHT={state.t.titleTabTotalHT}
          livraisonPrevue={state.t.titleTabLivrPrev}
          commandeCode={state.t.titleTabCodeCommande}
          designation={state.t.titleTabDesignation}
          familleCode={state.t.titleTabFamille}
          etatLivraison={state.t.titleTabLivraison}
          commandeId={"commandeId"}
          state={state}
          searchBarAction={{
            setSearchBarContentCodeOffre,
            setSearchBarContentNumeroLigne,
            setSearchBarContentMarque,
            setSearchBarContentQuantite,
            setSearchBarContentTotalHT,
            setSearchBarContentPrixUnitaire,
            setSearchBarContentLivrPrev,
            setSearchBarContentCodeCommande,
            setSearchBarContentDesignation,
            setSearchBarContentFamilleCode,
            setSearchBarContentEtatLivraison,
            setSearchBarContentQuantiteCde,
          }}
          sortAction={{
            setSortedCodeOffre,
            setSortedNumeroLigne,
            setSortedMarque,
            setSortedQuantite,
            setSortedTotalHT,
            setSortedPrixUnitaire,
            setSortedLivrPrev,
            setSortedCodeCommande,
            setSortedDesignation,
            setSortedEtatLivraison,
            setSortedFamilleCode,
            setSortedQuantiteCde,
          }}
          modale={actions}
        />
        {!commandesByArtIdList || commandesByArtIdList.length === 0 ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1rem",
            }}
          >
            <Grid container item direction="row" className={"row"}>
              {state.t.aucuneCommande}
            </Grid>
          </div>
        ) : (
          afficherListeCommandeByArt
        )}
      </StyledGridLignesTabRoseEC>
      <StyledDivPagination>
        <Pagination
          size="large"
          count={pagination.pageCount}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={0}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPagination>
    </div>
  );
};
