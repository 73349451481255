import { Grid } from "@mui/material";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
  StyledDivSubtitleExtremites,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";

import { DessinTechnique } from "../../components/DessinTechnique";
import { importAllSpec } from "../../../../helper/ImportAll";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import {
  formulaireDimensionsExtremitesBassesS06,
  formulaireFixationExtremitesIt1,
  formulaireFixationExtremitesIt2,
  formulaireSectionDeLaBrideIt1,
  formulaireSectionDeLaBrideIt2,
  formulaireSectionDeLaManchetteIt1,
  formulaireSectionDeLaManchetteIt2,
} from "../../ChampsFormulaires/S04_06/etape6/ChampsDimensionsExtremitesS06";
import {
  ALIGNEMENT_IT1,
  ALIGNEMENT_IT2,
  S06_EXTREMITE_IDENTIQUE,
  S06_TYPE_EXTREMITES_IT1,
  S06_TYPE_EXTREMITES_IT2,
  S06_LONGUEUR_A_EXT,
  S06_LONGUEUR_A_INT,
  S06_LONGUEUR_B_EXT,
  S06_LONGUEUR_B_INT,
  S06_MANCHETTE_A_IT1,
  S06_MANCHETTE_A_IT2,
  S06_MANCHETTE_B_IT1,
  S06_MANCHETTE_B_IT2,
} from "../../../../constantes/symbols/SymbolsS06";
import { S06_STEP6_arrayAlignement, S06_STEP6_arrayTypeExtremites } from "../../../../constantes/symbols/SymbolsServicesS06";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../constantes/symbols/HelperSymbolsServices";
import { useEffect } from "react";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";

export const StepS06BodyEnds = ({
  state: {
    booleanIdentite,
    etape,
    styles,
    styles: {
      subtitleExtremites,
      cssFontFamily,
      cssButton,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    formik,
  },
  action: { handleRetour },
  nom,
}) => {
  const lang = useTranslations();

  const alignementIt1 = formik.values[ALIGNEMENT_IT1.description];
  const alignementIt2 = formik.values[ALIGNEMENT_IT2.description];

  useEffect(() => {
    let longueurManchetteAInt = 0;
    let longueurManchetteBInt = 0;

    if (alignementIt1 === S06_STEP6_arrayAlignement(t)[0].code) {
      longueurManchetteAInt = formik.values[S06_LONGUEUR_A_INT.description];
      longueurManchetteBInt = formik.values[S06_LONGUEUR_B_INT.description];
    } else {
      longueurManchetteAInt = formik.values[S06_LONGUEUR_A_EXT.description];
      longueurManchetteBInt = formik.values[S06_LONGUEUR_B_EXT.description];
    }
  
    formik.setFieldValue(S06_MANCHETTE_A_IT1.description, longueurManchetteAInt);
    formik.setFieldValue(S06_MANCHETTE_B_IT1.description, longueurManchetteBInt);
  }, [alignementIt1]);

  useEffect(() => {
    let longueurManchetteAInt = 0;
    let longueurManchetteBInt = 0;

    if (alignementIt2 === S06_STEP6_arrayAlignement(t)[0].code) {
      longueurManchetteAInt = formik.values[S06_LONGUEUR_A_INT.description];
      longueurManchetteBInt = formik.values[S06_LONGUEUR_B_INT.description];
    } else {
      longueurManchetteAInt = formik.values[S06_LONGUEUR_A_EXT.description];
      longueurManchetteBInt = formik.values[S06_LONGUEUR_B_EXT.description];
    }

    formik.setFieldValue(S06_MANCHETTE_A_IT2.description, longueurManchetteAInt);
    formik.setFieldValue(S06_MANCHETTE_B_IT2.description, longueurManchetteBInt);
  }, [alignementIt2]);

  const afficherLesChampsExtremiteBasse = () => {
    switch (
      equalsBooleanOrStringOrNumber(
        formik.values[S06_EXTREMITE_IDENTIQUE.description]
      )
    ) {
      case 1:
        return (
          <>
            {genererFormulaire(
              formulaireFixationExtremitesIt1(styles, t),
              formik
            )}
            {afficherFormulaireExtremiteIdentiqueIt1()}
          </>
        );
      case 0:
        return (
          <>
            <StyledDivSubtitleExtremites className={subtitleExtremites}>
              {t.extremiteHaute}
            </StyledDivSubtitleExtremites>
            {genererFormulaire(
              formulaireFixationExtremitesIt2(styles, t),
              formik
            )}
            {afficherFormulaireExtremiteIdentiqueIt2()}
            <StyledDivSubtitleExtremites className={subtitleExtremites}>
              {t.extremiteBasse}
            </StyledDivSubtitleExtremites>
            {genererFormulaire(
              formulaireFixationExtremitesIt1(styles, t),
              formik
            )}
            {afficherFormulaireExtremiteIdentiqueIt1()}
          </>
        );
      default:
        return null;
    }
  };

  const afficherFormulaireExtremiteIdentiqueIt1 = () => {
    const typeExtremiteIt1 =
      formik.values[S06_TYPE_EXTREMITES_IT1.description];
    if (
      equalsCodeOrLabel(
        parseInt(typeExtremiteIt1),
        S06_STEP6_arrayTypeExtremites(),
        0
      )
    ) {
      return genererFormulaire(
        formulaireSectionDeLaManchetteIt1(styles, t, formik),
        formik
      );
    } else if (
      equalsCodeOrLabel(
        parseInt(typeExtremiteIt1),
        S06_STEP6_arrayTypeExtremites(),
        1
      )
    ) {
      return genererFormulaire(
        formulaireSectionDeLaBrideIt1(styles, t, formik),
        formik
      );
    }
  };

  const afficherFormulaireExtremiteIdentiqueIt2 = () => {
    const typeExtremiteIt2 =
      formik.values[S06_TYPE_EXTREMITES_IT2.description];
    if (
      equalsCodeOrLabel(
        parseInt(typeExtremiteIt2),
        S06_STEP6_arrayTypeExtremites(),
        0
      )
    ) {
      return genererFormulaire(
        formulaireSectionDeLaManchetteIt2(styles, t, formik),
        formik
      );
    } else if (
      equalsCodeOrLabel(
        parseInt(typeExtremiteIt2),
        S06_STEP6_arrayTypeExtremites(),
        1
      )
    ) {
      return genererFormulaire(
        formulaireSectionDeLaBrideIt2(styles, t, formik),
        formik
      );
    }
  };

  const afficherDessinTechnique = () => {
    return (
      <DessinTechnique
        styles={styles}
        formik={formik.values}
        etape={etape}
        t={t}
        isFrom={"Configurateur"}
      />
    );
  };

  const afficherSOSetForm = () => {
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyDimensionsARenseignerSouffletPlis}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(
              formulaireDimensionsExtremitesBassesS06(styles, t),
              formik
            )}
            {afficherLesChampsExtremiteBasse()}

            <StyledDivDessinMobile>
              <StyledDivDessin>
                {afficherDessinTechnique()}
                <StyledDivFiligranne
                  style={{
                    backgroundImage:
                      "url(" +
                      importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                      ")",
                  }}
                  className={logoFiligraneDessin}
                />
              </StyledDivDessin>
              {lang.lang === "FR" && afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={cssButton}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
        {lang.lang === "FR" && afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
