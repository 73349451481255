const TEXT = Symbol("text");
const TEXT_CUSTOM1 = Symbol("text custom1");
const TEXTFIELD = Symbol("TextField");
const TEXTFIELD_RETURN_ARRAY = Symbol("TextField return array");
const TEXTFIELD_RETURN_ARRAY_MULTI = Symbol("TextField return array multi");
const TEXTFIELD_ZONE = Symbol("TextField zone");
const TEXTFIELD_SELECT = Symbol("TextField select");
const TEXTFIELD_BY_LABEL = Symbol("TextFieldByLabel");
const TEXTFIELD_SELECT_BACKOFFICE = Symbol("TextFieldSelectBackoffice");
const TEXTFIELD_NUMBER = Symbol("TextField number");
const TEXTFIELD_SELECT_SYLOB = Symbol("TextField select sylob");
const TEXTFIELD_WITHOUT_FORMIK = Symbol("TextField without formik");
const DROPZONE = Symbol("Dropzone");
const BUTTON_GROUP_BOX_SHADOW = Symbol("ButtonGroupBoxShadow");
const BUTTON_GROUP = Symbol("ButtonGroup");
const BUTTON_GROUP_IMAGE = Symbol("ButtonGroupImage");
const CHECKBOX_BOX_SHADOW = Symbol("CheckboxBoxShadow");
const CHECKBOX_IMAGE_CUSTOM = Symbol("CheckboxImageCustom");
const CHECKBOX = Symbol("Checkbox");
const CHECKBOX_BY_LABEL = Symbol("CheckboxByLabel");
const SWITCH = Symbol("Switch");
const RECAP = Symbol("recapitulatif");
const RECAP_S05 = Symbol("recapitulatifS05");
const RECAP_S08 = Symbol("recapitulatifS08");
const RECAP_S04 = Symbol("recapitulatifS04");
const RECAP_S06 = Symbol("recapitulatifS06");
const TEXT_AND_TEXTFIELD_ALIGN = Symbol("text and textfield align");
const GRID = Symbol("Grid");
const TEXTFIELD_SEARCHBAR = Symbol("TextField SearchBar");
const TEXTFIELD_SEARCHBAR_CODE = Symbol("TextField SearchBar Return Code");

export {
  TEXT,
  TEXT_CUSTOM1,
  TEXTFIELD,
  TEXTFIELD_RETURN_ARRAY,
  TEXTFIELD_RETURN_ARRAY_MULTI,
  TEXTFIELD_ZONE,
  TEXTFIELD_SELECT,
  TEXTFIELD_BY_LABEL,
  TEXTFIELD_SELECT_BACKOFFICE,
  TEXTFIELD_NUMBER,
  TEXTFIELD_SELECT_SYLOB,
  TEXTFIELD_WITHOUT_FORMIK,
  DROPZONE,
  BUTTON_GROUP_BOX_SHADOW,
  BUTTON_GROUP,
  BUTTON_GROUP_IMAGE,
  CHECKBOX_BOX_SHADOW,
  CHECKBOX_IMAGE_CUSTOM,
  CHECKBOX,
  CHECKBOX_BY_LABEL,
  SWITCH,
  RECAP,
  RECAP_S04,
  RECAP_S05,
  RECAP_S08,
  TEXT_AND_TEXTFIELD_ALIGN,
  GRID,
  TEXTFIELD_SEARCHBAR,
  TEXTFIELD_SEARCHBAR_CODE,
  RECAP_S06
};
