import {
  S04_LONGUEUR_COMPRIMEE,
  S04_LONGUEUR_DEPLOYEE,
} from "../../../../../constantes/symbols/SymbolsS04";
import { TEXTFIELD_NUMBER } from "../../../components/generateFormulary/types/TypeFieldsFormulary";

export const formulaireDimensionsCorpsS04 = (styles, t) => [
  {
    nom: S04_LONGUEUR_DEPLOYEE,
    label: t.longueurDeployee + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0038_Q00010,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S04_LONGUEUR_COMPRIMEE,
    label: t.longueurComprimee + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    textInformation: {
      text: t.SQ0038_Q00020,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];
