import { usedForDisplay } from "./HelperSymbolsServices";
import {
  BAGUE_GUIDAGE,
  FERMETURE_GLISSIERE,
  OEILLET_FILTRE,
} from "./SymbolsS01";

//Etape 4 & 5
const DROIT = Symbol("droit");
const CONIQUE = Symbol("conique");
const ROND = Symbol("rond");
const RECTANGULAIRE = Symbol("rectangulaire");
const RONDE = Symbol("ronde");

//Etape 4
const arraySectionSoufflet = (t) => [
  {
    code: ROND.description,
    label: "Ronde",
    ...usedForDisplay(t, { name: t?.ronde }),
  },
  {
    code: RECTANGULAIRE.description,
    label: "Rectangulaire",
    ...usedForDisplay(t, { name: t?.rectangulaire }),
  },
];

const arrayTypeCorps = (t) => [
  {
    code: DROIT.description,
    label: "Droit",
    ...usedForDisplay(t, { name: t?.droit }),
  },
  {
    code: CONIQUE.description,
    label: "Conique",
    ...usedForDisplay(t, { name: t?.conique }),
  },
];

//Etape 5
const arraySectionExtremite = (t) => [
  {
    code: RONDE.description,
    label: "Ronde",
    ...usedForDisplay(t, { name: t?.ronde }),
  },
  {
    code: RECTANGULAIRE.description,
    label: "Rectangulaire",
    ...usedForDisplay(t, { name: t?.rectangulaire }),
  },
];

const arrayTypeExtremites = (t, iteration) => [
  iteration == 1
    ? {
        code: 1,
        label: "Manchette",
        ...usedForDisplay(t, { name: t?.manchette, img: "Manchette.png" }),
      }
    : {
        code: 1,
        label: "Manchette",
        ...usedForDisplay(t, {
          name: t?.manchette,
          img: "Manchette_gauche.png",
        }),
      },
  iteration == 1
    ? {
        code: 2,
        label: "Bride",
        ...usedForDisplay(t, { name: t?.bride, img: "Bride.png" }),
      }
    : {
        code: 2,
        label: "Bride",
        ...usedForDisplay(t, { name: t?.bride, img: "Bride_gauche.png" }),
      },
  iteration == 1
    ? {
        code: 3,
        label: "Manchette et bride",
        ...usedForDisplay(t, {
          name: t?.manchetteEtBride,
          img: "ManchetteEtBride.png",
        }),
      }
    : {
        code: 3,
        label: "Manchette et bride",
        ...usedForDisplay(t, {
          name: t?.manchetteEtBride,
          img: "ManchetteEtBride_gauche.png",
        }),
      },
];

const arrayFixationExtremite = (t) => [
  {
    code: 0,
    label: "Aucune",
    ...usedForDisplay(t, { name: t?.aucune }),
  },
  {
    code: 1,
    label: "Collier de serrage",
    ...usedForDisplay(t, { name: t?.collierSerrage }),
  },
  {
    code: 2,
    label: "Contre-Bride",
    ...usedForDisplay(t, { name: t?.contreBride }),
  },
];

//Etape 6
const arrayAccessoires = (t, styles) => [
  {
    code: FERMETURE_GLISSIERE,
    ...usedForDisplay(t, { name: t?.champfermetureGlissiere }),
    img: "FermetureGlissiere.png",
    textInformation: {
      text: t?.SQ0020_Q00010,
      cssIcon: styles?.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    code: OEILLET_FILTRE,
    ...usedForDisplay(t, { name: t?.champOeilletFiltre }),
    img: "OeilletFiltre.png",
    textInformation: {
      text: t?.SQ0021_Q00010,
      cssIcon: styles?.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    code: BAGUE_GUIDAGE,
    ...usedForDisplay(t, { name: t?.champBagueDeGuidage }),
    img: "BagueDeGuidage.png",
    textInformation: {
      text: t?.SQ0022_Q00010,
      cssIcon: styles?.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export {
  arrayTypeExtremites,
  arrayFixationExtremite,
  arraySectionExtremite,
  arraySectionSoufflet,
  arrayTypeCorps,
  arrayAccessoires,
};
