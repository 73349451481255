import { Paper } from "@mui/material";
import { containsLetters, equalsCodeOrLabel } from "../../../../constantes/symbols/HelperSymbolsServices";
import {
  checkFocusedArrow,
  checkFocusedArrowWithDependance,
  checkFocusedEndMarker,
  checkFocusedEndMarkerWithDependance,
  checkFocusedStartMarker,
  checkFocusedStartMarkerWithDependance,
  checkFocusedText,
  checkFocusedTextWithDependance,
} from "../../../../helper/ConditionnalCssProvider";
import {
  StyledPath1,
  StyledPath4,
  StyledPath5,
  StyledPathBaseTableElevatrice,
  StyledPathCote,
  StyledPathCoteDotted,
  StyledPathDotted,
  StyledPathFleche,
} from "../../styledComponents/StyledPath";
import {
  StyledRect,
  StyledRect2,
  StyledRect6,
} from "../../styledComponents/StyledRect";
import {
  ALIGNEMENT_IT1,
  ALIGNEMENT_IT2,
  BRIDE_FIXATION_IT1,
  BRIDE_FIXATION_IT2,
  S06_BRIDE_IT1,
  S06_BRIDE_IT2,
  S06_EXTREMITE_IDENTIQUE,
  S06_TYPE_EXTREMITES_IT1,
  S06_TYPE_EXTREMITES_IT2,
  S06_LONGUEUR_A_INT,
  S06_LONGUEUR_COMPRIMEE,
  S06_LONGUEUR_DEPLOYEE,
  S06_MANCHETTE_A_IT1,
  S06_MANCHETTE_A_IT2,
  S06_MANCHETTE_B_IT1,
  S06_MANCHETTE_B_IT2,
  S06_MANCHETTE_LARGEUR_IT1,
  S06_MANCHETTE_LARGEUR_IT2,
} from "../../../../constantes/symbols/SymbolsS06";
import {
  S06_STEP6_arrayAlignement,
  S06_STEP6_arrayFixationBride,
  S06_STEP6_arrayTypeBride,
  S06_STEP6_arrayTypeExtremites,
} from "../../../../constantes/symbols/SymbolsServicesS06";
import {
  MANCHETTE_A_IT1,
  MANCHETTE_A_IT2,
} from "../../../../constantes/symbols/SymbolsS01";

export const TableElevatrice = ({ styles, formik, t, isFrom }) => {
  
  const longueurComprimee = formik[S06_LONGUEUR_COMPRIMEE.description] || "";
  const longueurDeployee = formik[S06_LONGUEUR_DEPLOYEE.description] || "";
  const extremitesIdentiques =
    parseInt(formik[S06_EXTREMITE_IDENTIQUE.description]) || 0;
  const fixationIt1 =
    isFrom.description === "art"
      ? S06_STEP6_arrayTypeExtremites(t).find(
          (element) =>
            element.label === formik[S06_TYPE_EXTREMITES_IT1.description]
        ).code
      : parseInt(formik[S06_TYPE_EXTREMITES_IT1.description]);
  const fixationIt2 =
    isFrom.description === "art"
      ? S06_STEP6_arrayTypeExtremites(t).find(
          (element) =>
            element.label === formik[S06_TYPE_EXTREMITES_IT2.description]
        ).code
      : parseInt(formik[S06_TYPE_EXTREMITES_IT2.description]);

  const manchetteAIt1IsSupAInt =
    formik[S06_MANCHETTE_A_IT1.description] >
    formik[S06_LONGUEUR_A_INT.description]
      ? true
      : false;

  const manchetteAIt2IsSupAInt =
    formik[S06_MANCHETTE_A_IT2.description] >
    formik[S06_LONGUEUR_A_INT.description]
      ? true
      : false;

  const typeBrideIt1 =
    isFrom.description === "art"
      ? S06_STEP6_arrayTypeBride(t).find(
          (element) => element.label === formik[S06_BRIDE_IT1.description]
        ).code
      : parseInt(formik[S06_BRIDE_IT1.description]);

  const typeBrideIt2 =
    isFrom.description === "art"
      ? S06_STEP6_arrayTypeBride(t).find(
          (element) => element.label === formik[S06_BRIDE_IT2.description]
        ).code
      : parseInt(formik[S06_BRIDE_IT2.description]);

  const largeurManchetteIt1 = formik[S06_MANCHETTE_LARGEUR_IT1.description];
  const largeurManchetteIt2 = formik[S06_MANCHETTE_LARGEUR_IT2.description];

  const manchetteAXManchetteBIt1 =
    formik[S06_MANCHETTE_A_IT1.description] *
    formik[S06_MANCHETTE_B_IT1.description];
  const manchetteAXManchetteBIt2 =
    formik[S06_MANCHETTE_A_IT2.description] *
    formik[S06_MANCHETTE_B_IT2.description];

  const fixationBrideIt1 =
    isFrom.description === "art"
      ? S06_STEP6_arrayFixationBride(t).find(
          (element) => element.label === formik[BRIDE_FIXATION_IT1.description]
        ).code
      : parseInt(formik[BRIDE_FIXATION_IT1.description]);
  const fixationBrideIt2 =
    isFrom.description === "art"
      ? S06_STEP6_arrayFixationBride(t).find(
          (element) => element.label === formik[BRIDE_FIXATION_IT2.description]
        ).code
      : parseInt(formik[BRIDE_FIXATION_IT2.description]);

  const afficherExtremitesIdentiques = (t) => {
    switch (extremitesIdentiques) {
      case 1:
        return (
          <>
            {afficherFixationExtremiteIt1(fixationIt1, t)}

            {afficherFixationExtremiteIt2(fixationIt1, t)}
          </>
        );
      case 0:
        return (
          <>
            {afficherFixationExtremiteIt1(fixationIt1, t)}
            {afficherFixationExtremiteIt2(fixationIt2, t)}
          </>
        );
      default:
        return null;
    }
  };

  const afficherFixationExtremiteIt1 = (fixation, t) => {
    switch (true) {
      case equalsCodeOrLabel(fixation, S06_STEP6_arrayTypeExtremites(t), 0):
        return <>{afficherManchetteIt1(t)}</>;
      case equalsCodeOrLabel(fixation, S06_STEP6_arrayTypeExtremites(t), 1):
        return <>{afficherBrideIt1(typeBrideIt1, t)}</>;

      default:
        return (
          <>
            <StyledRect6 x="55" y="138" width="125" height="10" />
            <StyledRect6 x="55" y="57" width="125" height="10" />
          </>
        );
    }
  };

  const afficherFixationExtremiteIt2 = (fixation, t) => {
    switch (true) {
      case equalsCodeOrLabel(fixation, S06_STEP6_arrayTypeExtremites(t), 0):
        return <>{afficherManchetteIt2(t)}</>;
      case equalsCodeOrLabel(fixation, S06_STEP6_arrayTypeExtremites(t), 1):
        switch (extremitesIdentiques) {
          case 0:
            return <>{afficherBrideIt2(typeBrideIt2, t)}</>;
          case 1:
            return <>{afficherBrideIt2(typeBrideIt1, t)}</>;
          default:
            return null;
        }

      default:
        return null;
    }
  };

  const afficherBrideIt1 = (t) => {
    switch (typeBrideIt1) {
      case 0:
        return (
          <>
            <StyledRect6 x="55" y="138" width="125" height="10" />
            <StyledRect2
              x="55"
              y="135"
              width="125"
              height="2"
              className={styles.couleurCharteRect}
            />
            <StyledPathDotted
              d="M 180,97 L 180,111 "
              className={styles.dotted}
            />
            <StyledPathDotted d="M 54,97 L 54,111 " className={styles.dotted} />

            <StyledPath1 d="M 180,134 L 165,134 L 180,126 L 165,119 L 180,111 " />

            <StyledPath1 d="M 54.5,134 L 70,134 L 54.5,126 L 70,119 L 54.5,111" />
            {afficherBrideFixation(fixationBrideIt1, true, typeBrideIt1)}
          </>
        );
      case 1:
        return (
          <>
            <StyledRect6 x="55" y="138" width="125" height="10" />
            <StyledRect2
              x="55"
              y="135"
              width="125"
              height="2"
              className={styles.couleurCharteRect}
            />

            <StyledPathDotted
              d="M 165,97 L 165,111 "
              className={styles.dotted}
            />
            <StyledPathDotted d="M 70,97 L 70,111 " className={styles.dotted} />

            <StyledPath1 d="M 165,134 L 180,134 L 165,126 L 180,119 L 165,111 " />

            <StyledPath1 d="M 70,134 L 55,134 L 70,126 L 55,119 L 70,111 " />
            {afficherBrideFixation(fixationBrideIt1, true, typeBrideIt1)}
          </>
        );
      default:
        return (
          <>
            <StyledRect6 x="55" y="138" width="125" height="10" />
            <StyledRect
              x="55"
              y="135"
              width="125"
              height="2"
              className={styles.couleurCharteRect}
            />

            <StyledPathDotted
              d="M 165,97 L 165,111 "
              className={styles.dotted}
            />
            <StyledPathDotted d="M 70,97 L 70,111 " className={styles.dotted} />

            <StyledPath1 d="M 165,134 L 180,134 L 165,126 L 180,119 L 165,111 " />

            <StyledPath1 d="M 70,134 L 56,134 L 70,126 L 55,119 L 70,111 " />
          </>
        );
    }
  };

  const afficherBrideIt2 = (typeBride, t) => {
    switch (typeBride) {
      case 0:
        return (
          <>
            <StyledRect6 x="55" y="57" width="125" height="10" />
            <StyledRect2
              x="55"
              y="68"
              width="125"
              height="2"
              className={styles.couleurCharteRect}
            />

            <StyledPath1 d="M 180,71 L 165,71 L 180,79 L 165,87 L 180,95 " />

            <StyledPath1 d="M 55,71 L 70,71 L 55,79 L 70,87 L 55,95 " />

            {extremitesIdentiques ? (
              <>
                {afficherBrideFixation(fixationBrideIt1, false, typeBrideIt1)}
              </>
            ) : (
              <>
                {afficherBrideFixation(fixationBrideIt2, false, typeBrideIt2)}
              </>
            )}
          </>
        );
      case 1:
        return (
          <>
            <StyledRect6 x="55" y="57" width="125" height="10" />
            <StyledRect2
              x="55"
              y="68"
              width="125"
              height="2"
              className={styles.couleurCharteRect}
            />

            <StyledPath1 d="M 165,71 L 180,71 L 165,79 L 180,87 L 165,95 " />

            <StyledPath1 d="M 70,71 L 55,71 L 70,79 L 55,87 L 70,95 " />
            {extremitesIdentiques ? (
              <>
                {afficherBrideFixation(fixationBrideIt1, false, typeBrideIt1)}
              </>
            ) : (
              <>
                {afficherBrideFixation(fixationBrideIt2, false, typeBrideIt2)}
              </>
            )}
          </>
        );
      default:
        return (
          <>
            <StyledRect6 x="55" y="57" width="125" height="10" />
            <StyledRect
              x="55"
              y="68"
              width="125"
              height="2"
              className={styles.couleurCharteRect}
            />

            <StyledPath1 d="M 165,71 L 180,71 L 165,79 L 180,87 L 165,95 " />

            <StyledPath1 d="M 70,71 L 55,71 L 70,79 L 55,87 L 70,95 " />
          </>
        );
    }
  };

  const afficherManchetteIt1 = (t) => {
    switch (extremitesIdentiques) {
      case 1:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {!manchetteAIt1IsSupAInt ? (
              <>
                <StyledRect6 x="72" y="138" width="91.5" height="10" />
                <StyledPath1 d="M 164.5,134 L 164.5,148.5" />
                <StyledPath1 d="M 164.5,134 L 178.5,126 L 164.5,119 L 178.5,111 " />

                <StyledPath1 d="M 71,134 L 71,148.5" />
                <StyledPath1 d="M 71,134 L 57,126 L 71,119 L 57,111 " />
              </>
            ) : (
              <>
                <StyledRect6 x="55" y="138" width="125" height="10" />
                {/* COTE DROIT */}

                <StyledPath1 d="M 181,134 L 181,148.5" />
                <StyledPath1 d="M 181,134 L 166,126 L 181,119 L 166,111 " />

                <StyledPathDotted
                  d="M 166,97 L 166,111 "
                  className={styles.dotted}
                />
                <StyledPathDotted
                  d="M 69,97 L 69,111 "
                  className={styles.dotted}
                />

                {/* COTE GAUCHE */}

                <StyledPath1 d="M 54,134 L 54,148.5" />
                <StyledPath1 d="M 54,134 L 69,126 L 54,119 L 69,111 " />
              </>
            )}
          </>
        );
      case 0:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {!manchetteAIt1IsSupAInt ? (
              <>
                <StyledRect6 x="55" y="138" width="125" height="10" />

                <StyledPath1
                  className={styles.extremiteColor}
                  d="M 180,134 L 180,148.5"
                />
                <StyledPath1 d="M 180,134 L 165,126 L 180,119 L 165,111 L 180,103" />

                <StyledPathDotted
                  d="M 70,97 L 70,111 "
                  className={styles.dotted}
                />

                {/* COTE GAUCHE */}

                <StyledPath1
                  className={styles.extremiteColor}
                  d="M 55,134 L 55,148.5"
                />
                <StyledPath1 d="M 55,134 L 70,126 L 55,119 L 70,111 " />
              </>
            ) : (
              <>
                <StyledRect6 x="72" y="138" width="91.5" height="10" />

                <StyledPath1
                  className={styles.extremiteColor}
                  d="M 163.5,134 L 163.5,148.5"
                />
                <StyledPath1 d="M 163.5,134 L 178.5,126 L 163.5,119 L 178.5,111 " />

                <StyledPath1
                  className={styles.extremiteColor}
                  d="M 72,134 L 72,148.5"
                />
                <StyledPath1 d="M 72,134 L 57,126 L 72,119 L 57,111 " />
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const afficherManchetteIt2 = (t) => {
    switch (extremitesIdentiques) {
      case 1:
        return (
          <>
            {!manchetteAIt1IsSupAInt ? (
              <>
                <StyledRect6 x="72" y="57" width="91.5" height="10" />
                <StyledPath1 d="M 164.5,56.5 L 164.5,71" />
                <StyledPath1 d="M 164.5,71 L 178.5,79 L 164.5,87 L 179,95 " />

                <StyledPathDotted
                  d="M 56,97 L 56,111 "
                  className={styles.dotted}
                />
                <StyledPathDotted
                  d="M 179,97 L 179,111 "
                  className={styles.dotted}
                />

                <StyledPath1 d="M 71,56.5 L 71,71" />
                <StyledPath1 d="M 71,71 L 57,79 L 71,87 L 57,95 " />
              </>
            ) : (
              <>
                <StyledRect6 x="55" y="57" width="125" height="10" />
                <StyledPath1 d="M 181,56.5 L 181,71" />
                <StyledPath1 d="M 181,71 L 166,79 L 181,86 L 166,94 " />
                <StyledPath1 d="M 54,56.5 L 54,71" />
                <StyledPath1 d="M 54,71 L 69,79 L 54,86 L 69,94 " />
              </>
            )}
          </>
        );
      case 0:
        return (
          <>
            {!manchetteAIt2IsSupAInt ? (
              <>
                <StyledRect6 x="55" y="57" width="125" height="10" />

                <StyledPath1
                  className={styles.extremiteColor}
                  d="M 163.5,56.5 L 163.5,71"
                />
                <StyledPath1 d="M 163.5,71 L 178.5,79 L 163.5,87 L 179,95 " />

                <StyledPath1
                  className={styles.extremiteColor}
                  d="M 55,56.5 L 55,71"
                />
                <StyledPath1 d="M 55,71 L 71,79 L 56,87 L 71,95 L 56,103 " />
              </>
            ) : (
              <>
                <StyledRect6 x="72" y="57" width="91.5" height="10" />

                <StyledPath1
                  className={styles.extremiteColor}
                  d="M 163.5,56.5 L 163.5,71"
                />
                <StyledPath1 d="M 163.5,71 L 178.5,79 L 163.5,87 L 179,95 " />

                <StyledPath1
                  className={styles.extremiteColor}
                  d="M 72,56.5 L 72,71"
                />
                <StyledPath1 d="M 72,71 L 57,79 L 72,87 L 57,95 " />
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const afficherLDetLC = () => {
    return (
      <>
        {fixationIt1 === 1 && !manchetteAIt1IsSupAInt ? (
          <>
            <StyledPathCoteDotted d={"M 45,138 L 72,138"} />
          </>
        ) : (
          <>
            <StyledPathCoteDotted d={"M 45,138 L 54,138"} />
          </>
        )}

        {(fixationIt2 === 1 && !manchetteAIt2IsSupAInt) ||
        (extremitesIdentiques &&
          fixationIt1 === 1 &&
          !manchetteAIt1IsSupAInt) ? (
          <>
            <StyledPathCoteDotted d={"M 45,67 L 72,67"} />
          </>
        ) : (
          <>
            <StyledPathCoteDotted d={"M 45,67 L 54,67"} />
          </>
        )}

        <StyledPathFleche
          d={"M 45,73 L 45,132"}
          markerStart={checkFocusedStartMarker(formik, [
            S06_LONGUEUR_DEPLOYEE,
            S06_LONGUEUR_COMPRIMEE,
          ])}
          markerEnd={checkFocusedEndMarker(formik, [
            S06_LONGUEUR_DEPLOYEE,
            S06_LONGUEUR_COMPRIMEE,
          ])}
          className={checkFocusedArrow(
            formik,
            [S06_LONGUEUR_DEPLOYEE, S06_LONGUEUR_COMPRIMEE],
            styles
          )}
        />

        {longueurComprimee ? (
          <text
            fontSize="0.4em"
            x="-5"
            y="60"
            direction="rtl"
            transform="rotate(-90, 40, 60)"
            className={checkFocusedText(
              formik,
              [S06_LONGUEUR_DEPLOYEE, S06_LONGUEUR_COMPRIMEE],
              styles
            )}
          >
            LD {longueurDeployee}
          </text>
        ) : (
          <text
            fontSize="0.4em"
            x="-5"
            y="60"
            direction="rtl"
            transform="rotate(-90, 40, 60)"
            className={checkFocusedText(
              formik,
              [S06_LONGUEUR_DEPLOYEE, S06_LONGUEUR_COMPRIMEE],
              styles
            )}
          >
            LD {longueurDeployee}
          </text>
        )}
        {!longueurComprimee ? (
          <>
            <text
              fontSize="0.4em"
              x="0"
              y="60"
              textAnchor="middle"
              transform="rotate(-90, 40, 60)"
              className={checkFocusedText(
                formik,
                [S06_LONGUEUR_DEPLOYEE, S06_LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              /
            </text>
            <text
              fontSize="0.4em"
              x="5"
              y="60"
              direction="ltr"
              transform="rotate(-90, 40, 60)"
              className={checkFocusedText(
                formik,
                [S06_LONGUEUR_DEPLOYEE, S06_LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              LC {longueurComprimee}
            </text>
          </>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="0"
              y="60"
              textAnchor="middle"
              transform="rotate(-90, 40, 60)"
              className={checkFocusedText(
                formik,
                [S06_LONGUEUR_DEPLOYEE, S06_LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              /
            </text>
            <text
              fontSize="0.4em"
              x="5"
              y="60"
              direction="ltr"
              transform="rotate(-90, 40, 60)"
              className={checkFocusedText(
                formik,
                [S06_LONGUEUR_DEPLOYEE, S06_LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              LC {longueurComprimee}
            </text>
          </>
        )}
      </>
    );
  };

  const afficherAllCombinaisons = () => {
    const arrayFixationExtremites = S06_STEP6_arrayTypeExtremites(t);

    const fix1 = equalsCodeOrLabel(fixationIt1, arrayFixationExtremites, 0);
    const fix2 = equalsCodeOrLabel(fixationIt2, arrayFixationExtremites, 0);

    if (extremitesIdentiques) {
      return <>{afficherExtremitesIdentiques(t)}</>;
    }

    switch (true) {
      case fix1 && fix2 && !manchetteAIt1IsSupAInt && manchetteAIt2IsSupAInt:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {afficherCombinaison1(true)}
          </>
        );
      case fix1 && fix2 && manchetteAIt1IsSupAInt && !manchetteAIt2IsSupAInt:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {afficherCombinaison1()}
          </>
        );
      case fix1 && !fix2 && manchetteAIt1IsSupAInt:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {afficherCombinaison2(typeBrideIt2, true)}
            {afficherBrideFixation(fixationBrideIt2, false, typeBrideIt2)}
          </>
        );
      case !fix1 && fix2 && manchetteAIt2IsSupAInt:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {afficherCombinaison2(typeBrideIt1)}
            {afficherBrideFixation(fixationBrideIt1, true, typeBrideIt1)}
          </>
        );
      case fix1 && !fix2 && !manchetteAIt1IsSupAInt:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {afficherCombinaison3(typeBrideIt2, true)}
            {afficherBrideFixation(fixationBrideIt2, false, typeBrideIt2)}
          </>
        );
      case !fix1 && fix2 && !manchetteAIt2IsSupAInt:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {afficherCombinaison3(typeBrideIt1)}
            {afficherBrideFixation(fixationBrideIt1, true, typeBrideIt1)}
          </>
        );
      case !fix1 && !fix2:
        return (
          <>
            {afficherCombinaison4(typeBrideIt1, typeBrideIt2)}
            {afficherBrideFixation(fixationBrideIt2, false, typeBrideIt2)}
            {afficherBrideFixation(fixationBrideIt1, true, typeBrideIt1)}
          </>
        );
      case fix1 && fix2 && !manchetteAIt1IsSupAInt && !manchetteAIt2IsSupAInt:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {afficherCombinaison1Bis()}
          </>
        );
      case fix1 && fix2 && manchetteAIt1IsSupAInt && manchetteAIt2IsSupAInt:
        return (
          <>
            {afficherLongueurTableElevatrice()}
            {afficherCombinaison5()}
          </>
        );
      default:
        return null;
    }
  };

  // EXTREMITE NON IDENTIQUE / MANCHETTE ALIGNEMENT IT1 / MANCHETTE ALIGNEMENT IT2
  const afficherCombinaison1 = (rotate) => {
    // Point d'origine pour l'inversion
    const inversionPointY = 102.5;

    // Calcul du décalage pour l'inversion
    const yOffset = rotate ? inversionPointY * 2 : 0;

    return (
      <>
        <g
          transform={`translate(0, ${yOffset}) scale(${
            rotate ? "1, -1" : "1, 1"
          })`}
        >
          <StyledRect6 x="72" y="57" width="91.5" height="10" />
          <StyledRect6 x="55" y="138" width="125" height="10" />

          {/* EXTREMITE HAUT */}
          <StyledPath1 d="M 164.5,56.5 L 164.5,71" />
          <StyledPath1 d="M 164.5,71 L 178.5,79 L 164.5,87 L 179,95 " />

          <StyledPath1 d="M 71,56.5 L 71,71" />
          <StyledPath1 d="M 71,71 L 56,79 L 71,87 L 56,96 " />

          <StyledPathDotted d="M 55,97 L 55,105 " className={styles.dotted} />
          <StyledPathDotted d="M 179,97 L 179,105 " className={styles.dotted} />

          {/* EXTREMITE BAS */}
          <StyledPath1 d="M 181,134 L 181,148.5" />
          <StyledPath1 d="M 181,134 L 165,126 L 181,119 L 165,111 L 179,103 " />

          <StyledPath1 d="M 54,134 L 54,148.5" />

          <StyledPath1 d="M 54,134 L 70,126 L 55,119 L 70,111 L 55,103 " />
        </g>
      </>
    );
  };

  // EXTREMITE NON IDENTIQUE / MANCHETTE ALIGNEMENT IT1 / MANCHETTE ALIGNEMENT IT2
  const afficherCombinaison1Bis = (rotate) => {
    // Point d'origine pour l'inversion
    const inversionPointY = 102.5;

    // Calcul du décalage pour l'inversion
    const yOffset = rotate ? inversionPointY * 2 : 0;

    return (
      <>
        <g
          transform={`translate(0, ${yOffset}) scale(${
            rotate ? "1, -1" : "1, 1"
          })`}
        >
          <StyledRect6 x="72" y="57" width="91.5" height="10" />
          <StyledRect6 x="72" y="138" width="91.5" height="10" />

          {/* EXTREMITE HAUT */}
          <StyledPath1 d="M 164.5,56.5 L 164.5,71" />
          <StyledPath1 d="M 164.5,71 L 178.5,79 L 164.5,87 L 179,95 " />

          <StyledPath1 d="M 71,56.5 L 71,71" />
          <StyledPath1 d="M 71,71 L 57,79 L 71,87 L 57,95 " />

          <StyledPathDotted d="M 57,97 L 57,111 " className={styles.dotted} />
          <StyledPathDotted d="M 179,97 L 179,111 " className={styles.dotted} />

          {/* EXTREMITE BAS */}
          <StyledPath1 d="M 164.5,134 L 164.5,148.5" />
          <StyledPath1 d="M 164.5,134 L 178.5,126 L 164.5,119 L 178.5,111 " />

          <StyledPath1 d="M 71,134 L 71,148.5" />
          <StyledPath1 d="M 71,134 L 57,126 L 71,119 L 57,111 " />
        </g>
      </>
    );
  };

  // EXTREMITE NON IDENTIQUE / MANCHETTE ALIGNEMENT IT1 / BRIDE TYPE A
  const afficherCombinaison2 = (typeBride, rotate) => {
    // Point d'origine pour l'inversion
    const inversionPointY = 102.5;

    // Calcul du décalage pour l'inversion
    const yOffset = rotate ? inversionPointY * 2 : 0;

    return (
      <>
        <g
          transform={`translate(0, ${yOffset}) scale(${
            rotate ? "1, -1" : "1, 1"
          })`}
        >
          <StyledRect6 x="55" y="57" width="125" height="10" />
          <StyledRect6 x="55" y="138" width="125" height="10" />
          {/* EXTREMITE HAUTE */}
          <StyledRect2
            x="55"
            y="135"
            width="125"
            height="2"
            className={styles.couleurCharteRect}
          />

          {typeBride === 0 ? (
            <>
              <StyledPathDotted
                d="M 165,97 L 165,119 "
                className={styles.dotted}
              />
              <StyledPathDotted
                d="M 70,97 L 70,119 "
                className={styles.dotted}
              />

              <StyledPath1 d="M 180,134 L 165,134 L 180,126 L 165,119  " />

              <StyledPath1 d="M 54.5,134 L 70,134 L 54.5,126 L 70,119 " />
            </>
          ) : (
            <>
              <StyledPathDotted
                d="M 165,97 L 165,111 "
                className={styles.dotted}
              />
              <StyledPathDotted
                d="M 70,97 L 70,111 "
                className={styles.dotted}
              />
              <StyledPath1 d="M 165,134 L 180,134 L 165,126 L 180,119 L 165,111 " />

              <StyledPath1 d="M 70,134 L 55,134 L 70,126 L 55,119 L 70,111 " />
            </>
          )}

          {/* EXTREMITE BASSE */}

          <StyledPath1 d="M 54,56.5 L 54,71" />
          <StyledPath1 d="M 54,71 L 70,79 L 54,87 L 70,95 " />

          <StyledPath1 d="M 181,56.5 L 181,71" />
          <StyledPath1 d="M 181,71 L 165,79 L 181,87 L 165,95 " />
        </g>
      </>
    );
  };

  // EXTREMITE NON IDENTIQUE / MANCHETTE ALIGNEMENT IT1 / BRIDE TYPE B
  const afficherCombinaison3 = (typeBride, rotate) => {
    // Point d'origine pour l'inversion
    const inversionPointY = 102.5;

    // Calcul du décalage pour l'inversion
    const yOffset = rotate ? inversionPointY * 2 : 0;

    return (
      <>
        <g
          transform={`translate(0, ${yOffset}) scale(${
            rotate ? "1, -1" : "1, 1"
          })`}
        >
          <StyledRect6 x="72" y="57" width="91.5" height="10" />
          <StyledRect6 x="55" y="138" width="125" height="10" />
          {/* EXTREMITE BRIDE */}
          <StyledRect2
            x="55"
            y="135"
            width="125"
            height="2"
            className={styles.couleurCharteRect}
          />

          {typeBride === 0 ? (
            <>
              <StyledPathDotted
                d="M 180,97 L 180,111 "
                className={styles.dotted}
              />
              <StyledPathDotted
                d="M 54,97 L 54,111 "
                className={styles.dotted}
              />

              <StyledPath1 d="M 180,134 L 165,134 L 180,126 L 165,119 L 180,111 " />

              <StyledPath1 d="M 54.5,134 L 70,134 L 54.5,126 L 70,119 L 54.5,111" />
            </>
          ) : (
            <>
              <StyledPathDotted
                d="M 179,97 L 179,103 "
                className={styles.dotted}
              />
              <StyledPathDotted
                d="M 55,97 L 55,103 "
                className={styles.dotted}
              />
              <StyledPath1 d="M 165,134 L 180,134 L 165,126 L 180,119 L 165,111 L 180,103" />

              <StyledPath1 d="M 70,134 L 55,134 L 70,126 L 55,119 L 70,111 L 55,103" />
            </>
          )}

          {/* EXTREMITE MANCHETTE */}
          <StyledPath1 d="M 164.5,56.5 L 164.5,71" />
          <StyledPath1 d="M 164.5,71 L 178.5,79 L 164.5,87 L 179,95 " />

          <StyledPath1 d="M 71,56.5 L 71,71" />
          <StyledPath1 d="M 71,71 L 55,79 L 71,87 L 55,95 " />
        </g>
      </>
    );
  };

  const afficherCombinaison4 = (typeBrideIt1, typeBrideIt2, rotate) => {
    // Point d'origine pour l'inversion
    const inversionPointY = 102.5;

    // Calcul du décalage pour l'inversion
    const yOffset = rotate ? inversionPointY * 2 : 0;

    return (
      <>
        <g
          transform={`translate(0, ${yOffset}) scale(${
            rotate ? "1, -1" : "1, 1"
          })`}
        >
          <StyledRect6 x="55" y="57" width="125" height="10" />
          <StyledRect6 x="55" y="138" width="125" height="10" />
          {/* EXTREMITE BRIDE */}
          {formik[S06_TYPE_EXTREMITES_IT1.description] !== "" ? (
            <>
              <StyledRect2
                x="55"
                y="135"
                width="125"
                height="2"
                className={styles.couleurCharteRect}
              />

              <StyledRect2
                x="55"
                y="68"
                width="125"
                height="2"
                className={styles.couleurCharteRect}
              />
            </>
          ) : null}

          {/* Si les deux types de bride sont 0 */}
          {typeBrideIt1 === 0 && typeBrideIt2 === 0 && (
            <>
              <StyledPathDotted
                d="M 180,97 L 180,111 "
                className={styles.dotted}
              />
              <StyledPathDotted
                d="M 54,97 L 54,111 "
                className={styles.dotted}
              />

              <StyledPath1 d="M 180,134 L 165,134 L 180,126 L 165,119 L 180,111 " />

              <StyledPath1 d="M 54.5,134 L 70,134 L 54.5,126 L 70,119 L 54.5,111" />

              <StyledPath1 d="M 180,71 L 165,71 L 180,79 L 165,87 L 180,95 " />

              <StyledPath1 d="M 55,71 L 70,71 L 55,79 L 70,87 L 55,95 " />
            </>
          )}

          {/* Si typeBrideIt1 est 1 et typeBrideIt2 est 0 */}
          {typeBrideIt1 === 1 && typeBrideIt2 === 0 && (
            <>
              <StyledPathDotted
                d="M 165,105 L 165,111 "
                className={styles.dotted}
              />
              <StyledPathDotted
                d="M 69.5,105 L 69.5,111 "
                className={styles.dotted}
              />

              <StyledPath1 d="M 180,71 L 165,71 L 180,79 L 165,87 L 180,95 L 165,103" />

              <StyledPath1 d="M 55,71 L 70,71 L 55,79 L 70,87 L 55,95 L 70,103" />

              <StyledPath1 d="M 165,134 L 180,134 L 165,126 L 180,119 L 165,111 " />

              <StyledPath1 d="M 70,134 L 55,134 L 70,126 L 55,119 L 70,111" />
            </>
          )}

          {/* Si typeBrideIt1 est 0 et typeBrideIt2 est 1 */}
          {typeBrideIt1 === 0 && typeBrideIt2 === 1 && (
            <>
              <StyledPathDotted
                d="M 180,105 L 180,110"
                className={styles.dotted}
              />
              <StyledPathDotted
                d="M 55,105 L 55,110 "
                className={styles.dotted}
              />
              <StyledPath1 d="M 165,71 L 180,71 L 165,79 L 180,87 L 165,95 L 180,103" />

              <StyledPath1 d="M 70,71 L 55,71 L 70,79 L 55,87 L 70,95 L 55,103" />

              <StyledPath1 d="M 180,134 L 165,134 L 180,126 L 165,119 L 180,111 " />

              <StyledPath1 d="M 55,134 L 70,134 L 55,126 L 70,119 L 55,111" />
            </>
          )}

          {/* Si les deux types de bride sont 1 */}
          {typeBrideIt1 === 1 && typeBrideIt2 === 1 && (
            <>
              <StyledPathDotted
                d="M 165,97 L 165,111 "
                className={styles.dotted}
              />
              <StyledPathDotted
                d="M 70,97 L 70,111 "
                className={styles.dotted}
              />

              <StyledPath1 d="M 165,71 L 180,71 L 165,79 L 180,87 L 165,95 " />

              <StyledPath1 d="M 70,71 L 55,71 L 70,79 L 55,87 L 70,95" />

              <StyledPath1 d="M 165,134 L 180,134 L 165,126 L 180,119 L 165,111 " />

              <StyledPath1 d="M 70,134 L 55,134 L 70,126 L 55,119 L 70,111" />
            </>
          )}
        </g>
      </>
    );
  };

  const afficherCombinaison5 = () => {
    return (
      <>
        <StyledRect6 x="55" y="57" width="125" height="10" />
        <StyledRect6 x="55" y="138" width="125" height="10" />

        {/* EXTREMITE HAUT */}
        <StyledPath1 d="M 181,56.5 L 181,71" />
        <StyledPath1 d="M 181,71 L 165,79 L 181,86 L 165,94 " />
        <StyledPath1 d="M 54,56.5 L 54,71" />
        <StyledPath1 d="M 54,71 L 70,79 L 54,86 L 70,94 " />

        <StyledPathDotted d="M 70,97 L 70,111 " className={styles.dotted} />
        <StyledPathDotted d="M 165,97 L 165,111 " className={styles.dotted} />

        {/* EXTREMITE BAS */}
        <StyledPath1 d="M 181,134 L 181,148.5" />
        <StyledPath1 d="M 181,134 L 165,126 L 181,119 L 165,111" />

        <StyledPath1 d="M 54,134 L 54,148.5" />

        <StyledPath1 d="M 54,134 L 70,126 L 54,119 L 70,111" />
      </>
    );
  };

  const afficherLargeurManchette = () => {
    const setLongueurBasse = manchetteAIt1IsSupAInt ? 182 : 166;
    const setLongueurHaute = manchetteAIt2IsSupAInt ? 182 : 166;
    return (
      <>
        {extremitesIdentiques && fixationIt1 === 1 ? (
          <>
            <StyledPathCote d={"M" + setLongueurBasse + ",57 L 192,57"} />
            <StyledPathCote d={"M" + setLongueurBasse + ",71 L 192,71"} />

            <StyledPathFleche
              d={"M 190,52 L 190,76"}
              markerStart={checkFocusedEndMarker(formik, [
                S06_MANCHETTE_LARGEUR_IT1,
              ])}
              markerEnd={checkFocusedStartMarker(formik, [
                S06_MANCHETTE_LARGEUR_IT1,
              ])}
              className={checkFocusedArrow(
                formik,
                [S06_MANCHETTE_LARGEUR_IT1],
                styles
              )}
            />
            <text
              fontSize="0.4em"
              x="195"
              y="66"
              className={checkFocusedText(
                formik,
                [S06_MANCHETTE_LARGEUR_IT1],
                styles
              )}
            >
              {largeurManchetteIt1}
            </text>
          </>
        ) : null}

        {!extremitesIdentiques && fixationIt2 === 1 ? (
          <>
            <StyledPathCote d={"M" + setLongueurHaute + ",57 L 192,57"} />
            <StyledPathCote d={"M" + setLongueurHaute + ",71 L 192,71"} />

            <StyledPathFleche
              d={"M 190,52 L 190,76"}
              markerStart={checkFocusedEndMarker(formik, [
                S06_MANCHETTE_LARGEUR_IT2,
              ])}
              markerEnd={checkFocusedStartMarker(formik, [
                S06_MANCHETTE_LARGEUR_IT2,
              ])}
              className={checkFocusedArrow(
                formik,
                [S06_MANCHETTE_LARGEUR_IT2],
                styles
              )}
            />
            <text
              fontSize="0.4em"
              x="195"
              y="66"
              className={checkFocusedText(
                formik,
                [S06_MANCHETTE_LARGEUR_IT2],
                styles
              )}
            >
              {largeurManchetteIt2}
            </text>
          </>
        ) : null}

        {fixationIt1 === 1 ? (
          <>
            <StyledPathCote d={"M" + setLongueurBasse + ",134 L 192,134"} />
            <StyledPathCote d={"M" + setLongueurBasse + ",148 L 192,148"} />

            <StyledPathFleche
              d={"M 190,129 L 190,153"}
              markerStart={checkFocusedEndMarker(formik, [
                S06_MANCHETTE_LARGEUR_IT1,
              ])}
              markerEnd={checkFocusedStartMarker(formik, [
                S06_MANCHETTE_LARGEUR_IT1,
              ])}
              className={checkFocusedArrow(
                formik,
                [S06_MANCHETTE_LARGEUR_IT1],
                styles
              )}
            />
            {largeurManchetteIt1 !== "" ? (
              <>
                <text
                  fontSize="0.4em"
                  x="195"
                  y="143"
                  className={checkFocusedText(
                    formik,
                    [S06_MANCHETTE_LARGEUR_IT1],
                    styles
                  )}
                >
                  {largeurManchetteIt1}
                </text>
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
    // }
  };

  const afficherLongueurTableElevatrice = () => {
    const setLongueurGaucheIt1 = !manchetteAIt1IsSupAInt ? 78 : 60;
    const setLongueurDroiteIt1 = !manchetteAIt1IsSupAInt ? 158 : 175;
    const setLongueurGaucheIt2 = !manchetteAIt2IsSupAInt ? 78 : 60;
    const setLongueurDroiteIt2 = !manchetteAIt2IsSupAInt ? 158 : 175;

    return (
      <>
        {extremitesIdentiques ? (
          <>
            <StyledPathFleche
              d={`M ${setLongueurGaucheIt1}, 55 L ${setLongueurDroiteIt1}, 55`}
              markerStart={checkFocusedStartMarker(formik, [MANCHETTE_A_IT1])}
              markerEnd={checkFocusedEndMarker(formik, [MANCHETTE_A_IT1])}
              className={checkFocusedArrow(formik, [MANCHETTE_A_IT1], styles)}
            />
            <text
              fontSize="0.4em"
              x="118"
              y="53"
              textAnchor="middle"
              className={checkFocusedText(formik, [MANCHETTE_A_IT1], styles)}
            >
              {formik[S06_MANCHETTE_A_IT1.description]} x{" "}
              {formik[S06_MANCHETTE_B_IT1.description]}
            </text>
          </>
        ) : (
          <>
            {fixationIt1 === 1 ? (
              <>
                <StyledPathFleche
                  d={`M ${setLongueurGaucheIt1}, 151 L ${setLongueurDroiteIt1}, 151`}
                  markerStart={checkFocusedStartMarker(formik, [
                    MANCHETTE_A_IT1,
                  ])}
                  markerEnd={checkFocusedEndMarker(formik, [MANCHETTE_A_IT1])}
                  className={checkFocusedArrow(
                    formik,
                    [MANCHETTE_A_IT1],
                    styles
                  )}
                />
                <text
                  fontSize="0.4em"
                  x="118"
                  y="159"
                  textAnchor="middle"
                  className={checkFocusedText(
                    formik,
                    [MANCHETTE_A_IT1],
                    styles
                  )}
                >
                  {formik[S06_MANCHETTE_A_IT1.description]} x{" "}
                  {formik[S06_MANCHETTE_B_IT1.description]}
                </text>
              </>
            ) : null}

            {fixationIt2 === 1 ? (
              <>
                <StyledPathFleche
                  d={`M ${setLongueurGaucheIt2}, 55 L ${setLongueurDroiteIt2}, 55`}
                  markerStart={checkFocusedStartMarker(formik, [
                    MANCHETTE_A_IT2,
                  ])}
                  markerEnd={checkFocusedEndMarker(formik, [MANCHETTE_A_IT2])}
                  className={checkFocusedArrow(
                    formik,
                    [MANCHETTE_A_IT2],
                    styles
                  )}
                />
                <text
                  fontSize="0.4em"
                  x="118"
                  y="53"
                  textAnchor="middle"
                  className={checkFocusedText(
                    formik,
                    [MANCHETTE_A_IT2],
                    styles
                  )}
                >
                  {formik[S06_MANCHETTE_A_IT2.description]} x{" "}
                  {formik[S06_MANCHETTE_B_IT2.description]}
                </text>
              </>
            ) : null}
          </>
        )}
      </>
    );
  };

  const afficherLegende = (t) => {
    return (
      <>
        <text
          fontSize="0.4em"
          x="195"
          y="90"
          style={{ fill: "rgba(30,53,93,0.5)" }}
        >
          {t.titreLegendeTableElevatrice}
        </text>

        {fixationIt1 === 2 || (!extremitesIdentiques && fixationIt2 === 2) ? (
          <>
            <StyledRect2
              x="210"
              y="98"
              width="10"
              height="1"
              className={styles.couleurCharteRect}
            />
            <text fontSize="0.4em" x="223" y="100">
              {t.titreLegendeBride}
            </text>
          </>
        ) : null}

        {fixationIt1 !== 0 || (!extremitesIdentiques && fixationIt2 !== 0) ? (
          <>
            <StyledPath1 d="M 210,108 L 220,108" />
            <text fontSize="0.4em" x="225" y="110">
              {t.titreLegendeTissu}
            </text>
          </>
        ) : null}
      </>
    );
  };

  const afficherBrideFixation = (fixation, rotate, typeBride) => {
    switch (fixation) {
      case 0: // Percage
        return <>{afficherFixationPercage(rotate, typeBride)}</>;
      case 1: // Taraudages
        return <>{afficherFixationPercage(rotate, typeBride)}</>;
      case 2: // Velcro
        return <>{afficherFixationVelcro(rotate)}</>;
      case 3: // Dual Lock (Velcro renforcé)
        return <>{afficherFixationVelcro(rotate)}</>;
      default:
        return null;
    }
  };

  const afficherFixationPercage = (rotate, typeBride) => {
    const paths = [];
    if (typeBride === 0) {
      for (let yCoord = 60; yCoord <= 74; yCoord += 2) {
        paths.push(
          <>
            <StyledPath4
              d={`M 60,${yCoord} L 60,${yCoord + 1}`}
              transform={rotate ? "rotate(180, 117.5, 102.5)" : ""}
            />
            <StyledPath4
              d={`M 175,${yCoord} L 175,${yCoord + 1}`}
              transform={rotate ? "rotate(180, 117.5, 102.5)" : ""}
            />
          </>
        );
      }
    }

    return <>{paths}</>;
  };

  const afficherFixationVelcro = (rotate) => {
    const paths = [];
    for (let xCoord = 60; xCoord <= 175; xCoord += 5) {
      paths.push(
        <StyledPath5
          d={`M ${xCoord},64 L ${xCoord},69`}
          transform={rotate ? "rotate(180, 117.5, 102.5)" : ""}
        />
      );
    }
    return <>{paths}</>;
  };

  return (
    <Paper elevation={0}>
      <svg viewBox="-10 40 255 120">
        <defs>
          <marker
            id="startarrow"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 2, 10 5, 0 3.5" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 2, 10 3.5, 0 5" />
          </marker>
          <marker
            id="startarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="10 2, 10 5, 0 3.5"
              className={styles.markerSelected}
            />
          </marker>
          <marker
            id="endarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon
              points="0 2, 10 3.5, 0 5"
              className={styles.markerSelected}
            />
          </marker>
        </defs>

        <title></title>

        <>
          <StyledPathBaseTableElevatrice d="M 105,138 L 145,67 L 130,67 L 90,138 L 105,138" />
          <StyledPathBaseTableElevatrice d="M 90,67 L  130,138 L 145,138 L 105,67 L 90,67" />
          {afficherAllCombinaisons()}
          {afficherLDetLC()}
          {afficherLegende(t)}
          {afficherLargeurManchette()}
        </>
      </svg>
    </Paper>
  );
};
