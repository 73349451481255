import * as React from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export function useBlocker(blocker, when = false) {
  const { navigator } = React.useContext(NavigationContext);

  React.useEffect(() => {
    if (when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}
export default function usePrompt(message, when = false, booleanIdentite) {
  const blocker = React.useCallback(
    (tx) => {
      if (window.confirm(booleanIdentite === true ? message + " Moovprotect." : message + " RC Modèles.")) {
        tx.retry();
      }
    },
    [message, booleanIdentite]
  );
  useBlocker(blocker, when);
}
