const winVersion = ['Win32', 'Win64'];
const serveurDeDev = 'fps-f.dev.gibraltaz.fr';
const serveurProduction = 'configurateur.rcmodeles.com';
const serveurLocalhost = 'localhost';
const routeAjaxDev = 'fps/root/backend';
const routeAjaxProduction = 'api';

const getServicesUrl = () => {
    const splittedUrl = window.location.href.split('/');
    if(splittedUrl[2]===serveurDeDev || splittedUrl[2].match(serveurLocalhost)){
        return 'https://'+serveurDeDev+'/'+routeAjaxProduction+'/';
    }
    return 'https://'+serveurProduction+'/'+routeAjaxProduction+'/';
}

export const getServerUrl = (isUsedForFiles) => {
    const splittedUrl = window.location.href.split('/');
    const splittedDomain = splittedUrl[2].split(':');
    const osVersion = window.navigator.platform;
    if(splittedDomain[0]===serveurLocalhost){
        if(winVersion.includes(osVersion)){
            return 'https://fps-f.dev.gibraltaz.fr/api/';
        }else{
            return 'https://fps-f.dev.gibraltaz.fr/api/';
        }
    }else if(splittedDomain[0]===serveurDeDev){
        if(isUsedForFiles===true){
            return splittedUrl[0]+'//'+serveurDeDev+'/';
        }
        return splittedUrl[0]+'//'+serveurDeDev+'/'+routeAjaxProduction+'/';
    }
    if(isUsedForFiles===true){
        return splittedUrl[0]+'//'+serveurProduction+'/';
    }
    
    return splittedUrl[0]+'//'+serveurProduction+'/'+routeAjaxProduction+'/';
}

export const Serveur = Object.freeze({
    URL: getServerUrl(false),
    URL_SECTEURS: getServicesUrl()+'json/SylobActivites/',
    URL_FONCTIONS: getServicesUrl()+'json/SylobFonctions/',
    URL_TYPE_MACHINE: getServicesUrl()+'json/SylobTypeMachines/',
    URL_MARQUE_MACHINE: getServicesUrl()+'json/SylobMarqueMachine/',
    URL_PAYS: getServicesUrl()+'json/SylobPays/',
    URL_AGRESSIONS: getServicesUrl()+'json/SylobAgressions/',
    URL_ODP_BY_ID: getServicesUrl()+'json/getOffreDePrixById/',
    URL_ART_BY_ID: getServicesUrl()+'json/getCaracArtById/',
    URL_TRADUCTIONS: getServicesUrl()+'json/SylobTradText/',
    URL_TRADUCTIONS_INFOBULLES: getServicesUrl()+'json/SylobTradInfoB/',
    URL_GET_ALL_ARTICLES_EC: getServicesUrl()+'json/getArticlesEC/',
})