import * as Yup from "yup";
import { arrayAccessoires } from "../../../../../constantes/symbols/SymbolsServicesS01";

/* A Yup validation schema. */
export const validAccessoires = (t) => {
  return Yup.object().shape({
    [arrayAccessoires()[0].code.description]: Yup.number().test(
      "is boolean",
      () => {
        return t.elementNonListe;
      },
      (checkedElement) => checkedElement === 0 || checkedElement === 1
    ),
    [arrayAccessoires()[1].code.description]: Yup.number().test(
      "is boolean",
      () => {
        return t.elementNonListe;
      },
      (checkedElement) => checkedElement === 0 || checkedElement === 1
    ),
    [arrayAccessoires()[2].code.description]: Yup.number().test(
      "is boolean",
      () => {
        return t.elementNonListe;
      },
      (checkedElement) => checkedElement === 0 || checkedElement === 1
    ),
  });
};
