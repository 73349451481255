import * as Yup from "yup";

export const validModifierSonProfil = (t, roleList) =>
  Yup.object({
    prenom: Yup.string(),
    nom: Yup.string(),
    email: Yup.string(),
    motDePasse: Yup.string(),
    confirmerMotDePasse: Yup.string(),
  });
