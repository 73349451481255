import { styled } from "@mui/system";

export const StyledImgLogoRC = styled('img')({
    width: '40%',
    maxWidth: '147px',
    marginRight: '15px',
});

export const StyledImgLogoMoov = styled('img')({
    width: '180px',
    height: '150px',
    background: 'center/150%',
    '@media (max-width: 768px)':{
        width: '100px',
        height: '100px',
    },
});

export const StyledImgHeaderCollaborateur = styled('img')({
    width: '15%',
    minWidth: '150px',
});