import {
  CP,
  DEMANDE,
  EMAIL,
  FONCTION,
  NOM,
  PRENOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TEL,
} from "../../enum/champs/ChampsCommunFormulairesEnum";
import { DROPZONE_CONTACT } from "../ContactChamps";

export const initialValuesContact = () => {
  return {
    [DEMANDE.description]: "",
    [DROPZONE_CONTACT.description]: [],
    [SOCIETE.description]: "",
    [PAYS.description]: "",
    [CP.description]: "",
    [SECTEUR.description]: "",
    [NOM.description]: "",
    [PRENOM.description]: "",
    [FONCTION.description]: "",
    [EMAIL.description]: "",
    [TEL.description]: "",
  };
};
