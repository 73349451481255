import { styled } from "@mui/system";

export const StyledHrEspaceClient = styled("div")({
  width: "115px",
  height: "100%",
  border: "3px #EC8812 solid",
  borderRadius: "20px",
});

export const StyledHrEspaceClientRose = styled("div")({
  width: "115px",
  height: "100%",
  border: "3px #CC007B solid",
});

export const StyledDivCardWrapper = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",

  // Media query pour les écrans mobiles
  "@media (max-width: 768px)": {
    // Cible chaque carte individuellement par son ID
    "& #whoIam-card": { order: 1 },
    "& #publicite-card": { order: 2 },
    "& #commande-card": { order: 3 },
    "& #offre-card": { order: 4 },
    "& #commercial-card": { order: 5 },
    "& #article-card": { order: 6 },
    "& #nc-card": { order: 7 },
    "& #contact-card": { order: 8 },
    "& #service-card": { order: 9 },
    "& #configurateur-card": { order: 10 },
  },
});


export const StyledDivCardWrapperRow = styled("div")({
  // flex: 1,
  display: "flex",
  flexDirection: "row",
  gap: "1rem",

  "@media (max-width: 768px)": {
    flexDirection: "column",
  },
});

export const StyledDivWrapperVues = styled("div")({
  padding: "1.25rem",
});
