import {
  initChampsFormikToJsonDefaultArray,
  initChampsFormikToJsonDefaultCustom,
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";
import {
  FAMILLE_PROTECTION_INDUSTRIELLE,
  FOCUSED,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  LONGUEUR_COMPRIMEE,
  LONGUEUR_DEPLOYEE,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
  SECTION_SOUFFLET,
  TYPE_CORPS,
  RONDE_DROIT_INTERIEUR_MIN,
  RONDE_DROIT_EXTERIEUR_MAX,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
  SOUFFLET_AVEC_SPIRE,
} from "../../../../../constantes/symbols/SymbolsS01";
import { arrayProductFamilly } from "../../../../../constantes/symbols/SymbolsServicesCommon";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../../constantes/symbols/HelperSymbolsServices";

const champsString = [
  LONGUEUR_DEPLOYEE,
  LONGUEUR_COMPRIMEE,
  RONDE_DROIT_INTERIEUR_MIN,
  RONDE_DROIT_EXTERIEUR_MAX,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
];

const champsArray = [RECTANGULAIRE_CONIQUE_JOINDRE_PLAN];

export const initialValuesDimensionsCorps = (formulaire, t) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
      ...initChampsFormikToJsonDefaultArray(champsArray, formulaire),
      ...initChampsFormikToJsonDefaultCustom(
        [SECTION_SOUFFLET],
        formulaire,
        "rond"
      ),
      ...initChampsFormikToJsonDefaultCustom([TYPE_CORPS], formulaire, "droit"),
      ...initChampsFormikToJsonDefaultCustom(
        [SOUFFLET_AVEC_SPIRE],
        formulaire,
        equalsCodeOrLabel(
          formulaire[FAMILLE_PROTECTION_INDUSTRIELLE.description],
          arrayProductFamilly(t),
          0
        )
          ? 1
          : 0
      ),
      ...initChampsFormikToJsonDefaultCustom([FOCUSED], formulaire, null),
    },
    {}
  );
};
