export const SeeMoreIconEC = ({ dark, method }) => {
  return (
    <div
    // 
      style={{
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 8,
        display: "inline-flex",
        
      }}
    >
      <div
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 6,
          paddingBottom: 6,
          background: dark ? "rgba(255, 255, 255, 1)" : "rgba(42, 55, 92, 1)",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 0,
          overflow: "hidden",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 6,
          display: "flex",cursor: "pointer"
        }}
        onClick={method}
      >
        <div
          style={{
            width: 14,
            height: 14,
            position: "relative",
          }}
        >
          <div
            style={{
              top: -2.55,
              position: "absolute",
            }}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_187_2630)">
                <path
                  d="M13.5333 7.93151H7.93334V13.5315H6.06667V7.93151H0.466675V6.06484H6.06667V0.464844H7.93334V6.06484H13.5333V7.93151Z"
                  fill={dark ? "rgba(42, 55, 92, 1)" : "rgba(255, 255, 255, 1)"}
                />
              </g>
              <defs>
                <clipPath id="clip0_187_2630">
                  <rect
                    width="14"
                    height="14"
                    fill={dark ? "rgba(42, 55, 92, 1)" : "rgba(255, 255, 255, 1)"}
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
