import axios from 'axios';
import { Serveur } from '../../constantes/services/Serveur';


const getAllSecteurs = async () => {
    
    const config = {
        headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    };
    return await axios.get(Serveur.URL_SECTEURS, config);

}

const secteursService = {
    getAllSecteurs,
}

export default secteursService;

