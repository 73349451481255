import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  ARTICLE,
  DEMANDE_DE_PRIX,
  OFFRE_DE_PRIX,
} from "../../../../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { dataCleanerS04 } from "../../../../../../helper/DataCleanerS04";
import { setAllInfoByRefNull } from "../../../../../model/slice/FormulaireReducer";
import { DessinTechnique } from "../../../../components/DessinTechnique";
import { Loading, LoadingEC } from "../../../../components/Loading";
import {
  StyledDivDessinRecap,
  StyledDivDessinRecapIframe,
  StyledDivDessinRecapIframeScaleS04,
  StyledDivFiligranneRecapEC,
  StyledDivRecapExtremiteTitre,
  StyledDivRecapGeneEC,
  StyledDivRecapInt,
  StyledDivRecapitulatifEC,
  StyledDivSectionRecapEC,
  StyledDivSectionsRowRecap,
  StyledDivSectionsRowReverseRecap,
  StyledDivSubtitlesRecap,
} from "../../../../styledComponents/StyledDiv";
import { BoutonRetourEC } from "../../../../components/espaceClient/BoutonAccueilEC";
import {
  StyledH1VuesEC,
} from "../../../../styledComponents/StyledTextEspaceClient";
import { StyledPaperBodyEC } from "../../../../styledComponents/StyledPaper";
import { importAllSpec } from "../../../../../../helper/ImportAll";
import { StyledSpanSubtitlesRecap } from "../../../../styledComponents/StyledSpan";
import { arrayProductFamilly } from "../../../../../../constantes/symbols/SymbolsServicesCommon";
import { StyledHrEspaceClient } from "../../../../styledComponents/StyledDivEspaceClient";
import { equalsBooleanOrStringOrNumber } from "../../../../../../constantes/symbols/HelperSymbolsServices";
import { S04_EXTREMITE_IDENTIQUE } from "../../../../../../constantes/symbols/SymbolsS04";
import { genererFormulaire } from "../../../../components/generateFormulary/CasesGeneration";
import {
  formulaireAggressions,
  formulaireDimensionsCorps,
  formulaireExtremiteDroite,
  formulaireExtremiteGauche,
  formulaireExtremitesIdentiques,
  formulaireInformationsMachine,
} from "../../../../ChampsFormulaires/S01/recapitulatif/ChampsRecapitulatif";
import { genererChampRecapitulatifHorsConfigurateur } from "../../../../components/generateFormulary/methods/ResumeGenerate";
import { FormeConstructionFace } from "../../../../DessinTechnique/S04/FormeConstructionFace";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  formulaireAccessoiresS04,
  formulaireConstruction,
  formulaireConstructionSection,
} from "../../../../ChampsFormulaires/S04/recapitulatifS04/ChampsRecapitulatifS04";
import {
  afficherOnglets,
  afficherOngletsMobile,
} from "../../../../components/espaceClient/OngletsArticle";

export const ArticleS04 = ({
  state: {
    booleanIdentite,
    etape,
    formik,
    styles,
    styles: {
      cssButton,
      cssButtonPrec,
      sousTitreRecap,
      underlineH1,
      cssTypeProtec,
      cssCenter,
      iconeRecap,
      sousTitreExtremitesRecap,
    },
    pdfHeaderRef,
    pdfBodyRef,
    t,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateAgressionsList,
    upToDateFonctionsList,
    upToDateSecteursList,
    upToDatePaysList,
    reference,
    isArticleByIdSet,
    articleById,
  },
  action: {
    dispatch,
    setIsPdfInCreation,
    setPdfToDownload,
    setEtape,
    goTo,
    setUpToDateArticleByIdList,
    setArticleId,
    handleRetour,
    setIsArticleByIdSet,
  },
  isFrom,
  nom,
}) => {
  const [isOngletDesktopFormat, setIsOngletDesktopFormat] = useState(window.innerWidth >= 768);

  const [params] = useSearchParams();
  const from = [DEMANDE_DE_PRIX, OFFRE_DE_PRIX, ARTICLE].includes(isFrom);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const [iframe, setIframe] = useState();
  const [formulaire, setFormulaire] = useState(
    dataCleanerS04(formik.values ? formik.values : formik)
  );
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setIsOngletDesktopFormat(window.innerWidth >= 768);
  }, [window.innerWidth]);

  useEffect(() => {
    setIframe(parseInt(query.get("iframe")) === 1);
  }, []);

  useEffect(() => {
    setArticleId(params.get("art"));
  }, [booleanIdentite, setArticleId]);

  const triggerHandleRetour = () => {
    const url = new URL(window.location.href);

    if (from) {
      dispatch(setAllInfoByRefNull());
      setIsArticleByIdSet(false);

      url.searchParams.delete("art");
      window.history.pushState({}, "", url);
    } else {
      handleRetour();
    }
  };

  if (!isArticleByIdSet) {
    return <LoadingEC t={t} />;
  }

  const afficherBoutonRetour = (texte, action, backgroundColor) => {
    return (
      <BoutonRetourEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  const afficherExtremite = (t, formulaireList) => {
    switch (
      equalsBooleanOrStringOrNumber(
        formulaireList[S04_EXTREMITE_IDENTIQUE.description]
      )
    ) {
      case 1:
        return (
          <>
            <StyledDivRecapExtremiteTitre className={sousTitreExtremitesRecap}>
              {t.titreExtremites}
            </StyledDivRecapExtremiteTitre>
            {genererFormulaire(formulaireExtremiteDroite(t), formulaireList, t)}
            {genererChampRecapitulatifHorsConfigurateur(formulaire, 5)}
          </>
        );
      case 0:
        return (
          <>
            <StyledDivRecapExtremiteTitre className={sousTitreExtremitesRecap}>
              {t.titreExtremiteGauche}
            </StyledDivRecapExtremiteTitre>
            {genererFormulaire(formulaireExtremiteGauche(t), formulaireList, t)}
            {genererChampRecapitulatifHorsConfigurateur(formulaire, 4)}
            <div></div>
            <StyledDivRecapExtremiteTitre className={sousTitreExtremitesRecap}>
              {t.titreExtremiteDroite}
            </StyledDivRecapExtremiteTitre>
            {genererFormulaire(formulaireExtremiteDroite(t), formulaireList, t)}
            {genererChampRecapitulatifHorsConfigurateur(formulaire, 4)}
          </>
        );
      default:
        return null;
    }
  };

  const afficherDessinTechnique = (t) => {
    return iframe ? (
      <div>
        <StyledDivDessinRecapIframe>
          <StyledDivDessinRecapIframeScaleS04>
            <DessinTechnique formik={formulaire} styles={styles} t={t} />
          </StyledDivDessinRecapIframeScaleS04>
        </StyledDivDessinRecapIframe>
        <StyledDivDessinRecapIframe>
          <StyledDivDessinRecapIframeScaleS04>
            <FormeConstructionFace styles={styles} formik={formulaire} t={t} />
          </StyledDivDessinRecapIframeScaleS04>
        </StyledDivDessinRecapIframe>
      </div>
    ) : (
      <>
        <StyledDivDessinRecap>
          <DessinTechnique formik={formulaire} styles={styles} t={t} />
        </StyledDivDessinRecap>
        <StyledDivDessinRecap>
          <FormeConstructionFace styles={styles} formik={formulaire} t={t} />
        </StyledDivDessinRecap>
      </>
    );
  };

  const afficherRecap = () => {
    return (
      <>
        {iframe ? afficherDessinTechnique(t) : null}
        <StyledPaperBodyEC elevation={0}>
          <StyledDivRecapitulatifEC>
            <StyledDivRecapInt>
              <StyledDivFiligranneRecapEC
                style={{
                  backgroundImage:
                    "url(" +
                    importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                    ")",
                  zIndex: "0",
                }}
                className={styles.logoFiligraneEC}
              />
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                {!iframe ? afficherDessinTechnique(t) : null}
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titleReferences}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {formulaire?.["infosArticleEC"]?.[0][
                      "articleReferenceClient"
                    ] === "" ? null : (
                      <div>
                        {t.titleCodeClient} :{" "}
                        <span>
                          {
                            formulaire?.["infosArticleEC"]?.[0][
                              "articleReferenceClient"
                            ]
                          }
                        </span>
                      </div>
                    )}
                    <div>
                      {t.codeRCMP} :{" "}
                      <span>
                        {formulaire?.["infosArticleEC"]?.[0]["articleCode"]}
                      </span>
                    </div>

                    <div>
                      {t.titleNomArticle} :{" "}
                      <span>
                        {
                          formulaire?.["infosArticleEC"]?.[0][
                            "articleDesignation"
                          ]
                        }
                      </span>
                    </div>

                    <div>
                      {t.titleFamille} :{" "}
                      <span>
                        {arrayProductFamilly(t).find(
                          (e) =>
                            e.code ===
                            formulaire?.["infosArticleEC"]?.[0][
                              "familleCode"
                            ].substring(0, 3)
                        )?.name ??
                          formulaire?.["infosArticleEC"]?.[0][
                            "familleCode"
                          ].substring(0, 3)}
                      </span>
                    </div>
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreConstruction}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireConstruction(formulaire, t),
                      formulaire,
                      t,
                      null,
                      from
                    )}
                    {genererFormulaire(
                      formulaireConstructionSection(t),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 8)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowRecap>

              <StyledDivSectionsRowReverseRecap style={{ zIndex: "2" }}>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.descrArticle}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC
                    dangerouslySetInnerHTML={{
                      __html:
                        formulaire?.["infosArticleEC"]?.[0][
                          "articleDescription"
                        ],
                    }}
                  />
                </StyledDivSectionRecapEC>

                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreDimensionsCorps}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireDimensionsCorps(t),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 3)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowReverseRecap>

              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreDimensionsExtremites}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  {genererFormulaire(
                    formulaireExtremitesIdentiques(t),
                    formulaire,
                    t,
                    booleanIdentite,
                    [OFFRE_DE_PRIX, ARTICLE].includes(isFrom)
                  )}
                  <StyledDivRecapGeneEC>
                    {afficherExtremite(t, formulaire)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>

                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreEnvironnement}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireAggressions(t, upToDateAgressionsList),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 2)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowRecap>

              <StyledDivSectionsRowReverseRecap style={{ zIndex: "2" }}>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreInformationsMachine}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireInformationsMachine(
                        t,
                        upToDateTypeMachineList,
                        upToDateMarqueMachineList
                      ),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 1)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>

                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreAccessoires}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  {genererFormulaire(
                    formulaireAccessoiresS04(t),
                    formulaire,
                    t,
                    null,
                    from
                  )}
                  {genererChampRecapitulatifHorsConfigurateur(formulaire, 11)}
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowReverseRecap>
            </StyledDivRecapInt>
          </StyledDivRecapitulatifEC>
        </StyledPaperBodyEC>
      </>
    );
  };

  return (
    <div name={nom} style={{ padding: "1rem" }}>
      {afficherBoutonRetour(
        t.retourListeArticles,
        triggerHandleRetour,
        "#F8F9FF"
      )}
      {formulaire ? (
        <div>
          <div>
            <StyledH1VuesEC>
              {t.detailsArticle} :{" "}
              {arrayProductFamilly(t).find(
                (e) =>
                  e.code ===
                  formulaire?.["infosArticleEC"]?.[0]["familleCode"].substring(
                    0,
                    3
                  )
              )?.name ?? formulaire["designation"]}
            </StyledH1VuesEC>
            <StyledHrEspaceClient></StyledHrEspaceClient>
          </div>

          {isOngletDesktopFormat
            ? afficherOnglets(
                t,
                formulaire,
                selectedTab,
                setSelectedTab,
                afficherRecap()
              )
            : afficherOngletsMobile(
                t,
                formulaire,
                selectedTab,
                setSelectedTab,
                afficherRecap()
              )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};
