import * as Yup from "yup";
import {
  AUTRE,
  CODE_ARTICLE,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  NUMERO_DE_PLAN,
  POSITION_DE_TRAVAIL,
  TYPE_DE_MACHINE,
  VITESSE_DEPLACEMENT,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  arrayPositionTravail,
  arrayPositionTravailS01,
  arrayProductFamilly2_4_5,
} from "../../../../../constantes/symbols/SymbolsServicesCommon";

/* A Yup validation schema. */
export const validInformationsMachine = (formikValues, t, etape) => {
  if (etape !== 2) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    // [TYPE_DE_MACHINE.description]: Yup.mixed().test(
    //   "TypeMachineInList",
    //   () => {
    //     return t.selectionFonction;
    //   },
    //   (selectedValue) => {
    //     if (selectedValue === null) {
    //       return true;
    //     }
    //     return selectedValue
    //       ? typeMachineList
    //           .map((value) => value.name)
    //           .includes(selectedValue.name)
    //       : false;
    //   }
    // ),
    // [MARQUE_MACHINE.description]: Yup.mixed().test(
    //   "MarqueMachineInList",
    //   () => {
    //     return t.selectionFonction;
    //   },
    //   (selectedValue) => {
    //     if (selectedValue === null) {
    //       return true;
    //     }
    //     return selectedValue
    //       ? marqueMachineList
    //           .map((value) => value.name)
    //           .includes(selectedValue.name)
    //       : false;
    //   }
    // ),
    [TYPE_DE_MACHINE.description]: Yup.string().optional(),
    [MARQUE_MACHINE.description]: Yup.string().optional(),
    [AUTRE.description]: Yup.string().optional(),
    [MODELE_MACHINE.description]: Yup.string().optional(),
    [POSITION_DE_TRAVAIL.description]: Yup.mixed().when({
      is: () =>
        arrayProductFamilly2_4_5(t).includes(
          formikValues[FAMILLE_PROTECTION_INDUSTRIELLE.description]
        ),
      then: Yup.mixed()
        .test(
          "RadioButtonPositionDeTravail",
          () => {
            return t.erreurSelectionPlanDeTravail;
          },
          (value) =>
            arrayPositionTravail(t).some((e) => e.code === parseInt(value))
        )
        .required(t.erreurSelectionPlanDeTravail),
      otherwise: Yup.mixed()
        .test(
          "RadioButtonPositionDeTravail",
          () => {
            return t.erreurSelectionPlanDeTravail;
          },
          (value) =>
            arrayPositionTravailS01(t).some((e) => e.code === parseInt(value))
        )
        .required(t.erreurSelectionPlanDeTravail),
    }),

    [NUMERO_DE_PLAN.description]: Yup.string().optional(),
    [CODE_ARTICLE.description]: Yup.string().optional(),
    [VITESSE_DEPLACEMENT.description]: Yup.mixed().when({
      is: () =>
        arrayProductFamilly2_4_5(t).includes(
          formikValues[FAMILLE_PROTECTION_INDUSTRIELLE.description]
        ),
      then: Yup.number()
        .positive()
        .min(1, t.quantiteSupA0)
        .required(t.renseignerChamp),
      otherwise: Yup.mixed().optional(),
    }),
  });
};
