import { createTheme } from "@mui/material";

export const ThemeAutocomplete = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          backgroundColor: "rgb(255,255,255,1) !important",

          // '&[aria-selected="true"]': {
          //   backgroundColor: "rgb(255,255,255,1)",
          // },
          '&[aria-selected="true"].Mui-focused': {
            backgroundColor: "rgb(235, 235, 235,1) !important",
          },
        },
      },
    },
  },
});
