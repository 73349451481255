import { styled } from "@mui/system";

export const StyledImgHeader = styled('img')({
    width: '20%',
    maxHeight: '20%',
});

export const StyledImgHeaderLarge = styled('img')({
    width: '40%',
    maxHeight: '40%',
});

export const StyledImgConfirmHaut = styled('img')({
    position: 'absolute',
    right: '0',
    top: '107.9px',
    transform: 'rotate(180deg)',
    width: '25%',
    maxWidth: '389px',
    '@media (max-width: 600px)': {
        display:'none',
    },
});

export const StyledImgConfirmBas = styled('img')({
    position: 'absolute',
    left: '0',
    bottom: '75.2px',
    width: '25%',
    maxWidth: '389px',
    '@media (max-width: 600px)': {
        display:'none',
    },
});