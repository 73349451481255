import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const StyledGridItem = styled(Grid)({
  fontFamily: "Cabin",
  fontWeight: "600",
  fontSize: "1.1em",
  color: "#2A375C",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  columnGap: "0.5em",
  borderRight: "1.5px solid white",
  height: "3.2em",
  width: "22%",
  "&.colAction": {
    width: "10%",
    borderRight: "none",
  },
  "&.colMail": {
    wordBreak: "break-all",
    width: "24%",
    padding: "0 0.5em",
  },
  "@media (max-width: 900px)": {
    fontSize: "0.9em",
    columnGap: "0",
  },
  "@media (max-width: 650px)": {
    width: "60%",
    fontSize: "1.1em",
    "&.colAction": {
      width: "40%!important",
      columnGap: "1em",
    },
    "&.colNonEssentielle": {
      display: "none",
    },
    "&.colNum": {
      width: "60%!important",
    },
  },

  "&.itemDdp": {
    flexWrap: "wrap",
    rowGap: "0.3em",
    width: "10%",
    height: "auto",
    padding: "0.5em",
    ".textCol": {
      width: "80%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      height: "50%",
    },
    "&.colEntite": {
      width: "15%",
      ".textCol": {
        width: "70%",
      },
    },
    "&.colNum": {
      width: "17%",
      wordBreak: "break-all",
      textAlign: "center",
    },
    "&.colAction": {
      width: "9%",
      borderRight: "none",
    },
    "@media (max-width: 1100px)": {
      fontSize: "0.9em",
      columnGap: "0",
    },
  },
});

export const StyledGridLignesTab = styled(Grid)(({ theme }) => ({
  fontFamily: "Cabin",
  fontWeight: "600",
  fontSize: "0.9em",
  color: "#2A375C",
  wordBreak: "break-all",
  ".isActionHeader": {
    marginRight: "-0.75rem",
    right: "1rem",
    position: "relative",
  },
  ".hasSearch": {
    display: "flex !important",
    justifyContent: "flex-start",
  },
  ".MuiGrid-root": {
    padding: "0.5em",
    display: "flex",
    justifyContent: "center",
  },
  ".row0": {
    backgroundColor: "#2A375C",
  },
  // ".br-top-8": {
  //   backgroundColor: "#2A375C",
  // },
  ".row:nth-of-type(odd)": {
    backgroundColor: "white",
    ".hasBorderRight": {
      borderRight: "1.5px solid #E7EAFF", // Gris clair pour les lignes impaires
      height: "100%",
    },
  },
  ".row:nth-of-type(even)": {
    backgroundColor: "#F8F9FF",
  },
  ".hasBorderRight": {
    borderRight: "1.5px solid white",
    height: "100%",
  },
  ".columnHeader": {
    gridTemplateColumns: "repeat(2, 1fr)",
    fontWeight: "600",
    color: "white",
    fontFamily: "Dosis",
    fontSize: "1em",
    backgroundColor: "#2A375C!important",
    textTransform: "uppercase",
    wordBreak: "break-word!important",
    //Tablette 600 - 900
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.8em",
      columnGap: "0",
      "&.retirerFormatTablette": {
        display: "none!important",
      },
    },
    //Mobile 0 - 600
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
      "&.retirerFormatMobile": {
        display: "none!important",
      },
    },
  },
  ".columnHeaderEC": {
    gridTemplateColumns: "repeat(2, 1fr)",
    fontFamily: "Dosis",
    fontSize: "1.15rem",
    fontStyle: "semi-bold",
    fontWeight: "600",
    lineHeight: "120%",
    color: "white",
    backgroundColor: "#2A375C!important",
    wordBreak: "break-word!important",
    //Tablette 600 - 900
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.05em",
      columnGap: "0",
      "&.retirerFormatTablette": {
        display: "none!important",
      },
    },
    //Mobile 0 - 600
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.35em",
      "&.retirerFormatMobile": {
        display: "none!important",
      },
    },
  },
  ".MuiGrid-item.itemDdp": {
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    //Tablette 600 - 900
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.8em",
      columnGap: "0",
      "&.retirerFormatTablette": {
        display: "none!important",
      },
    },
    //Mobile 0 - 600
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
      "&.retirerFormatMobile": {
        display: "none!important",
      },
    },
  },
}));

export const StyledGridLignesTabOrangeEC = styled(Grid)(({ theme }) => ({
  fontFamily: "Cabin",
  fontWeight: "600",
  fontSize: "0.9em",
  color: "#2A375C",
  wordBreak: "break-all",
  ".isActionHeader": {
    marginRight: "-0.75rem",
    right: "1rem",
    position: "relative",
  },
  ".hasSearch": {
    display: "flex !important",
    justifyContent: "flex-start",
  },
  ".MuiGrid-root": {
    padding: "0.5em",
    display: "flex",
    justifyContent: "center",
  },
  ".row0": {
    backgroundColor: "#2A375C",
  },
  // ".br-top-8": {
  //   backgroundColor: "#2A375C",
  // },
  ".hrDdp": {
    border: "1.5px solid white",
  },
  ".row:nth-of-type(odd)": {
    backgroundColor: "white",
    ".hasBorderRight": {
      borderRight: "1.5px solid #E7EAFF", // Gris clair pour les lignes impaires
      height: "100%",
    },
    ".hrDdp": {
      border: "1.5px solid #E7EAFF",
    },
  },
  ".row:nth-of-type(even)": {
    backgroundColor: "#EC88124D",
  },
  ".hasBorderRight": {
    borderRight: "1.5px solid white",
    height: "100%",
  },
  ".columnHeader": {
    gridTemplateColumns: "repeat(2, 1fr)",
    fontWeight: "600",
    color: "white",
    fontFamily: "Dosis",
    fontSize: "1em",
    backgroundColor: "#2A375C!important",
    textTransform: "uppercase",
    wordBreak: "break-word!important",
    //Tablette 600 - 900
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.8em",
      columnGap: "0",
      "&.retirerFormatTablette": {
        display: "none!important",
      },
    },
    //Mobile 0 - 600
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
      "&.retirerFormatMobile": {
        display: "none!important",
      },
    },
  },
  ".columnHeaderEC": {
    gridTemplateColumns: "repeat(2, 1fr)",
    fontFamily: "Dosis",
    fontSize: "1.15rem",
    fontStyle: "semi-bold",
    fontWeight: "600",
    lineHeight: "120%",
    color: "white",
    backgroundColor: "#2A375C!important",
    wordBreak: "break-word!important",
    //Tablette 600 - 900
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.05em",
      columnGap: "0",
      "&.retirerFormatTablette": {
        display: "none!important",
      },
    },
    //Mobile 0 - 600
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.35em",
      "&.retirerFormatMobile": {
        display: "none!important",
      },
    },
  },
  ".MuiGrid-item.itemDdp": {
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    //Tablette 600 - 900
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.8em",
      columnGap: "0",
      "&.retirerFormatTablette": {
        display: "none!important",
      },
    },
    //Mobile 0 - 600
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
      "&.retirerFormatMobile": {
        display: "none!important",
      },
    },
  },
}));

export const StyledGridLignesTabRoseEC = styled(Grid)(({ theme }) => ({
  fontFamily: "Cabin",
  fontWeight: "600",
  fontSize: "0.9em",
  color: "#2A375C",
  wordBreak: "break-all",
  ".isActionHeader": {
    marginRight: "-0.75rem",
    right: "1rem",
    position: "relative",
  },
  ".hasSearch": {
    display: "flex !important",
    justifyContent: "flex-start",
  },
  ".MuiGrid-root": {
    padding: "0.5em",
    display: "flex",
    justifyContent: "center",
  },
  ".row0": {
    backgroundColor: "#2A375C",
  },
  // ".br-top-8": {
  //   backgroundColor: "#2A375C",
  // },
  ".hrDdp": {
    border: "1.5px solid white",
  },
  ".row:nth-of-type(odd)": {
    backgroundColor: "white",
    ".hasBorderRight": {
      borderRight: "1.5px solid #E7EAFF", // Gris clair pour les lignes impaires
      height: "100%",
    },
    ".hrDdp": {
      border: "1.5px solid #E7EAFF",
    },
  },
  ".row:nth-of-type(even)": {
    backgroundColor: "#CC007B40",
  },
  ".hasBorderRight": {
    borderRight: "1.5px solid white",
    height: "100%",
  },
  ".columnHeader": {
    gridTemplateColumns: "repeat(2, 1fr)",
    fontWeight: "600",
    color: "white",
    fontFamily: "Dosis",
    fontSize: "1em",
    backgroundColor: "#2A375C!important",
    textTransform: "uppercase",
    wordBreak: "break-word!important",
    //Tablette 600 - 900
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.8em",
      columnGap: "0",
      "&.retirerFormatTablette": {
        display: "none!important",
      },
    },
    //Mobile 0 - 600
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
      "&.retirerFormatMobile": {
        display: "none!important",
      },
    },
  },
  ".columnHeaderEC": {
    gridTemplateColumns: "repeat(2, 1fr)",
    fontFamily: "Dosis",
    fontSize: "1.15rem",
    fontStyle: "semi-bold",
    fontWeight: "600",
    lineHeight: "120%",
    color: "white",
    backgroundColor: "#2A375C!important",
    wordBreak: "break-word!important",
    //Tablette 600 - 900
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.05em",
      columnGap: "0",
      "&.retirerFormatTablette": {
        display: "none!important",
      },
    },
    //Mobile 0 - 600
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.35em",
      "&.retirerFormatMobile": {
        display: "none!important",
      },
    },
  },
  ".MuiGrid-item.itemDdp": {
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    //Tablette 600 - 900
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.8em",
      columnGap: "0",
      "&.retirerFormatTablette": {
        display: "none!important",
      },
    },
    //Mobile 0 - 600
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
      "&.retirerFormatMobile": {
        display: "none!important",
      },
    },
  },
}));

export const StyledGridEnteteDdp = styled(Grid)({
  fontWeight: "600",
  color: "white",
  fontFamily: "Dosis",
  fontSize: "1.2em",
  backgroundColor: "#2A375C!important",
  textTransform: "uppercase",
  wordBreak: "normal!important",
  "@media (max-width: 1100px)": {
    fontSize: "1em",
  },
  "@media (max-width: 650px)": {
    fontSize: "1.2em",
    width: "60%",
    "&.colAction": {
      width: "40%!important",
      columnGap: "1em",
    },
    "&.colNonEssentielle": {
      display: "none",
    },
    "&.colNum": {
      width: "60%!important",
    },
  },
});

export const StyledGridLine = styled(Grid)({
  display: "flex",
  flexDirection: "row",
});

export const StyledGridTab = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

export const StyledDivChampsModifier = styled(Grid)({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "800px",
  rowGap: "2em",
  "@media (max-width: 600px)": {
    justifyContent: "center",
  },
});
