import {
  FAMILLE_PROTECTION_INDUSTRIELLE,
  AXE_EMPLACEMENT,
  CODE_ARTICLE,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  NUMERO_DE_PLAN,
  POSITION_DE_TRAVAIL,
  TYPE_DE_MACHINE,
  AGRESSIONS,
  ENVIRONNEMENT,
  TEMPERATURE,
  COMMENTAIRE,
  QUANTITEE_SOUHAITEE,
  ADRESSE_MAIL,
  CODE_POSTAL,
  FONCTION,
  NOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
  PRENOM,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  LONGUEUR_COMPRIMEE,
  LONGUEUR_DEPLOYEE,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
  RONDE_DROIT_EXTERIEUR_MAX,
  RONDE_DROIT_INTERIEUR_MIN,
  SECTION_SOUFFLET,
  SOUFFLET_AVEC_SPIRE,
  TYPE_CORPS,
  BRIDE_A_IT1,
  BRIDE_A_IT2,
  BRIDE_B_IT1,
  BRIDE_B_IT2,
  BRIDE_DIAMETRE_IT1,
  BRIDE_DIAMETRE_IT2,
  BRIDE_IT1,
  BRIDE_IT2,
  BRIDE_PERCEE_IT1,
  BRIDE_PERCEE_IT2,
  EXTREMITE_IDENTIQUE,
  FIXATION_EXTREMITES_IT1,
  FIXATION_EXTREMITES_IT2,
  MANCHETTE_A_IT1,
  MANCHETTE_A_IT2,
  MANCHETTE_B_IT1,
  MANCHETTE_B_IT2,
  MANCHETTE_DIAMETRE_IT1,
  MANCHETTE_DIAMETRE_IT2,
  MANCHETTE_IT1,
  MANCHETTE_IT2,
  MANCHETTE_LARGEUR_IT1,
  MANCHETTE_LARGEUR_IT2,
  TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT2,
  ACCESSOIRES,
  FERMETURE_GLISSIERE,
  OEILLET_FILTRE,
  BAGUE_GUIDAGE,
} from "../../../../../constantes/symbols/SymbolsS01";
import { RECAP } from "../../../components/generateFormulary/types/TypeFieldsFormulary";
export const FICHIERS = Symbol("AfficherFichiers");
export const FICHIER_RECAPITULATIF = Symbol("AfficherFichierRécapitulatif");

export const formulaireRecapitulatifTypeProtection = (t) => [
  {
    nom: FAMILLE_PROTECTION_INDUSTRIELLE,
    label: t.champTypeDeProtection,
    type: RECAP,
  },
];

export const formulaireDimensionsCorps = (t) => [
  {
    nom: SOUFFLET_AVEC_SPIRE,
    label: t.champSouffletAvecSpire,
    type: RECAP,
  },
  {
    nom: LONGUEUR_DEPLOYEE,
    label: t.champLongueurDeployee,
    type: RECAP,
  },
  {
    nom: LONGUEUR_COMPRIMEE,
    label: t.champLongueurComprimee,
    type: RECAP,
  },
  {
    nom: SECTION_SOUFFLET,
    label: t.champSectionSoufflet,
    type: RECAP,
  },
  {
    nom: TYPE_CORPS,
    label: t.champTypeDeCorps,
    type: RECAP,
  },
  {
    nom: RONDE_DROIT_INTERIEUR_MIN,
    label: t.champRondeDroitInterieurMin,
    type: RECAP,
  },
  {
    nom: RONDE_DROIT_EXTERIEUR_MAX,
    label: t.champRondeDroitExterieurMax,
    type: RECAP,
  },
  {
    nom: RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
    label: t.champInterieurGauche,
    type: RECAP,
  },
  {
    nom: RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
    label: t.champExterieurGauche,
    type: RECAP,
  },
  {
    nom: RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
    label: t.champInterieurDroit,
    type: RECAP,
  },
  {
    nom: RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
    label: t.champExterieurDroit,
    type: RECAP,
  },
  {
    nom: RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
    label: t.champRectangulaireDroitInterieurA,
    type: RECAP,
  },
  {
    nom: RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
    label: t.champRectangulaireDroitInterieurB,
    type: RECAP,
  },
  {
    nom: RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
    label: t.champRectangulaireDroitExterieurA,
    type: RECAP,
  },
  {
    nom: RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
    label: t.champRectangulaireDroitExterieurB,
    type: RECAP,
  },
  {
    nom: RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
    label: t.champRectangulaireConique,
    type: RECAP,
  },
];
export const formulaireExtremitesIdentiques = (t) => [
  {
    nom: EXTREMITE_IDENTIQUE,
    label: t.champExtremitesIdentiques,
    type: RECAP,
  },
];

export const formulaireExtremiteDroite = (t) => [
  {
    nom: TYPE_EXTREMITES_IT1,
    label: t.champTypeExtremite,
    type: RECAP,
  },
  {
    nom: MANCHETTE_IT1,
    label: t.champManchetteSection,
    type: RECAP,
  },
  {
    nom: MANCHETTE_LARGEUR_IT1,
    label: t.champManchetteLargeur,
    type: RECAP,
  },
  {
    nom: MANCHETTE_DIAMETRE_IT1,
    label: t.champManchetteDiametre,
    type: RECAP,
  },
  {
    nom: MANCHETTE_A_IT1,
    label: t.champManchetteA,
    type: RECAP,
  },
  {
    nom: MANCHETTE_B_IT1,
    label: t.champManchetteB,
    type: RECAP,
  },
  {
    nom: BRIDE_IT1,
    label: t.champBrideSection,
    type: RECAP,
  },
  {
    nom: BRIDE_DIAMETRE_IT1,
    label: t.champBrideDiametre,
    type: RECAP,
  },
  {
    nom: BRIDE_A_IT1,
    label: t.champBrideA,
    type: RECAP,
  },
  {
    nom: BRIDE_B_IT1,
    label: t.champBrideB,
    type: RECAP,
  },
  {
    nom: BRIDE_PERCEE_IT1,
    label: t.champBridePercee,
    type: RECAP,
  },
  {
    nom: FIXATION_EXTREMITES_IT1,
    label: t.champFixationExtremite,
    type: RECAP,
  },
];

export const formulaireExtremiteGauche = (t) => [
  {
    nom: TYPE_EXTREMITES_IT2,
    label: t.champTypeExtremite,
    type: RECAP,
  },
  {
    nom: MANCHETTE_IT2,
    label: t.champManchetteSection,
    type: RECAP,
  },
  {
    nom: MANCHETTE_LARGEUR_IT2,
    label: t.champManchetteLargeur,
    type: RECAP,
  },
  {
    nom: MANCHETTE_DIAMETRE_IT2,
    label: t.champManchetteDiametre,
    type: RECAP,
  },
  {
    nom: MANCHETTE_A_IT2,
    label: t.champManchetteA,
    type: RECAP,
  },
  {
    nom: MANCHETTE_B_IT2,
    label: t.champManchetteB,
    type: RECAP,
  },
  {
    nom: BRIDE_IT2,
    label: t.champBrideSection,
    type: RECAP,
  },
  {
    nom: BRIDE_DIAMETRE_IT2,
    label: t.champBrideDiametre,
    type: RECAP,
  },
  {
    nom: BRIDE_A_IT2,
    label: t.champBrideA,
    type: RECAP,
  },
  {
    nom: BRIDE_B_IT2,
    label: t.champBrideB,
    type: RECAP,
  },
  {
    nom: BRIDE_PERCEE_IT2,
    label: t.champBridePercee,
    type: RECAP,
  },
  {
    nom: FIXATION_EXTREMITES_IT2,
    label: t.champFixationExtremite,
    type: RECAP,
  },
];

export const formulaireAccessoires = (t) => [
  {
    nom: FERMETURE_GLISSIERE,
    label: t.champfermetureGlissiere,
    type: RECAP,
  },
  {
    nom: OEILLET_FILTRE,
    label: t.champOeilletFiltre,
    type: RECAP,
  },
  {
    nom: BAGUE_GUIDAGE,
    label: t.champBagueDeGuidage,
    type: RECAP,
  },
];

export const formulaireInformationsMachine = (
  t,
  serviceTypeMachine,
  serviceMarqueMachine
) => [
  {
    nom: TYPE_DE_MACHINE,
    label: t.champTypeDeMachine,
    type: RECAP,
    service: serviceTypeMachine,
  },
  {
    nom: MARQUE_MACHINE,
    label: t.champMarqueDeMachine,
    type: RECAP,
    service: serviceMarqueMachine,
  },
  {
    nom: MODELE_MACHINE,
    label: t.champModeleDeMachine,
    type: RECAP,
  },
  {
    nom: AXE_EMPLACEMENT,
    label: t.champAxeEmplacement,
    type: RECAP,
  },
  {
    nom: POSITION_DE_TRAVAIL,
    label: t.champPositionDeTravail,
    type: RECAP,
  },
  {
    nom: NUMERO_DE_PLAN,
    label: t.champNDePlan,
    type: RECAP,
  },
  {
    nom: CODE_ARTICLE,
    label: t.champCodeArticle,
    type: RECAP,
  },
];

export const formulaireAggressions = (t, agressionsList) => [
  {
    nom: TEMPERATURE,
    label: t.champTemperature,
    type: RECAP,
  },
  {
    nom: ENVIRONNEMENT,
    label: t.champEnvironnement,
    type: RECAP,
    nomChamps: agressionsList,
  },
];

export const formulaireQuantités = () => [
  {
    nom: QUANTITEE_SOUHAITEE,
    type: RECAP,
  },
];

export const formulaireCoordonnees = (
  t,
  servicePays,
  serviceSecteur,
  serviceFonction
) => [
  {
    nom: SOCIETE,
    label: t.champSociete,
    type: RECAP,
  },
  {
    nom: PAYS,
    label: t.champPays,
    type: RECAP,
    service: servicePays,
  },
  {
    nom: CODE_POSTAL,
    label: t.champCodePostal,
    type: RECAP,
  },
  {
    nom: SECTEUR,
    label: t.champSecteurActivite,
    type: RECAP,
    service: serviceSecteur,
  },
  {
    nom: NOM,
    label: t.champNom,
    type: RECAP,
  },
  {
    nom: PRENOM,
    label: t.champPrenom,
    type: RECAP,
  },
  {
    nom: FONCTION,
    label: t.champFonction,
    type: RECAP,
    service: serviceFonction,
  },
  {
    nom: ADRESSE_MAIL,
    label: t.champAdresseMail,
    type: RECAP,
  },
  {
    nom: TELEPHONE,
    label: t.champTelephone,
    type: RECAP,
  },
];

export const formulaireCommentaire = (t, booleanIdentite) => [
  {
    nom: COMMENTAIRE,
    label: t.champCommentaire,
    type: RECAP,
  },
];

export const formulaireFichiers = () => [
  {
    nom: FICHIERS,
    type: RECAP,
  },
];

export const formulaireFichiersRecapitulatif = () => [
  {
    nom: FICHIER_RECAPITULATIF,
    type: RECAP,
  },
];
