import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { HeaderInternaute } from "../../redux/vue/HeaderInternaute";
import { FooterInternaute } from "../../redux/vue/FooterInternaute";
import { GetStyle } from "../../helper/Styles";
import { StyledCircularProgressLoading } from "../../redux/vue/styledComponents/StyledIcon";
import { StyledDivLoading } from "../../redux/vue/styledComponents/StyledDiv";
import { useTranslations } from "../../redux/vue/components/hook/useTranslations";
import { Sidebar } from "./SideBar";
import { HeaderEspaceClient } from "../../redux/vue/HeaderEspaceClient";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../redux/model/slice/MessageReducer";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import {
  rafraichissement,
  rafraichissementEC,
} from "../../redux/model/slice/AuthentificationReducer";
import { ClientRoutes } from "../../constantes/routes/ClientRoutes";

export const ClientWrapper = ({ setTrackingId }) => {
  const [upToDateArticleList, setUpToDateArticleList] = useState([]);
  const [isMobileFormat, setIsMobileFormat] = useState(
    window.innerWidth >= 768
  );

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const navigate = useNavigate();
  const [iframe, setIframe] = useState();

  const dispatch = useDispatch();
  const { t, init } = useTranslations();

  const styles = GetStyle(false);
  const { accessTokenEC } = useSelector((state) => state.authentification);
  const [upToDateArticleByIdList, setUpToDateArticleByIdList] = useState();
  const [upToDateOffreByIdList, setUpToDateOffreByIdList] = useState();
  const [isOffreByIdSet, setIsOffreByIdSet] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);

  const setIdentite = () => {
    const url = window.location.href;
    const regex = new RegExp("moovp*");
    const site = regex.test(url);
    const title = site === true ? "Moovprotect" : "RC Modèles";
    document.title = title + " | Demande de prix";
    return site;
  };

  const [booleanIdentite, setBooleanIdentite] = useState(setIdentite());
  const [isPdfInCreation, setIsPdfInCreation] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("accessTokenEC") === null ||
      localStorage.getItem("accessTokenEC") === undefined
    ) {
      dispatch(setMessage(t.expiredSession));
      navigate("EspaceClient/" + ClientRoutes.AUTHENTIFICATION);
    }
  }, []);

  useEffect(() => {
    dispatch(rafraichissementEC());
    dispatch(setMessage(null));
    init();
    setIframe(query.get("iframe"));
  }, [navigate]);

  useEffect(() => {
    setTrackingId(booleanIdentite === true ? "G-HMK14DHSCQ" : "G-YP15RLFR0V");
  }, [booleanIdentite, setTrackingId]);

  useEffect(() => {
    init();
    setIframe(query.get("iframe"));
  }, []);

  // Update sidebar visibility on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileFormat(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const stateUsedForRender = {
    booleanIdentite,
    styles,
    t,
    upToDateArticleList,
    upToDateArticleByIdList,
    upToDateOffreByIdList,
    isMobileFormat,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F9FF",
      }}
    >
      <div>
        {!iframe ? (
          <HeaderEspaceClient
            t={t}
            booleanIdentite={booleanIdentite}
            setBooleanIdentite={setBooleanIdentite}
            isMobileFormat={isMobileFormat}
            styles={styles}
          />
        ) : null}
      </div>

      <div style={{ display: "flex" } }>
        {/* Ajout de la Sidebar */}
        {isMobileFormat && (
          <Sidebar
            t={t}
            upToDateArticleByIdList={upToDateArticleByIdList}
            setUpToDateArticleByIdList={setUpToDateArticleByIdList}
            upToDateOffreByIdList={upToDateOffreByIdList}
            setUpToDateOffreByIdList={setUpToDateOffreByIdList}
            isOffreByIdSet={isOffreByIdSet}
            setIsOffreByIdSet={setIsOffreByIdSet}
            isMobileFormat={isMobileFormat}
          />
        )}

        <div
          style={{
            flex: 1,
            backgroundColor: "#F8F9FF",
          }}
        >
          <Outlet
            context={[
              styles,
              t,
              booleanIdentite,
              upToDateArticleByIdList,
              setUpToDateArticleByIdList,
              upToDateOffreByIdList,
              setUpToDateOffreByIdList,
              isMobileFormat,
            ]}
            state={stateUsedForRender}
          />
        </div>
      </div>

      <div>{!iframe ? <FooterInternaute styles={styles} /> : null}</div>
    </div>
  );
};
