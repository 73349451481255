import { styled } from "@mui/system";

//--------------------------Div Button--------------------------//

export const StyledDivRadioButtonBoxShadow = styled("div")({
  ":hover": {
    cursor: "pointer",
  },
  width: "185px",
  border: "0.7px solid #787D98",
  borderRadius: "8px",
  backgroundColor: "white",
  boxShadow: "0px 4px 4px rgba(42, 55, 92, 0.3)",
  margin: "10px",
});

export const StyledDivRadioButton = styled("div")({
  margin: "10px",
  ":hover": {
    cursor: "pointer",
  },
});

export const StyledDivRadioWithImage = styled("div")({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-around",
  gap: "1em",
  marginBottom: "1em",
  "& div:first-of-type": {
    width: "200px",
  },
  "& div:last-child": {
    width: "100px",
  },
});

export const StyledDivRadioButtonImage = styled("div")({
  textAlign: "center",
  width: "100%",
  img: {
    width: "auto",
    height: "auto",
  },
});

export const StyledDivButtonConfirm = styled("div")({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  gap: "4em",
  margin: "1em 0 0 0",
});

export const StyledDivButtonQuestionnaire = styled("div")({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  gap: "4em",
  margin: "1em 0 0 0",
});

export const StyledDivButton = styled("div")({
  marginTop: "1.5em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const StyledDivButtonToRecap = styled("div")({
  marginTop: "1.5em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 600px)": {
    flexDirection: "column-reverse",
  },
});

//----------------------Div Container Form----------------------//

export const StyledDivFooter = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
});

export const StyledDivFormContainer = styled("div")({
  backgroundColor: "white",
  width: "35%",
  padding: "0 2.5em 2em 2.5em",
  borderRight: "5px solid #E7EAFF",
  "@media (min-width: 1600px)": {
    width: "30%",
  },
  "@media (max-width: 992px)": {
    width: "40%",
  },
  "@media (max-width: 600px)": {
    width: "100%",
    padding: "2em 1.5em 1.5em 1.5em",
    boxSizing: "border-box",
    borderRight: "0px",
  },
});

export const StyledDivFormAndImageContainer = styled("div")({
  display: "flex",
  flexWrap: "nowrap",
  "@media (max-width: 600px)": {
    flexDirection: "column",
  },
});

export const StyledDivHome = styled("div")({
  width: "25em",
  backgroundColor: "white",
  boxShadow: "0.375em 0.375em 0.25em rgba(42, 55, 92, 0.3)",
  borderRadius: "8px",
  padding: "0.5em 2em 2em 2em",
  margin: "3.5em 6em",
  "@media (min-width: 1600px)": {
    padding: "2em 2em",
    margin: "3.5em 10em",
  },
  "@media (max-width: 992px)": {
    margin: "3em 2em",
    width: "22em",
  },
  "@media (max-width: 600px)": {
    width: "100%",
    margin: "0",
    borderRadius: "0",
    position: "relative",
    boxSizing: "border-box",
    padding: "2em 1.5em",
  },
});

export const StyledDivRecapitulatif = styled("div")({
  fontFamily: "Dosis",
  color: "#2A375C",
  lineHeight: "2em",
  backgroundColor: "white",
  width: "85%",
  margin: "auto",
  padding: "3em",
  boxSizing: "border-box",
  "@media (max-width: 600px)": {
    padding: "1em 0",
  },
});

export const StyledDivAlignItemsHorizontally = styled("div")({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
});

export const StyledDivAccueilParent = styled("div")({
  width: "100%",
  position: "absolute",
  "@media (max-width: 580px)": {
    position: "static",
  },
});

export const StyledDivConfirm = styled("div")({
  paddingBottom: "3em",
});

export const StyledDivLoading = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: "0",
  left: "0",
  bottom: "0",
  pointerEvents: "none",
  zIndex: "999",
});

export const StyledDivFormAndSOS = styled("div")({
  display: "flex",
  alignItems: "start",
  gap: "2em",
  "& p, span": {
    fontFamily: "Dosis",
    textTransform: "none",
    color: "#fff",
    width: "100%",
    textAlign: "left",
    fontSize: "1.2em",
    fontWeight: "500",
    lineHeight: "1.2",
  },
  "& span": {
    fontSize: "1.5em",
    fontWeight: "600",
  },
  "& > button": {
    backgroundColor: "#2A375C",
    textAlign: "left",
    width: "50%",
    boxShadow: "7px 5px 5px #00000029",
    flexDirection: "column",
  },
  "& > button:hover": {
    backgroundColor: "#2A375C",
  },
  "& > button > div": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  "& #collapseContent": {
    padding: "0 0 1em 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    top: "100%", // positionne le collapseContent sous le bouton principal
    left: "0", // aligne le collapseContent à gauche du bouton principal
    width: "92.7%",

    "@media (min-aspect-ratio: 16/10) and (max-height: 600px)": {
      "& p": {
        display: "none",
      },
      "& button": {
        marginTop: "1em",
      },
    },
  },

  "& #collapseContent button": {
    fontFamily: "Dosis",
    fontSize: "1.3em",
    lineHeight: "1.125em",
    fontWeight: "500",
    textTransform: "none",
  },

  "@media (max-width: 992px)": {
    flexDirection: "column",
    gap: "1em",
    "& > button": {
      width: "100%",
    },
  },
});

//-----------------------Div  Fields-----------------------//

export const StyledDivQuantiteEtCode = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  rowGap: "0",
  flexDirection: "row-reverse",
  columnGap: "10px",
  justifyContent: "center",
});

export const StyledDivFieldsQuantite = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "0",
  flexDirection: "column",
});

export const StyledDivColFieldsQuantite = styled("div")({
  padding: "0 1em",
  backgroundColor: "#F7F8FF",
  width: "80%",
  alignSelf: "center",
  display: "flex",
  gap: "0 1em",
  "@media (max-width: 992px)": {
    flexWrap: "wrap",
  },
});

export const StyledDivSubtitleExtremites = styled("div")({
  fontSize: "1.125em",
  fontWeight: "800",
  width: "60%",
  borderTop: "2px solid",
  padding: "0.4em 0 0 0.7em",
  margin: "2em 0 0 2em",
});

export const StyledDivDropzone = styled("div")({
  backgroundColor: "white",
  border: "2px dashed #2A375C",
  borderRadius: "5px",
  margin: "1em 0 -1.5em 0",
  "& textarea": {
    marginLeft: "1em",
    color: "#2A375C",
    fontSize: "1em",
    fontWeight: "600",
  },
  "& textarea.Mui-disabled": {
    WebkitTextFillColor: "#2A375C",
  },
});

export const StyledDivTextAndTextfieldAlign = styled("div")({
  display: "flex",
  "& .MuiTextField-root": {
    margin: "0 0 1em 0",
  },
});

export const StyledDivCheckboxBoxShadow = styled("div")({
  ":hover": {
    cursor: "pointer",
  },
  width: "185px",
  border: "0.7px solid #787D98",
  borderRadius: "8px",
  backgroundColor: "white",
  boxShadow: "0px 4px 4px rgba(42, 55, 92, 0.3)",
  margin: "10px",
});

export const StyledDivCheckbox = styled("div")({
  width: "185px",
  border: "0.7px solid #787D98",
  borderRadius: "8px",
  backgroundColor: "white",
  margin: "10px",
  ":hover": {
    cursor: "pointer",
  },
});

export const StyledDivCheckboxImage = styled("div")({
  textAlign: "center",
  width: "100%",
  img: {
    width: "100%",
    height: "100%",
  },
});

export const StyledDivImageLabel = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
  backgroundColor: "white",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  fontSize: "0.9em",
  padding: "0.1em 0",
  textAlign: "center",
  color: "#2A375C",
});

export const StyledDivTitleChamp = styled("div")({
  color: "#2A375C",
  backgroundColor: "white",
  padding: "0",
  margin: "0.5em",
  fontSize: "1.125em",
  fontWeight: "600",
  "&.Mui-checked": {
    color: "#2A375C",
  },
});

export const StyledDivLabelQuestion = styled("div")({
  fontSize: "1.125em",
  fontWeight: "600",
  color: "#2A375C",
  padding: "0.7em 2em 0.5em 0",
  "&.Mui-focused": {
    color: "#2A375C",
  },
  "@media (max-width: 600px)": {
    padding: "0.7em 0 0.5em 0",
  },
});

export const StyledDivLabelBgBlueColorWhite = styled("div")({
  color: "white",
  fontWeight: "600",
  textAlign: "left",
  justifyContent: "left",
  width: "100%",
  maxWidth: "250px",
  height: "auto",
  minHeight: "25px",
  margin: "1em 0",
  padding: "0.5em",
  borderRadius: "8px",
  backgroundColor: "#2A375C",
  "&:hover": {
    backgroundColor: "#2A375C",
  },
  "@media (max-width: 600px)": {
    fontSize: "1em",
    lineHeight: "1em",
    width: "100%",
  },
});

export const StyledDivLegend = styled("div")({
  fontSize: "1em",
  color: "#2A375C",
  lineHeight: "1.2",
  textAlign: "left",
  "&.Mui-focused": {
    color: "#2A375C",
  },
});

//---------------------Div Dessin/Photo---------------------//

export const StyledDivBackgroundImageEtape3 = styled("div")({
  width: "55%",
});

export const StyledDivDessin = styled("div")({
  borderRadius: "0 8px 8px 0",
  backgroundColor: "white",
  margin: "2em 0 3em 0",
  position: "relative",
  overflow: "hidden",
  width: "100%",
});

export const StyledDivBackgroundImgEnv = styled("div")({
  height: "100%",
  width: "100%",
  objectFit: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
});

export const StyledDivOverlay = styled("div")({
  cursor: "pointer",
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  borderRadius: "8px",
});

export const StyledDivFiligranne = styled("div")({
  position: "absolute",

  backgroundRepeat: "repeat",
  opacity: "5%",
  backgroundPosition: "center center",

  height: "250%",
  width: "150%",
  transform: "rotate(-45deg)",
  pointerEvents: "none",
});

export const StyledDivPhotoAccueil = styled("div")({
  float: "right",
  borderRadius: "8px",
  width: "61%",
  height: "790px",
  margin: "3em 6em",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundColor: "orange",
  "@media (min-width: 1600px)": {
    margin: "3em 10em",
  },
  "@media (max-width: 576px)": {
    display: "none",
  },
  "@media (max-width: 992px)": {
    margin: "1.5em 2em",
    height: "900px",
  },
});

export const StyledDivPhotoFirstStep = styled("div")({
  // float: "right",
  borderRadius: "8px",
  width: "100%",
  height: "650px",
  marginTop: "1em",
  marginLeft: "-2em",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundColor: "orange",
  "@media (min-width: 1600px)": {
    margin: "3em 10em",
  },
  "@media (max-width: 576px)": {
    display: "none",
  },
  "@media (max-width: 992px)": {
    margin: "1.5em 2em",
    height: "900px",
  },
});

export const StyledDivPhotoRech = styled("div")({
  width: "100%",
  margin: " 2em 0",
  height: "100%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  borderRadius: "0 8px 8px 0",
  "@media (max-width: 576px)": {
    display: "none",
  },
});

export const StyledDivDessinMobile = styled("div")({
  height: "0",
  overflow: "hidden",
  "@media (max-width: 576px)": {
    display: "contents",
  },
});
export const StyledDivBackgroundImgRech = styled("div")({
  height: "100%",
  width: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  objectFit: "cover",
});

export const StyledDivSticky = styled("div")({
  width: "55vw",
  height: "85vh",
  position: "sticky",
  margin: "5px 20px 20px 10px",
  "@media (max-width: 576px)": {
    display: "none",
  },
  "img.alone": {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    borderRadius: "0 8px 8px 0",
    margin: "5px 20px 20px -10px",
  },
  top: "10px",
  left: "0",
  borderRadius: "0 8px 8px 0",
});

/* export const  StyledDivStickyConf = styled('div')({
    width:'55vw',
    height:'70vh',
    position: 'fixed',
    margin: '5px 20px 20px 10px',
    '@media (max-width: 576px)': {
        display: 'none',
        width:'45vw',
    },
    'img.alone':{
        objectFit:'cover',
        height:'100%',
        width:'100%',
        borderRadius: '0 8px 8px 0',
    },
    top:'13vh',
    //background:'orange',
    borderRadius: '0 8px 8px 0',
}); */

export const StyledDivPhotoFamille = styled("div")({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  float: "none",
  width: "100%",
  height: "70vh",
  borderRadius: "0 8px 8px 0",
  margin: "4em 0",
});

export const StyledDivPhotoEnv = styled("div")({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  float: "none",
  width: "100%",
  height: "70vh",
  borderRadius: "0 8px 8px 0",
  margin: "4em 0",
});

export const StyledDivPhotoPasDessin = styled("div")({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  float: "none",
  width: "100%",
  height: "60vh",
  borderRadius: "0 8px 8px 0",
  margin: "0",
  "@media (max-width: 576px)": {
    width: "100%",
    height: "30vh",
    borderRadius: "8px 8px 8px 8px",
  },
});

//-----------------------Div Recapitulatif Espace Client -----------------------//
export const StyledDivSectionRecapEC = styled("div")({
  fontFamily: "Cabin",
  backgroundColor: "#E7EAFF",
  padding: "1em 1.5em",
  borderRadius: "8px",
  marginBottom: "2em",
  width: "43%",
  "@media (max-width: 992px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    padding: "0.8em 1em",
    boxSizing: "border-box",
  },
});

export const StyledDivRecapGeneEC = styled("div")({
  "div:nth-of-type(2n)": {
    backgroundColor: "#F8F9FF",
  },
});

export const StyledDivRecapitulatifEC = styled("div")({
  fontFamily: "Dosis",
  color: "#2A375C",
  lineHeight: "2em",
  backgroundColor: "white",
  width: "100%",
  margin: "auto",
  padding: "2em",
  boxSizing: "border-box",
  "@media (max-width: 600px)": {
    padding: "1em 0",
  },
});

export const StyledDivFiligranneRecapEC = styled("div")({
  position: "absolute",
  backgroundRepeat: "repeat",
  opacity: "5%",
  backgroundPosition: "center center",
  transform: "rotate(-45deg)",
  pointerEvents: "none",
});

export const StyledDivVueDetailsEC = styled("div")({
  fontFamily: "Dosis",
  color: "#2A375C",
  lineHeight: "2em",
  backgroundColor: "white",
  width: "100%",
  margin: "auto",
  padding: "1.5em",
  gap: "1em",
  boxSizing: "border-box",
  borderRadius: "8px",
  boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
});

export const StyledDivSectionVueDetailOrangeEC = styled("div")({
  backgroundColor: "white",
  paddingTop: "1em",
  borderRadius: "8px",
  width: "47%",
  "@media (max-width: 992px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    boxSizing: "border-box",
  },
});

export const StyledDivVueDetailsGeneOrangeEC = styled("div")({
  "div:nth-of-type(1n)": {
    backgroundColor: "#EC88124D",
  },
  "div:nth-of-type(2n)": {
    backgroundColor: "white",
  },
});

export const StyledDivVueDetailsGeneOrangeReverseEC = styled("div")({
  "div:nth-of-type(2n)": {
    backgroundColor: "#EC88124D",
  },
  "div:nth-of-type(3n)": {
    backgroundColor: "white",
  },
});

export const StyledDivSectionVueDetailRoseEC = styled("div")({
  backgroundColor: "white",
  paddingTop: "1em",
  borderRadius: "8px",
  marginBottom: "2em",
  width: "48%",
  "@media (max-width: 992px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    padding: "0.8em 1em",
    boxSizing: "border-box",
  },
});

export const StyledDivVueDetailsGeneRoseEC = styled("div")({
  "div:nth-of-type(1n)": {
    backgroundColor: "#CC007B40",
  },
  "div:nth-of-type(2n)": {
    backgroundColor: "white",
  },
});

export const StyledDivVueDetailsGeneRoseReverseEC = styled("div")({
  "div:nth-of-type(2n)": {
    backgroundColor: "#CC007B40",
  },
  "div:nth-of-type(3n)": {
    backgroundColor: "white",
  },
});

export const StyledDivVueDetailsGeneBasicEC = styled("div")({
  "div:nth-of-type(1n)": {
    backgroundColor: "#E7EAFF",
  },
  "div:nth-of-type(2n)": {
    backgroundColor: "white",
  },
});

export const StyledDivVueDetailsGeneBasicReverseEC = styled("div")({
  "div:nth-of-type(2n)": {
    backgroundColor: "#E7EAFF",
  },
  "div:nth-of-type(3n)": {
    backgroundColor: "white",
  },
});

//-----------------------Div FIN Recapitulatif Espace Client -----------------------//

//-----------------------Div  Recapitulatif-----------------------//

export const StyledDivSubtitlesRecap = styled("div")({
  display: "flex",
  flexWrap: "nowrap",
  cursor: "pointer",
});

export const StyledDivSectionRecap = styled("div")({
  backgroundColor: "#F8F9FF",
  padding: "1em 1.5em",
  borderRadius: "8px",
  marginBottom: "2em",
  width: "43%",
  "@media (max-width: 992px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    padding: "0.8em 1em",
    boxSizing: "border-box",
  },
});

export const StyledDivDessinRecap = styled("div")({
  padding: "0",
  borderRadius: "0",
  marginBottom: "2em",
  width: "47%",
  "@media (max-width: 992px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    padding: "0.8em 1em",
    boxSizing: "border-box",
  },
});

export const StyledDivDessinRecapIframe = styled("div")({
  borderRadius: "0",
  marginBottom: "2em",
  width: "100%",
  backgroundColor: "white",
});
export const StyledDivDessinRecapIframeScaleS01 = styled("div")({
  padding: "15%",
  marginLeft: "-2%",
});
export const StyledDivDessinRecapIframeScaleS04 = styled("div")({
  padding: "15%",
  marginLeft: "-2%",
});
export const StyledDivDessinRecapIframeScaleS05 = styled("div")({
  padding: "15%",
  marginLeft: "-2%",
});
export const StyledDivDessinRecapIframeScaleS08 = styled("div")({
  padding: "15%",
  marginLeft: "-2%",
});

export const StyledDivSectionRecapProtecType = styled("div")({
  fontSize: "1.5em",
  fontWeight: "600",
  padding: "0.5em 1em",
  marginBottom: "1.3em",
  backgroundColor: "#F8F9FF",
  borderRadius: "8px",
  width: "43%",
  "@media (max-width: 992px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    padding: "0.8em 1em",
    boxSizing: "border-box",
  },
});

export const StyledDivSectionsRowRecap = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
});

export const StyledDivSectionsRowRecapIframe = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
});

export const StyledDivSectionsRowReverseRecap = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap-reverse",
});

export const StyledDivFiligranneRecap = styled("div")({
  position: "absolute",
  backgroundRepeat: "repeat",
  opacity: "5%",
  backgroundPosition: "center center",
  transform: "rotate(-45deg)",
  pointerEvents: "none",
});

export const StyledDivRecapInt = styled("div")({
  overflow: "hidden",
  position: "relative",
  padding: "10px",
  marginTop: "2em",
});

export const StyledDivRecapExtremiteTitre = styled("div")({
  fontSize: "1em",
  textTransform: "uppercase",
  fontWeight: "bold",
});

export const StyledDivRecapGene = styled("div")({
  "div:nth-of-type(2n)": {
    backgroundColor: "#E7EAFF",
  },
});

//---------------------Div Form Section---------------------//

export const StyledDivFormMarginTitle = styled("div")({
  margin: "0",
  backgroundColor: "#F7F8FF",
  width: "80%",
  alignSelf: "center",
  padding: "0 1em",
});

export const StyledDivFormSection = styled("div")({
  backgroundColor: "#F7F8FF",
  width: "80%",
  alignSelf: "center",
  padding: "0 1em",
});
//-------------------------Div H1-------------------------//

export const StyledDivH1 = styled("div")({
  fontFamily: "Dosis",
  fontSize: "1.125em",
  fontWeight: "600",
  color: "#2A375C",
  lineHeight: "2.2em",
  paddingTop: "0.5em",
  "@media (max-width: 600px)": {
    padding: "0 0 1em 0",
  },
});

export const StyledDivH1Resume = styled("div")({
  fontFamily: "Dosis",
  fontSize: "1.125em",
  fontWeight: "600",
  color: "#2A375C",
  lineHeight: "2.2em",
  paddingTop: "0.5em",
  "@media (max-width: 600px)": {
    padding: "0 0 1em 1em",
  },
});

export const StyledDivH1Accueil = styled("div")({
  fontFamily: "Dosis",
  fontSize: "1.125em",
  fontWeight: "800",
  color: "#2A375C",
  marginBottom: "1.5em",
  lineHeight: "2.2em",
});

export const StyledDivH1ContactRecherche = styled("div")({
  fontFamily: "Dosis",
  fontSize: "1.125em",
  fontWeight: "600",
  color: "#2A375C",
  margin: "2em 0 1.5em 0",
  lineHeight: "2.2em",
  "@media (max-width: 600px)": {
    margin: "0.6em 1.5em",
  },
});

export const StyledDivUnderlineH1 = styled("div")({
  marginTop: "-1em",
  borderTop: "2px solid",
  width: "6em",
});

//---------------------Div Steps Titles---------------------//

export const StyledDivActiveStepTitle = styled("div")({
  padding: "0.1em 2.5em",
  margin: "0 -2.5em 0 -2.5em",
  "& h2": {
    fontFamily: "Dosis",
    fontWeight: "600",
    fontSize: "1.5em",
    color: "white",
  },
  "@media (max-width: 600px)": {
    margin: "-2em -1.5em 0 -1.5em",
    padding: "0.2em 1.6em",
  },
});

export const StyledDivNextStepsTitle = styled("div")({
  backgroundColor: "white",
  color: "#2A375C",
  borderTop: "1px solid #787D98",
  fontSize: "1.125em",
  padding: "1em 2.2em 1em 2.2em",
  margin: "0",
  fontFamily: "Dosis",
  fontWeight: "600",
  borderRight: "5px solid #E7EAFF",
  width: "35%",
  "@media (min-width: 1600px)": {
    width: "30%",
  },
  "@media (max-width: 992px)": {
    width: "40%",
  },
  "@media (max-width: 600px)": {
    width: "100%",
    margin: "0",
    padding: "0.7em 0em 0.7em 0",
    borderRight: "none",
  },
});

export const StyledDivPreviousStepsTitle = styled("div")({
  color: "#787D98",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  borderTop: "1px solid #787D98",
  fontSize: "1.125em",
  padding: "1em 2.2em 1em 2.2em",
  margin: "0",
  fontFamily: "Dosis",
  fontWeight: "600",
  borderRight: "5px solid #E7EAFF",
  width: "35%",
  "@media (min-width: 1600px)": {
    width: "30%",
  },
  "@media (max-width: 992px)": {
    width: "40%",
  },
  "@media (max-width: 600px)": {
    width: "100%",
    margin: "0",
    padding: "0.7em 0em 0.7em 0",
    borderRight: "none",
  },
});

//-----------------------Div Others-----------------------//

export const StyledDivLineRechercher = styled("div")({
  borderTop: "2px solid #2A375C",
  width: "9.5em",
  "@media (max-width: 600px)": {
    marginLeft: "1.7em",
  },
});

export const StyledDivMsgErreur = styled("div")({
  fontWeight: "400",
  lineHeight: "1.66",
  letterSpacing: "0.03333em",
  margin: "4px 14px 0 14px",
  fontSize: "0.75rem",
  color: "#d32f2f",
});

export const StyledDivCheckboxServicesRC = styled("div")({
  lineHeight: "1.2",
});

export const StyledDivInformationIconHtml = styled("div")({
  fontFamily: "Dosis",
});

//-----------------Div page Confirmation------------------//
export const StyledDivQuestionnaire = styled("div")(({ theme }) => ({
  display: "block",
  flexFlow: "row wrap",
  justifyContent: "center",
  gap: "4em",
  margin: "1em auto", // Centre la div horizontalement et maintient la marge supérieure

  width: "100%", // Occupe toute la largeur disponible par défaut
  maxWidth: "1600px", // Définit une largeur maximale pour les écrans plus larges

  "@media (max-width: 992px)": {
    maxWidth: "1200px", // Définit une largeur maximale pour les écrans de taille moyenne
  },
  "@media (max-width: 600px)": {
    maxWidth: "800px", // Augmente la largeur pour les petits écrans (inférieurs à 600px)
  },
}));

export const StyledSVG = styled("svg")({
  width: "40%",
  height: "auto",

  "@media (max-width: 992px)": {
    width: "60%",
  },
  "@media (max-width: 600px)": {
    width: "80%",
  },
});

// Conteneur pour les labels
export const StyledLabelContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginRight: "20px", // Espacement entre les colonnes de labels et de valeurs
});

// Conteneur pour les valeurs
export const StyledValueContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

// Conteneur pour chaque élément label-valeur
export const StyledDetailItem = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "10px", // Espacement entre les éléments
});

// Label pour chaque paire label-valeur
export const StyledLabel = styled("span")({
  fontWeight: "bold",
});

// Valeur pour chaque paire label-valeur
export const StyledValue = styled("span")({
  marginLeft: "10px", // Espacement entre le label et la valeur
});
