export const IconContactCard = () => {
  return (
    <svg
      width="85"
      height="85"
      viewBox="0 0 85 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="contacts 1" opacity="0.3" clipPath="url(#clip0_198_2639)">
        <g id="Group">
          <path
            id="Vector"
            d="M8.30078 65.7422V55.1172"
            stroke="#EC8812"
            strokeWidth="3"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="Clip path group">
            <g id="Group_2">
              <path
                id="Vector_2"
                d="M8.30078 11.9533V8.301C8.30078 4.63356 11.2739 1.6604 14.9414 1.6604H66.7381C70.4055 1.6604 73.3787 4.63356 73.3787 8.301V76.6992C73.3787 80.3668 70.4055 83.3398 66.7381 83.3398H14.9414C11.2739 83.3398 8.30078 80.3668 8.30078 76.6992V73.0471"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_3"
                d="M12.6172 19.2578H5.31249C3.29541 19.2578 1.66016 17.6226 1.66016 15.6055C1.66016 13.5884 3.29541 11.9531 5.31249 11.9531H12.6172C14.6342 11.9531 16.2695 13.5884 16.2695 15.6055C16.2695 17.6226 14.6342 19.2578 12.6172 19.2578Z"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_4"
                d="M12.6172 37.1875H5.31249C3.29541 37.1875 1.66016 35.5523 1.66016 33.5352C1.66016 31.5181 3.29541 29.8828 5.31249 29.8828H12.6172C14.6342 29.8828 16.2695 31.5181 16.2695 33.5352C16.2695 35.5523 14.6342 37.1875 12.6172 37.1875Z"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_5"
                d="M12.6172 55.1172H5.31249C3.29541 55.1172 1.66016 53.4819 1.66016 51.4649C1.66016 49.4478 3.29541 47.8125 5.31249 47.8125H12.6172C14.6342 47.8125 16.2695 49.4478 16.2695 51.4649C16.2695 53.4819 14.6342 55.1172 12.6172 55.1172Z"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_6"
                d="M12.6172 73.0469H5.31249C3.29541 73.0469 1.66016 71.4116 1.66016 69.3945C1.66016 67.3775 3.29541 65.7422 5.31249 65.7422H12.6172C14.6342 65.7422 16.2695 67.3775 16.2695 69.3945C16.2695 71.4116 14.6342 73.0469 12.6172 73.0469Z"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_7"
                d="M65.4099 1.6602H80.0192C81.853 1.6602 83.3395 3.1467 83.3395 4.98051V14.6758C83.3395 16.5096 81.853 17.9961 80.0192 17.9961H73.3786"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_8"
                d="M73.3787 17.9961H80.0193C81.8531 17.9961 83.3396 19.4826 83.3396 21.3164V31.0117C83.3396 32.8455 81.8531 34.332 80.0193 34.332H73.3787"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_9"
                d="M73.3787 34.3321H80.0193C81.8531 34.3321 83.3396 35.8186 83.3396 37.6524V47.3477C83.3396 49.1815 81.8531 50.668 80.0193 50.668H73.3787"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_10"
                d="M73.3787 50.668H80.0193C81.8531 50.668 83.3396 52.1545 83.3396 53.9883V63.6836C83.3396 65.5174 81.8531 67.0039 80.0193 67.0039H73.3787"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_11"
                d="M73.3786 67.004H80.0192C81.853 67.004 83.3395 68.4905 83.3395 70.3243V80.0195C83.3395 81.8533 81.853 83.3398 80.0192 83.3398H65.4099"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_12"
                d="M49.9916 57.1094H56.4451V54.4531C56.4451 47.8517 51.0936 42.5 44.492 42.5H40.5077C33.9062 42.5 28.5546 47.8517 28.5546 54.4531V57.1094H35.0502"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_13"
                d="M42.4999 27.8907C38.8324 27.8907 35.8593 30.8638 35.8593 34.5313V35.8594C35.8593 39.5268 38.8324 42.5 42.4999 42.5C46.1673 42.5 49.1405 39.5268 49.1405 35.8594V34.5313C49.1405 30.8638 46.1673 27.8907 42.4999 27.8907Z"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_14"
                d="M8.30078 37.1875V47.8125"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_15"
                d="M8.30078 29.8828V19.2578"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_16"
                d="M42.4999 42.9982V46.8442"
                stroke="#EC8812"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_198_2639">
          <rect width="85" height="85" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
