export const DashboardIcon = ({ styles, activePage }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.6"
        y="0.6"
        width="13.4667"
        height="13.4667"
        rx="2.4"
        style={{ stroke: activePage === "/EspaceClient/" ? "#CC007B" : "#2a375c", strokeWidth: 1.2}}
      />
      <rect
        x="0.6"
        y="17.9333"
        width="13.4667"
        height="13.4667"
        rx="2.4"
        style={{ stroke: activePage === "/EspaceClient/" ? "#EC8812" : "#2a375c", strokeWidth: 1.2}}
      />
      <rect
        x="17.9333"
        y="0.6"
        width="13.4667"
        height="13.4667"
        rx="2.4"
        style={{ stroke: activePage === "/EspaceClient/" ? "#EC8812" : "#2a375c", strokeWidth: 1.2}}
      />
      <rect
        x="17.9333"
        y="17.9333"
        width="13.4667"
        height="13.4667"
        rx="2.4"
        style={{ stroke: activePage === "/EspaceClient/" ? "#CC007B" : "#2a375c", strokeWidth: 1.2}}
      />
    </svg>
  );
};
