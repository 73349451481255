import {
  Autocomplete,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  ThemeProvider,
} from "@mui/material";
import { OUTLINED } from "../commonParameters/CommonParameters";
import { genererBrSiChampNonVideOuUndefined } from "../commonMethods/CommonMethods";
import { StyledDivFormSection } from "../../../styledComponents/StyledDiv";
import { StyledTextfield } from "../../../styledComponents/StyledTextfield";
import { StyledMenuItemLegend } from "../../../styledComponents/StyledMenuItem";
import { ThemeAutocomplete } from "../../../styledComponents/StyledAutocomplete";
import { HelperText } from "../../HelperText";
import { StyledTextfieldOrange } from "../../../styledComponents/StyledTextfieldBackoffice";

/**
 * Generate a text field with a select option
 * @param champ - The field to generate.
 * @param formik - The Formik instance.
 * @returns The `genererChampTextFieldSelect` function returns a `Grid` item with a `TextField` inside.
 */
const genererChampTextFieldSelect = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledTextfield
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label}
          variant={OUTLINED.description}
          select
          value={formik.values[champ.nom.description] ?? ""}
          onChange={(e) => {
            formik.setFieldValue(champ.nom.description, e.target.value);
          }}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik.touched[champ.nom.description] &&
              formik.errors[champ.nom.description]
          )}
          helperText={
            formik.touched[champ.nom.description]
              ? formik.errors[champ.nom.description]
              : null
          }
          size="small"
          InputProps={{
            className: champ.textInformation?.text
              ? champ.textInformation?.cssIcon
              : null,
            endAdornment: champ.textInformation?.text ? (
              <InputAdornment position="end">
                <HelperText
                  name={champ.nom.description}
                  textInformation={champ.textInformation}
                  gridStyle={{ marginRight: "1em" }}
                />
              </InputAdornment>
            ) : null,
            startAdornment: null,
          }}
        >
          {champ?.donnees.map((option) => (
            <StyledMenuItemLegend
              className={champ.cssFontFamily}
              key={option.code.description || option.code}
              value={option.code.description || option.code}
            >
              {option.name}
            </StyledMenuItemLegend>
          ))}
        </StyledTextfield>
      </Grid>
    </StyledDivFormSection>
  );
};

/**
 * Generate a text field with a searchbar
 * @param champ - the field to generate
 * @param formik - the Formik instance
 * @returns The `genererChampTextfieldAndSearchBar` functions returns a 'Grid' item and an 'Autocomplete' component inside.
 */
const genererChampTextfieldAndSearchBar = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <Autocomplete
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          options={champ.donnees}
          autoHighlight
          value={formik.values[champ.nom.description] ?? ""}
          onChange={(event, value) => {
            formik.setFieldValue(champ.nom.description, value);
          }}
          getOptionLabel={(value) =>
            champ.donnees.find((elem) => elem.code === value.code)?.name || ""
          }
          getOptionDisabled={(option) => option.isDivider}
          size="small"
          renderOption={(option, value) => (
            <div key={value.code}>
              <li {...option}>{value.name}</li>
              {value.isDivider ? <Divider /> : null}
            </div>
          )}
          isOptionEqualToValue={(option, value) =>
            option.code === value.code || option.code === value
          }
          renderInput={(option) => {
            return (
              <StyledTextfield
                label={champ.label ? champ.label : ""}
                variant="outlined"
                key={option.code}
                value={option.name}
                error={Boolean(
                  formik.touched[champ.nom.description] &&
                    formik.errors[champ.nom.description]
                )}
                helperText={
                  <>
                    {champ.helper}
                    {genererBrSiChampNonVideOuUndefined(champ.helper)}
                    {formik.touched[champ.nom.description]
                      ? formik.errors[champ.nom.description]
                      : null}
                  </>
                }
                {...option}
                InputProps={{
                  ...option.InputProps,
                  className: champ.textInformation?.text
                    ? champ.textInformation?.cssIcon
                    : null,
                  endAdornment: champ.textInformation?.text ? (
                    <InputAdornment position="end">
                      <HelperText
                        name={champ.nom.description}
                        textInformation={champ.textInformation}
                        gridStyle={{ marginRight: "1.3em" }}
                      />
                      {option.InputProps.endAdornment}
                    </InputAdornment>
                  ) : (
                    option.InputProps.endAdornment
                  ),
                  startAdornment: null,
                }}
              />
            );
          }}
        />
      </Grid>
    </StyledDivFormSection>
  );
};

/**
 * Generate a text field with a searchbar
 * @param champ - the field to generate
 * @param formik - the Formik instance
 * @returns The `genererChampTextfieldAndSearchBarReturnCode` functions returns a 'Grid' item and an 'Autocomplete' component inside.
 */
const genererChampTextfieldAndSearchBarReturnCode = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <ThemeProvider theme={ThemeAutocomplete}>
          <Autocomplete
            className={champ.cssTextfield}
            id={champ.nom.description}
            name={champ.nom.description}
            options={champ.donnees}
            autoHighlight
            value={formik.values[champ.nom.description]}
            onChange={(event, value) => {
              formik.setFieldValue(champ.nom.description, value?.code ?? "");
            }}
            getOptionLabel={(option) =>
              champ.donnees.find(
                (el) => el.code === formik.values[champ.nom.description]
              )?.name ?? ""
            }
            getOptionDisabled={(option) => option.isDivider}
            size="small"
            filterOptions={(options, { inputValue: value }) => {
              if (!value) {
                return options;
              }
              return options.filter((element) =>
                element.name
                  .replace(",", "")
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            }}
            renderOption={(option, value) => {
              return (
                <div key={value.code}>
                  <li {...option}>{value.name}</li>
                  {value.isDivider ? <Divider /> : null}
                </div>
              );
            }}
            isOptionEqualToValue={(option, value) =>
              option.code === value || value === ""
            }
            renderInput={(option) => {
              return (
                <StyledTextfield
                  {...option}
                  label={champ.label}
                  variant="outlined"
                  key={option.code}
                  value={option.name}
                  error={Boolean(
                    formik.touched[champ.nom.description] &&
                      formik.errors[champ.nom.description]
                  )}
                  helperText={
                    <>
                      {champ.helper}
                      {genererBrSiChampNonVideOuUndefined(champ.helper)}
                      {formik.touched[champ.nom.description]
                        ? formik.errors[champ.nom.description]
                        : null}
                    </>
                  }
                  InputProps={{
                    ...option.InputProps,
                    className: champ.textInformation?.text
                      ? champ.textInformation?.cssIcon
                      : null,
                    endAdornment: champ.textInformation?.text ? (
                      <InputAdornment position="end">
                        <HelperText
                          name={champ.nom.description}
                          textInformation={champ.textInformation}
                        />
                        {option.InputProps.endAdornment}
                      </InputAdornment>
                    ) : (
                      option.InputProps.endAdornment
                    ),
                    startAdornment: null,
                  }}
                />
              );
            }}
          />
        </ThemeProvider>
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampTextFieldSelectByLabel = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledTextfield
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label}
          variant={OUTLINED.description}
          select
          value={formik.values[champ.nom.description] ?? ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik.touched[champ.nom.description] &&
              formik.errors[champ.nom.description]
          )}
          helperText={
            formik.touched[champ.nom.description]
              ? formik.errors[champ.nom.description]
              : null
          }
          size="small"
        >
          {champ?.donnees.map((option) => (
            <MenuItem
              className={champ.css}
              key={option.code}
              value={option.code}
            >
              {option.name}
            </MenuItem>
          ))}
        </StyledTextfield>
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampTextFieldSelectBackoffice = (champ, formik) => {
  return (
    <Grid item key={champ.label}>
      <StyledTextfieldOrange
        id={champ.nom.description}
        name={champ.nom.description}
        label={champ.label}
        variant={OUTLINED.description}
        select
        value={formik.values[champ.nom.description] ?? ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(
          formik.touched[champ.nom.description] &&
            formik.errors[champ.nom.description]
        )}
        helperText={
          formik.touched[champ.nom.description]
            ? formik.errors[champ.nom.description]
            : null
        }
        size="small"
      >
        {champ?.donnees.map((option) => (
          <MenuItem key={option.code} value={option.code}>
            {option.name}
          </MenuItem>
        ))}
      </StyledTextfieldOrange>
    </Grid>
  );
};

export {
  genererChampTextFieldSelect,
  genererChampTextfieldAndSearchBar,
  genererChampTextFieldSelectByLabel,
  genererChampTextfieldAndSearchBarReturnCode,
  genererChampTextFieldSelectBackoffice,
};
