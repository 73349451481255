import {
  AGRESSIONS,
  ENVIRONNEMENT,
  TEMPERATURE,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  arrayEnvironnement,
  arrayTemperatures,
} from "../../../../../constantes/symbols/SymbolsServicesCommon";
import { importAll } from "../../../../../helper/ImportAll";
import {
  TEXTFIELD_SELECT,
  CHECKBOX,
  TEXT,
  BUTTON_GROUP,
  GRID,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRETEMPERATURE = Symbol("titre temperature");
const TITRE_ENVIRONNEMENT = Symbol("titre environnement");

export const formulaireEnvironnement = (styles, t) => [
  {
    nom: TITRE_ENVIRONNEMENT,
    label: t.titreEnvironnementEtape3,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0014_Q00071,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: ENVIRONNEMENT,
    label: t.commentaireEnvironnement,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    cssRadioColumn: styles.radioColumn,
    donnees: arrayEnvironnement(t),
    
  },
];

export const formulaireTemperatures = (styles, t) => [
  {
    nom: TITRETEMPERATURE,
    label: t.temperature,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0013_Q00015,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: TEMPERATURE,
    label: t.selectionTemperature + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    cssTextfield: styles.cssTextfield,
    donnees: arrayTemperatures(t),
  },
];

export const formulaireAgressions = (agressionsList, styles, t) => [
  {
    nom: AGRESSIONS,
    label: t.agressionsCheckbox,
    type: CHECKBOX,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: agressionsList,
    //Pas de code question pour les agressions c'est dans environnement
  },
];

export const formulaireAgressionsImages = (agressionsWithImgList, styles) => [
  {
    nom: AGRESSIONS,
    type: GRID,
    images: importAll(),
    cssSurroundGridItem: styles.cssSurroundGridItem,
    cssFontFamily: styles.cssFontFamily,
    donnees: agressionsWithImgList,
    hidden: true,
  },
];
