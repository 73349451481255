import { usedForDisplay } from "./HelperSymbolsServices";
import {
  AXE_EMPLACEMENT,
  CODE_ARTICLE,
  COMMENTAIRE,
  ENVIRONNEMENT,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  FONCTION,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  NUMERO_DE_PLAN,
  PAYS,
  POSITION_DE_TRAVAIL,
  QUANTITEE_SOUHAITEE,
  SECTEUR,
  TEMPERATURE,
  TYPE_DE_MACHINE,
  VITESSE_DEPLACEMENT,
} from "./SymbolsCommon";

//Etape 1
export const SOUFFLET_ENDUIT = Symbol("Soufflet");
export const SOUFFLET_PLISSE = Symbol("Soufflet plissé");
export const ENROULEUR = Symbol("Enrouleur");
export const PROTECTEUR_TELESCOPIQUE = Symbol("Protecteur telescopique");
export const COMPENSATEUR = Symbol("Compensateur");

//Etape 7
const SERVICE_UN = Symbol("fabricationUrgence");
const SERVICE_DEUX = Symbol("livraisonParTransporteur");
const SERVICE_TROIS = Symbol("livraisonExpresse");
const SERVICE_QUATRE = Symbol("enlevementParVosSoins");
const SERVICE_CINQ = Symbol("montageSurSite");

//Etape 1
const arrayProductFamilly = (t) => [
  {
    code: "S01", //Soufflet enduit
    ...usedForDisplay(t, { name: t?.souffletEnduit }),
    img: "SouffletEnduit.png",
  },
  {
    code: "S01_1", //Compensateur
    ...usedForDisplay(t, { name: t?.compensateur }),
    img: "Compensateur.png",
  },
  {
    code: "S04", //Soufflet plissé
    ...usedForDisplay(t, { name: t?.souffletPlisse }),
    img: "SouffletPlisse.png",
  },
  {
    code: "S04-06", //Table élévatrice
    ...usedForDisplay(t, { name: t?.tableElevatrice }),
    img: "table-elevatrice.png",
  },
  {
    code: "S05", //Enrouleur
    ...usedForDisplay(t, { name: t?.enrouleur }),
    img: "Enrouleur.png",
  },
  {
    code: "S08", //Protecteur telescopique
    ...usedForDisplay(t, { name: t?.protectionTelescopique }),
    img: "ProtecteurTelescopique.png",
  },
  
];

const arrayProductFamilly0_1_3 = (t) => [
  arrayProductFamilly(t)[0].code,
  arrayProductFamilly(t)[1].code,
  arrayProductFamilly(t)[3].code,
];

const arrayProductFamilly0_1_3_2_3 = (t) => [
  arrayProductFamilly(t)[0].code,
  arrayProductFamilly(t)[1].code,
  arrayProductFamilly(t)[2].code,
  arrayProductFamilly(t)[3].code,
];

const arrayProductFamilly2_4_5 = (t) => [
  arrayProductFamilly(t)[2].code,
  arrayProductFamilly(t)[4].code,
  arrayProductFamilly(t)[5].code,
];

//Etape 2
const arrayPositionTravail = (t) => [
  {
    code: 0,
    label: "Frontale",
    ...usedForDisplay(t, { name: t?.frontale }),
  },
  {
    code: 1,
    label: "Verticale",
    ...usedForDisplay(t, { name: t?.verticale }),
  },
  {
    code: 2,
    label: "Horizontale",
    ...usedForDisplay(t, { name: t?.horizontale }),
  },
];

const arrayPositionTravailS01 = (t) => [
  arrayPositionTravail(t)[1],
  arrayPositionTravail(t)[2],
];

const arrayPositionTravailS04_5_8 = (t) => [
  arrayPositionTravail(t)[0],
  arrayPositionTravail(t)[1],
  arrayPositionTravail(t)[2],
];

//Etape 3
const arrayEnvironnement = (t) => [
  {
    code: 0,
    ...usedForDisplay(t, { name: t?.alimentaire }),
  },
  {
    code: 1,
    ...usedForDisplay(t, { name: t?.nucleaire }),
  },
  {
    code: 2,
    ...usedForDisplay(t, { name: t?.labelPeinture }),
  },
  {
    code: 3,
    ...usedForDisplay(t, { name: t?.autreEnvironnement }),
  },
];

const arrayTemperatures = (t) => [
  {
    code: 1,
    label: "Inférieure à 10°C",
    ...usedForDisplay(t, { name: t?.tempUn }),
  },
  {
    code: 2,
    label: "10°C à 39°C (Ambiante)",
    ...usedForDisplay(t, { name: t?.tempDeux }),
  },
  {
    code: 3,
    label: "40°C à 99°C",
    ...usedForDisplay(t, { name: t?.tempTrois }),
  },
  {
    code: 4,
    label: "100°C à 149°C",
    ...usedForDisplay(t, { name: t?.tempQuatre }),
  },
  {
    code: 5,
    label: "150°C à 200°C",
    ...usedForDisplay(t, { name: t?.tempCinq }),
  },
  {
    code: 6,
    label: "Supérieure à 200°C",
    ...usedForDisplay(t, { name: t?.tempSix }),
  },
];

//Etape 7
const arrayServicesRC = (t) => [
  {
    code: SERVICE_UN,
    ...usedForDisplay(t, { name: t?.fabricationUrgence }),
    img: "rc-chrono.jpg",
  },
  // {
  //     code:SERVICE_DEUX,
  //     name: t.livraisonParTransporteur
  // },
  // {
  //     code:SERVICE_TROIS,
  //     name: t.livraisonExpresse
  // },
  // {
  //     code:SERVICE_QUATRE,
  //     name: t.enlevementParVosSoins
  // },
  {
    code: SERVICE_CINQ,
    ...usedForDisplay(t, { name: t?.montageSurSite }),
    img: "montage-sur-site.jpg",
  },
];

const codeServicesRC = () => {
  return {
    fabricationUrgence: 1,
    montageSurSite: 2,
  };
};

const arrayServicesMoov = (t) => [
  // {
  //   code: SERVICE_UN,
  //   ...usedForDisplay(t, { name: t?.fabricationUrgence }),
  // },
  // {
  //     code:SERVICE_DEUX,
  //     name: t.livraisonParTransporteur
  // },
  // {
  //     code:SERVICE_TROIS,
  //     name: t.livraisonExpresse
  // },
  // {
  //     code:SERVICE_QUATRE,
  //     name: t.enlevementParVosSoins
  // },
  {
    code: SERVICE_CINQ,
    ...usedForDisplay(t, { name: t?.montageSurSite }),
  },
];

const codeServicesMoov = () => {
  return {
    montageSurSite: 2,
  };
};

//Resumes
const arrayCommonSymbolsToDisplay = () => [
  PAYS,
  SECTEUR,
  FONCTION,
  QUANTITEE_SOUHAITEE,
  COMMENTAIRE,
  TEMPERATURE,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  POSITION_DE_TRAVAIL,
  TYPE_DE_MACHINE,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  AXE_EMPLACEMENT,
  NUMERO_DE_PLAN,
  VITESSE_DEPLACEMENT,
  CODE_ARTICLE,
  ENVIRONNEMENT,
];

export {
  arrayProductFamilly,
  arrayProductFamilly0_1_3,
  arrayProductFamilly0_1_3_2_3,
  arrayProductFamilly2_4_5,
  arrayPositionTravail,
  arrayPositionTravailS04_5_8,
  arrayPositionTravailS01,
  arrayEnvironnement,
  arrayTemperatures,
  arrayServicesRC,
  codeServicesRC,
  arrayServicesMoov,
  codeServicesMoov,
  arrayCommonSymbolsToDisplay,
};
