import { Radio } from "@mui/material";
import { styled } from "@mui/system";

export const StyledRadioWithoutAnimation = styled(Radio)({
    pointerEvents: "none",
});

export const StyledRadio = styled(Radio)({
    color: '#2A375C',
    backgroundColor: 'white',
    padding: '0',
    margin: '0.5em',
    '&.Mui-checked': {
        color: '#2A375C',
    },
});