import {
  MATIERE_TOILE,
  TYPE_ENROULEUR,
} from "../../../../../constantes/symbols/SymbolsS05";
import {
  S05_STEP4_arrayMatiereToile,
  S05_STEP4_arrayTypeEnrouleur,
} from "../../../../../constantes/symbols/SymbolsServicesS05";
import {
  BUTTON_GROUP,
  TEXT,
  TEXTFIELD_BY_LABEL,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_TYPE_ENROULEUR = Symbol("Type d'enrouleur");
const TITRE_MATIERE_TOILE = Symbol("Matière toile");
const DESCRIPTION_MATIERE_TOILE_S05 = Symbol("Description matière toile S05");

export const formulaireConstructionS05 = (styles, t) => [
  {
    nom: TITRE_MATIERE_TOILE,
    label: t.toile,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0078_Q00030,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: DESCRIPTION_MATIERE_TOILE_S05,
    label: t.descriptionToile2,
    type: TEXT,
    cssFontFamily: styles.descriptionInDiv,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
  {
    nom: MATIERE_TOILE,
    label: t.descrMatiereToile,
    type: TEXTFIELD_BY_LABEL,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: S05_STEP4_arrayMatiereToile(t),
  },
  {
    nom: TITRE_TYPE_ENROULEUR,
    label: t.titreTypeEnrouleur + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0078_Q00040,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: TYPE_ENROULEUR,
    label: t.descrTypeEnrouleur,
    type: BUTTON_GROUP /*ou TEXTFIELD_SELECT*/,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    cssRadioColumn: styles.radioColumn,
    donnees: S05_STEP4_arrayTypeEnrouleur(t),
  },
];
