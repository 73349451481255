import { usedForDisplay } from "./HelperSymbolsServices";

//Etape 4
const S06_STEP4_arrayToile = (t) => [
  {
    code: 99,
    label: "Ne sait pas",
    ...usedForDisplay(t, { name: t?.neSaitPas }),
  },
  {
    code: 0,
    label: "PU 0.4 mm",
    ...usedForDisplay(t, { name: t?.pu3 }),
  },
  {
    code: 2,
    label: "Polyester PVC",
    ...usedForDisplay(t, { name: t?.polyesterPVC }),
  },
  {
    code: 3,
    label: "Polyester PVC M2 (Classe feu)",
    ...usedForDisplay(t, { name: t?.polyesterM2 }),
  },
  {
    code: 7,
    label: "TV Aluminium",
    ...usedForDisplay(t, { name: t?.tvAluminium }),
  },
  {
    code: 8,
    label: "FNB2E Alimentaire",
    ...usedForDisplay(t, { name: t?.fnb2e }),
  },
  
];

const S06_STEP5_arrayNombreFaces = (t) => [
  {
    code: 3,
    label: "3",
    ...usedForDisplay(t, { name: t?.troisFaces }),
  },
  {
    code: 4,
    label: "4",
    ...usedForDisplay(t, { name: t?.quatreFaces }),
  },
];

const S06_STEP5_arrayDimensionsPlis = (t) => [
  {
    code: 40,
    label: "40",
    ...usedForDisplay(t, { name: t?.dimensionPlis1 }),
  },
  {
    code: 50,
    label: "50",
    ...usedForDisplay(t, { name: t?.dimensionPlis2 }),
  },
];

const S06_STEP5_arrayDimensionsPlisWithLongueurASup1000 = (t) => [
  {
    code: 50,
    label: "50",
    ...usedForDisplay(t, { name: t?.dimensionPlis2 }),
  },
];

//Etape 6
const S06_STEP6_arrayTypeExtremites = (t) => [
  {
    code: 1,
    label: "Manchette",
    ...usedForDisplay(t, { name: t?.manchette, img: "Manchette.png" }),
  },
  {
    code: 2,
    label: "Bride",
    ...usedForDisplay(t, { name: t?.bride, img: "Bride.png" }),
  },
];

const S06_STEP6_arrayAlignement = (t) => [
  {
    code: 0,
    label: "Interieur",
    ...usedForDisplay(t, { name: t?.surInterieur }),
  },
  {
    code: 1,
    label: "Exterieur",
    ...usedForDisplay(t, { name: t?.surExterieur }),
  },
];

const S06_STEP6_arrayTypeBride = (t) => [
  {
    code: 0,
    label: "Type A",
    ...usedForDisplay(t, { name: t?.typeA }),
  },
  {
    code: 1,
    label: "Type B",
    ...usedForDisplay(t, { name: t?.typeB }),
  },
];

const S06_STEP6_arrayExtremiteInsereeMatiere = (t) => [
  {
    code: 0,
    label: "PVC",
    ...usedForDisplay(t, { name: t?.pvcS06 }),
  },
  {
    code: 1,
    label: "Acier",
    ...usedForDisplay(t, { name: t?.acier }),
  },
  {
    code: 2,
    label: "Aluminium",
    ...usedForDisplay(t, { name: t?.aluminium }),
  },
  {
    code: 3,
    label: "Inox",
    ...usedForDisplay(t, { name: t?.inox }),
  },
  {
    code: 99,
    label: "Peu importe",
    ...usedForDisplay(t, { name: t?.peuImporte }),
  },
];

const S06_STEP6_arrayFixationBride = (t, type, matiere) => [
  {
    code: 2,
    label: "Velcro",
    ...usedForDisplay(t, { name: t?.velcro }),
  },
  {
    code: 3,
    label: "Dual lock",
    ...usedForDisplay(t, { name: t?.dualLock }),
  },
  {
    code: 4,
    label: "Aucune",
    ...usedForDisplay(t, { name: t?.aucune }),
  },
];

const S06_STEP6_arrayFixationBrideTypeA = (t, type, matiere) => [
  {
    code: 0,
    label: "Percage",
    ...usedForDisplay(t, { name: t?.percage }),
  },
  {
    code: 2,
    label: "Velcro",
    ...usedForDisplay(t, { name: t?.velcro }),
  },
  {
    code: 3,
    label: "Dual lock",
    ...usedForDisplay(t, { name: t?.dualLock }),
  },
  {
    code: 4,
    label: "Aucune",
    ...usedForDisplay(t, { name: t?.aucune }),
  },
];

const S06_STEP6_arrayFixationBrideTypeAEtAcier = (t, type, matiere) => [
  {
    code: 0,
    label: "Percage",
    ...usedForDisplay(t, { name: t?.percage }),
  },
  {
    code: 1,
    label: "Taraudages",
    ...usedForDisplay(t, { name: t?.taraudages }),
  },
  {
    code: 2,
    label: "Velcro",
    ...usedForDisplay(t, { name: t?.velcro }),
  },
  {
    code: 3,
    label: "Dual lock",
    ...usedForDisplay(t, { name: t?.dualLock }),
  },
  {
    code: 4,
    label: "Aucune",
    ...usedForDisplay(t, { name: t?.aucune }),
  },
];

//Etape 7
const S06_STEP7_arrayAccessories = (t) => [
  {
    code: 0,
    label: "Oui",
    ...usedForDisplay(t, { name: t?.ouiMajuscule }),
  },
  {
    code: 1,
    label: "Non",
    ...usedForDisplay(t, { name: t?.nonMajuscule }),
  },
  /*{
    code: 9,
    label: "Ne sait pas",
    name: "Ne sait pas",
  },*/
];

export {
  //Etape 4
  S06_STEP4_arrayToile,
  //Etape 5
  S06_STEP5_arrayNombreFaces,
  S06_STEP5_arrayDimensionsPlis,
  S06_STEP5_arrayDimensionsPlisWithLongueurASup1000,
  //Etape 6
  S06_STEP6_arrayTypeExtremites,
  S06_STEP6_arrayAlignement,
  S06_STEP6_arrayTypeBride,
  S06_STEP6_arrayExtremiteInsereeMatiere,
  S06_STEP6_arrayFixationBride,
  S06_STEP6_arrayFixationBrideTypeA,
  S06_STEP6_arrayFixationBrideTypeAEtAcier,
  //Etape 7
  S06_STEP7_arrayAccessories,
};
