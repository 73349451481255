import axios from 'axios';
import { Serveur } from '../../constantes/services/Serveur';

const getAllTypeMachine = async () => {
    
    const config = {
        headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    };
    return await axios.get(Serveur.URL_TYPE_MACHINE, config);
}

const getAllMarqueMachine = async() => {
    const config = {
        headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    };
    return await axios.get(Serveur.URL_MARQUE_MACHINE, config);
}
    
const machinesService = {
    getAllTypeMachine,
    getAllMarqueMachine,
}

export default machinesService;

