import * as Yup from "yup";
import { S04_LONGUEUR_COMPRIMEE, S04_LONGUEUR_DEPLOYEE } from "../../../../../constantes/symbols/SymbolsS04";

const otherwiseSchema = Yup.mixed().optional();

const schemaNotZero = (error) => {
  return Yup.number().min(1, error);
};

const yupPlusPetit = (champ, erreur, errorInfZero) => {
  return Yup.number().when(champ.toString(), {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .lessThan(Yup.ref(champ.toString()), erreur),
  });
};

const yupPlusGrand = (champ, erreur, errorInfZero) => {
  return Yup.number().when(champ.toString(), {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .moreThan(Yup.ref(champ.toString()), erreur),
  });
};

/* A Yup validation schema. */
export const validDimensionsCorpsS04 = (t, etape) => {
  if (etape !== 5) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [S04_LONGUEUR_COMPRIMEE.description]: Yup.lazy(() =>
      yupPlusPetit(
        [S04_LONGUEUR_DEPLOYEE.description],
        t.erreurLongueurComprimeePetit,
        t.superieurA0
      ).required(t.champRequis)
    ),

    [S04_LONGUEUR_DEPLOYEE.description]: Yup.lazy(() =>
      yupPlusGrand(
        [S04_LONGUEUR_COMPRIMEE.description],
        t.erreurLongueurComprimeeGrand,
        t.superieurA0
      ).required(t.champRequis)
    ),
  });
};
