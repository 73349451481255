import { Grid } from "@mui/material";
import {
  formulaireAccessoiresS04,
  formulaireEcailles,
} from "../../ChampsFormulaires/S04/etape7/ChampsAccessoiresS04";
import { DessinTechnique } from "../../components/DessinTechnique";
import { importAllSpec } from "../../../../helper/ImportAll";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import {
  S04_ACCESSOIRES,
  S04_ECAILLES,
  SECTION_SYMETRIQUE,
  TYPE_SECTION,
} from "../../../../constantes/symbols/SymbolsS04";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../constantes/symbols/HelperSymbolsServices";
import {
  S04_STEP4_arrayFormeCorpsD,
  S04_STEP4_arrayFormeCorpsE,
  S04_STEP4_arrayFormeCorpsF,
  S04_STEP4_arrayFormeCorpsSymA,
  S04_STEP4_arrayFormeCorpsSymD,
  S04_STEP4_arrayFormeCorpsSymE,
  S04_STEP4_arrayFormeCorpsSymF,
  S04_STEP4_arrayTypeCorps,
  S04_STEP7_arrayAccessories,
  S04_STEP7_arrayEcaillesA2S,
  S04_STEP7_arrayEcaillesA3S,
  S04_STEP7_arrayEcaillesB1S,
  S04_STEP7_arrayEcaillesC1S,
  S04_STEP7_arrayEcaillesD1,
  S04_STEP7_arrayEcaillesD1S,
  S04_STEP7_arrayEcaillesD2,
  S04_STEP7_arrayEcaillesD3,
  S04_STEP7_arrayEcaillesD3S,
  S04_STEP7_arrayEcaillesE1,
  S04_STEP7_arrayEcaillesE1S,
  S04_STEP7_arrayEcaillesE2,
  S04_STEP7_arrayEcaillesE2S,
  S04_STEP7_arrayEcaillesF1,
  S04_STEP7_arrayEcaillesF1S,
  S04_STEP7_arrayEcaillesF2,
  S04_STEP7_arrayEcaillesF2S,
} from "../../../../constantes/symbols/SymbolsServicesS04";
import { findFormeCorpsFromTypeSection } from "../../../../helper/displayCasesFormulary";
import { useEffect } from "react";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";

export const StepS04Accessories = ({
  state: {
    booleanIdentite,
    etape,
    styles,
    styles: {
      cssFontFamily,
      cssButton,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    formik,
  },
  action: { handleRetour },
  nom,
}) => {
  const lang = useTranslations();
  const typeSection = formik.values[TYPE_SECTION.description];
  const sectionSymetrique = equalsBooleanOrStringOrNumber(
    formik.values[SECTION_SYMETRIQUE.description]
  );
  const formeCorps = findFormeCorpsFromTypeSection(formik.values);
  const isEcailles = formik.values[S04_ACCESSOIRES.description];

  useEffect(()=>{
    if(equalsBooleanOrStringOrNumber(isEcailles)){
      formik.setFieldValue(S04_ECAILLES.description,[]);
    }
  }, [isEcailles])

  const afficherCheckboxCoteEcailles = (t) => {
    if (equalsCodeOrLabel(isEcailles, S04_STEP7_arrayAccessories(t), 0)) {
      switch (true) {
        //A
        case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 0):
          switch (true) {
            //A2S
            case equalsCodeOrLabel(
              formeCorps,
              S04_STEP4_arrayFormeCorpsSymA(),
              1
            ):
              return genererFormulaire(
                formulaireEcailles(S04_STEP7_arrayEcaillesA2S(t), styles, t),
                formik
              );
            //A3S
            case equalsCodeOrLabel(
              formeCorps,
              S04_STEP4_arrayFormeCorpsSymA(),
              2
            ):
              return genererFormulaire(
                formulaireEcailles(S04_STEP7_arrayEcaillesA3S(t), styles, t),
                formik
              );
            default:
              return null;
          }
        //B
        case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 1):
          return genererFormulaire(
            formulaireEcailles(S04_STEP7_arrayEcaillesB1S(t), styles, t),
            formik
          );
        //C
        case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 2):
          return genererFormulaire(
            formulaireEcailles(S04_STEP7_arrayEcaillesC1S(t), styles, t),
            formik
          );
        //D
        case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 3):
          switch (true) {
            case !!sectionSymetrique:
              switch (true) {
                //si symétrique ET option D1S selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsSymD(),
                  0
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesD1S(t), styles, t),
                    formik
                  );
                //si symétrique ET option D3S selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsSymD(),
                  1
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesD3S(t), styles, t),
                    formik
                  );
                default:
                  return null;
              }
            case !sectionSymetrique:
              switch (true) {
                //si non symétrique ET option D1 selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsD(),
                  0
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesD1(t), styles, t),
                    formik
                  );
                //si non symétrique ET option D2 selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsD(),
                  1
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesD2(t), styles, t),
                    formik
                  );
                //si non symétrique ET option D3 selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsD(),
                  2
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesD3(t), styles, t),
                    formik
                  );
                default:
                  return null;
              }
            default:
              return null;
          }
        //E
        case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 4):
          switch (true) {
            case !!sectionSymetrique:
              switch (true) {
                //si symétrique ET option E1S selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsSymE(),
                  0
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesE1S(t), styles, t),
                    formik
                  );
                //si symétrique ET option E2S selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsSymE(),
                  1
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesE2S(t), styles, t),
                    formik
                  );
                default:
                  return null;
              }
            case !sectionSymetrique:
              switch (true) {
                //si non symétrique ET option E1 selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsE(),
                  0
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesE1(t), styles, t),
                    formik
                  );
                //si non symétrique ET option E2 selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsE(),
                  1
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesE2(t), styles, t),
                    formik
                  );
                default:
                  return null;
              }
            default:
              return null;
          }
        //F
        case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 5):
          switch (true) {
            case !!sectionSymetrique:
              switch (true) {
                //si symétrique ET option F1S selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsSymF(),
                  0
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesF1S(t), styles, t),
                    formik
                  );
                //si symétrique ET option F2S selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsSymF(),
                  1
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesF2S(t), styles, t),
                    formik
                  );
                default:
                  return null;
              }
            case !sectionSymetrique:
              switch (true) {
                //si non symétrique ET option F1 selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsF(),
                  0
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesF1(t), styles, t),
                    formik
                  );
                //si non symétrique ET option F2 selectionnée
                case equalsCodeOrLabel(
                  formeCorps,
                  S04_STEP4_arrayFormeCorpsF(),
                  1
                ):
                  return genererFormulaire(
                    formulaireEcailles(S04_STEP7_arrayEcaillesF2(t), styles, t),
                    formik
                  );
                default:
                  return null;
              }
            default:
              return null;
          }
        default:
          return null;
      }
    }
  };

  const afficherDessinTechnique = () => {
    return (
      <DessinTechnique formik={formik.values} styles={styles} etape={etape} t={t}/>
    );
  };

  const afficherSOSetForm = () => {
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodySelectionnerDesAccessoiresSoufflet}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(formulaireAccessoiresS04(styles, t), formik)}
            {afficherCheckboxCoteEcailles(t)}

            <StyledDivDessinMobile>
              <StyledDivDessin>
                {afficherDessinTechnique()}
                <StyledDivFiligranne
                  style={{
                    backgroundImage:
                      "url(" +
                      importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                      ")",
                  }}
                  className={logoFiligraneDessin}
                />
              </StyledDivDessin>
              {lang.lang === "FR" && afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={cssButton}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
        {lang.lang === "FR" && afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
