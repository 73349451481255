import {
  LARGEUR_TOILE,
  L_MAX,
  MAXIMUM_ENROULE,
  SECTION_MAX,
  SECTION_MAX_A,
  SECTION_MAX_B,
  SECTION_MAX_C,
  TYPE_ENROULEUR,
} from "../../../../../constantes/symbols/SymbolsS05";
import {
  initChampsFormikToJsonDefaultCustom,
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";
import { FOCUSED } from "./ChampsDimensionsS05";

const champsString = [
  TYPE_ENROULEUR,
  L_MAX,
  LARGEUR_TOILE,
  MAXIMUM_ENROULE,
  SECTION_MAX_A,
  SECTION_MAX_B,
  // SECTION_MAX_C,
];

export const initialValuesDimensionsS05 = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
      ...initChampsFormikToJsonDefaultCustom([FOCUSED], formulaire, null),
      ...initChampsFormikToJsonDefaultCustom(
        [SECTION_MAX],
        formulaire,
        formulaire[SECTION_MAX_A.description] +
          "X" +
          formulaire[SECTION_MAX_B.description] 
          // +
          // "X" +
          // formulaire[SECTION_MAX_C.description]
      ),
    },
    {}
  );
};
