import { styled } from "@mui/system";

export const StyledP = styled('div')({
    fontSize: '1em',
    color: '#787D98',
    margin: '1em 0 1em 0',
    '@media (max-width: 600px)':{
        margin: '1em 1.5em',
    },
});

export const StyledPAccueil = styled('p')({
    fontSize: '1em',
    color: '#787D98',
    margin: '1em 0 0.5em 0',
    '@media (max-width: 600px)':{
        margin: '0',
    },
});

export const StyledPContact = styled('p')({
    fontSize: '1.125em',
    color: '#787D98',
    margin: '1em 0 1em 0',
    '@media (max-width: 600px)':{
        margin: '1em 1.5em',
    },
});