import { ACCESSOIRES } from "../../../../../constantes/symbols/SymbolsS01";
import { arrayAccessoires } from "../../../../../constantes/symbols/SymbolsServicesS01";
import { importAll } from "../../../../../helper/ImportAll";
import { CHECKBOX_BOX_SHADOW } from "../../../components/generateFormulary/types/TypeFieldsFormulary";

export const formulaireAccessoires = (styles, t) => [
  {
    nom: ACCESSOIRES,
    label: "",
    type: CHECKBOX_BOX_SHADOW,
    images: importAll(),
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.noSectionForm,
    cssSurroundGridItem: styles.cssSurroundGridItem,
    cssCheckbox: styles.cssCheckboxAccessoires,
    donnees: arrayAccessoires(t, styles),
  },
];
