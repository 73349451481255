import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { importAllSpec } from "../../../../helper/ImportAll";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivColFieldsQuantite,
  StyledDivDessin,
  StyledDivFieldsQuantite,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonAjouterQuantite,
  StyledButtonContinuer,
  StyledButtonPrec,
  StyledButtonRetirerQuantite,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";

import { DessinTechnique } from "../../components/DessinTechnique";
import {
  formulaireInformationsComplementairesNewQte,
  formulaireInformationsComplementairesP1,
  formulaireInformationsComplementairesP2,
  formulaireInformationsComplementairesP3,
  formulaireInformationsComplementairesP4,
  formulaireInformationsComplementairesP5,
} from "../../ChampsFormulaires/S01/etape7/ChampsInformationsComplementaires";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import { HelperText } from "../../components/HelperText";

export const StepAdditionalInfo = ({
  state: {
    etape,
    booleanIdentite,
    formik,
    styles,
    styles: {
      cssFontFamily,
      cssButton,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    nbChampsQte,
    maxChampQte,
  },
  action: { handleRetour, setNbChampsQte },
  nom,
}) => {
  const [bouttonsList, setBouttonList] = useState([]);

  useEffect(() => {
    if (nbChampsQte <= maxChampQte) {
      const newButtonList = [...Array(nbChampsQte)].map((value, index) => {
        return formulaireInformationsComplementairesNewQte(styles, t, index);
      });
      setBouttonList(newButtonList);
      formik.setFieldValue("nbFieldToKeep", nbChampsQte);
    }
  }, [nbChampsQte]);

  const ajouterUnChampBouton = () => {
    if (nbChampsQte < maxChampQte) {
      setNbChampsQte(nbChampsQte + 1);
    }
  };

  const retirerUnChampBouton = () => {
    if (nbChampsQte > 1) {
      setNbChampsQte(nbChampsQte - 1);
      setBouttonList(bouttonsList.slice(0, nbChampsQte - 1));
    }
  };

  const afficherBoutonRetirer = (t) => {
    if (nbChampsQte > 1)
      return (
        <StyledButtonRetirerQuantite
          className={cssFontFamily}
          variant="contained"
          onClick={retirerUnChampBouton}
          startIcon={<RemoveIcon />}
        >
          {t.retirerDerniereQuantite}
        </StyledButtonRetirerQuantite>
      );
  };

  const afficherBoutonAjouter = (t) => {
    return (
      <StyledButtonAjouterQuantite
        className={cssFontFamily}
        variant="contained"
        onClick={ajouterUnChampBouton}
        startIcon={<AddIcon />}
      >
        {t.descrBouttonAjouter}
      </StyledButtonAjouterQuantite>
    );
  };

  const afficherBoutonList = (bouttonsList, formik, styles) => {
    if (bouttonsList !== []) {
      return genererFormulaire(bouttonsList, formik, styles);
    }
  };

  const afficherDessinTechnique = () => {
    return (
      <DessinTechnique formik={formik.values} styles={styles} etape={etape} t={t} isFrom={"Configurateur"}/>
    );
  };

  const commentaire = {
    textInformation: {
      text: t?.SQ0063_Q00010,
      cssIcon: styles?.helperTextIcon,
      // images: getImageInformation(),
    },
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          <Grid container>
            <Grid item md={11}>
              {t.genConfigurateurBodyConfigurationTerminee}
            </Grid>
            {commentaire.textInformation?.text ? (
              <HelperText
                md={1}
                name={"commentaireInfosSupp"}
                textInformation={commentaire.textInformation}
                gridStyle={{ marginTop: "9px" }}
              />
            ) : null}
          </Grid>
        </StyledP>

        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(
              formulaireInformationsComplementairesP1(styles, t),
              formik
            )}
            <StyledDivColFieldsQuantite>
              <StyledDivFieldsQuantite>
                {afficherBoutonList(bouttonsList, formik, styles)}
              </StyledDivFieldsQuantite>
              <StyledDivFieldsQuantite>
                {afficherBoutonAjouter(t)}
                {afficherBoutonRetirer(t)}
              </StyledDivFieldsQuantite>
            </StyledDivColFieldsQuantite>
            {genererFormulaire(
              formulaireInformationsComplementairesP2(styles, t),
              formik
            )}
            {!booleanIdentite
              ? genererFormulaire(
                  formulaireInformationsComplementairesP3(styles, t),
                  formik
                )
              : genererFormulaire(
                  formulaireInformationsComplementairesP5(styles, t),
                  formik
                )}
            {genererFormulaire(
              formulaireInformationsComplementairesP4(styles, t),
              formik
            )}

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={cssButton}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
