import * as Yup from "yup";
import {
  ALIGNEMENT_IT1,
  ALIGNEMENT_IT2,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT1,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT2,
  BRIDE_FIXATION_IT1,
  BRIDE_FIXATION_IT2,
  S06_BRIDE_IT1,
  S06_BRIDE_IT2,
  S06_EXTREMITE_IDENTIQUE,
  S06_TYPE_EXTREMITES_IT1,
  S06_TYPE_EXTREMITES_IT2,
  S06_MANCHETTE_LARGEUR_IT1,
  S06_MANCHETTE_LARGEUR_IT2,
} from "../../../../../constantes/symbols/SymbolsS06";
import {
  S06_STEP6_arrayAlignement,
  S06_STEP6_arrayExtremiteInsereeMatiere,
  S06_STEP6_arrayFixationBride,
  S06_STEP6_arrayFixationBrideTypeA,
  S06_STEP6_arrayFixationBrideTypeAEtAcier,
  S06_STEP6_arrayTypeExtremites,
  S06_STEP6_arrayTypeBride,
} from "../../../../../constantes/symbols/SymbolsServicesS06";

const testPositiveAndMin = (t) => {
  return Yup.number().min(1, t.superieurA0).required(t.renseignerChamp);
};

const testPositif = (t) => {
  return Yup.number().min(0, t.positif).required(t.renseignerChamp);
};

const otherwiseSchema = Yup.mixed().optional();

/* A Yup validation schema. */
export const validDimensionsExtremitesS06 = (t, etape, formulaire) => {
  if (etape !== 6) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    
    //IT1
    [S06_TYPE_EXTREMITES_IT1.description]: Yup.mixed().test(
      "S06RadioButtonTypeExtremitesIt1",
      () => {
        return t.renseignerChamp;
      },
      (selectedButton) => {
        return S06_STEP6_arrayTypeExtremites(t).some(
          (e) => e.code === parseInt(selectedButton)
        );
      }
    ),

    [S06_MANCHETTE_LARGEUR_IT1.description]: Yup.mixed().when(
      [S06_TYPE_EXTREMITES_IT1.description].toString(),
      {
        is: (type) => {
         return parseInt(type) === S06_STEP6_arrayTypeExtremites(t)[0].code;
        },
        then: testPositif(t),
        otherwise: otherwiseSchema,
      }
    ),

    [ALIGNEMENT_IT1.description]: Yup.mixed().when(
      [S06_TYPE_EXTREMITES_IT1.description].toString(),
      {
        is: (type) =>
          parseInt(type) === S06_STEP6_arrayTypeExtremites(t)[0].code,
        then: Yup.mixed()
          .test(
            "alignementIt1",
            () => {
              return t.erreurSelectionFixation;
            },
            (value) =>
              S06_STEP6_arrayAlignement(t).some((e) => e.code === value)
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [S06_BRIDE_IT1.description]: Yup.mixed().when(
      [S06_TYPE_EXTREMITES_IT1.description].toString(),
      {
        is: (type) => {
          return parseInt(type) === S06_STEP6_arrayTypeExtremites(t)[1].code;
         },
        then: Yup.mixed()
          .test(
            "brideType",
            () => {
              return t.erreurSelectionFixation;
            },
            (value) => S06_STEP6_arrayTypeBride(t).some((e) => e.code === value)
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_EXTREMITE_INSEREE_MATIERE_IT1.description]: Yup.mixed().when(
      [S06_TYPE_EXTREMITES_IT1.description].toString(),
      {
        is: (type) => {
          return parseInt(type) === S06_STEP6_arrayTypeExtremites(t)[1].code;
         },
        then: Yup.mixed()
          .test(
            "fixationBrideExtremiteInsereeMatiereHaut",
            () => {
              return t.erreurSelectionFixation;
            },
            (value) =>
              S06_STEP6_arrayExtremiteInsereeMatiere(t).some(
                (e) => e.code === value
              )
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    // [BRIDE_FIXATION_IT1.description]: Yup.mixed().when(
    //   [S06_TYPE_EXTREMITES_IT1.description].toString(),
    //   {
    //     is: (type) =>
    //       parseInt(type) === S06_STEP6_arrayTypeExtremites()[1].code,
    //     then: Yup.mixed()
    //       .test(
    //         "fixationBride",
    //         () => {
    //           return t.erreurSelectionFixation;
    //         },
    //         (value) =>
    //         S06_STEP6_arrayFixationBrideTypeAEtAcier(t).some((e) => e.code === value)
    //       )
    //       .required(t.renseignerChamp),
    //     otherwise: otherwiseSchema,
    //   }
    // ),

    [BRIDE_FIXATION_IT1.description]: Yup.mixed().when(
      [
        S06_TYPE_EXTREMITES_IT1.description,
        S06_BRIDE_IT1.description,
        BRIDE_EXTREMITE_INSEREE_MATIERE_IT1.description,
      ].map(field => field.toString()),
      {
        is: (typeExtremite, brideType, matiereType) => {
          return parseInt(typeExtremite) === S06_STEP6_arrayTypeExtremites()[1].code && parseInt(brideType) === 0 && parseInt(matiereType) === 1;
        },
        then: Yup.mixed().test(
          "fixationBride",
          () => {
            return t.erreurSelectionFixation;
          },
          (value) =>
            S06_STEP6_arrayFixationBrideTypeAEtAcier(t).some((e) => e.code === value)
        ).required(t.renseignerChamp),
        otherwise: Yup.mixed().when(
          [
            S06_TYPE_EXTREMITES_IT1.description,
            S06_BRIDE_IT1.description,
            BRIDE_EXTREMITE_INSEREE_MATIERE_IT1.description,
          ].map(field => field.toString()),
          {
            is: (typeExtremite, brideType, matiereType) => {
              return parseInt(typeExtremite) === S06_STEP6_arrayTypeExtremites()[1].code &&  parseInt(brideType) === 0 && parseInt(matiereType) !== 1;
            },
            then: Yup.mixed().test(
              "fixationBride",
              () => {
                return t.erreurSelectionFixation;
              },
              (value) =>
                S06_STEP6_arrayFixationBrideTypeA(t).some((e) => e.code === value)
            ).required(t.renseignerChamp),
            otherwise: Yup.mixed().when(
              [
                S06_TYPE_EXTREMITES_IT1.description,
                S06_BRIDE_IT1.description,
                BRIDE_EXTREMITE_INSEREE_MATIERE_IT1.description
              ].map(field => field.toString()),
              {
                is: (typeExtremite, brideType, matiereType) => {
                  return parseInt(typeExtremite) === 2 && parseInt(brideType) !== 0;
                },
                then: Yup.mixed().test(
                  "fixationBride",
                  () => {
                    return t.erreurSelectionFixation;
                  },
                  (value) =>
                    S06_STEP6_arrayFixationBride(t).some((e) => e.code === value)
                ).required(t.renseignerChamp),
                otherwise: otherwiseSchema,
              }
            ),
          }
        ),
      }
    ),
    
    
    

    //IT2
    [S06_TYPE_EXTREMITES_IT2.description]: Yup.mixed().when(
      [S06_EXTREMITE_IDENTIQUE.description].toString(),
      {
        is: (boolean) => boolean === 0,
        then: Yup.mixed()
          .test(
            "RadioButtonTypeExtremitesIt2",
            () => {
              return t.renseignerChamp;
            },
            (selectedButton) =>
              S06_STEP6_arrayTypeExtremites(t).some(
                (e) => e.code === parseInt(selectedButton)
              )
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [S06_MANCHETTE_LARGEUR_IT2.description]: Yup.mixed().when(
      [
        [S06_TYPE_EXTREMITES_IT2.description].toString(),
        [S06_EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, boolean) =>
          parseInt(type) === S06_STEP6_arrayTypeExtremites()[0].code &&
          boolean === 0,
        then: testPositif(t),
        otherwise: otherwiseSchema,
      }
    ),

    [ALIGNEMENT_IT2.description]: Yup.mixed().when(
      [
        [S06_TYPE_EXTREMITES_IT2.description].toString(),
        [S06_EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, boolean) =>
          parseInt(type) === S06_STEP6_arrayTypeExtremites()[0].code &&
          boolean === 0,
        then: Yup.mixed()
          .test(
            "alignementIt2",
            () => {
              return t.erreurSelectionFixation;
            },
            (value) =>
              S06_STEP6_arrayAlignement(t).some((e) => e.code === value)
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [S06_BRIDE_IT2.description]: Yup.mixed().when(
      [
        [S06_TYPE_EXTREMITES_IT2.description].toString(),
        [S06_EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, boolean) =>
          parseInt(type) === S06_STEP6_arrayTypeExtremites()[1].code &&
          boolean === 0,
        then: Yup.mixed()
          .test(
            "brideType",
            () => {
              return t.erreurSelectionFixation;
            },
            (value) => S06_STEP6_arrayTypeBride(t).some((e) => e.code === value)
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_EXTREMITE_INSEREE_MATIERE_IT2.description]: Yup.mixed().when(
      [
        [S06_TYPE_EXTREMITES_IT2.description].toString(),
        [S06_EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, boolean) =>
          parseInt(type) === S06_STEP6_arrayTypeExtremites()[1].code &&
          boolean === 0,
        then: Yup.mixed()
          .test(
            "fixationBrideExtremiteInsereeMatiereHaut",
            () => {
              return t.erreurSelectionFixation;
            },
            (value) =>
              S06_STEP6_arrayExtremiteInsereeMatiere(t).some(
                (e) => e.code === value
              )
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    // [BRIDE_FIXATION_IT2.description]: Yup.mixed().when(
    //   [
    //     [S06_TYPE_EXTREMITES_IT2.description].toString(),
    //     [S06_EXTREMITE_IDENTIQUE.description].toString(),
    //   ],
    //   {
    //     is: (type, boolean) =>
    //       parseInt(type) === S06_STEP6_arrayTypeExtremites()[1].code
    //       && boolean === 0,
    //     then: Yup.mixed()
    //       .test(
    //         "fixationBride",
    //         () => {
    //           return t.erreurSelectionFixation;
    //         },
    //         (value) =>
    //         S06_STEP6_arrayFixationBrideTypeAEtAcier(t).some((e) => e.code === value)
    //       )
    //       .required(t.renseignerChamp),
    //     otherwise: otherwiseSchema,
    //   }
    // ),

    [BRIDE_FIXATION_IT2.description]: Yup.mixed().when(
      [
        S06_TYPE_EXTREMITES_IT2.description,
        S06_BRIDE_IT2.description,
        BRIDE_EXTREMITE_INSEREE_MATIERE_IT2.description,
        S06_EXTREMITE_IDENTIQUE.description,
      ].map(field => field.toString()),
      {
        is: (typeExtremite, brideType, matiereType, boolean) => {
          return parseInt(typeExtremite) === S06_STEP6_arrayTypeExtremites()[1].code && parseInt(brideType) === 0 && parseInt(matiereType) === 1 && !!boolean === 0;
        },
        then: Yup.mixed().test(
          "fixationBride",
          () => {
            return t.erreurSelectionFixation;
          },
          (value) =>
            S06_STEP6_arrayFixationBrideTypeAEtAcier(t).some((e) => e.code === value)
        ).required(t.renseignerChamp),
        otherwise: Yup.mixed().when(
          [
            S06_TYPE_EXTREMITES_IT2.description,
            S06_BRIDE_IT2.description,
            BRIDE_EXTREMITE_INSEREE_MATIERE_IT2.description,
            S06_EXTREMITE_IDENTIQUE.description,
          ].map(field => field.toString()),
          {
            is: (typeExtremite, brideType, matiereType, boolean) => {
              return parseInt(typeExtremite) === S06_STEP6_arrayTypeExtremites()[1].code &&  parseInt(brideType) === 0 && parseInt(matiereType) !== 1 && !!boolean === 0;
            },
            then: Yup.mixed().test(
              "fixationBride",
              () => {
                return t.erreurSelectionFixation;
              },
              (value) =>
                S06_STEP6_arrayFixationBrideTypeA(t).some((e) => e.code === value)
            ).required(t.renseignerChamp),
            otherwise: Yup.mixed().when(
              [
                S06_TYPE_EXTREMITES_IT2.description,
                S06_BRIDE_IT2.description,
                BRIDE_EXTREMITE_INSEREE_MATIERE_IT2.description,
                S06_EXTREMITE_IDENTIQUE.description,
              ].map(field => field.toString()),
              {
                is: (typeExtremite, brideType, matiereType, boolean) => {
                  return parseInt(typeExtremite) === 2 && parseInt(brideType) !== 0 && boolean === 0;
                },
                then: Yup.mixed().test(
                  "fixationBride",
                  () => {
                    return t.erreurSelectionFixation;
                  },
                  (value) =>
                    S06_STEP6_arrayFixationBride(t).some((e) => e.code === value)
                ).required(t.renseignerChamp),
                otherwise: otherwiseSchema,
              }
            ),
          }
        ),
      }
    ),


  });
};
