import { Grid } from "@mui/material";
import {
  StyledDivFormSection,
  StyledDivLabelBgBlueColorWhite,
  StyledDivLabelQuestion,
} from "../../../styledComponents/StyledDiv";
import { HelperText } from "../../HelperText";

/**
 * It generates a grid item with a label and a text field.
 * @param champ - The field to be rendered.
 * @returns A Grid item with a div containing the label of the field.
 */
const genererChampText = (champ) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <Grid container>
          <Grid item xs={champ.textInformation?.text ? 11 : 12}>
            <StyledDivLabelQuestion
              className={
                champ.cssFontFamily +
                " " +
                champ.csslabelQuestionConfirmation +
                " " +
                champ.cssMsgConfirmation
              }
              id={champ.nom.description}
              dangerouslySetInnerHTML={{ __html: champ.label }}
            />
          </Grid>
          {champ.textInformation?.text ? (
            <Grid item xs={1}>
              <HelperText
                name={champ.nom.description}
                textInformation={champ.textInformation}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </StyledDivFormSection>
  );
};

/**
 * It generates a grid item with a label and a text field.
 * @param champ - The field to be rendered.
 * @returns A Grid item with a div containing the label of the field.
 */
const genererChampTextCustom1 = (champ) => {

  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <Grid container>
          <Grid item xs={champ.textInformation?.text ? 11 : 12}>
            <StyledDivLabelBgBlueColorWhite
              
              id={champ.nom.description}
              dangerouslySetInnerHTML={{ __html: champ.label }}
            />
          </Grid>
          {champ.textInformation?.text ? (
            <Grid item xs={1}>
              <HelperText
                name={champ.nom.description}
                textInformation={champ.textInformation}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </StyledDivFormSection>
  );
};

export { genererChampText, genererChampTextCustom1 };
