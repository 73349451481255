import { Paper } from "@mui/material";
import {
  StyledPath1,
  StyledPathAxeSymetrie,
  StyledPathDotted,
  StyledPathFleche,
  StyledPathCote,
} from "../styledComponents/StyledPath";
import {
  arraySectionExtremite,
  arrayTypeExtremites,
} from "../../../constantes/symbols/SymbolsServicesS01";
import {
  checkFocusedArrow,
  checkFocusedArrowWithDependance,
  checkFocusedEndMarker,
  checkFocusedEndMarkerWithDependance,
  checkFocusedStartMarker,
  checkFocusedStartMarkerWithDependance,
  checkFocusedText,
  checkFocusedTextWithDependance,
  coefAffichageBride,
  coefAffichageBrideIT1,
  coefAffichageBrideIT2,
  coefAffichageManchette,
} from "../../../helper/ConditionnalCssProvider";
import {
  LONGUEUR_COMPRIMEE,
  LONGUEUR_DEPLOYEE,
  RONDE_DROIT_EXTERIEUR_MAX,
  RONDE_DROIT_INTERIEUR_MIN,
  SOUFFLET_AVEC_SPIRE,
  BRIDE_A_IT1,
  BRIDE_A_IT2,
  BRIDE_B_IT1,
  BRIDE_B_IT2,
  BRIDE_DIAMETRE_IT1,
  BRIDE_DIAMETRE_IT2,
  BRIDE_IT1,
  BRIDE_IT2,
  EXTREMITE_IDENTIQUE,
  MANCHETTE_A_IT1,
  MANCHETTE_A_IT2,
  MANCHETTE_B_IT1,
  MANCHETTE_B_IT2,
  MANCHETTE_DIAMETRE_IT1,
  MANCHETTE_DIAMETRE_IT2,
  MANCHETTE_IT1,
  MANCHETTE_IT2,
  MANCHETTE_LARGEUR_IT1,
  MANCHETTE_LARGEUR_IT2,
  TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT2,
} from "../../../constantes/symbols/SymbolsS01";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../constantes/symbols/HelperSymbolsServices";

export const RondDroit = ({ styles, formik, t }) => {
  const longueurComprimee = formik[LONGUEUR_COMPRIMEE.description] || "";
  const longueurDeployee = formik[LONGUEUR_DEPLOYEE.description] || "";
  const interieurMin = formik[RONDE_DROIT_INTERIEUR_MIN.description] || "";
  const exterieurMax = formik[RONDE_DROIT_EXTERIEUR_MAX.description] || "";
  const spire =
    formik[SOUFFLET_AVEC_SPIRE.description] !== undefined
      ? equalsBooleanOrStringOrNumber(formik[SOUFFLET_AVEC_SPIRE.description])
      : "";

  const afficherManchetteDroite = (largeur, aire) => {
    const coefManchette =
      20 *
      coefAffichageManchette(
        MANCHETTE_LARGEUR_IT1,
        MANCHETTE_LARGEUR_IT2,
        formik
      );
    return (
      <>
        {/*Fleche taille ouverture droite*/}
        <StyledPathFleche
          d="M 210,140 L 210,107"
          markerEnd={checkFocusedEndMarker(formik, [MANCHETTE_DIAMETRE_IT1])}
          className={checkFocusedArrow(
            formik,
            [MANCHETTE_DIAMETRE_IT1],
            styles
          )}
        />
        {/*Ligne pour taille ouverture droite */}
        <StyledPathCote
          d={"M " + (180 + coefManchette + 3) + ",100 L 213,100"}
        />
        {/*Extremité droite pour manchette*/}
        <StyledPath1 d={"M 180,100 L" + (180 + coefManchette) + ",100"} />
        {/*Fleche Largeur droite*/}
        <StyledPathFleche
          d={"M 174,62 L " + (180 + coefManchette + 6) + ",62"}
          markerStart={checkFocusedEndMarker(formik, [MANCHETTE_LARGEUR_IT1])}
          markerEnd={checkFocusedStartMarker(formik, [MANCHETTE_LARGEUR_IT1])}
          className={checkFocusedArrow(formik, [MANCHETTE_LARGEUR_IT1], styles)}
        />
        {/*Ligne pour mesure largeur manchette droite*/}
        <StyledPathCote
          d={
            "M " +
            (180 + coefManchette) +
            ",60 L " +
            (180 + coefManchette) +
            ",100"
          }
        />
        {/*Largeur droite text*/}
        <text
          fontSize="0.4em"
          x="186"
          y="60"
          className={checkFocusedText(formik, [MANCHETTE_LARGEUR_IT1], styles)}
        >
          {/*{formik[MANCHETTE_LARGEUR_IT1.description]}*/}
          {largeur}
        </text>
        {/*Diametre extremite droite*/}
        <text
          fontSize="0.4em"
          x="212"
          y="135"
          className={checkFocusedText(formik, [MANCHETTE_DIAMETRE_IT1], styles)}
        >
          {/*D{formik[MANCHETTE_DIAMETRE_IT1.description]}*/}
          {aire}
        </text>
        {/*Cote largeur droit*/}
        <StyledPathCote d="M 180,60 L 180,100" />
      </>
    );
  };

  const afficherManchetteGauche = (largeur, aire) => {
    const coefManchette =
      20 *
      coefAffichageManchette(
        MANCHETTE_LARGEUR_IT2,
        MANCHETTE_LARGEUR_IT1,
        formik
      );
    return (
      <>
        {/*Fleche taille ouverture gauche*/}
        <StyledPathFleche
          d="M 30,127 L 30,107"
          markerEnd={checkFocusedEndMarkerWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1],
            [MANCHETTE_DIAMETRE_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1],
            [MANCHETTE_DIAMETRE_IT2],
            styles
          )}
        />
        <StyledPathFleche
          d="M 30,140 L 30,137"
          className={checkFocusedArrowWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1],
            [MANCHETTE_DIAMETRE_IT2],
            styles
          )}
        />
        {/*Ligne pour taille ouverture gauche */}
        <StyledPathCote d={"M 27,100 L " + (60 - coefManchette - 3) + ",100"} />
        {/*Extremité gauche pour manchette*/}
        <StyledPath1 d={"M " + (60 - coefManchette) + ",100 L 60,100"} />
        {/*Fleche Largeur gauche*/}
        <StyledPathFleche
          d={"M " + (60 - coefManchette - 6) + ",62 L 66,62"}
          markerStart={checkFocusedEndMarkerWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2]
          )}
          markerEnd={checkFocusedStartMarkerWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2],
            styles
          )}
        />
        {/*Ligne pour mesure largeur manchette gauche*/}
        <StyledPathCote
          d={
            "M " +
            (60 - coefManchette) +
            ",60 L " +
            (60 - coefManchette) +
            ",100"
          }
        />
        {/*Largeur gauche text*/}
        <text
          fontSize="0.4em"
          x="47"
          y="60"
          className={checkFocusedTextWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2],
            styles
          )}
        >
          {largeur}
        </text>
        {/*Diametre extremite gauche*/}
        <text
          fontSize="0.4em"
          x="17"
          y="135"
          className={checkFocusedTextWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1],
            [MANCHETTE_DIAMETRE_IT2],
            styles
          )}
        >
          {aire}
        </text>
        {/*Cote largeur gauche*/}
        <StyledPathCote d="M 60,60 L 60,100" />
      </>
    );
  };

  const afficherBrideDroite = (aire) => {
    const coefBride =
      20 *
      coefAffichageBrideIT1(
        [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1],
        [RONDE_DROIT_INTERIEUR_MIN, RONDE_DROIT_INTERIEUR_MIN],
        RONDE_DROIT_EXTERIEUR_MAX,
        formik
      );
    return (
      <>
        {/*Cote largeur droit*/}
        <StyledPathCote d={"M 180,60 L 180,95"} />
        {!!spire ? (
          <text fontSize="0.4em" x="175" y="125">
            Ø{interieurMin}
          </text>
        ) : (
          <text fontSize="0.4em" x="170" y="125">
            Ø{interieurMin}
          </text>
        )}

        {/*Fleche taille ouverture droite bas*/}
        <StyledPathFleche d="M 191,140 L 191,107" markerEnd="url(#endarrow)" />
        {/*Ligne pour taille ouverture droite bas*/}
        <StyledPathCote d="M 183,100 L 193,100" />
        {/*Fleche taille ouverture droite haut*/}
        <StyledPathFleche
          d={"M 201,140 L 201," + (107 - coefBride)}
          markerEnd={checkFocusedEndMarker(formik, [
            BRIDE_DIAMETRE_IT1,
            BRIDE_A_IT1,
            BRIDE_B_IT1,
          ])}
          className={checkFocusedArrow(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            styles
          )}
        />
        {/*Ligne pour taille ouverture droite haut*/}
        <StyledPathCote
          d={"M 183," + (100 - coefBride) + " L 203," + (100 - coefBride)}
        />
        {/*Extremité droite pour bride*/}
        <StyledPath1 d={"M 180," + (100 - coefBride) + " L 180, 100"} />
        {/*Diametre extremite droite*/}
        <text
          fontSize="0.4em"
          x="202"
          y="135"
          className={checkFocusedText(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            styles
          )}
        >
          {/*D{formik[BRIDE_DIAMETRE_IT1.description]}*/}
          {aire}
        </text>
      </>
    );
  };

  const afficherBrideGauche = (aire) => {
    const coefBride =
      20 *
      coefAffichageBrideIT2(
        [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2],
        [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1],
        [
          [RONDE_DROIT_INTERIEUR_MIN, RONDE_DROIT_INTERIEUR_MIN],
          [RONDE_DROIT_INTERIEUR_MIN, RONDE_DROIT_INTERIEUR_MIN],
        ],
        RONDE_DROIT_EXTERIEUR_MAX,
        formik
      );
    return (
      <>
        {/*Cote largeur gauche*/}
        <StyledPathCote d="M 60,60 L 60,95" />

        <text fontSize="0.4em" x="50" y="125">
          Ø{interieurMin}
        </text>

        {/*Fleche taille ouverture gauche bas*/}
        <StyledPathFleche d="M 49,140 L 49,107" markerEnd="url(#endarrow)" />
        {/*Ligne pour taille ouverture gauche bas*/}
        <StyledPathCote d="M 47,100 L 57,100" />

        {/*Fleche taille ouverture gauche haut*/}
        <StyledPathFleche
          d={"M 39,140 L 39," + (100 - coefBride + 7)}
          markerEnd={checkFocusedEndMarkerWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
            styles
          )}
        />
        {/*Ligne pour taille ouverture gauche haut*/}
        <StyledPathCote
          d={"M 37," + (100 - coefBride) + " L 57," + (100 - coefBride)}
        />

        {/*Extremité gauche pour bride*/}
        <StyledPath1 d={"M 60," + (100 - coefBride) + " L 60,100"} />

        {/*Diametre extremite gauche*/}
        <text
          fontSize="0.4em"
          x="17"
          y="135"
          className={checkFocusedTextWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
            styles
          )}
        >
          {aire}
        </text>
      </>
    );
  };

  const afficherManchetteEtBrideDroite = (
    aireManchette,
    aireBride,
    largeurManchette
  ) => {
    const coefManchette =
      10 *
      coefAffichageManchette(
        MANCHETTE_LARGEUR_IT1,
        MANCHETTE_LARGEUR_IT2,
        formik
      );
    const coefBride =
      20 *
      coefAffichageBrideIT1(
        [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1],
        [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1],
        RONDE_DROIT_EXTERIEUR_MAX,
        formik
      );

    return (
      <>
        {/*Cote bride droit*/}
        <StyledPathCote
          d={
            "M " +
            (180 + coefManchette) +
            ",60 L " +
            (180 + coefManchette) +
            ",80"
          }
        />

        {/*Fleche cote bride droite bas*/}
        <StyledPathFleche
          d={"M 174,62 L " + (180 + coefManchette + 5) + ",62"}
          markerStart={checkFocusedEndMarker(formik, [MANCHETTE_LARGEUR_IT1])}
          markerEnd={checkFocusedStartMarker(formik, [MANCHETTE_LARGEUR_IT1])}
          className={checkFocusedArrow(formik, [MANCHETTE_LARGEUR_IT1], styles)}
        />
        <text
          fontSize="0.4em"
          x="181"
          y="60"
          className={checkFocusedText(formik, [MANCHETTE_LARGEUR_IT1], styles)}
        >
          {largeurManchette}
        </text>

        {/*Fleche taille ouverture droite haut*/}
        <StyledPathFleche
          d={"M 201,140 L 201," + (100 - coefBride + 7)}
          markerEnd={checkFocusedEndMarker(formik, [
            BRIDE_DIAMETRE_IT1,
            BRIDE_A_IT1,
            BRIDE_B_IT1,
          ])}
          className={checkFocusedArrow(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            styles
          )}
        />
        {!!spire ? (
          <text
            fontSize="0.4em"
            x="178"
            y="125"
            className={checkFocusedText(
              formik,
              [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
              styles
            )}
          >
            {aireManchette}
          </text>
        ) : (
          <text
            fontSize="0.4em"
            x="181"
            y="125"
            className={checkFocusedText(
              formik,
              [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
              styles
            )}
          >
            {aireManchette}
          </text>
        )}

        {/*Ligne pour taille ouverture droite haut*/}
        <StyledPathCote
          d={
            "M " +
            (180 + coefManchette + 3) +
            "," +
            (100 - coefBride) +
            " L 203," +
            (100 - coefBride)
          }
        />

        {/*Fleche taille ouverture droite*/}
        <StyledPathFleche
          d="M 195,120 L 195,107"
          markerEnd={checkFocusedEndMarker(formik, [
            MANCHETTE_DIAMETRE_IT1,
            MANCHETTE_A_IT1,
            MANCHETTE_B_IT1,
          ])}
          className={checkFocusedArrow(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            styles
          )}
        />
        <StyledPathFleche
          d="M 195,126 L 195,140"
          className={checkFocusedArrow(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            styles
          )}
        />

        {/*Ligne pour taille ouverture droite */}
        <StyledPathCote
          d={"M " + (180 + coefManchette + 3) + ",100 L 197,100"}
        />

        {/*Ligne pour mesure debut largeur manchette droite*/}
        <StyledPathCote d="M 180,60 L 180,100" />

        {/*Extremité droite pour bride*/}
        <StyledPath1
          d={
            "M 180,100 L " +
            (180 + coefManchette) +
            ",100 L " +
            (180 + coefManchette) +
            "," +
            (100 - coefBride)
          }
        />
        {/*Diametre extremite droite*/}
        <text
          fontSize="0.4em"
          x="202"
          y="135"
          className={checkFocusedText(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            styles
          )}
        >
          {aireBride}
        </text>
      </>
    );
  };

  const afficherManchetteEtBrideGauche = (
    aireManchette,
    aireBride,
    largeurManchette
  ) => {
    const coefManchette =
      10 *
      coefAffichageManchette(
        MANCHETTE_LARGEUR_IT2,
        MANCHETTE_LARGEUR_IT1,
        formik
      );
    const coefBride =
      20 *
      coefAffichageBrideIT2(
        [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2],
        [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1],
        [
          [MANCHETTE_DIAMETRE_IT1, MANCHETTE_DIAMETRE_IT2],
          [MANCHETTE_A_IT1, MANCHETTE_A_IT2],
        ],
        RONDE_DROIT_EXTERIEUR_MAX,
        formik
      );

    return (
      <>
        {/*Cote bride gauche*/}
        <StyledPathCote
          d={
            "M " +
            (60 - coefManchette) +
            ",60 L " +
            (60 - coefManchette) +
            ",80"
          }
        />
        {/*Fleche cote bride gauche bas*/}
        <StyledPathFleche
          d={"M " + (60 - coefManchette - 6) + ",62 L 66,62"}
          markerEnd={checkFocusedStartMarkerWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2]
          )}
          markerStart={checkFocusedEndMarkerWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="51.5"
          y="60"
          className={checkFocusedTextWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2],
            styles
          )}
        >
          {largeurManchette}
        </text>

        {/*Fleche taille ouverture gauche haut*/}
        <StyledPathFleche
          d={"M 39,130 L 39," + (100 - coefBride + 7)}
          markerEnd={checkFocusedEndMarkerWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
            styles
          )}
        />

        <StyledPathFleche
          d="M 39,140 L 39,137"
          className={checkFocusedArrowWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
            styles
          )}
        />
        {!!spire ? (
          <text
            fontSize="0.4em"
            x="47"
            y="125"
            className={checkFocusedTextWithDependance(
              formik,
              [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
              [MANCHETTE_DIAMETRE_IT2, MANCHETTE_A_IT2, MANCHETTE_B_IT2],
              styles
            )}
          >
            {aireManchette}
          </text>
        ) : (
          <text
            fontSize="0.4em"
            x="46"
            y="125"
            className={checkFocusedTextWithDependance(
              formik,
              [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
              [MANCHETTE_DIAMETRE_IT2, MANCHETTE_A_IT2, MANCHETTE_B_IT2],
              styles
            )}
          >
            {aireManchette}
          </text>
        )}

        {/*Ligne pour taille ouverture gauche haut*/}
        <StyledPathCote
          d={
            "M 37," +
            (100 - coefBride) +
            " L " +
            (60 - coefManchette - 3) +
            "," +
            (100 - coefBride)
          }
        />

        {/*Fleche taille ouverture gauche*/}
        <StyledPathFleche
          d="M 45,140 L 45,107"
          markerEnd={checkFocusedEndMarkerWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            [MANCHETTE_DIAMETRE_IT2, MANCHETTE_A_IT2, MANCHETTE_B_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            [MANCHETTE_DIAMETRE_IT2, MANCHETTE_A_IT2, MANCHETTE_B_IT2],
            styles
          )}
        />

        {/*Ligne pour taille ouverture gauche */}
        <StyledPathCote d={"M 43,100 L " + (60 - coefManchette - 3) + ",100"} />

        {/*Ligne pour mesure largeur manchette gauche*/}
        <StyledPathCote d="M 60,60 L 60,100" />
        {/* manchette gauche */}
        <StyledPath1 d={"M " + (60 - coefManchette) + ",100 L 60,100"} />
        {/* <StyledPath1 d="M 50,100 L 60,100" /> */}
        {/*Extremité gauche pour bride*/}
        <StyledPath1
          d={
            "M " +
            (60 - coefManchette) +
            "," +
            (100 - coefBride) +
            " L " +
            (60 - coefManchette) +
            ",100"
          }
        />
        {/*Diametre extremite gauche*/}
        {!!spire ? (
          <text
            fontSize="0.4em"
            x="17"
            y="135"
            className={checkFocusedTextWithDependance(
              formik,
              [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
              [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
              styles
            )}
          >
            {aireBride}
          </text>
        ) : (
          <text
            fontSize="0.4em"
            x="25"
            y="135"
            className={checkFocusedTextWithDependance(
              formik,
              [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
              [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
              styles
            )}
          >
            {aireBride}
          </text>
        )}
      </>
    );
  };

  const afficherIT1 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT1.description],
        arrayTypeExtremites(),
        0
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT1.description],
            arraySectionExtremite(),
            0
          ):
            return (
              <>
                {afficherManchetteDroite(
                  formik[MANCHETTE_LARGEUR_IT1.description],
                  "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description]
                )}
              </>
            );
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT1.description],
            arraySectionExtremite(),
            1
          ):
            return (
              <>
                {afficherManchetteDroite(
                  formik[MANCHETTE_LARGEUR_IT1.description],
                  formik[MANCHETTE_A_IT1.description] +
                    "X" +
                    formik[MANCHETTE_B_IT1.description]
                )}
              </>
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT1.description],
        arrayTypeExtremites(),
        1
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[BRIDE_IT1.description],
            arraySectionExtremite(),
            0
          ):
            return (
              <>
                {afficherBrideDroite(
                  "Ø" + formik[BRIDE_DIAMETRE_IT1.description]
                )}
              </>
            );
          case equalsCodeOrLabel(
            formik[BRIDE_IT1.description],
            arraySectionExtremite(),
            1
          ):
            return (
              <>
                {afficherBrideDroite(
                  formik[BRIDE_A_IT1.description] +
                    "X" +
                    formik[BRIDE_B_IT1.description]
                )}
              </>
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT1.description],
        arrayTypeExtremites(),
        2
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT1.description],
            arraySectionExtremite(),
            0
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteEtBrideDroite(
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                      "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                      formik[MANCHETTE_LARGEUR_IT1.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteEtBrideDroite(
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                      formik[BRIDE_A_IT1.description] +
                        "X" +
                        formik[BRIDE_B_IT1.description],
                      formik[MANCHETTE_LARGEUR_IT1.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT1.description],
            arraySectionExtremite(),
            1
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteEtBrideDroite(
                      formik[MANCHETTE_A_IT1.description] +
                        "X" +
                        formik[MANCHETTE_B_IT1.description],
                      "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                      formik[MANCHETTE_LARGEUR_IT1.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteEtBrideDroite(
                      formik[MANCHETTE_A_IT1.description] +
                        "X" +
                        formik[MANCHETTE_B_IT1.description],
                      formik[BRIDE_A_IT1.description] +
                        "X" +
                        formik[BRIDE_B_IT1.description],
                      formik[MANCHETTE_LARGEUR_IT1.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const afficherIT2 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT2.description],
        arrayTypeExtremites(),
        0
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT2.description],
            arraySectionExtremite(),
            0
          ):
            return (
              <>
                {afficherManchetteGauche(
                  formik[MANCHETTE_LARGEUR_IT2.description],
                  "Ø" + formik[MANCHETTE_DIAMETRE_IT2.description]
                )}
              </>
            );
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT2.description],
            arraySectionExtremite(),
            1
          ):
            return (
              <>
                {afficherManchetteGauche(
                  formik[MANCHETTE_LARGEUR_IT2.description],
                  formik[MANCHETTE_A_IT2.description] +
                    "X" +
                    formik[MANCHETTE_B_IT2.description]
                )}
              </>
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT2.description],
        arrayTypeExtremites(),
        1
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[BRIDE_IT2.description],
            arraySectionExtremite(),
            0
          ):
            return (
              <>
                {afficherBrideGauche(
                  "Ø" + formik[BRIDE_DIAMETRE_IT2.description]
                )}
              </>
            );
          case equalsCodeOrLabel(
            formik[BRIDE_IT2.description],
            arraySectionExtremite(),
            1
          ):
            return (
              <>
                {afficherBrideGauche(
                  formik[BRIDE_A_IT2.description] +
                    "X" +
                    formik[BRIDE_B_IT2.description]
                )}
              </>
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT2.description],
        arrayTypeExtremites(),
        2
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT2.description],
            arraySectionExtremite(),
            0
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT2.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteEtBrideGauche(
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT2.description],
                      "Ø" + formik[BRIDE_DIAMETRE_IT2.description],
                      formik[MANCHETTE_LARGEUR_IT2.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT2.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteEtBrideGauche(
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT2.description],
                      formik[BRIDE_A_IT2.description] +
                        "X" +
                        formik[BRIDE_B_IT2.description],
                      formik[MANCHETTE_LARGEUR_IT2.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT2.description],
            arraySectionExtremite(),
            1
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT2.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteEtBrideGauche(
                      formik[MANCHETTE_A_IT2.description] +
                        "X" +
                        formik[MANCHETTE_B_IT2.description],
                      "Ø" + formik[BRIDE_DIAMETRE_IT2.description],
                      formik[MANCHETTE_LARGEUR_IT2.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT2.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteEtBrideGauche(
                      formik[MANCHETTE_A_IT2.description] +
                        "X" +
                        formik[MANCHETTE_B_IT2.description],
                      formik[BRIDE_A_IT2.description] +
                        "X" +
                        formik[BRIDE_B_IT2.description],
                      formik[MANCHETTE_LARGEUR_IT2.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const afficherExtremitesIdentiques = () => {
    switch (
      equalsBooleanOrStringOrNumber(formik[EXTREMITE_IDENTIQUE.description])
    ) {
      case 1:
        switch (true) {
          case equalsCodeOrLabel(
            formik[TYPE_EXTREMITES_IT1.description],
            arrayTypeExtremites(),
            0
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[MANCHETTE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteDroite(
                      formik[MANCHETTE_LARGEUR_IT1.description],
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description]
                    )}
                    {afficherManchetteGauche(
                      formik[MANCHETTE_LARGEUR_IT1.description],
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[MANCHETTE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteDroite(
                      formik[MANCHETTE_LARGEUR_IT1.description],
                      formik[MANCHETTE_A_IT1.description] +
                        "X" +
                        formik[MANCHETTE_B_IT1.description]
                    )}
                    {afficherManchetteGauche(
                      formik[MANCHETTE_LARGEUR_IT1.description],
                      formik[MANCHETTE_A_IT1.description] +
                        "X" +
                        formik[MANCHETTE_B_IT1.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          case equalsCodeOrLabel(
            formik[TYPE_EXTREMITES_IT1.description],
            arrayTypeExtremites(),
            1
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherBrideDroite(
                      "Ø" + formik[BRIDE_DIAMETRE_IT1.description]
                    )}
                    {afficherBrideGauche(
                      "Ø" + formik[BRIDE_DIAMETRE_IT1.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherBrideDroite(
                      formik[BRIDE_A_IT1.description] +
                        "X" +
                        formik[BRIDE_B_IT1.description]
                    )}
                    {afficherBrideGauche(
                      formik[BRIDE_A_IT1.description] +
                        "X" +
                        formik[BRIDE_B_IT1.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          case equalsCodeOrLabel(
            formik[TYPE_EXTREMITES_IT1.description],
            arrayTypeExtremites(),
            2
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[MANCHETTE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                switch (true) {
                  case equalsCodeOrLabel(
                    formik[BRIDE_IT1.description],
                    arraySectionExtremite(),
                    0
                  ):
                    return (
                      <>
                        {afficherManchetteEtBrideDroite(
                          "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                          "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                        {afficherManchetteEtBrideGauche(
                          "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                          "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                      </>
                    );
                  case equalsCodeOrLabel(
                    formik[BRIDE_IT1.description],
                    arraySectionExtremite(),
                    1
                  ):
                    return (
                      <>
                        {afficherManchetteEtBrideDroite(
                          "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                          formik[BRIDE_A_IT1.description] +
                            "X" +
                            formik[BRIDE_B_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                        {afficherManchetteEtBrideGauche(
                          "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                          formik[BRIDE_A_IT1.description] +
                            "X" +
                            formik[BRIDE_B_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                      </>
                    );
                  default:
                    return null;
                }
              case equalsCodeOrLabel(
                formik[MANCHETTE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                switch (true) {
                  case equalsCodeOrLabel(
                    formik[BRIDE_IT1.description],
                    arraySectionExtremite(),
                    0
                  ):
                    return (
                      <>
                        {afficherManchetteEtBrideDroite(
                          formik[MANCHETTE_A_IT1.description] +
                            "X" +
                            formik[MANCHETTE_B_IT1.description],
                          "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                        {afficherManchetteEtBrideGauche(
                          formik[MANCHETTE_A_IT1.description] +
                            "X" +
                            formik[MANCHETTE_B_IT1.description],
                          "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                      </>
                    );
                  case equalsCodeOrLabel(
                    formik[BRIDE_IT1.description],
                    arraySectionExtremite(),
                    1
                  ):
                    return (
                      <>
                        {afficherManchetteEtBrideDroite(
                          formik[MANCHETTE_A_IT1.description] +
                            "X" +
                            formik[MANCHETTE_B_IT1.description],
                          formik[BRIDE_A_IT1.description] +
                            "X" +
                            formik[BRIDE_B_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                        {afficherManchetteEtBrideGauche(
                          formik[MANCHETTE_A_IT1.description] +
                            "X" +
                            formik[MANCHETTE_B_IT1.description],
                          formik[BRIDE_A_IT1.description] +
                            "X" +
                            formik[BRIDE_B_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                      </>
                    );
                  default:
                    return null;
                }
              default:
                return null;
            }
          default:
            return null;
        }
      case 0:
        return (
          <>
            {afficherIT1()}
            {afficherIT2()}
          </>
        );

      default:
        return null;
    }
  };

  const displayRefWidthLine = () => {
    return (
      <>
        {/* Manchette gauche par defaut */}
        {/* <path d="M 40,100 L 60,100" className={styles.path1} /> */}
        {/* Manchette droite par defaut*/}
        {/* <path d="M 180,100 L 200,100" className={styles.path1} /> */}
        {/* Ligne pour mesure largeur manchette gauche */}
        <StyledPathCote d="M 60,60 L 60,100" />
        {/*Ligne pour mesure largeur manchette droite*/}
        <StyledPathCote d="M 180,60 L 180,100" />
      </>
    );
  };

  const afficherSpires = (t) => {
    return (
      <>
        {/*Membre gauche*/}
        <StyledPath1 d="M 60,100 L 70,80 L 80,100 L 90,80" />
        {/*Barre pointillée*/}
        <StyledPathDotted d="M 90,80 L 150,80 " className={styles.dotted} />
        {/*Membre droit*/}
        {/*afficher spire ou non ICI */}
        <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100" />

        {/*Axe de symétrie*/}
        <StyledPathAxeSymetrie d="M 15,140 L 225,140" />
        <text fontSize="0.4em" x="15" y="150">
          {t?.axeSymetrie}
        </text>

        {/*Fleche interieur premier cone*/}
        <StyledPathFleche
          d="M 70,128 L 70,87"
          markerEnd={checkFocusedEndMarker(formik, [RONDE_DROIT_EXTERIEUR_MAX])}
          className={checkFocusedArrow(
            formik,
            [RONDE_DROIT_EXTERIEUR_MAX],
            styles
          )}
        />
        <StyledPathFleche
          d="M 70,140 L 70,137"
          className={checkFocusedArrow(
            formik,
            [RONDE_DROIT_EXTERIEUR_MAX],
            styles
          )}
        />

        <text
          fontSize="0.4em"
          x="58"
          y="135"
          className={checkFocusedText(
            formik,
            [RONDE_DROIT_EXTERIEUR_MAX],
            styles
          )}
        >
          Ø{exterieurMax}
        </text>

        {/*Fleche exterieur droit premier cone*/}
        <StyledPathFleche
          d="M 80,128 L 80,107"
          markerEnd={checkFocusedEndMarker(formik, [RONDE_DROIT_INTERIEUR_MIN])}
          className={checkFocusedArrow(
            formik,
            [RONDE_DROIT_INTERIEUR_MIN],
            styles
          )}
        />
        <StyledPathFleche
          d="M 80,140 L 80,137"
          className={checkFocusedArrow(
            formik,
            [RONDE_DROIT_INTERIEUR_MIN],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="82"
          y="135"
          className={checkFocusedText(
            formik,
            [RONDE_DROIT_INTERIEUR_MIN],
            styles
          )}
        >
          Ø{interieurMin}
        </text>

        {/*Ligne avant pointillets 1*/}
        <StyledPathFleche d="M 90,128 L 90,80" />
        {/*Ligne avant pointillets 2*/}
        <StyledPathFleche d="M 90,140 L 90,137" />

        {/*Ligne apres pointillets*/}
        <StyledPathFleche d="M 150,140 L 150,80" />

        {/*Ligne exterieur gauche second cone*/}
        <StyledPathFleche d="M 160,140 L 160,100" />

        {/*Ligne interieur second cone*/}
        <StyledPathFleche d="M 170,140 L 170,80" />
      </>
    );
  };

  const afficherCompensateurRondDroit = (t) => {
    return (
      <>
        {/*ligne pointillets*/}
        <StyledPathDotted d="M 90,100 L 150,100 " className={styles.dotted} />

        {/*Membre gauche*/}
        <StyledPath1 d="M 60,100 L 90,100 " />

        {/*Membre droit*/}
        <StyledPath1 d="M 150,100 L 180,100" />

        {/*Axe de symétrie*/}
        <StyledPathAxeSymetrie d="M 15,140 L 225,140" />
        <text fontSize="0.4em" x="15" y="150">
        {t?.axeSymetrie}
        </text>

        {/*ligne avant pointillets 1*/}
        <path
          d="M 60,119 L 60,100"
          className={checkFocusedArrow(
            formik,
            [RONDE_DROIT_EXTERIEUR_MAX],
            styles
          )}
        />

        <path
          d="M 60,127 L 60,140"
          className={checkFocusedArrow(
            formik,
            [RONDE_DROIT_EXTERIEUR_MAX],
            styles
          )}
        />

        {/*ligne avant pointillets 2*/}
        <path
          d="M 90,140 L 90,100"
          className={checkFocusedArrow(
            formik,
            [RONDE_DROIT_EXTERIEUR_MAX],
            styles
          )}
        />

        {/*Fleche membre gauche*/}
        <StyledPathFleche
          d="M 75,128 L 75,107"
          markerEnd={checkFocusedEndMarker(formik, [RONDE_DROIT_INTERIEUR_MIN])}
          className={checkFocusedArrow(
            formik,
            [RONDE_DROIT_INTERIEUR_MIN],
            styles
          )}
        />
        <path
          d="M 75,140 L 75,137"
          className={checkFocusedArrow(
            formik,
            [RONDE_DROIT_INTERIEUR_MIN],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="70"
          y="135"
          className={checkFocusedText(
            formik,
            [RONDE_DROIT_INTERIEUR_MIN],
            styles
          )}
        >
          Ø{interieurMin}
        </text>

        {/*Ligne apres pointillets*/}
        <StyledPathFleche d="M 150,140 L 150,100" />

        {/*Ligne interieur second cone*/}
        <StyledPathFleche d="M 180,120 L 180,100" />
        <StyledPathFleche d="M 180,126 L 180,140" />
      </>
    );
  };

  const afficherDessinRondDroit = (t) => {
    if (!!spire) {
      return <>{afficherSpires(t)}</>;
    } else {
      return <>{afficherCompensateurRondDroit(t)}</>;
    }
  };

  return (
    <Paper elevation={0}>
      <svg viewBox="-10 40 255 120">
        <defs>
          <marker
            id="startarrow"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 2, 10 5, 0 3.5" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 2, 10 3.5, 0 5" />
          </marker>
          <marker
            id="startarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="10 2, 10 5, 0 3.5"
              className={styles.markerSelected}
            />
          </marker>
          <marker
            id="endarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon
              points="0 2, 10 3.5, 0 5"
              className={styles.markerSelected}
            />
          </marker>
        </defs>

        <title></title>

        {afficherDessinRondDroit(t)}

        {/*Fleche Largeur*/}
        <StyledPathFleche
          d="M 66,62 L 174,62"
          markerStart={checkFocusedStartMarker(formik, [
            LONGUEUR_DEPLOYEE,
            LONGUEUR_COMPRIMEE,
          ])}
          markerEnd={checkFocusedEndMarker(formik, [
            LONGUEUR_DEPLOYEE,
            LONGUEUR_COMPRIMEE,
          ])}
          className={checkFocusedArrow(
            formik,
            [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
            styles
          )}
        />
        {!!spire ? (
          <text
            fontSize="0.4em"
            x={longueurComprimee === "" ? "130" : "115"}
            y="60"
            direction="rtl"
            className={checkFocusedText(
              formik,
              [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
              styles
            )}
          >
            LD {longueurDeployee}
          </text>
        ) : (
          <text
            fontSize="0.4em"
            x="130"
            y="60"
            direction="rtl"
            className={checkFocusedText(
              formik,
              [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
              styles
            )}
          >
            LD {longueurDeployee}
          </text>
        )}
        {longueurComprimee === "" || !spire ? null : (
          <>
            <text
              fontSize="0.4em"
              x="120"
              y="60"
              textAnchor="middle"
              className={checkFocusedText(
                formik,
                [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              /
            </text>
            <text
              fontSize="0.4em"
              x="125"
              y="60"
              direction="ltr"
              className={checkFocusedText(
                formik,
                [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              LC {longueurComprimee}
            </text>
          </>
        )}
        {displayRefWidthLine()}
        {afficherExtremitesIdentiques()}
      </svg>
    </Paper>
  );
};
