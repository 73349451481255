import * as Yup from "yup";
import {
  LONGUEUR_COMPRIMEE,
  LONGUEUR_DEPLOYEE,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
  RONDE_DROIT_EXTERIEUR_MAX,
  RONDE_DROIT_INTERIEUR_MIN,
  SECTION_SOUFFLET,
  SOUFFLET_AVEC_SPIRE,
  TYPE_CORPS,
} from "../../../../../constantes/symbols/SymbolsS01";
import {
  arraySectionSoufflet,
  arrayTypeCorps,
} from "../../../../../constantes/symbols/SymbolsServicesS01";

/* Setting the maximum size of a file to 10MB. */
const FILE_SIZE = 10000000;
/* A constant that contains the list of supported formats. */
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/bmp",
  "image/png",
];

const otherwiseSchema = Yup.mixed().optional();

const schemaNotZero = (error) => {
  return Yup.number().min(1, error);
};

const yupPlusPetit = (champ, erreur, errorInfZero) => {
  return Yup.number().when(champ.toString(), {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .lessThan(Yup.ref(champ.toString()), erreur),
  });
};

const yupPlusGrand = (champ, erreur, errorInfZero) => {
  return Yup.number().when(champ.toString(), {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .moreThan(Yup.ref(champ.toString()), erreur),
  });
};

const yupPlusGrand2Fields = (champ, erreur, errorInfZero) => {
  return Yup.number().when([champ[0].toString(), champ[1].toString()], {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .moreThan(Yup.ref(champ[0].toString()), erreur[0])
      .moreThan(Yup.ref(champ[1].toString()), erreur[1]),
  });
};

const yupPlusGrandPetit2Fields = (champ, erreur, errorInfZero) => {
  return Yup.number().when([champ[0].toString(), champ[1].toString()], {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .moreThan(Yup.ref(champ[0].toString()), erreur[0])
      .lessThan(Yup.ref(champ[1].toString()), erreur[1]),
  });
};

/* A Yup validation schema. */
export const validDimensionsCorps = (t, etape) => {
  if (etape !== 4) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [LONGUEUR_COMPRIMEE.description]: Yup.mixed().when(
      [[SOUFFLET_AVEC_SPIRE.description].toString()],
      {
        is: (boolean) => boolean,
        then: Yup.lazy(() =>
          yupPlusPetit(
            [LONGUEUR_DEPLOYEE.description],
            t.erreurLongueurComprimeePetit,
            t.superieurA0
          ).required(t.champRequis)
        ),
        otherwise: otherwiseSchema,
      }
    ),

    [LONGUEUR_DEPLOYEE.description]: Yup.mixed().when(
      [[SOUFFLET_AVEC_SPIRE.description].toString()],
      {
        is: (boolean) => boolean,
        then: Yup.lazy(() =>
          yupPlusGrand(
            [LONGUEUR_COMPRIMEE.description],
            t.erreurLongueurComprimeeGrand,
            t.superieurA0
          ).required(t.champRequis)
        ),
        otherwise: schemaNotZero(t.superieurA0).required(t.champRequis),
      }
    ),
    [SECTION_SOUFFLET.description]: Yup.mixed()
      .test(
        "RadioButtonSectionInput",
        () => {
          return t.selectionIncorrect;
        },
        (selectedButton) =>
          arraySectionSoufflet().some((e) => e.code === selectedButton)
      )
      .required(t.erreurSelectionFormeSoufflet),
    [TYPE_CORPS.description]: Yup.mixed()
      .test(
        "RadioButtonTypeCorpsInput",
        () => {
          return t.selectionIncorrect;
        },
        (selectedButton) =>
          arrayTypeCorps().some((e) => e.code === selectedButton)
      )
      .required(t.erreurSelectionCorps),
    //----------RONDE_DROIT----------//
    [RONDE_DROIT_INTERIEUR_MIN.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {
        is: (section, type, spire) =>
          section === arraySectionSoufflet()[0].code &&
          type === arrayTypeCorps()[0].code &&
          !!spire === true,
        then: Yup.lazy(() =>
          yupPlusPetit(
            [RONDE_DROIT_EXTERIEUR_MAX.description],
            t.erreurInterieurMinTropGrand,
            t.superieurA0
          ).required(t.champRequis)
        ),
        otherwise: Yup.mixed().when(
          [
            [SECTION_SOUFFLET.description].toString(),
            [TYPE_CORPS.description].toString(),
            [SOUFFLET_AVEC_SPIRE.description].toString(),
          ],
          {
            is: (section, type, spire) =>
              section === arraySectionSoufflet()[0].code &&
              type === arrayTypeCorps()[0].code &&
              !!spire === false,
            then: schemaNotZero(t.superieurA0).required(t.champRequis),
            otherwise: otherwiseSchema,
          }
        ),
      }
    ),
    [RONDE_DROIT_EXTERIEUR_MAX.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {
        is: (section, type, spire) =>
          section === arraySectionSoufflet()[0].code &&
          type === arrayTypeCorps()[0].code &&
          !!spire === true,
        then: Yup.lazy(() =>
          yupPlusGrand(
            [RONDE_DROIT_INTERIEUR_MIN.description],
            t.erreurExterieurMaxTropPetit,
            t.superieurA0
          ).required(t.champRequis)
        ),
        otherwise: otherwiseSchema,
      }
    ),
    //----------RONDE_CONIQUE----------//
    [RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {
        is: (section, type, spire) =>
          section === arraySectionSoufflet()[0].code &&
          type === arrayTypeCorps()[1].code &&
          !!spire === true,
        then: Yup.lazy(() =>
          yupPlusPetit(
            [RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX.description],
            t.erreurRondeConiqueInterieurGaucheMin,
            t.superieurA0
          ).required(t.champRequis)
        ),
        otherwise: Yup.mixed().when(
          [
            [SECTION_SOUFFLET.description].toString(),
            [TYPE_CORPS.description].toString(),
            [SOUFFLET_AVEC_SPIRE.description].toString(),
          ],
          {
            is: (section, type, spire) =>
              section === arraySectionSoufflet()[0].code &&
              type === arrayTypeCorps()[1].code &&
              !!spire === false,
            then: schemaNotZero(t.superieurA0).required(t.champRequis),
            otherwise: otherwiseSchema,
          }
        ),
      }
    ),
    [RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {
        is: (section, type, spire) =>
          section === arraySectionSoufflet()[0].code &&
          type === arrayTypeCorps()[1].code &&
          !!spire === true,
        then: Yup.lazy(() =>
          yupPlusGrand2Fields(
            [
              [RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN.description],
              [RONDE_CONIQUE_EXTERIEUR_DROIT_MAX.description],
            ],
            [
              t.erreur1RondeConiqueExterieurGaucheMax,
              t.erreur2RondeConiqueExterieurGaucheMax,
            ],
            t.superieurA0
          ).required(t.champRequis)
        ),
        otherwise: otherwiseSchema,
      }
    ),
    [RONDE_CONIQUE_INTERIEUR_DROIT_MIN.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {
        is: (section, type, spire) =>
          section === arraySectionSoufflet()[0].code &&
          type === arrayTypeCorps()[1].code &&
          !!spire === true,
        then: Yup.lazy(() =>
          yupPlusPetit(
            [RONDE_CONIQUE_EXTERIEUR_DROIT_MAX.description],
            t.erreurRondeConiqueInterieurDroitMin,
            t.superieurA0
          ).required(t.champRequis)
        ),
        otherwise: Yup.mixed().when(
          [
            [SECTION_SOUFFLET.description].toString(),
            [TYPE_CORPS.description].toString(),
            [SOUFFLET_AVEC_SPIRE.description].toString(),
          ],
          {
            is: (section, type, spire) =>
              section === arraySectionSoufflet()[0].code &&
              type === arrayTypeCorps()[1].code &&
              !!spire === false,
            then: schemaNotZero(t.superieurA0).required(t.champRequis),
            otherwise: otherwiseSchema,
          }
        ),
      }
    ),
    [RONDE_CONIQUE_EXTERIEUR_DROIT_MAX.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {
        is: (section, type, spire) =>
          section === arraySectionSoufflet()[0].code &&
          type === arrayTypeCorps()[1].code &&
          !!spire === true,
        then: Yup.lazy(() =>
          yupPlusGrandPetit2Fields(
            [
              [RONDE_CONIQUE_INTERIEUR_DROIT_MIN.description],
              [RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX.description],
            ],
            [
              t.erreur1RondeConiqueExterieurDroitMax,
              t.erreur2RondeConiqueExterieurDroitMax,
            ],
            t.superieurA0
          ).required(t.champRequis)
        ),
        otherwise: otherwiseSchema,
      }
    ),
    //----------RECTANGULAIRE_DROIT----------//
    [RECTANGULAIRE_DROIT_INTERIEUR_A_MIN.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {
        is: (section, type, spire) =>
          section === arraySectionSoufflet()[1].code &&
          type === arrayTypeCorps()[0].code &&
          !!spire === true,
        then: yupPlusPetit(
          [RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX.description],
          t.erreurRectangulaireDroitExterieurA,
          t.superieurA0
        ).required(t.champRequis),
        otherwise: Yup.mixed().when(
          [
            [SECTION_SOUFFLET.description].toString(),
            [TYPE_CORPS.description].toString(),
            [SOUFFLET_AVEC_SPIRE.description].toString(),
          ],
          {
            is: (section, type, spire) =>
              section === arraySectionSoufflet()[1].code &&
              type === arrayTypeCorps()[0].code &&
              !!spire === false,
            then: schemaNotZero(t.superieurA0).required(t.champRequis),
            otherwise: otherwiseSchema,
          }
        ),
      }
    ),
    [RECTANGULAIRE_DROIT_INTERIEUR_B_MIN.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {

        is: (section, type,spire) =>
          section === arraySectionSoufflet()[1].code &&
          type === arrayTypeCorps()[0].code &&
          !!spire === true,
        then: yupPlusPetit(
          [RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX.description],
          t.erreurRectangulaireDroitExterieurB,
          t.superieurA0
        ).required(t.champRequis),
          otherwise: Yup.mixed().when(
              [
                  [SECTION_SOUFFLET.description].toString(),
                  [TYPE_CORPS.description].toString(),
                  [SOUFFLET_AVEC_SPIRE.description].toString(),
              ],
              {
                  is: (section, type, spire) =>
                      section === arraySectionSoufflet()[1].code &&
                      type === arrayTypeCorps()[0].code &&
                      !!spire === false,
                  then: schemaNotZero(t.superieurA0).required(t.champRequis),
                  otherwise: otherwiseSchema,
              }
          ),
      }
    ),
    [RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {
        is: (section, type, spire) =>
          section === arraySectionSoufflet()[1].code &&
          type === arrayTypeCorps()[0].code &&
          !!spire === true,
        then: yupPlusGrand(
          [RECTANGULAIRE_DROIT_INTERIEUR_A_MIN.description],
          t.erreurRectangulaireDroitInterieurA,
          t.superieurA0
        ).required(t.champRequis),
        otherwise: otherwiseSchema,
      }
    ),
    [RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
        [SOUFFLET_AVEC_SPIRE.description].toString(),
      ],
      {
        is: (section, type, spire) =>
          section === arraySectionSoufflet()[1].code &&
          type === arrayTypeCorps()[0].code &&
          !!spire === true,
        then: yupPlusGrand(
          [RECTANGULAIRE_DROIT_INTERIEUR_B_MIN.description],
          t.erreurRectangulaireDroitInterieurB,
          t.superieurA0
        ).required(t.champRequis),
        otherwise: otherwiseSchema,
      }
    ),
    //----------RECTANGULAIRE_CONIQUE----------//
    [RECTANGULAIRE_CONIQUE_JOINDRE_PLAN.description]: Yup.mixed().when(
      [
        [SECTION_SOUFFLET.description].toString(),
        [TYPE_CORPS.description].toString(),
      ],
      {
        is: (section, type) =>
          section === arraySectionSoufflet()[1].code &&
          type === arrayTypeCorps()[1].code,
        then: Yup.mixed()
          .test(
            "fileSize",
            (fileList) => {
              return (
                t.erreurFichierVolumineux +
                getNameFilesTooBig(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFilesTooBig(fileList).length === 0;
            }
          )
          .test(
            "fileCount",
            (fileList) => {
              return t.erreurFichierMaxAtteint + fileList.length;
            },
            (fileList) => {
              return fileList.length <= 10;
            }
          )
          .test(
            "fileFormat",
            (fileList) => {
              return (
                t.erreurFormatFichier +
                getNameFileWrongFormat(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFileWrongFormat(fileList).length === 0;
            }
          ),
        otherwise: otherwiseSchema,
      }
    ),
  });
};

/**
 * Given a list of files, return a list of files that are too big
 * @param listFiles - the list of files to be filtered
 * @returns An array of objects.
 */
const getNameFilesTooBig = (listFiles) => {
  return listFiles.filter((file) => file.size > FILE_SIZE);
};

/**
 * Given a list of files, return a list of files that are not of a supported format
 * @param listFiles - The list of files to be filtered.
 * @returns An array of files that are not supported.
 */
const getNameFileWrongFormat = (listFiles) => {
  return listFiles.filter((file) => !SUPPORTED_FORMATS.includes(file.type));
};
