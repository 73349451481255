import { usedForDisplay } from "./HelperSymbolsServices";

//Etape 4
const S05_STEP4_arrayMatiereToile = (t) => [
  {
    code: 99,
    label: "Non renseignée",
    ...usedForDisplay(t, { name: t?.matiereNonRenseignee }),
  },
  {
    code: 98,
    label: "Sans toile",
    ...usedForDisplay(t, { name: t?.sansToile }),
  },
  {
    code: 0,
    label: "TKVB",
    ...usedForDisplay(t, { name: t?.tkvb }),
  },
  {
    code: 1,
    label: "TKVA",
    ...usedForDisplay(t, { name: t?.tkva }),
  },
  {
    code: 2,
    label: "PU",
    ...usedForDisplay(t, { name: t?.pu}),
  },
  {
    code: 3,
    label: "Tablier aluminium",
    ...usedForDisplay(t, { name: t?.tablierAluminium }),
  },
];

const S05_STEP4_arrayTypeEnrouleur = (t) => [
  {
    code: 0,
    label: "Avec caisson",
    ...usedForDisplay(t, { name: t?.avecCaisson }),
  },
  {
    code: 1,
    label: "Sans caisson",
    ...usedForDisplay(t, { name: t?.sansCaisson }),
  },
  {
    code: 2,
    label: "Avec support",
    ...usedForDisplay(t, { name: t?.avecSupport }),
  },
];

//Etape 6
const S05_STEP6_arrayMatiereExtremite = (t) => [
  {
    code: 0,
    label: "Aluminium",
    ...usedForDisplay(t, { name: t?.aluminium }),
  },
  {
    code: 1,
    label: "Acier",
    ...usedForDisplay(t, { name: t?.acier }),
  },
];

const S05_STEP6_arrayDimensionExtremiteIt1 = (t) => [
  {
    code: 0,
    label: "15X3",
    ...usedForDisplay(t, { name: t?.dimension1 }),
  },
  {
    code: 1,
    label: "20X3",
    ...usedForDisplay(t, { name: t?.dimension2 }),
  },
  {
    code: 2,
    label: "30X3",
    ...usedForDisplay(t, { name: t?.dimension3 }),
  },
];

const S05_STEP6_arrayDimensionExtremiteIt2 = (t) => [
  {
    code: 3,
    label: "15X15X3",
    ...usedForDisplay(t, { name: t?.dimension4 }),
  },
  {
    code: 4,
    label: "20X20X3",
    ...usedForDisplay(t, { name: t?.dimension5 }),
  },
  {
    code: 5,
    label: "30X30X3",
    ...usedForDisplay(t, { name: t?.dimension6 }),
  },
];

const S05_STEP6_arrayPositionExtremite = (t) => [
  {
    code: 0,
    label: "1",
    ...usedForDisplay(t, { name: t?.S05_positionnement_extremite_1 }),
  },
  {
    code: 1,
    label: "2",
    ...usedForDisplay(t, { name: t?.S05_positionnement_extremite_2 }),
  },
  {
    code: 2,
    label: "3",
    ...usedForDisplay(t, { name: t?.S05_positionnement_extremite_3 }),
  },
  {
    code: 3,
    label: "4",
    ...usedForDisplay(t, { name: t?.S05_positionnement_extremite_4 }),
  },
  {
    code: 4,
    label: "5",
    ...usedForDisplay(t, { name: t?.S05_positionnement_extremite_5 }),
  },
  {
    code: 5,
    label: "6",
    ...usedForDisplay(t, { name: t?.S05_positionnement_extremite_6 }),
  },
  {
    code: 6,
    label: "7",
    ...usedForDisplay(t, { name: t?.S05_positionnement_extremite_7 }),
  },
];

const S05_STEP6_arraySystemeFixationCaisson = (t) => [
  {
    code: 0,
    label: "Aucun",
    ...usedForDisplay(t, { name: t?.aucun, img: "FPS_no.png" }),
  },
  {
    code: 1,
    label: "1",
    ...usedForDisplay(t, { name: t?.fixation1, img: "S05_type_fixation_boitier_1.png" }),
  },
  {
    code: 2,
    label: "2",
    ...usedForDisplay(t, { name: t?.fixation2, img: "S05_type_fixation_boitier_2.png" }),
  },
  {
    code: 3,
    label: "3",
    ...usedForDisplay(t, { name: t?.fixation3, img: "S05_type_fixation_boitier_3.png" }),
  },
  {
    code: 4,
    label: "4",
    ...usedForDisplay(t, { name: t?.fixation4, img: "S05_type_fixation_boitier_4.png" }),
  },
];

const S05_STEP6_arrayPositionFixationCaisson = (t) => [
  {
    code: 0,
    label: "Face 1",
    ...usedForDisplay(t, { name: t?.face1 }),
  },
  {
    code: 1,
    label: "Face 2",
    ...usedForDisplay(t, { name: t?.face2 }),
  },
  {
    code: 2,
    label: "Face 3",
    ...usedForDisplay(t, { name: t?.face3 }),
  },
  {
    code: 3,
    label: "Face 4",
    ...usedForDisplay(t, { name: t?.face4 }),
  },
];

const S05_STEP6_arrayCoteAgresseToile = (t) => [
  {
    code: 0,
    label: "Côté 1",
    ...usedForDisplay(t, { name: t?.cote1 }),
  },
  {
    code: 1,
    label: "Côté 2",
    ...usedForDisplay(t, { name: t?.cote2 }),
  },
];

export {
  //Etape 4
  S05_STEP4_arrayMatiereToile,
  S05_STEP4_arrayTypeEnrouleur,
  //Etape 6
  S05_STEP6_arrayMatiereExtremite,
  S05_STEP6_arrayDimensionExtremiteIt1,
  S05_STEP6_arrayDimensionExtremiteIt2,
  S05_STEP6_arrayPositionExtremite,
  S05_STEP6_arraySystemeFixationCaisson,
  S05_STEP6_arrayPositionFixationCaisson,
  S05_STEP6_arrayCoteAgresseToile,
};
