import {
  BUTTON_GROUP,
  BUTTON_GROUP_BOX_SHADOW,
  CHECKBOX,
  CHECKBOX_BOX_SHADOW,
  DROPZONE,
  GRID,
  RECAP,
  SWITCH,
  TEXT,
  TEXTFIELD,
  TEXTFIELD_NUMBER,
  TEXTFIELD_RETURN_ARRAY,
  TEXTFIELD_RETURN_ARRAY_MULTI,
  TEXTFIELD_SEARCHBAR,
  TEXTFIELD_SELECT,
  TEXTFIELD_ZONE,
  TEXT_AND_TEXTFIELD_ALIGN,
  BUTTON_GROUP_IMAGE,
  CHECKBOX_BY_LABEL,
  RECAP_S04,
  RECAP_S05,
  RECAP_S08,
  TEXTFIELD_BY_LABEL,
  TEXTFIELD_SEARCHBAR_CODE,
  TEXTFIELD_SELECT_BACKOFFICE,
  RECAP_S06,
  TEXT_CUSTOM1,
  CHECKBOX_IMAGE_CUSTOM,
  TEXTFIELD_WITHOUT_FORMIK,
} from "./types/TypeFieldsFormulary";
import {
  genererChampText,
  genererChampTextCustom1,
} from "./methods/TextGenerate";
import {
  genererChampStyledTextfield,
  genererChampTextAndTextfieldAlign,
  genererChampStyledTextfieldReturnArray,
  genererChampStyledTextfieldReturnArrayMulti,
  genererChampTextFieldZone,
  genererChampStyledTextfieldZone,
  genererChampStyledTextfieldWithoutFormik,
} from "./methods/TextFieldGenerate";
import { genererChampTextFieldNumber } from "./methods/NumberGenerate";
import {
  genererChampTextfieldAndSearchBar,
  genererChampTextfieldAndSearchBarReturnCode,
  genererChampTextFieldSelect,
  genererChampTextFieldSelectBackoffice,
  genererChampTextFieldSelectByLabel,
} from "./methods/SelectGenerate";
import { genererChampDropZone } from "./methods/DropzoneGenerate";
import {
  genererChampButtonGroup,
  genererChampButtonGroupBoxShadow,
  genererChampButtonGroupWithImage,
} from "./methods/ButtonGroupGenerate";
import {
  genererChampCheckbox,
  genererChampCheckboxBoxShadow,
  genererChampCheckboxByLabel,
  genererChampCheckboxImageCustom,
} from "./methods/CheckboxGenerate";
import { genererChampSwitch } from "./methods/SwitchGenerate";
import {
  genererChampRecapitulatif,
  genererChampRecapitulatifS04,
  genererChampRecapitulatifS05,
  genererChampRecapitulatifS06,
  genererChampRecapitulatifS08,
} from "./methods/ResumeGenerate";
import { genererChampImagesGridSelectable } from "./methods/ImagesGenerate";

/**
 * Generate the form from the form data
 * @param formulaire - The array of fields that will be generated.
 * @param formik - The Formik instance.
 * @returns A form with the fields of the formulaire.
 */
const ERROR = Symbol("Ce type d'élément n'est pas pris en charge ");
export const genererFormulaire = (
  formulaire,
  formik,
  t,
  booleanIdentite,
  isFromDDP,
  state
) => {
  const cleanFormulary = formulaire.filter((element) => element);
  return cleanFormulary.map((champ) => {
    switch (champ.type) {
      case TEXT:
        return genererChampText(champ);
      case TEXT_CUSTOM1:
        return genererChampTextCustom1(champ);
      case TEXTFIELD:
        return genererChampStyledTextfield(champ, formik);
      case TEXTFIELD_RETURN_ARRAY:
        return genererChampStyledTextfieldReturnArray(champ, formik);
      case TEXTFIELD_RETURN_ARRAY_MULTI:
        return genererChampStyledTextfieldReturnArrayMulti(champ, formik);
      case TEXTFIELD_NUMBER:
        return genererChampTextFieldNumber(champ, formik);
      case TEXTFIELD_SELECT:
        return genererChampTextFieldSelect(champ, formik);
      case TEXTFIELD_SEARCHBAR:
        return genererChampTextfieldAndSearchBar(champ, formik);
      case TEXTFIELD_SEARCHBAR_CODE:
        return genererChampTextfieldAndSearchBarReturnCode(champ, formik);
      case TEXTFIELD_ZONE:
        return genererChampStyledTextfieldZone(champ, formik);
        case TEXTFIELD_WITHOUT_FORMIK:
        return genererChampStyledTextfieldWithoutFormik(champ, formik);
      case DROPZONE:
        return genererChampDropZone(champ, formik);
      case BUTTON_GROUP_BOX_SHADOW:
        return genererChampButtonGroupBoxShadow(champ, formik, state);
      case BUTTON_GROUP:
        return genererChampButtonGroup(champ, formik);
      case CHECKBOX:
        return genererChampCheckbox(champ, formik);
      case CHECKBOX_BOX_SHADOW:
        return genererChampCheckboxBoxShadow(champ, formik);
      case CHECKBOX_IMAGE_CUSTOM:
        return genererChampCheckboxImageCustom(champ, formik);
      case SWITCH:
        return genererChampSwitch(champ, formik);
      case TEXT_AND_TEXTFIELD_ALIGN:
        return genererChampTextAndTextfieldAlign(champ, formik);
      case GRID:
        return genererChampImagesGridSelectable(champ, formik);
      case BUTTON_GROUP_IMAGE:
        return genererChampButtonGroupWithImage(champ, formik);
      case CHECKBOX_BY_LABEL:
        return genererChampCheckboxByLabel(champ, formik);
      case TEXTFIELD_BY_LABEL:
        return genererChampTextFieldSelectByLabel(champ, formik);
      case TEXTFIELD_SELECT_BACKOFFICE:
        return genererChampTextFieldSelectBackoffice(champ, formik);
      case RECAP:
        return genererChampRecapitulatif(
          champ,
          formik,
          t,
          booleanIdentite,
          isFromDDP
        );
      case RECAP_S04:
        return genererChampRecapitulatifS04(
          champ,
          formik,
          t,
          booleanIdentite,
          isFromDDP
        );
      case RECAP_S05:
        return genererChampRecapitulatifS05(
          champ,
          formik,
          t,
          booleanIdentite,
          isFromDDP
        );
      case RECAP_S08:
        return genererChampRecapitulatifS08(
          champ,
          formik,
          t,
          booleanIdentite,
          isFromDDP
        );
      case RECAP_S06:
        return genererChampRecapitulatifS06(
          champ,
          formik,
          t,
          booleanIdentite,
          isFromDDP
        );
      default:
        console.log(ERROR.description + champ.type);
        return null;
    }
  });
};
