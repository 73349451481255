import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  ARTICLE,
  DEMANDE_DE_PRIX,
  OFFRE_DE_PRIX,
} from "../../../../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { useTranslations } from "../../../../components/hook/useTranslations";
import { setAllInfoByRefNull } from "../../../../../model/slice/FormulaireReducer";
import { DessinTechnique } from "../../../../components/DessinTechnique";
import { Loading, LoadingEC } from "../../../../components/Loading";
import {
  StyledDivDessinRecap,
  StyledDivDessinRecapIframe,
  StyledDivDessinRecapIframeScaleS05,
  StyledDivFiligranneRecapEC,
  StyledDivRecapGeneEC,
  StyledDivRecapInt,
  StyledDivRecapitulatifEC,
  StyledDivSectionRecapEC,
  StyledDivSectionsRowRecap,
  StyledDivSectionsRowReverseRecap,
  StyledDivSubtitlesRecap,
} from "../../../../styledComponents/StyledDiv";
import { BoutonRetourEC } from "../../../../components/espaceClient/BoutonAccueilEC";
import {
  StyledH1VuesEC,
} from "../../../../styledComponents/StyledTextEspaceClient";
import { StyledPaperBodyEC } from "../../../../styledComponents/StyledPaper";
import { importAllSpec } from "../../../../../../helper/ImportAll";
import { StyledSpanSubtitlesRecap } from "../../../../styledComponents/StyledSpan";
import { arrayProductFamilly } from "../../../../../../constantes/symbols/SymbolsServicesCommon";
import { StyledHrEspaceClient } from "../../../../styledComponents/StyledDivEspaceClient";
import { genererFormulaire } from "../../../../components/generateFormulary/CasesGeneration";
import { genererChampRecapitulatifHorsConfigurateur } from "../../../../components/generateFormulary/methods/ResumeGenerate";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  formulaireAggressionsS05,
  formulaireCommentaireS05,
  formulaireConstructionS05,
  formulaireDimensions,
  formulaireExtremite,
  formulaireInformationsMachineS05,
  formulaireQuantitésS05,
} from "../../../../ChampsFormulaires/S05/recapitulatifS05/ChampsRecapitulatifS05";
import { dataCleanerS05 } from "../../../../../../helper/DataCleanerS05";
import { afficherOnglets, afficherOngletsMobile } from "../../../../components/espaceClient/OngletsArticle";

export const ArticleS05 = ({
  state: {
    booleanIdentite,
    etape,
    formik,
    styles,
    styles: {
      sousTitreRecap,
      iconeRecap,
    },
    t,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateAgressionsList,
    isArticleByIdSet,
  },
  action: {
    dispatch,
    setArticleId,
    handleRetour,
    setIsArticleByIdSet,
  },
  isFrom,
  nom,
}) => {
  const [params] = useSearchParams();
  const from = [DEMANDE_DE_PRIX, OFFRE_DE_PRIX, ARTICLE].includes(isFrom);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const [iframe, setIframe] = useState();
  const [formulaire, setFormulaire] = useState(
    dataCleanerS05(formik.values ? formik.values : formik)
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [isOngletDesktopFormat, setIsOngletDesktopFormat] = useState(window.innerWidth >= 768);

  useEffect(() => {
    setIframe(parseInt(query.get("iframe")) === 1);
  }, []);

  useEffect(() => {
    setIsOngletDesktopFormat(window.innerWidth >= 768);
  }, [window.innerWidth]);

  useEffect(() => {
    setArticleId(params.get("art"));
  }, [booleanIdentite, setArticleId]);

  const triggerHandleRetour = () => {
    const url = new URL(window.location.href);

    if (from) {
      dispatch(setAllInfoByRefNull());
      setIsArticleByIdSet(false);

      url.searchParams.delete("art");
      window.history.pushState({}, "", url);
    } else {
      handleRetour();
    }
  };

  if (!isArticleByIdSet) {
    return <LoadingEC t={t} />;
  }

  const afficherBoutonRetour = (texte, action, backgroundColor) => {
    return (
      <BoutonRetourEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  const afficherDessinTechnique = () => {
    return <DessinTechnique formik={formulaire} styles={styles} t={t} />;
  };

  const displayDessinTechnique = () => {
    return iframe ? (
      <StyledDivDessinRecapIframe name={nom}>
        <StyledDivDessinRecapIframeScaleS05>
          {afficherDessinTechnique()}
        </StyledDivDessinRecapIframeScaleS05>
      </StyledDivDessinRecapIframe>
    ) : (
      <StyledDivDessinRecap>{afficherDessinTechnique()}</StyledDivDessinRecap>
    );
  };

  const afficherRecap = () => {
    return (
      <>
        {iframe ? displayDessinTechnique() : null}
        <StyledPaperBodyEC elevation={0}>
          <StyledDivRecapitulatifEC>
            <StyledDivRecapInt>
              <StyledDivFiligranneRecapEC
                style={{
                  backgroundImage:
                    "url(" +
                    importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                    ")",
                  zIndex: "0",
                }}
                className={styles.logoFiligraneEC}
              />
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titleReferences}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {formulaire?.["infosArticleEC"]?.[0][
                      "articleReferenceClient"
                    ] === "" ? null : (
                      <div>
                        {t.titleCodeClient} :{" "}
                        <span>
                          {
                            formulaire?.["infosArticleEC"]?.[0][
                              "articleReferenceClient"
                            ]
                          }
                        </span>
                      </div>
                    )}
                    <div>
                      {t.codeRCMP} :{" "}
                      <span>
                        {formulaire?.["infosArticleEC"]?.[0]["articleCode"]}
                      </span>
                    </div>

                    <div>
                      {t.titleNomArticle} :{" "}
                      <span>
                        {formulaire?.["infosArticleEC"]?.[0]["articleDesignation"]}
                      </span>
                    </div>

                    <div>
                      {t.titleFamille} :{" "}
                      <span>
                        {arrayProductFamilly(t).find(
                          (e) =>
                            e.code ===
                            formulaire?.["infosArticleEC"]?.[0][
                              "familleCode"
                            ].substring(0, 3)
                        )?.name ??
                          formulaire?.["infosArticleEC"]?.[0][
                            "familleCode"
                          ].substring(0, 3)}
                      </span>
                    </div>
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
                {!iframe ? displayDessinTechnique() : null}
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.descrArticle}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC
                    dangerouslySetInnerHTML={{
                      __html:
                        formulaire?.["infosArticleEC"]?.[0]["articleDescription"],
                    }}
                  />
                </StyledDivSectionRecapEC>

                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreDimensions}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireDimensions(t, formulaire),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 7)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowRecap>

              <StyledDivSectionsRowReverseRecap style={{ zIndex: "2" }}>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreConstruction}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireConstructionS05(t),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 8)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>

                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreExtremiteS05}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireExtremite(t, formulaire),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 6)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowReverseRecap>

              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreInformationsMachine}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireInformationsMachineS05(
                        t,
                        upToDateTypeMachineList,
                        upToDateMarqueMachineList
                      ),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 1)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreEnvironnement}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireAggressionsS05(
                        t,
                        upToDateAgressionsList,
                        formulaire
                      ),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 2)}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowRecap>

              <StyledDivSectionsRowReverseRecap style={{ zIndex: "2" }}>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreInfosComp}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  {genererFormulaire(formulaireQuantitésS05(t), formulaire, t)}
                  {genererFormulaire(
                    formulaireCommentaireS05(t, booleanIdentite),
                    formulaire,
                    t,
                    booleanIdentite
                  )}
                  {genererChampRecapitulatifHorsConfigurateur(formulaire, 12)}
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowReverseRecap>
            </StyledDivRecapInt>
          </StyledDivRecapitulatifEC>
        </StyledPaperBodyEC>
      </>
    );
  };

  return (
    <div name={nom} style={{ padding: "1rem" }}>
      {afficherBoutonRetour(
        t.retourListeArticles,
        triggerHandleRetour,
        "#F8F9FF"
      )}
      {formulaire ? (
        <div>
          <div>
            <StyledH1VuesEC>
              {t.detailsArticle} :{" "}
              {arrayProductFamilly(t).find(
                (e) =>
                  e.code ===
                  formulaire?.["infosArticleEC"]?.[0]["familleCode"].substring(0, 3)
              )?.name ?? formulaire["designation"]}
            </StyledH1VuesEC>
            <StyledHrEspaceClient></StyledHrEspaceClient>
          </div>

          {isOngletDesktopFormat
            ? afficherOnglets(
                t,
                formulaire,
                selectedTab,
                setSelectedTab,
                afficherRecap()
              )
            : afficherOngletsMobile(
                t,
                formulaire,
                selectedTab,
                setSelectedTab,
                afficherRecap()
              )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};
