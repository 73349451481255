import { Box, Tab, Tabs } from "@mui/material";
import { StyledTitleTab } from "../../styledComponents/StyledTextEspaceClient";
import { OffresArticle } from "../../espaceClient/vueArticle/controllerResume/OffresArticle";
import { CommandesArticle } from "../../espaceClient/vueArticle/controllerResume/CommandesArticle";

export const afficherOnglets = (t, formulaire, selectedTab, setSelectedTab, recap ) => {
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };

    return (
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          style={{ marginTop: "2rem" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#2A375C",
            },
          }}
        >
          <Tab
            style={{
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: selectedTab === 0 ? "white" : "#E7EAFF",
              padding: "1.5rem",
              marginRight: "1.5rem",
              opacity: selectedTab === 0 ? "1" : "0.8",
            }}
            label={
              <StyledTitleTab>{t.titleDetailArtCaracteristique}</StyledTitleTab>
            }
          />
          <Tab
            style={{
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: selectedTab === 1 ? "white" : "#EC88124D",
              padding: "1.5rem",
              marginRight: "1.5rem",
              opacity: selectedTab === 1 ? "1" : "0.8",
            }}
            label={<StyledTitleTab>{t.titleDetailArtODP}</StyledTitleTab>}
          />
          <Tab
            style={{
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: selectedTab === 2 ? "white" : "#CC007B40",
              padding: "1.5rem",
              opacity: selectedTab === 2 ? "1" : "0.8",
            }}
            label={<StyledTitleTab>{t.titleDetailArtCommande}</StyledTitleTab>}
          />
        </Tabs>
        {selectedTab === 0 && <Box>{recap}</Box>}
        {selectedTab === 1 && (
          <Box>
            {
              <OffresArticle
                clientId={formulaire?.["infosArticleEC"]?.[0]["clientId"]}
                articleId={formulaire?.["infosArticleEC"]?.[0]["articleId"]}
              />
            }
          </Box>
        )}
        {selectedTab === 2 && (
          <Box>
            {
              <CommandesArticle
                clientId={formulaire?.["infosArticleEC"]?.[0]["clientId"]}
                articleId={formulaire?.["infosArticleEC"]?.[0]["articleId"]}
              />
            }
          </Box>
        )}
      </Box>
    );
  };

export const afficherOngletsMobile = (t, formulaire, selectedTab, setSelectedTab, recap) => {
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };

    return (
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          orientation="vertical" // Pour que les onglets soient disposés verticalement
          style={{ marginTop: "2rem" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#2A375C",
            },
          }}
        >
          <Tab
            style={{
              borderRadius: "8px 8px 8px 8px",
              backgroundColor: selectedTab === 0 ? "white" : "#E7EAFF",
              padding: "1.5rem",
              marginBottom: "1.5rem", // Remplacer marginRight par marginBottom
              width: "100%", // Assurer que chaque onglet prend toute la largeur
              opacity: selectedTab === 0 ? "1" : "0.8",
            }}
            label={
              <StyledTitleTab>{t.titleDetailArtCaracteristique}</StyledTitleTab>
            }
          />
          <Tab
            style={{
              borderRadius: "8px 8px 8px 8px",
              backgroundColor: selectedTab === 1 ? "white" : "#EC88124D",
              padding: "1.5rem",
              marginBottom: "1.5rem", // Remplacer marginRight par marginBottom
              width: "100%", // Assurer que chaque onglet prend toute la largeur
              opacity: selectedTab === 1 ? "1" : "0.8",
            }}
            label={<StyledTitleTab>{t.titleDetailArtODP}</StyledTitleTab>}
          />
          <Tab
            style={{
              borderRadius: "8px 8px 8px 8px",
              backgroundColor: selectedTab === 2 ? "white" : "#CC007B40",
              padding: "1.5rem",
              width: "100%", // Assurer que chaque onglet prend toute la largeur
              marginBottom: "1.5rem",
              opacity: selectedTab === 2 ? "1" : "0.8",
            }}
            label={<StyledTitleTab>{t.titleDetailArtCommande}</StyledTitleTab>}
          />
        </Tabs>
        {selectedTab === 0 && <Box>{recap}</Box>}
        {selectedTab === 1 && (
          <Box>
            <OffresArticle
              clientId={formulaire?.["infosArticleEC"]?.[0]["clientId"]}
              articleId={formulaire?.["infosArticleEC"]?.[0]["articleId"]}
            />
          </Box>
        )}
        {selectedTab === 2 && (
          <Box>
            <CommandesArticle
              clientId={formulaire?.["infosArticleEC"]?.[0]["clientId"]}
              articleId={formulaire?.["infosArticleEC"]?.[0]["articleId"]}
            />
          </Box>
        )}
      </Box>
    );
  };