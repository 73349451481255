import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../constantes/symbols/HelperSymbolsServices";
import {
  BRIDE_IT1,
  BRIDE_IT2,
  EXTREMITE_IDENTIQUE,
  MANCHETTE_A_IT1,
  MANCHETTE_DIAMETRE_IT1,
  MANCHETTE_IT1,
  MANCHETTE_IT2,
  MANCHETTE_LARGEUR_IT1,
  MANCHETTE_LARGEUR_IT2,
  TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT2,
} from "../constantes/symbols/SymbolsS01";
import {
  arraySectionExtremite,
  arrayTypeExtremites,
} from "../constantes/symbols/SymbolsServicesS01";

const MAX_HEIGHT_MULTIPLICATOR = 1.6;

export const checkFocusedAngle = (formikValues, fieldList, styles) => {
  if (formikValues === undefined) return styles.path1;
  return fieldList.find(
    (element) => element.description === formikValues["focused"]
  ) !== undefined
    ? styles.path3
    : styles.path1;
};

export const checkFocusedArrow = (formikValues, fieldList, styles) => {
  if (formikValues === undefined) return styles.fleche;
  return fieldList.find(
    (element) => element.description === formikValues["focused"]
  ) !== undefined
    ? styles.cssSurbrillanceFlecheDessinTechnique
    : styles.fleche;
};

export const checkFocusedText = (formikValues, fieldList, styles) => {
  if (formikValues === undefined) return null;
  return fieldList.find(
    (element) => element.description === formikValues["focused"]
  ) !== undefined
    ? styles.cssSurbrillanceTextDessinTechnique
    : null;
};

export const checkFocusedStartMarker = (formikValues, fieldList) => {
  if (formikValues === undefined) return "url(#endarrow)";
  return fieldList.find(
    (element) => element.description === formikValues["focused"]
  ) !== undefined
    ? "url(#startarrowSelectedRC)"
    : "url(#startarrow)";
};

export const checkFocusedEndMarker = (formikValues, fieldList) => {
  if (formikValues === undefined) return "url(#endarrow)";
  return fieldList.find(
    (element) => element.description === formikValues["focused"]
  ) !== undefined
    ? "url(#endarrowSelectedRC)"
    : "url(#endarrow)";
};

export const checkFocusedArrowWithDependance = (
  formikValues,
  fieldList,
  bypassFieldList,
  styles
) => {
  return fieldList.find(
    (element) => element.description === formikValues["focused"]
  ) !== undefined && formikValues[EXTREMITE_IDENTIQUE.description] === 1
    ? styles.cssSurbrillanceFlecheDessinTechnique
    : bypassFieldList.find(
        (element) => element.description === formikValues["focused"]
      ) !== undefined && formikValues[EXTREMITE_IDENTIQUE.description] === 0
    ? styles.cssSurbrillanceFlecheDessinTechnique
    : styles.fleche;
};

export const checkFocusedTextWithDependance = (
  formikValues,
  fieldList,
  bypassFieldList,
  styles
) => {
  return fieldList.find(
    (element) => element.description === formikValues["focused"]
  ) !== undefined && formikValues[EXTREMITE_IDENTIQUE.description] === 1
    ? styles.cssSurbrillanceTextDessinTechnique
    : bypassFieldList.find(
        (element) => element.description === formikValues["focused"]
      ) !== undefined && formikValues[EXTREMITE_IDENTIQUE.description] === 0
    ? styles.cssSurbrillanceTextDessinTechnique
    : null;
};

export const checkFocusedStartMarkerWithDependance = (
  formikValues,
  fieldList,
  bypassFieldList
) => {
  return fieldList.find(
    (element) => element.description === formikValues["focused"]
  ) !== undefined && formikValues[EXTREMITE_IDENTIQUE.description] === 1
    ? "url(#startarrowSelectedRC)"
    : bypassFieldList.find(
        (element) => element.description === formikValues["focused"]
      ) !== undefined && formikValues[EXTREMITE_IDENTIQUE.description] === 0
    ? "url(#startarrowSelectedRC)"
    : "url(#startarrow)";
};

export const checkFocusedEndMarkerWithDependance = (
  formikValues,
  fieldList,
  bypassFieldList
) => {
  return fieldList.find(
    (element) => element.description === formikValues["focused"]
  ) !== undefined && formikValues[EXTREMITE_IDENTIQUE.description] === 1
    ? "url(#endarrowSelectedRC)"
    : bypassFieldList.find(
        (element) => element.description === formikValues["focused"]
      ) !== undefined && formikValues[EXTREMITE_IDENTIQUE.description] === 0
    ? "url(#endarrowSelectedRC)"
    : "url(#endarrow)";
};

export const coefAffichageManchette = (numerateur, denominateur, formik) => {
  const den =
    formik[denominateur.description] || formik[numerateur.description];

  const num =
    formik[numerateur.description] || formik[denominateur.description];

  const typeExtremiteIt1 = formik[TYPE_EXTREMITES_IT1.description];
  const typeExtremiteIt2 = formik[TYPE_EXTREMITES_IT2.description];

  const coef = num / den || 1;

  if(formik[numerateur.description] === 0){
    return 0;
  }

  if (
    coef < 1 &&
    coef > 0 &&
    (typeExtremiteIt1 === typeExtremiteIt2 ||
      (equalsCodeOrLabel(typeExtremiteIt1, arrayTypeExtremites(), 0) &&
        equalsCodeOrLabel(typeExtremiteIt2, arrayTypeExtremites(), 2)) ||
      (equalsCodeOrLabel(typeExtremiteIt1, arrayTypeExtremites(), 2) &&
        equalsCodeOrLabel(typeExtremiteIt2, arrayTypeExtremites(), 0)))
  ) {
    return coef;
  } else {
    return 1;
  }
};

export const coefAffichageBrideIT1 = (chiffre, borneInf, borneSup, formik) => {
  const chiffreVal = equalsCodeOrLabel(
    formik[BRIDE_IT1.description],
    arraySectionExtremite(),
    0
  )
    ? formik[chiffre[0].description]
    : formik[chiffre[1].description];

  const borneInfVal = equalsCodeOrLabel(
    formik[MANCHETTE_IT1.description],
    arraySectionExtremite(),
    0
  )
    ? formik[borneInf[0].description]
    : formik[borneInf[1].description];

  const borneSupValTmp = formik[borneSup.description];

  const borneSupVal =
    isNaN(borneSupValTmp) || borneSupValTmp === "" ? null : borneSupValTmp;

  if (chiffreVal <= 0) {
    return 0;
  }

  if (borneSupVal) {
    if (chiffreVal <= borneSupVal && chiffreVal >= borneInfVal) {
      return (chiffreVal - borneInfVal) / (borneSupVal - borneInfVal);
    }
    if (
      chiffreVal > borneSupVal &&
      chiffreVal / borneSupVal <= MAX_HEIGHT_MULTIPLICATOR
    ) {
      return chiffreVal / borneSupVal;
    }
    if (chiffreVal / borneSupVal > MAX_HEIGHT_MULTIPLICATOR) {
      return MAX_HEIGHT_MULTIPLICATOR;
    }
  }

  if (chiffreVal / borneInfVal - 1 <= MAX_HEIGHT_MULTIPLICATOR) {
    return chiffreVal / borneInfVal - 1;
  }

  if (chiffreVal / borneInfVal - 1 > MAX_HEIGHT_MULTIPLICATOR) {
    return MAX_HEIGHT_MULTIPLICATOR;
  }

  return 0;
};

export const coefAffichageBrideIT2 = (
  chiffreIT2,
  chiffreIT1,
  borneInf,
  borneSup,
  formik
) => {
  const chiffreVal = !equalsBooleanOrStringOrNumber(
    formik[EXTREMITE_IDENTIQUE.description]
  )
    ? equalsCodeOrLabel(
        formik[BRIDE_IT2.description],
        arraySectionExtremite(),
        0
      )
      ? formik[chiffreIT2[0].description]
      : formik[chiffreIT2[1].description]
    : equalsCodeOrLabel(
        formik[BRIDE_IT1.description],
        arraySectionExtremite(),
        0
      )
    ? formik[chiffreIT1[0].description]
    : formik[chiffreIT1[1].description];

  const borneInfVal = !equalsBooleanOrStringOrNumber(
    formik[EXTREMITE_IDENTIQUE.description]
  )
    ? equalsCodeOrLabel(
        formik[MANCHETTE_IT2.description],
        arraySectionExtremite(),
        0
      )
      ? formik[borneInf[0][1].description]
      : formik[borneInf[1][1].description]
    : equalsCodeOrLabel(
        formik[MANCHETTE_IT1.description],
        arraySectionExtremite(),
        0
      )
    ? formik[borneInf[0][0].description]
    : formik[borneInf[1][0].description];

  const borneSupValTmp = equalsCodeOrLabel(
    formik[TYPE_EXTREMITES_IT2.description],
    arrayTypeExtremites(),
    0
  )
    ? null
    : equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT1.description],
        arrayTypeExtremites(),
        0
      )
    ? null
    : formik[borneSup.description];

  const borneSupVal =
    isNaN(borneSupValTmp) || borneSupValTmp === "" ? null : borneSupValTmp;

  if (chiffreVal <= 0) {
    return 0;
  }

  if (borneSupVal) {
    if (chiffreVal <= borneSupVal && chiffreVal >= borneInfVal) {
      return (chiffreVal - borneInfVal) / (borneSupVal - borneInfVal);
    }
    if (
      chiffreVal > borneSupVal &&
      chiffreVal / borneSupVal <= MAX_HEIGHT_MULTIPLICATOR
    ) {
      return chiffreVal / borneSupVal;
    }
    if (chiffreVal / borneSupVal > MAX_HEIGHT_MULTIPLICATOR) {
      return MAX_HEIGHT_MULTIPLICATOR;
    }
  }

  if (chiffreVal / borneInfVal - 1 <= MAX_HEIGHT_MULTIPLICATOR) {
    return chiffreVal / borneInfVal - 1;
  }

  if (chiffreVal / borneInfVal - 1 > MAX_HEIGHT_MULTIPLICATOR) {
    return MAX_HEIGHT_MULTIPLICATOR;
  }

  return 0;
};
