import { ENVELOPPE_MATIERE } from "../../../../../constantes/symbols/SymbolsS08";
import { S08_STEP4_arrayMatiere } from "../../../../../constantes/symbols/SymbolsServicesS08";
import {
  TEXT,
  TEXTFIELD_BY_LABEL,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_ENVELOPPE_MATIERE = Symbol("Enveloppe - Matière");

export const formulaireMatiereS08 = (styles, t) => [
  {
    nom: TITRE_ENVELOPPE_MATIERE,
    label: t.titreEnveloppeMatiere+t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0071_Q00020,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: ENVELOPPE_MATIERE,
    label: t.descrEnveloppeMatiere,
    type: TEXTFIELD_BY_LABEL,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: S08_STEP4_arrayMatiere(t),
  },
];
