export const PersonIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginRight: "1rem",
        width: "24px",
        height: "24px",
      }}
    >
      <g clipPath="url(#clip0_447_4111)">
        <path
          d="M8 9.00001C9.21242 9.00001 10.3752 8.54027 11.2325 7.72193C12.0898 6.90359 12.5714 5.79368 12.5714 4.63637C12.5714 3.47906 12.0898 2.36916 11.2325 1.55082C10.3752 0.732475 9.21242 0.272736 8 0.272736C6.78758 0.272736 5.62482 0.732475 4.76751 1.55082C3.9102 2.36916 3.42857 3.47906 3.42857 4.63637C3.42857 5.79368 3.9102 6.90359 4.76751 7.72193C5.62482 8.54027 6.78758 9.00001 8 9.00001ZM6.36786 10.6364C2.85 10.6364 0 13.3568 0 16.7148C0 17.2739 0.475 17.7273 1.06071 17.7273H14.9393C15.525 17.7273 16 17.2739 16 16.7148C16 13.3568 13.15 10.6364 9.63214 10.6364H6.36786Z"
          fill="#2A375C"
        />
      </g>
      <defs>
        <clipPath id="clip0_447_4111">
          <rect
            width="16"
            height="17.4545"
            fill="white"
            transform="translate(0 0.272736)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
