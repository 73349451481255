import {
  BRIDE_A_IT1,
  BRIDE_A_IT2,
  BRIDE_B_IT1,
  BRIDE_B_IT2,
  BRIDE_DIAMETRE_IT1,
  BRIDE_DIAMETRE_IT2,
  BRIDE_IT1,
  BRIDE_IT2,
  BRIDE_PERCEE_IT1,
  BRIDE_PERCEE_IT2,
  EXTREMITE_IDENTIQUE,
  FIXATION_EXTREMITES_IT1,
  FIXATION_EXTREMITES_IT2,
  JOINDRE_PLAN_BRIDE_PERCEE_IT1,
  JOINDRE_PLAN_BRIDE_PERCEE_IT2,
  MANCHETTE_A_IT1,
  MANCHETTE_A_IT2,
  MANCHETTE_B_IT1,
  MANCHETTE_B_IT2,
  MANCHETTE_DIAMETRE_IT1,
  MANCHETTE_DIAMETRE_IT2,
  MANCHETTE_IT1,
  MANCHETTE_IT2,
  MANCHETTE_LARGEUR_IT1,
  MANCHETTE_LARGEUR_IT2,
  TITRE_BRIDE_IT1,
  TITRE_BRIDE_PERCEE,
  TITRE_EXTREMITE_IDENTIQUE,
  TITRE_FIXATION_EXTREMITES_IT1,
  TITRE_MANCHETTE_IT1,
  TITRE_TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT2,
} from "../../../../../constantes/symbols/SymbolsS01";
import {
  arrayFixationExtremite,
  arraySectionExtremite,
  arrayTypeExtremites,
} from "../../../../../constantes/symbols/SymbolsServicesS01";
import { importAll } from "../../../../../helper/ImportAll";
import {
  BUTTON_GROUP,
  SWITCH,
  TEXTFIELD_NUMBER,
  DROPZONE,
  BUTTON_GROUP_BOX_SHADOW,
  TEXT,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_TYPE_EXTREMITES_IT2 = Symbol("typeExtremitesIt2");
const TITRE_MANCHETTE_IT2 = Symbol("manchetteIt2");
const TITRE_BRIDE_IT2 = Symbol("brideIt2");
const TITRE_BRIDE_PERCEE_IT2 = Symbol("bridePerceeIt2");
const TITRE_FIXATION_EXTREMITES_IT2 = Symbol("fixationExtremitesIt2");

export const formulaireDimensionsExtremites = (styles, t) => [
  {
    nom: TITRE_EXTREMITE_IDENTIQUE,
    label: t.extremiteIdentique + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00170,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: EXTREMITE_IDENTIQUE,
    type: SWITCH,
    cssFontFamily: styles.cssFontFamily,
    cssSwitch: styles.cssSwitch,
    cssMarginFormSection: styles.sectionFormMarginB,
    false: t.non,
    true: t.oui,
  },
];

//-----------Deux fois le même formulaire - itération 1----------//
export const formulaireTypeExtremitesIt1 = (styles, t) => [
  {
    nom: TITRE_TYPE_EXTREMITES_IT1,
    label: t.typeExtremite + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00040$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: TYPE_EXTREMITES_IT1,
    type: BUTTON_GROUP_BOX_SHADOW,
    cssFontFamily: styles.cssFontFamily,
    cssRadioPhoto: styles.cssRadioPhoto,
    cssRadioElementExtremites: styles.radioElementExtremites,
    cssMarginFormSection: styles.sectionFormMarginB,
    cssSurroundGridItem: styles.cssSurroundGridItem,
    cssGridRadio: styles.gridRadioExtremites,
    images: importAll(),
    donnees: arrayTypeExtremites(t, 1),
  },
];

export const formulaireSectionDeLaManchetteIt1 = (styles, t) => [
  {
    nom: TITRE_MANCHETTE_IT1,
    label: t.sectionManchette + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00020$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: MANCHETTE_IT1,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: arraySectionExtremite(t),
  },
  {
    nom: MANCHETTE_LARGEUR_IT1,
    label: t.manchetteLargeur + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00050$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaManchetteRondeIt1 = (styles, t) => [
  {
    nom: MANCHETTE_DIAMETRE_IT1,
    label: t.manchetteDiametre + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00080$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaManchetteRectangulaireIt1 = (styles, t) => [
  {
    nom: MANCHETTE_A_IT1,
    label: t.manchetteA + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00090$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: MANCHETTE_B_IT1,
    label: t.manchetteB + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00100$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaBrideIt1 = (styles, t) => [
  {
    nom: TITRE_BRIDE_IT1,
    label: t.sectionBride + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00110$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_IT1,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: arraySectionExtremite(t),
  },
];

export const formulaireSectionDeLaBrideRondeIt1 = (styles, t) => [
  {
    nom: BRIDE_DIAMETRE_IT1,
    label: t.brideDiametre + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00120$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaBrideRectangulaireIt1 = (styles, t) => [
  {
    nom: BRIDE_A_IT1,
    label: t.brideA + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00130$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_B_IT1,
    label: t.brideB + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00140$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireBridePerceeIt1 = (styles, t) => [
  {
    nom: TITRE_BRIDE_PERCEE,
    label: t.bridePercee + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.joindrePlanBridePercee$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_PERCEE_IT1,
    type: SWITCH,
    cssFontFamily: styles.cssFontFamily,
    cssSwitch: styles.cssSwitch,
    cssMarginFormSection: styles.sectionForm0Margin,
    false: t.non,
    true: t.oui,
  },
];

export const formulaireBridePerceeOuiIt1 = (styles, t) => [
  {
    nom: JOINDRE_PLAN_BRIDE_PERCEE_IT1,
    label: t.joindrePlanPercage,
    type: DROPZONE,
    description: t.descriptionBridePercee,
    styles: styles,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
];

export const formulaireFixationExtremitesIt1 = (data, styles, t) => [
  {
    nom: TITRE_FIXATION_EXTREMITES_IT1,
    label: t.fixationExtremite + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00200$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: FIXATION_EXTREMITES_IT1,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: data,
  },
];

//-----------Deux fois le même formulaire - itération 2----------//
export const formulaireTypeExtremitesIt2 = (styles, t) => [
  {
    nom: TITRE_TYPE_EXTREMITES_IT2,
    label: t.typeExtremite + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00040$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: TYPE_EXTREMITES_IT2,
    type: BUTTON_GROUP_BOX_SHADOW,
    cssFontFamily: styles.cssFontFamily,
    cssRadioPhoto: styles.cssRadioPhoto,
    cssRadioElementExtremites: styles.radioElementExtremites,
    cssMarginFormSection: styles.sectionForm0Margin,
    cssSurroundGridItem: styles.cssSurroundGridItem,
    cssGridRadio: styles.gridRadioExtremites,
    images: importAll(),
    donnees: arrayTypeExtremites(t),
  },
];

export const formulaireSectionDeLaManchetteIt2 = (styles, t) => [
  {
    nom: TITRE_MANCHETTE_IT2,
    label: t.sectionManchette + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00020$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: MANCHETTE_IT2,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: arraySectionExtremite(t),
  },
  {
    nom: MANCHETTE_LARGEUR_IT2,
    label: t.manchetteLargeur + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00050$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaManchetteRondeIt2 = (styles, t) => [
  {
    nom: MANCHETTE_DIAMETRE_IT2,
    label: t.manchetteDiametre + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00080$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaManchetteRectangulaireIt2 = (styles, t) => [
  {
    nom: MANCHETTE_A_IT2,
    label: t.manchetteA + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00090$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: MANCHETTE_B_IT2,
    label: t.manchetteB + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00100$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaBrideIt2 = (styles, t) => [
  {
    nom: TITRE_BRIDE_IT2,
    label: t.sectionBride + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00110$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_IT2,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: arraySectionExtremite(t),
  },
];

export const formulaireSectionDeLaBrideRondeIt2 = (styles, t) => [
  {
    nom: BRIDE_DIAMETRE_IT2,
    label: t.brideDiametre + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00120$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaBrideRectangulaireIt2 = (styles, t) => [
  {
    nom: BRIDE_A_IT2,
    label: t.brideA + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00130$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_B_IT2,
    label: t.brideB + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0012_Q00140$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireBridePerceeIt2 = (styles, t) => [
  {
    nom: TITRE_BRIDE_PERCEE_IT2,
    label: t.bridePercee + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.joindrePlanBridePercee$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_PERCEE_IT2,
    type: SWITCH,
    cssFontFamily: styles.cssFontFamily,
    cssSwitch: styles.cssSwitch,
    cssMarginFormSection: styles.sectionForm0Margin,
    false: t.non,
    true: t.oui,
  },
];

export const formulaireBridePerceeOuiIt2 = (styles, t) => [
  {
    nom: JOINDRE_PLAN_BRIDE_PERCEE_IT2,
    label: t.joindrePlanPercage,
    type: DROPZONE,
    styles: styles,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
];

export const formulaireFixationExtremitesIt2 = (data, styles, t) => [
  {
    nom: TITRE_FIXATION_EXTREMITES_IT2,
    label: t.fixationExtremite + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00200$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: FIXATION_EXTREMITES_IT2,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: data,
  },
];

export const fixationExtremitesManchette = (t) => [
  arrayFixationExtremite(t)[0],
  arrayFixationExtremite(t)[1],
];

export const fixationExtremitesBride = (t) => [
  arrayFixationExtremite(t)[0],
  arrayFixationExtremite(t)[2],
];

export const fixationExtremitesManchetteEtBride = (t) =>
  arrayFixationExtremite(t);
