//Etape 4
const TYPE_ENROULEUR = Symbol("SQ0078_Q00040");
const MATIERE_TOILE = Symbol("SQ0078_Q00030");

//Etape 5
const L_MAX = Symbol("SQ0077_Q00020");
const LARGEUR_TOILE = Symbol("SQ0077_Q00030");
const MAXIMUM_ENROULE = Symbol("SQ0077_Q00040");
const SECTION_MAX_A = Symbol("SQ0077_Q00060$1");
const SECTION_MAX_B = Symbol("SQ0077_Q00060$2");
// const SECTION_MAX_C = Symbol("SQ0077_Q00060$3");

//Etape 6
const MATIERE_EXTREMITE = Symbol("SQ0079_Q00020");
const DIMENSIONS_EXTREMITE = Symbol("SQ0079_Q00030");
const POSITION_EXTREMITE = Symbol("SQ0079_Q00040");
const FIXATION_CAISSON = Symbol("SQ0079_Q00050");
const POSITION_FIXATION_CAISSON = Symbol("SQ0079_Q00080");
const COTE_AGRESSE_TOILE = Symbol("SQ0079_Q00070");
const SECTION_MAX = Symbol("SQ0077_Q00060");

export {
  //Etape4
  TYPE_ENROULEUR,
  MATIERE_TOILE,
  //Etape5
  L_MAX,
  LARGEUR_TOILE,
  MAXIMUM_ENROULE,
  SECTION_MAX_A,
  SECTION_MAX_B,
  // SECTION_MAX_C,
  SECTION_MAX,
  //Etape6
  MATIERE_EXTREMITE,
  DIMENSIONS_EXTREMITE,
  POSITION_EXTREMITE,
  FIXATION_CAISSON,
  POSITION_FIXATION_CAISSON,
  COTE_AGRESSE_TOILE,
};
