import { styled } from "@mui/system";

export const StyledDivConnexion = styled("div")({
  backgroundColor: "white",
  width: "30%",
  maxWidth: "530px",
  maxHeight: "650px",
  boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "2em 3em 3em 2em",
  "@media (max-width: 900px)": {
    width: "50%",
  },
  "@media (max-width: 600px)": {
    width: "100%",
    padding: "1em 2em 2em 1em",
  },
});

export const StyledDivConnexionParent = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  backgroundSize: "cover!important",
  backgroundRepeat: "no-repeat!important",
  backgroundColor: "#485C96",
});

export const StyledDivLogos = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
  marginBottom: "2em",
});

export const StyledDivButtonConnexion = styled("div")({
  width: "100%",
  display: "flex",
  flexWrap: "wrap-reverse",
  justifyContent: "space-between",
  rowGap: "1em",
});

export const StyledDivButtonConnexionEC = styled("div")({
  width: "100%",
  display: "flex",
  flexWrap: "wrap-reverse",
  justifyContent: "center",
  rowGap: "1em",
});

export const StyledDivOverlay = styled("div")({
  width: "100%",
  height: "100vh",
  background: "rgba(72, 92, 150, 0.5)",
  position: "absolute",
});

export const StyledDivImgProfil = styled("div")({
  width: "47%",
  height: "80vh",
  clipPath: "polygon(20% 0, 100% 0, 100% 100%, 0 100%)",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  "@media (max-width: 600px)": {
    display: "none",
  },
});

export const StyledDivProfilParent = styled("div")({
  height: "80vh",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const StyledDivProfil = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "50%",
  alignItems: "center",
  paddingTop: "2em",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

export const StyledDivProfilContent = styled("div")({});

export const StyledDivOverlayProfil = styled("div")({
  width: "47%",
  height: "80vh",
  clipPath: "polygon(20% 0, 100% 0, 100% 100%, 0 100%)",
  "@media (max-width: 600px)": {
    display: "none",
  },
  background: "rgba(72, 92, 150, 0.5)",
  position: "absolute",
  right: "0",
});

export const StyledDivHeaderCollaborateur = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0 1em",
  columnGap: "1em",
  ".MuiGrid-root": {
    cursor: "pointer",
    width: "35%",
    textAlign: "center",
    div: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "@media (max-width: 600px)": {
    flexDirection: "column",
    rowGap: "0.5em",
  },
});

export const StyledDivBackoffice = styled("div")({
  minHeight: "80vh",
  padding: "2em 6em",
  "@media (max-width: 900px)": {
    padding: "1em 3em",
  },
  "@media (max-width: 600px)": {
    padding: "1em",
  },
});

export const StyledDivButtonValiderAnnuler = styled("div")({
  display: "flex",
  flexWrap: "wrap-reverse",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "1em",
  columnGap: "3em",
  marginTop: "4em",
  "&.modal": {
    marginTop: "2em",
  },
});

export const StyledDivChampsModifierParent = styled("div")({
  display: "flex",
  justifyContent: "center",
  margin: "3em 0 2em 0",
});

export const StyledDivRechercherAjouter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "3em",
  flexWrap: "wrap",
  columnGap: "2em",
  rowGap: "2em",
  "@media (max-width: 600px)": {
    justifyContent: "center",
  },
});

export const StyledDivEntete = styled("div")({
  width: "100%",
  ".MuiGrid-root.MuiGrid-item": {
    fontWeight: "600",
    color: "white",
    fontFamily: "Dosis",
    fontSize: "1.2em",
    backgroundColor: "#2A375C",
    textTransform: "uppercase",
    "@media (max-width: 900px)": {
      fontSize: "1em",
    },
    "@media (max-width: 650px)": {
      fontSize: "1.2em",
    },
  },
});

export const StyledDivPagination = styled("div")({
  margin: "2em 0",
  ".MuiButtonBase-root.MuiPaginationItem-root": {
    backgroundColor: "white",
    fontSize: "1.1em",
    fontFamily: "Cabin",
    color: "#2A375C",
    margin: "0 -0.2em 0 -0.2em",
    "&:hover": {
      backgroundColor: "rgba(204,0,123,0.3)",
    },
    "&.Mui-selected": {
      color: "#CC007B",
      fontSize: "1.3em",
      textDecoration: "underline",
    },
  },
  ".MuiSvgIcon-root.MuiPaginationItem-icon": {
    fontSize: "2.5em",
  },
  "@media (max-width: 900px)": {
    ".MuiButtonBase-root.MuiPaginationItem-root": {
      fontSize: "1em",
      "&.Mui-selected": {
        fontSize: "1.5em",
      },
    },
    ".MuiSvgIcon-root.MuiPaginationItem-icon": {
      fontSize: "2em",
    },
  },
});

export const StyledDivPaginationOrange = styled("div")({
  margin: "2em 0",
  ".MuiButtonBase-root.MuiPaginationItem-root": {
    backgroundColor: "white",
    fontSize: "1.1em",
    fontFamily: "Cabin",
    color: "#2A375C",
    margin: "0 -0.2em 0 -0.2em",
    "&:hover": {
      backgroundColor: "rgba(236,136,18,0.3)",
    },
    "&.Mui-selected": {
      color: "#EC8812",
      fontSize: "1.3em",
      textDecoration: "underline",
    },
  },
  ".MuiSvgIcon-root.MuiPaginationItem-icon": {
    fontSize: "2.5em",
  },
  "@media (max-width: 900px)": {
    ".MuiButtonBase-root.MuiPaginationItem-root": {
      fontSize: "1em",
      "&.Mui-selected": {
        fontSize: "1.5em",
      },
    },
    ".MuiSvgIcon-root.MuiPaginationItem-icon": {
      fontSize: "2em",
    },
  },
});

export const StyledDivLignesTab = styled("div")({
  width: "100%",
  "div.lignesTab:nth-of-type(odd)": {
    backgroundColor: "#E7EAFF",
  },
  "div.lignesTab:nth-of-type(even)": {
    backgroundColor: "#F8F9FF",
  },
});

export const StyledDivDdpSelected = styled("div")({
  color: "#EC8812",
  textTransform: "uppercase",
});

export const StyledDivGestionSelected = styled("div")({
  color: "#CC007B",
  textTransform: "uppercase",
});

export const StyledDivTriangleHeader = styled("div")({
  width: "40px",
  height: "20px",
  clipPath: "polygon(0 100%, 50% 0, 100% 100%)",
  backgroundColor: "white",
  backgroundRepeat: "no-repeat",
  "@media (max-width: 600px)": {
    display: "none",
  },
});

export const StyledDivMenuNotSelected = styled("div")({
  marginBottom: "20px",
});
export const StyledDivSearchBar = styled("div")({
  width: "100%",
  alignSelf: "end",
});

export const StyledDivArrows = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "5px",
  alignSelf: "center",
});

export const StyledDivArrowUpIcon = styled("div")({
  width: "0",
  height: "0",
  borderLeft: "4px solid transparent",
  borderRight: "4px solid transparent",
  borderBottom: "7px solid white",
  ":hover": {
    cursor: "pointer",
  },
  ":active": {
    borderBottom: "7px solid #EC8812",
  },
});

export const StyledDivArrowDownIcon = styled("div")({
  width: "0",
  height: "0",
  borderLeft: "4px solid transparent",
  borderRight: "4px solid transparent",
  borderTop: "7px solid white",
  ":hover": {
    cursor: "pointer",
  },
  ":active": {
    borderTop: "7px solid #EC8812",
  },
});

export const StyledDivMsgSucces = styled("div")({
  width: "100%",
  height: "41px",
  backgroundColor: "#ECF4CD",
  font: "60 18px Cabin",
  color: "#2A375C",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "7px",
});

export const StyledDivMsgEchec = styled("div")({
  width: "100%",
  height: "41px",
  backgroundColor: "#FFE7D6",
  font: "60 18px Cabin",
  color: "#2A375C",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "7px",
});
