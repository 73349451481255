export const initChampsFormikToJsonDefaultBoolean = (tableauDesChamps, formulaireExistant) => {
    return initChampsFormikToJsonDefaultCustom(tableauDesChamps, formulaireExistant, 0);
}

export const initChampsFormikToJsonDefaultString = (tableauDesChamps, formulaireExistant) => {
    return initChampsFormikToJsonDefaultCustom(tableauDesChamps, formulaireExistant, '');
}

export const initChampsFormikToJsonDefaultArray = (tableauDesChamps, formulaireExistant) => {
    return initChampsFormikToJsonDefaultCustom(tableauDesChamps, formulaireExistant, []);
}

export const initChampsFormikToJsonDefaultAutocomplete = (tableauDesChamps, formulaireExistant) => {
    return initChampsFormikToJsonDefaultCustom(tableauDesChamps, formulaireExistant, null);
}

export const initChampsFormikToJsonDefaultNull = (tableauDesChamps, formulaireExistant) => {
    return initChampsFormikToJsonDefaultCustom(tableauDesChamps, formulaireExistant, null);
}

export const initChampsFormikToJsonMultiIteratifDefaultString = (tableauDesChamps, formulaireExistant, nbChamps, fieldName) => {
    return {[fieldName.description]:
        [...Array(nbChamps)].map(() => {
            return tableauDesChamps.reduce((firstValue, secondValue) => {
                return Object.assign({
                    [firstValue.description]:formulaireExistant[firstValue?.description]||'', 
                    [secondValue.description]:formulaireExistant[secondValue?.description]||''
                }, {})
            });
        })
    }
}

export const initChampsFormikToJsonIteratifDefaultString = (fieldName, formulaireExistant, nbChamps) => {
    return {[fieldName.description]:
        [...Array(nbChamps)].map((value, index) => {
            //Always set the first value to 1
            if(index===0){
                return '1';
            }else if(formulaireExistant[fieldName?.description]!==undefined && formulaireExistant[fieldName?.description][index]!==undefined){
                return formulaireExistant[fieldName?.description][index];
            }else{
                return '';
            }
        })
    }
}

export const initChampsFormikToJsonDefaultCustom = (tableauDesChamps, formulaireExistant, customValue) => {
    return tableauDesChamps.reduce((prevValue, currValue) => Object.assign({
        ...prevValue, 
        [currValue.description]:
            formulaireExistant[currValue?.description]!==undefined
            &&formulaireExistant[currValue?.description]!==''
                ?formulaireExistant[currValue?.description]
                :customValue
    }), {});
}