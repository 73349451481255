import * as Yup from "yup";
import { S04_ACCESSOIRES } from "../../../../../constantes/symbols/SymbolsS04";
import { S04_STEP7_arrayAccessories } from "../../../../../constantes/symbols/SymbolsServicesS04";

/* A Yup validation schema. */
export const validAccessoiresS04 = (t, etape) => {
  if (etape !== 7) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [S04_ACCESSOIRES.description]: Yup.mixed()
      .test(
        "TypeSelectionInSelect",
        () => {
          return t.erreurSelectionType;
        },
        (value) =>
          S04_STEP7_arrayAccessories(t).some((e) => e.code === parseInt(value))
      )
      .required(t.erreurSelectionType),
  });
};
