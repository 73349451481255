import { styled } from "@mui/system";

export const StyledH1Connexion = styled('h1')({
    fontFamily: 'Dosis',
    fontWeight: '800',
    fontSize: '3em',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
    color: '#2A375C',
    marginTop: '0px',
});

export const StyledH1Profil = styled('h1')({
    fontFamily: 'Dosis',
    fontWeight: '600',
    fontSize: '3em',
    color: '#EC8812',
    marginBottom: '1em',
});

export const StyledPProfil = styled('p')({
    fontFamily: 'Cabin',
    fontWeight: '600',
    fontSize: '1.5em',
    color: '#2A375C',
});

export const StyledH1Orange = styled('h1')({
    fontFamily: 'Dosis',
    fontWeight: '600',
    fontSize: '2.25em',
    color: '#EC8812',
    marginBottom: '1em',
});

export const StyledH1Rose = styled('h1')({
    fontFamily: 'Dosis',
    fontWeight: '600',
    fontSize: '2.25em',
    color: '#CC007B',
    marginBottom: '1em',
});

export const StyledP = styled('p')({
    fontFamily: 'Cabin',
    fontWeight: '400',
    fontSize: '1.3em',
    color: '#2A375C',
});