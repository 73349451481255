/**
 * Il prend un fichier et une fonction qui définit la progression du téléchargement du fichier et
 * renvoie une promesse qui se résout en la chaîne base64 du fichier
 * @param file - Le fichier à convertir en base64
 * @param setProgress - une fonction qui définit l'état d'avancement du composant
 */
export const toBase64 = async (file, setProgress) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onprogress = (data) => {
        if(data.lengthComputable){
            var progress = parseInt(((data.loaded / data.total) * 100), 10 );
            setProgress(progress);
        }
    }
    reader.onload = () => resolve(reader.result);
    reader.onloadend = () => setProgress(-1);
    reader.onerror = error => reject(error);
});

/**
 * Convertir un fichier en base64 sans progrès.
 * @param file - Le fichier à convertir en base64
 */
export const toBase64WithoutProgress = async (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});