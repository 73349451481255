import { Grid, InputAdornment, TextField } from "@mui/material";
import { TYPE_NUMBER, OUTLINED } from "../commonParameters/CommonParameters";
import { genererBrSiChampNonVideOuUndefined } from "../commonMethods/CommonMethods";
import { StyledDivFormSection } from "../../../styledComponents/StyledDiv";
import { StyledTextfield } from "../../../styledComponents/StyledTextfield";
import { HelperText } from "../../HelperText";

/**
 * It generates a text field with a number type.
 * @param champ
 * @param formik
 * @returns A React component.
 */
const genererChampTextFieldNumber = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledTextfield
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label}
          type={TYPE_NUMBER.description}
          variant={OUTLINED.description}
          value={formik.values[champ.nom.description]}
          onFocus={() => {
            formik.setFieldValue("focused", champ.nom.description);
          }}
          onChange={formik.handleChange}
          onBlur={(e) => {
            formik.setFieldValue("focused", null);
            formik.handleBlur(e);
          }}
          disabled={champ.disabled ? champ.disabled : false}
          error={Boolean(
            formik.touched[champ.nom.description] &&
              formik.errors[champ.nom.description]
          )}
          helperText={
            <>
              {champ.helper}
              {genererBrSiChampNonVideOuUndefined(champ.helper)}
              {formik.touched[champ.nom.description]
                ? formik.errors[champ.nom.description]
                : null}
            </>
          }
          InputProps={{
            className: champ.textInformation?.cssIcon,
            endAdornment: champ.textInformation?.text ? (
              <InputAdornment position="end">
                <HelperText
                  name={champ.nom.description}
                  textInformation={champ.textInformation}
                />
              </InputAdornment>
            ) : null,
            startAdornment: null,
          }}
          size="small"
        />
      </Grid>
    </StyledDivFormSection>
  );
};

export { genererChampTextFieldNumber };
