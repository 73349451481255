import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import './index.css';
import { App } from "./routes/App";
import { Provider } from "react-redux";
import store from './app/store';
import { StyledEngineProvider } from "@mui/material";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </StyledEngineProvider>
        </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);