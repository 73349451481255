import { S06_LONGUEUR_COMPRIMEE, S06_LONGUEUR_DEPLOYEE, S06_TOILE } from "../../../../../constantes/symbols/SymbolsS06";

import {
  TEXT,
  TEXTFIELD_BY_LABEL,
  TEXTFIELD_NUMBER,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_MATIERE_TOILE = Symbol("Matière toile");
const DESCRIPTION_MATIERE_TOILE = Symbol("Description matière toile");


export const formulaireToileS06 = (optionList, styles, t) => [
  {
    nom: S06_LONGUEUR_DEPLOYEE,
    label: t.longueurDeployee + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0102_Q00020,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S06_LONGUEUR_COMPRIMEE,
    label: t.longueurComprimee + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    textInformation: {
      text: t.SQ0102_Q00030,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: TITRE_MATIERE_TOILE,
    label: t.toile,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0101_Q00020,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: DESCRIPTION_MATIERE_TOILE,
    label: t.descriptionToile2,
    type: TEXT,
    cssFontFamily: styles.descriptionInDiv,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
  {
    nom: S06_TOILE,
    label: t.descrMatiereToile,
    type: TEXTFIELD_BY_LABEL,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: optionList,
  },
];