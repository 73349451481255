import {
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  StyledDivArrowDownIcon,
  StyledDivArrows,
  StyledDivArrowUpIcon,
  StyledDivSearchBar,
} from "../../styledComponents/StyledDivBackoffice";
import {
  StyledTextfieldSearchDdp,
} from "../../styledComponents/StyledTextfieldBackoffice";
import {
  getOneContact,
} from "../../../model/slice/EspaceClientReducer";

export const ContactItem = ({
  id,
  action,
  titre,
  prenom,
  nom,
  fonction,
  telephone,
  email,
  refus_emailing,
  clientId,
  contactId,
  state: { t },
  searchBarAction: { setSearchBarContentNom },
  sortAction: { setSortedNom },
}) => {
  const [columnSize, setColumnSize] = useState(1);

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const noClassNameMobileAndTablette =
    "retirerFormatTablette retirerFormatMobile hasBorderRight";
  const noClassNameMobile = "retirerFormatMobile hasBorderRight";
  const alwaysDisplayed = "hasBorderRight";

  const handleChange = (event, setterSearchBar) => {
    setterSearchBar(event.target.value);
  };

  const showForm = (contactId) => {
    let url = new URL(window.location.href);

    if (!url.pathname.endsWith("/")) {
      url.pathname += "/";
    }

    url.searchParams.set("contact", contactId);
    window.history.pushState({}, "", url);

    const encodedClientId = localStorage.getItem("clientId");
    const clientId = atob(encodedClientId);

    dispatch(getOneContact({ clientId, contactId }));
  };

  const DisplayAdornment = ({ md, style, xs }) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={md ? md : columnSize}
        className={style + " itemDdp action"}
        xs={xs ? xs : 4}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        <IconButton style={{ padding: 0 }}>
          <div
            style={{
              padding: 6,
              borderRadius: 4,
              overflow: "hidden",
              border: "2px #1C243C solid",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "inline-flex",
            }}
            onClick={() => showForm(contactId)}
          >
            <div
              style={{
                color: "#1C243C",
                fontSize: 18,
                fontFamily: "Dosis",
                fontWeight: "600",
              }}
            >
              {t.modifier}
            </div>
          </div>
        </IconButton>
      </Grid>
    );
  };

  const displaySearchBar = (setValue) => {
    if (setValue) {
      return (
        <div>
          <StyledDivSearchBar>
            <StyledTextfieldSearchDdp
              variant="outlined"
              size="small"
              onChange={(event) => handleChange(event, setValue)}
              InputProps={{
                endAdornment: (
                  <SearchIcon md={{ color: "#EC8812", fontSize: 10 }} />
                ),
              }}
            />
          </StyledDivSearchBar>
        </div>
      );
    }
  };

  const displaySortArrows = (setSortedValue) => {
    if (setSortedValue) {
      return (
        <Stack alignItems="center" justifyContent="center">
          <StyledDivArrows>
            <StyledDivArrowUpIcon
              fontSize="small"
              onClick={() => setSortedValue("up")}
            />
            <StyledDivArrowDownIcon
              fontSize="small"
              onClick={() => setSortedValue("down")}
            />
          </StyledDivArrows>
        </Stack>
      );
    }
  };

  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={`${style} columnHeaderEC ${
          searchBarStateSetter ? "" : "hasSearch"
        }`}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
          // marginLeft={name === "Action" ? "-0.75rem" : "0"}
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
            {displaySortArrows(sortStateSetter)}
          </Stack>
          <Stack>{displaySearchBar(searchBarStateSetter)}</Stack>
        </Stack>
      </Grid>
    );
  };

  const displayItem = (name, md, sm, style) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </Grid>
    );
  };

  const displayItemAction = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return <DisplayAdornment md={md} xs={xs} style={style} />;
  };

  const displayRefusEmailing = (name, md, sm, style) => {
    let refusEmailing = name === "Vrai" ? "Oui" : "Non";
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: refusEmailing }}
        />
      </Grid>
    );
  };

  return (
    <Grid
      container
      item
      direction="row"
      className={id === 0 ? "row0" : "row"}
      style={id === 0 ? { borderRadius: "8px 8px 0 0" } : { height: "100px" }}
    >
      {id === 0
        ? displayItemHeader(
            titre,
            null,
            null,
            1,
            1,
            4,
            noClassNameMobileAndTablette
          )
        : displayItem(titre, 1, 1, noClassNameMobileAndTablette)}
      {id === 0
        ? displayItemHeader(prenom, null, null, 1.5, 1.5, 4, noClassNameMobile)
        : displayItem(prenom, 1.5, 1.5, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            nom,
            setSearchBarContentNom,
            setSortedNom,
            1.5,
            1.5,
            8,
            alwaysDisplayed
          )
        : displayItem(nom, 1.5, 1.5, alwaysDisplayed)}
      {id === 0
        ? displayItemHeader(email, null, null, 1.5, 1.5, 4, noClassNameMobile)
        : displayItem(email, 1.5, 1.5, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            telephone,
            null,
            null,
            1.5,
            1.5,
            4,
            noClassNameMobile
          )
        : displayItem(telephone, 1.5, 1.5, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(fonction, null, null, 2, 2, 4, noClassNameMobile)
        : displayItem(fonction, 2, 2, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
            refus_emailing,
            null,
            null,
            2,
            2,
            null,
            noClassNameMobileAndTablette
          )
        : displayRefusEmailing(
            refus_emailing,
            2,
            2,
            noClassNameMobileAndTablette
          )}
      {id === 0
        ? displayItemHeader(action, null, null, 1, 1, 4, "")
        : displayItemAction(action, null, null, 1, 1, 4, "")}
    </Grid>
  );
};
