import { equalsCodeOrLabel } from "../../../../../constantes/symbols/HelperSymbolsServices";
import {
  FAMILLE_PROTECTION_INDUSTRIELLE,
  AXE_EMPLACEMENT,
  CODE_ARTICLE,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  NUMERO_DE_PLAN,
  POSITION_DE_TRAVAIL,
  TYPE_DE_MACHINE,
  VITESSE_DEPLACEMENT,
  ENVIRONNEMENT,
  COMMENTAIRE,
  QUANTITEE_SOUHAITEE,
  ADRESSE_MAIL,
  CODE_POSTAL,
  FONCTION,
  NOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
  PRENOM,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  BUTEE_CAOUTCHOUC,
  ENVELOPPE_MATIERE,
  PANTOGRAPHE,
  ROULETTES,
  S08_ANGLE_J,
  S08_ANGLE_K,
  S08_ANGLE_L,
  S08_FIXATION_GROS_ELEMENT,
  S08_FIXATION_PETIT_ELEMENT,
  S08_LONGUEUR_1ER_ELEMENT,
  S08_LONGUEUR_A,
  S08_LONGUEUR_B,
  S08_LONGUEUR_C,
  S08_LONGUEUR_COURSE,
  S08_LONGUEUR_D,
  S08_LONGUEUR_E,
  S08_LONGUEUR_F,
  S08_LONGUEUR_G,
  S08_LONGUEUR_H,
  S08_LONGUEUR_I,
  S08_LONGUEUR_MAX,
  S08_LONGUEUR_MIN,
  S08_NOMBRE_ELEMENTS,
  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
  S08_SECTION_ENVELOPPE_PLAT,
  S08_SECTION_ENVELOPPE_S,
  S08_SECTION_ENVELOPPE_TOIT,
  S08_SECTION_ENVELOPPE_TOIT_PLAT,
  S08_TYPE_PENTE,
  S08_TYPE_SECTION_ENVELOPPE,
  S08_TYPE_TOIT,
  TOLE_LARMEE,
} from "../../../../../constantes/symbols/SymbolsS08";
import {
  S08_STEP5_arrayTypePente,
  S08_STEP5_arrayTypeSection,
  S08_STEP5_arrayTypeToit,
} from "../../../../../constantes/symbols/SymbolsServicesS08";
import { RECAP_S08 } from "../../../components/generateFormulary/types/TypeFieldsFormulary";
import {
  FICHIERS,
  FICHIER_RECAPITULATIF,
} from "../../S01/recapitulatif/ChampsRecapitulatif";

export const formulaireRecapitulatifTypeProtectionS08 = (t) => [
  {
    nom: FAMILLE_PROTECTION_INDUSTRIELLE,
    label: t.champTypeDeProtection,
    type: RECAP_S08,
  },
];

export const formulaireRecapitulatifMatiere = (t) => [
  {
    nom: ENVELOPPE_MATIERE,
    label: t.champEnveloppeMatiere,
    type: RECAP_S08,
  },
];

export const formulaireFormeS08 = (t, formulaire) => [
  {
    nom: S08_TYPE_SECTION_ENVELOPPE,
    label: t.champTypeSectionEnveloppe,
    type: RECAP_S08,
  },
  equalsCodeOrLabel(
    formulaire[S08_TYPE_SECTION_ENVELOPPE.description],
    S08_STEP5_arrayTypeSection(t),
    1
  )
    ? {
        nom: S08_TYPE_TOIT,
        label: t.champTypeToit,
        type: RECAP_S08,
      }
    : null,
  equalsCodeOrLabel(
    formulaire[S08_TYPE_SECTION_ENVELOPPE.description],
    S08_STEP5_arrayTypeSection(t),
    2
  )
    ? {
        nom: S08_TYPE_PENTE,
        label: t.champTypePente,
        type: RECAP_S08,
      }
    : null,
  equalsCodeOrLabel(
    formulaire[S08_TYPE_SECTION_ENVELOPPE.description],
    S08_STEP5_arrayTypeSection(t),
    0
  )
    ? {
        nom: S08_SECTION_ENVELOPPE_PLAT,
        label: t.champSectionEnveloppe,
        type: RECAP_S08,
      }
    : null,

  equalsCodeOrLabel(
    formulaire[S08_TYPE_SECTION_ENVELOPPE.description],
    S08_STEP5_arrayTypeSection(t),
    1
  )
    ? parseInt(formulaire[S08_TYPE_PENTE.description]) ===
      S08_STEP5_arrayTypeToit(t)[0].code
      ? {
          nom: S08_SECTION_ENVELOPPE_TOIT,
          label: t.champSectionEnveloppe,
          type: RECAP_S08,
        }
      : {
          nom: S08_SECTION_ENVELOPPE_TOIT_PLAT,
          label: t.champSectionEnveloppe,
          type: RECAP_S08,
        }
    : null,

  equalsCodeOrLabel(
    formulaire[S08_TYPE_SECTION_ENVELOPPE.description],
    S08_STEP5_arrayTypeSection(t),
    2
  )
    ? parseInt(formulaire[S08_TYPE_PENTE.description]) ===
      S08_STEP5_arrayTypePente(t)[0].code
      ? {
          nom: S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
          label: t.champSectionEnveloppe,
          type: RECAP_S08,
        }
      : {
          nom: S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
          label: t.champSectionEnveloppe,
          type: RECAP_S08,
        }
    : null,
  equalsCodeOrLabel(
    formulaire[S08_TYPE_SECTION_ENVELOPPE.description],
    S08_STEP5_arrayTypeSection(t),
    3
  )
    ? {
        nom: S08_SECTION_ENVELOPPE_S,
        label: t.champSectionEnveloppe,
        type: RECAP_S08,
      }
    : null,
  {
    nom: S08_LONGUEUR_A,
    label: t.champLongueurA,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_B,
    label: t.champLongueurB,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_C,
    label: t.champLongueurC,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_D,
    label: t.champLongueurD,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_E,
    label: t.champLongueurE,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_F,
    label: t.champLongueurF,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_G,
    label: t.champLongueurG,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_H,
    label: t.champLongueurH,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_I,
    label: t.champLongueurI,
    type: RECAP_S08,
  },
  {
    nom: S08_ANGLE_J,
    label: t.champAngleJ,
    type: RECAP_S08,
  },
  {
    nom: S08_ANGLE_K,
    label: t.champAngleK,
    type: RECAP_S08,
  },
  {
    nom: S08_ANGLE_L,
    label: t.champAngleL,
    type: RECAP_S08,
  },
];

export const formulaireDimensionsS08 = (t) => [
  {
    nom: S08_LONGUEUR_MAX,
    label: t.champLongueurDeployee,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_MIN,
    label: t.champLongueurComprimee,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_COURSE,
    label: t.longueurCourse,
    type: RECAP_S08,
  },
  {
    nom: S08_NOMBRE_ELEMENTS,
    label: t.champNombreElements,
    type: RECAP_S08,
  },
  {
    nom: S08_LONGUEUR_1ER_ELEMENT,
    label: t.champLongueur1erElement,
    type: RECAP_S08,
  },
];

export const formulaireExtremitesRecapS08 = (t) => [
  {
    nom: S08_FIXATION_GROS_ELEMENT,
    label: t.titreFixationGrosElement,
    type: RECAP_S08,
  },
  {
    nom: S08_FIXATION_PETIT_ELEMENT,
    label: t.titreFixationPetitElement,
    type: RECAP_S08,
  },
];

export const formulaireAccessoiresS08 = (t) => [
  {
    nom: BUTEE_CAOUTCHOUC,
    label: t.buteeCaoutchouc,
    type: RECAP_S08,
  },
  {
    nom: ROULETTES,
    label: t.roulettes,
    type: RECAP_S08,
  },
  {
    nom: PANTOGRAPHE,
    label: t.pantographe,
    type: RECAP_S08,
  },
  {
    nom: TOLE_LARMEE,
    label: t.toleLarmee,
    type: RECAP_S08,
  },
];

export const formulaireInformationsMachine = (
  t,
  serviceTypeMachine,
  serviceMarqueMachine
) => [
  {
    nom: TYPE_DE_MACHINE,
    label: t.champTypeDeMachine,
    type: RECAP_S08,
    service: serviceTypeMachine,
  },
  {
    nom: MARQUE_MACHINE,
    label: t.champMarqueDeMachine,
    type: RECAP_S08,
    service: serviceMarqueMachine,
  },
  {
    nom: MODELE_MACHINE,
    label: t.champModeleDeMachine,
    type: RECAP_S08,
  },
  {
    nom: AXE_EMPLACEMENT,
    label: t.champAxeEmplacement,
    type: RECAP_S08,
  },
  {
    nom: POSITION_DE_TRAVAIL,
    label: t.champPositionDeTravail,
    type: RECAP_S08,
  },
  {
    nom: VITESSE_DEPLACEMENT,
    label: t.champVitesseDeplacement,
    type: RECAP_S08,
  },
  {
    nom: NUMERO_DE_PLAN,
    label: t.champNDePlan,
    type: RECAP_S08,
  },
  {
    nom: CODE_ARTICLE,
    label: t.champCodeArticle,
    type: RECAP_S08,
  },
];

export const formulaireAggressions = (t, agressionsList) => [
  {
    nom: ENVIRONNEMENT,
    label: t.champEnvironnement,
    type: RECAP_S08,
    nomChamps: agressionsList,
  },
];

export const formulaireQuantités = () => [
  {
    nom: QUANTITEE_SOUHAITEE,
    type: RECAP_S08,
  },
];

export const formulaireCoordonnees = (
  t,
  upToDatePaysList,
  upToDateSecteursList,
  upToDateFonctionsList
) => [
  {
    nom: SOCIETE,
    label: t.champSociete,
    type: RECAP_S08,
  },
  {
    nom: PAYS,
    label: t.champPays,
    type: RECAP_S08,
    service: upToDatePaysList,
  },
  {
    nom: CODE_POSTAL,
    label: t.champCodePostal,
    type: RECAP_S08,
  },
  {
    nom: SECTEUR,
    label: t.champSecteurActivite,
    type: RECAP_S08,
    service: upToDateSecteursList,
  },
  {
    nom: NOM,
    label: t.champNom,
    type: RECAP_S08,
  },
  {
    nom: PRENOM,
    label: t.champPrenom,
    type: RECAP_S08,
  },
  {
    nom: FONCTION,
    label: t.champFonction,
    type: RECAP_S08,
    service: upToDateFonctionsList,
  },
  {
    nom: ADRESSE_MAIL,
    label: t.champAdresseMail,
    type: RECAP_S08,
  },
  {
    nom: TELEPHONE,
    label: t.champTelephone,
    type: RECAP_S08,
  },
];

export const formulaireCommentaire = (t, booleanIdentite) => [
  {
    nom: COMMENTAIRE,
    label: t.champCommentaire,
    type: RECAP_S08,
  },
];

export const formulaireFichiers = () => [
  {
    nom: FICHIERS,
    type: RECAP_S08,
  },
];

export const formulaireFichiersRecapitulatif = () => [
  {
    nom: FICHIER_RECAPITULATIF,
    type: RECAP_S08,
  },
];
