import React, { useEffect, useState } from "react";
import {
  StyledGridLignesTabOrangeEC,
} from "../../styledComponents/StyledGridBackoffice";
import {
  StyledDivPaginationOrange,
} from "../../styledComponents/StyledDivBackoffice";
import { Pagination } from "@mui/material";
import { ArtByOffreItem } from "./ArtByOffreItem";
import { useParams } from "react-router-dom";

export const ListeArticleByOffre = ({ actions, state }) => {

  const params = useParams();

  const [filteredList, setFilteredList] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 5,
    pageCount: 0,
    currentData: [],
  });

  useEffect(() => {
    setFilteredList(state.tableauOffres);
  }, [params, state.tableauOffres]);

  useEffect(() => {
    if (!filteredList) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const handlePageClick = (event, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const afficherListeArtByOdp = pagination.currentData.map((item) => {
    return (
      <ArtByOffreItem
        key={item.ligneOffreId}
        id={item.ligneOffreId}
        state={state}
        {...item}
        modale={actions}
      />
    );
  });

  return (
    <div>
      <StyledGridLignesTabOrangeEC style={{ marginTop: "1rem" }} container>
        <ArtByOffreItem
          key={0}
          id={0}
          action={state.t.titleAction}
          articleReferenceClient={state.t.titleTabReferenceClient}
          articleCode={state.t.titleTabCodeArticle}
          articleDesignation={state.t.titleTabDesignation}
          familleCode={state.t.titleTabFamille}
          marque={state.t.titleTabMarque}
          quantite={state.t.titleTabQuantite}
          prixUnitaireHT={state.t.titleTabPrixUnitaireHT}
          totalHT={state.t.titleTabTotalHT}
          miseADisposition={state.t.titleTabDelaisProduction}
          ligne={state.t.titleTabLigne}
          reponseLigne={state.t.titleTabReponse}
          state={state}
          modale={actions}
        />
        {afficherListeArtByOdp}
      </StyledGridLignesTabOrangeEC>
      <StyledDivPaginationOrange>
        <Pagination
          size="large"
          count={pagination.pageCount}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={0}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPaginationOrange>
    </div>
  );
};
