import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../../constantes/symbols/HelperSymbolsServices";
import {
  FORME_CORPS_A,
  FORME_CORPS_B,
  FORME_CORPS_C,
  FORME_CORPS_D,
  FORME_CORPS_DS,
  FORME_CORPS_E,
  FORME_CORPS_ES,
  FORME_CORPS_F,
  FORME_CORPS_FS,
  JOINDRE_PLAN_FORME_QUELCONQUE,
  LONGUEUR_A,
  LONGUEUR_B,
  LONGUEUR_C,
  LONGUEUR_D,
  LONGUEUR_E,
  LONGUEUR_F,
  LONGUEUR_G,
  LONGUEUR_H,
  LONGUEUR_X,
  SECTION_SYMETRIQUE,
  TOILE,
  TYPE_SECTION,
} from "../../../../../constantes/symbols/SymbolsS04";
import { S04_STEP4_arrayTypeCorps } from "../../../../../constantes/symbols/SymbolsServicesS04";
import {
  BUTTON_GROUP,
  DROPZONE,
  SWITCH,
  TEXT,
  TEXTFIELD_BY_LABEL,
  TEXTFIELD_NUMBER,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_CORPS_SECTION = Symbol("Corps- Type de section");
const TITRE_CORPS_FORME = Symbol("Corps- Forme");
const TITRE_MATIERE_TOILE = Symbol("Matière toile");
const DESCRIPTION_MATIERE_TOILE = Symbol("Description matière toile");

export const formulaireTypeSection = (styles, t) => [
  {
    nom: TITRE_CORPS_SECTION,
    label: t.titreSection,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00040,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: TYPE_SECTION,
    label: t.typeSection + t.asterisque,
    type: TEXTFIELD_BY_LABEL,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: S04_STEP4_arrayTypeCorps(t),
  },
];

export const formulaireSection = (formik, optionList, styles, t, codeQuestion) => [
  {
    nom: TITRE_CORPS_FORME,
    label: t.titreFormeCorps,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: codeQuestion,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: findFormeCorpsFromTypeSection(formik.values),
    label: t.formeCorps + t.asterisque,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: optionList,
  },
  {
    nom: LONGUEUR_A,
    label: t.longueurA + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00050,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurX = (styles, t) => [
  {
    nom: LONGUEUR_X,
    label: t.longueurX + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00270,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionSymetrique = (styles, t) => [
  {
    nom: SECTION_SYMETRIQUE,
    label: t.sectionIdentique + t.asterisque,
    type: SWITCH,
    cssFontFamily: styles.cssFontFamily,
    cssSwitch: styles.cssSwitch,
    cssMarginFormSection: styles.sectionForm0Margin,
    false: t.non,
    true: t.oui,
  },
];

export const formulaireLongueurB = (styles, t) => [
  {
    nom: LONGUEUR_B,
    label: t.longueurB + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00060,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurC = (styles, t) => [
  {
    nom: LONGUEUR_C,
    label: t.longueurC + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00070,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurD = (styles, t) => [
  {
    nom: LONGUEUR_D,
    label: t.longueurD + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00080,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurE = (styles, t) => [
  {
    nom: LONGUEUR_E,
    label: t.longueurE + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00090,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurF = (styles, t) => [
  {
    nom: LONGUEUR_F,
    label: t.longueurF + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00100,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurG = (styles, t) => [
  {
    nom: LONGUEUR_G,
    label: t.longueurG + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00110,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurH = (styles, t) => [
  {
    nom: LONGUEUR_H,
    label: t.longueurH + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0037_Q00380,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireFormeQuelconque = (styles, t) => [
  {
    nom: JOINDRE_PLAN_FORME_QUELCONQUE,
    label: t.joindrePlanSectionSoufflet,
    type: DROPZONE,
    styles: styles,
    cssTextfield: styles.descriptionDropzone,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
];

export const formulaireToile = (optionList, styles, t) => [
  {
    nom: TITRE_MATIERE_TOILE,
    label: t.toile ,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0037_Q00010,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: DESCRIPTION_MATIERE_TOILE,
    label: t.descriptionToile2,
    type: TEXT,
    cssFontFamily: styles.descriptionInDiv,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
  {
    nom: TOILE,
    label: t.descrMatiereToile,
    type: TEXTFIELD_BY_LABEL,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: optionList,
  },
];

const findFormeCorpsFromTypeSection = (payload) => {
  const symetrique = equalsBooleanOrStringOrNumber(
    payload[SECTION_SYMETRIQUE.description]
  );
  switch (true) {
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      0
    ):
      return FORME_CORPS_A;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      1
    ):
      return FORME_CORPS_B;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      2
    ):
      return FORME_CORPS_C;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      3
    ):
      return !!symetrique ? FORME_CORPS_DS : FORME_CORPS_D;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      4
    ):
      return !!symetrique ? FORME_CORPS_ES : FORME_CORPS_E;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      5
    ):
      return !!symetrique ? FORME_CORPS_FS : FORME_CORPS_F;
    default:
      return FORME_CORPS_A;
  }
};
