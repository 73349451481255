import { styled } from "@mui/system";
import { Button } from "@mui/material";

export const StyledButtonConnexion = styled(Button)({
    color: 'white',
    fontFamily: 'Cabin',
    fontSize: '1.2em',
    fontWeight: 600,
    backgroundColor: '#2A375C',
    borderRadius: 50,
    padding: '0.7em',
    minWidth: '180px',
    lineHeight: '1.1em',
    border: '2px solid #2A375C',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#ffffff',
        borderColor: '#2A375C',
        color: '#2A375C',
        boxShadow: 'none',
    },
    '&:active': {
        backgroundColor: '#ffffff',
        borderColor: '#2A375C',
        color: '#2A375C',
        boxShadow: 'none',
    },
});

export const StyledButtonMdp = styled(Button)({
    color: '#EC8812',
    fontFamily: 'Cabin',
    fontSize: 16,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
        boxShadow: 'none',
    },
});

export const StyledButtonOrange = styled(Button)({
    color: 'white',
    fontFamily: 'Cabin',
    fontSize: '1.2em',
    fontWeight: 600,
    backgroundColor: '#EC8812',
    borderRadius: 50,
    padding: '0.7em',
    lineHeight: '1.1em',
    border: '2px solid #EC8812',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#ffffff',
        borderColor: '#EC8812',
        color: '#EC8812',
        boxShadow: 'none',
    }
});

export const StyledButtonRose = styled(Button)({
    color: 'white',
    fontFamily: 'Cabin',
    fontSize: '1.2em',
    fontWeight: 600,
    backgroundColor: '#CC007B',
    borderRadius: 50,
    padding: '0.7em',
    lineHeight: '1.1em',
    border: '2px solid #CC007B',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#ffffff',
        borderColor: '#CC007B',
        color: '#CC007B',
        boxShadow: 'none',
    },
    '&.ouiModal': {
        width: '100px'
    }
});

export const StyledButtonAnnulerOrange = styled(Button)({
    color: '#EC8812',
    fontFamily: 'Cabin',
    fontSize: '1.2em',
    fontWeight: 600,
    backgroundColor: 'white',
    borderRadius: 50,
    padding: '0.4em 0.7em',
    lineHeight: '1.1em',
    border: '2px solid #EC8812',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#EC8812',
        borderColor: 'white',
        color: 'white',
        boxShadow: 'none',
    }
});

export const StyledButtonAnnulerRose = styled(Button)({
    color: '#CC007B',
    fontFamily: 'Cabin',
    fontSize: '1.2em',
    fontWeight: 600,
    backgroundColor: 'white',
    borderRadius: 50,
    padding: '0.4em 0.7em',
    lineHeight: '1.1em',
    border: '2px solid #CC007B',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#CC007B',
        borderColor: 'white',
        color: 'white',
        boxShadow: 'none',
    }
});

export const StyledButtonDeconnexion = styled(Button)({
    color: 'white',
    fontFamily: 'Cabin',
    fontSize: '0.5em',
    fontWeight: 600,
    backgroundColor: '#2A375C',
    borderRadius: 50,
    padding: '0.5em 0.3em',
    minWidth: '180px',
    lineHeight: '1.1em',
    border: '2px solid #ffffff',
    boxShadow: 'none',
    position: 'absolute',
    right: '3em',
    '&:hover': {
        backgroundColor: '#ffffff',
        borderColor: '#2A375C',
        color: '#2A375C',
        boxShadow: 'none',
    },
    '@media (max-width: 576px)':{
        position: 'static',
     },
});

export const StyledButtonAjoutUtilisateur = styled(Button)({
    color: 'white',
    fontFamily: 'Cabin',
    fontSize: '1.2em',
    fontWeight: 600,
    backgroundColor: '#CC007B',
    borderRadius: 50,
    padding: '0.2em 0.7em 0.2em 0.2em',
    lineHeight: '1.1em',
    border: '2px solid #CC007B',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#ffffff',
        borderColor: '#CC007B',
        color: '#CC007B',
        boxShadow: 'none',
    }
});