import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../../helper/ErrorHandlerMessageProvider";
import { SecteursActionType } from "../../../constantes/actionType/SecteursActionType";
import secteursService from "../../service/SecteursService";
import { langJsonFormatter } from "../../../helper/ServiceCleaner";

export const getAllSecteurs = createAsyncThunk(
  SecteursActionType.GET_ALL_SECTEURS,
  async (lang, thunkAPI) => {
    try {
      const reponse = await secteursService.getAllSecteurs();
      return langJsonFormatter(lang, thunkAPI, reponse);
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {};

const secteursSlice = createSlice({
  name: SecteursActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllSecteurs.fulfilled, (state, action) => {
        state.secteursList = action.payload;
      })
      .addCase(getAllSecteurs.rejected, (state, action) => {
        state.secteursList = [];
      });
  },
});

const { reducer } = secteursSlice;
export default reducer;
