import { Grid, Stack, Switch, Typography } from "@mui/material";
import {
  StyledDivFormSection,
  StyledDivLabelQuestion,
} from "../../../styledComponents/StyledDiv";
import { StyledStack } from "../../../styledComponents/StyledStack";
import { StyledSwitch } from "../../../styledComponents/StyledSwitch";
import { displayErrorForElementsWithoutErrorHandling } from "../commonMethods/CommonMethods";

/**
 * It takes a field and a formik object and returns a grid item containing a switch
 * @param champ
 * @param formik - the formik object
 * @returns A Grid component with a div, a Stack component, and a Typography component.
 */
const genererChampSwitch = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        {champ?.label ? (
          <StyledDivLabelQuestion className={champ.cssFontFamily}>
            {champ.label}
          </StyledDivLabelQuestion>
        ) : null}
        <StyledStack
          className={champ.cssSwitch}
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography key="falseCase" style={{ textTransform: "uppercase" }}>
            {champ.false}
          </Typography>
          <StyledSwitch
            className={champ.cssSwitch}
            key={champ.nom.description}
            name={champ.nom.description}
            onChange={() => {
              if (formik.values[champ.nom.description] === 0) {
                formik.setFieldValue(champ.nom.description, 1);
              } else {
                formik.setFieldValue(champ.nom.description, 0);
              }
            }}
            checked={!!formik.values[champ.nom.description]}
          />
          <Typography key="trueCase" style={{ textTransform: "uppercase" }}>
            {champ.true}
          </Typography>
        </StyledStack>
        {displayErrorForElementsWithoutErrorHandling(champ, formik)}
      </Grid>
    </StyledDivFormSection>
  );
};

export { genererChampSwitch };
