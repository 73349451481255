import { equalsBooleanOrStringOrNumber, equalsCodeOrLabel } from "../constantes/symbols/HelperSymbolsServices";
import { FORME_CORPS_A, FORME_CORPS_B, FORME_CORPS_C, FORME_CORPS_D, FORME_CORPS_DS, FORME_CORPS_E, FORME_CORPS_ES, FORME_CORPS_F, FORME_CORPS_FS, SECTION_SYMETRIQUE, TYPE_SECTION } from "../constantes/symbols/SymbolsS04";
import { S04_STEP4_arrayTypeCorps } from "../constantes/symbols/SymbolsServicesS04";

export const findForDisplayNormalField = (elementToFind, list) => {
  if (elementToFind === undefined) {
    return undefined;
  }
  return list.find((currentElement) => {
    return (
      currentElement.nom.description === elementToFind ||
      currentElement.nom === elementToFind
    );
  });
};

export const findFormeCorpsFromTypeSection = (payload) => {
  const symetrique = equalsBooleanOrStringOrNumber(
    payload[SECTION_SYMETRIQUE.description]
  );
  switch (true) {
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      0
    ):
      return payload[FORME_CORPS_A.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      1
    ):
      return payload[FORME_CORPS_B.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      2
    ):
      return payload[FORME_CORPS_C.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      3
    ):
      return !!symetrique
        ? payload[FORME_CORPS_DS.description]
        : payload[FORME_CORPS_D.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      4
    ):
      return !!symetrique
        ? payload[FORME_CORPS_ES.description]
        : payload[FORME_CORPS_E.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      5
    ):
      return !!symetrique
        ? payload[FORME_CORPS_FS.description]
        : payload[FORME_CORPS_F.description];
    default:
      return payload[FORME_CORPS_A.description];
  }
};
