export const NCIconCard = () => {
  return (
    <svg
      width="85"
      height="85"
      viewBox="0 0 85 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="non-conformites 1" opacity="0.2">
        <g id="Group">
          <path
            id="Vector"
            d="M74.9515 76.4386H10.0484C6.42144 76.4386 3.17368 74.5638 1.35979 71.4224C-0.453264 68.2817 -0.453264 64.5308 1.35979 61.3901L33.812 5.18218C35.6252 2.04083 38.8728 0.166016 42.5 0.166016C46.1264 0.166016 49.3747 2.04083 51.1879 5.18218L64.7253 28.6292C65.1838 29.423 64.9114 30.4386 64.1177 30.897C63.324 31.3549 62.3083 31.0831 61.8499 30.2894L48.3125 6.84233C47.0998 4.74058 44.9266 3.48633 42.5 3.48633C40.0733 3.48633 37.9002 4.74058 36.6868 6.84233L4.23535 63.0502C3.02194 65.152 3.02194 67.6612 4.23535 69.7623C5.44859 71.864 7.62107 73.1189 10.0484 73.1189H74.9515C77.3782 73.1189 79.5513 71.864 80.7646 69.7621C81.978 67.6612 81.978 65.152 80.7646 63.0502L69.3206 43.229C68.8621 42.4351 69.1345 41.4196 69.9282 40.9612C70.7226 40.5027 71.7375 40.7749 72.196 41.5688L83.6401 61.3901C85.4532 64.5314 85.4532 68.2817 83.6401 71.4224C81.8262 74.5638 78.5785 76.4386 74.9515 76.4386Z"
            fill="#CC007B"
          />
          <path
            id="Vector_2"
            d="M67.0237 37.5904C66.5855 37.5904 66.1586 37.4128 65.848 37.104C65.5379 36.7919 65.3631 36.3703 65.3616 35.9303C65.3616 35.4937 65.5392 35.0658 65.848 34.7566C66.1608 34.4463 66.5831 34.2716 67.0237 34.2701C67.4602 34.2701 67.8869 34.4478 68.1955 34.7566C68.506 35.0684 68.6808 35.4902 68.6819 35.9303C68.6819 36.3667 68.5056 36.7952 68.1955 37.104C67.8842 37.4141 67.4631 37.5889 67.0237 37.5904ZM42.5001 62.4745C39.3879 62.4745 36.8562 59.9428 36.8562 56.8307C36.8562 53.7185 39.3879 51.1868 42.5001 51.1868C45.6122 51.1868 48.1439 53.7185 48.1439 56.8307C48.1439 59.9428 45.6122 62.4745 42.5001 62.4745ZM42.5001 54.5064C41.2186 54.5064 40.1759 55.5494 40.1759 56.8307C40.1759 58.112 41.2186 59.1549 42.5001 59.1549C43.7815 59.1549 44.8243 58.1123 44.8243 56.8307C44.8243 55.549 43.7815 54.5064 42.5001 54.5064ZM42.5001 46.921C39.3879 46.921 36.8562 44.3893 36.8562 41.2765V27.2998C36.8562 24.187 39.3879 21.6553 42.5001 21.6553C45.6122 21.6553 48.1439 24.187 48.1439 27.2998V41.2763C48.1439 44.3891 45.6122 46.9209 42.5001 46.9209V46.921ZM42.5001 24.9758C41.2186 24.9758 40.1759 26.018 40.1759 27.3V41.2765C40.1759 42.5586 41.2186 43.6007 42.5001 43.6007C43.7815 43.6007 44.8243 42.5586 44.8243 41.2765V27.2998C44.8243 26.0178 43.7815 24.9758 42.5001 24.9758Z"
            fill="#CC007B"
          />
        </g>
      </g>
    </svg>
  );
};
