export const formModifierSonProfil = () =>
  Object.freeze([
    {
      ref: "nom",
      nom: "Nom",
      type: "text",
    },
    {
      ref: "motDePasse",
      nom: "Nouveau mot de passe",
      type: "password",
    },
    {
      ref: "prenom",
      nom: "Prénom",
      type: "text",
    },
    {
      ref: "confirmerMotDePasse",
      nom: "Confirmer le nouveau mot de passe",
      type: "password",
    },
    {
      ref: "email",
      nom: "Adresse mail",
      type: "text",
    },
  ]);
