import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../../../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../../../../../constantes/symbols/SymbolsServicesCommon";
import { ArticleS01 } from "./ArticleS01";
import { ArticleS04 } from "./S04/ArticleS04";
import { ArticleS05 } from "./S05/ArticleS05";
import { ArticleS06 } from "./S06/ArticleS06";
import { ArticleS08 } from "./S08/ArticleS08";

export const controllerDisplayResumeArticleEC = (state, action, isFrom) => {
  switch (state.formik[FAMILLE_PROTECTION_INDUSTRIELLE.description]) {
    case arrayProductFamilly()[2].code:
      return renderS04(state, action, isFrom);
    case arrayProductFamilly()[4].code:
      return renderS05(state, action, isFrom);
    case arrayProductFamilly()[5].code:
      return renderS08(state, action, isFrom);
    case arrayProductFamilly()[3].code:
      return renderS06(state, action, isFrom);
    default:
      return renderS01(state, action, isFrom);
  }
};

const renderS01 = (state, action, isFrom) => {
  return <ArticleS01 state={state} action={action} isFrom={isFrom} />;
};

const renderS04 = (state, action, isFrom) => {
  return <ArticleS04 state={state} action={action} isFrom={isFrom} />;
};

const renderS05 = (state, action, isFrom) => {
  return <ArticleS05 state={state} action={action} isFrom={isFrom} />;
};

const renderS06 = (state, action, isFrom) => {
  return <ArticleS06 state={state} action={action} isFrom={isFrom} />;
};

const renderS08 = (state, action, isFrom) => {
  return <ArticleS08 state={state} action={action} isFrom={isFrom} />;
};

