import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { StyledH1Orange } from "./styledComponents/StyledTextBackoffice";
import {
  StyledDivBackoffice,
  StyledDivMsgEchec,
  StyledDivMsgSucces,
} from "./styledComponents/StyledDivBackoffice";
import { ListeDdp } from "./ListeDdp";
import { getAllAgressions } from "../model/slice/AgressionsReducer";
import {
  getAllMarqueMachine,
  getAllTypeMachine,
} from "../model/slice/MachinesReducer";
import {
  deleteDDPByRef,
  getAllInfoByRef,
  getAllReference,
} from "../model/slice/FormulaireReducer";
import { setMessage } from "../model/slice/MessageReducer";
import { ModalBackoffice } from "./components/ModalBackoffice";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { getAllSecteurs } from "../model/slice/SecteurReducer";
import { getAllFonctions } from "../model/slice/FonctionReducer";
import { getAllPays } from "../model/slice/PaysReducer";
import { controllerResumeDisplay } from "./backoffice/ControllerResumeDisplay";
import { DEMANDE_DE_PRIX } from "../../constantes/symbols/SymbolsResumeDisplayedFrom";

export const DemandeDePrix = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const [reference, setReference] = useState(null);
  const [open, setOpen] = useState(false);
  const [isDeleteApproved, setIsDeleteApproved] = useState(false);
  const [referenceToDelete, setReferenceToDelete] = useState();
  const { message } = useSelector((state) => state.message);

  //SERVICES
  const [upToDateAgressionsList, setUpToDateAgressionsList] = useState([]);
  const [upToDateSecteursList, setUpToDateSecteursList] = useState([]);
  const [upToDateFonctionsList, setUpToDateFonctionsList] = useState([]);
  const [upToDatePaysList, setUpToDatePaysList] = useState([]);
  const [upToDateTypeMachineList, setUpToDateTypeMachineList] = useState([]);
  const [upToDateAgressionsWithImgList, setUpToDateAgressionsWithImgList] =
    useState([]);
  const [upToDateMarqueMachineList, setUpToDateMarqueMachineList] = useState(
    []
  );

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);
  const { agressionsList } = useSelector((state) => state.agressions);
  const { formikList } = useSelector((state) => state.formulaire);
  const { isFormularySent } = useSelector((state) => state.formulaire);
  const { typeMachineList, marqueMachineList } = useSelector(
    (state) => state.machines
  );

  const [
    styles,
    t,
    setUpToDateReferenceByIdList,
    upToDateReferenceByIdList,
    setSelectedPage,
  ] = useOutletContext();

  

  useEffect(() => {
    const ddp = params.get(DEMANDE_DE_PRIX.description);
    if (!ddp) {
      return;
    }
    const obj = {
      reference: ddp,
    };
    dispatch(getAllInfoByRef(obj));
  }, [dispatch, params]);

  useEffect(() => {
    if (!formikList) {
      return;
    }
    setUpToDateReferenceByIdList(formikList);
  }, [formikList]);

  useEffect(() => {
    setUpToDateReferenceByIdList(upToDateReferenceByIdList);
  }, [upToDateReferenceByIdList, setUpToDateReferenceByIdList]);

  useEffect(() => {
    setSelectedPage(1);
  }, []);

  useEffect(() => {
    dispatch(getAllAgressions());
    dispatch(getAllTypeMachine());
    dispatch(getAllMarqueMachine());
    dispatch(getAllSecteurs());
    dispatch(getAllFonctions());
    dispatch(getAllPays());
  }, [dispatch]);

  useEffect(() => {
    if (!agressionsList) {
      return;
    }
    setUpToDateAgressionsList(agressionsList);
  }, [agressionsList]);

  useEffect(() => {
    if (!typeMachineList) {
      return;
    }
    setUpToDateTypeMachineList(typeMachineList);
  }, [typeMachineList]);

  useEffect(() => {
    if (!marqueMachineList) {
      return;
    }
    setUpToDateMarqueMachineList(marqueMachineList);
  }, [marqueMachineList]);

  useEffect(() => {
    if (!secteursList) {
      return;
    }
    setUpToDateSecteursList(secteursList);
  }, [secteursList]);

  useEffect(() => {
    if (!fonctionsList) {
      return;
    }
    setUpToDateFonctionsList(fonctionsList);
  }, [fonctionsList]);

  useEffect(() => {
    if (!paysList) {
      return;
    }
    setUpToDatePaysList(paysList);
  }, [paysList]);

  useEffect(() => {
    if (!isDeleteApproved || !referenceToDelete) {
      return;
    }
    dispatch(deleteDDPByRef(referenceToDelete))
      .unwrap()
      .then((e) => {
        if (e.code !== 400 && e.code !== 500) {
          dispatch(getAllReference());
          dispatch(setMessage(t.confirmDeletedDDP));
        }
      })
      .catch((e) => {
        dispatch(setMessage(t.errorBdd));
      });
    handleDeleteUnapproved();
    setReferenceToDelete(undefined);
  }, [isDeleteApproved, dispatch, referenceToDelete]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteApproved = () => {
    setIsDeleteApproved(true);
  };

  const handleDeleteUnapproved = () => {
    setIsDeleteApproved(false);
  };

  const handleYesModale = () => {
    handleDeleteApproved();
    handleClose();
  };

  const handleNoModale = () => {
    handleDeleteUnapproved();
    handleClose();
  };

  const resetMessage = () => {
    dispatch(setMessage(null));
  };

  const stateUsedForRender = {
    booleanIdentite: false,
    formik: upToDateReferenceByIdList,
    styles,
    t,
    isFormularySent,
    upToDateAgressionsList,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateFonctionsList,
    upToDateSecteursList,
    upToDatePaysList,
  };

  const actionUsedForRender = {
    dispatch,
    setReference,
    setUpToDateReferenceByIdList,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  return upToDateReferenceByIdList === undefined ? (
    <Paper onClick={() => resetMessage()}>
      {displayError()}
      <StyledDivBackoffice>
        <ModalBackoffice
          open={open}
          handleClose={handleClose}
          message={t.confirmDeleteDDP}
          handleNoModale={handleNoModale}
          non={t.non}
          handleYesModale={handleYesModale}
          oui={t.oui}
        />
        <StyledH1Orange> Liste des demandes de prix</StyledH1Orange>
        <ListeDdp
          state={stateUsedForRender}
          actions={{
            handleOpen,
            setReferenceToDelete,
          }}
        />
      </StyledDivBackoffice>
    </Paper>
  ) : upToDateAgressionsList.length !== 0 ? (
    <div>
      {controllerResumeDisplay(
        stateUsedForRender,
        actionUsedForRender,
        DEMANDE_DE_PRIX
      )}
    </div>
  ) : null;
};
