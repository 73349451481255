import { TEXTFIELD_SELECT_BACKOFFICE } from "../../redux/vue/components/generateFormulary/types/TypeFieldsFormulary";

export const formAjouterUtilisateur = () =>
  Object.freeze([
    {
      ref: "nom",
      nom: "Nom",
      type: "text",
    },
    {
      ref: "prenom",
      nom: "Prénom",
      type: "text",
    },
    {
      ref: "email",
      nom: "Adresse mail",
      type: "text",
    },
    {
      ref: "motDePasse",
      nom: "Mot de passe",
      type: "password",
    },
  ]);
const ROLE = Symbol("role");
export const formulaireRole = (roleList) => [
  {
    nom: ROLE,
    label: "Role",
    type: TEXTFIELD_SELECT_BACKOFFICE,
    donnees: roleList,
  },
];
