import { MenuItem } from "@mui/material";
import { styled } from "@mui/system";

export const StyledMenuItemLegend = styled(MenuItem)({
    fontSize: '1em',
    color: '#2A375C',
    lineHeight: '1.2',
    '&.Mui-focused': {
        color: '#2A375C',
    }
});