import { styled } from "@mui/system";

export const StyledH1VuesEC = styled("h1")({
  fontFamily: "Dosis",
  color: "#1C243C",
  fontSize: "2.25rem",
  fontWeight: "600",
  lineHeight: "100%",
  textTransform: "uppercase",
  marginBottom: "1rem",
});

export const StyledH2VuesEC = styled("h1")({
  fontFamily: "Dosis",
  color: "#1C243C",
  fontSize: "1.5rem",
  fontWeight: "600",
  lineHeight: "120%",
});

export const StyledP = styled("p")({
  fontFamily: "Cabin",
  color: "#2A375C",
  fontSize: "1.25rem",
  fontWeight: "500",
  lineHeight: "150%",
});

export const StyledDivTitleCard = styled("div")({
  color: "#1C243C",
  fontSize: 24,
  fontFamily: "Dosis",
  fontWeight: "600",
  marginBottom: "1.25rem",
  lineHeight: "120%",
});

export const StyledDivTitleCardReverse = styled("div")({
  color: "white",
  fontSize: 24,
  fontFamily: "Dosis",
  fontWeight: "600",
  marginBottom: "1.25rem",
});

export const StyledTitleActus = styled("div")({
  color: "white",
  fontSize: 24,
  fontFamily: "Cabin",
  fontWeight: "700",
});

export const StyledContentActus = styled("div")({
  color: "white",
  fontSize: 20,
  fontFamily: "Cabin",
  fontWeight: "500",
  lineHeight: "150%",
});

export const StyledContentCard = styled("div")({
  color: "#2A375C",
  fontSize: 16,
  fontFamily: "Cabin",
  fontWeight: "400",
  marginTop: "0.25rem",
  lineHeight: "150%",
});

export const StyledDivBoutonCialProximite = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

export const StyledDivTitleSubCard = styled("div")({
  color: "#1C243C",
  fontFamily: "Cabin",
  fontSize: "1rem",
  fontWeight: 700,
});

export const StyledDivTitleSubCardReverse = styled("div")({
  color: "white",
  fontFamily: "Cabin",
  fontSize: "1rem",
  fontWeight: 700,
});

export const StyledContentSubCardReverse = styled("div")({
  color: "white",
  fontSize: 16,
  fontFamily: "Cabin",
  fontWeight: "400",
  marginTop: "0.25rem",
});

export const StyledTitleTab = styled("div")({
  color: "#1C243C",
  fontFamily: "Dosis",
  fontSize: "1.25rem",
  fontWeight: "600",
  lineHeight: "120%",
  textTransform: "none",
});

export const StyledTitleVueDetailsEC = styled("div")({
  padding: "0.3em",
  fontFamily: "Cabin",
  fontSize: "1.25rem",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "120%",
  display: "flex",
  alignItems: "center",
});

export const StyledContentVueDetailsEC = styled("span")({
  fontFamily: "Cabin",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "120%",
});

export const StyledLabel = styled("span")({
  fontFamily: "Cabin",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "120%",
});

export const StyledLabelNormal = styled("span")({
  fontFamily: "Cabin",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "120%",
});
