import { Paper } from "@mui/material";
import { equalsCodeOrLabel } from "../../../../constantes/symbols/HelperSymbolsServices";
import {
  COTE_AGRESSE_TOILE,
  DIMENSIONS_EXTREMITE,
  FIXATION_CAISSON,
  LARGEUR_TOILE,
  L_MAX,
  POSITION_EXTREMITE,
  POSITION_FIXATION_CAISSON,
  SECTION_MAX_A,
  SECTION_MAX_B,
  SECTION_MAX_C,
  TYPE_ENROULEUR,
} from "../../../../constantes/symbols/SymbolsS05";
import {
  S05_STEP4_arrayTypeEnrouleur,
  S05_STEP6_arrayCoteAgresseToile,
  S05_STEP6_arrayDimensionExtremiteIt1,
  S05_STEP6_arrayDimensionExtremiteIt2,
  S05_STEP6_arrayPositionExtremite,
  S05_STEP6_arrayPositionFixationCaisson,
  S05_STEP6_arraySystemeFixationCaisson,
} from "../../../../constantes/symbols/SymbolsServicesS05";
import {
  checkFocusedArrow,
  checkFocusedEndMarker,
  checkFocusedStartMarker,
  checkFocusedText,
} from "../../../../helper/ConditionnalCssProvider";
import { StyledCircle } from "../../styledComponents/StyledCircle";
import {
  StyledPath1,
  StyledPath3,
  StyledPathCote,
  StyledPathDotted,
  StyledPathFleche,
} from "../../styledComponents/StyledPath";
import {
  StyledRect,
  StyledRect4,
  StyledRect5,
} from "../../styledComponents/StyledRect";

export const Enrouleur = ({ styles, formik, formulaire, t }) => {
  const longueurDeployee = formik[L_MAX.description] || "";
  const largeurToile = formik[LARGEUR_TOILE.description] || "";
  const sectionMaxA = formik[SECTION_MAX_A.description] || "";
  const sectionMaxB = formik[SECTION_MAX_B.description] || "";
  // const sectionMaxC = formik[SECTION_MAX_C.description] || ""

  const afficherEnrouleurCaisson = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        <StyledRect x="50" y="135" width="20" height="15" />
        <StyledRect x="50" y="50" width="20" height="15" />

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />

        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {/* {formik[FIXATION_CAISSON.description] == ""
          ? afficherSectionMax(160, 158, 47, 44)
          : null} */}
      </>
    );
  };

  const afficherEnrouleurSansCaisson = () => {
    return (
      <>
        {/* Vue du dessus */}
        <StyledRect x="55" y="70" width="15" height="70" />

        <StyledRect x="70" y="72.5" width="105" height="65" />

        <StyledRect4 x="175" y="72.5" width="5" height="65" className={styles.rectFill}/>

        <StyledRect x="60" y="60" width="5" height="10" />
        <StyledRect x="60" y="140" width="5" height="10" />

        {/* vue profil */}
        <StyledCircle cx="70" cy="172" r="8" />
        <StyledCircle cx="70" cy="172" r="3" />

        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 180,72.5 L 185,72.5" />
        <StyledPathCote d="M 180,137.5 L 185,137.5" />
        <StyledPathFleche
          d="M 185,78 L 185,132"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="187"
          y="107"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>
      </>
    );
  };

  const afficherEnrouleurAvecSupport = () => {
    return (
      <>
        {/* Vue du dessus */}
        <StyledRect x="55" y="65" width="10" height="70" />
        <StyledRect x="65" y="67.5" width="105" height="65" />
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/> 
        {/* //TEST */}

        <StyledRect x="50" y="135" width="20" height="5" />
        <StyledRect5 x="50" y="140" width="2" height="12" className={styles.couleurCharteRect}/>

        <StyledRect x="50" y="60" width="20" height="5" />
        <StyledRect5 x="50" y="48" width="2" height="12" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />

        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledRect5 x="50" y="162" width="2" height="20" className={styles.couleurCharteRect}/>
        <StyledCircle cx="60" cy="172" r="3" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 175,67.5 L 180,67.5" />
        <StyledPathCote d="M 175,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,73 L 180,127"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="102"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>
      </>
    );
  };

  const afficherCoteAgresse = () => {
    if (
      equalsCodeOrLabel(
        formik[COTE_AGRESSE_TOILE.description],
        S05_STEP6_arrayCoteAgresseToile(),
        0
      )
    ) {
      return (
        <>
          <StyledPathFleche
            d="M 110,182 L 120,187"
            markerStart={checkFocusedStartMarker(formik, [COTE_AGRESSE_TOILE])}
            className={checkFocusedArrow(formik, [COTE_AGRESSE_TOILE], styles)}
          />
          <text
            fontSize="0.3em"
            x="150"
            y="188"
            direction="rtl"
            className={checkFocusedText(formik, [COTE_AGRESSE_TOILE], styles)}
          >
            {t?.titreLegendeCoteAgresse}
          </text>
        </>
      );
    } else {
      if (
        equalsCodeOrLabel(
          formik[COTE_AGRESSE_TOILE.description],
          S05_STEP6_arrayCoteAgresseToile(),
          1
        )
      ) {
        return (
          <>
            <StyledPathFleche
              d="M 110,176 L 120,170"
              markerStart={checkFocusedStartMarker(formik, [
                COTE_AGRESSE_TOILE,
              ])}
              className={checkFocusedArrow(
                formik,
                [COTE_AGRESSE_TOILE],
                styles
              )}
            />
            <text
              fontSize="0.3em"
              x="150"
              y="172"
              direction="rtl"
              className={checkFocusedText(formik, [COTE_AGRESSE_TOILE], styles)}
            >
              {t?.titreLegendeCoteAgresse}
            </text>
          </>
        );
      }
    }
  };

  const afficherCoteAgresseFix2etFace4 = () => {
    if (
      equalsCodeOrLabel(
        formik[COTE_AGRESSE_TOILE.description],
        S05_STEP6_arrayCoteAgresseToile(),
        0
      )
    ) {
      return (
        <>
          <StyledPathFleche
            d="M 110,162 L 120,167"
            markerStart={checkFocusedStartMarker(formik, [COTE_AGRESSE_TOILE])}
            className={checkFocusedArrow(formik, [COTE_AGRESSE_TOILE], styles)}
          />
          <text
            fontSize="0.3em"
            x="150"
            y="168"
            direction="rtl"
            className={checkFocusedText(formik, [COTE_AGRESSE_TOILE], styles)}
          >
            {t?.titreLegendeCoteAgresse}
          </text>
        </>
      );
    } else {
      if (
        equalsCodeOrLabel(
          formik[COTE_AGRESSE_TOILE.description],
          S05_STEP6_arrayCoteAgresseToile(),
          1
        )
      ) {
        return (
          <>
            <StyledPathFleche
              d="M 110,156 L 120,150"
              markerStart={checkFocusedStartMarker(formik, [
                COTE_AGRESSE_TOILE,
              ])}
              className={checkFocusedArrow(
                formik,
                [COTE_AGRESSE_TOILE],
                styles
              )}
            />
            <text
              fontSize="0.3em"
              x="150"
              y="152"
              direction="rtl"
              className={checkFocusedText(formik, [COTE_AGRESSE_TOILE], styles)}
            >
              {t?.titreLegendeCoteAgresse}
            </text>
          </>
        );
      }
    }
  };

  const afficherRacleur = (
    x1Cote1,
    x2Cote1,
    x3Cote1,
    x4Cote1,
    x1Cote2,
    x2Cote2
  ) => { 
    if (
      equalsCodeOrLabel(
        formik[COTE_AGRESSE_TOILE.description],
        S05_STEP6_arrayCoteAgresseToile(),
        0
      )
    ) { 
      return (
        <> 
          <StyledPath1 d={"M " + x1Cote1 + ",183 L " + x2Cote1 + ",180 "} />
          <StyledPath1 d={"M " + x3Cote1 + ",183 L " + x4Cote1 + ",183 "} />
          {/* <StyledPath1 d="M 70,183 L 75,180 " />
          <StyledPath1 d="M 67,183 L 70,183 " /> */}
        </>
      );
    }
    if (
      equalsCodeOrLabel(
        formik[COTE_AGRESSE_TOILE.description],
        S05_STEP6_arrayCoteAgresseToile(),
        1
      )
    ) {
      return (
        <>
          <StyledPath1 d={"M " + x1Cote2 + ",175 L " + x2Cote2 + ",180 "} />
        </>
      );
    }
  };

  const afficherRacleurFixation2etFace4 = () => {
    if (
      equalsCodeOrLabel(
        formik[COTE_AGRESSE_TOILE.description],
        S05_STEP6_arrayCoteAgresseToile(),
        0
      )
    ) {
      return (
        <>
          <StyledPath1 d={"M 70,163 L 75,160 "} />
          <StyledPath1 d={"M 67,163 L 70,163 "} />
          {/* <StyledPath1 d="M 70,183 L 75,180 " />
          <StyledPath1 d="M 67,183 L 70,183 " /> */}
        </>
      );
    }
    if (
      equalsCodeOrLabel(
        formik[COTE_AGRESSE_TOILE.description],
        S05_STEP6_arrayCoteAgresseToile(),
        1
      )
    ) {
      return (
        <>
          <StyledPath1 d={"M 70,155 L 75,160 "} />
        </>
      );
    }
  };

  const afficherSectionMax = (hauteurA, hauteurA2, longueurB, longueurB2) => {
    if (
      equalsCodeOrLabel(
        formik[TYPE_ENROULEUR.description],
        S05_STEP4_arrayTypeEnrouleur(),
        0
      )
    ) {
      return (
        <>
          <StyledPathFleche
            d={"M 56," + hauteurA + " L 65," + hauteurA + ""}
            markerStart={checkFocusedStartMarker(formik, [SECTION_MAX_A])}
            markerEnd={checkFocusedEndMarker(formik, [SECTION_MAX_A])}
            className={checkFocusedArrow(formik, [SECTION_MAX_A], styles)}
          />
          {sectionMaxA === "" ? (
            <text
              fontSize="0.4em"
              x="60"
              y={hauteurA2}
              textAnchor="middle"
              className={checkFocusedText(formik, [SECTION_MAX_A], styles)}
            >
              A
            </text>
          ) : (
            <>
              <text
                fontSize="0.4em"
                x="59"
                y={hauteurA2}
                textAnchor="middle"
                className={checkFocusedText(formik, [SECTION_MAX_A], styles)}
              >
                {sectionMaxA}
              </text>
            </>
          )}
             
          <StyledPathFleche
            d={"M " + longueurB + ",168 L " + longueurB + ",176"}
            markerStart={checkFocusedStartMarker(formik, [SECTION_MAX_B])}
            markerEnd={checkFocusedEndMarker(formik, [SECTION_MAX_B])}
            className={checkFocusedArrow(formik, [SECTION_MAX_B], styles)}
          />
          {sectionMaxB === "" ? (
            <text
              fontSize="0.4em"
              x={longueurB2}
              y="174"
              textAnchor="middle"
              className={checkFocusedText(formik, [SECTION_MAX_B], styles)}
            >
              B
            </text>
          ) : (
            <>
              <text
                fontSize="0.4em"
                x={longueurB2}
                y="174"
                direction="rtl"
                className={checkFocusedText(formik, [SECTION_MAX_B], styles)}
              >
                {sectionMaxB}
              </text>
            </>
          )}

          {/* <StyledPathFleche
            d="M 47,71 L 47,129"
            markerStart={checkFocusedStartMarker(formik, [SECTION_MAX_C])}
            markerEnd={checkFocusedEndMarker(formik, [SECTION_MAX_C])}
            className={checkFocusedArrow(formik, [SECTION_MAX_C], styles)}
          />
          {sectionMaxC === "" ? (
            <text
              fontSize="0.4em"
              x="42"
              y="102"
              textAnchor="middle"
              className={checkFocusedText(formik, [SECTION_MAX_C], styles)}
            >
              C
            </text>
          ) : (
            <>
              <text
                fontSize="0.4em"
                x="44"
                y="102"
                direction="rtl"
                className={checkFocusedText(formik, [SECTION_MAX_C], styles)}
              >
                {sectionMaxC}
              </text>
            </>
          )} */}
        </>
      );
    }
  };

  const afficherSectionMaxFixation2etFace4 = () => {
    if (
      equalsCodeOrLabel(
        formik[TYPE_ENROULEUR.description],
        S05_STEP4_arrayTypeEnrouleur(),
        0
      )
    ) {
      return (
        <>
          <StyledPathFleche
            d={"M 56,139 L 65,139"}
            markerStart={checkFocusedStartMarker(formik, [SECTION_MAX_A])}
            markerEnd={checkFocusedEndMarker(formik, [SECTION_MAX_A])}
            className={checkFocusedArrow(formik, [SECTION_MAX_A], styles)}
          />
          {sectionMaxA === "" ? (
            <text
              fontSize="0.4em"
              x="60"
              y="137"
              textAnchor="middle"
              className={checkFocusedText(formik, [SECTION_MAX_A], styles)}
            >
              A
            </text>
          ) : (
            <>
              <text
                fontSize="0.4em"
                x="59"
                y="137"
                direction="ltr"
                className={checkFocusedText(formik, [SECTION_MAX_A], styles)}
              >
                {sectionMaxA}
              </text>
            </>
          )}

          <StyledPathFleche
            d={"M 47,148 L 47,156"}
            markerStart={checkFocusedStartMarker(formik, [SECTION_MAX_B])}
            markerEnd={checkFocusedEndMarker(formik, [SECTION_MAX_B])}
            className={checkFocusedArrow(formik, [SECTION_MAX_B], styles)}
          />
          {sectionMaxB === "" ? (
            <text
              fontSize="0.4em"
              x="44"
              y="154"
              textAnchor="middle"
              className={checkFocusedText(formik, [SECTION_MAX_B], styles)}
            >
              B
            </text>
          ) : (
            <>
              <text
                fontSize="0.4em"
                x="44"
                y="154"
                direction="rtl"
                className={checkFocusedText(formik, [SECTION_MAX_B], styles)}
              >
                {sectionMaxB}
              </text>
            </>
          )}
        </>
      );
    }
  };

  const afficherPositionExtremite = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        0
      ):
        return (
          <StyledPath3 d="M 170,180 170,182 L 180,182 L 180,180 L 170,180" className={styles.rectFill}/>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        1
      ):
        return (
          <StyledPath3 d="M 170,180 170,178 L 180,178 L 180,180 L 170,180" className={styles.rectFill}/>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        2
      ):
        return (
          <>
            <StyledPath3 d="M 170,180 170,182 L 180,182 L 180,180 L 170,180" className={styles.rectFill}/>
            <StyledPath3 d="M 170,180 170,178 L 180,178 L 180,180 L 170,180" className={styles.rectFill}/>
          </>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        3
      ):
        return (
          <>
            <StyledPath3 d="M 170,180 L 180,180 L 180,189 L 178,189 L 178,182 L 170,182 L 170,180" className={styles.rectFill}/>
          </>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        4
      ):
        return (
          <>
            <StyledPath3 d="M 170,180 L 180,180 L 180,171 L 178,171 L 178,178 L 170,178 L 170,180" className={styles.rectFill}/>
          </>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        5
      ):
        return (
          <>
            <StyledPath3 d="M 170,180 170,178 L 180,178 L 180,180 L 170,180" className={styles.rectFill}/>
            <StyledPath3 d="M 170,180 L 180,180 L 180,189 L 178,189 L 178,182 L 170,182 L 170,180" className={styles.rectFill}/>
          </>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        6
      ):
        return (
          <>
            <StyledPath3 d="M 17,180 L 180,180 L 180,171 L 178,171 L 178,178 L 170,178 L 170,180" className={styles.rectFill}/>
            <StyledPath3 d="M 170,180 170,182 L 180,182 L 180,180 L 170,180" className={styles.rectFill}/>
            
            
            
          
          </>
        );
      default:
        return (
          <StyledPath3 d="M 170,180 170,178 L 180,178 L 180,180 L 170,180" className={styles.rectFill}/>
        );
    }
  };

  const afficherPositionExtremiteFixation2EtFace4 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        0
      ):
        return (
          <StyledPath3 d="M 170,160 170,162 L 180,162 L 180,160 L 170,160" className={styles.rectFill}/>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        1
      ):
        return (
          <StyledPath3 d="M 170,160 170,158 L 180,158 L 180,160 L 170,160" className={styles.rectFill}/>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        2
      ):
        return (
          <>
            <StyledPath3 d="M 170,160 170,162 L 180,162 L 180,160 L 170,160" className={styles.rectFill}/>
            <StyledPath3 d="M 170,160 170,158 L 180,158 L 180,160 L 170,160" className={styles.rectFill}/>
          </>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        3
      ):
        return (
          <>
            <StyledPath3 d="M 170,160 L 180,160 L 180,169 L 178,169 L 178,162 L 170,162 L 170,160" className={styles.rectFill}/>
          </>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        4
      ):
        return (
          <>
            <StyledPath3 d="M 170,160 L 180,160 L 180,151 L 178,151 L 178,158 L 170,158 L 170,160" className={styles.rectFill}/>
          </>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        5
      ):
        return (
          <>
            <StyledPath3 d="M 170,160 170,158 L 180,158 L 180,160 L 170,160" className={styles.rectFill}/>
            <StyledPath3 d="M 170,160 L 180,160 L 180,169 L 178,169 L 178,162 L 170,162 L 170,160" className={styles.rectFill}/>
          </>
        );
      case equalsCodeOrLabel(
        formik[POSITION_EXTREMITE.description],
        S05_STEP6_arrayPositionExtremite(),
        6
      ):
        return (
          <>
            <StyledPath3 d="M 170,160 L 180,160 L 180,151 L 178,151 L 178,158 L 170,158 L 170,160" className={styles.rectFill}/>
            <StyledPath3 d="M 170,160 L 180,160 L 180,169 L 178,169 L 178,162 L 170,162 L 170,160" className={styles.rectFill}/>
          </>
        );
      default:
        return (
          <StyledPath3 d="M 170,160 170,158 L 180,158 L 180,160 L 170,160" className={styles.rectFill}/>
        );
    }
  };

  const afficherDimensionExtremite = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt1(),
        0
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="183" direction="ltr">
              15X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt1(),
        1
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="183" direction="ltr">
              20X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt1(),
        2
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="183" direction="ltr">
              30X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt2(),
        0
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="183" direction="ltr">
              15X15X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt2(),
        1
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="183" direction="ltr">
              20X20X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt2(),
        2
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="183" direction="ltr">
              30X30X3
            </text>
          </>
        );
      default:
        break;
    }
  };

  const afficherDimensionExtremiteFix2etFace4 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt1(),
        0
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="163" direction="ltr">
              15X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt1(),
        1
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="163" direction="ltr">
              20X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt1(),
        2
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="163" direction="ltr">
              30X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt2(),
        0
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="163" direction="ltr">
              15X15X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt2(),
        1
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="163" direction="ltr">
              20X20X3
            </text>
          </>
        );
      case equalsCodeOrLabel(
        formik[DIMENSIONS_EXTREMITE.description],
        S05_STEP6_arrayDimensionExtremiteIt2(),
        2
      ):
        return (
          <>
            <text fontSize="0.3em" x="182" y="163" direction="ltr">
              30X30X3
            </text>
          </>
        );
      default:
        break;
    }
  };

  const enrouleurFixation1EtFace1 = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        <StyledRect5 x="68" y="135" width="2" height="15" className={styles.couleurCharteRect}/>
        <StyledRect5 x="68" y="50" width="2" height="15" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="68" y="162" width="2" height="20" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}
        {afficherSectionMax(160, 158, 47, 44)}
      </>
    );
  };

  const enrouleurFixation1EtFace2 = () => {
    return (
      <>
        {/* Vue du dessus */}
        <StyledRect5 x="50" y="127.5" width="20" height="15" className={styles.couleurCharteRect}/>
        <StyledRect5 x="50" y="42.5" width="20" height="15" className={styles.couleurCharteRect}/>

        <StyledRect x="50" y="57.5" width="20" height="70" />
        <StyledRect x="70" y="60" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="60" width="5" height="65" className={styles.rectFill}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="50" y="162" width="20" height="2" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 75,58 L 169,58"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="56"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,60.5 L 180,60.5" />
        <StyledPathCote d="M 176,125.5 L 180,125.5" />
        <StyledPathFleche
          d="M 180,67 L 180,120"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="94"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}
        {afficherSectionMax(159, 156, 47, 44)}
      </>
    );
  };

  const enrouleurFixation1EtFace3 = () => {
    return (
      <>
        {/* Vue du dessus */}  

        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        <StyledRect5 x="50" y="135" width="2" height="15" className={styles.couleurCharteRect}/>
        <StyledRect5 x="50" y="50" width="2" height="15" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="50" y="162" width="2" height="20" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>
        {afficherRacleur(70, 75, 67, 70, 70, 75)} 
        {afficherSectionMax(160, 158, 47, 44)}    
      </>
    );
  };

  const enrouleurFixation1EtFace4 = () => {
    return (
      <>
        {/* Vue du dessus */}
        <StyledRect5 x="50" y="127.5" width="20" height="15" className={styles.couleurCharteRect}/>
        <StyledRect5 x="50" y="42.5" width="20" height="15" className={styles.couleurCharteRect}/>

        <StyledRect x="50" y="57.5" width="20" height="70" />
        <StyledRect x="70" y="60" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="60" width="5" height="65" className={styles.rectFill}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="50" y="180" width="20" height="2" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 75,57 L 169,57"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="54"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,60.5 L 180,60.5" />
        <StyledPathCote d="M 176,125.5 L 180,125.5" />
        <StyledPathFleche
          d="M 180,67 L 180,120"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="94"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}
        {afficherSectionMax(159, 156, 47, 44)}
      </>
    );
  };

  const enrouleurFixation2EtFace1 = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        <StyledRect5 x="50" y="135" width="40" height="3" className={styles.couleurCharteRect} />
        <StyledRect5 x="50" y="62" width="40" height="3" className={styles.couleurCharteRect}/>

        

        <StyledPathCote d="M 70,57 L 70,59" />
        <StyledPathCote d="M 175,57 L 175,66" />
        <StyledPathFleche
          d="M 77,57 L 169,57"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="54"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}
        {afficherSectionMax(160, 158, 47, 44)}

        {/* Vue profil */}
        <StyledPath1 d="M 50,180 L 170,180" />
        <StyledRect5 x="70" y="162" width="20" height="20" className={styles.couleurCharteRect} />
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        
      </>
    );
  };

  const enrouleurFixation2EtFace2 = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="57.5" width="20" height="70" />
        <StyledRect x="70" y="60" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="60" width="5" height="65" className={styles.rectFill}/>

        <StyledRect5 x="50" y="127.5" width="20" height="3" className={styles.couleurCharteRect}/>
        <StyledRect5 x="50" y="54.5" width="20" height="3" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledRect5 x="50" y="147" width="20" height="15" className={styles.couleurCharteRect}/>

        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />

        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,56 L 169,56"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="54"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,60.5 L 180,60.5" />
        <StyledPathCote d="M 176,125.5 L 180,125.5" />
        <StyledPathFleche
          d="M 180,67 L 180,120"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="94"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}
        {afficherSectionMax(144, 142, 47, 44)}
      </>
    );
  };

  const enrouleurFixation2EtFace3 = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        <StyledRect5 x="30" y="135" width="40" height="3" className={styles.couleurCharteRect}/>
        <StyledRect5 x="30" y="62" width="40" height="3" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledRect5 x="30" y="162" width="20" height="20" className={styles.couleurCharteRect}/>

        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />

        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}
        {afficherSectionMax(160, 158, 27, 24)}
      </>
    );
  };

  const enrouleurFixation2EtFace4 = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="52.5" width="20" height="70" />
        <StyledRect x="70" y="55" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="55" width="5" height="65" className={styles.rectFill}/>

        <StyledRect5 x="50" y="122.5" width="20" height="3" className={styles.couleurCharteRect}/>
        <StyledRect5 x="50" y="49.5" width="20" height="3" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledRect5 x="50" y="162" width="20" height="20" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 50,162 L 70,162 L 70,142 L 50,142 L 50,162" />

        <StyledPath1 d="M 70,160 L 170,160" />

        <StyledPathFleche
          d="M 75,52 L 169,52"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="50"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,55.5 L 180,55.5" />
        <StyledPathCote d="M 176,120.5 L 180,120.5" />
        <StyledPathFleche
          d="M 180,62 L 180,115"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="89"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleurFixation2etFace4()}
        {afficherSectionMaxFixation2etFace4()}
      </>
    );
  };

  const enrouleurFixation3EtFace1 = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>
        <StyledRect4></StyledRect4>

        <StyledRect5 x="67" y="65" width="3" height="70" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="68" y="155" width="2" height="7" className={styles.couleurCharteRect}/>
        <StyledRect5 x="68" y="182" width="2" height="7" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}

        {/* délimiteur coté section max A */}
        <StyledPathCote d="M 50,152 L 50,160" />
        <StyledPathCote d="M 70,152 L 70,154" />

        {afficherSectionMax(152, 150, 47, 44)}
      </>
    );
  };

  const enrouleurFixation3EtFace2 = () => {
    return (
      <>
        {/* Vue du dessus */}
        <StyledRect5 x="40" y="57.5" width="10" height="70" className={styles.couleurCharteRect}/>
        <StyledRect x="70" y="60" width="100" height="65" />
        <StyledRect5 x="70" y="57.5" width="10" height="70" className={styles.couleurCharteRect}/>
        <StyledRect x="50" y="57.5" width="20" height="70" />

        {/* Toile */}
        <StyledRect4 x="170" y="60" width="5" height="65" className={styles.rectFill}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="40" y="162" width="10" height="2" className={styles.couleurCharteRect}/>
        <StyledRect5 x="70" y="162" width="10" height="2" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 75,55 L 169,55"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="53"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,60.5 L 180,60.5" />
        <StyledPathCote d="M 176,125.5 L 180,125.5" />
        <StyledPathFleche
          d="M 180,67 L 180,120"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="94"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}

        {/* délimiteur section max B */}
        <StyledPathCote d="M 37,162 L 39,162" />
        <StyledPathCote d="M 37,182 L 48,182" />
        {afficherSectionMax(159, 157, 37, 34)}
      </>
    );
  };

  const enrouleurFixation3EtFace3 = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        <StyledRect5 x="50" y="65" width="3" height="70" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="50" y="155" width="2" height="7" className={styles.couleurCharteRect}/>
        <StyledRect5 x="50" y="182" width="2" height="7" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}

        {/* délimiteur coté section max A */}
        <StyledPathCote d="M 70,152 L 70,160" />
        <StyledPathCote d="M 50,152 L 50,154" />

        {afficherSectionMax(152, 150, 47, 44)}
      </>
    );
  };

  const enrouleurFixation3EtFace4 = () => {
    return (
      <>
        {/* Vue du dessus */}
        <StyledRect5 x="70" y="57.5" width="10" height="2" className={styles.couleurCharteRect}/>
        <StyledRect5 x="70" y="125.5" width="10" height="2" className={styles.couleurCharteRect}/>

        <StyledRect5 x="40" y="57.5" width="10" height="70" className={styles.couleurCharteRect}/>

        <StyledRect x="50" y="57.5" width="20" height="70" />
        <StyledRect x="70" y="60" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="60" width="5" height="65" className={styles.rectFill}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="40" y="180" width="10" height="2" className={styles.couleurCharteRect}/>
        <StyledRect5 x="70" y="180" width="10" height="2" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        {/* ligne pointillée système de fixation droit */}
        <StyledPathDotted d="M 80,62 L 80,125 " className={styles.dotted} />

        <StyledPathFleche
          d="M 75,55 L 169,55"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="53"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,60.5 L 180,60.5" />
        <StyledPathCote d="M 176,125.5 L 180,125.5" />
        <StyledPathFleche
          d="M 180,67 L 180,120"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="94"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}

        {/* délimiteur section max B */}
        <StyledPathCote d="M 37,162 L 48,162" />
        <StyledPathCote d="M 37,182 L 39,182" />
        {afficherSectionMax(159, 157, 37, 34)}
      </>
    );
  };

  const enrouleurFixation4EtFace1 = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        <StyledRect5 x="68" y="55" width="2" height="90" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="68" y="154" width="2" height="28" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}

        {/* délimiteur coté section max A */}
        <StyledPathCote d="M 50,152 L 50,160" />
        <StyledPathCote d="M 70,152 L 70,154" />

        {afficherSectionMax(152, 150, 47, 44)}
      </>
    );
  };

  const enrouleurFixation4EtFace2 = () => {
    return (
      <>
        {/* Vue du dessus */}
        <StyledRect5 x="35" y="55" width="35" height="10" className={styles.couleurCharteRect}/>
        <StyledRect5 x="35" y="55" width="15" height="80" className={styles.couleurCharteRect}/>
        <StyledRect5 x="35" y="135" width="35" height="10" className={styles.couleurCharteRect}/>


        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="35" y="162" width="35" height="2" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}

        {/* délimiteur section max B */}
        <StyledPathCote d="M 32,162 L 34,162" />
        <StyledPathCote d="M 32,182 L 48,182" />

        {afficherSectionMax(159, 157, 32, 29)}
      </>
    );
  };

  const enrouleurFixation4EtFace3 = () => {
    return (
      <>
        {/* Vue du dessus */}

        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        <StyledRect5 x="50" y="55" width="2" height="90" className={styles.couleurCharteRect}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="50" y="154" width="2" height="28" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}

        {/* délimiteur coté section max A */}
        <StyledPathCote d="M 50,152 L 50,154" />
        <StyledPathCote d="M 70,152 L 70,161" />

        {afficherSectionMax(152, 150, 47, 44)}
      </>
    );
  };

  const enrouleurFixation4EtFace4 = () => {
    return (
      <>
        {/* Vue du dessus */}
        <StyledRect5 x="35" y="55" width="35" height="10" className={styles.couleurCharteRect}/>
        <StyledRect5 x="35" y="55" width="15" height="80" className={styles.couleurCharteRect}/>
        <StyledRect5 x="35" y="135" width="35" height="10" className={styles.couleurCharteRect}/>
        <StyledRect x="50" y="65" width="20" height="70" />
        <StyledRect x="70" y="67.5" width="100" height="65" />
        {/* Toile */}
        <StyledRect4 x="170" y="67.5" width="5" height="65" className={styles.rectFill}/>

        {/* Vue profil */}
        <StyledPath1 d="M 50,182 L 70,182 L 70,162 L 50,162 L 50,182" />
        <StyledRect5 x="35" y="180" width="35" height="2" className={styles.couleurCharteRect}/>
        <StyledPath1 d="M 70,180 L 170,180" />

        <StyledPathFleche
          d="M 77,62 L 169,62"
          markerStart={checkFocusedStartMarker(formik, [L_MAX])}
          markerEnd={checkFocusedEndMarker(formik, [L_MAX])}
          className={checkFocusedArrow(formik, [L_MAX], styles)}
        />

        <text
          fontSize="0.4em"
          x="127"
          y="59"
          direction="rtl"
          className={checkFocusedText(formik, [L_MAX], styles)}
        >
          LD {longueurDeployee}
        </text>

        <StyledPathCote d="M 176,67.5 L 180,67.5" />
        <StyledPathCote d="M 176,132.5 L 180,132.5" />
        <StyledPathFleche
          d="M 180,74 L 180,126"
          markerStart={checkFocusedStartMarker(formik, [LARGEUR_TOILE])}
          markerEnd={checkFocusedEndMarker(formik, [LARGEUR_TOILE])}
          className={checkFocusedArrow(formik, [LARGEUR_TOILE], styles)}
        />

        <text
          fontSize="0.4em"
          x="183"
          y="100"
          direction="ltr"
          className={checkFocusedText(formik, [LARGEUR_TOILE], styles)}
        >
          LT {largeurToile}
        </text>

        {afficherRacleur(70, 75, 67, 70, 70, 75)}

        {/* délimiteur section max B */}
        <StyledPathCote d="M 32,162 L 48,162" />
        <StyledPathCote d="M 32,182 L 34,182" />

        {afficherSectionMax(159, 157, 32, 29)}
      </>
    );
  };

  const switchFixationAndPositionCaisson = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[FIXATION_CAISSON.description],
        S05_STEP6_arraySystemeFixationCaisson(),
        0
      ):
        return (
          <>
            {afficherEnrouleurCaisson()}
            {afficherCoteAgresse()}
            {afficherPositionExtremite()}
            {afficherDimensionExtremite()}
            {afficherSectionMax(160, 158, 47, 44)}
          </>
        );
      //Système de fixation 1
      case equalsCodeOrLabel(
        formik[FIXATION_CAISSON.description],
        S05_STEP6_arraySystemeFixationCaisson(),
        1
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            0
          ):
            return (
              <>
                {enrouleurFixation1EtFace1()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            1
          ):
            return (
              <>
                {enrouleurFixation1EtFace2()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            2
          ):
            return (
              <>
                {enrouleurFixation1EtFace3()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            3
          ):
            return (
              <>
                {enrouleurFixation1EtFace4()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          default:
            return (
              <>
                {afficherEnrouleurCaisson()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
                {afficherSectionMax(160, 158, 47, 44)}
              </>
            );
        }

      // Système de fixation 2
      case equalsCodeOrLabel(
        formik[FIXATION_CAISSON.description],
        S05_STEP6_arraySystemeFixationCaisson(),
        2
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            0
          ):
            return (
              <>
                {enrouleurFixation2EtFace1()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            1
          ):
            return (
              <>
                {enrouleurFixation2EtFace2()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            2
          ):
            return (
              <>
                {enrouleurFixation2EtFace3()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            3
          ):
            return (
              <>
                {enrouleurFixation2EtFace4()}
                {afficherCoteAgresseFix2etFace4()}
                {afficherPositionExtremiteFixation2EtFace4()}
                {afficherDimensionExtremiteFix2etFace4()}
              </>
            );
          default:
            return (
              <>
                {afficherEnrouleurCaisson()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
                {afficherSectionMax(160, 158, 47, 44)}
              </>
            );
        }

      // Système de fixation 3
      case equalsCodeOrLabel(
        formik[FIXATION_CAISSON.description],
        S05_STEP6_arraySystemeFixationCaisson(),
        3
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            0
          ):
            return (
              <>
                {enrouleurFixation3EtFace1()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            1
          ):
            return (
              <>
                {enrouleurFixation3EtFace2()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            2
          ):
            return (
              <>
                {enrouleurFixation3EtFace3()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            3
          ):
            return (
              <>
                {enrouleurFixation3EtFace4()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          default:
            return (
              <>
                {afficherEnrouleurCaisson()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
                {afficherSectionMax(160, 158, 47, 44)}
              </>
            );
        }

      // Système de fixation 4
      case equalsCodeOrLabel(
        formik[FIXATION_CAISSON.description],
        S05_STEP6_arraySystemeFixationCaisson(),
        4
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            0
          ):
            return (
              <>
                {enrouleurFixation4EtFace1()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            1
          ):
            return (
              <>
                {enrouleurFixation4EtFace2()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            2
          ):
            return (
              <>
                {enrouleurFixation4EtFace3()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          case equalsCodeOrLabel(
            formik[POSITION_FIXATION_CAISSON.description],
            S05_STEP6_arrayPositionFixationCaisson(),
            3
          ):
            return (
              <>
                {enrouleurFixation4EtFace4()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
              </>
            );

          default:
            return (
              <>
                {afficherEnrouleurCaisson()}
                {afficherCoteAgresse()}
                {afficherPositionExtremite()}
                {afficherDimensionExtremite()}
                {afficherSectionMax(160, 158, 47, 44)}
              </>
            );
        }

      default:
        return (
          <>
            {afficherEnrouleurCaisson()}
            {afficherCoteAgresse()}
            {afficherPositionExtremite()}
            {afficherDimensionExtremite()}
            {afficherSectionMax(160, 158, 47, 44)}
          </>
        );
    }
  };

  const afficherEnrouleur = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[TYPE_ENROULEUR.description],
        S05_STEP4_arrayTypeEnrouleur(),
        0
      ):
        return (
          <>
            {switchFixationAndPositionCaisson()}
            {/* {afficherSectionMax(160, 158, 47, 44)} */}
          </>
        );

      case equalsCodeOrLabel(
        formik[TYPE_ENROULEUR.description],
        S05_STEP4_arrayTypeEnrouleur(),
        1
      ):
        return (
          <>
            {afficherEnrouleurSansCaisson()}
            {afficherCoteAgresse()}
            {afficherPositionExtremite()}
            {afficherDimensionExtremite()}
          </>
        );
      case equalsCodeOrLabel(
        formik[TYPE_ENROULEUR.description],
        S05_STEP4_arrayTypeEnrouleur(),
        2
      ):
        return (
          <>
            {afficherEnrouleurAvecSupport()}
            {afficherCoteAgresse()}
            {afficherPositionExtremite()}
            {afficherDimensionExtremite()}
          </>
        );
      default:
        return null;
    }
  };
  return (
    <Paper elevation={0}>
      <svg viewBox="-10 40 255 150">
        <defs>
          <marker
            id="startarrow"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 2, 10 5, 0 3.5" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 2, 10 3.5, 0 5" />
          </marker>
          <marker
            id="startarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="10 2, 10 5, 0 3.5"
              className={styles.markerSelected}
            />
          </marker>
          <marker
            id="endarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon
              points="0 2, 10 3.5, 0 5"
              className={styles.markerSelected}
            />
          </marker>
        </defs>

        <title></title>

        {afficherEnrouleur()}
        {/* <image
              href={importAll()["D3.png"]}
              height="100%"
              width="100%"
              x="-14"
              y="40"
            /> */}
      </svg>
    </Paper>
  );
};
