//Etape 4
const ENVELOPPE_MATIERE = Symbol("SQ0071_Q00020");

//Etape 5
const S08_TYPE_SECTION_ENVELOPPE = Symbol("SQ0072_Q00020");
const S08_SECTION_ENVELOPPE_PLAT = Symbol("SQ0072_Q00030");
const S08_TYPE_TOIT = Symbol("SQ0072_Q00040");
const S08_SECTION_ENVELOPPE_TOIT = Symbol("SQ0072_Q00050");
const S08_SECTION_ENVELOPPE_TOIT_PLAT = Symbol("SQ0072_Q00050");
const S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE = Symbol("SQ0072_Q00080");
const S08_SECTION_ENVELOPPE_PENTE_MONTANTE = Symbol("SQ0072_Q00090");
const S08_SECTION_ENVELOPPE_S = Symbol("SQ0072_Q00100");
const S08_TYPE_PENTE = Symbol("SQ0072_Q00070");
const S08_LONGUEUR_A = Symbol("SQ0072_Q00110");
const S08_LONGUEUR_B = Symbol("SQ0072_Q00120");
const S08_LONGUEUR_C = Symbol("SQ0072_Q00130");
const S08_LONGUEUR_D = Symbol("SQ0072_Q00140");
const S08_LONGUEUR_E = Symbol("SQ0072_Q00150");
const S08_LONGUEUR_F = Symbol("SQ0072_Q00170");
const S08_LONGUEUR_G = Symbol("SQ0072_Q00180");
const S08_LONGUEUR_H = Symbol("SQ0072_Q00190");
const S08_LONGUEUR_I = Symbol("SQ0072_Q00160");

const S08_ANGLE_J = Symbol("SQ0072_Q00250");
const S08_ANGLE_K = Symbol("SQ0072_Q00260");
const S08_ANGLE_L = Symbol("SQ0072_Q00270");

const S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE = Symbol("formeQuelconqueJoindrePlan");

//Etape 6
const S08_LONGUEUR_MAX = Symbol("SQ0073_Q00020");
const S08_LONGUEUR_MIN = Symbol("SQ0073_Q00030");
const S08_LONGUEUR_COURSE = Symbol("SQ0073_Q00060");
const S08_NOMBRE_ELEMENTS = Symbol("SQ0073_Q00040");
const S08_LONGUEUR_1ER_ELEMENT = Symbol("SQ0073_Q00050");

//Etape 7
const S08_FIXATION_GROS_ELEMENT = Symbol("SQ0074_Q00020");
const S08_FIXATION_PETIT_ELEMENT = Symbol("SQ0074_Q00030");

//Etape 8
const S08_ACCESSOIRES = Symbol("accessoires");
const BUTEE_CAOUTCHOUC = Symbol("SQ0075_Q00050");
const ROULETTES = Symbol("SQ0075_Q00040");
const PANTOGRAPHE = Symbol("SQ0075_Q00170");
const TOLE_LARMEE = Symbol("SQ0075_Q00180");

export {
  //Etape4
  ENVELOPPE_MATIERE,
  //Etape5
  S08_TYPE_SECTION_ENVELOPPE,
  S08_SECTION_ENVELOPPE_PLAT,
  S08_SECTION_ENVELOPPE_TOIT,
  S08_SECTION_ENVELOPPE_TOIT_PLAT,
  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
  S08_SECTION_ENVELOPPE_S,
  S08_TYPE_TOIT,
  S08_TYPE_PENTE,
  S08_LONGUEUR_A,
  S08_LONGUEUR_B,
  S08_LONGUEUR_C,
  S08_LONGUEUR_D,
  S08_LONGUEUR_E,
  S08_LONGUEUR_F,
  S08_LONGUEUR_G,
  S08_LONGUEUR_H,
  S08_LONGUEUR_I,
  S08_ANGLE_J,
  S08_ANGLE_K,
  S08_ANGLE_L,
  S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE,
  //Etape 6
  S08_LONGUEUR_MAX,
  S08_LONGUEUR_MIN,
  S08_LONGUEUR_COURSE,
  S08_NOMBRE_ELEMENTS,
  S08_LONGUEUR_1ER_ELEMENT,
  //Etape 7
  S08_FIXATION_GROS_ELEMENT,
  S08_FIXATION_PETIT_ELEMENT,
  //Etape 8
  S08_ACCESSOIRES,
  BUTEE_CAOUTCHOUC,
  ROULETTES,
  PANTOGRAPHE,
  TOLE_LARMEE
};
