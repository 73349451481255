import {
  COMMENTAIRE,
  DELAI,
  FICHIERS_SUPP,
  QUANTITEE_SOUHAITEE,
  RENSEIGNEZ_QTE,
  SERVICES,
  SERVICESMOOV,
  SERVICESRC,
} from "../../../../../constantes/symbols/SymbolsCommon";
import { arrayServicesMoov, arrayServicesRC } from "../../../../../constantes/symbols/SymbolsServicesCommon";
import { getImageInformation, importAll } from "../../../../../helper/ImportAll";
import {
  TEXTFIELD_ZONE,
  TEXT,
  DROPZONE,
  CHECKBOX,
  TEXTFIELD,
  TEXTFIELD_RETURN_ARRAY,
  CHECKBOX_BOX_SHADOW,
  TEXT_CUSTOM1,
  CHECKBOX_IMAGE_CUSTOM,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_SERVICESRC = Symbol("titreServices");
const TITRE_SERVICESMOOV = Symbol("titreServicesMoov");
const TITRE_COMMENTAIRE = Symbol("titreCommentaire");
const TITRE_DELAIS = Symbol("titreDelais");
const SOUS_TITRE_SERVICESRC = Symbol("sousTitreServices");
const SOUS_TITRE_SERVICESMOOV = Symbol("sousTitreServicesMoov");

export const formulaireInformationsComplementairesP1 = (styles, t) => [
  {
    nom: RENSEIGNEZ_QTE,
    label: t.renseignementQuantite+t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
];

export const formulaireInformationsComplementairesNewQte = (
  styles,
  t,
  index
) => {
  return {
    nom: QUANTITEE_SOUHAITEE,
    label: t.quantiteSouhaitee,
    type: TEXTFIELD_RETURN_ARRAY,
    dataType: "number",
    positionInArray: index,
    cssTextfield: styles.cssTextfield,
    disabled: index === 0,
  };
};

export const formulaireInformationsComplementairesP2 = (styles, t) => [
  {
    nom: TITRE_DELAIS,
    label: t.titreDelais,
    type: TEXT,
    cssFontFamily: styles.labelQuestion,
    cssMarginFormSection: styles.sectionFormMarginT,
    /*textInformation: {
      text: t.informationsComplementairesInfo,
      cssIcon: styles.helperTextIcon,
      images: getImageInformation(),
    },*/
  },
  {
    nom: DELAI,
    label: t.delaiSouhaite,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMargin0,
  },
];
export const formulaireInformationsComplementairesP3 = (styles, t) => [
  {
    nom: TITRE_SERVICESRC,
    label: t.titreServiceRC,
    type: TEXT_CUSTOM1,
    cssFontFamily: styles.labelQuestion,
    cssMarginFormSection: styles.sectionFormMarginT,
    /*textInformation: {
      text: t.informationsComplementairesInfo,
      cssIcon: styles.helperTextIcon,
      images: getImageInformation(),
    },*/
  },
  {
    nom: SOUS_TITRE_SERVICESRC,
    label: t.titreChoixService,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
  {
    nom: SERVICES,
    type: CHECKBOX_IMAGE_CUSTOM, 
    images: importAll(),
    cssSurroundGridItem: styles.cssSurroundGridItem,
    cssCheckbox: styles.cssCheckboxAccessoires,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.noSectionForm,
    donnees: arrayServicesRC(t),
  },
  // {
  //   nom: SERVICES,
  //   label: t.titreChoixServiceMoov,
  //   type: CHECKBOX,
  //   cssFontFamily: styles.cssFontFamily,
  //   cssMarginFormSection: styles.sectionForm0Margin,
  //   donnees: arrayServicesRC(t),
  // },
];

export const formulaireInformationsComplementairesP4 = (styles, t) => [
  {
    nom: TITRE_COMMENTAIRE,
    label: t.titreCommentaires,
    type: TEXT,
    cssFontFamily: styles.labelQuestion,
    cssMarginFormSection: styles.sectionFormMarginT,
    /*textInformation: {
      text: t.informationsComplementairesInfo,
      cssIcon: styles.helperTextIcon,
      images: getImageInformation(),
    },*/
  },
  {
    nom: COMMENTAIRE,
    label: t.commentaire,
    type: TEXTFIELD_ZONE,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
  },
  {
    nom: FICHIERS_SUPP,
    label: t.ajouterFichier,
    type: DROPZONE,
    styles: styles,
    cssMarginFormSection: styles.sectionFormMarginB,
  },
];

export const formulaireInformationsComplementairesP5 = (styles, t) => [
  {
    nom: TITRE_SERVICESMOOV,
    label: t.titreServiceMoov,
    type: TEXT,
    cssFontFamily: styles.labelQuestion,
    cssMarginFormSection: styles.sectionFormMarginT,
    /*textInformation: {
      text: t.informationsComplementairesInfo,
      cssIcon: styles.helperTextIcon,
      images: getImageInformation(),
    },*/
  },
  {
    nom: SOUS_TITRE_SERVICESMOOV,
    label: t.titreChoixServiceMoov,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
  {
    nom: SERVICES,
    type: CHECKBOX_BOX_SHADOW, 
    images: importAll(),
    cssSurroundGridItem: styles.cssSurroundGridItem,
    cssCheckbox: styles.cssCheckboxAccessoires,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.noSectionForm,
    donnees: arrayServicesMoov(t),
  },
  
];
