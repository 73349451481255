import {
  S08_FIXATION_GROS_ELEMENT,
  S08_FIXATION_PETIT_ELEMENT,
} from "../../../../../constantes/symbols/SymbolsS08";
import {
  S08_STEP7_arrayFixationGros,
  S08_STEP7_arrayFixationPetit,
} from "../../../../../constantes/symbols/SymbolsServicesS08";
import {
  TEXT,
  TEXTFIELD_BY_LABEL,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_FIXATION_GROS_ELEMENT = Symbol("Fixation - Gros élément");
const TITRE_FIXATION_PETIT_ELEMENT = Symbol("Fixation - Petit élément");

export const formulaireExtremitesS08 = (styles, t) => [
  {
    nom: TITRE_FIXATION_GROS_ELEMENT,
    label: t.fixationGrosElement+t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0074_Q00020,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_FIXATION_GROS_ELEMENT,
    label: t.descrFixationElement,
    type: TEXTFIELD_BY_LABEL /*ou BUTTON_GROUP*/,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: S08_STEP7_arrayFixationGros(t),
  },
  {
    nom: TITRE_FIXATION_PETIT_ELEMENT,
    label: t.fixationPetitElement+t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0074_Q00030,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_FIXATION_PETIT_ELEMENT,
    label: t.descrFixationElement,
    type: TEXTFIELD_BY_LABEL /*ou BUTTON_GROUP*/,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: S08_STEP7_arrayFixationPetit(t),
  },
];
