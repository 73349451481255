/**
 * Permet d'utiliser l'objet meme si l'attribut nom n'est pas défini
 *
 * @param {*} t
 * @param {*} obj
 * @returns
 */
export const usedForDisplay = (t, obj) => {
  return { ...(t && { ...obj }) };
};

/**
 * Elle renvoie true si le premier argument est égal au code ou à l'étiquette de l'objet à l'index du
 * second argument.
 * @param constante - la constante est la valeur que vous voulez comparer au tableau.
 * @param array - [{code : 'A', étiquette : 'A'}, {code : 'B', étiquette : 'B'}, {code : 'C', étiquette
 * : 'C'}]
 * @param index - l'indice du tableau
 * @returns Une fonction qui prend en compte 3 paramètres.
 */
export const equalsCodeOrLabel = (constante, array, index) => {
  return (
    constante === array[index].code ||
    parseInt(constante) === array[index].code ||
    constante === array[index].label
  );
};

/**
 * Elle renvoie true si la constante n'est pas égale au code ou à l'étiquette du tableau à l'index
 * donné.
 * @param constante - est la valeur constante que je veux comparer avec le tableau
 * @param array - [{code : 1, étiquette : 'étiquette1'}, {code : 2, étiquette : 'étiquette2'}, {code :
 * 3, étiquette : 'étiquette3'}]
 * @param index - l'indice du tableau
 * @returns Une fonction qui accepte une constante, un tableau et un index.
 */
export const notEqualsCodeAndLabel = (constante, array, index) => {
  return (
    parseInt(constante) !== array[index].code &&
    constante !== array[index].label
  );
};

export const internalServiceValueInArray = (array, value) => {
  return (
    array.find((e) => e.code === parseInt(value) || e.label === value) !==
    undefined
  );
};

/**
 * Elle renvoie 1 si la constante est égale à 1, "Oui" ou vrai, sinon elle renvoie 0.
 * @param constante - la valeur à vérifier
 * @returns 1
 */
export const equalsBooleanOrStringOrNumber = (constante) => {
  if (parseInt(constante) === 1) {
    return 1;
  }
  switch (constante) {
    case "Oui":
    case true:
      return 1;
    default:
      return 0;
  }
};

export const isValidNumber = (value) => !isNaN(parseInt(value)) && value !== null && value !== undefined;

export const containsLetters = (value) => /[a-zA-Z]/.test(value);
