import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../../../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../../../../../constantes/symbols/SymbolsServicesCommon";
import { importAll } from "../../../../../helper/ImportAll";
import { BUTTON_GROUP_BOX_SHADOW } from "../../../components/generateFormulary/types/TypeFieldsFormulary";


/**
 * Il prend un objet de styles et un objet de traduction comme paramètres et renvoie un tableau
 * d'objets
 * @param styles - un objet contenant les styles css
 * @param t - est l'objet de traduction
 */
export const formulaireFamilleProtectionIndustrielle = (styles, t) => [
  {
    nom: FAMILLE_PROTECTION_INDUSTRIELLE,
    type: BUTTON_GROUP_BOX_SHADOW,
    cssFontFamily: styles.cssFontFamily,
    cssRadioPhoto: styles.cssRadioPhoto,
    cssMarginFormSection: styles.noSectionForm,
    cssSurroundGridItem: styles.cssSurroundGridItem,
    images: importAll(),
    donnees: arrayProductFamilly(t),
  },
];
