import * as Yup from "yup";
import {
  MATIERE_TOILE,
  TYPE_ENROULEUR,
} from "../../../../../constantes/symbols/SymbolsS05";
import {
  S05_STEP4_arrayMatiereToile,
  S05_STEP4_arrayTypeEnrouleur,
} from "../../../../../constantes/symbols/SymbolsServicesS05";

/* A Yup validation schema. */
export const validConstructionS05 = (t, etape) => {
  if (etape !== 4) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [MATIERE_TOILE.description]: Yup.mixed().test(
      "matiereToileInSelect",
      () => {
        return t.erreurSelectionType;
      },
      (value) => {
        if(value===null||value===undefined){
          return true;
        }
        return S05_STEP4_arrayMatiereToile(t).some(
          (e) => e.code === parseInt(value)
        );
      }
    ),
    //.required(t.erreurSelectionType),
    [TYPE_ENROULEUR.description]: Yup.mixed()
      .test(
        "typeEnrouleurInSelect",
        () => {
          return t.erreurSelectionType;
        },
        (selectedValue) =>
          S05_STEP4_arrayTypeEnrouleur(t).some(
            (e) => e.code === parseInt(selectedValue)
          )
      )
      .required(t.erreurSelectionType),
  });
};
