import * as Yup from "yup";
import { equalsCodeOrLabel } from "../../../../../constantes/symbols/HelperSymbolsServices";
import {
  FORME_CORPS_A,
  FORME_CORPS_B,
  FORME_CORPS_C,
  FORME_CORPS_D,
  FORME_CORPS_DS,
  FORME_CORPS_E,
  FORME_CORPS_ES,
  FORME_CORPS_F,
  FORME_CORPS_FS,
  JOINDRE_PLAN_FORME_QUELCONQUE,
  LONGUEUR_A,
  LONGUEUR_B,
  LONGUEUR_C,
  LONGUEUR_D,
  LONGUEUR_E,
  LONGUEUR_F,
  LONGUEUR_G,
  LONGUEUR_H,
  LONGUEUR_X,
  SECTION_SYMETRIQUE,
  TOILE,
  TYPE_SECTION,
} from "../../../../../constantes/symbols/SymbolsS04";
import {
  S04_STEP4_arrayFormeCorpsD,
  S04_STEP4_arrayFormeCorpsE,
  S04_STEP4_arrayFormeCorpsF,
  S04_STEP4_arrayFormeCorpsSymA,
  S04_STEP4_arrayFormeCorpsSymB,
  S04_STEP4_arrayFormeCorpsSymC,
  S04_STEP4_arrayFormeCorpsSymD,
  S04_STEP4_arrayFormeCorpsSymE,
  S04_STEP4_arrayFormeCorpsSymF,
  S04_STEP4_arrayToile,
  S04_STEP4_arrayTypeCorps,
} from "../../../../../constantes/symbols/SymbolsServicesS04";

/* Setting the maximum size of a file to 10MB. */
const FILE_SIZE = 10000000;
/* A constant that contains the list of supported formats. */
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/bmp",
  "image/png",
];

const otherwiseSchema = Yup.mixed().optional();

const testPositiveAndMin = (t) => {
  return Yup.number()
    .positive()
    .min(0, t.superieurA0)
    .required(t.renseignerChamp);
};

const testFormeCorps = (
  t,
  indexTypeSection,
  isSymetrique,
  isIncludedInThisList
) => {
  return Yup.mixed().when(
    [
      [TYPE_SECTION.description].toString(),
      [SECTION_SYMETRIQUE.description].toString(),
    ],
    {
      is: (type, symetrique) =>
        equalsCodeOrLabel(type, S04_STEP4_arrayTypeCorps(), indexTypeSection) &&
        !!symetrique === isSymetrique,
      then: Yup.mixed()
        .test(
          "TypeSelectionInSelect",
          () => {
            return t.erreurSelectionType;
          },
          (selectedValue) =>
            isIncludedInThisList.some((e) => e.code === parseInt(selectedValue))
        )
        .required(t.erreurSelectionType),
      otherwise: otherwiseSchema,
    }
  );
};

/* A Yup validation schema. */
export const validConstruction = (t, etape) => {
  if (etape !== 4) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [TYPE_SECTION.description]: Yup.mixed()
      .test(
        "TypeSelectionInSelect",
        () => {
          return t.erreurSelectionType;
        },
        (value) =>
          S04_STEP4_arrayTypeCorps(t).some((e) => e.code === parseInt(value))
      )
      .required(t.erreurSelectionType),

    [FORME_CORPS_A.description]: testFormeCorps(
      t,
      0,
      true || false,
      S04_STEP4_arrayFormeCorpsSymA()
    ),
    [FORME_CORPS_B.description]: testFormeCorps(
      t,
      1,
      true || false,
      S04_STEP4_arrayFormeCorpsSymB()
    ),
    [FORME_CORPS_C.description]: testFormeCorps(
      t,
      2,
      true || false,
      S04_STEP4_arrayFormeCorpsSymC()
    ),
    [FORME_CORPS_DS.description]: testFormeCorps(
      t,
      3,
      true,
      S04_STEP4_arrayFormeCorpsSymD()
    ),
    [FORME_CORPS_D.description]: testFormeCorps(
      t,
      3,
      false,
      S04_STEP4_arrayFormeCorpsD()
    ),
    [FORME_CORPS_ES.description]: testFormeCorps(
      t,
      4,
      true,
      S04_STEP4_arrayFormeCorpsSymE()
    ),
    [FORME_CORPS_E.description]: testFormeCorps(
      t,
      4,
      false,
      S04_STEP4_arrayFormeCorpsE()
    ),
    [FORME_CORPS_FS.description]: testFormeCorps(
      t,
      5,
      true,
      S04_STEP4_arrayFormeCorpsSymF()
    ),
    [FORME_CORPS_F.description]: testFormeCorps(
      t,
      5,
      false,
      S04_STEP4_arrayFormeCorpsF()
    ),

    [TOILE.description]: Yup.mixed().test(
      "ToileSelectionInSelect",
      () => {
        return t.erreurSelectionType;
      },
      (selectedValue) => {
        if ([null, undefined].includes(selectedValue)) {
          return true;
        }
        return S04_STEP4_arrayToile(t).some(
          (e) => e.code === parseInt(selectedValue)
        );
      }
    ),
    //.required(t.erreurSelectionType),
    [LONGUEUR_A.description]: Yup.mixed().when(
      [TYPE_SECTION.description].toString(),
      {
        is: (type) =>
          [
            S04_STEP4_arrayTypeCorps(t)[0].code,
            S04_STEP4_arrayTypeCorps(t)[1].code,
            S04_STEP4_arrayTypeCorps(t)[2].code,
            S04_STEP4_arrayTypeCorps(t)[3].code,
            S04_STEP4_arrayTypeCorps(t)[4].code,
            S04_STEP4_arrayTypeCorps(t)[5].code,
          ].includes(parseInt(type)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [LONGUEUR_B.description]: Yup.mixed().when(
      [TYPE_SECTION.description].toString(),
      {
        is: (type) =>
          [
            S04_STEP4_arrayTypeCorps(t)[1].code,
            S04_STEP4_arrayTypeCorps(t)[2].code,
            S04_STEP4_arrayTypeCorps(t)[3].code,
            S04_STEP4_arrayTypeCorps(t)[4].code,
            S04_STEP4_arrayTypeCorps(t)[5].code,
          ].includes(parseInt(type)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [LONGUEUR_C.description]: Yup.mixed().when(
      [
        [TYPE_SECTION.description].toString(),
        [SECTION_SYMETRIQUE.description].toString(),
        [FORME_CORPS_DS.description].toString(),
      ],
      {
        is: (type, symetrique, formeDS) =>
          (parseInt(type) === S04_STEP4_arrayTypeCorps(t)[3].code &&
            ((!!symetrique &&
              parseInt(formeDS) === S04_STEP4_arrayFormeCorpsSymD(t)[1].code) ||
              !symetrique)) ||
          parseInt(type) === S04_STEP4_arrayTypeCorps(t)[4].code ||
          parseInt(type) === S04_STEP4_arrayTypeCorps(t)[5].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [LONGUEUR_D.description]: Yup.mixed().when(
      [
        [TYPE_SECTION.description].toString(),
        [SECTION_SYMETRIQUE.description].toString(),
        [FORME_CORPS_D.description].toString(),
        [FORME_CORPS_ES.description].toString(),
        [FORME_CORPS_FS.description].toString(),
      ],
      {
        is: (type, symetrique, formeD, formeES, formeFS) =>
          (parseInt(type) === S04_STEP4_arrayTypeCorps(t)[3].code &&
            !symetrique &&
            parseInt(formeD) ===
              (S04_STEP4_arrayFormeCorpsD(t)[1].code ||
                S04_STEP4_arrayFormeCorpsD(t)[2].code)) ||
          (parseInt(type) === S04_STEP4_arrayTypeCorps(t)[4].code &&
            ((!!symetrique &&
              parseInt(formeES) === S04_STEP4_arrayFormeCorpsSymE(t)[1].code) ||
              !symetrique)) ||
          (parseInt(type) === S04_STEP4_arrayTypeCorps(t)[5].code &&
            ((!!symetrique &&
              parseInt(formeFS) === S04_STEP4_arrayFormeCorpsSymF(t)[1].code) ||
              !symetrique)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [LONGUEUR_E.description]: Yup.mixed().when(
      [
        [TYPE_SECTION.description].toString(),
        [SECTION_SYMETRIQUE.description].toString(),
        [FORME_CORPS_D.description].toString(),
        [FORME_CORPS_ES.description].toString(),
        [FORME_CORPS_E.description].toString(),
      ],
      {
        is: (type, symetrique, formeD, formeES, formeE) =>
          (parseInt(type) === S04_STEP4_arrayTypeCorps(t)[3].code &&
            !symetrique &&
            parseInt(formeD) === S04_STEP4_arrayFormeCorpsD(t)[2].code) ||
          (parseInt(type) === S04_STEP4_arrayTypeCorps(t)[4].code &&
            ((!!symetrique &&
              parseInt(formeES) === S04_STEP4_arrayFormeCorpsSymE(t)[1].code) ||
              (!symetrique &&
                parseInt(formeE) === S04_STEP4_arrayFormeCorpsE(t)[1].code))) ||
          (parseInt(type) === S04_STEP4_arrayTypeCorps(t)[5].code &&
            !symetrique),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [LONGUEUR_F.description]: Yup.mixed().when(
      [
        [TYPE_SECTION.description].toString(),
        [SECTION_SYMETRIQUE.description].toString(),
        [FORME_CORPS_E.description].toString(),
      ],
      {
        is: (type, symetrique, formeE) =>
          (parseInt(type) === S04_STEP4_arrayTypeCorps(t)[4].code &&
            !symetrique &&
            parseInt(formeE) === S04_STEP4_arrayFormeCorpsE(t)[1].code) ||
          (parseInt(type) === S04_STEP4_arrayTypeCorps(t)[5].code &&
            !symetrique),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [LONGUEUR_G.description]: Yup.mixed().when(
      [
        [TYPE_SECTION.description].toString(),
        [SECTION_SYMETRIQUE.description].toString(),
        [FORME_CORPS_F.description].toString(),
      ],
      {
        is: (type, symetrique, formeF) =>
          parseInt(type) === S04_STEP4_arrayTypeCorps(t)[5].code &&
          !symetrique &&
          parseInt(formeF) === S04_STEP4_arrayFormeCorpsF(t)[1].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [LONGUEUR_H.description]: Yup.mixed().when(
      [
        [TYPE_SECTION.description].toString(),
        [SECTION_SYMETRIQUE.description].toString(),
        [FORME_CORPS_F.description].toString(),
      ],
      {
        is: (type, symetrique, formeF) =>
          parseInt(type) === S04_STEP4_arrayTypeCorps(t)[5].code &&
          !symetrique &&
          parseInt(formeF) === S04_STEP4_arrayFormeCorpsF(t)[1].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),
    [LONGUEUR_X.description]: Yup.mixed().when(
      [TYPE_SECTION.description].toString(),
      {
        is: (type) =>
          [
            S04_STEP4_arrayTypeCorps(t)[0].code,
            S04_STEP4_arrayTypeCorps(t)[1].code,
            S04_STEP4_arrayTypeCorps(t)[2].code,
            S04_STEP4_arrayTypeCorps(t)[3].code,
            S04_STEP4_arrayTypeCorps(t)[4].code,
            S04_STEP4_arrayTypeCorps(t)[5].code,
          ].includes(parseInt(type)),
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [JOINDRE_PLAN_FORME_QUELCONQUE.description]: Yup.mixed().when(
      [TYPE_SECTION.description],
      {
        is: (type) => type === S04_STEP4_arrayTypeCorps(t)[6].code,
        then: Yup.mixed()
          .test(
            "fileSize",
            (fileList) => {
              return (
                t.erreurFichierVolumineux +
                getNameFilesTooBig(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFilesTooBig(fileList).length === 0;
            }
          )
          .test(
            "fileCount",
            (fileList) => {
              return t.erreurFichierMaxAtteint + fileList.length;
            },
            (fileList) => {
              return fileList.length <= 10;
            }
          )
          .test(
            "fileFormat",
            (fileList) => {
              return (
                t.erreurFormatFichier +
                getNameFileWrongFormat(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFileWrongFormat(fileList).length === 0;
            }
          ),
        /*.test(
                        'fileCountZero',
                        'Merci de fournir un plan dans la dropbox ci-dessus.',
                        (fileList) => !fileList.length===0
                    )*/ otherwise: otherwiseSchema,
      }
    ),
  });
};

/**
 * Given a list of files, return a list of files that are too big
 * @param listFiles - the list of files to be filtered
 * @returns An array of objects.
 */
const getNameFilesTooBig = (listFiles) => {
  return listFiles.filter((file) => file.size > FILE_SIZE);
};

/**
 * Given a list of files, return a list of files that are not of a supported format
 * @param listFiles - The list of files to be filtered.
 * @returns An array of files that are not supported.
 */
const getNameFileWrongFormat = (listFiles) => {
  return listFiles.filter((file) => !SUPPORTED_FORMATS.includes(file.type));
};
