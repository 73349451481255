import { FOCUSED } from "../../../../../constantes/symbols/SymbolsCommon";
import {
  S08_LONGUEUR_1ER_ELEMENT,
  S08_LONGUEUR_COURSE,
  S08_LONGUEUR_MAX,
  S08_LONGUEUR_MIN,
  S08_NOMBRE_ELEMENTS,
} from "../../../../../constantes/symbols/SymbolsS08";
import {
  initChampsFormikToJsonDefaultCustom,
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
  S08_LONGUEUR_MAX,
  S08_LONGUEUR_MIN,
  S08_LONGUEUR_COURSE,
  S08_NOMBRE_ELEMENTS,
  S08_LONGUEUR_1ER_ELEMENT,
];

export const initialValuesDimensionsS08 = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
      ...initChampsFormikToJsonDefaultCustom([FOCUSED], formulaire, null),
    },
    {}
  );
};
