import { arrayProductFamilly } from "../constantes/symbols/SymbolsServicesCommon";

const getImagesContextGene = () => {
  return require.context("../images", false, /\.(png|jpe?g|svg)$/);
};

const getImagesContextSpec = (booleanIdentite) => {
  if (booleanIdentite) {
    return require.context(
      "../images/moovprotect",
      false,
      /\.(png|jpe?g|svg)$/
    );
  } else {
    return require.context("../images/rcmodeles", false, /\.(png|jpe?g|svg)$/);
  }
};

const getImagesContextFamilleProduit = (famille) => {
  switch (famille) {
    case arrayProductFamilly()[0].code:
      return require.context("../images/S01", false, /\.(png|jpe?g|svg)$/);
    case arrayProductFamilly()[1].code:
      return require.context("../images/S01_1", false, /\.(png|jpe?g|svg)$/);
    case arrayProductFamilly()[2].code:
      return require.context("../images/S04", false, /\.(png|jpe?g|svg)$/);
    case arrayProductFamilly()[4].code:
      return require.context("../images/S05", false, /\.(png|jpe?g|svg)$/);
    case arrayProductFamilly()[5].code:
      return require.context("../images/S08", false, /\.(png|jpe?g|svg)$/);
    case arrayProductFamilly()[3].code:
      return require.context("../images/S06", false, /\.(png|jpe?g|svg)$/);
    default:
      return null;
  }
};

export const importAll = () => {
  const imagesDirectory = getImagesContextGene();
  let images = {};
  imagesDirectory.keys().map((item, index) => {
    images[item.replace("./", "")] = imagesDirectory(item);
  });
  return images;
};

export const importAllSpec = (booleanIdentite) => {
  const imagesDirectory = getImagesContextSpec(booleanIdentite);
  let images = {};
  imagesDirectory.keys().map((item, index) => {
    images[item.replace("./", "")] = imagesDirectory(item);
  });
  return images;
};

/**
 * Il prend une chaîne comme argument et renvoie un objet avec les clés étant les noms de fichiers des
 * images dans le répertoire et les valeurs étant les images elles-mêmes.
 * @param famille - le nom du dossier contenant les images
 * @returns Un objet dont la clé est le nom du fichier et la valeur l'image.
 */
export const importAllEnvironnement = (famille) => {
  const imagesDirectory = getImagesContextFamilleProduit(famille);
  let images = {};
  imagesDirectory.keys().map((item, index) => {
    images[item.replace("./", "")] = imagesDirectory(item);
  });
  return images;
};

export const getImageInformation = () => {
  const imagesDirectory = require.context(
    "../images/information",
    false,
    /\.(png|jpe?g|svg)$/
  );
  let images = {};
  imagesDirectory.keys().map((item, index) => {
    images[item.replace("./", "").split(".")[0]] = imagesDirectory(item);
  });
  return images;
};

//Remove last index in javascript ?
