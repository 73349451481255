import axios from "axios";
import { Serveur } from "../../constantes/services/Serveur";
import { Utilisateur } from "../../constantes/services/Utilisateur";
import { LocalStorage } from "../../constantes/globalName/LocalStorage";

const getAll = async (token) => {
  const reponse = await axios.post(Serveur.URL + Utilisateur.GET_ALL, token);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }
  return reponse;
};

const getAllRole = async (token) => {
  const reponse = await axios.post(
    Serveur.URL + Utilisateur.GET_ALL_ROLE,
    token
  );
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }
  return reponse;
};

const getOwn = async (id, token) => {
  const data = {
    id: id,
    ...token,
  };

  const reponse = await axios.post(Serveur.URL + Utilisateur.GET_OWN, data);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }
  return reponse;
};

const addOne = async (formData, token) => {
  const data = {
    ...formData,
    ...token,
  };

  const reponse = await axios.post(Serveur.URL + Utilisateur.ADD_ONE, data);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }
  return reponse;
};

const modifyOne = async (formData, token) => {
  const data = {
    ...formData,
    ...token,
  };

  const reponse = await axios.post(Serveur.URL + Utilisateur.MODIFY_ONE, data);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }

  return reponse;
};

const deleteOne = async (formData, token) => {
  const data = {
    id: formData,
    ...token,
  };
  const reponse = await axios.post(Serveur.URL + Utilisateur.DELETE_ONE, data);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }
  return reponse;
};

const utilisateurService = {
  getAll,
  getAllRole,
  getOwn,
  addOne,
  modifyOne,
  deleteOne,
};

export default utilisateurService;
