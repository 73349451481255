import { Paper } from "@mui/material";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../constantes/symbols/HelperSymbolsServices";
import {
  FORME_CORPS_A,
  FORME_CORPS_B,
  FORME_CORPS_C,
  FORME_CORPS_D,
  FORME_CORPS_DS,
  FORME_CORPS_E,
  FORME_CORPS_ES,
  FORME_CORPS_F,
  FORME_CORPS_FS,
  LONGUEUR_A,
  LONGUEUR_B,
  LONGUEUR_C,
  LONGUEUR_D,
  LONGUEUR_E,
  LONGUEUR_F,
  LONGUEUR_G,
  LONGUEUR_H,
  LONGUEUR_X,
  S04_ECAILLES,
  SECTION_SYMETRIQUE,
  TYPE_SECTION,
} from "../../../../constantes/symbols/SymbolsS04";
import {
  S04_STEP4_arrayFormeCorpsD,
  S04_STEP4_arrayFormeCorpsE,
  S04_STEP4_arrayFormeCorpsF,
  S04_STEP4_arrayFormeCorpsSymA,
  S04_STEP4_arrayFormeCorpsSymD,
  S04_STEP4_arrayFormeCorpsSymE,
  S04_STEP4_arrayFormeCorpsSymF,
  S04_STEP4_arrayTypeCorps,
  S04_STEP7_arrayA,
  S04_STEP7_arrayAPrime,
  S04_STEP7_arrayB,
  S04_STEP7_arrayBPrime,
  S04_STEP7_arrayC,
  S04_STEP7_arrayD,
  S04_STEP7_arrayE,
  S04_STEP7_arrayF,
} from "../../../../constantes/symbols/SymbolsServicesS04";
import {
  checkFocusedArrow,
  checkFocusedEndMarker,
  checkFocusedStartMarker,
  checkFocusedText,
} from "../../../../helper/ConditionnalCssProvider";

import {
  StyledPath1,
  StyledPath2,
  StyledPathCote,
  StyledPathCoteDotted,
  StyledPathDotted,
  StyledPathEcaille,
  StyledPathFleche,
} from "../../styledComponents/StyledPath";
import { StyledPolygon } from "../../styledComponents/StyledPolygon";
import { StyledRect2 } from "../../styledComponents/StyledRect";

export const FormeConstructionFace = ({ styles, formik, t }) => {
  const formeCorps = findFormeCorpsFromTypeSection(formik);
  const sectionSymetrique = equalsBooleanOrStringOrNumber(
    formik[SECTION_SYMETRIQUE.description]
  );

  const longueurA = formik[LONGUEUR_A.description] || "";
  const longueurB = formik[LONGUEUR_B.description] || "";
  const longueurC = formik[LONGUEUR_C.description] || "";
  const longueurD = formik[LONGUEUR_D.description] || "";
  const longueurE = formik[LONGUEUR_E.description] || "";
  const longueurF = formik[LONGUEUR_F.description] || "";
  const longueurG = formik[LONGUEUR_G.description] || "";
  const longueurH = formik[LONGUEUR_H.description] || "";
  const longueurX = formik[LONGUEUR_X.description] || "";

  const valueHeure = formik?.horsConfigurateur?.CARA0204?.valueHeure;
const arrayValueHeure = valueHeure?.split('').filter(char => char !== "'") || [];


  const isEcaillesLongueurA = formik[S04_ECAILLES.description]?.includes(
    S04_STEP7_arrayA(t)[0].code
  ) /*|| arrayValueHeure.includes('A')*/;
  
  const isEcaillesLongueurB = formik[S04_ECAILLES.description]?.includes(
    S04_STEP7_arrayB(t)[0].code
  );
  const isEcaillesLongueurC = formik[S04_ECAILLES.description]?.includes(
    S04_STEP7_arrayC(t)[0].code
  );
  const isEcaillesLongueurD = formik[S04_ECAILLES.description]?.includes(
    S04_STEP7_arrayD(t)[0].code
  );
  const isEcaillesLongueurE = formik[S04_ECAILLES.description]?.includes(
    S04_STEP7_arrayE(t)[0].code
  );
  const isEcaillesLongueurF = formik[S04_ECAILLES.description]?.includes(
    S04_STEP7_arrayF(t)[0].code
  );
  const isEcaillesLongueurAPrime = formik[S04_ECAILLES.description]?.includes(
    S04_STEP7_arrayAPrime(t)[0].code
  );
  const isEcaillesLongueurBPrime = formik[S04_ECAILLES.description]?.includes(
    S04_STEP7_arrayBPrime(t)[0].code
  );

  const isEcailles = isEcaillesLongueurA || isEcaillesLongueurB || isEcaillesLongueurC || isEcaillesLongueurD || isEcaillesLongueurE || isEcaillesLongueurF || isEcaillesLongueurAPrime || isEcaillesLongueurBPrime;

  const afficherFormeA = () => {
    switch (true) {
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymA(), 0):
        return (
          <>
            <StyledRect2 x="45" y="70" width="150" height="69" />
            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 202,76 L 202,133"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="208"
                y="105"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="208"
                  y="105"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}
          </>
        );
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymA(), 1):
        return (
          <>
            <StyledRect2 x="45" y="70" width="150" height="69" />
            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 202,76 L 202,133"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="208"
                y="105"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="208"
                  y="105"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            {/* ligne pointillée */}
            <StyledPathDotted d="M 54,78 L 185,78" className={styles.dotted} />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>
            
            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 45,68 L 195,68" className={styles.couleurEcaille} />
            ) : null}
          </>
        );
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymA(), 2):
        return (
          <>
          {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 45,68 L 195,68" className={styles.couleurEcaille} />
            ) : null}
            <StyledRect2 x="45" y="70" width="150" height="69" />
            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 202,76 L 202,133"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="208"
                y="105"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="208"
                  y="105"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            <StyledPathDotted d="M 54,78 L 185,78" className={styles.dotted} />
            <StyledPathDotted d="M 54,78 L 54,130" className={styles.dotted} />
            <StyledPathDotted
              d="M 185,78 L 185,130"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );
      default:
        break;
    }
  };

  const afficherFormeB = () => {
    return (
      <>
        {isEcaillesLongueurA ? (
          <StyledPath1 d="M 43,70 L 43,140" className={styles.couleurEcaille} />
        ) : null}
        {isEcaillesLongueurB ? (
          <StyledPath1 d="M 45,68 L 195,68" className={styles.couleurEcaille} />
        ) : null}
        {isEcaillesLongueurAPrime ? (
          <StyledPath1 d="M 197,70 L 197,140" className={styles.couleurEcaille} />
        ) : null}
        {isEcaillesLongueurBPrime ? (
          <StyledPath1 d="M 45,142 L 195,142" className={styles.couleurEcaille} />
        ) : null}
        <StyledRect2 x="45" y="70" width="15" height="70" />
        <StyledRect2 x="180" y="70" width="15" height="70" />
        <StyledRect2 x="45" y="70" width="135" height="15" />
        <StyledRect2 x="45" y="125" width="135" height="15" />

        <StyledPathFleche
          d="M 50,62 L 190,62"
          markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
          markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
          className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
        />
        {longueurB === "" ? (
          <text
            fontSize="0.4em"
            x="120"
            y="60"
            textAnchor="middle"
            className={checkFocusedText(formik, [LONGUEUR_B], styles)}
          >
            B
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="120"
              y="60"
              direction="ltr"
              className={checkFocusedText(formik, [LONGUEUR_B], styles)}
            >
              {longueurB}
            </text>
          </>
        )}

        <StyledPathFleche
          d="M 38,76 L 38,133"
          markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
          markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
          className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
        />
        {longueurA === "" ? (
          <text
            fontSize="0.4em"
            x="32"
            y="105"
            textAnchor="middle"
            className={checkFocusedText(formik, [LONGUEUR_A], styles)}
          >
            A
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="33"
              y="105"
              direction="rtl"
              className={checkFocusedText(formik, [LONGUEUR_A], styles)}
            >
              {longueurA}
            </text>
          </>
        )}

        <StyledPathFleche
          d="M 50,145 L 55,145"
          markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
          markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
          className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
        />
        {longueurX === "" ? (
          <text
            fontSize="0.4em"
            x="52"
            y="153"
            textAnchor="middle"
            className={checkFocusedText(formik, [LONGUEUR_X], styles)}
          >
            X
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="50"
              y="153"
              direction="ltr"
              className={checkFocusedText(formik, [LONGUEUR_X], styles)}
            >
              {longueurX}
            </text>
          </>
        )}
        {/*ligne pointillée haut  */}
        <StyledPathDotted d="M 52,76 L 189,76" className={styles.dotted} />
        {/*ligne pointillée gauche  */}
        <StyledPathDotted d="M 52,76 L 52,135" className={styles.dotted} />
        {/*ligne pointillée droit  */}
        <StyledPathDotted d="M 189,76 L 189,135" className={styles.dotted} />
        {/*ligne pointillée bas  */}
        <StyledPathDotted d="M 52,135 L 189,135" className={styles.dotted} />

        <StyledPathDotted d="M 70,150 L 90,150" className={styles.dotted} />
        <text fontSize="0.4em" x="95" y="153" direction="ltr">
          {t?.titreLegendeToile}
        </text>

        {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
      </>
    );
  };

  const afficherFormeC = () => {
    return (
      <>
        {isEcaillesLongueurA ? (
          <StyledPath1 d="M 43,70 L 43,140" className={styles.couleurEcaille} />
        ) : null}
        {isEcaillesLongueurB ? (
          <StyledPath1 d="M 45,68 L 180,68" className={styles.couleurEcaille} />
        ) : null}
        <StyledRect2 x="45" y="70" width="15" height="70" />
        <StyledRect2 x="45" y="70" width="135" height="15" />

        <StyledPathFleche
          d="M 50,62 L 175,62"
          markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
          markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
          className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
        />
        {longueurB === "" ? (
          <text
            fontSize="0.4em"
            x="112"
            y="60"
            textAnchor="middle"
            className={checkFocusedText(formik, [LONGUEUR_B], styles)}
          >
            B
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="112"
              y="60"
              direction="ltr"
              className={checkFocusedText(formik, [LONGUEUR_B], styles)}
            >
              {longueurB}
            </text>
          </>
        )}

        <StyledPathFleche
          d="M 38,76 L 38,133"
          markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
          markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
          className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
        />
        {longueurA === "" ? (
          <text
            fontSize="0.4em"
            x="32"
            y="105"
            textAnchor="middle"
            className={checkFocusedText(formik, [LONGUEUR_A], styles)}
          >
            A
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="33"
              y="105"
              direction="rtl"
              className={checkFocusedText(formik, [LONGUEUR_A], styles)}
            >
              {longueurA}
            </text>
          </>
        )}

        <StyledPathFleche
          d="M 50,145 L 55,145"
          markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
          markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
          className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
        />
        {longueurX === "" ? (
          <text
            fontSize="0.4em"
            x="52"
            y="153"
            textAnchor="middle"
            className={checkFocusedText(formik, [LONGUEUR_X], styles)}
          >
            X
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="50"
              y="153"
              direction="ltr"
              className={checkFocusedText(formik, [LONGUEUR_X], styles)}
            >
              {longueurX}
            </text>
          </>
        )}

        <StyledPathDotted d="M 50,75 L 175,75" className={styles.dotted} />
        <StyledPathDotted d="M 50,75 L 50,135" className={styles.dotted} />

        <StyledPathDotted d="M 70,150 L 90,150" className={styles.dotted} />
        <text fontSize="0.4em" x="95" y="153" direction="ltr">
          {t?.titreLegendeToile}
        </text>

        {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
      </>
    );
  };

  const afficherFormeDIt2 = () => {
    switch (true) {
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsD(), 0):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 43,70 L 43,140" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 45,68 L 195,68" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurC ? (
              <StyledPath1 d="M 197,70 L 197,110" className={styles.couleurEcaille} />
            ) : null}

            <StyledRect2 x="45" y="70" width="15" height="70" />
            <StyledRect2 x="180" y="70" width="15" height="40" />
            <StyledRect2 x="45" y="70" width="135" height="15" />

            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,76 L 38,133"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="32"
                y="105"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="33"
                  y="105"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 50,145 L 55,145"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="153"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="50"
                  y="153"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 202,76 L 202,104"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="207"
                y="92"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="207"
                  y="92"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathDotted d="M 50,75 L 190,75" className={styles.dotted} />
            <StyledPathDotted d="M 50,75 L 50,135" className={styles.dotted} />
            <StyledPathDotted
              d="M 190,75 L 190,107"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsD(), 1):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 43,70 L 43,110" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 45,68 L 195,68" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurC ? (
              <StyledPath1 d="M 197,70 L 197,140" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurD ? (
              <StyledPath1 d="M 150,142 L 195,142" className={styles.couleurEcaille} />
            ) : null}

            <StyledRect2 x="45" y="70" width="15" height="40" />
            <StyledRect2 x="180" y="70" width="15" height="70" />
            <StyledRect2 x="45" y="70" width="135" height="15" />
            <StyledRect2 x="150" y="125" width="30" height="15" />

            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,76 L 38,103"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="32"
                y="92"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="33"
                  y="92"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 50,115 L 55,115"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="123"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="50"
                  y="123"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 202,76 L 202,133"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="207"
                y="107"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="207"
                  y="107"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 157,145 L 188,145"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_D])}
              className={checkFocusedArrow(formik, [LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="173"
                y="153"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="171"
                  y="153"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathDotted d="M 50,75 L 190,75" className={styles.dotted} />
            <StyledPathDotted d="M 50,75 L 50,107" className={styles.dotted} />
            <StyledPathDotted
              d="M 190,75 L 190,135"
              className={styles.dotted}
            />
            <StyledPathDotted
              d="M 155,135 L 190,135"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 60,150 L 80,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="85" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 110,150 L 130,150" /><text fontSize="0.4em" x="135" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsD(), 2):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 45,127 L 75,127" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 43,70 L 43,125" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurC ? (
              <StyledPath1 d="M 45,68 L 195,68" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurD ? (
              <StyledPath1 d="M 197,70 L 197,140" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurE ? (
              <StyledPath1 d="M 150,142 L 195,142" className={styles.couleurEcaille} />
            ) : null}

            <StyledRect2 x="45" y="70" width="15" height="40" />
            <StyledRect2 x="180" y="70" width="15" height="70" />
            <StyledRect2 x="45" y="70" width="135" height="15" />
            <StyledRect2 x="150" y="125" width="30" height="15" />
            <StyledRect2 x="45" y="110" width="30" height="15" />

            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,76 L 38,118"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="32"
                y="99"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="33"
                  y="99"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 50,130 L 69,130"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="59"
                y="138"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="58"
                  y="138"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 80,116 L 80,120"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="85"
                y="121"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="84"
                  y="121"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 202,76 L 202,133"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_D])}
              className={checkFocusedArrow(formik, [LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="207"
                y="107"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="207"
                  y="107"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 157,145 L 188,145"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_E])}
              className={checkFocusedArrow(formik, [LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="173"
                y="153"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="171"
                  y="153"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathDotted d="M 50,75 L 190,75" className={styles.dotted} />
            <StyledPathDotted d="M 50,75 L 50,123" className={styles.dotted} />
            <StyledPathDotted d="M 50,122 L 70,122" className={styles.dotted} />
            <StyledPathDotted
              d="M 190,75 L 190,135"
              className={styles.dotted}
            />
            <StyledPathDotted
              d="M 155,135 L 190,135"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 110,150 L 130,150" /><text fontSize="0.4em" x="135" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );
      default:
        break;
    }
  };

  const afficherFormeDIt1 = () => {
    switch (true) {
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymD(), 0):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 43,70 L 43,140" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 45,68 L 195,68" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurAPrime ? (
              <StyledPath1 d="M 197,70 L 197,140" className={styles.couleurEcaille} />
            ) : null}

            <StyledRect2 x="45" y="70" width="15" height="70" />
            <StyledRect2 x="180" y="70" width="15" height="70" />
            <StyledRect2 x="45" y="70" width="135" height="15" />

            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,76 L 38,133"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="32"
                y="105"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="33"
                  y="105"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 50,145 L 55,145"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="153"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="50"
                  y="153"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            <StyledPathDotted d="M 50,75 L 190,75" className={styles.dotted} />
            <StyledPathDotted d="M 50,75 L 50,135" className={styles.dotted} />
            <StyledPathDotted
              d="M 190,75 L 190,135"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );

      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymD(), 1):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 45,142 L 90,142" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 43,70 L 43,140" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurC ? (
              <StyledPath1 d="M 45,68 L 195,68" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurAPrime ? (
              <StyledPath1 d="M 150,142 L 195,142" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurBPrime ? (
              <StyledPath1 d="M 197,70 L 197,140" className={styles.couleurEcaille} />
            ) : null}

            <StyledRect2 x="45" y="70" width="15" height="70" />
            <StyledRect2 x="180" y="70" width="15" height="70" />
            <StyledRect2 x="45" y="70" width="135" height="15" />
            <StyledRect2 x="150" y="125" width="30" height="15" />
            <StyledRect2 x="60" y="125" width="30" height="15" />

            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,76 L 38,133"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="32"
                y="107"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="33"
                  y="107"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 51,145 L 84,145"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="67"
                y="152"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="66"
                  y="153"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 95,131 L 95,134"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="100"
                y="135"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="98"
                  y="135"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            <StyledPathDotted d="M 50,75 L 190,75" className={styles.dotted} />
            <StyledPathDotted d="M 50,75 L 50,139" className={styles.dotted} />
            <StyledPathDotted d="M 50,135 L 85,135" className={styles.dotted} />
            <StyledPathDotted
              d="M 190,75 L 190,135"
              className={styles.dotted}
            />
            <StyledPathDotted
              d="M 155,135 L 190,135"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );
      default:
        break;
    }
  };

  const formeE = (hauteurMembreGauche, hauteurMembreDroit) => {
    return (
      <>
        <StyledPolygon points="60,60 75,78 155,95 170,85" />
        <StyledPolygon
          points={
            "170.5,85 155,85 155," +
            hauteurMembreDroit +
            " 170.5," +
            hauteurMembreDroit
          }
        />
        <StyledPolygon
          points={
            "59,60.35 75,78 75," +
            hauteurMembreGauche +
            " 59.5," +
            hauteurMembreGauche
          }
        />
      </>
    );
  };

  const afficherFormeEIt2 = () => {
    switch (true) {
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsE(), 0):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 57,60 L 57,145" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 60,58 L 170,83" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurC ? (
              <StyledPath1 d="M 172.5,85 L 172.5,115" className={styles.couleurEcaille} />
            ) : null}

            {formeE(145, 115)}

            <StyledPathFleche
              d="M 65,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            <StyledPathCoteDotted d="M 59,57 L 59,59" />
            <StyledPathCoteDotted d="M 171,57 L 171,83" />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,67 L 55,139"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="47"
                y="105"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="47"
                  y="105"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,148 L 70,148"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="67.5"
                y="157"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="65"
                  y="157"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,92 L 175,109"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="103"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="103"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathCoteDotted d="M 80,115.5 L 153,115.5" />
            <StyledPathCoteDotted d="M 80,145 L 170,145" />
            <StyledPathFleche
              d="M 80,123 L 80,138"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_D])}
              className={checkFocusedArrow(formik, [LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="85"
                y="133"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="83"
                  y="133"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathDotted d="M 63,65 L 165,88" className={styles.dotted} />
            <StyledPathDotted d="M 63,65 L 63,145" className={styles.dotted} />
            <StyledPathDotted
              d="M 165,88 L 165,113"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 90,150 L 110,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="115" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 150,150 L 170,150" /><text fontSize="0.4em" x="175" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsE(), 1):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 60,147 L 90,147" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 57,60 L 57,145" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurC ? (
              <StyledPath1 d="M 60,58 L 170,83" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurD ? (
              <StyledPath1 d="M 172.5,85 L 172.5,120" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurE ? (
              <StyledPath1 d="M 141,122 L 170,122" className={styles.couleurEcaille} />
            ) : null}

            {formeE(145, 115)}
            <StyledRect2 x="75" y="130" width="15" height="15" />
            <StyledRect2 x="140.5" y="105" width="30" height="15" />

            <StyledPathFleche
              d="M 66,149 L 84,149"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="75"
                y="155"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="73"
                  y="155"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            <StyledPathCoteDotted d="M 59,57 L 59,59" />
            <StyledPathCoteDotted d="M 171,57 L 171,83" />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,67 L 55,139"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="45"
                y="105"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="50"
                  y="105"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 137,111 L 137,115"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="132"
                y="115"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="133"
                  y="115"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,92 L 175,114"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_D])}
              className={checkFocusedArrow(formik, [LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="106"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="106"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 148,124 L 164,124"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_E])}
              className={checkFocusedArrow(formik, [LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="156"
                y="131"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="154"
                  y="131"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathCoteDotted d="M 76,120.5 L 140,120.5" />
            <StyledPathCoteDotted d="M 93,145 L 170,145" />
            <StyledPathFleche
              d="M 94,127 L 94,138"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_F])}
              className={checkFocusedArrow(formik, [LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="98"
                y="134"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="97"
                  y="134"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathDotted d="M 63,65 L 165,88" className={styles.dotted} />
            <StyledPathDotted d="M 63,65 L 63,145" className={styles.dotted} />
            <StyledPathDotted d="M 63,141 L 85,141" className={styles.dotted} />
            <StyledPathDotted
              d="M 165,88 L 165,115"
              className={styles.dotted}
            />
            <StyledPathDotted
              d="M 145,115 L 165,115"
              className={styles.dotted}
            />

<StyledPathDotted
              d="M 105,150 L 125,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="130" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 150,150 L 170,150" /><text fontSize="0.4em" x="175" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );
      default:
        break;
    }
  };

  const afficherFormeEIt1 = () => {
    switch (true) {
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymE(), 0):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 57,60 L 57,125" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 60,58 L 170,83" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurC ? (
              <StyledPath1 d="M 172.5,85 L 172.5,125" className={styles.couleurEcaille} />
            ) : null}
            {formeE(125, 125)}
            <StyledPathFleche
              d="M 65,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            <StyledPathCoteDotted d="M 59,57 L 59,59" />
            <StyledPathCoteDotted d="M 171,57 L 171,83" />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,67 L 55,119"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="48"
                y="95"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="48"
                  y="95"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,130 L 70,130"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="67.5"
                y="137"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="65"
                  y="137"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,92 L 175,119"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="109"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="109"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 63,65 L 165,88" className={styles.dotted} />
            {/* ligne pointillée gauche */}
            <StyledPathDotted d="M 63,65 L 63,123" className={styles.dotted} />
            {/* ligne pointillée droite */}
            <StyledPathDotted
              d="M 165,88 L 165,123"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );

      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymE(), 1):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 60,137 L 99,137" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 57,60 L 57,135" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurC ? (
              <StyledPath1 d="M 60,58 L 170,83" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurD ? (
              <StyledPath1 d="M 172.5,85 L 172.5,135" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurE ? (
              <StyledPath1 d="M 131,137 L 170,137" className={styles.couleurEcaille} />
            ) : null}

            {formeE(125, 125)}
            <StyledRect2 x="59.5" y="120" width="40" height="15" />
            <StyledRect2 x="130.5" y="120" width="40" height="15" />
            <StyledPathFleche
              d="M 65,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            <StyledPathCoteDotted d="M 59,57 L 59,59" />
            <StyledPathCoteDotted d="M 171,57 L 171,83" />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,67 L 55,129"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="45"
                y="100"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="52"
                  y="100"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,140 L 93,140"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="79"
                y="146"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="78"
                  y="146"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,92 L 175,129"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_D])}
              className={checkFocusedArrow(formik, [LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="113"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="113"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 137,140 L 165,140"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_E])}
              className={checkFocusedArrow(formik, [LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="151"
                y="146"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="149"
                  y="146"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 104,126 L 104,130"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="108.5"
                y="130"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="107"
                  y="130"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 63,65 L 165,88" className={styles.dotted} />
            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 63,65 L 63,131" className={styles.dotted} />
            {/* ligne pointillée gauche horizontale*/}
            <StyledPathDotted
              d="M 62.5,131 L 96,131"
              className={styles.dotted}
            />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 165,88 L 165,131"
              className={styles.dotted}
            />
            {/* ligne pointillée droite horizontale*/}
            <StyledPathDotted
              d="M 134,131 L 166,131"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );

      default:
        break;
    }
  };

  const formeFIt2 = () => {
    return (
      <>
        <StyledPolygon
          points="113,62 184,97 184,142 170,142 170,106 113,77.5 75,100 75,125 61,125
         61,93 113,62"
        />
      </>
    );
  };

  const formeFIt1 = (hauteurCote) => {
    return (
      <>
        <StyledPolygon
          points={
            "113,62 165,93 165," +
            hauteurCote +
            " 151," +
            hauteurCote +
            " 151,100 113,77.5 75,100 75," +
            hauteurCote +
            " 61," +
            hauteurCote +
            " 61,93 113,62"
          }
        />
      </>
    );
  };

  const afficherFormeFIt1 = () => {
    switch (true) {
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymF(), 0):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 59.5,93 L 59.5,125" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurC ? (
              <StyledPath1 d="M 60,91.5 L 113,60 L 165.5,91" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurAPrime ? (
              <StyledPath1 d="M 167,93 L 167,125" className={styles.couleurEcaille} />
            ) : null}

            {formeFIt1(125)}
            <StyledPathFleche
              d="M 58,99 L 58,119"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="112"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="55"
                  y="112"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 47,69 L 47,106"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            <StyledPathFleche
              d="M 47,114 L 47,119"
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="41"
                y="97"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="44"
                  y="97"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 66,55 L 160,55"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="113"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 67,128 L 69,128"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="69"
                y="135"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="67"
                  y="135"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            {/* ligne pointillée membre central droit */}
            <StyledPathDotted d="M 114,67 L 162,95" className={styles.dotted} />
            {/* ligne pointillée membre central gauche*/}
            <StyledPathDotted d="M 65,95 L 115,67" className={styles.dotted} />
            {/* ligne pointillée membre gauche verticale*/}
            <StyledPathDotted d="M 65,95 L 65,121" className={styles.dotted} />
            {/* ligne pointillée membre droit verticale */}
            <StyledPathDotted
              d="M 161,95 L 161,121"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );

      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymF(), 1):
        return (
          <>
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 59.5,93 L 59.5,145" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurD ? (
              <StyledPath1 d="M 60,91.5 L 113,60 L 165.5,91" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurBPrime ? (
              <StyledPath1 d="M 167,93 L 167,145" className={styles.couleurEcaille} />
            ) : null}

            {formeFIt1(130)}
            <StyledRect2 x="61" y="130" width="35" height="15" />
            <StyledRect2 x="130" y="130" width="35" height="15" />

            <StyledPathFleche
              d="M 67,148 L 90,148"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="78"
                y="154"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="77"
                  y="154"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 58,99 L 58,140"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="120"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="55"
                  y="120"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 47,69 L 47,114"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            <StyledPathFleche
              d="M 47,122 L 47,140"
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="41"
                y="97"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="44"
                  y="97"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 66,55 L 160,55"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_D])}
              className={checkFocusedArrow(formik, [LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="113"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 99,136 L 99,139"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="103"
                y="140"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="103"
                  y="140"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            {/* ligne pointillée membre central droit */}
            <StyledPathDotted d="M 114,67 L 162,95" className={styles.dotted} />
            {/* ligne pointillée membre central gauche*/}
            <StyledPathDotted d="M 65,95 L 114,67" className={styles.dotted} />
            {/* ligne pointillée membre gauche verticale*/}
            <StyledPathDotted d="M 65,95 L 65,140" className={styles.dotted} />
            {/* ligne pointillée membre gauche horizontale*/}
            <StyledPathDotted d="M 65,139 L 95,139" className={styles.dotted} />
            {/* ligne pointillée membre droit verticale */}
            <StyledPathDotted
              d="M 161,95 L 161,140"
              className={styles.dotted}
            />
            {/* ligne pointillée membre droit horizontale */}
            <StyledPathDotted
              d="M 161,139 L 130,139"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 80,160 L 100,160"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="105" y="161.5" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,160 L 150,160" /><text fontSize="0.4em" x="155" y="161.5" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );
      default:
        break;
    }
  };

  const afficherFormeFIt2 = () => {
    switch (true) {
      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsF(), 0):
        return (
          <>
            {isEcaillesLongueurA ? (
              <StyledPath1 d="M 59.5,93 L 59.5,125" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurD ? (
              <StyledPath1 d="M 60,91.5 L 113,60 L 184,95" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurE ? (
              <StyledPath1 d="M 185.5,97 L 185.5,142" className={styles.couleurEcaille} />
            ) : null}

            {formeFIt2()}

            <StyledPathFleche
              d="M 58,99 L 58,119"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="112"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="55"
                  y="112"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 47,69 L 47,106"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            <StyledPathFleche
              d="M 47,114 L 47,119"
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="41"
                y="97"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="44"
                  y="97"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 62,60 L 108,60"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="83"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="81"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 62,49 L 178,49"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_D])}
              className={checkFocusedArrow(formik, [LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="123"
                y="46"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="121"
                  y="46"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 188,103 L 188,136"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_E])}
              className={checkFocusedArrow(formik, [LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="192"
                y="120"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="191"
                  y="120"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 198,67 L 198,114"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_F])}
              className={checkFocusedArrow(formik, [LONGUEUR_F], styles)}
            />
            <StyledPathFleche
              d="M 198,121 L 198,136"
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_F])}
              className={checkFocusedArrow(formik, [LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="203"
                y="102"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="202"
                  y="102"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 67,128 L 69,128"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="69"
                y="135"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="67"
                  y="135"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            {/* ligne pointillée membre central droit */}
            <StyledPathDotted d="M 114,67 L 180,99" className={styles.dotted} />
            {/* ligne pointillée membre central gauche*/}
            <StyledPathDotted d="M 65,96 L 115,67" className={styles.dotted} />
            {/* ligne pointillée membre gauche verticale*/}
            <StyledPathDotted d="M 65,96 L 65,121" className={styles.dotted} />
            {/* ligne pointillée membre droit verticale */}
            <StyledPathDotted
              d="M 180,99 L 180,140"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 70,150 L 90,150"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="95" y="153" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 130,150 L 150,150" /><text fontSize="0.4em" x="155" y="153" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );

      case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsF(), 1):
        return (
          <>
            {isEcaillesLongueurB ? (
              <StyledPath1 d="M 59.5,93 L 59.5,136" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurE ? (
              <StyledPath1 d="M 60,91.5 L 113,60 L 184,95" className={styles.couleurEcaille} />
            ) : null}
            {isEcaillesLongueurF ? (
              <StyledPath1 d="M 185.5,97 L 185.5,152" className={styles.couleurEcaille} />
            ) : null}

            {formeFIt2()}
            <StyledRect2 x="61" y="121" width="40" height="15" />
            <StyledRect2 x="144" y="137" width="40" height="15" />

            <StyledPathFleche
              d="M 67,140 L 95,140"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_A])}
              className={checkFocusedArrow(formik, [LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="81"
                y="148"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="79"
                  y="148"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 58,99 L 58,131"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_B])}
              className={checkFocusedArrow(formik, [LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="112"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="55"
                  y="112"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 47,69 L 47,106"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            <StyledPathFleche
              d="M 47,114 L 47,131"
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_C])}
              className={checkFocusedArrow(formik, [LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="41"
                y="97"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="44"
                  y="97"
                  direction="rtl"
                  className={checkFocusedText(formik, [LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,60 L 108,60"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_D])}
              className={checkFocusedArrow(formik, [LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="83"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="81"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,49 L 178,49"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_E])}
              className={checkFocusedArrow(formik, [LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="123"
                y="46"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="121"
                  y="46"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 188,103 L 188,146"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_F])}
              className={checkFocusedArrow(formik, [LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="192"
                y="120"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="191"
                  y="120"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 198,67 L 198,114"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_G])}
              className={checkFocusedArrow(formik, [LONGUEUR_G], styles)}
            />
            <StyledPathFleche
              d="M 198,121 L 198,146"
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_G])}
              className={checkFocusedArrow(formik, [LONGUEUR_G], styles)}
            />
            {longueurG === "" ? (
              <text
                fontSize="0.4em"
                x="203"
                y="102"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_G], styles)}
              >
                G
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="202"
                  y="102"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_G], styles)}
                >
                  {longueurG}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 150,155 L 178,155"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_H])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_H])}
              className={checkFocusedArrow(formik, [LONGUEUR_H], styles)}
            />
            {longueurH === "" ? (
              <text
                fontSize="0.4em"
                x="164"
                y="162"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_H], styles)}
              >
                H
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="163"
                  y="162"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_H], styles)}
                >
                  {longueurH}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 105,127 L 105,130"
              markerStart={checkFocusedStartMarker(formik, [LONGUEUR_X])}
              markerEnd={checkFocusedEndMarker(formik, [LONGUEUR_X])}
              className={checkFocusedArrow(formik, [LONGUEUR_X], styles)}
            />
            {longueurX === "" ? (
              <text
                fontSize="0.4em"
                x="109"
                y="130"
                textAnchor="middle"
                className={checkFocusedText(formik, [LONGUEUR_X], styles)}
              >
                X
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="108"
                  y="130"
                  direction="ltr"
                  className={checkFocusedText(formik, [LONGUEUR_X], styles)}
                >
                  {longueurX}
                </text>
              </>
            )}

            {/* ligne pointillée membre central droit */}
            <StyledPathDotted d="M 114,67 L 180,99" className={styles.dotted} />
            {/* ligne pointillée membre central gauche*/}
            <StyledPathDotted d="M 65,96 L 115,67" className={styles.dotted} />
            {/* ligne pointillée membre gauche verticale*/}
            <StyledPathDotted d="M 65,96 L 65,135" className={styles.dotted} />
            {/* ligne pointillée membre gauche horizontale*/}
            <StyledPathDotted d="M 65,132 L 95,132" className={styles.dotted} />
            {/* ligne pointillée membre droit verticale */}
            <StyledPathDotted
              d="M 180,99 L 180,148"
              className={styles.dotted}
            />
            {/* ligne pointillée membre droit horizontale */}
            <StyledPathDotted
              d="M 148,148 L 180,148"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 45,155 L 65,155"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="70" y="157" direction="ltr">
              {t?.titreLegendeToile}
            </text>

            {
              isEcailles ? (
                <><StyledPathEcaille
                  d="M 90,155 L 110,155" /><text fontSize="0.4em" x="115" y="157" direction="ltr">
                    { t.titreLegendeEcaille }
                  </text></>
              ) : null

            }
          </>
        );
      default:
        break;
    }
  };

  const afficherFormeFace = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        0
      ):
        return <>{afficherFormeA()}</>;
      case equalsCodeOrLabel(
        formik[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        1
      ):
        return <>{afficherFormeB()}</>;
      case equalsCodeOrLabel(
        formik[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        2
      ):
        return <>{afficherFormeC()}</>;
      case equalsCodeOrLabel(
        formik[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        3
      ):
        switch (sectionSymetrique) {
          case 1:
            return <>{afficherFormeDIt1()}</>;
          case 0:
            return <>{afficherFormeDIt2()}</>;
          default:
            return null;
        }

      case equalsCodeOrLabel(
        formik[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        4
      ):
        switch (sectionSymetrique) {
          case 1:
            return <>{afficherFormeEIt1()}</>;
          case 0:
            return <>{afficherFormeEIt2()}</>;
          default:
            return null;
        }

      case equalsCodeOrLabel(
        formik[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        5
      ):
        switch (sectionSymetrique) {
          case 1:
            return <>{afficherFormeFIt1()}</>;
          case 0:
            return <>{afficherFormeFIt2()}</>;
          default:
            return null;
        }
      default:
        break;
    }
  };

  return (
    <Paper elevation={0}>
      <svg viewBox="-10 40 255 130">
        <defs>
          <marker
            id="startarrow"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 2, 10 5, 0 3.5" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 2, 10 3.5, 0 5" />
          </marker>
          <marker
            id="startarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="10 2, 10 5, 0 3.5"
              className={styles.markerSelected}
            />
          </marker>
          <marker
            id="endarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon
              points="0 2, 10 3.5, 0 5"
              className={styles.markerSelected}
            />
          </marker>
        </defs>

        <title></title>

        {afficherFormeFace()}
        {/* <image href={importAll()["F1S_SVG.png"]} height="90%" width="100%" x="-15" y="45"/> */}
      </svg>
    </Paper>
  );
};

const findFormeCorpsFromTypeSection = (payload) => {
  const symetrique = equalsBooleanOrStringOrNumber(
    payload[SECTION_SYMETRIQUE.description]
  );
  switch (true) {
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      0
    ):
      return payload[FORME_CORPS_A.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      1
    ):
      return payload[FORME_CORPS_B.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      2
    ):
      return payload[FORME_CORPS_C.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      3
    ):
      return !!symetrique
        ? payload[FORME_CORPS_DS.description]
        : payload[FORME_CORPS_D.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      4
    ):
      return !!symetrique
        ? payload[FORME_CORPS_ES.description]
        : payload[FORME_CORPS_E.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      5
    ):
      return !!symetrique
        ? payload[FORME_CORPS_FS.description]
        : payload[FORME_CORPS_F.description];
    default:
      return payload[FORME_CORPS_A.description];
  }
};
