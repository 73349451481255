import * as Yup from "yup";
import {
  arraySectionExtremite,
  arrayTypeExtremites,
} from "../../../../../constantes/symbols/SymbolsServicesS01";
import {
  BRIDE_A_IT1,
  BRIDE_A_IT2,
  BRIDE_B_IT1,
  BRIDE_B_IT2,
  BRIDE_DIAMETRE_IT1,
  BRIDE_DIAMETRE_IT2,
  BRIDE_IT1,
  BRIDE_IT2,
  BRIDE_PERCEE_IT1,
  BRIDE_PERCEE_IT2,
  EXTREMITE_IDENTIQUE,
  FIXATION_EXTREMITES_IT1,
  FIXATION_EXTREMITES_IT2,
  JOINDRE_PLAN_BRIDE_PERCEE_IT1,
  JOINDRE_PLAN_BRIDE_PERCEE_IT2,
  MANCHETTE_A_IT1,
  MANCHETTE_A_IT2,
  MANCHETTE_B_IT1,
  MANCHETTE_B_IT2,
  MANCHETTE_DIAMETRE_IT1,
  MANCHETTE_DIAMETRE_IT2,
  MANCHETTE_IT1,
  MANCHETTE_IT2,
  MANCHETTE_LARGEUR_IT1,
  MANCHETTE_LARGEUR_IT2,
  TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT2,
} from "../../../../../constantes/symbols/SymbolsS01";
import {
  fixationExtremitesBride,
  fixationExtremitesManchette,
  fixationExtremitesManchetteEtBride,
} from "./ChampsDimensionsExtremites";

/* Setting the maximum size of a file to 10MB. */
const FILE_SIZE = 10000000;
/* A constant that contains the list of supported formats. */
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/bmp",
  "image/png",
];

const otherwiseSchema = Yup.mixed().optional();

const testPositiveAndMin = (t) => {
  return Yup.number().min(1, t.superieurA0).required(t.renseignerChamp);
};

const testPositif = (t) => {
  return Yup.number().min(0, t.positif).required(t.renseignerChamp);
};

/* A Yup validation schema. */
export const validDimensionsExtremites = (t, etape) => {
  if (etape !== 5) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    //IT1
    [TYPE_EXTREMITES_IT1.description]: Yup.mixed()
      .test(
        "RadioButtonTypeExtremitesIt1",
        () => {
          return t.renseignerChamp;
        },
        (selectedButton) => {
          return arrayTypeExtremites(t).some((e) => e.code === selectedButton);
        }
      )
      .required(t.renseignerChamp),

    [MANCHETTE_IT1.description]: Yup.mixed().when(
      [TYPE_EXTREMITES_IT1.description].toString(),
      {
        is: (type) =>
          type === arrayTypeExtremites()[0].code ||
          type === arrayTypeExtremites()[2].code,
        then: Yup.mixed()
          .test(
            "RadioButtonSectionManchetteIt1",
            () => {
              return t.renseignerChamp;
            },
            (selectedButton) =>
              arraySectionExtremite(t).some((e) => e.code === selectedButton)
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [MANCHETTE_LARGEUR_IT1.description]: Yup.mixed().when(
      [TYPE_EXTREMITES_IT1.description].toString(),
      {
        is: (type) =>
          type === arrayTypeExtremites()[0].code ||
          type === arrayTypeExtremites()[2].code,
        then: testPositif(t),
        otherwise: otherwiseSchema,
      }
    ),

    [MANCHETTE_DIAMETRE_IT1.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT1.description].toString(),
        [MANCHETTE_IT1.description].toString(),
      ],
      {
        is: (type, section) =>
          (type === arrayTypeExtremites()[0].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[0].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [MANCHETTE_A_IT1.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT1.description].toString(),
        [MANCHETTE_IT1.description].toString(),
      ],
      {
        is: (type, section) =>
          (type === arrayTypeExtremites()[0].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[1].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [MANCHETTE_B_IT1.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT1.description].toString(),
        [MANCHETTE_IT1.description].toString(),
      ],
      {
        is: (type, section) =>
          (type === arrayTypeExtremites()[0].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[1].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_IT1.description]: Yup.mixed().when(
      [TYPE_EXTREMITES_IT1.description].toString(),
      {
        is: (type) =>
          type === arrayTypeExtremites()[1].code ||
          type === arrayTypeExtremites()[2].code,
        then: Yup.mixed()
          .test(
            "RadioButtonSectionBrideIt1",
            () => {
              return t.renseignerChamp;
            },
            (selectedButton) => {
              return arraySectionExtremite(t).some(
                (e) => e.code === selectedButton
              );
            }
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_DIAMETRE_IT1.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT1.description].toString(),
        [BRIDE_IT1.description].toString(),
      ],
      {
        is: (type, section) =>
          (type === arrayTypeExtremites()[1].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[0].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_A_IT1.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT1.description].toString(),
        [BRIDE_IT1.description].toString(),
      ],
      {
        is: (type, section) =>
          (type === arrayTypeExtremites()[1].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[1].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_B_IT1.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT1.description].toString(),
        [BRIDE_IT1.description].toString(),
      ],
      {
        is: (type, section) =>
          (type === arrayTypeExtremites()[1].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[1].code,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [JOINDRE_PLAN_BRIDE_PERCEE_IT1.description]: Yup.mixed().when(
      [BRIDE_PERCEE_IT1.description].toString(),
      {
        is: (boolean) => boolean === 1,
        then: Yup.mixed()
          .test(
            "fileSize",
            (fileList) => {
              return (
                t.erreurFichierVolumineux +
                getNameFilesTooBig(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFilesTooBig(fileList).length === 0;
            }
          )
          .test(
            "fileCount",
            (fileList) => {
              return t.erreurFichierMaxAtteint + fileList.length;
            },
            (fileList) => {
              return fileList.length <= 10;
            }
          )
          .test(
            "fileFormat",
            (fileList) => {
              return (
                t.erreurFormatFichier +
                getNameFileWrongFormat(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFileWrongFormat(fileList).length === 0;
            }
          ),
        /*.test(
                        'fileCountZero',
                        'Merci de fournir un plan dans la dropbox ci-dessus.',
                        (fileList) => !fileList.length===0
                    )*/ otherwise: otherwiseSchema,
      }
    ),

    [FIXATION_EXTREMITES_IT1.description]: Yup.mixed()
      .when([TYPE_EXTREMITES_IT1.description].toString(), {
        is: (type) => type === arrayTypeExtremites()[0].code,
        then: Yup.mixed().test(
          "fixationManchette",
          () => {
            return t.erreurSelectionFixation;
          },
          (radioButton) =>
            fixationExtremitesManchette(t).some(
              (e) => e.code === parseInt(radioButton)
            )
        ),
        otherwise: otherwiseSchema,
      })
      .when([TYPE_EXTREMITES_IT1.description].toString(), {
        is: (type) => type === arrayTypeExtremites()[1].code,
        then: Yup.mixed().test(
          "fixationBride",
          () => t.erreurSelectionFixation,
          (radioButton) =>
            fixationExtremitesBride(t).some(
              (e) => e.code === parseInt(radioButton)
            )
        ),
        otherwise: otherwiseSchema,
      })
      .when([TYPE_EXTREMITES_IT1.description].toString(), {
        is: (type) => type === arrayTypeExtremites()[2].code,
        then: Yup.mixed().test(
          "fixationManchetteEtBride",
          () => {
            return t.erreurSelectionFixation;
          },
          (radioButton) =>
            fixationExtremitesManchetteEtBride(t).some(
              (e) => e.code === parseInt(radioButton)
            )
        ),
        otherwise: otherwiseSchema,
      }),

    //IT2

    [TYPE_EXTREMITES_IT2.description]: Yup.mixed().when(
      [EXTREMITE_IDENTIQUE.description].toString(),
      {
        is: (boolean) => boolean === 0,
        then: Yup.mixed()
          .test(
            "RadioButtonTypeExtremitesIt2",
            () => {
              return t.renseignerChamp;
            },
            (selectedButton) =>
              arrayTypeExtremites(t).some((e) => e.code === selectedButton)
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [MANCHETTE_IT2.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT2.description].toString(),
        [EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, boolean) =>
          (type === arrayTypeExtremites()[0].code ||
            type === arrayTypeExtremites()[2].code) &&
          boolean === 0,
        then: Yup.mixed()
          .test(
            "RadioButtonSectionManchetteIt2",
            () => {
              return t.renseignerChamp;
            },
            (selectedButton) =>
              arraySectionExtremite(t).some((e) => e.code === selectedButton)
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [MANCHETTE_LARGEUR_IT2.description]: Yup.mixed().when(
      [
        [EXTREMITE_IDENTIQUE.description].toString(),
        [TYPE_EXTREMITES_IT2.description].toString(),
      ],
      {
        is: (boolean, type) =>
          boolean === 0 &&
          (type === arrayTypeExtremites()[0].code ||
            type === arrayTypeExtremites()[2].code),
        then: testPositif(t),
        otherwise: otherwiseSchema,
      }
    ),

    [MANCHETTE_DIAMETRE_IT2.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT2.description].toString(),
        [MANCHETTE_IT2.description].toString(),
        [EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, section, boolean) =>
          (type === arrayTypeExtremites()[0].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[0].code &&
          boolean === 0,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [MANCHETTE_A_IT2.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT2.description].toString(),
        [MANCHETTE_IT2.description].toString(),
        [EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, section, boolean) =>
          (type === arrayTypeExtremites()[0].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[1].code &&
          boolean === 0,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [MANCHETTE_B_IT2.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT2.description].toString(),
        [MANCHETTE_IT2.description].toString(),
        [EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, section, boolean) =>
          (type === arrayTypeExtremites()[0].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[1].code &&
          boolean === 0,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_IT2.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT2.description].toString(),
        [EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, boolean) =>
          (type === arrayTypeExtremites()[1].code ||
            type === arrayTypeExtremites()[2].code) &&
          boolean === 0,
        then: Yup.mixed()
          .test(
            "RadioButtonSectionBrideIt2",
            () => {
              return t.renseignerChamp;
            },
            (selectedButton) =>
              arraySectionExtremite(t).some((e) => e.code === selectedButton)
          )
          .required(t.renseignerChamp),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_DIAMETRE_IT2.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT2.description].toString(),
        [BRIDE_IT2.description].toString(),
        [EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, section, boolean) =>
          (type === arrayTypeExtremites()[1].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[0].code &&
          boolean === 0,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_A_IT2.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT2.description].toString(),
        [BRIDE_IT2.description].toString(),
        [EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, section, boolean) =>
          (type === arrayTypeExtremites()[1].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[1].code &&
          boolean === 0,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [BRIDE_B_IT2.description]: Yup.mixed().when(
      [
        [TYPE_EXTREMITES_IT2.description].toString(),
        [BRIDE_IT2.description].toString(),
        [EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (type, section, boolean) =>
          (type === arrayTypeExtremites()[1].code ||
            type === arrayTypeExtremites()[2].code) &&
          section === arraySectionExtremite(t)[1].code &&
          boolean === 0,
        then: testPositiveAndMin(t),
        otherwise: otherwiseSchema,
      }
    ),

    [JOINDRE_PLAN_BRIDE_PERCEE_IT2.description]: Yup.mixed().when(
      [
        [BRIDE_PERCEE_IT2.description].toString(),
        [EXTREMITE_IDENTIQUE.description].toString(),
      ],
      {
        is: (boolean1, boolean2) => boolean1 === 1 && boolean2 === 0,
        then: Yup.mixed()
          .test(
            "fileSize",
            (fileList) => {
              return (
                t.erreurFichierVolumineux +
                getNameFilesTooBig(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFilesTooBig(fileList).length === 0;
            }
          )
          .test(
            "fileCount",
            (fileList) => {
              return t.erreurFichierMaxAtteint + fileList.length;
            },
            (fileList) => {
              return fileList.length <= 10;
            }
          )
          .test(
            "fileFormat",
            (fileList) => {
              return (
                t.erreurFormatFichier +
                getNameFileWrongFormat(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFileWrongFormat(fileList).length === 0;
            }
          ),
        /*.test(
                        'fileCountZero',
                        'Merci de fournir un plan dans la dropbox ci-dessus.',
                        (fileList) => !fileList.length===0
                    )*/ otherwise: otherwiseSchema,
      }
    ),

    [FIXATION_EXTREMITES_IT2.description]: Yup.mixed()
      .when(
        [
          [TYPE_EXTREMITES_IT2.description].toString(),
          [EXTREMITE_IDENTIQUE.description].toString(),
        ],
        {
          is: (type, boolean) =>
            type === arrayTypeExtremites()[0].code && boolean === 0,
          then: Yup.mixed().test(
            "fixationManchette",
            t.erreurSelectionFixation,
            (radioButton) =>
              fixationExtremitesManchette(t).some(
                (e) => e.code === parseInt(radioButton)
              )
          ),
          otherwise: otherwiseSchema,
        }
      )
      .when(
        [
          [TYPE_EXTREMITES_IT2.description].toString(),
          [EXTREMITE_IDENTIQUE.description].toString(),
        ],
        {
          is: (type, boolean) =>
            type === arrayTypeExtremites()[1].code && boolean === 0,
          then: Yup.mixed().test(
            "fixationBride",
            t.erreurSelectionFixation,
            (radioButton) =>
              fixationExtremitesBride(t).some(
                (e) => e.code === parseInt(radioButton)
              )
          ),
          otherwise: otherwiseSchema,
        }
      )
      .when(
        [
          [TYPE_EXTREMITES_IT2.description].toString(),
          [EXTREMITE_IDENTIQUE.description].toString(),
        ],
        {
          is: (type, boolean) =>
            type === arrayTypeExtremites()[2].code && boolean === 0,
          then: Yup.mixed().test(
            "fixationManchetteEtBride",
            () => {
              return t.erreurSelectionFixation;
            },
            (radioButton) =>
              fixationExtremitesManchetteEtBride(t).some(
                (e) => e.code === parseInt(radioButton)
              )
          ),
          otherwise: otherwiseSchema,
        }
      ),
  });
};

/**
 * Given a list of files, return a list of files that are too big
 * @param listFiles - the list of files to be filtered
 * @returns An array of objects.
 */
const getNameFilesTooBig = (listFiles) => {
  return listFiles.filter((file) => file.size > FILE_SIZE);
};

/**
 * Given a list of files, return a list of files that are not of a supported format
 * @param listFiles - The list of files to be filtered.
 * @returns An array of files that are not supported.
 */
const getNameFileWrongFormat = (listFiles) => {
  return listFiles.filter((file) => !SUPPORTED_FORMATS.includes(file.type));
};
