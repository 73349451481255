import { initChampsFormikToJsonDefaultArray, initChampsFormikToJsonDefaultAutocomplete, initChampsFormikToJsonDefaultString, initChampsFormikToJsonMultiIteratifDefaultString } from "../../../../helper/InitialisationChampsFormik";
import { CP, DEMANDE, EMAIL, FONCTION, NOM, PAYS, PRENOM, SECTEUR, SOCIETE, TEL } from "../../enum/champs/ChampsCommunFormulairesEnum";
import { ARTICLES, CAP, DROPZONE_RECHERCHER, NDDP, NUM_OFFRE, QUANTITEE_SOUHAITEE_CODE_ARTICLE } from "../RechercherChamps";

const champString = [
    NDDP,
    NUM_OFFRE,
    DEMANDE,
    SOCIETE,
    CP,
    NOM,
    PRENOM,
    EMAIL,
    TEL
];

const champsAutocomplete = [
    PAYS,
    SECTEUR,
    FONCTION
];

const champsIteration = [
    QUANTITEE_SOUHAITEE_CODE_ARTICLE,
    CAP
];

export const initialValuesRechercher = (formulaire, nbChamps, t) => {
    return Object.assign({
        ...initChampsFormikToJsonDefaultString(champString, formulaire),
        ...initChampsFormikToJsonDefaultArray([DROPZONE_RECHERCHER], formulaire),
        ...initChampsFormikToJsonDefaultAutocomplete(champsAutocomplete, formulaire),
        ...initChampsFormikToJsonMultiIteratifDefaultString(champsIteration, formulaire, nbChamps, ARTICLES)
    }, {});
}