export const LogoutIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 32 32" style={{ fill: "none" }}>
      <path
        d="M17.9947 28H8.66667C7.19333 28 6 26.4653 6 24.572V7.42667C6 5.53467 7.19333 4 8.66667 4H18M21.3333 20.6667L26 16L21.3333 11.3333M12.6667 15.9947H26"
        style={{
          stroke: "#2A375C",
          strokeWidth: 1.2,
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
      />
    </svg>
  );
};
