import { OUVETRURE } from "../../../../../constantes/symbols/SymbolsS06";
import { initChampsFormikToJsonDefaultCustom } from "../../../../../helper/InitialisationChampsFormik";

const champsArray = [OUVETRURE];

export const initialValuesAccessoiresS06 = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultCustom(champsArray, formulaire, "1"),
    },
    {}
  );
};
