import {
  S08_LONGUEUR_1ER_ELEMENT,
  S08_LONGUEUR_COURSE,
  S08_LONGUEUR_MAX,
  S08_LONGUEUR_MIN,
  S08_NOMBRE_ELEMENTS,
} from "../../../../../constantes/symbols/SymbolsS08";
import {
  TEXTFIELD_NUMBER,
  TEXTFIELD,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

export const formulaireDimensionsS08 = (styles, t, formik) => [
  {
    nom: S08_LONGUEUR_MAX,
    label: t.longueurDeployee+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0073_Q00020,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_LONGUEUR_MIN,
    label: t.longueurComprimee+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0073_Q00030,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_LONGUEUR_COURSE,
    label: t.champlongueurCourse,
    value:
      formik.values[S08_LONGUEUR_MAX.description] -
      formik.values[S08_LONGUEUR_MIN.description],
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    disabled: true,
    textInformation: {
      text: t.SQ0073_Q00060,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_NOMBRE_ELEMENTS,
    label: t.nombreElements+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0073_Q00040,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_LONGUEUR_1ER_ELEMENT,
    label: t.longueur1erElement+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0073_Q00050,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];
