import { styled } from "@mui/styles";

export const StyledH2Home = styled('h2')({
    fontFamily: 'Dosis',
    fontSize: '1.125em',
    fontWeight: '600',
    textUnderlineOffset: '0.3em',
    color: '#2A375C',
    margin: '1.2em 2em 0.6em 0',
    '@media (max-width: 992px)':{
        margin: '1.2em 1em 0.6em 0',
    },
});