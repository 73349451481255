import * as Yup from "yup";

export const validAjouterUtilisateur = (t, roleList) =>
  Yup.object({
    prenom: Yup.string().required(t.champRequis),
    nom: Yup.string().required(t.champRequis),
    email: Yup.string().min(5, t.minimumCinqCaracteres).required(t.champRequis),
    motDePasse: Yup.string()
      .min(5, t.minimumCinqCaracteres)
      .required(t.champRequis),
    role: Yup.mixed()
      .test(
        "roleInRoleList",
        () => {
          return "La valeur selectionnée n'est pas dans la liste";
        },
        (value) => roleList.some((e) => parseInt(e.code) === parseInt(value))
      )
      .required(t.erreurSelectionType),
  });
