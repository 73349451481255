import { styled } from "@mui/system";

export const StyledHeaderHeader = styled('header')({
    backgroundColor: '#2A375C',
    fontFamily: 'Dosis',
    color: 'white',
    fontSize: '0.9em',
    borderRadius: '0',
    margin: '0',
    padding: '1em 2em',
    '& span:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    '& a': {
        color: 'white',
    },
    wrap: 'nowrap', 
    display: 'flex',

    '@media (max-width: 576px)': {
        padding: '0.5em 0',
    }
});