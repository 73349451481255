import {
  AUTRE,
  AXE_EMPLACEMENT,
  CODE_ARTICLE,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  NUMERO_DE_PLAN,
  POSITION_DE_TRAVAIL,
  TYPE_DE_MACHINE,
  VITESSE_DEPLACEMENT,
} from "../../../../../constantes/symbols/SymbolsCommon";
import { initChampsFormikToJsonDefaultString } from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
  TYPE_DE_MACHINE,
  MARQUE_MACHINE,
  AUTRE,
  MODELE_MACHINE,
  AXE_EMPLACEMENT,
  POSITION_DE_TRAVAIL,
  NUMERO_DE_PLAN,
  CODE_ARTICLE,
  VITESSE_DEPLACEMENT,
];

export const initialValuesInformationsMachine = (formulaire) => {
  return initChampsFormikToJsonDefaultString(champsString, formulaire);
};

// return Object.assign(
//   {
//     ...initChampsFormikToJsonDefaultString(champsString, formulaire),
//     ...initChampsFormikToJsonDefaultAutocomplete(
//       champsAutocomplete,
//       formulaire
//     ),
//   },
//   {}
// );
