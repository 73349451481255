import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../../../constantes/symbols/SymbolsCommon";
import {
  StyledDivAlignItemsHorizontally,
  StyledDivFormMarginTitle,
  StyledDivRadioButtonBoxShadow,
  StyledDivRadioButtonImage,
  StyledDivRadioWithImage,
  StyledDivCheckboxImage,
  StyledDivLabelQuestion,
  StyledDivFormSection,
  StyledDivLegend,
} from "../../../styledComponents/StyledDiv";
import { StyledFormLabelQuestion } from "../../../styledComponents/StyledFormLabel";
import { StyledGridRadio } from "../../../styledComponents/StyledGrid";
import {
  StyledRadio,
  StyledRadioWithoutAnimation,
} from "../../../styledComponents/StyledRadio";
import {
  displayErrorForElementsWithoutErrorHandling,
  CheckImageDisplayForRadioButton,
} from "../commonMethods/CommonMethods";
import {
  CONTAINED,
  OUTLINED_PRIMARY_BUTTON_GROUP,
} from "../commonParameters/CommonParameters";

/**
 * It generates a button group with 3 buttons.
 * @param champ - The name of the field in the formik state.
 * @param formik - The Formik instance.
 * @returns A button group with three buttons.
 */
const genererChampButtonGroupBoxShadow = (champ, formik, state) => {
  return (
    <StyledDivFormMarginTitle
      key={champ.nom.description}
      className={champ.cssMarginFormSection}
    >
      {champ.label ? (
        <StyledDivLabelQuestion className={champ.cssFontFamily}>
          {champ.label}
        </StyledDivLabelQuestion>
      ) : null}
      <StyledGridRadio item className={champ.cssGridRadio}>
        {champ?.donnees.map((radioButton) => {
          return (
            <StyledDivRadioButtonBoxShadow
              key={radioButton.code}
              onClick={() => {
                if (state) {
                  state.setFormulary(
                    Object.assign({}, state.formulary, {
                      [FAMILLE_PROTECTION_INDUSTRIELLE.description]:
                        radioButton.code,
                    })
                  );
                  state.setMaxReachedEtape(1);
                  state.setFamilleProt(radioButton.code);
                } else {
                  formik.setFieldValue(champ.nom.description, radioButton.code);
                }
                
              }}
              className={
                formik.values[champ.nom.description] === radioButton.code
                  ? champ.cssSurroundGridItem +
                    " " +
                    champ.cssRadioElementExtremites
                  : champ.cssRadioElementExtremites
              }
            >
              <StyledDivRadioButtonImage>
                <CheckImageDisplayForRadioButton
                  img={radioButton.img}
                  images={champ.images}
                  disabled={champ.disabled}
                />
              </StyledDivRadioButtonImage>
              <StyledDivAlignItemsHorizontally>
                <StyledRadioWithoutAnimation
                  name={champ.nom.description}
                  checked={
                    formik.values[champ.nom.description] === radioButton.code
                  }
                  value={radioButton.code}
                  disabled={champ.disabled}
                  className={
                    formik.values[champ.nom.description] === radioButton.code
                      ? champ.cssRadioPhoto
                      : null
                  }
                />
                <StyledDivLegend className={champ.cssFontFamily}>
                  {radioButton.name}
                </StyledDivLegend>
              </StyledDivAlignItemsHorizontally>
            </StyledDivRadioButtonBoxShadow>
          );
        })}
        {displayErrorForElementsWithoutErrorHandling(champ, formik)}
      </StyledGridRadio>
    </StyledDivFormMarginTitle>
  );
};

const genererChampButtonGroup = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <FormControl>
          <StyledFormLabelQuestion className={champ.cssFontFamily}>
            {champ.label}
          </StyledFormLabelQuestion>
          <RadioGroup
            row
            variant={CONTAINED.description}
            aria-label={OUTLINED_PRIMARY_BUTTON_GROUP.description}
            name={champ.nom.description}
            value={formik.values[champ.nom.description] ?? ""}
            className={champ.cssRadioColumn}
          >
            {champ?.donnees.map((radioButton) => {
              return (
                <div key={radioButton.code}>
                  <CheckImageDisplayForRadioButton
                    img={radioButton.img}
                    images={champ.images}
                    disabled={champ.disabled}
                  />
                  <FormControlLabel
                    value={radioButton.code}
                    control={<StyledRadio />}
                    labelPlacement="end"
                    label={
                      <StyledDivLegend className={champ.cssFontFamily}>
                        {radioButton.name.description || radioButton.name}
                      </StyledDivLegend>
                    }
                    disabled={champ.disabled}
                    onChange={formik.handleChange}
                  />
                </div>
              );
            })}
          </RadioGroup>
        </FormControl>
        {displayErrorForElementsWithoutErrorHandling(champ, formik)}
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampButtonGroupWithImage = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <FormControl>
          <StyledFormLabelQuestion className={champ.cssFontFamily}>
            {champ.label}
          </StyledFormLabelQuestion>
          <StyledDivRadioWithImage>
            <StyledDivCheckboxImage>
              <CheckImageDisplayForRadioButton
                img={champ.img}
                images={champ.images}
              />
            </StyledDivCheckboxImage>
            <RadioGroup
              row
              variant={CONTAINED.description}
              aria-label={OUTLINED_PRIMARY_BUTTON_GROUP.description}
              name={champ.nom.description}
              value={formik.values[champ.nom.description]}
            >
              {champ?.donnees.map((radioButton) => {
                return (
                  <div key={radioButton.code}>
                    <FormControlLabel
                      value={radioButton.code}
                      control={<StyledRadio />}
                      labelPlacement="end"
                      label={
                        <StyledDivLegend className={champ.cssFontFamily}>
                          {radioButton.name}
                        </StyledDivLegend>
                      }
                      disabled={champ.disabled}
                      onChange={formik.handleChange}
                    />
                  </div>
                );
              })}
            </RadioGroup>
          </StyledDivRadioWithImage>
        </FormControl>
        {displayErrorForElementsWithoutErrorHandling(champ, formik)}
      </Grid>
    </StyledDivFormSection>
  );
};

export {
  genererChampButtonGroup,
  genererChampButtonGroupBoxShadow,
  genererChampButtonGroupWithImage,
};
