import axios from 'axios';
import { Serveur } from '../../constantes/services/Serveur';


const getAllAgressions = async () => {
    
    const config = {
        headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    };

    return await axios.get(Serveur.URL_AGRESSIONS, config);

}

const agressionsService = {
    getAllAgressions,
}

export default agressionsService;

