import {
  BRIDE_A_IT1,
  BRIDE_A_IT2,
  BRIDE_B_IT1,
  BRIDE_B_IT2,
  BRIDE_DIAMETRE_IT1,
  BRIDE_DIAMETRE_IT2,
  BRIDE_IT1,
  BRIDE_IT2,
  JOINDRE_PLAN_BRIDE_PERCEE_IT1,
  JOINDRE_PLAN_BRIDE_PERCEE_IT2,
  LONGUEUR_COMPRIMEE,
  MANCHETTE_A_IT1,
  MANCHETTE_A_IT2,
  MANCHETTE_B_IT1,
  MANCHETTE_B_IT2,
  MANCHETTE_DIAMETRE_IT1,
  MANCHETTE_DIAMETRE_IT2,
  MANCHETTE_IT1,
  MANCHETTE_IT2,
  MANCHETTE_LARGEUR_IT1,
  MANCHETTE_LARGEUR_IT2,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
  RONDE_DROIT_EXTERIEUR_MAX,
  RONDE_DROIT_INTERIEUR_MIN,
} from "./SymbolsS01";

const arrayCaseRondDroit = () => [
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN.description,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX.description,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN.description,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX.description,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN.description,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN.description,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX.description,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX.description,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN.description,
];

const arrayCaseRectangulaireDroit = () => [
  RONDE_DROIT_INTERIEUR_MIN.description,
  RONDE_DROIT_EXTERIEUR_MAX.description,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN.description,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX.description,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN.description,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX.description,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN.description,
];

const arrayCaseRondConique = () => [
  RONDE_DROIT_INTERIEUR_MIN.description,
  RONDE_DROIT_EXTERIEUR_MAX.description,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN.description,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN.description,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX.description,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX.description,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN.description,
];

const arrayCaseRectangulaireConique = () => [
  RONDE_DROIT_INTERIEUR_MIN.description,
  RONDE_DROIT_EXTERIEUR_MAX.description,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN.description,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN.description,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX.description,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX.description,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN.description,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX.description,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN.description,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX.description,
];

const arrayRemoveManchetteIt1 = () => [
  MANCHETTE_IT1.description,
  MANCHETTE_LARGEUR_IT1.description,
  MANCHETTE_DIAMETRE_IT1.description,
  MANCHETTE_A_IT1.description,
  MANCHETTE_B_IT1.description,
];
const arrayRemoveManchetteIt2 = () => [
  MANCHETTE_IT2.description,
  MANCHETTE_LARGEUR_IT2.description,
  MANCHETTE_DIAMETRE_IT2.description,
  MANCHETTE_A_IT2.description,
  MANCHETTE_B_IT2.description,
];
const arrayRemoveBrideIt1 = () => [
  BRIDE_IT1.description,
  BRIDE_DIAMETRE_IT1.description,
  BRIDE_A_IT1.description,
  BRIDE_B_IT1.description,
  JOINDRE_PLAN_BRIDE_PERCEE_IT1.description,
];
const arrayRemoveBrideIt2 = () => [
  BRIDE_IT2.description,
  BRIDE_DIAMETRE_IT2.description,
  BRIDE_A_IT2.description,
  BRIDE_B_IT2.description,
  JOINDRE_PLAN_BRIDE_PERCEE_IT2.description,
];

const arrayCaseSouffletSansSpire = () => [
    RONDE_DROIT_EXTERIEUR_MAX.description,
    RONDE_CONIQUE_EXTERIEUR_DROIT_MAX.description,
    RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX.description,
    RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX.description,
    RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX.description,
    LONGUEUR_COMPRIMEE.description
];

export {
  arrayCaseRondDroit,
  arrayCaseRondConique,
  arrayCaseRectangulaireConique,
  arrayCaseRectangulaireDroit,
  arrayRemoveManchetteIt1,
  arrayRemoveManchetteIt2,
  arrayRemoveBrideIt1,
  arrayRemoveBrideIt2,
  arrayCaseSouffletSansSpire
};
