import * as Yup from "yup";
import { S06_LONGUEUR_COMPRIMEE, S06_LONGUEUR_DEPLOYEE, S06_TOILE } from "../../../../../constantes/symbols/SymbolsS06";
import { S06_STEP4_arrayToile } from "../../../../../constantes/symbols/SymbolsServicesS06";

const otherwiseSchema = Yup.mixed().optional();

const schemaNotZero = (error) => {
  return Yup.number().min(1, error);
};

const yupPlusPetit = (champ, erreur, errorInfZero) => {
  return Yup.number().when(champ.toString(), {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .lessThan(Yup.ref(champ.toString()), erreur),
  });
};

const yupPlusGrand = (champ, erreur, errorInfZero) => {
  return Yup.number().when(champ.toString(), {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .moreThan(Yup.ref(champ.toString()), erreur),
  });
};

/* A Yup validation schema. */
export const validConstructionS06 = (t, etape) => {
  if (etape !== 4) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [S06_LONGUEUR_COMPRIMEE.description]: Yup.lazy(() =>
      yupPlusPetit(
        [S06_LONGUEUR_DEPLOYEE.description],
        t.erreurLongueurComprimeePetit,
        t.superieurA0
      ).required(t.champRequis)
    ),

    [S06_LONGUEUR_DEPLOYEE.description]: Yup.lazy(() =>
      yupPlusGrand(
        [S06_LONGUEUR_COMPRIMEE.description],
        t.erreurLongueurComprimeeGrand,
        t.superieurA0
      ).required(t.champRequis)
    ),

    [S06_TOILE.description]: Yup.mixed().test(
      "ToileSelectionInSelect",
      () => {
        return t.erreurSelectionType;
      },
      (selectedValue) => {
        if ([null, undefined].includes(selectedValue)) {
          return true;
        }
        return S06_STEP4_arrayToile(t).some(
          (e) => e.code === parseInt(selectedValue)
        );
      }
    ),
    //.required(t.erreurSelectionType),

  });
};
