import axios from 'axios';
import { Serveur } from '../../constantes/services/Serveur';


const getAllPays = async () => {
    
    const config = {
        headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    };

    return await axios.get(Serveur.URL_PAYS, config);

}

const paysService = {
    getAllPays,
}

export default paysService;

