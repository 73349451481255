import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Button, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toBase64 } from "../../../helper/Base64Converter";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { StyledIconDropZone } from "../styledComponents/StyledIcon";
import { StyledButtonRetirerFichier } from "../styledComponents/StyledButton";
import RemoveIcon from "@mui/icons-material/Remove";
import { StyledDivDropzone } from "../styledComponents/StyledDiv";
import { HelperText } from "./HelperText";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  marginBottom: 8,
  marginRight: 8,
  marginLeft: "-2em",
  width: "120%",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

const setNameExtension = (file) => {
  return "." + file.split(".").slice(-1);
};

const setFileName = (file) => {
  return file.split(".").splice(-2, 1).join(".");
};

export const trimFileName = (text) => {
  const textWithoutNumbers = text.slice(0, -9);
  return textWithoutNumbers.length > 20
    ? textWithoutNumbers.slice(0, 8) +
        "..." +
        textWithoutNumbers.slice(-8) +
        setNameExtension(text)
    : textWithoutNumbers + setNameExtension(text);
};

/**
 * This is a dropzone component that allows you to upload files.
 * @returns The DropzoneContact component is returning a section element with a div element inside of
 * it. The div element is the dropzone. The dropzone is a div element that is styled with the
 * useDropzone hook. The useDropzone hook is a react-dropzone hook that allows the user to drag and
 * drop files into the dropzone. The dropzone is also a text field that is disabled
 */
export const DropzoneContact = ({
  getFieldValue,
  setFieldValue,
  label,
  fieldName,
  styles,
  textInformation,
}) => {
  const [progress, setProgress] = useState(-1);
  /* This is the useDropzone hook that allows the user to drag and drop files into the dropzone. */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setFieldValue(
        fieldName,
        await filesList(getFieldValue, acceptedFiles, setProgress).then(
          (promise) => promise
        )
      );
    },
  });

  const filesList = async (getFieldValue, acceptedFiles, setProgress) => {
    const promises = acceptedFiles.map(async (file) => {
      const obj = Object.assign(
        {
          name:
            setFileName(file.name) +
            "_" +
            getRandomInt(1000, 10000) +
            setNameExtension(file.name),
        },
        { tmp_name: URL.createObjectURL(file) },
        { type: file.type },
        { size: file.size },
        { base64: await toBase64(file, setProgress) }
      );
      return obj;
    });
    const files = await Promise.all(promises);
    return [...getFieldValue, ...files];
  };

  /**
   * It removes one value from the field.
   */
  const removeFile = (file) => () => {
    const newFiles = [...getFieldValue];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFieldValue(fieldName, newFiles);
  };

  /**
   * It removes all the values from the field.
   */
  const removeAll = () => {
    setFieldValue(fieldName, []);
  };

  const displayFiles = getFieldValue?.map((file) => {
    return (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <Grid container direction="column">
            <Grid item>
              {trimFileName(file.name)}
              <Button onClick={removeFile(file)} startIcon={<DeleteIcon />} />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  });

  /* This is a React Hook that is used to update the state of the form. */
  useEffect(() => {
    setFieldValue(fieldName, getFieldValue);
  }, [fieldName, getFieldValue, setFieldValue]);

  const cssFontFamily = styles.cssFontFamily;

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <StyledDivDropzone
          className={cssFontFamily}
          style={{
            display: "flex",
            fontFamily: '"Cabin","Helvetica","Arial",sans-serif',
            fontWeight: "bold",
            fontSize: "0.95em",
            padding: "16.5px 14px",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <div>
            <StyledIconDropZone />
          </div>
          <div style={{ color: "#2A375C" }}>{label}</div>
          <div>
            {textInformation?.text ? (
              <HelperText name={fieldName} textInformation={textInformation} />
            ) : null}
          </div>
        </StyledDivDropzone>
      </div>
      <aside style={thumbsContainer}>
        <ul>{displayFiles}</ul>
        <div style={{ width: "100%", textAlign: "center" }}>
          {progress >= 0 && progress <= 100 ? (
            <CircularProgressWithLabel value={progress} />
          ) : null}
        </div>
      </aside>
      {getFieldValue?.length > 0 && (
        <StyledButtonRetirerFichier
          className={cssFontFamily}
          startIcon={<RemoveIcon />}
          onClick={removeAll}
        >
          Supprimer les fichiers
        </StyledButtonRetirerFichier>
      )}
    </section>
  );
};
