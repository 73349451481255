import React, { useEffect, useState } from "react";
import {
  StyledGridLignesTab,
} from "../../styledComponents/StyledGridBackoffice";
import { useSelector } from "react-redux";
import {
  StyledDivPaginationOrange,
} from "../../styledComponents/StyledDivBackoffice";
import { CircularProgress, Pagination } from "@mui/material";
import { ContactItem } from "./ContactItem";

export const ListeContact = ({ actions, state }) => {
  const [searchBarContentNom, setSearchBarContentNom] = useState();

  const [sortedNom, setSortedNom] = useState();

  const { contactList } = useSelector((state) => state.espaceClient);
  const [filteredList, setFilteredList] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  const defineFilteredList = (searchBarContent, key) => {
    return contactList.filter((item) => {
      if (searchBarContent === undefined) {
        return item;
      } else {
        if (item[key].toLowerCase().includes(searchBarContent.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      }
    });
  };

  const defineSortedList = (sortedDownOrUp, key) => {
    if (sortedDownOrUp === "up") {
      setFilteredList(
        [...contactList].sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    }
    if (sortedDownOrUp === "down") {
      setFilteredList(
        [...contactList].sort((a, b) => (b[key] > a[key] ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    setFilteredList(contactList);
  }, [contactList]);

  useEffect(() => {
    if (!contactList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentNom, "nom"));
  }, [searchBarContentNom, contactList]);

  useEffect(() => {
    if (!contactList) {
      return;
    }
    defineSortedList(sortedNom, "nom");
  }, [sortedNom, contactList]);

  useEffect(() => {
    if (!filteredList) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const handlePageClick = (event, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const afficherListeNC = pagination.currentData.map((item) => {
    return (
      <ContactItem
        key={item.contactId}
        id={item.contactId}
        state={state}
        {...item}
        searchBarAction={{
          setSearchBarContentNom,
        }}
        sortAction={{
          setSortedNom,
        }}
        modale={actions}
      />
    );
  });

  return (
    <div>
      <StyledGridLignesTab
        container
        style={{
          boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        }}
      >
        <ContactItem
          key={0}
          id={0}
          action={state.t.titleAction}
          titre={state.t.titleTabTitre}
          prenom={state.t.titleTabPrenom}
          nom={state.t.titleTabNom}
          fonction={state.t.titleTabFonction}
          telephone={state.t.titleTabTelephone}
          email={state.t.titleTabEmail}
          refus_emailing={state.t.titleTabRefusEmailing}
          clientId={"clientId"}
          contactId={"contactId"}
          state={state}
          searchBarAction={{
            setSearchBarContentNom,
          }}
          sortAction={{
            setSortedNom,
          }}
          modale={actions}
        />
        {!contactList || contactList.length === 0 ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1rem",
            }}
          >
            <CircularProgress style={{ color: "#2A375C" }} />
          </div>
        ) : (
          afficherListeNC
        )}
      </StyledGridLignesTab>
      <StyledDivPaginationOrange>
        <Pagination
          size="large"
          count={pagination.pageCount}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={0}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPaginationOrange>
    </div>
  );
};
