import { TextField } from "@mui/material";
import { styled } from "@mui/system";

export const StyledTextfieldOrange = styled(TextField)({
  width: "330px",
  "& label": {
    color: "#2A375C",
    fontSize: "1em",
    fontWeight: "600",
    fontFamily: "Cabin",
  },
  "& label.Mui-focused": {
    color: "#2A375C",
    fontSize: "1.2em",
    fontFamily: "Cabin",
    height: "50px",
  },
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    padding: "12px 14px",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    height: "50px",
    "& fieldset": {
      borderColor: "#EC8812",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EC8812",
    },
    "&:hover fieldset": {
      borderColor: "#EC8812",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    fontSize: "1.13em",
    top: "-3px",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: "1.13em",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EC8812",
  },
  "@media (max-width: 992px)": {
    width: "290px",
  },
});

export const StyledTextfieldSearchDdp = styled(TextField)({
  width: "100%",
  ".MuiSvgIcon-root": {
    color: "#EC8812",
    width: "0.7em",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    height: "25px",
    paddingRight:"0.3em",
    "& fieldset": {
      borderColor: "#EC8812",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EC8812",
    },
    "&:hover fieldset": {
      borderColor: "#EC8812",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EC8812",
  },
});

export const StyledTextfieldSearchDdpRose = styled(TextField)({
  width: "100%",
  ".MuiSvgIcon-root": {
    color: "#CC007B",
    width: "0.7em",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    height: "25px",
    paddingRight:"0.3em",
    "& fieldset": {
      borderColor: "#CC007B",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CC007B",
    },
    "&:hover fieldset": {
      borderColor: "#CC007B",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CC007B",
  },
});

export const StyledTextfieldRose = styled(TextField)({
  width: "330px",
  "& label": {
    color: "#2A375C",
    fontSize: "1em",
    fontWeight: "600",
    fontFamily: "Cabin",
  },
  "& label.Mui-focused": {
    color: "#2A375C",
    fontSize: "1.2em",
    fontFamily: "Cabin",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    "& fieldset": {
      borderColor: "#CC007B",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CC007B",
    },
    "&:hover fieldset": {
      borderColor: "#CC007B",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    fontSize: "1.13em",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: "1.13em",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CC007B",
  },
  "@media (max-width: 992px)": {
    width: "290px",
  },
});
