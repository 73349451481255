import {useTranslations} from "./components/hook/useTranslations";
import {StyledPaperPolitiques} from "./styledComponents/StyledPaper";

export const PolitiqueDeConfidentialite = () => {
    const {t} = useTranslations();
    return (
        <StyledPaperPolitiques elevation={0}
                               dangerouslySetInnerHTML={{__html: t.messagePolitiqueConfidentialiteP1 + t.messagePolitiqueConfidentialiteP2 + t.messagePolitiqueConfidentialiteP3 + t.messagePolitiqueConfidentialiteP4}}>
        </StyledPaperPolitiques>

    );
};
