import { styled } from "@mui/system";

export const StyledPath1 = styled('path')({
    stroke:'rgba(67,67,67,1)',
    fill:'none',
});

export const StyledPath2 = styled('path')({
    stroke:'rgba(30,53,93,1)',
    fill: 'rgba(30,53,93,1)'
});

export const StyledPath3 = styled('path')({
    stroke:'rgba(30,53,93,1)',
    fill:'yellow',
});

export const StyledPath4 = styled('path')({
    stroke:'#000000',
    fill:'none',
});

export const StyledPath5 = styled('path')({
    stroke:'#000000',
    strokeWidth:0.60,
    fill:'none',
});

export const StyledPathDotted = styled('path')({
    strokeDasharray:'4,4',
});

export const StyledPathAxeSymetrie = styled('path')({
    stroke:'grey',
    strokeDasharray:'0.8em,0.2em,0.2em,0.2em',
});

export const StyledPathAxeSymetrie2 = styled('path')({
    stroke: 'grey',
    fill: 'none',
});

export const StyledPathFleche = styled('path')({
    strokeWidth:0.60,
    stroke:'grey',
});
export const StyledPathCote = styled('path')({
    strokeWidth:0.60,
    stroke:'rgba(180, 180, 180, 1)',
});

export const StyledPathEcaille = styled('path')({
    strokeWidth:2,
    stroke:'rgba(6, 127, 54, 1)',
});

export const StyledPathCoteDotted = styled('path')({
    strokeWidth:0.60,
    stroke:'rgba(180, 180, 180, 1)',
    strokeDasharray:'2,2',
});
export const StyledPathRacleur = styled('path')({
    stroke:'rgba(255,0,0,1)',
    fill:'none',
});

export const StyledPathBaseTableElevatrice = styled('path')({
    strokeWidth:0.60,
    stroke:'none',
    fill: 'rgba(30,53,93,0.3)'
});


