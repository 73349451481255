import {
  S08_FIXATION_GROS_ELEMENT,
  S08_FIXATION_PETIT_ELEMENT,
} from "../../../../../constantes/symbols/SymbolsS08";
import { initChampsFormikToJsonDefaultString } from "../../../../../helper/InitialisationChampsFormik";

const champsString = [S08_FIXATION_GROS_ELEMENT, S08_FIXATION_PETIT_ELEMENT];

export const initialValuesExtremitesS08 = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
    },
    {}
  );
};
