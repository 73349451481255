//Etape 4
const S06_TOILE = Symbol("SQ0101_Q00020");

//Etape 5
const NOMBRE_FACE = Symbol("SQ0102_Q00110");
const S06_LONGUEUR_DEPLOYEE = Symbol("SQ0102_Q00020");
const S06_LONGUEUR_COMPRIMEE = Symbol("SQ0102_Q00030");
const S06_LONGUEUR_A_INT = Symbol("SQ0102_Q00060");
//TODO: A verifier si c'est bien les bons codes pour longueur B et C
const S06_LONGUEUR_B_INT = Symbol("SQ0102_Q00080");
const S06_LONGUEUR_C_INT = Symbol("SQ0102_Q00081");
const DIMENSIONS_PLIS = Symbol("SQ0102_Q00041");
const S06_LONGUEUR_A_EXT = Symbol("SQ0102_Q00064"); //faux code
const S06_LONGUEUR_B_EXT = Symbol("SQ0102_Q00065"); //faux code
const S06_LONGUEUR_C_EXT = Symbol("SQ0102_Q00066"); //faux code

//Etape 6
// const S06_TITRE_TYPE_EXTREMITES_HAUT = Symbol("titre_extremite_haute");
// const S06_FIXATION_EXTREMITES_HAUT = Symbol("SQ0103_Q00300$3");
// const S06_MANCHETTE_LARGEUR_HAUT = Symbol("SQ0103_Q00320$3");
// const ALIGNEMENT_HAUT = Symbol("SQ0103_Q00500$3");
// const S06_MANCHETTE_A_HAUT = Symbol("SQ0103_Q00330$3");
// const S06_MANCHETTE_B_HAUT = Symbol("SQ0103_Q00340$3");
// const S06_TITRE_BRIDE_HAUT = Symbol("titre_bride_haute");
// const S06_BRIDE_HAUT = Symbol("SQ0103_Q00040$3");
// const BRIDE_EXTREMITE_INSEREE_MATIERE_HAUT = Symbol("SQ0103_Q00160$3");
// const BRIDE_FIXATION_HAUT = Symbol("SQ0103_Q00110$3");
// const BRIDE_EXTREMITE_RAPORTEE_MATIERE_HAUT = Symbol("SQ0103_Q00310$3");
// const BRIDE_EXTREMITE_INSEREE_EPAISSEUR_HAUT = Symbol("SQ0103_Q00060$3");


//!!!!!Comme pour le S01, à la demande du client, les extremites sont inversées!!!!!
//!!!!!Les textes sont juste et le nom des variable est faux!!!!!
const S06_TITRE_EXTREMITE_IDENTIQUE = Symbol("text_extremite_identique");
const S06_EXTREMITE_IDENTIQUE = Symbol("SQ0012_Q00170");

//It2 (EXTREMITE HAUTE)
const S06_TITRE_TYPE_EXTREMITES_IT1 = Symbol("titre_extremite_it1");
const S06_TYPE_EXTREMITES_IT1 = Symbol("SQ0103_Q00300$2");
const S06_MANCHETTE_LARGEUR_IT1 = Symbol("SQ0103_Q00320$2");
const ALIGNEMENT_IT1 = Symbol("SQ0103_Q00500$2");
const S06_MANCHETTE_A_IT1 = Symbol("SQ0103_Q00330$2");
const S06_MANCHETTE_B_IT1 = Symbol("SQ0103_Q00340$2");
const S06_TITRE_BRIDE_IT1 = Symbol("titre_bride_it1");
const S06_BRIDE_IT1 = Symbol("SQ0103_Q00040$2");
const BRIDE_EXTREMITE_INSEREE_MATIERE_IT1 = Symbol("SQ0103_Q00160$2");
const BRIDE_FIXATION_IT1 = Symbol("SQ0103_Q00110$2");
const BRIDE_EXTREMITE_RAPORTEE_MATIERE_IT1 = Symbol("SQ0103_Q00310$2");
const BRIDE_EXTREMITE_INSEREE_EPAISSEUR_IT1 = Symbol("SQ0103_Q00060$2");

//It1 (EXTREMITE BASSE)
const S06_TITRE_TYPE_EXTREMITES_IT2 = Symbol("titre_extremite_it2");
const S06_TYPE_EXTREMITES_IT2 = Symbol("SQ0103_Q00300$1");
const S06_MANCHETTE_LARGEUR_IT2 = Symbol("SQ0103_Q00320$1");
const ALIGNEMENT_IT2 = Symbol("SQ0103_Q00500$1");
const S06_MANCHETTE_A_IT2 = Symbol("SQ0103_Q00330$1");
const S06_MANCHETTE_B_IT2 = Symbol("SQ0103_Q00340$1");
const S06_TITRE_BRIDE_IT2 = Symbol("titre_bride_it2");
const S06_BRIDE_IT2 = Symbol("SQ0103_Q00040$1");
const BRIDE_EXTREMITE_INSEREE_MATIERE_IT2 = Symbol("SQ0103_Q00160$1");
const BRIDE_FIXATION_IT2 = Symbol("SQ0103_Q00110$1");
const BRIDE_EXTREMITE_RAPORTEE_MATIERE_IT2 = Symbol("SQ0103_Q00310$1");
const BRIDE_EXTREMITE_INSEREE_EPAISSEUR_IT2 = Symbol("SQ0103_Q00060$1");

// const JOINDRE_PLAN_EXTREMITES_IT1 = Symbol("joindrePlanExtremites$2");
// const JOINDRE_PLAN_EXTREMITES_IT2 = Symbol("joindrePlanExtremites$1");

//Etape 7
const S06_ACCESSOIRE = Symbol("SQ0104_Q00010"); // faux code
const OUVETRURE = Symbol("SQ0104_Q00020");

export {
  //Etape4
  S06_TOILE,
  //Etape5
  NOMBRE_FACE,
  S06_LONGUEUR_DEPLOYEE,
  S06_LONGUEUR_COMPRIMEE,
  S06_LONGUEUR_A_INT,
  S06_LONGUEUR_B_INT,
  S06_LONGUEUR_C_INT,
  DIMENSIONS_PLIS,
  S06_LONGUEUR_A_EXT,
  S06_LONGUEUR_B_EXT,
  S06_LONGUEUR_C_EXT,
  //Etape6
  //EXTREMITE HAUTE
  // S06_TITRE_TYPE_EXTREMITES_HAUT,
  // S06_FIXATION_EXTREMITES_HAUT,
  // S06_MANCHETTE_LARGEUR_HAUT,
  // ALIGNEMENT_HAUT,
  // S06_MANCHETTE_A_HAUT,
  // S06_MANCHETTE_B_HAUT,
  // S06_TITRE_BRIDE_HAUT,
  // S06_BRIDE_HAUT,
  // BRIDE_EXTREMITE_INSEREE_MATIERE_HAUT,
  // BRIDE_FIXATION_HAUT,
  // BRIDE_EXTREMITE_RAPORTEE_MATIERE_HAUT,
  // BRIDE_EXTREMITE_INSEREE_EPAISSEUR_HAUT,
  //EXTREMITE BASSE
  S06_TITRE_EXTREMITE_IDENTIQUE,
  S06_EXTREMITE_IDENTIQUE,
  S06_TITRE_TYPE_EXTREMITES_IT1,
  S06_TYPE_EXTREMITES_IT1,
  S06_MANCHETTE_LARGEUR_IT1,
  ALIGNEMENT_IT1,
  S06_MANCHETTE_A_IT1,
  S06_MANCHETTE_B_IT1,
  S06_TITRE_BRIDE_IT1,
  S06_BRIDE_IT1,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT1,
  BRIDE_FIXATION_IT1,
  BRIDE_EXTREMITE_RAPORTEE_MATIERE_IT1,
  BRIDE_EXTREMITE_INSEREE_EPAISSEUR_IT1,
  S06_TITRE_TYPE_EXTREMITES_IT2,
  S06_TYPE_EXTREMITES_IT2,
  S06_MANCHETTE_LARGEUR_IT2,
  ALIGNEMENT_IT2,
  S06_MANCHETTE_A_IT2,
  S06_MANCHETTE_B_IT2,
  S06_TITRE_BRIDE_IT2,
  S06_BRIDE_IT2,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT2,
  BRIDE_FIXATION_IT2,
  BRIDE_EXTREMITE_RAPORTEE_MATIERE_IT2,
  BRIDE_EXTREMITE_INSEREE_EPAISSEUR_IT2,
  //Etape7
  S06_ACCESSOIRE,
  OUVETRURE
  
};
