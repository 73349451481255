import { S04_ACCESSOIRES, S04_ECAILLES } from "../../../../../constantes/symbols/SymbolsS04";
import { initChampsFormikToJsonDefaultCustom } from "../../../../../helper/InitialisationChampsFormik";

const champsArray = [S04_ACCESSOIRES, S04_ECAILLES];

export const initialValuesAccessoiresS04 = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultCustom(champsArray, formulaire, ""),
    },
    {}
  );
};
