import * as Yup from 'yup';

export const ValidAuthentification = (t) => {
    return Yup.object({
        email: Yup
            .string(t.renseignerChamp)
            .required(t.champRequis),
        motDePasse: Yup
            .string(t.renseignerChamp)
            .required(t.champRequis)
    })
};