import {
  JOINDRE_PLAN_EXTREMITES,
  JOINDRE_PLAN_EXTREMITES_IT1,
  JOINDRE_PLAN_EXTREMITES_IT2,
  S04_EXTREMITE_IDENTIQUE,
  S04_FIXATION_EXTREMITES_IT1,
  S04_FIXATION_EXTREMITES_IT2,
} from "../../../../../constantes/symbols/SymbolsS04";
import { S04_STEP6_arrayFixationExtremites } from "../../../../../constantes/symbols/SymbolsServicesS04";
import {
  DROPZONE,
  SWITCH,
  TEXT,
  TEXTFIELD_SELECT,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const S04_TITRE_EXTREMITE_IDENTIQUE = Symbol("s04_titre_extremite_identique");

export const formulaireDimensionsExtremites = (styles, t) => [
  {
    nom: S04_TITRE_EXTREMITE_IDENTIQUE,
    label: t.extremiteIdentique + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0039_Q00020,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S04_EXTREMITE_IDENTIQUE,
    type: SWITCH,
    cssFontFamily: styles.cssFontFamily,
    cssSwitch: styles.cssSwitch,
    cssMarginFormSection: styles.sectionForm0Margin,
    false: t.non,
    true: t.oui,
  },
];

//-----------Deux fois le même formulaire - itération 1----------//
export const formulaireFixationExtremitesIt1 = (styles, t) => [
  {
    nom: S04_FIXATION_EXTREMITES_IT1,
    label: t.fixationExtremite + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: S04_STEP6_arrayFixationExtremites(t),
    textInformation: {
      text: t.SQ0039_Q00050$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireFixationExtremitesIt2 = (styles, t) => [
  {
    nom: S04_FIXATION_EXTREMITES_IT2,
    label: t.fixationExtremite + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: S04_STEP6_arrayFixationExtremites(t),
    textInformation: {
      text: t.SQ0039_Q00050$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulairePlanExtremiteIt1 = (styles, t, formik) => [
  [
    S04_STEP6_arrayFixationExtremites()[5].code,
    S04_STEP6_arrayFixationExtremites()[6].code,
    S04_STEP6_arrayFixationExtremites()[7].code,
    S04_STEP6_arrayFixationExtremites()[8].code,
  ].includes(formik.values[S04_FIXATION_EXTREMITES_IT1.description])
    ?
  {
    nom: JOINDRE_PLAN_EXTREMITES_IT1,
    label: t.joindrePlanSectionSoufflet,
    type: DROPZONE,
    styles: styles,
    cssTextfield: styles.descriptionDropzone,
    cssMarginFormSection: styles.sectionForm0Margin,
  }
  :null,
];

export const formulairePlanExtremiteIt2 = (styles, t, formik) => [
  [
    S04_STEP6_arrayFixationExtremites()[5].code,
    S04_STEP6_arrayFixationExtremites()[6].code,
    S04_STEP6_arrayFixationExtremites()[7].code,
    S04_STEP6_arrayFixationExtremites()[8].code,
  ].includes(formik.values[S04_FIXATION_EXTREMITES_IT2.description])
    ?
  {
    nom: JOINDRE_PLAN_EXTREMITES_IT2,
    label: t.joindrePlanSectionSoufflet,
    type: DROPZONE,
    styles: styles,
    cssTextfield: styles.descriptionDropzone,
    cssMarginFormSection: styles.sectionForm0Margin,
  }
  :null,
];
