import {
  S04_ACCESSOIRES,
  S04_ECAILLES,
} from "../../../../../constantes/symbols/SymbolsS04";
import { S04_STEP7_arrayAccessories } from "../../../../../constantes/symbols/SymbolsServicesS04";
import { importAll } from "../../../../../helper/ImportAll";
import {
  BUTTON_GROUP_IMAGE,
  CHECKBOX,
  TEXT,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_ECAILLES = Symbol("Ecailles");

export const formulaireAccessoiresS04 = (styles, t) => [
  {
    nom: TITRE_ECAILLES,
    label: t.ecailles,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0040_Q00150,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S04_ACCESSOIRES,
    label: "",
    type: BUTTON_GROUP_IMAGE,
    images: importAll(),
    img: "ecailles.jpg",
    cssMarginFormSection: styles.sectionFormMarginB,
    cssFontFamily: styles.cssFontFamily,
    cssRadio: styles.cssRadio,
    donnees: S04_STEP7_arrayAccessories(t),
  },
];

export const formulaireEcailles = (cotesList, styles, t) => [
  {
    nom: S04_ECAILLES,
    label: t.titreCheckboxEcailles,
    type: CHECKBOX,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: cotesList,
  },
];
