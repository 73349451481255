import {
  CP,
  DEMANDE,
  EMAIL,
  FONCTION,
  NOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TEL,
} from "../enum/champs/ChampsCommunFormulairesEnum";
import {
  DROPZONE,
  TEXT,
  TEXTFIELD,
  TEXTFIELD_ZONE,
  TEXTFIELD_SEARCHBAR,
} from "../components/generateFormulary/types/TypeFieldsFormulary";
import { getImageInformation } from "../../../helper/ImportAll";
import { PRENOM } from "../../../constantes/symbols/SymbolsCommon";

//Nom des champs propre à RechercherChamps
const HEADER = Symbol("header");
export const NDDP = Symbol("numeroDemandeDePrix");
const OU = Symbol("ou");
export const ARTICLES = Symbol("articles");
export const CAP = Symbol("codeArticlePrecedent");
export const NUM_OFFRE = Symbol("numeroOffreTransmise");
export const DROPZONE_RECHERCHER = Symbol("fichierRechercher");
export const QUANTITEE_SOUHAITEE_CODE_ARTICLE = Symbol(
  "quantiteSouhaiteeCodeArticle"
);
export const CODE_ARTICLE = Symbol("codeArticle");

export const formulaireRechercherP1 = (styles, t) => [
  {
    nom: HEADER,
    label: t.headerRechercher,
    type: TEXT,
    cssFontFamily: styles.cssPReactualisez,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: NDDP,
    label: t.titreReferenceDemandePrix,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.informationsComplementairesInfo,
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
  
  {
    nom: OU,
    label: t.ou,
    type: TEXT,
    //cssFontFamily: styles.cssFontFamily,
    cssFontFamily: styles.cssOuRechercher,
    cssMarginFormSection: styles.noSectionForm,
  },{
    nom: NUM_OFFRE,
    label: t.numeroOffreTransmise,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.rechercherTextInfoNumeroOffre,
    //   cssIcon: styles.helperTextIcon,
    // },
  },
  {
    nom: QUANTITEE_SOUHAITEE_CODE_ARTICLE,
    label: t.renseignementQuantite+t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginRechercher,
    // textInformation: {
    //   text: t.rechercherTextInfoQteEtCodeArticle,
    //   cssIcon: styles.helperTextIcon,
    //   images: getImageInformation(),
    // },
  },
];
export const formulaireRechercherP2 = (
  paysList,
  secteurList,
  fonctionList,
  styles,
  t,
  asterisque
) => [
  {
    nom: DROPZONE_RECHERCHER,
    label: t.ajouterFichier,
    type: DROPZONE,
    styles: styles,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.rechercherTextInfoDropbox,
    //   cssIcon: styles.helperTextIcon,
    //   images: getImageInformation(),
    // }
  },
  {
    nom: DEMANDE,
    label: t.precisionCasEcheant,
    type: TEXTFIELD_ZONE,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: SOCIETE,
    label: t.titreSociete+" *",
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.informationsComplementairesInfo,
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
  {
    nom: PAYS,
    label: t.titrePays+" *",
    type: TEXTFIELD_SEARCHBAR,
    donnees: paysList,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.rechercherTextInfoDropbox,
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // }
  },
  {
    nom: CP,
    label: t.titreCodePostal+" *",
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.informationsComplementairesInfo,
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
  {
    nom: SECTEUR,
    label: t.titreSecteurActiviteSansObli+" *",
    type: TEXTFIELD_SEARCHBAR,
    donnees: secteurList,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: NOM,
    label: t.titreNom+" *",
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.informationsComplementairesInfo,
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
  {
    nom: PRENOM,
    label: t.titrePrenom+" *",
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.informationsComplementairesInfo,
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
  {
    nom: FONCTION,
    label: t.titreFonctionSansObli,
    type: TEXTFIELD_SEARCHBAR,
    donnees: fonctionList,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
  },
  {
    nom: EMAIL,
    label: t.titreAdresseMail+" *",
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.informationsComplementairesInfo,
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
  {
    nom: TEL,
    label: t.titreTelephone+" *",
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.noSectionForm,
    // textInformation: {
    //   text: t.informationsComplementairesInfo,
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
];
