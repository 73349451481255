import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const StyledGridRadio = styled(Grid)({
  display: "flex",
  flexWrap: "wrap",
  rowGap: "7%",
  columnGap: "3%",
  marginBottom: "0.5em",
  justifyContent: "center",
  "@media (min-width: 1600px)": {
    marginLeft: "3.1em",
  },
  "@media (max-width: 1270px) and (min-width: 576px)": {
    justifyContent: "center",
    flexDirection:"column",
    rowGap: "0",
    alignItems:"center",
  },
  "@media (max-width: 490px)": {
    justifyContent: "center",
    rowGap: "0",
    marginLeft: "0",
  },
  "@media (max-width: 576px)": {
    margin: "0 1em",
    flexDirection:"column",
    alignItems:"center",
  },
});

export const StyledGridCheckbox = styled(Grid)({
  display: "flex",
  flexWrap: "wrap",
  rowGap: "1em",
  columnGap: "5%",
  marginLeft: "2.2em",
  justifyContent: "left",
  "@media (min-width: 1600px)": {
    marginLeft: "3.1em",
  },
  "@media (max-width: 1270px) and (min-width: 576px)": {
    justifyContent: "center",
    rowGap: "0",
  },
  "@media (max-width: 490px)": {
    justifyContent: "center",
    rowGap: "0",
  },
  "@media (max-width: 576px)": {
    margin: "0 1em",
  },
});

export const StyledGridStickyZoneAgressions = styled(Grid)({
  borderRadius: "0 8px 8px 0",
  paddingTop: "0%",
  paddingBottom: "50%",
  margin: "0em 0",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  float: "none",
  width: "100%",
  maxWidth: "100%",
});
