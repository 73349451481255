import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../../helper/ErrorHandlerMessageProvider";
import { AgressionsActionType } from "../../../constantes/actionType/AgressionsActionType";
import agressionsService from "../../service/AgressionsService";
import { langForService } from "../../../helper/ServiceCleaner";

export const getAllAgressions = createAsyncThunk(
  AgressionsActionType.GET_ALL_AGRESSIONS,
  async (lang, thunkAPI) => {
    try {
      const reponse = await agressionsService.getAllAgressions();
      const resolvedLang = langForService(lang, thunkAPI);
      const data = reponse.data
        .sort((curr, next) => {
          return curr[resolvedLang].localeCompare(next[resolvedLang]);
        })
        .map((element) => {
          return {
            code: element.IDSylob,
            reference: element.cle,
            name: element[resolvedLang],
            img: element.cle,
          };
        });
      return data;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {};

const agressionsSlice = createSlice({
  name: AgressionsActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllAgressions.fulfilled, (state, action) => {
        state.agressionsList = action.payload;
      })
      .addCase(getAllAgressions.rejected, (state, action) => {
        state.agressionsList = [];
      });
  },
});

const { reducer } = agressionsSlice;
export default reducer;
