import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../../helper/ErrorHandlerMessageProvider";
import { PaysActionType } from "../../../constantes/actionType/PaysActionType";
import paysService from "../../service/PaysService";
import { langJsonFormatter } from "../../../helper/ServiceCleaner";

export const getAllPays = createAsyncThunk(
  PaysActionType.GET_ALL_PAYS,
  async (lang, thunkAPI) => {
    try {
      const reponse = await paysService.getAllPays();
      return langJsonFormatter(lang, thunkAPI, reponse);
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {};
const paysSelected = ["FR", "BE", "DE"];
const divider = {
  code: "",
  name: "",
  isDivider: true,
};

const paysSlice = createSlice({
  name: PaysActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllPays.fulfilled, (state, action) => {
        const listPaysAllValues = action.payload;
        const listPaysSelected = listPaysAllValues.filter((elt) =>
          paysSelected.includes(elt.reference)
        );

        const listPaysSelected_sorted = listPaysSelected.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );

        const listAttenduPaysSelected = listPaysAllValues.filter(
          (elt) => !paysSelected.includes(elt.reference)
        );

        const paysAttendus = listPaysSelected_sorted.concat(
          divider,
          listAttenduPaysSelected
        );

        state.paysList = paysAttendus;
      })
      .addCase(getAllPays.rejected, (state, action) => {
        state.paysList = [];
      });
  },
});

const { reducer } = paysSlice;
export default reducer;
