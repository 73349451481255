import * as Yup from "yup";
import {
  AGRESSIONS,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  TEMPERATURE,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  arrayProductFamilly0_1_3_2_3,
  arrayTemperatures,
} from "../../../../../constantes/symbols/SymbolsServicesCommon";

/* A Yup validation schema. */
export const validAgressions = (t, formulaire, etape) => {
  if (etape !== 3) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [TEMPERATURE.description]: Yup.mixed().when({
      is: () =>
        arrayProductFamilly0_1_3_2_3(t).includes(
          formulaire[FAMILLE_PROTECTION_INDUSTRIELLE.description]
        ),
      then: Yup.mixed()
        .test(
          "TemperatureInSelect",
          () => {
            return t.erreurSelectionTemperature;
          },
          (code) => arrayTemperatures(t).some((e) => e.code === code)
        )
        .required(t.erreurSelectionTemperature),
      otherwise: Yup.mixed().optional(),
    }),
    [AGRESSIONS.description]: Yup.mixed().optional(),
  });
};
