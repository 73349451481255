import {
  S08_ANGLE_J,
  S08_ANGLE_K,
  S08_ANGLE_L,
  S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE,
  S08_LONGUEUR_A,
  S08_LONGUEUR_B,
  S08_LONGUEUR_C,
  S08_LONGUEUR_D,
  S08_LONGUEUR_E,
  S08_LONGUEUR_F,
  S08_LONGUEUR_G,
  S08_LONGUEUR_H,
  S08_LONGUEUR_I,
  S08_TYPE_PENTE,
  S08_TYPE_SECTION_ENVELOPPE,
  S08_TYPE_TOIT,
} from "../../../../../constantes/symbols/SymbolsS08";
import {
  S08_STEP5_arrayTypePente,
  S08_STEP5_arrayTypeSection,
  S08_STEP5_arrayTypeToit,
} from "../../../../../constantes/symbols/SymbolsServicesS08";
import { getImageInformation } from "../../../../../helper/ImportAll";
import {
  TEXT,
  BUTTON_GROUP,
  TEXTFIELD_NUMBER,
  DROPZONE,
  TEXTFIELD_BY_LABEL,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_ENVELOPPE_TYPE_SECTION = Symbol("Enveloppe - Type de section");
const TITRE_SECTION_ENVELOPPE = Symbol("Enveloppe - Section");
const TITRE_TYPE_TOIT_S08 = Symbol("Type de toit");
const TITRE_TYPE_PENTE_S08 = Symbol("Type de pente");

export const formulaireTypeSectionS08 = (styles, t) => [
  {
    nom: TITRE_ENVELOPPE_TYPE_SECTION,
    label: t.titreTypeSectionEnveloppe+t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00020,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_TYPE_SECTION_ENVELOPPE,
    label: t.descrTypeSectionEnveloppe,
    type: TEXTFIELD_BY_LABEL,
    cssFontFamily: styles.cssFontFamily,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: S08_STEP5_arrayTypeSection(t),
  },
];

export const formulaireSection0 = (
  styles,
  t,
  nomSectionEnveloppe,
  sectionList,
  codeQuestion
) => [
  {
    nom: TITRE_SECTION_ENVELOPPE,
    label: t.titreSectionEnveloppe+t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: codeQuestion,
      cssIcon: styles.helperTextIcon,
      images: getImageInformation(),
    },
  },
  {
    nom: nomSectionEnveloppe,
    label: t.descrEnveloppeSection,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: sectionList,
  },
  {
    nom: S08_LONGUEUR_A,
    label: t.longueurA+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00110,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_LONGUEUR_B,
    label: t.longueurB+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00120,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_LONGUEUR_C,
    label: t.longueurC+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00130,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireTypeToit = (styles, t) => [
  {
    nom: TITRE_TYPE_TOIT_S08,
    label: t.titreTypeToit+t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00040,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_TYPE_TOIT,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: S08_STEP5_arrayTypeToit(t),
  },
];

export const formulaireTypePente = (styles, t) => [
  {
    nom: TITRE_TYPE_PENTE_S08,
    label: t.titreTypePente+t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00070,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S08_TYPE_PENTE,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: S08_STEP5_arrayTypePente(t),
  },
];

export const formulaireLongueurB = (styles, t) => [
  {
    nom: S08_LONGUEUR_B,
    label: t.longueurB+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00120,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurC = (styles, t) => [
  {
    nom: S08_LONGUEUR_C,
    label: t.longueurC+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00130,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurD = (styles, t) => [
  {
    nom: S08_LONGUEUR_D,
    label: t.longueurD+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00140,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurE = (styles, t) => [
  {
    nom: S08_LONGUEUR_E,
    label: t.longueurE+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00150,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurF = (styles, t) => [
  {
    nom: S08_LONGUEUR_F,
    label: t.longueurF+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00170,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurG = (styles, t) => [
  {
    nom: S08_LONGUEUR_G,
    label: t.longueurG+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00180,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurH = (styles, t) => [
  {
    nom: S08_LONGUEUR_H,
    label: t.longueurH+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00190,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurI = (styles, t) => [
  {
    nom: S08_LONGUEUR_I,
    label: t.longueurI+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00160,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireAngleJ = (styles, t) => [
  {
    nom: S08_ANGLE_J,
    label: t.angleJ+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00250,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireAngleK = (styles, t) => [
  {
    nom: S08_ANGLE_K,
    label: t.angleK+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00260,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireAngleL = (styles, t) => [
  {
    nom: S08_ANGLE_L,
    label: t.angleL+t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0072_Q00270,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireQuelconquePlan = (styles, t) => [
  {
    nom: S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE,
    label: t.joindrePlanSectionQuelconque,
    type: DROPZONE,
    styles: styles,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
  },
];
