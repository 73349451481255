//Librairies de fonctionnement
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useOutletContext } from "react-router-dom";
/*-----------!!! ATTENTION Il existe @material-ui et @mui. !!!-----------------
 * @material-ui est une ancienne version (<V5) de @mui et peut renvoyer des erreurs
 * de type findDomnodes ces erreurs n'affectent pas nécessairement le comportement
 * du code mais mettent à mal le strict-mode de react (vérification continue des
 * références objet pour éviter les boucles infinies). => On peut donc considérer
 * @material-ui comme deprecié (j'ai passé trop de temps à déduire ca 😢)
 */
import { Grid } from "@mui/material";

import { genererFormulaire } from "./components/generateFormulary/CasesGeneration";
import { initialValuesContact } from "./ChampsFormulaires/Initialisation/ContactInitialValues";
import { ValidContact } from "./ChampsFormulaires/validationsFormulaires/ValidContact";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "./components/hook/useTranslations";
import { getAllSecteurs } from "../model/slice/SecteurReducer";
import { getAllFonctions } from "../model/slice/FonctionReducer";
import { getAllPays } from "../model/slice/PaysReducer";
import {
  StyledDivButton,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
  StyledDivH1ContactRecherche,
  StyledDivSticky,
} from "./styledComponents/StyledDiv";
import { importAllSpec } from "../../helper/ImportAll";
import { Loading } from "./components/Loading";
import {
  resetIsFormularySent,
  sendFormulaireContact,
  sendFormulaireContactSOS,
} from "../model/slice/FormulaireReducer";
import { WaitingDbInsert } from "./components/WaitingDbInsert";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "./styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "./styledComponents/StyledIcon";
import { StyledPContact } from "./styledComponents/StyledP";
import { Confirmation } from "./formulaires/Confirmation";
import { ErrorDbInsert } from "./formulaires/ErrorDbInsert";
import { SOS } from "../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { formulaireContactSOS } from "./ChampsFormulaires/ContactSOSChamps";
import { ValidContactSOS } from "./ChampsFormulaires/validationsFormulaires/ValidContactSOS";
import { initialValuesContactSOS } from "./ChampsFormulaires/Initialisation/ContactSOSInitialValues";

export const ContactSOS = () => {
  const [styles, t, booleanIdentite] = useOutletContext();
  const [step, setStep] = useState(1);
  const [formulaire, setFormulaire] = useState("");
  const [upToDatePaysList, setUpToDatePaysList] = useState([]);
  const [isAllowedToQuit, setIsAllowedToQuit] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { lang } = useTranslations();
  const { paysList } = useSelector((state) => state.pays);
  const { isFormularySent } = useSelector((state) => state.formulaire);

  const cssButtonPrec = styles.cssButtonPrec;
  const cssButton = styles.cssButton;
  const cssPContact = styles.cssPContact;
  const sectionFormSansBg = styles.sectionFormSansBg;

  useEffect(() => {
    dispatch(getAllSecteurs());
    dispatch(getAllFonctions());
    dispatch(getAllPays());
  }, [dispatch]);

  useEffect(() => {
    if (!paysList) {
      return;
    }
    setUpToDatePaysList(paysList);
  }, [paysList]);

  const handleSubmit = (form) => {
    form.langue = lang;
    form.marque = booleanIdentite ? "MP" : "RC";
    form.timestamp = Date.now();
    form.formulaireFPS = localStorage.getItem("formulaireFPS");
    dispatch(sendFormulaireContactSOS(form));
    setStep(step + 1);
  };

  const handleRetour = () => {
    navigate(GuestRoutes.ACCUEIL);
  };

  const formik = useFormik({
    initialValues: { ...initialValuesContactSOS(formulaire) },
    validationSchema: ValidContactSOS(upToDatePaysList, t),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const resetFormularySentState = () => {
    dispatch(resetIsFormularySent());
  };

  const resetFormulary = () => {
    setFormulaire({});
  };

  const state = {
    styles,
    t,
    booleanIdentite,
    formik,
  };

  const action = {
    setEtape: setStep,
    resetFormularySentState,
    resetFormulary,
    setIsAllowedToQuit,
  };

  if (!upToDatePaysList) {
    return <Loading t={t} />;
  } else {
    if (isFormularySent.status !== 200) {
      return (
        <ErrorDbInsert
          etape={step}
          setEtape={setStep}
          t={t}
          styles={styles}
          resetFormularySentState={resetFormularySentState}
          resetFormulary={resetFormulary}
        />
      );
    }
    if (isFormularySent.isBackendOk) {
      return <Confirmation state={state} action={action} from={SOS} />;
    }
    if (step === 1) {
    return (
      <StyledDivFormAndImageContainer>
        <StyledDivFormContainer>
          <StyledDivH1ContactRecherche>
            <h1>{t.genAccueilH2EtreRappele}</h1>
          </StyledDivH1ContactRecherche>
          <StyledPContact className={cssPContact}>
            {t.descrPageSOS}
            <br />
            {t.descrPageSOSPart2}
            <br />
            {t.descrPageSOSPart3}
          </StyledPContact>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
              <div className={sectionFormSansBg}>
                {
                  /* Generating the form with the fields of the form. */
                  genererFormulaire(
                    formulaireContactSOS(upToDatePaysList, styles, t),
                    formik
                  )
                }
              </div>
              <Grid item>
                <StyledDivButton>
                  <StyledButtonPrec
                    className={cssButtonPrec}
                    variant="outlined"
                    onClick={handleRetour}
                    startIcon={<StyledArrowBackOutlinedIcon />}
                  />
                  <StyledButtonContinuer
                    className={cssButton}
                    variant="contained"
                    type="submit"
                  >
                    {t.bouttonEnvoyer}
                  </StyledButtonContinuer>
                </StyledDivButton>
              </Grid>
            </Grid>
          </form>
        </StyledDivFormContainer>
        <StyledDivSticky>
          <img
            className="alone"
            src={importAllSpec(booleanIdentite)["LandingPageImage.jpg"]}
            alt="TODO: Define alt"
          />
        </StyledDivSticky>
      </StyledDivFormAndImageContainer>
    );
  } else {
    return <WaitingDbInsert state={state} from={SOS} />;
  }
  }
};
