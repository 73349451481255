import {
  AGRESSIONS,
  ENVIRONNEMENT,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  TEMPERATURE,
} from "../../../../../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly0_1_3 } from "../../../../../constantes/symbols/SymbolsServicesCommon";
import {
  initChampsFormikToJsonDefaultArray,
  initChampsFormikToJsonDefaultCustom,
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";

const champsString = [TEMPERATURE];

const champsArray = [AGRESSIONS];

/**
 * Si RC et que la famille de produits est S01 ou S01_1, alors
 * renvoyez la valeur par défaut du champ environnement.
 * @param formulaire - {
 * @param booleanIdentite - booléen
 * @returns {
 *   [ENVIRONNEMENT.description]: "3" || formulaire value
 * }
 */
const initValueEnvironnement = (formulaire, booleanIdentite) => {
  if (!booleanIdentite) {
    if (
      arrayProductFamilly0_1_3().includes(
        formulaire[FAMILLE_PROTECTION_INDUSTRIELLE.description]
      )
    ) {
      return {
        ...initChampsFormikToJsonDefaultCustom([ENVIRONNEMENT], formulaire, 3),
      };
    }
  }
  return { [ENVIRONNEMENT.description]: "3" };
};

export const initialValuesEnvironnementMachine = (
  formulaire,
  booleanIdentite
) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
      ...initChampsFormikToJsonDefaultArray(champsArray, formulaire),
      ...initValueEnvironnement(formulaire, booleanIdentite), //autre
    },
    {}
  );
};
