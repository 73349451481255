import { FOCUSED } from "../../../../../constantes/symbols/SymbolsCommon";
import { DIMENSIONS_PLIS, NOMBRE_FACE, S06_LONGUEUR_A_EXT, S06_LONGUEUR_A_INT, S06_LONGUEUR_B_EXT, S06_LONGUEUR_B_INT, S06_LONGUEUR_COMPRIMEE, S06_LONGUEUR_C_EXT, S06_LONGUEUR_C_INT, S06_LONGUEUR_DEPLOYEE } from "../../../../../constantes/symbols/SymbolsS06";
import { initChampsFormikToJsonDefaultCustom, initChampsFormikToJsonDefaultString } from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
    S06_LONGUEUR_COMPRIMEE,
    S06_LONGUEUR_DEPLOYEE,
    NOMBRE_FACE,
    S06_LONGUEUR_A_INT,
    S06_LONGUEUR_B_INT,
    S06_LONGUEUR_C_INT,
    S06_LONGUEUR_A_EXT,
    S06_LONGUEUR_B_EXT,
    S06_LONGUEUR_C_EXT,
    DIMENSIONS_PLIS
];

export const initialValuesDimensionsCorpsS06 = (formulaire) => {
    return Object.assign({
            ...initChampsFormikToJsonDefaultString(champsString, formulaire),
            ...initChampsFormikToJsonDefaultCustom([FOCUSED], formulaire, null),
        }, {});
}