import { MATIERE_TOILE, TYPE_ENROULEUR } from "../../../../../constantes/symbols/SymbolsS05";
import { initChampsFormikToJsonDefaultString } from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
	TYPE_ENROULEUR,
    MATIERE_TOILE,
];

export const initialValuesConstructionS05 = (formulaire) => {
    return Object.assign({
        ...initChampsFormikToJsonDefaultString(champsString, formulaire),
    }, {});
}