import * as Yup from "yup";
import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../../../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../../../../../constantes/symbols/SymbolsServicesCommon";

/* A Yup validation schema. */
export const validFamilleProtectionIndustrielle = (t) => {
  return Yup.object({
    [FAMILLE_PROTECTION_INDUSTRIELLE.description]: Yup.mixed()
      .test(
        "RadioButtonFamilleProtection",
        () => {
          return t.erreurFamilleProtection;
        },
        (selectedButton) =>
          arrayProductFamilly(t).some((e) => e.code === selectedButton)
      )
      .required(t.erreurFamilleProtection),
  });
};
