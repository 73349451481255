export const EspaceClientActionType = Object.freeze({
    END_POINT: 'espaceClient',
    GET_ALL_ARTICLES: 'espaceClient/getAllArtcilesEC',
    GET_ONE_ARTICLE: 'espaceClient/getOneArticleEC',
    GET_ALL_COMMANDES: 'espaceClient/getAllCommandesEC',
    GET_ALL_COMMANDES_BY_ARTICLE_ID: 'espaceClient/getAllCommandesByArticleIdEC',
    GET_ALL_OFFRES: 'espaceClient/getAllOffresEC',
    GET_ALL_OFFRES_BY_ARTICLE_ID: 'espaceClient/getAllOffresByArticleIdEC',
    GET_ONE_OFFRE: 'espaceClient/getOneOffreIdEC',
    GET_TYPES_COMMANDE: 'espaceClient/getTypesCommandeEC',
    GET_ONE_COMMANDE: 'espaceClient/getOneCommandeEC',
    GET_ALL_NC: 'espaceClient/getAllNC',
    GET_ETAT_NC: 'espaceClient/getEtatNC',
    GET_ONE_NC: 'espaceClient/getOneNC',
    GET_ALL_CLIENTS: 'espaceClient/getAllContacts',
    GET_DETAILS_CLIENT: 'espaceClient/getDetailsClient',
    GET_ONE_CONTACT: 'espaceClient/getOneContact',
    SEND_MAIL_PROGRAMMER_VISITE: 'espaceClient/sendMailProgrammerVisite',
    SEND_MAIL_ETRE_RAPPELER: 'espaceClient/sendMailEtreRappeler',
    SEND_MAIL_MODIF_CONTACT: 'espaceClient/sendMailModifContact',
    GET_ALL_FAMILLE_ARTICLE: 'espaceClient/getAllFamilleArticle',
    SEND_STATS_TO_FPS: 'espaceClient/sendStatsToFPS',
})
