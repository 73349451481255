import * as Yup from "yup";
import { arrayAccessoiresS08 } from "../../../../../constantes/symbols/SymbolsServicesS08";

/* A Yup validation schema. */
export const validS08Accessoires = (t) => {
  return Yup.object().shape({
    [arrayAccessoiresS08()[0].code.description]: Yup.number().test(
      "is boolean",
      () => {
        return t.elementNonListe;
      },
      (checkedElement) => checkedElement === 0 || checkedElement === 1
    ),
    [arrayAccessoiresS08()[1].code.description]: Yup.number().test(
      "is boolean",
      () => {
        return t.elementNonListe;
      },
      (checkedElement) => checkedElement === 0 || checkedElement === 1
    ),
    [arrayAccessoiresS08()[2].code.description]: Yup.number().test(
      "is boolean",
      () => {
        return t.elementNonListe;
      },
      (checkedElement) => checkedElement === 0 || checkedElement === 1
    ),
    [arrayAccessoiresS08()[3].code.description]: Yup.number().test(
      "is boolean",
      () => {
        return t.elementNonListe;
      },
      (checkedElement) => checkedElement === 0 || checkedElement === 1
    ),
  });
};
