import { langUrl } from "../../config/i18nConfig";

/**
 * It gets the json file from the langUrl object.
 * @param lang - The language you want to translate to.
 * @returns The JSON object.
 */
export const getJsonFromFileByLang = (lang) => {
    return langUrl
        .keys()
        .filter((fileName) => {
            return fileName.replace('./', '').split('.')[0]===lang;
        })
        .map((fileName) => {
            return langUrl(fileName);
        })
        .find((elem, index) => index === 0);
}


/**
 * It fetches the translations for the given language.
 * @param lang - The language to fetch.
 * @returns A promise.
 */
export const fetchTranslations = async (lang) => {
    const baseLanguage = getJsonFromFileByLang("fr");
    const languageFileContent = getJsonFromFileByLang(lang);

    const languageToReturn = {
        ...baseLanguage, 
        ...languageFileContent
    }

    return new Promise((resolve) => {
        setTimeout(()=>{
            resolve(languageToReturn);
        }, 10);
    });
}

const i18nService = {
    fetchTranslations,
}

export default i18nService;