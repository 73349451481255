import { Switch } from "@mui/material";
import { styled } from "@mui/system";

export const StyledSwitch = styled(Switch)({
    color: '#2A375C',
    '& .MuiTypography-root':{
        fontFamily: 'Dosis',
        fontWeight: '500',
        fontSize: '1.125em',
    }
});