export const EspaceClient = Object.freeze({
    GET_ALL_ARTICLES: "json/getArticlesEC/",
    GET_ALL_COMMANDES: "json/getCommandesEC/",
    GET_ALL_OFFRES: "json/getOffresEC/",
    GET_DETAILS_CLIENT: "json/getContactsEC/",
    GET_TYPES_COMMANDE: "json/getAllTypesCommandesEC/",
    GET_ALL_NCL: "json/getNCEC/",
    GET_ETAT_NC: "json/getAllEtatNCEC/",
    GET_ALL_CLIENTS: "json/getContactsEC/",
    SEND_MAIL_EC: "json/sendMailEC/",
    GET_ALL_FAMILLE_ARTICLE: "json/getAllFamilleArticlesEC/",
    SEND_STATS_TO_FPS: "json/sendStatsToFPS/",
  });
  