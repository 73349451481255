import { IconButton, Stack } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { StringToRouteInNavigate } from "../../helper/StringParser";
import { AdminRoutes } from "../../constantes/routes/AdminRoutes";
import {
  StyledGridItem,
  StyledGridLine,
} from "./styledComponents/StyledGridBackoffice";
import { useDispatch } from "react-redux";
import { setMessage } from "../model/slice/MessageReducer";
import {
  StyledDivArrowDownIcon,
  StyledDivArrows,
  StyledDivArrowUpIcon,
} from "./styledComponents/StyledDivBackoffice";

export const CompteItem = ({
  id,
  nom,
  prenom,
  email,
  label,
  action,
  modale: { handleOpen, setUserToDelete },
  sortAction = null,
}) => {
  const setSortedNom = sortAction?.setSortedNom || null;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ModifierProfil = () => {
    dispatch(setMessage(null));
    navigate(
      StringToRouteInNavigate(
        AdminRoutes.MODIFIER_COLLABORATEUR + "/" + { id }.id
      )
    );
  };

  const DeleteProfil = (id) => {
    setUserToDelete(id);
    handleOpen();
  };
  const DisplayAdornment = ({ id, xs }) => {
    if (id !== 0) {
      return (
        <StyledGridItem item className="colAction">
          <IconButton onClick={ModifierProfil}>
            <BorderColorOutlinedIcon sx={{ color: "#2A375C", fontSize: 20 }} />
          </IconButton>
          <IconButton onClick={() => DeleteProfil(id)}>
            <DeleteIcon sx={{ color: "#CC007B", fontSize: 20 }} />
          </IconButton>
        </StyledGridItem>
      );
    } else {
      return null;
    }
  };

  const displaySortArrows = (setSortedValue) => {
    if (setSortedValue) {
      return (
        <Stack alignItems="center" justifyContent="center">
          <StyledDivArrows>
            <StyledDivArrowUpIcon
              fontSize="small"
              onClick={() => setSortedValue("up")}
            />
            <StyledDivArrowDownIcon
              fontSize="small"
              onClick={() => setSortedValue("down")}
            />
          </StyledDivArrows>
        </Stack>
      );
    }
  };

  return (
    <StyledGridLine item>
      <StyledGridItem item style={{ textTransform: "uppercase" }}>
        {nom}
        {displaySortArrows(setSortedNom)}
      </StyledGridItem>
      <StyledGridItem item className="colNonEssentielle">
        {prenom}
      </StyledGridItem>
      <StyledGridItem item className="colNonEssentielle colMail">
        {email}
      </StyledGridItem>
      <StyledGridItem item className="colNonEssentielle">
        {label}
      </StyledGridItem>
      {action ? (
        <StyledGridItem item className="colAction">
          {action}
        </StyledGridItem>
      ) : (
        <DisplayAdornment id={id} />
      )}
    </StyledGridLine>
  );
};
