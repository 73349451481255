import { genererFormulaire } from "../components/generateFormulary/CasesGeneration";
import { TEXT } from "../components/generateFormulary/types/TypeFieldsFormulary";
import { StyledButtonPageConfirmation } from "../styledComponents/StyledButton";
import { StyledDivButtonToRecap } from "../styledComponents/StyledDiv";
import { StyledClearSharpIconError } from "../styledComponents/StyledIcon";
import {
  StyledPaperBody,
  StyledPaperConfirmation,
} from "../styledComponents/StyledPaper";

const TITRE_ERROR = Symbol("titreError");

const messageError = (t, styles) => [
  {
    nom: TITRE_ERROR,
    label: t.echecDemandeDePrix,
    type: TEXT,
    csslabelQuestionConfirmation: styles.labelQuestionConfirmation,
    cssMarginFormSection: styles.noSectionForm,
  },
];

export const ErrorDbInsert = ({
  state: { styles, t, etape, booleanIdentite },
  action: { setEtape, resetFormularySentState, resetFormulary },
}) => {
  const cssButton = styles.cssButton;

  const handleRetourRecapitulatif = () => {
    resetFormularySentState();
    setEtape(etape - 1);
  };

  const handleNouvelleDemande = () => {
    //TODO: vider le localStorage
    resetFormulary();
    resetFormularySentState();
    setEtape(1);
  };

  const handleSiteWeb = () => {
    const locationSite = booleanIdentite
      ? "https://moovprotect.com/"
      : "https://www.rcmodeles.com/";
    window.open(locationSite, "_blank");
  };

  return (
    <StyledPaperBody elevation={0}>
      <StyledPaperConfirmation elevation={0}>
        <div>
          <div
            style={{
              width: "auto",
              marginLeft: "4em",
              marginRight: "4em",
              textAlign: "-moz-center",
            }}
          >
            {genererFormulaire(messageError(t, styles))}
          </div>
          <div>
            <StyledClearSharpIconError
              style={{
                backgroundColor: "rgb(255, 0, 0, 0.33)",
                borderRadius: "50%",
              }}
            />

            <StyledDivButtonToRecap 
              style={{
                marginTop: "5em",
              }}>
              <StyledButtonPageConfirmation
                variant="contained"
                className={cssButton}
                type="submit"
                onClick={handleRetourRecapitulatif}
              >
                {t.retournerAuRecap}
              </StyledButtonPageConfirmation>
              <StyledButtonPageConfirmation
                variant="contained"
                className={cssButton}
                type="submit"
                onClick={handleNouvelleDemande}
                style={{
                  margin: "1em",
                }}
              >
                {t.nouvelleDemande}
              </StyledButtonPageConfirmation>
              <StyledButtonPageConfirmation
                variant="contained"
                className={cssButton}
                type="submit"
                onClick={handleSiteWeb}
              >
                {t.siteWeb}
              </StyledButtonPageConfirmation>
            </StyledDivButtonToRecap>
          </div>
        </div>
      </StyledPaperConfirmation>
    </StyledPaperBody>
  );
};
