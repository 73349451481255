export const defaultLang = "FR";

const importAllLanguageFiles = (directory) => {
    let languagesType = {};
    directory.keys().map((item) => {
        let fileName = item.replace('./', '').split('.');
        languagesType = { ...languagesType, ...{[fileName[0].toUpperCase()]: directory(item).languageName}}
    })

    return languagesType;
}
export const supportedLangs = importAllLanguageFiles(require.context('../languages', false, /\.(json)$/));

export const langUrl = require.context('../languages/', false, /\.(json)$/);