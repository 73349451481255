import {
  LONGUEUR_COMPRIMEE,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
  RONDE_DROIT_EXTERIEUR_MAX,
  SOUFFLET_AVEC_SPIRE,
  JOINDRE_PLAN_BRIDE_PERCEE_IT1,
  JOINDRE_PLAN_BRIDE_PERCEE_IT2,
  ACCESSOIRES,
  TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT2,
  FIXATION_EXTREMITES_IT1,
  FIXATION_EXTREMITES_IT2,
  FERMETURE_GLISSIERE,
  OEILLET_FILTRE,
  BAGUE_GUIDAGE,
  EXTREMITE_IDENTIQUE,
  BRIDE_PERCEE_IT1,
  BRIDE_PERCEE_IT2,
  SECTION_SOUFFLET,
  TYPE_CORPS,
  MANCHETTE_IT1,
  MANCHETTE_IT2,
} from "../../../../../constantes/symbols/SymbolsS01";
import {
  AGRESSIONS,
  AUTRE,
  ENVIRONNEMENT,
  TYPE_DE_MACHINE,
  MARQUE_MACHINE,
  COMMENTAIRE,
  DELAI,
  FICHIERS_SUPP,
  QUANTITEE_SOUHAITEE,
  SERVICES,
  FONCTION,
  PAYS,
  SECTEUR,
  TEMPERATURE,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  POSITION_DE_TRAVAIL,
  VITESSE_DEPLACEMENT,
  CODE_ARTICLE,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  FICHIERS,
  FICHIER_RECAPITULATIF,
} from "../../../ChampsFormulaires/S01/recapitulatif/ChampsRecapitulatif";
import { StyledDivRecapGene } from "../../../styledComponents/StyledDiv";
import {
  displayFileArrayIfExist,
  displayFieldFromInternalService,
  displayFieldBoolean,
  displayFieldFromService,
  displayRecapitulatifFile,
} from "../commonMethods/CommonMethods";
import {
  arrayFixationExtremite,
  arraySectionExtremite,
  arraySectionSoufflet,
  arrayTypeCorps,
  arrayTypeExtremites,
} from "../../../../../constantes/symbols/SymbolsServicesS01";
import {
  arrayProductFamilly,
  arrayEnvironnement,
  arrayPositionTravail,
  arrayServicesRC,
  arrayTemperatures,
  arrayCommonSymbolsToDisplay,
} from "../../../../../constantes/symbols/SymbolsServicesCommon";
import {
  TYPE_SECTION,
  JOINDRE_PLAN_FORME_QUELCONQUE,
  TOILE,
  S04_ACCESSOIRES,
  S04_EXTREMITE_IDENTIQUE,
  S04_FIXATION_EXTREMITES_IT1,
  S04_FIXATION_EXTREMITES_IT2,
  SECTION_SYMETRIQUE,
  FORME_CORPS_A,
  FORME_CORPS_B,
  FORME_CORPS_C,
  FORME_CORPS_DS,
  FORME_CORPS_D,
  FORME_CORPS_ES,
  FORME_CORPS_E,
  FORME_CORPS_FS,
  FORME_CORPS_F,
  S04_ECAILLES,
  JOINDRE_PLAN_EXTREMITES_IT1,
  JOINDRE_PLAN_EXTREMITES_IT2,
} from "../../../../../constantes/symbols/SymbolsS04";
import {
  S04_STEP4_arrayFormeCorpsD,
  S04_STEP4_arrayFormeCorpsE,
  S04_STEP4_arrayFormeCorpsF,
  S04_STEP4_arrayFormeCorpsSymA,
  S04_STEP4_arrayFormeCorpsSymB,
  S04_STEP4_arrayFormeCorpsSymC,
  S04_STEP4_arrayFormeCorpsSymD,
  S04_STEP4_arrayFormeCorpsSymE,
  S04_STEP4_arrayFormeCorpsSymF,
  S04_STEP4_arrayToile,
  S04_STEP4_arrayTypeCorps,
  S04_STEP6_arrayFixationExtremites,
  S04_STEP7_arrayAccessories,
} from "../../../../../constantes/symbols/SymbolsServicesS04";
import {
  BUTEE_CAOUTCHOUC,
  ENVELOPPE_MATIERE,
  PANTOGRAPHE,
  ROULETTES,
  S08_ANGLE_J,
  S08_ANGLE_K,
  S08_ANGLE_L,
  S08_FIXATION_GROS_ELEMENT,
  S08_FIXATION_PETIT_ELEMENT,
  S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE,
  S08_LONGUEUR_A,
  S08_LONGUEUR_B,
  S08_LONGUEUR_C,
  S08_LONGUEUR_COURSE,
  S08_LONGUEUR_D,
  S08_LONGUEUR_E,
  S08_LONGUEUR_F,
  S08_LONGUEUR_G,
  S08_LONGUEUR_H,
  S08_LONGUEUR_I,
  S08_LONGUEUR_MAX,
  S08_LONGUEUR_MIN,
  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
  S08_SECTION_ENVELOPPE_PLAT,
  S08_SECTION_ENVELOPPE_S,
  S08_SECTION_ENVELOPPE_TOIT,
  S08_SECTION_ENVELOPPE_TOIT_PLAT,
  S08_TYPE_PENTE,
  S08_TYPE_SECTION_ENVELOPPE,
  S08_TYPE_TOIT,
  TOLE_LARMEE,
} from "../../../../../constantes/symbols/SymbolsS08";
import {
  S08_STEP4_arrayMatiere,
  S08_STEP5_arrayShape_pente_C_descendante,
  S08_STEP5_arrayShape_pente_C_montante,
  S08_STEP5_arrayShape_plat_A,
  S08_STEP5_arrayShape_toitS_D,
  S08_STEP5_arrayShape_toit_B_toit,
  S08_STEP5_arrayShape_toit_B_toit_plat,
  S08_STEP5_arrayTypePente,
  S08_STEP5_arrayTypeSection,
  S08_STEP5_arrayTypeToit,
  S08_STEP7_arrayFixationGros,
  S08_STEP7_arrayFixationPetit,
} from "../../../../../constantes/symbols/SymbolsServicesS08";
import {
  COTE_AGRESSE_TOILE,
  DIMENSIONS_EXTREMITE,
  FIXATION_CAISSON,
  MATIERE_EXTREMITE,
  MATIERE_TOILE,
  MAXIMUM_ENROULE,
  POSITION_EXTREMITE,
  POSITION_FIXATION_CAISSON,
  SECTION_MAX,
  TYPE_ENROULEUR,
} from "../../../../../constantes/symbols/SymbolsS05";
import {
  S05_STEP4_arrayMatiereToile,
  S05_STEP4_arrayTypeEnrouleur,
  S05_STEP6_arrayCoteAgresseToile,
  S05_STEP6_arrayDimensionExtremite,
  S05_STEP6_arrayDimensionExtremiteIt1,
  S05_STEP6_arrayDimensionExtremiteIt2,
  S05_STEP6_arrayMatiereExtremite,
  S05_STEP6_arrayPositionExtremite,
  S05_STEP6_arrayPositionFixationCaisson,
  S05_STEP6_arraySystemeFixationCaisson,
} from "../../../../../constantes/symbols/SymbolsServicesS05";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
  internalServiceValueInArray,
} from "../../../../../constantes/symbols/HelperSymbolsServices";
import {
  ALIGNEMENT_IT1,
  ALIGNEMENT_IT2,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT1,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT2,
  BRIDE_FIXATION_IT1,
  BRIDE_FIXATION_IT2,
  DIMENSIONS_PLIS,
  NOMBRE_FACE,
  OUVETRURE,
  S06_ACCESSOIRE,
  S06_BRIDE_IT1,
  S06_BRIDE_IT2,
  S06_EXTREMITE_IDENTIQUE,
  S06_TYPE_EXTREMITES_IT1,
  S06_TYPE_EXTREMITES_IT2,
  S06_LONGUEUR_A_EXT,
  S06_LONGUEUR_A_INT,
  S06_LONGUEUR_B_EXT,
  S06_LONGUEUR_B_INT,
  S06_LONGUEUR_C_EXT,
  S06_LONGUEUR_C_INT,
  S06_TOILE,
} from "../../../../../constantes/symbols/SymbolsS06";
import {
  S06_STEP4_arrayToile,
  S06_STEP5_arrayDimensionsPlis,
  S06_STEP5_arrayNombreFaces,
  S06_STEP6_arrayAlignement,
  S06_STEP6_arrayExtremiteInsereeMatiere,
  S06_STEP6_arrayFixationBride,
  S06_STEP6_arrayTypeExtremites,
  S06_STEP6_arrayTypeBride,
  S06_STEP7_arrayAccessories,
  S06_STEP6_arrayFixationBrideTypeAEtAcier,
} from "../../../../../constantes/symbols/SymbolsServicesS06";
import {
  S06_arrayRemoveBrideIt1,
  S06_arrayRemoveBrideIt2,
  S06_arrayRemoveManchetteIt1,
  S06_arrayRemoveManchetteIt2,
} from "../../../../../constantes/symbols/DimensionsGroupS06";

const commonToDisplay = (champ, listToDisplay, t, booleanIdentite) => {
  if (
    listToDisplay &&
    listToDisplay.affichage &&
    listToDisplay.affichage[champ.nom.description] === false
  ) {
    return null;
  }

  if (champ.nom === QUANTITEE_SOUHAITEE) {
    return (
      <StyledDivRecapGene
        key={QUANTITEE_SOUHAITEE.description}
        style={{ backgroundColor: "#E7EAFF" }}
      >
        {Object.entries(listToDisplay)
          .filter(
            (element) =>
              element[0].slice(0, 13) === QUANTITEE_SOUHAITEE.description
          )
          .map((value, key) => {
            if (listToDisplay[key[0]] !== "") {
              return (
                <div key={key} id={t[value[0]]}>
                  {t[value[0]] + " : "}
                  {listToDisplay[value[0]].join(", ")}
                </div>
              );
            }
            return null;
          })
          .filter((element) => element)}
      </StyledDivRecapGene>
    );
  }
  
  if (champ.nom === COMMENTAIRE) {
    return (
      <StyledDivRecapGene
        id={champ.nom.description}
        key={COMMENTAIRE.description}
      >
        <div id={t.champFabricationUrgence}>
          {t.recapitulatifDelaiSouhaite + " : "}
          {listToDisplay[DELAI.description]
            ? listToDisplay[DELAI.description]
            : t.non}
        </div>
        {booleanIdentite || listToDisplay.marque === "MP" ? (
          <>
            <div id={t.champMontageSurSite}>
              {t.montageSurSite + " : "}
              {listToDisplay[SERVICES.description]?.includes(
                arrayServicesRC()[1].code.description
              )
                ? t.oui
                : t.non}
            </div>
          </>
        ) : (
          <>
            <div id={t.champFabricationUrgence}>
              {t.fabricationUrgence + " : "}
              {listToDisplay[SERVICES.description]?.includes(
                arrayServicesRC()[0].code.description
              )
                ? t.oui
                : t.non}
            </div>
            {/* <div id={t.champLivraisonExpresse}>
                        {t.livraisonExpresse+' : '}
                        {listToDisplay[SERVICESRC.description]
                            .includes(SERVICE_TROIS.description)
                                ?t.oui
                                :t.non} 
                    </div>
                    <div id={t.champEnlevementParVosSoins}>
                        {t.enlevementParVosSoins+' : '}
                        {listToDisplay[SERVICESRC.description]
                            .includes(SERVICE_QUATRE.description)
                                ?t.oui
                                :t.non} 
                    </div> 
                    <div id={t.champLivraisonTransporteur}>
                        {t.livraisonParTransporteur+' : '}
                        {listToDisplay[SERVICESRC.description]
                            .includes(SERVICE_DEUX.description)
                                ?t.oui
                                :t.non} 
                    </div>*/}

            <div id={t.champMontageSurSite}>
              {t.montageSurSite + " : "}
              {listToDisplay[SERVICES.description]?.includes(
                arrayServicesRC()[1].code.description
              )
                ? t.oui
                : t.non}
            </div>
          </>
        )}
        {/* <hr></hr> */}
        {listToDisplay[champ.nom.description]}
      </StyledDivRecapGene>
    );
  }

  if (champ.nom === TEMPERATURE) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      arrayTemperatures
    );
  }

  if (champ.nom === FAMILLE_PROTECTION_INDUSTRIELLE) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      arrayProductFamilly
    );
  }

  if (champ.nom === POSITION_DE_TRAVAIL) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      arrayPositionTravail
    );
  }

  if (
    champ.nom === TYPE_DE_MACHINE ||
    champ.nom === MARQUE_MACHINE ||
    champ.nom === PAYS ||
    champ.nom === SECTEUR ||
    champ.nom === FONCTION
  ) {
    return displayFieldFromService(champ, listToDisplay, champ.service, t);
  }

  if (champ.nom === ENVIRONNEMENT) {
    return (
      <div
        id={t.champEnvironnement}
        key={ENVIRONNEMENT.description || AGRESSIONS.description}
      >
        {t.champEnvironnement + " : "}
        {Array.isArray(listToDisplay[ENVIRONNEMENT.description])
          ? listToDisplay[ENVIRONNEMENT.description]
              .map(
                (agressionSelected) =>
                  champ.nomChamps.find(
                    (agressionServiceSelected) =>
                      agressionServiceSelected.code === agressionSelected
                  ).name
              )
              .join(", ")
          : null}
        {listToDisplay[AGRESSIONS.description]
          ? listToDisplay[AGRESSIONS.description]
              .map(
                (agressionSelected) =>
                  champ.nomChamps.find(
                    (agressionServiceSelected) =>
                      agressionServiceSelected.code === agressionSelected
                  ).name
              )
              .join(", ")
          : null}
        {listToDisplay[ENVIRONNEMENT.description] !== "3"
          ? arrayEnvironnement(t)
              .map((environnementListElement) => {
                if (
                  environnementListElement.code ===
                  parseInt(listToDisplay[ENVIRONNEMENT.description])
                ) {
                  return environnementListElement.name;
                }
                return null;
              })
              .filter((element) => element)
          : null}
      </div>
    );
  }

  return (
    <div id={champ.nom.description} key={champ.nom.description}>
      {champ.label + " : "}
      <span>
        {["0", ""].includes(listToDisplay[champ.nom.description])
          ? t.nonRenseigne
          : listToDisplay[champ.nom.description]}
      </span>
    </div>
  );
};

//------------------------------------------------------------------------------//
const genererChampRecapitulatif = (
  champ,
  listToDisplay,
  t,
  booleanIdentite,
  isFromDDP
) => {
  
  if (
    listToDisplay &&
    listToDisplay.affichage &&
    listToDisplay.affichage[champ.nom.description] === false
  ) {
    return null;
  }

  if (
    arrayCommonSymbolsToDisplay().find(
      (elem) => elem.description === champ.nom.description
    )
  ) {
    return commonToDisplay(champ, listToDisplay, t, booleanIdentite);
  }

  if (champ.nom === RECTANGULAIRE_CONIQUE_JOINDRE_PLAN) {
    return null;
  }

  if (
    (champ.nom === LONGUEUR_COMPRIMEE ||
      champ.nom === RONDE_DROIT_EXTERIEUR_MAX ||
      champ.nom === RONDE_CONIQUE_EXTERIEUR_DROIT_MAX ||
      champ.nom === RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX ||
      champ.nom === RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX ||
      champ.nom === RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX) &&
    listToDisplay[SOUFFLET_AVEC_SPIRE.description] === 0
  ) {
    return null;
  }

  if (champ.nom === FICHIERS) {
    return (
      <div key={"fichiers"}>
        {displayFileArrayIfExist(
          listToDisplay,
          RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
          t.recapRectangulaireConiqueJoindrePlan,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_BRIDE_PERCEE_IT2,
          t.recapJoindrePlanBridePerceeIt1,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_BRIDE_PERCEE_IT1,
          t.recapJoindrePlanBridePerceeIt2,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          FICHIERS_SUPP,
          t.recapFichiersSup,
          isFromDDP
        )}
      </div>
    );
  }

  if (champ.nom === FICHIER_RECAPITULATIF) {
    return (
      <div key={"fichierRecapitulatif"}>
        {displayRecapitulatifFile(listToDisplay, "Recap", isFromDDP)}
      </div>
    );
  }

  if (listToDisplay[champ.nom.description] === undefined) {
    return null;
  }

  if (
    champ.nom === SOUFFLET_AVEC_SPIRE ||
    champ.nom === EXTREMITE_IDENTIQUE ||
    champ.nom === BRIDE_PERCEE_IT1 ||
    champ.nom === BRIDE_PERCEE_IT2
  ) {
    return displayFieldBoolean(champ, t, listToDisplay, isFromDDP);
  }

  if (champ.nom === TYPE_EXTREMITES_IT1 || champ.nom === TYPE_EXTREMITES_IT2) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      arrayTypeExtremites
    );
  }

  if (champ.nom === SECTION_SOUFFLET) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      arraySectionSoufflet
    );
  }

  if (champ.nom === TYPE_CORPS) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      arrayTypeCorps
    );
  }

  if (champ.nom === MANCHETTE_IT1 || champ.nom === MANCHETTE_IT2) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      arraySectionExtremite
    );
  }

  if (
    typeof listToDisplay[champ.nom.description] === "boolean" ||
    champ.nom === FERMETURE_GLISSIERE ||
    champ.nom === OEILLET_FILTRE ||
    champ.nom === BAGUE_GUIDAGE
  ) {
    return displayFieldBoolean(champ, t, listToDisplay, isFromDDP);
  }

  if (
    champ.nom === FIXATION_EXTREMITES_IT1 ||
    champ.nom === FIXATION_EXTREMITES_IT2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      arrayFixationExtremite
    );
  }

  if (listToDisplay[champ.nom.description] === "0") {
    return (
      <div id={champ.nom.description} key={champ.nom.description}>
        {champ.label + " : -"}
      </div>
    );
  }

  return (
    <div id={champ.nom.description} key={champ.nom.description}>
      {champ.label + " : "}
      <span>
        {listToDisplay[champ.nom.description] === "0"
          ? t.nonRenseigne
          : listToDisplay[champ.nom.description]}
      </span>
    </div>
  );
};

//------------------------------------------------------------------------------//
const genererChampRecapitulatifS04 = (
  champ,
  listToDisplay,
  t,
  booleanIdentite,
  isFromDDP
) => {
  if (
    listToDisplay &&
    listToDisplay.affichage &&
    listToDisplay.affichage[champ.nom.description] === false
  ) {
    return null;
  }

  if (
    listToDisplay &&
    listToDisplay.affichage &&
    listToDisplay.affichage[champ.nom.description] === false
  ) {
    return null;
  }

  if (
    arrayCommonSymbolsToDisplay().find(
      (elem) => elem.description === champ.nom.description
    )
  ) {
    return commonToDisplay(champ, listToDisplay, t, booleanIdentite);
  }

  if (champ.nom.description === TYPE_SECTION.description) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S04_STEP4_arrayTypeCorps
    );
  }

  if (
    [
      FORME_CORPS_A.description,
      FORME_CORPS_B.description,
      FORME_CORPS_C.description,
      FORME_CORPS_DS.description,
      FORME_CORPS_D.description,
      FORME_CORPS_ES.description,
      FORME_CORPS_E.description,
      FORME_CORPS_FS.description,
      FORME_CORPS_F.description,
    ].includes(champ.nom.description)
  ) {
    switch (true) {
      case equalsCodeOrLabel(
        listToDisplay[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        0
      ):
        return displayFieldFromInternalService(
          champ,
          t,
          listToDisplay,
          S04_STEP4_arrayFormeCorpsSymA
        );
      case equalsCodeOrLabel(
        listToDisplay[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        1
      ):
        return displayFieldFromInternalService(
          champ,
          t,
          listToDisplay,
          S04_STEP4_arrayFormeCorpsSymB
        );
      case equalsCodeOrLabel(
        listToDisplay[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        2
      ):
        return displayFieldFromInternalService(
          champ,
          t,
          listToDisplay,
          S04_STEP4_arrayFormeCorpsSymC
        );
      case equalsCodeOrLabel(
        listToDisplay[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        3
      ):
        switch (
          equalsBooleanOrStringOrNumber(
            listToDisplay[SECTION_SYMETRIQUE.description]
          )
        ) {
          case 0:
            return displayFieldFromInternalService(
              champ,
              t,
              listToDisplay,
              S04_STEP4_arrayFormeCorpsD
            );
          case 1:
            return displayFieldFromInternalService(
              champ,
              t,
              listToDisplay,
              S04_STEP4_arrayFormeCorpsSymD
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        listToDisplay[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        4
      ):
        switch (
          equalsBooleanOrStringOrNumber(
            listToDisplay[SECTION_SYMETRIQUE.description]
          )
        ) {
          case 0:
            return displayFieldFromInternalService(
              champ,
              t,
              listToDisplay,
              S04_STEP4_arrayFormeCorpsE
            );
          case 1:
            return displayFieldFromInternalService(
              champ,
              t,
              listToDisplay,
              S04_STEP4_arrayFormeCorpsSymE
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        listToDisplay[TYPE_SECTION.description],
        S04_STEP4_arrayTypeCorps(),
        5
      ):
        switch (
          equalsBooleanOrStringOrNumber(
            listToDisplay[SECTION_SYMETRIQUE.description]
          )
        ) {
          case 0:
            return displayFieldFromInternalService(
              champ,
              t,
              listToDisplay,
              S04_STEP4_arrayFormeCorpsF
            );
          case 1:
            return displayFieldFromInternalService(
              champ,
              t,
              listToDisplay,
              S04_STEP4_arrayFormeCorpsSymF
            );
          default:
            return null;
        }
      default:
        return null;
    }
  }

  if (champ.nom.description === TOILE.description) {
    if (isFromDDP === false) {
      return displayFieldFromInternalService(
        champ,
        t,
        listToDisplay,
        S04_STEP4_arrayToile
      );
    } else {
      return (
        <div id={champ.nom.description} key={champ.nom.description}>
          {champ.label + " : "}
          <span>
            {listToDisplay[champ.nom.description]}
          </span>
        </div>
      );
    }
   
  }

  if (champ.nom.description === S04_ACCESSOIRES.description) {
    if (isFromDDP === false) {
      return displayFieldFromInternalService(
        champ,
        t,
        listToDisplay,
        S04_STEP7_arrayAccessories
      );
    } else {
      return (
        <div id={champ.nom.description} key={champ.nom.description}>
          {t.libelleArticleEcaille + " : "}
          <span>
            {!equalsBooleanOrStringOrNumber(
              listToDisplay[champ.nom.description]
            )
              ? t.oui
              : t.non}
          </span>
        </div>
      );
    }
  }

  if (champ.nom.description === S04_ECAILLES.description) {
    if (
      equalsCodeOrLabel(
        listToDisplay[S04_ACCESSOIRES.description],
        S04_STEP7_arrayAccessories(t),
        0
      ) ||
      listToDisplay[champ.nom.description] !== null ||
      listToDisplay[champ.nom.description] !== undefined
    ) {
      return (
        <div id={champ.nom.description} key={champ.nom.description}>
          {!isFromDDP
            ? champ.label + " : "
            : t.libelleArticleEcailleFace + " : "}
          <span>
            {Array.isArray(listToDisplay[champ.nom.description])
              ? listToDisplay[champ.nom.description].join("")
              : listToDisplay[champ.nom.description]}
          </span>
        </div>
      );
    }
    return;
  }

  if (champ.nom === FICHIERS) {
    return (
      <div key={"fichiers"}>
        {displayFileArrayIfExist(
          listToDisplay,
          FICHIERS_SUPP,
          t.recapFichiersSup,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_FORME_QUELCONQUE,
          t.recapJoindrePlanFormeQuelconque,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_EXTREMITES_IT2,
          t.recapJoindrePlanExtremiteIt2,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_EXTREMITES_IT1,
          t.recapJoindrePlanExtremiteIt1,
          isFromDDP
        )}
      </div>
    );
  }

  if (champ.nom === FICHIER_RECAPITULATIF) {
    return (
      <div key={"fichierRecapitulatif"}>
        {displayRecapitulatifFile(listToDisplay, "Recap", isFromDDP)}
      </div>
    );
  }

  if (typeof listToDisplay[champ.nom.description] === "boolean") {
    return displayFieldBoolean(champ, t, listToDisplay, isFromDDP);
  }

  if (listToDisplay[champ.nom.description] === "0") {
    return (
      <div id={champ.nom.description} key={champ.nom.description}>
        {champ.label + " : -"}
      </div>
    );
  }

  if (champ.nom === S04_EXTREMITE_IDENTIQUE) {
    return displayFieldBoolean(champ, t, listToDisplay, isFromDDP);
  }

  if (
    champ.nom === S04_FIXATION_EXTREMITES_IT1 ||
    champ.nom === S04_FIXATION_EXTREMITES_IT2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S04_STEP6_arrayFixationExtremites
    );
  }

  if (listToDisplay[champ.nom.description] === undefined) {
    return null;
  }

  return (
    <div id={champ.nom.description} key={champ.nom.description}>
      {champ.label + " : "}
      <span>
        {listToDisplay[champ.nom.description] === "0"
          ? t.nonRenseigne
          : listToDisplay[champ.nom.description]}
      </span>
    </div>
  );
};

//------------------------------------------------------------------------------//
const genererChampRecapitulatifS05 = (
  champ,
  listToDisplay,
  t,
  booleanIdentite,
  isFromDDP
) => {
  if (
    listToDisplay &&
    listToDisplay.affichage &&
    listToDisplay.affichage[champ.nom.description] === false
  ) {
    return null;
  }

  if (
    arrayCommonSymbolsToDisplay().find(
      (elem) => elem.description === champ.nom.description
    )
  ) {
    return commonToDisplay(champ, listToDisplay, t, booleanIdentite);
  }

  if (champ.nom === TYPE_ENROULEUR) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S05_STEP4_arrayTypeEnrouleur
    );
  }

  if (champ.nom === MATIERE_TOILE) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S05_STEP4_arrayMatiereToile
    );
  }

  if (champ.nom === MATIERE_EXTREMITE) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S05_STEP6_arrayMatiereExtremite
    );
  }

  if (champ.nom === POSITION_EXTREMITE) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S05_STEP6_arrayPositionExtremite
    );
  }
  if (champ.nom === DIMENSIONS_EXTREMITE) {
    return internalServiceValueInArray(
      [
        S05_STEP6_arrayPositionExtremite()[0],
        S05_STEP6_arrayPositionExtremite()[1],
        S05_STEP6_arrayPositionExtremite()[2],
      ],
      listToDisplay[POSITION_EXTREMITE.description]
    )
      ? displayFieldFromInternalService(
          champ,
          t,
          listToDisplay,
          S05_STEP6_arrayDimensionExtremiteIt1
        )
      : displayFieldFromInternalService(
          champ,
          t,
          listToDisplay,
          S05_STEP6_arrayDimensionExtremiteIt2
        );
  }

  if (champ.nom === FIXATION_CAISSON) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S05_STEP6_arraySystemeFixationCaisson
    );
  }
  if (champ.nom === POSITION_FIXATION_CAISSON) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S05_STEP6_arrayPositionFixationCaisson
    );
  }
  if (champ.nom === COTE_AGRESSE_TOILE) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S05_STEP6_arrayCoteAgresseToile
    );
  }

  if (champ.nom === SECTION_MAX) {
    return (
      <div id={champ.nom.description} key={champ.nom.description}>
        {champ.label + " : "}
        <span>
          {listToDisplay[SECTION_MAX.description] === "X"
            ? t.nonRenseigne
            : listToDisplay[SECTION_MAX.description]}
        </span>
      </div>
    );
  }

  if (champ.nom === MAXIMUM_ENROULE) {
    return (
      <div id={champ.nom.description} key={champ.nom.description}>
        {champ.label + " : "}
        <span>
          {listToDisplay[MAXIMUM_ENROULE.description] === ""
            ? t.nonRenseigne
            : listToDisplay[MAXIMUM_ENROULE.description]}
        </span>
      </div>
    );
  }

  if (champ.nom === FICHIERS) {
    return (
      <div key={"fichiers"}>
        {displayFileArrayIfExist(
          listToDisplay,
          RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
          t.recapRectangulaireConiqueJoindrePlan,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_BRIDE_PERCEE_IT2,
          t.recapJoindrePlanBridePerceeIt1,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_BRIDE_PERCEE_IT1,
          t.recapJoindrePlanBridePerceeIt2,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          FICHIERS_SUPP,
          t.recapFichiersSup,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_FORME_QUELCONQUE,
          t.recapJoindrePlanFormeQuelconque,
          isFromDDP
        )}
      </div>
    );
  }

  if (champ.nom === FICHIER_RECAPITULATIF) {
    return (
      <div key={"fichierRecapitulatif"}>
        {displayRecapitulatifFile(listToDisplay, "Recap", isFromDDP)}
      </div>
    );
  }

  if (typeof listToDisplay[champ.nom.description] === "boolean") {
    return displayFieldBoolean(champ, t, listToDisplay, isFromDDP);
  }

  if (listToDisplay[champ.nom.description] === "0") {
    return (
      <div id={champ.nom.description} key={champ.nom.description}>
        {champ.label + " : -"}
      </div>
    );
  }

  if (listToDisplay[champ.nom.description] === undefined) {
    return null;
  }

  return (
    <div id={champ.nom.description} key={champ.nom.description}>
      {champ.label + " : "}
      <span>
        {listToDisplay[champ.nom.description] === "0"
          ? t.nonRenseigne
          : listToDisplay[champ.nom.description]}
      </span>
    </div>
  );
};

//------------------------------------------------------------------------------//
const genererChampRecapitulatifS08 = (
  champ,
  listToDisplay,
  t,
  booleanIdentite,
  isFromDDP
) => {
  if (
    listToDisplay &&
    listToDisplay.affichage &&
    listToDisplay.affichage[champ.nom.description] === false
  ) {
    return null;
  }

  if (
    arrayCommonSymbolsToDisplay().find(
      (elem) => elem.description === champ.nom.description
    )
  ) {
    return commonToDisplay(champ, listToDisplay, t, booleanIdentite);
  }

  if (champ.nom.description === S08_FIXATION_GROS_ELEMENT.description) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S08_STEP7_arrayFixationGros
    );
  }

  if (champ.nom.description === S08_FIXATION_PETIT_ELEMENT.description) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S08_STEP7_arrayFixationPetit
    );
  }

  if (champ.nom.description === ENVELOPPE_MATIERE.description) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S08_STEP4_arrayMatiere
    );
  }

  if (champ.nom.description === S08_TYPE_SECTION_ENVELOPPE.description) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S08_STEP5_arrayTypeSection
    );
  }

  if (champ.nom.description === S08_SECTION_ENVELOPPE_PLAT.description) {
    if (listToDisplay[champ.nom.description] === undefined) {
      return null;
    }
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S08_STEP5_arrayShape_plat_A
    );
  }

  if (champ.nom.description === S08_SECTION_ENVELOPPE_TOIT.description) {
    if (listToDisplay[champ.nom.description] === undefined) {
      return null;
    }
    if (
      equalsCodeOrLabel(
        listToDisplay[S08_TYPE_TOIT.description],
        S08_STEP5_arrayTypeToit(t),
        0
      )
    ) {
      return displayFieldFromInternalService(
        champ,
        t,
        listToDisplay,
        S08_STEP5_arrayShape_toit_B_toit
      );
    }
  }

  if (champ.nom.description === S08_SECTION_ENVELOPPE_TOIT_PLAT.description) {
    if (listToDisplay[champ.nom.description] === undefined) {
      return null;
    }
    if (
      equalsCodeOrLabel(
        listToDisplay[S08_TYPE_TOIT.description],
        S08_STEP5_arrayTypeToit(t),
        1
      )
    ) {
      return displayFieldFromInternalService(
        champ,
        t,
        listToDisplay,
        S08_STEP5_arrayShape_toit_B_toit_plat
      );
    }
  }

  if (
    champ.nom.description ===
    S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description
  ) {
    if (listToDisplay[champ.nom.description] === undefined) {
      return null;
    }
    if (
      equalsCodeOrLabel(
        listToDisplay[S08_TYPE_PENTE.description],
        S08_STEP5_arrayTypePente(t),
        0
      )
    ) {
      return displayFieldFromInternalService(
        champ,
        t,
        listToDisplay,
        S08_STEP5_arrayShape_pente_C_descendante
      );
    }
  }

  if (
    champ.nom.description === S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description
  ) {
    if (listToDisplay[champ.nom.description] === undefined) {
      return null;
    }
    if (
      equalsCodeOrLabel(
        listToDisplay[S08_TYPE_PENTE.description],
        S08_STEP5_arrayTypePente(t),
        1
      )
    ) {
      return displayFieldFromInternalService(
        champ,
        t,
        listToDisplay,
        S08_STEP5_arrayShape_pente_C_montante
      );
    }
  }

  if (champ.nom.description === S08_SECTION_ENVELOPPE_S.description) {
    if (listToDisplay[champ.nom.description] === undefined) {
      return null;
    }
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S08_STEP5_arrayShape_toitS_D
    );
  }

  if (champ.nom.description === S08_TYPE_TOIT.description) {
    if (listToDisplay[champ.nom.description] === undefined) {
      return null;
    }
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S08_STEP5_arrayTypeToit
    );
  }

  if (champ.nom.description === S08_TYPE_PENTE.description) {
    if (listToDisplay[champ.nom.description] === undefined) {
      return null;
    }
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S08_STEP5_arrayTypePente
    );
  }

  if (
    champ.nom.description === S08_LONGUEUR_A.description ||
    champ.nom.description === S08_LONGUEUR_B.description ||
    champ.nom.description === S08_LONGUEUR_C.description ||
    champ.nom.description === S08_LONGUEUR_D.description ||
    champ.nom.description === S08_LONGUEUR_E.description ||
    champ.nom.description === S08_LONGUEUR_F.description ||
    champ.nom.description === S08_LONGUEUR_G.description ||
    champ.nom.description === S08_LONGUEUR_H.description ||
    champ.nom.description === S08_LONGUEUR_I.description ||
    champ.nom.description === S08_ANGLE_J.description ||
    champ.nom.description === S08_ANGLE_K.description ||
    champ.nom.description === S08_ANGLE_L.description
  ) {
    if (listToDisplay[champ.nom.description] === undefined) {
      return null;
    }
  }

  if (champ.nom === S08_LONGUEUR_COURSE) {
    return (
      <div id={champ.nom.description} key={champ.nom.description}>
        {champ.label + " : "}
        <span>
          {listToDisplay[S08_LONGUEUR_MAX.description] -
            listToDisplay[S08_LONGUEUR_MIN.description]}
        </span>
      </div>
    );
  }

  if (
    typeof listToDisplay[champ.nom.description] === "boolean" ||
    champ.nom === BUTEE_CAOUTCHOUC ||
    champ.nom === ROULETTES ||
    champ.nom === PANTOGRAPHE ||
    champ.nom === TOLE_LARMEE
  ) {
    // if (
    //   (champ.nom === ROULETTES ||
    //    champ.nom === BUTEE_CAOUTCHOUC ||
    //    champ.nom === PANTOGRAPHE) &&
    //   listToDisplay?.horsConfigurateur
    // ) {
    //   return null;
    // }
    return displayFieldBoolean(champ, t, listToDisplay, isFromDDP);
  }

  if (champ.nom === FICHIERS) {
    return (
      <div key={"fichiers"}>
        {displayFileArrayIfExist(
          listToDisplay,
          FICHIERS_SUPP,
          t.recapFichiersSup,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE,
          t.recapJoindrePlanFormeQuelconque,
          isFromDDP
        )}
      </div>
    );
  }

  if (champ.nom === FICHIER_RECAPITULATIF) {
    return (
      <div key={"fichierRecapitulatif"}>
        {displayRecapitulatifFile(listToDisplay, "Recap", isFromDDP)}
      </div>
    );
  }

  if (listToDisplay[champ.nom.description] === true) {
    return (
      <StyledDivRecapGene
        id={champ.nom.description}
        key={champ.nom.description}
      >
        {champ.label + " : " + t.oui}
      </StyledDivRecapGene>
    );
  }

  if (listToDisplay[champ.nom.description] === false) {
    return (
      <StyledDivRecapGene
        id={champ.nom.description}
        key={champ.nom.description}
      >
        {champ.label + " : " + t.non}
      </StyledDivRecapGene>
    );
  }

  if (listToDisplay[champ.nom.description] === undefined) {
    return null;
  }

  return (
    <div id={champ.nom.description} key={champ.nom.description}>
      {champ.label + " : "}
      <span>
        {listToDisplay[champ.nom.description] === "0"
          ? t.nonRenseigne
          : listToDisplay[champ.nom.description]}
      </span>
    </div>
  );
};

//------------------------------------------------------------------------------//
const genererChampRecapitulatifS06 = (
  champ,
  listToDisplay,
  t,
  booleanIdentite,
  isFromDDP
) => {
  if (
    listToDisplay &&
    listToDisplay.affichage &&
    listToDisplay.affichage[champ.nom.description] === false
  ) {
    return null;
  }

  if (
    listToDisplay &&
    listToDisplay.affichage &&
    listToDisplay.affichage[champ.nom.description] === false
  ) {
    return null;
  }

  if (
    arrayCommonSymbolsToDisplay().find(
      (elem) => elem.description === champ.nom.description
    )
  ) {
    return commonToDisplay(champ, listToDisplay, t, booleanIdentite);
  }

  if (champ.nom.description === S06_TOILE.description) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP4_arrayToile
    );
  }

  if (champ.nom.description === NOMBRE_FACE.description) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP5_arrayNombreFaces
    );
  }

  if (champ.nom.description === DIMENSIONS_PLIS.description) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP5_arrayDimensionsPlis
    );
  }

  // if (champ.nom === S06_LONGUEUR_A_EXT) {
  //   if (listToDisplay[NOMBRE_FACE.description] === S06_STEP5_arrayNombreFaces(t)[1].code) {
  //     return (
  //       <div id={champ.nom.description} key={champ.nom.description}>
  //         {champ.label + " : "}
  //         <span>
  //           {listToDisplay[S06_LONGUEUR_A_INT.description] + 2 * ( S06_STEP5_arrayDimensionsPlis()[listToDisplay[DIMENSIONS_PLIS.description]]?.label)}
  //         </span>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div id={champ.nom.description} key={champ.nom.description}>
  //         {champ.label + " : "}
  //         <span>
  //           {listToDisplay[S06_LONGUEUR_A_INT.description] + 1 * ( S06_STEP5_arrayDimensionsPlis()[listToDisplay[DIMENSIONS_PLIS.description]]?.label)}
  //         </span>
  //       </div>
  //     );
  //   }

  // }

  // if (champ.nom === S06_LONGUEUR_B_EXT) {
  //   return (
  //     <div id={champ.nom.description} key={champ.nom.description}>
  //       {champ.label + " : "}
  //       <span>
  //         {listToDisplay[S06_LONGUEUR_B_INT.description] + 2 * ( S06_STEP5_arrayDimensionsPlis()[listToDisplay[DIMENSIONS_PLIS.description]]?.label)}
  //       </span>
  //     </div>
  //   );

  // }

  // if (champ.nom === S06_LONGUEUR_C_EXT) {
  //   return (
  //     <div id={champ.nom.description} key={champ.nom.description}>
  //       {champ.label + " : "}
  //       <span>
  //         {listToDisplay[S06_LONGUEUR_C_INT.description] + 1 * ( S06_STEP5_arrayDimensionsPlis()[listToDisplay[DIMENSIONS_PLIS.description]]?.label)}
  //       </span>
  //     </div>
  //   );

  // }

  if (
    champ.nom === S06_TYPE_EXTREMITES_IT1 ||
    champ.nom === S06_TYPE_EXTREMITES_IT2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayTypeExtremites
    );
  }

  // Manchette
  if (
    champ.nom === ALIGNEMENT_IT1 &&
    parseInt(listToDisplay[S06_TYPE_EXTREMITES_IT1.description]) === 1
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayAlignement
    );
  }

  if (
    champ.nom === ALIGNEMENT_IT2 &&
    listToDisplay[S06_EXTREMITE_IDENTIQUE.description] === 0 &&
    parseInt(listToDisplay[S06_TYPE_EXTREMITES_IT2.description]) === 1
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayAlignement
    );
  }

  //Bride
  if (
    champ.nom === S06_BRIDE_IT1 &&
    parseInt(listToDisplay[S06_TYPE_EXTREMITES_IT1.description]) === 2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayTypeBride
    );
  }

  if (
    champ.nom === S06_BRIDE_IT2 &&
    listToDisplay[S06_EXTREMITE_IDENTIQUE.description] === 0 &&
    parseInt(listToDisplay[S06_TYPE_EXTREMITES_IT2.description]) === 2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayTypeBride
    );
  }

  if (
    champ.nom === BRIDE_EXTREMITE_INSEREE_MATIERE_IT1 &&
    parseInt(listToDisplay[S06_TYPE_EXTREMITES_IT1.description]) === 2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayExtremiteInsereeMatiere
    );
  }

  if (
    champ.nom === BRIDE_EXTREMITE_INSEREE_MATIERE_IT2 &&
    listToDisplay[S06_EXTREMITE_IDENTIQUE.description] === 0 &&
    parseInt(listToDisplay[S06_TYPE_EXTREMITES_IT2.description]) === 2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayExtremiteInsereeMatiere
    );
  }

  if (
    champ.nom === BRIDE_FIXATION_IT1 &&
    parseInt(listToDisplay[S06_TYPE_EXTREMITES_IT1.description]) === 2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayFixationBrideTypeAEtAcier
    );
  }

  if (
    champ.nom === BRIDE_FIXATION_IT2 &&
    listToDisplay[S06_EXTREMITE_IDENTIQUE.description] === 0 &&
    parseInt(listToDisplay[S06_TYPE_EXTREMITES_IT2.description]) === 2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayFixationBrideTypeAEtAcier
    );
  }

  // Etape 7
  if (champ.nom.description === OUVETRURE.description) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP7_arrayAccessories
    );
  }

  if (champ.nom === FICHIERS) {
    return (
      <div key={"fichiers"}>
        {displayFileArrayIfExist(
          listToDisplay,
          FICHIERS_SUPP,
          t.recapFichiersSup,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_FORME_QUELCONQUE,
          t.recapJoindrePlanFormeQuelconque,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_EXTREMITES_IT2,
          t.recapJoindrePlanExtremiteIt2,
          isFromDDP
        )}
        {displayFileArrayIfExist(
          listToDisplay,
          JOINDRE_PLAN_EXTREMITES_IT1,
          t.recapJoindrePlanExtremiteIt1,
          isFromDDP
        )}
      </div>
    );
  }

  if (champ.nom === FICHIER_RECAPITULATIF) {
    return (
      <div key={"fichierRecapitulatif"}>
        {displayRecapitulatifFile(listToDisplay, "Recap", isFromDDP)}
      </div>
    );
  }

  if (typeof listToDisplay[champ.nom.description] === "boolean") {
    return displayFieldBoolean(champ, t, listToDisplay, isFromDDP);
  }

  if (listToDisplay[champ.nom.description] === "0") {
    return (
      <div id={champ.nom.description} key={champ.nom.description}>
        {champ.label + " : -"}
      </div>
    );
  }

  if (champ.nom === S06_EXTREMITE_IDENTIQUE) {
    return displayFieldBoolean(champ, t, listToDisplay, isFromDDP);
  }

  if (
    champ.nom === S06_TYPE_EXTREMITES_IT1 ||
    champ.nom === S06_TYPE_EXTREMITES_IT2
  ) {
    return displayFieldFromInternalService(
      champ,
      t,
      listToDisplay,
      S06_STEP6_arrayTypeExtremites
    );
  }

  if (listToDisplay[champ.nom.description] === undefined) {
    return null;
  }

  return (
    <div id={champ.nom.description} key={champ.nom.description}>
      {champ.label + " : "}
      <span>
        {listToDisplay[champ.nom.description] === "0"
          ? t.nonRenseigne
          : listToDisplay[champ.nom.description]}
      </span>
    </div>
  );
};

const genererChampRecapitulatifHorsConfigurateur = (
  listToDisplay,
  groupeToDisplay,
  t
) => {
  if (!listToDisplay?.horsConfigurateur) {
    return;
  }

  return Object.entries(listToDisplay.horsConfigurateur)
    .filter(([key, elem]) => parseInt(elem.groupe) === groupeToDisplay)
    .map(([key, elem]) => {
      let libelle;
      switch (key) {
        case "CARA0052":
          libelle = t.racleur;
          break;
        case "CARA0053":
          libelle = t.roulettes;
          break;
        case "CARA0054":
          libelle = t.pantographe;
          break;
        case "CARA0173":
          libelle = t.buteeCaoutchouc;
          break;
        default:
          libelle = elem.libelle;
          break;
      }

      const formattedValueHeure =
        elem.valueHeure && ["Oui", "Non"].includes(elem.valueHeure)
          ? elem.valueHeure.toLowerCase()
          : elem.valueHeure;

      return (
        <div id={elem.libelle} key={key}>
          {libelle + " : "}
          <span>
            {formattedValueHeure ? formattedValueHeure : elem.valueNum}
          </span>
        </div>
      );
    });
};

const isGroupDataAvailable = (listToDisplay, groupeToDisplay) => {
  if (!listToDisplay?.horsConfigurateur) {
    return false;
  }
  return (
    Object.values(listToDisplay.horsConfigurateur)?.filter(
      (elem) => parseInt(elem.groupe) === groupeToDisplay
    ).length > 0
  );
};

export {
  genererChampRecapitulatif,
  genererChampRecapitulatifS04,
  genererChampRecapitulatifS05,
  genererChampRecapitulatifS08,
  genererChampRecapitulatifHorsConfigurateur,
  isGroupDataAvailable,
  genererChampRecapitulatifS06,
};
