export const Formulary = Object.freeze({
  SEND_FORMULARY: "json/persisterFormulaire/",
  SEND_FORMULARY_CONTACT: "json/persisterContact/",
  SEND_FORMULARY_RECHERCHER: "json/persisterRechercher/",
  SEND_REFERENCE: "json/getAllReference/",
  GET_ALL_REF: "json/referenceList/",
  SEND_ALL_INFO_BY_REF: "json/referenceListById/?reference=",
  DELETE_DDP_BY_REF: "json/deleteDDPByRef/?reference=",
  GET_ODP_BY_ID: "json/SylobOffresDePrix/",
  GET_ARTICLE_BY_ID: "json/SylobCaracteristiquesArticles/",
  FORCE_SEND_MAIL_AND_FILES_BY_DDP: "json/forceSendMailAndFilesByDDP/",
  SEND_FORMULARY_CONTACT_SOS: "json/persisterContactSOS/",
});
