import {
  TYPE_SECTION,
  LONGUEUR_A,
  LONGUEUR_B,
  LONGUEUR_C,
  LONGUEUR_D,
  LONGUEUR_E,
  LONGUEUR_F,
  LONGUEUR_G,
  LONGUEUR_H,
  LONGUEUR_X,
  JOINDRE_PLAN_FORME_QUELCONQUE,
  TOILE,
  SECTION_SYMETRIQUE,
  FORME_CORPS_A,
  FORME_CORPS_D,
  FORME_CORPS_DS,
  FORME_CORPS_C,
  FORME_CORPS_B,
  FORME_CORPS_ES,
  FORME_CORPS_E,
  FORME_CORPS_FS,
  FORME_CORPS_F,
} from "../../../../../constantes/symbols/SymbolsS04";
import {
  initChampsFormikToJsonDefaultArray,
  initChampsFormikToJsonDefaultCustom,
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
  TYPE_SECTION,
  LONGUEUR_A,
  LONGUEUR_B,
  LONGUEUR_C,
  LONGUEUR_D,
  LONGUEUR_E,
  LONGUEUR_F,
  LONGUEUR_G,
  LONGUEUR_H,
  LONGUEUR_X,
  TOILE,
];

const champsStringInitValueZero = [
  FORME_CORPS_A,
  FORME_CORPS_B,
  FORME_CORPS_C,
  FORME_CORPS_DS,
  FORME_CORPS_D,
  FORME_CORPS_ES,
  FORME_CORPS_E,
  FORME_CORPS_FS,
  FORME_CORPS_F,
];

const champsArray = [JOINDRE_PLAN_FORME_QUELCONQUE];

export const initialValuesConstruction = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
      ...initChampsFormikToJsonDefaultArray(champsArray, formulaire),
      ...initChampsFormikToJsonDefaultCustom(
        [SECTION_SYMETRIQUE],
        formulaire,
        0
      ),
      ...initChampsFormikToJsonDefaultCustom(
        champsStringInitValueZero,
        formulaire,
        "1"
      ),
    },
    {}
  );
};
