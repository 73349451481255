import {
  formulaireContactConfirmation,
  formulaireContactConfirmationRechercherEtContactCorps,
  formulaireMessageConfirmation,
  formulaireMessageConfirmationP2,
  formulairePubliciteFps,
  formulaireQuestionnaire,
} from "../ChampsFormulaires/S01/confirmation/ChampsConfirmation";
import { importAll } from "../../../helper/ImportAll";
import {
  StyledDivButtonConfirm,
  StyledDivButtonQuestionnaire,
  StyledDivConfirm,
  StyledDivQuestionnaire,
  StyledSVG,
} from "../styledComponents/StyledDiv";
import {
  StyledImgConfirmHaut,
  StyledImgConfirmBas,
} from "../styledComponents/StyledImg";
import { genererFormulaire } from "../components/generateFormulary/CasesGeneration";
import { StyledCheckRoundedIcon } from "../styledComponents/StyledIcon";
import {
  StyledButtonPageConfirmation,
  StyledButtonTelechargerRecap,
} from "../styledComponents/StyledButton";
import {
  StyledPaperBody,
  StyledPaperConfirmation,
} from "../styledComponents/StyledPaper";
import {
  CONFIGURATEUR,
  CONTACT,
  RECHERCHER,
  SOS,
} from "../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { useEffect } from "react";

export const Confirmation = ({
  state: {
    t,
    styles,
    pdfToDownload,
    booleanIdentite,
    formik,
    reference,
    familleProt,
  },
  action: {
    setEtape,
    setMaxReachedEtape,
    setIsAllowedToQuit,
    resetFormularySentState,
    resetFormulary,
  },
  from,
}) => {
  const cssButton = styles.cssButton;
  const ref = reference || "";

  useEffect(() => {
    setIsAllowedToQuit(true);
  }, []);

  const handleNouvelleDemande = () => {
    resetFormulary();
    resetFormularySentState();
    formik.resetForm();
    setEtape(1);

    if (setMaxReachedEtape !== undefined) {

      setMaxReachedEtape ?? setMaxReachedEtape(1);
    }
  };

  const handleDownloadResume = () => {
    pdfToDownload.save(ref + ".pdf");
  };

  const handleSiteWeb = () => {
    const locationSite = booleanIdentite
      ? "https://moovprotect.com/"
      : "https://www.rcmodeles.com/";
    window.open(locationSite, "_blank");
  };

  const handleQuestionnaire = () => {
    const locationQuestionnaire = "https://forms.office.com/e/09Vj4VvjMS";
    window.open(locationQuestionnaire, "_blank");
  };

  const displayFrom = () => {
    if (from === CONFIGURATEUR) {
      return (
        <StyledDivConfirm>
          <div>
            <div>
              {genererFormulaire(
                formulaireMessageConfirmation(styles, from, t)
              )}
            </div>
            <div>
              <StyledCheckRoundedIcon
                sx={{
                  backgroundColor: "rgb(95, 163, 110, 0.33)",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div>
              {genererFormulaire(
                formulaireContactConfirmation(styles, ref, booleanIdentite, t)
              )}
            </div>
          </div>
          <StyledDivButtonConfirm>
            <StyledButtonTelechargerRecap
              variant="contained"
              className={cssButton}
              type="submit"
              onClick={handleDownloadResume}
            >
              {t.telechargementRecap}
            </StyledButtonTelechargerRecap>
          </StyledDivButtonConfirm>
          <StyledDivQuestionnaire>
            <StyledSVG
              viewBox="0 0 688 202"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                id="Rectangle 33"
                width="688"
                height="202"
                rx="8"
                fill="#2A375C"
              />

              {/* Embed the formulaire inside the SVG */}
              <foreignObject x="50px" y="20px" width="700px" height="100px">
                {genererFormulaire(formulaireQuestionnaire(styles, t))}
              </foreignObject>

              {/* Embed the button inside the SVG */}
              <foreignObject x="200px" y="120px" width="300px" height="70px">
                <StyledDivButtonQuestionnaire>
                  <StyledButtonTelechargerRecap
                    variant="contained"
                    className={cssButton}
                    type="submit"
                    onClick={handleQuestionnaire}
                    sx={{
                      left: "30px",
                    }}
                  >
                    {t.jeParticipe}
                  </StyledButtonTelechargerRecap>
                </StyledDivButtonQuestionnaire>
              </foreignObject>

              <foreignObject x="-430px" y="0px" width="100%" height="100%">
                {/* <StyledDivQuestionnaire> */}
                <img
                  src={importAll()["questionnaire_confirmation.png"]}
                  alt="Questionnaire confirmation"
                  style={{
                    width: "171px",
                    height: "202px",
                    position: "relative",
                    left: "170px",
                  }}
                />
                {/* </StyledDivQuestionnaire> */}
              </foreignObject>
            </StyledSVG>
          </StyledDivQuestionnaire>

          <div>
            {genererFormulaire(formulaireMessageConfirmationP2(styles, t))}
            <StyledDivButtonConfirm>
              <StyledButtonPageConfirmation
                variant="contained"
                className={cssButton}
                type="submit"
                onClick={handleNouvelleDemande}
              >
                {t.nouvelleDemande}
              </StyledButtonPageConfirmation>
              <StyledButtonPageConfirmation
                variant="contained"
                className={cssButton}
                type="submit"
                onClick={handleSiteWeb}
              >
                {t.siteWeb}
              </StyledButtonPageConfirmation>
            </StyledDivButtonConfirm>
          </div>
          <div>
            {genererFormulaire(
              formulairePubliciteFps(styles, booleanIdentite, t, familleProt)
            )}
          </div>
        </StyledDivConfirm>
      );
    }
    if (from === RECHERCHER || from === CONTACT || from === SOS) {
      return (
        <StyledDivConfirm>
          <div>
            <div>
              {genererFormulaire(
                formulaireMessageConfirmation(styles, from, t)
              )}
            </div>
            <div>
              <StyledCheckRoundedIcon
                sx={{
                  backgroundColor: "rgb(95, 163, 110, 0.33)",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div>
              {genererFormulaire(
                formulaireContactConfirmationRechercherEtContactCorps(
                  styles,
                  booleanIdentite,
                  t
                )
              )}
            </div>
          </div>
          <div>
            {genererFormulaire(formulaireMessageConfirmationP2(styles, t))}
            <StyledDivButtonConfirm>
              <StyledButtonPageConfirmation
                variant="contained"
                className={cssButton}
                type="submit"
                onClick={handleNouvelleDemande}
              >
                {t.nouvelleDemande}
              </StyledButtonPageConfirmation>
              <StyledButtonPageConfirmation
                variant="contained"
                className={cssButton}
                type="submit"
                onClick={handleSiteWeb}
              >
                {t.siteWeb}
              </StyledButtonPageConfirmation>
            </StyledDivButtonConfirm>
          </div>
        </StyledDivConfirm>
      );
    }
    return null;
  };

  return (
    <StyledPaperBody elevation={0}>
      <StyledPaperConfirmation elevation={0}>
        <StyledImgConfirmHaut
          src={importAll()["IllustrationConfirmation.png"]}
        />
        {displayFrom()}
        <StyledImgConfirmBas
          src={importAll()["IllustrationConfirmation.png"]}
        />
      </StyledPaperConfirmation>
    </StyledPaperBody>
  );
};
