//Librairies de fonctionnement
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { useTranslations } from "../components/hook/useTranslations";
import usePrompt from "../../../helper/ReactRouterToolbox";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import { LocalStorage } from "../../../constantes/globalName/LocalStorage";
import {
  mergeEtape,
  resetIsFormularySent,
} from "../../model/slice/FormulaireReducer";
import { getAllAgressions } from "../../model/slice/AgressionsReducer";
import { anchorCheck, anchorEtape } from "../../../helper/Anchors";
import {
  ControllerStep,
  renderConfirmation,
  renderErrorDb,
} from "./ControllerStep";
import { useFormik } from "formik";
import { initialValuesProvider } from "./formik/InitialValuesProvider";
import { validationProvider } from "./formik/ValidationProvider.js";
import {
  getAllMarqueMachine,
  getAllTypeMachine,
} from "../../model/slice/MachinesReducer";
import { getAllSecteurs } from "../../model/slice/SecteurReducer";
import { getAllFonctions } from "../../model/slice/FonctionReducer";
import { getAllPays } from "../../model/slice/PaysReducer";
import { controllerMenuStep } from "./ControllerMenuStep";
import {
  JOINDRE_PLAN_BRIDE_PERCEE_IT1,
  JOINDRE_PLAN_BRIDE_PERCEE_IT2,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
} from "../../../constantes/symbols/SymbolsS01";
import {
  FAMILLE_PROTECTION_INDUSTRIELLE,
  FICHIERS_SUPP,
} from "../../../constantes/symbols/SymbolsCommon";
import {
  JOINDRE_PLAN_EXTREMITES_IT1,
  JOINDRE_PLAN_EXTREMITES_IT2,
  JOINDRE_PLAN_FORME_QUELCONQUE,
} from "../../../constantes/symbols/SymbolsS04";

import {
  StyledDivFormContainer,
  StyledDivH1,
  StyledDivNextStepsTitle,
  StyledDivPreviousStepsTitle,
  StyledDivUnderlineH1,
} from "../styledComponents/StyledDiv";
import { StyledPaperBody } from "../styledComponents/StyledPaper";
import {
  StyledSpanIconStep,
  StyledSpanTitleStep,
} from "../styledComponents/StyledSpan";
import { S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE } from "../../../constantes/symbols/SymbolsS08";

import { arrayProductFamilly } from "../../../constantes/symbols/SymbolsServicesCommon";

export const Configurateur = () => {
  //HOOKS
  const dispatch = useDispatch();
  const [
    styles,
    t,
    booleanIdentite,
    pdfHeaderRef,
    pdfBodyRef,
    setIsPdfInCreation,
  ] = useOutletContext();

  //STATES
  const [etape, setEtape] = useState(1); //TODO set to 1
  const [maxReachedEtape, setMaxReachedEtape] = useState(1);
  const [formulary, setFormulary] = useState({});
  const [pdfToDownload, setPdfToDownload] = useState(null);
  const [reference, setReference] = useState(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isAllowedToQuit, setIsAllowedToQuit] = useState(false);
  const [nbChampsQte, setNbChampsQte] = useState();
  const [familleProt, setFamilleProt] = useState();

  //SERVICES
  const [upToDateAgressionsList, setUpToDateAgressionsList] = useState([]);
  const [upToDateSecteursList, setUpToDateSecteursList] = useState([]);
  const [upToDateFonctionsList, setUpToDateFonctionsList] = useState([]);
  const [upToDatePaysList, setUpToDatePaysList] = useState([]);
  const [upToDateTypeMachineList, setUpToDateTypeMachineList] = useState([]);
  const [upToDateAgressionsWithImgList, setUpToDateAgressionsWithImgList] =
    useState([]);
  const [upToDateMarqueMachineList, setUpToDateMarqueMachineList] = useState(
    []
  );
  const [upToDateTraductionsList, setUpToDateTraductionsList] = useState([]);

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);
  const { agressionsList } = useSelector((state) => state.agressions);
  const { formikList } = useSelector((state) => state.formulaire);
  const { isFormularySent } = useSelector((state) => state.formulaire);
  const { typeMachineList, marqueMachineList } = useSelector(
    (state) => state.machines
  );

  const { lang } = useTranslations();

  //CONSTS
  const underlineH1 = styles.underlineH1;
  const previousStepsTitle = styles.previousStepsTitle;
  const maxChampQte = 5;

  //EFFECTS
  const params = useParams();
  useEffect(() => {
    dispatch(resetIsFormularySent());
    dispatch(getAllMarqueMachine());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllAgressions(lang));
    dispatch(getAllTypeMachine(lang));
    dispatch(getAllSecteurs(lang));
    dispatch(getAllFonctions(lang));
    dispatch(getAllPays(lang));
  }, [dispatch, lang]);

  useEffect(() => {
    // Récupérer la valeur du paramètre d'URL
    const urlParams = new URLSearchParams(window.location.search);
    const valueToSave = urlParams.get("value");

    if (valueToSave) {

      // Décoder la valeur de base64
      const decodedValue = atob(valueToSave);

      // Enregistrer la nouvelle valeur dans le localStorage
      localStorage.setItem(LocalStorage.FORMULAIRE_FPS, decodedValue);

      // Supprimer le paramètre d'URL et recharger la page
      window.history.replaceState({}, document.title, window.location.pathname);
      // window.location.reload();
    }
  }, [formulary]);

  useEffect(() => {
    if (
      etape === 1 &&
      formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description] === "" &&
      (localStorage.getItem(LocalStorage.FORMULAIRE_FPS) === null ||
        localStorage.getItem(LocalStorage.FORMULAIRE_FPS)[
          FAMILLE_PROTECTION_INDUSTRIELLE.description
        ] === undefined)
    ) {
      setMaxReachedEtape(1);
    }
  }, [etape]);

  usePrompt(t.leaveMessage, isAllowedToQuit, booleanIdentite);

  useEffect(() => {
    if (!isFirstRender) {
      return;
    }
    const currentEtapeName = controllerMenuStep(
      formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description],
      t
    )[etape - 1];
    if (currentEtapeName !== undefined) {
      anchorEtape(currentEtapeName);
    }
    setIsFirstRender(false);
  }, [isFirstRender]);

  useEffect(() => {
    const formulaireFPS = localStorage.getItem(LocalStorage.FORMULAIRE_FPS)
      ? JSON.parse(atob(localStorage.getItem(LocalStorage.FORMULAIRE_FPS)))
      : null;
    const informationsClient = localStorage.getItem(
      LocalStorage.INFORMATIONS_CLIENT
    )
      ? JSON.parse(atob(localStorage.getItem(LocalStorage.INFORMATIONS_CLIENT)))
      : null;
    if (formulaireFPS) {
      setFamilleProt(
        formulaireFPS[FAMILLE_PROTECTION_INDUSTRIELLE.description]
      );
    }
    dispatch(mergeEtape(Object.assign({}, formulaireFPS, informationsClient)));
  }, []);

  useEffect(() => {
    const max = localStorage.getItem(LocalStorage.MAX_REACH_ETAPE)
      ? JSON.parse(atob(localStorage.getItem(LocalStorage.MAX_REACH_ETAPE)))
      : 1;
    setMaxReachedEtape(max);
  }, []);

  useEffect(() => {
    if (maxReachedEtape < etape) {
      setMaxReachedEtape(etape);
      localStorage.setItem(
        LocalStorage.MAX_REACH_ETAPE,
        btoa(JSON.stringify(maxReachedEtape))
      );
    }
  }, [etape, maxReachedEtape]);

  useEffect(() => {
    if (!formikList) {
      return;
    }
    setFormulary(formikList);
  }, [formikList]);

  useEffect(() => {
    setNbChampsQte(formulary.champQuantite?.filter((el) => el).length || 1);
  }, [formulary]);

  useEffect(() => {
    if (!agressionsList) {
      return;
    }
    setUpToDateAgressionsList(agressionsList);
    setUpToDateAgressionsWithImgList(
      agressionsList.map((element) => {
        return {
          ...element,
          img: element.img + ".jpg",
        };
      })
    );
  }, [agressionsList]);

  useEffect(() => {
    if (!typeMachineList) {
      return;
    }
    setUpToDateTypeMachineList(typeMachineList);
  }, [typeMachineList]);

  useEffect(() => {
    if (!marqueMachineList) {
      return;
    }
    setUpToDateMarqueMachineList(marqueMachineList);
  }, [marqueMachineList]);

  useEffect(() => {
    if (!secteursList) {
      return;
    }
    setUpToDateSecteursList(secteursList);
  }, [secteursList]);

  useEffect(() => {
    if (!fonctionsList) {
      return;
    }
    setUpToDateFonctionsList(fonctionsList);
  }, [fonctionsList]);

  useEffect(() => {
    if (!paysList) {
      return;
    }
    setUpToDatePaysList(paysList);
  }, [paysList]);

  const validationProps = {
    t,
    nbChampsQte,
    upToDateAgressionsList,
    upToDateAgressionsWithImgList,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateFonctionsList,
    upToDateSecteursList,
    upToDatePaysList,
  };

  const formik = useFormik({
    initialValues: {
      ...initialValuesProvider(formulary, maxChampQte, booleanIdentite),
    },
    validationSchema: validationProvider(formulary, validationProps, etape),
    enableReinitialize: true,
    onSubmit: () => {
      handleSubmit();
    },
  });

  useEffect(() => {
    anchorCheck(formik.errors);
  }, [formik.isSubmitting]);

  const handleSubmit = () => {
    if (!formik.isValid) {
      return;
    }
    switchStep(etape + 1);
  };

  const handleRetour = () => {
    if (etape !== maxReachedEtape) {
      if (!formik.isValid) {
        return;
      }
    }
    switchStep(etape - 1);
  };

  const goTo = async (toGo) => {
    if (maxReachedEtape >= toGo) {
      if (etape !== maxReachedEtape) {
        const errors = await formik.validateForm();
        if (Object.entries(errors).length !== 0) {
          formik.handleSubmit();
          return;
        }
      }
      switchStep(toGo);
    }
  };

  const switchStep = (etape) => {
    //set localStorage
    dispatch(mergeEtape(formik.values));
    //reset touched fields for validation
    formik.setTouched({});
    //change etape
    setEtape(etape);
    //trigger anchor
    setIsFirstRender(true);
  };

  const _delete = (obj, prop) => {
    if (obj[FAMILLE_PROTECTION_INDUSTRIELLE.description] !== undefined) {
      return Object.fromEntries(
        Object.entries(obj).filter((fileEntry) => {
          return !prop.includes(fileEntry[0]);
        })
      );
    } else {
      return null;
    }
  };

  const removeImages = (form) => {
    return _delete(form, [
      //S01
      JOINDRE_PLAN_BRIDE_PERCEE_IT1.description,
      JOINDRE_PLAN_BRIDE_PERCEE_IT2.description,
      RECTANGULAIRE_CONIQUE_JOINDRE_PLAN.description,
      FICHIERS_SUPP.description,
      //S04
      JOINDRE_PLAN_FORME_QUELCONQUE.description,
      JOINDRE_PLAN_EXTREMITES_IT1.description,
      JOINDRE_PLAN_EXTREMITES_IT2.description,
      //S08
      S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE.description,
    ]);
  };

  useEffect(() => {
    // Enregistrer la nouvelle valeur de formulary dans le localStorage
    localStorage.setItem(
      LocalStorage.FORMULAIRE_FPS,
      btoa(JSON.stringify(removeImages(formulary)))
    );
  }, [formulary]);

  useEffect(() => {
    if (!formik.values || !params.famille) {
      return;
    }
    if (arrayProductFamilly(t).some((e) => e.code === params.famille)) {
      dispatch(
        mergeEtape(
          Object.assign({}, formik.values, {
            [FAMILLE_PROTECTION_INDUSTRIELLE.description]: params.famille,
          })
        )
      );
      setMaxReachedEtape(1);
    }
  }, [params.famille]);

  const resetFormularySentState = () => {
    dispatch(resetIsFormularySent());
  };

  const resetFormulary = () => {
    setFormulary({});
  };

  const afficherTitre = () => {
    const nbSteps = controllerMenuStep(
      formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description],
      t
    ).length;
    if (
      etape < nbSteps && //!Recap//!Waiting validation//!Confirmation
      isFormularySent.status === 200
    ) {
      return (
        <>
          <StyledDivFormContainer>
            <StyledDivH1>
              <h1>{t.genConfigurateurH1ConfigurezVosProtections}</h1>
              <StyledDivUnderlineH1
                className={underlineH1}
              ></StyledDivUnderlineH1>
            </StyledDivH1>
          </StyledDivFormContainer>
        </>
      );
    }
  };

  const stateUsedForRender = {
    booleanIdentite,
    etape,
    formik,
    formulary,
    pdfBodyRef,
    pdfHeaderRef,
    pdfToDownload,
    reference,
    styles,
    t,
    isFormularySent,
    upToDateAgressionsList,
    upToDateAgressionsWithImgList,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateFonctionsList,
    upToDateSecteursList,
    upToDatePaysList,
    nbChampsQte,
    maxChampQte,
    familleProt,
    lang
  };

  const actionUsedForRender = {
    dispatch,
    setEtape,
    goTo,
    setMaxReachedEtape,
    setNbChampsQte,
    setFormulary,
    setIsPdfInCreation,
    setPdfToDownload,
    setReference,
    setIsAllowedToQuit,
    handleRetour,
    resetFormulary,
    resetFormularySentState,
    setFamilleProt,
  };

  const afficherToutesLesEtapes = () => {
    if (
      stateUsedForRender.isFormularySent.status !== 200 &&
      stateUsedForRender.isFormularySent.isBackendOk !== true
    ) {
      return renderErrorDb(stateUsedForRender, actionUsedForRender);
    }
    if (stateUsedForRender.isFormularySent.isBackendOk) {
      return renderConfirmation(stateUsedForRender, actionUsedForRender);
    }

    const menuSteps = controllerMenuStep(
      formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description],
      t
    );

    const toReturn = menuSteps.map((nom, index) => {
      if (index === etape - 1) {
        return (
          <ControllerStep
            key={"etape" + (index + 1)}
            nom={nom}
            state={stateUsedForRender}
            action={actionUsedForRender}
          />
        );
      }

      if (index >= maxReachedEtape) {
        return (
          <StyledDivNextStepsTitle
            key={"etape" + (index + 1)}
            onClick={async () => await goTo(index + 1)}
          >
            <StyledSpanTitleStep>{nom}</StyledSpanTitleStep>
          </StyledDivNextStepsTitle>
        );
      }

      return (
        <StyledDivPreviousStepsTitle
          className={previousStepsTitle}
          key={"etape" + (index + 1)}
          onClick={() => goTo(index + 1)}
        >
          <StyledSpanTitleStep>{nom}</StyledSpanTitleStep>
          <StyledSpanIconStep>
            <BorderColorOutlinedIcon />
          </StyledSpanIconStep>
        </StyledDivPreviousStepsTitle>
      );
    });

    if (
      etape === menuSteps.length || //Recap
      etape === menuSteps.length + 1 //WaitingValidation
    ) {
      return (
        <ControllerStep
          key={"etape" + etape}
          nom={menuSteps[menuSteps.length - 1]}
          state={stateUsedForRender}
          action={actionUsedForRender}
        />
      );
    } else {
      return <div>{toReturn}</div>;
    }
  };

  return (
    <StyledPaperBody elevation={0}>
      {afficherTitre()}
      {afficherToutesLesEtapes()}
    </StyledPaperBody>
  );
};
