import {
  ADRESSE_MAIL,
  CODE_POSTAL,
  FONCTION,
  NOM,
  PRENOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  initChampsFormikToJsonDefaultAutocomplete,
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";

const champsString = [SOCIETE, CODE_POSTAL, NOM, PRENOM, ADRESSE_MAIL, TELEPHONE];

const champsAutocomplete = [PAYS, SECTEUR, FONCTION];

export const initialValuesCoordonnees = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
      ...initChampsFormikToJsonDefaultAutocomplete(
        champsAutocomplete,
        formulaire
      ),
    },
    {}
  );
};
