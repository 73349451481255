import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { CircularProgress } from "@mui/material";
import ClearSharpIcon from '@mui/icons-material/ClearSharp';

import { styled } from "@mui/system";

export const StyledArrowBackOutlinedIcon = styled(ArrowBackOutlinedIcon)({
    height: '1.5em',
    width: '2em',
    padding: '0 0 0 0.5em',
});

export const StyledIconDropZone = styled(AddCircleOutlineOutlinedIcon)({
    height: '1.5em',
    width: '2em',
    padding: '0 0 0 0.5em',
    color: '#2A375C',
});

export const StyledCheckRoundedIcon = styled(CheckRoundedIcon)({
    color: "#5FA36E",
    fontSize: '7.375em',
    marginBottom: '1%',
    display: 'inline-block',
});


export const StyledCircularProgress = styled(CircularProgress)({
    fontSize: '7.375em',
    marginBottom: '2%',
    display: 'inline-block',
    borderRadius:'50%',
});

export const StyledCircularProgressLoading = styled(CircularProgress)({
    borderRadius:"50%",
    position: "absolute",
    top:"50vh",
    fontSize: '7.375em',
    marginBottom: '2%',
    display: 'inline-block',
});

export const StyledClearSharpIconError = styled(ClearSharpIcon)({
    color: "#FF0000",
    fontSize: '7.375em',
    marginBottom: '2%',
    display: 'inline-block',
    marginTop : '0.8em',
});
