import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderCollaborateur } from "../../redux/vue/HeaderCollaborateur";
import { FooterInternaute } from "../../redux/vue/FooterInternaute";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetStyle } from "../../helper/Styles";
import { useTranslations } from "../../redux/vue/components/hook/useTranslations";
import { setMessage } from "../../redux/model/slice/MessageReducer";
import { rafraichissement } from "../../redux/model/slice/AuthentificationReducer";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";

export const EmployeeWrapper = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const navigate = useNavigate();
  const [iframe, setIframe] = useState();
  
  const dispatch = useDispatch();
  const { t, init } = useTranslations();

  const styles = GetStyle(false);
  const { accessToken } = useSelector((state) => state.authentification);
  const [upToDateReferenceByIdList, setUpToDateReferenceByIdList] = useState();
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") === null ||
      localStorage.getItem("accessToken") === undefined
    ) {
      dispatch(setMessage(t.expiredSession));
      navigate("/" + GuestRoutes.AUTHENTIFICATION);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(rafraichissement());
    dispatch(setMessage(null));
    init();
    setIframe(query.get('iframe'));
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {!iframe ? (
        <HeaderCollaborateur
          role={accessToken?.data.role}
          setUpToDateReferenceByIdList={setUpToDateReferenceByIdList}
          selectedPage={selectedPage}
        />
      ):null}
      <Outlet
        context={[
          styles,
          t,
          setUpToDateReferenceByIdList,
          upToDateReferenceByIdList,
          setSelectedPage,
        ]}
      />
      {!iframe ? <FooterInternaute /> : null}
    </div>
  );
};
