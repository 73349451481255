import {
  S08_ANGLE_J,
  S08_ANGLE_K,
  S08_ANGLE_L,
  S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE,
  S08_LONGUEUR_A,
  S08_LONGUEUR_B,
  S08_LONGUEUR_C,
  S08_LONGUEUR_D,
  S08_LONGUEUR_E,
  S08_LONGUEUR_F,
  S08_LONGUEUR_G,
  S08_LONGUEUR_H,
  S08_LONGUEUR_I,
  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
  S08_SECTION_ENVELOPPE_PLAT,
  S08_SECTION_ENVELOPPE_S,
  S08_SECTION_ENVELOPPE_TOIT,
  S08_SECTION_ENVELOPPE_TOIT_PLAT,
  S08_TYPE_PENTE,
  S08_TYPE_SECTION_ENVELOPPE,
  S08_TYPE_TOIT,
} from "../../../../../constantes/symbols/SymbolsS08";
import {
  initChampsFormikToJsonDefaultArray,
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
  S08_TYPE_SECTION_ENVELOPPE,
  S08_SECTION_ENVELOPPE_PLAT,
  S08_SECTION_ENVELOPPE_TOIT,
  S08_SECTION_ENVELOPPE_TOIT_PLAT,
  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
  S08_SECTION_ENVELOPPE_S,
  S08_TYPE_TOIT,
  S08_TYPE_PENTE,
  S08_LONGUEUR_A,
  S08_LONGUEUR_B,
  S08_LONGUEUR_C,
  S08_LONGUEUR_D,
  S08_LONGUEUR_E,
  S08_LONGUEUR_F,
  S08_LONGUEUR_G,
  S08_LONGUEUR_H,
  S08_LONGUEUR_I,
  S08_ANGLE_J,
  S08_ANGLE_K,
  S08_ANGLE_L,
];

const champsArray = [S08_JOINDRE_PLAN_QUELCONQUE_ENVELOPPE];

export const initialValuesFormeS08 = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
      ...initChampsFormikToJsonDefaultArray(champsArray, formulaire),
    },
    {}
  );
};
