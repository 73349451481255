import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../../../../constantes/symbols/SymbolsServicesCommon";
import { initialValuesFamilleProtectionIndustrielle } from "../../ChampsFormulaires/S01/etape1/InitialValuesFamilleProtectionIndustrielle";
import { initialValuesInformationsMachine } from "../../ChampsFormulaires/S01/etape2/InitialValuesInformationsMachine";
import { initialValuesEnvironnementMachine } from "../../ChampsFormulaires/S01/etape3/InitialValuesEnvironnementMachine";
import { initialValuesDimensionsCorps } from "../../ChampsFormulaires/S01/etape4/InitialValuesDimensionsCorps";
import { initialValuesDimensionsExtremites } from "../../ChampsFormulaires/S01/etape5/InitialValuesDimensionsExtremites";
import { initialValuesAccessoires } from "../../ChampsFormulaires/S01/etape6/InitialValuesAccessoires";
import { initialValuesInformationsComplementaires } from "../../ChampsFormulaires/S01/etape7/InitialValuesInformationsComplementaires";
import { initialValuesCoordonnees } from "../../ChampsFormulaires/S01/etape8/InitialValuesCoordonnees";
import { initialValuesConstruction } from "../../ChampsFormulaires/S04/etape4/initialValuesConstruction";
import { initialValuesDimensionsCorpsS04 } from "../../ChampsFormulaires/S04/etape5/InitialValuesDimensionsCorpsS04";
import { initialValuesDimensionsExtremitesS04 } from "../../ChampsFormulaires/S04/etape6/InitialValuesDimensionsExtremitesS04";
import { initialValuesAccessoiresS04 } from "../../ChampsFormulaires/S04/etape7/InitialValuesAccessoiresS04";
import { initialValuesConstructionS06 } from "../../ChampsFormulaires/S04_06/etape4/initialValuesConstructionS06";
import { initialValuesDimensionsCorpsS06 } from "../../ChampsFormulaires/S04_06/etape5/InitialValuesDimensionsCorpsS06";
import { initialValuesDimensionsExtremitesS06 } from "../../ChampsFormulaires/S04_06/etape6/InitialValuesDimensionsExtremitesS06";
import { initialValuesAccessoiresS06 } from "../../ChampsFormulaires/S04_06/etape7/InitialValuesAccessoiresS06";
import { initialValuesConstructionS05 } from "../../ChampsFormulaires/S05/etape4/initialValuesConstructionS05";
import { initialValuesDimensionsS05 } from "../../ChampsFormulaires/S05/etape5/InitialValuesDimensionsS05";
import { initialValuesExtremitesS05 } from "../../ChampsFormulaires/S05/etape6/InitialValuesExtremitesS05";
import { initialValuesMatiereS08 } from "../../ChampsFormulaires/S08/etape4/initialValuesMatiereS08";
import { initialValuesFormeS08 } from "../../ChampsFormulaires/S08/etape5/initialValuesFormeS08";
import { initialValuesDimensionsS08 } from "../../ChampsFormulaires/S08/etape6/InitialValuesDimensionsS08";
import { initialValuesExtremitesS08 } from "../../ChampsFormulaires/S08/etape7/InitialValuesExtremitesS08";
import { initialValuesS08Accessoires } from "../../ChampsFormulaires/S08/etape8/InitialValuesS08Accessoires";

export const initialValuesProvider = (
  formulary,
  maxChampQte,
  booleanIdentite
) => {
  switch (formulary[FAMILLE_PROTECTION_INDUSTRIELLE.description]) {
    case arrayProductFamilly()[2].code:
      return {
        ...commonInitialValues(formulary, maxChampQte, booleanIdentite),
        ...initialValuesS04(formulary),
      };
    case arrayProductFamilly()[4].code:
      return {
        ...commonInitialValues(formulary, maxChampQte, booleanIdentite),
        ...initialValuesS05(formulary),
      };
    case arrayProductFamilly()[5].code:
      return {
        ...commonInitialValues(formulary, maxChampQte, booleanIdentite),
        ...initialValuesS08(formulary),
      };
    case arrayProductFamilly()[3].code:
      return {
        ...commonInitialValues(formulary, maxChampQte, booleanIdentite),
        ...initialValuesS06(formulary),
      };
    default:
      return {
        ...commonInitialValues(formulary, maxChampQte, booleanIdentite),
        ...initialValuesS01(formulary),
      };
  }
};

const commonInitialValues = (formulary, maxChampQte, booleanIdentite) => {
  return {
    ...initialValuesFamilleProtectionIndustrielle(formulary),
    ...initialValuesInformationsMachine(formulary),
    ...initialValuesEnvironnementMachine(formulary, booleanIdentite),
    ...initialValuesInformationsComplementaires(formulary, maxChampQte),
    ...initialValuesCoordonnees(formulary),
  };
};

const initialValuesS01 = (formulary) => {
  return {
    ...initialValuesDimensionsCorps(formulary),
    ...initialValuesDimensionsExtremites(formulary),
    ...initialValuesAccessoires(formulary),
  };
};

const initialValuesS04 = (formulary) => {
  return {
    ...initialValuesConstruction(formulary),
    ...initialValuesDimensionsCorpsS04(formulary),
    ...initialValuesDimensionsExtremitesS04(formulary),
    ...initialValuesAccessoiresS04(formulary),
  };
};

const initialValuesS05 = (formulary) => {
  return {
    ...initialValuesConstructionS05(formulary),
    ...initialValuesDimensionsS05(formulary),
    ...initialValuesExtremitesS05(formulary),
  };
};

const initialValuesS06 = (formulary) => {
  return {
    ...initialValuesConstructionS06(formulary),
    ...initialValuesDimensionsCorpsS06(formulary),
    ...initialValuesDimensionsExtremitesS06(formulary),
    ...initialValuesAccessoiresS06(formulary),
  };
};

const initialValuesS08 = (formulary) => {
  return {
    ...initialValuesMatiereS08(formulary),
    ...initialValuesFormeS08(formulary),
    ...initialValuesDimensionsS08(formulary),
    ...initialValuesExtremitesS08(formulary),
    ...initialValuesS08Accessoires(formulary),
  };
};
