import { FOCUSED } from "../../../../../constantes/symbols/SymbolsCommon";
import { SOUFFLET_AVEC_SPIRE } from "../../../../../constantes/symbols/SymbolsS01";
import { S04_LONGUEUR_COMPRIMEE, S04_LONGUEUR_DEPLOYEE } from "../../../../../constantes/symbols/SymbolsS04";
import { initChampsFormikToJsonDefaultCustom, initChampsFormikToJsonDefaultString } from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
    S04_LONGUEUR_COMPRIMEE,
    S04_LONGUEUR_DEPLOYEE
];

export const initialValuesDimensionsCorpsS04 = (formulaire) => {
    return Object.assign({
            ...initChampsFormikToJsonDefaultString(champsString, formulaire),
            ...initChampsFormikToJsonDefaultCustom([SOUFFLET_AVEC_SPIRE], [], 1),
            ...initChampsFormikToJsonDefaultCustom([FOCUSED], formulaire, null),
        }, {});
}