import {
  CAP,
  QUANTITEE_SOUHAITEE_CODE_ARTICLE,
} from "../redux/vue/ChampsFormulaires/RechercherChamps";
import { ARTICLES } from "../redux/vue/ChampsFormulaires/RechercherChamps";
import {
  AGRESSIONS,
  ENVIRONNEMENT,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  QUANTITEE_SOUHAITEE,
  TEMPERATURE,
} from "../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../constantes/symbols/SymbolsServicesCommon";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../constantes/symbols/HelperSymbolsServices";
import { BRIDE_EXTREMITE_INSEREE_MATIERE_IT1, BRIDE_FIXATION_IT1, NOMBRE_FACE, S06_BRIDE_IT1, S06_BRIDE_IT2, S06_EXTREMITE_IDENTIQUE, S06_TYPE_EXTREMITES_IT1, S06_TYPE_EXTREMITES_IT2, S06_LONGUEUR_C_EXT, S06_LONGUEUR_C_INT } from "../constantes/symbols/SymbolsS06";
import { S06_STEP5_arrayNombreFaces, S06_STEP6_arrayTypeExtremites } from "../constantes/symbols/SymbolsServicesS06";
import { S06_arrayRemoveBrideIt1, S06_arrayRemoveBrideIt2, S06_arrayRemoveManchetteIt1, S06_arrayRemoveManchetteIt2 } from "../constantes/symbols/DimensionsGroupS06";

export const dataCleanerS06 = (payload) => {
  //ETAPE3
  payload = removeForEnvAutre(payload);
  payload = removeTemperature(payload);

  //ETAPE4
  // payload = removeFormeCorps(payload);
  // payload = removeLongueur(payload);

  //ETAPE5
  payload = removeLongueurCIntEtExt(payload);

  //Etape6_S04
  payload = removeExtremiteIt2S06(payload);
  payload = removeIt2(payload);
  payload = removeBrideIt1(payload);
  payload = removeBrideIt2(payload);
  payload = removeManchetteIt1(payload);
  payload = removeManchetteIt2(payload);

  //ETAPE 7
  payload = removeQteToDontKeep(payload);
  payload = removeEmptyQte(payload);
  return payload;
};

/**
 * Il prend un tableau et un objet, et renvoie un nouvel objet avec les mêmes clés que l'objet
 * d'origine, mais avec les valeurs des clés qui sont dans le tableau supprimées.
 *
 * Voici un exemple de la façon dont cela fonctionne :
 *
 * charge utile const = {
 *   nom : "Jean",
 *   âge : 30 ans,
 *   genre masculin",
 *   adresse : "123 Main St",
 *   ville : "New York",
 *   indiquer : "NY",
 *   code postal : "10001",
 * } ;
 *
 * tableau const = ["adresse", "ville", "état", "zip"] ;
 *
 * const newPayload = retireElements(tableau, charge utile);
 *
 * console.log(newPayload);
 * // { nom : "Jean", âge : 30 ans, sexe : "homme" }
 *
 * @param array - ['nom Age']
 * @param payload - {
 * @returns Un objet avec les éléments de la charge utile qui ne sont pas dans le tableau.
 */
const withdrawElements = (array, payload) => {
  return Object.fromEntries(
    Object.entries(payload)
      .map((element) => {
        if (array.includes(element[0])) {
          return null;
        }
        return element;
      })
      .filter((element) => element)
  );
};


const removeLongueurCIntEtExt = (payload) => {
  const nombreFaces = payload[NOMBRE_FACE.description];
  if (equalsCodeOrLabel(
    nombreFaces,
    S06_STEP5_arrayNombreFaces(),
    1
  )) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] === S06_LONGUEUR_C_INT.description ||
            element[0] === S06_LONGUEUR_C_EXT.description
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
}

/**
 * It takes an object and returns a new object with all the properties that end with "It2" removed.
 * @param payload - {
 * @returns The payload object with the "It2" removed from the end of the key.
 */
const removeIt2 = (payload) => {
  if (
    !!equalsBooleanOrStringOrNumber(payload[S06_EXTREMITE_IDENTIQUE.description])
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0].slice(-2) === "$1") {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * It removes all the keys that start with "bride" and end with "It1" from the payload object.
 * @param payload - {
 * @returns The payload object with the brideIt1 property removed.
 */
const removeBrideIt1 = (payload) => {
  const fixationIt1 = payload[S06_TYPE_EXTREMITES_IT1.description];
  
  if (
    equalsCodeOrLabel(
      fixationIt1,
      S06_STEP6_arrayTypeExtremites(),
      0
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] === S06_BRIDE_IT1.description || 
            element[0] === BRIDE_EXTREMITE_INSEREE_MATIERE_IT1.description ||
            element[0] === BRIDE_FIXATION_IT1.description  ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * It removes all the keys that start with "bride" and end with "It1" from the payload object.
 * @param payload - {
 * @returns The payload object with the brideIt1 property removed.
 */
const removeBrideIt2 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[S06_TYPE_EXTREMITES_IT2.description],
      S06_STEP6_arrayTypeExtremites(),
      0
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (S06_arrayRemoveBrideIt2().includes(element[0])) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * It removes the manchette properties from the payload object if the payload object has a
 * typeExtremitesIt1 property with a value of "bride".
 * @param payload - {
 * @returns The payload object with the manchetteIt1 property removed.
 */
const removeManchetteIt1 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[S06_TYPE_EXTREMITES_IT1.description],
      S06_STEP6_arrayTypeExtremites(),
      1
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (S06_arrayRemoveManchetteIt1().includes(element[0])) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * It removes the manchette properties from the payload object if the payload object has a
 * typeExtremitesIt1 property with a value of "bride".
 * @param payload - {
 * @returns The payload object with the manchetteIt1 property removed.
 */
const removeManchetteIt2 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[S06_TYPE_EXTREMITES_IT2.description],
      S06_STEP6_arrayTypeExtremites(),
      1
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (S06_arrayRemoveManchetteIt2().includes(element[0])) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeExtremiteIt2S06 = (payload) => {
  if (!!payload[S06_EXTREMITE_IDENTIQUE.description] === true) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] === S06_TYPE_EXTREMITES_IT2.description) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeTemperature = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[FAMILLE_PROTECTION_INDUSTRIELLE.description],
      arrayProductFamilly(),
      2
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] === TEMPERATURE.description) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeForEnvAutre = (payload) => {
  if (isNaN(parseInt(payload[ENVIRONNEMENT.description]))) {
    return payload;
  }
  if (parseInt(payload[ENVIRONNEMENT.description]) !== 3) {
    payload = Object.fromEntries(
      Object.entries(payload).map((element) => {
        if (element[0] === AGRESSIONS.description) {
          return [[AGRESSIONS.description], []];
        }
        return element;
      })
    );
  }
  return payload;
};

const removeQteToDontKeep = (payload) => {
  if (payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.slice(
          0,
          payload?.nbFieldToKeep
        ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};

const removeEmptyQte = (payload) => {
  if (payload?.champQuantite !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.filter(Number),
      },
      {}
    );
  }
  return payload;
};

export const removeCodeAndQteToDontKeepOrEmpty = (payload) => {
  if (payload?.articles !== undefined && payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [ARTICLES.description]: payload.articles
          .slice(0, payload.nbFieldToKeep)
          .filter(
            (value) =>
              value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description] !== "" &&
              value[CAP.description] !== "" &&
              !isNaN(value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description])
          ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};
