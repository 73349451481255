import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../../helper/ErrorHandlerMessageProvider";
import { MachinesActionType } from "../../../constantes/actionType/MachinesActionType";
import machinesService from "../../service/MachinesService";
import { langJsonFormatter } from "../../../helper/ServiceCleaner";

export const getAllTypeMachine = createAsyncThunk(
  MachinesActionType.GET_ALL_TYPE_MACHINE,
  async (lang, thunkAPI) => {
    try {
      const reponse = await machinesService.getAllTypeMachine();
      return langJsonFormatter(lang, thunkAPI, reponse);
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllMarqueMachine = createAsyncThunk(
  MachinesActionType.GET_ALL_MARQUE_MACHINE,
  async (thunkAPI) => {
    try {
      const reponse = await machinesService.getAllMarqueMachine();
      return reponse.data;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {};

const machinesSlice = createSlice({
  name: MachinesActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllTypeMachine.fulfilled, (state, action) => {
        state.typeMachineList = action.payload;
      })
      .addCase(getAllTypeMachine.rejected, (state, action) => {
        state.typeMachineList = [];
      })
      .addCase(getAllMarqueMachine.fulfilled, (state, action) => {
        state.marqueMachineList = action.payload.map((element) => {
          return {
            code: element.IDSylob,
            name: element.nom,
          };
        });
      })
      .addCase(getAllMarqueMachine.rejected, (state, action) => {
        state.marqueMachineList = [];
      });
  },
});

const { reducer } = machinesSlice;
export default reducer;
