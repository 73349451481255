import * as Yup from "yup";
import {
  JOINDRE_PLAN_EXTREMITES_IT1,
  JOINDRE_PLAN_EXTREMITES_IT2,
  S04_EXTREMITE_IDENTIQUE,
  S04_FIXATION_EXTREMITES_IT1,
  S04_FIXATION_EXTREMITES_IT2,
} from "../../../../../constantes/symbols/SymbolsS04";
import { S04_STEP6_arrayFixationExtremites } from "../../../../../constantes/symbols/SymbolsServicesS04";

/* Setting the maximum size of a file to 10MB. */
const FILE_SIZE = 10000000;
/* A constant that contains the list of supported formats. */
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/bmp",
  "image/png",
];

const otherwiseSchema = Yup.mixed().optional();

/* A Yup validation schema. */
export const validDimensionsExtremitesS04 = (t, etape) => {
  if (etape !== 6) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    //IT1
    [S04_FIXATION_EXTREMITES_IT1.description]: Yup.mixed().test(
      "fixationManchette",
      () => {
        return t.erreurSelectionFixation;
      },
      (value) =>
        S04_STEP6_arrayFixationExtremites(t).some((e) => e.code === value)
    ),

    //IT2
    [S04_FIXATION_EXTREMITES_IT2.description]: Yup.mixed().when(
      [S04_EXTREMITE_IDENTIQUE.description].toString(),
      {
        is: (boolean) => !!boolean === false,
        then: Yup.mixed().test(
          "fixationManchette",
          t.erreurSelectionFixation,
          (value) => {
            return S04_STEP6_arrayFixationExtremites(t).some(
              (e) => e.code === value
            );
          }
        ),
        otherwise: otherwiseSchema,
      }
    ),

    [JOINDRE_PLAN_EXTREMITES_IT1.description]: Yup.mixed()
      .when([S04_FIXATION_EXTREMITES_IT1.description], {
        is: (extremite) =>
          [
            S04_STEP6_arrayFixationExtremites()[5].code,
            S04_STEP6_arrayFixationExtremites()[6].code,
            S04_STEP6_arrayFixationExtremites()[7].code,
            S04_STEP6_arrayFixationExtremites()[8].code,
          ].includes(parseInt(extremite)),
        then: Yup.mixed()
          .test(
            "fileSize",
            (fileList) => {
              return (
                t.erreurFichierVolumineux +
                getNameFilesTooBig(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFilesTooBig(fileList).length === 0;
            }
          )
          .test(
            "fileCount",
            (fileList) => {
              return t.erreurFichierMaxAtteint + fileList.length;
            },
            (fileList) => {
              return fileList.length <= 10;
            }
          )
          .test(
            "fileCountZero",
            t.planRequis,
            (fileList) => {
              return fileList.length > 0;
            }
          )
          .test(
            "fileFormat",
            (fileList) => {
              return (
                t.erreurFormatFichier +
                getNameFileWrongFormat(fileList.originalValue).map((file) => {
                  return file.name + "\n";
                })
              );
            },
            (fileList) => {
              return getNameFileWrongFormat(fileList).length === 0;
            }
          ),
          otherwise: otherwiseSchema,
      }),

    [JOINDRE_PLAN_EXTREMITES_IT2.description]: Yup.mixed()
      .when(
        [
          [S04_FIXATION_EXTREMITES_IT2.description].toString(),
          [S04_EXTREMITE_IDENTIQUE.description].toString(),
        ],
        {
          is: (extremite, boolean) =>
            [
              S04_STEP6_arrayFixationExtremites()[5].code,
              S04_STEP6_arrayFixationExtremites()[6].code,
              S04_STEP6_arrayFixationExtremites()[7].code,
              S04_STEP6_arrayFixationExtremites()[8].code,
            ].includes(parseInt(extremite)) && !!boolean == false,
          then: Yup.mixed()
            .test(
              "fileSize",
              (fileList) => {
                return (
                  t.erreurFichierVolumineux +
                  getNameFilesTooBig(fileList.originalValue).map((file) => {
                    return file.name + "\n";
                  })
                );
              },
              (fileList) => {
                return getNameFilesTooBig(fileList).length === 0;
              }
            )
            .test(
              "fileCount",
              (fileList) => {
                return t.erreurFichierMaxAtteint + fileList.length;
              },
              (fileList) => {
                return fileList.length <= 10;
              }
            )
            .test(
              "fileCountZero",
              (fileList) => {
                return t.planRequis;
              },
              (fileList) => {
                return fileList.length > 0;
              }
            )
            .test(
              "fileFormat",
              (fileList) => {
                return (
                  t.erreurFormatFichier +
                  getNameFileWrongFormat(fileList.originalValue).map((file) => {
                    return file.name + "\n";
                  })
                );
              },
              (fileList) => {
                return getNameFileWrongFormat(fileList).length === 0;
              }
            ),
            otherwise: otherwiseSchema,
        }
      ),
  });
};

/**
 * Given a list of files, return a list of files that are too big
 * @param listFiles - the list of files to be filtered
 * @returns An array of objects.
 */
const getNameFilesTooBig = (listFiles) => {
  return listFiles.filter((file) => file.size > FILE_SIZE);
};

/**
 * Given a list of files, return a list of files that are not of a supported format
 * @param listFiles - The list of files to be filtered.
 * @returns An array of files that are not supported.
 */
const getNameFileWrongFormat = (listFiles) => {
  return listFiles.filter((file) => !SUPPORTED_FORMATS.includes(file.type));
};
