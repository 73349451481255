import { OUVETRURE } from "../../../../../constantes/symbols/SymbolsS06";
import { S06_STEP7_arrayAccessories } from "../../../../../constantes/symbols/SymbolsServicesS06";
import { importAll } from "../../../../../helper/ImportAll";
import {
  BUTTON_GROUP,
  BUTTON_GROUP_IMAGE,
  TEXT,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_OUVERTURE = Symbol("Ouverture");

export const formulaireAccessoiresS06 = (styles, t) => [
  {
    nom: TITRE_OUVERTURE,
    label: t.ouvertureS06,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0040_Q00150,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: OUVETRURE,
    label: "",
    type: BUTTON_GROUP,
    images: importAll(),
    // img: "ecailles.jpg",
    cssMarginFormSection: styles.sectionFormMarginB,
    cssFontFamily: styles.cssFontFamily,
    cssRadio: styles.cssRadio,
    donnees: S06_STEP7_arrayAccessories(t),
  },
];
