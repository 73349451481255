import {
  COMMENTAIRE,
  DELAI,
  FICHIERS_SUPP,
  QUANTITEE_SOUHAITEE,
  SERVICES,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  initChampsFormikToJsonDefaultArray,
  initChampsFormikToJsonDefaultString,
  initChampsFormikToJsonIteratifDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";

const champsString = [COMMENTAIRE, DELAI];

const champsArray = [FICHIERS_SUPP, SERVICES];

export const initialValuesInformationsComplementaires = (
  formulaire,
  maxChampQte
) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
      ...initChampsFormikToJsonDefaultArray(champsArray, formulaire),
      ...initChampsFormikToJsonIteratifDefaultString(
        QUANTITEE_SOUHAITEE,
        formulaire,
        maxChampQte
      ),
    },
    {}
  );
};
