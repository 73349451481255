import {
  CAP,
  QUANTITEE_SOUHAITEE_CODE_ARTICLE,
} from "../redux/vue/ChampsFormulaires/RechercherChamps";
import {
  BRIDE_A_IT1,
  BRIDE_A_IT2,
  BRIDE_B_IT1,
  BRIDE_B_IT2,
  BRIDE_DIAMETRE_IT1,
  BRIDE_DIAMETRE_IT2,
  BRIDE_PERCEE_IT1,
  BRIDE_PERCEE_IT2,
  JOINDRE_PLAN_BRIDE_PERCEE_IT1,
  JOINDRE_PLAN_BRIDE_PERCEE_IT2,
  MANCHETTE_A_IT1,
  MANCHETTE_A_IT2,
  MANCHETTE_B_IT1,
  MANCHETTE_B_IT2,
  MANCHETTE_DIAMETRE_IT1,
  MANCHETTE_DIAMETRE_IT2,
  SOUFFLET_AVEC_SPIRE,
  TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT2,
  BRIDE_IT1,
  BRIDE_IT2,
  MANCHETTE_IT1,
  MANCHETTE_IT2,
  SECTION_SOUFFLET,
  TYPE_CORPS,
  EXTREMITE_IDENTIQUE,
} from "../constantes/symbols/SymbolsS01.js";
import { ARTICLES } from "../redux/vue/ChampsFormulaires/RechercherChamps";
import {
  arraySectionExtremite,
  arraySectionSoufflet,
  arrayTypeCorps,
  arrayTypeExtremites,
} from "../constantes/symbols/SymbolsServicesS01";
import {
  arrayCaseRondDroit,
  arrayCaseRondConique,
  arrayCaseRectangulaireConique,
  arrayCaseRectangulaireDroit,
  arrayRemoveManchetteIt1,
  arrayRemoveManchetteIt2,
  arrayRemoveBrideIt1,
  arrayRemoveBrideIt2,
  arrayCaseSouffletSansSpire,
} from "../constantes/symbols/DimensionsGroup.js";
import {
  AGRESSIONS,
  ENVIRONNEMENT,
  QUANTITEE_SOUHAITEE,
} from "../constantes/symbols/SymbolsCommon";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../constantes/symbols/HelperSymbolsServices";

export const dataCleaner = (payload) => {
  //ETAPE3
  payload = removeForEnvAutre(payload);
  //ETAPE4
  payload = removeSouffletSansSpire(payload);
  payload = caseRondeDroit(payload);
  payload = caseRondeConique(payload);
  payload = caseRectangulaireDroit(payload);
  payload = caseRectangulaireConique(payload);

  //ETAPE 5
  payload = removeIt2(payload);
  payload = removeBrideIt1(payload);
  payload = removeBrideIt2(payload);
  payload = removeManchetteIt1(payload);
  payload = removeManchetteIt2(payload);
  payload = removeManchetteRondeIt1(payload);
  payload = removeManchetteRondeIt2(payload);
  payload = removeManchetteRectangulaireIt1(payload);
  payload = removeManchetteRectangulaireIt2(payload);
  payload = removeBrideRondeIt1(payload);
  payload = removeBrideRondeIt2(payload);
  payload = removeBrideRectangulaireIt1(payload);
  payload = removeBrideRectangulaireIt2(payload);
  payload = removeBridePerceeIt1(payload);
  payload = removeBridePerceeIt2(payload);

  //ETAPE 7
  payload = removeQteToDontKeep(payload);
  payload = removeEmptyQte(payload);
  return payload;
};

const removeSouffletSansSpire = (payload) => {
  if (
    !equalsBooleanOrStringOrNumber(payload[SOUFFLET_AVEC_SPIRE.description])
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (arrayCaseSouffletSansSpire().includes(element[0])) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeForEnvAutre = (payload) => {
  if (isNaN(parseInt(payload[ENVIRONNEMENT.description]))) {
    return payload;
  }
  if (parseInt(payload[ENVIRONNEMENT.description]) !== 3) {
    payload = Object.fromEntries(
      Object.entries(payload).map((element) => {
        if (element[0] === AGRESSIONS.description) {
          return [[AGRESSIONS.description], []];
        }
        return element;
      })
    );
  }
  return payload;
};

/**
 * It takes an object and returns a new object with all the properties that end with "It2" removed.
 * @param payload - {
 * @returns The payload object with the "It2" removed from the end of the key.
 */
const removeIt2 = (payload) => {
  if (
    !!equalsBooleanOrStringOrNumber(payload[EXTREMITE_IDENTIQUE.description])
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0].slice(-2) === "$1") {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * It removes all the keys that start with "bride" and end with "It1" from the payload object.
 * @param payload - {
 * @returns The payload object with the brideIt1 property removed.
 */
const removeBrideIt1 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[TYPE_EXTREMITES_IT1.description],
      arrayTypeExtremites(),
      0
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (arrayRemoveBrideIt1().includes(element[0])) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * If the payload has a typeExtremitesIt2 property with a value of "manchette", then remove all
 * properties that start with "bride" and end with "It2" or have a value of "bridePerceeIt2" from the
 * payload.
 * @param payload - {
 * @returns The payload object with the keys that match the conditions.
 */
const removeBrideIt2 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[TYPE_EXTREMITES_IT2.description],
      arrayTypeExtremites(),
      0
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] === BRIDE_PERCEE_IT2.description) {
            return element;
          }
          if (arrayRemoveBrideIt2().includes(element[0])) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * It removes the manchette properties from the payload object if the payload object has a
 * typeExtremitesIt1 property with a value of "bride".
 * @param payload - {
 * @returns The payload object with the manchetteIt1 property removed.
 */
const removeManchetteIt1 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[TYPE_EXTREMITES_IT1.description],
      arrayTypeExtremites(),
      1
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (arrayRemoveManchetteIt1().includes(element[0])) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * If the payload has a property with the name of the first argument, and the value of that property is
 * the second argument, then remove that property from the payload.
 * @param payload - {
 * @returns The payload is being returned.
 */
const removeManchetteIt2 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[TYPE_EXTREMITES_IT2.description],
      arrayTypeExtremites(),
      1
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (arrayRemoveManchetteIt2().includes(element[0])) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * If the payload has a property called manchetteIt1 with a value of "rectangulaire", then remove the
 * properties manchetteAIt1 and manchetteBIt1 from the payload.
 * @param payload - {
 * @returns The payload is being returned.
 */
const removeManchetteRondeIt1 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[MANCHETTE_IT1.description],
      arraySectionExtremite(),
      1
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] !== MANCHETTE_DIAMETRE_IT1.description) return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * Il supprime les propriétés "manchetteAIt2" et "manchetteBIt2" de l'objet payload si la propriété
 * "manchetteIt2" est égale à "rectangulaire".
 * @param payload - {
 * @returns L'objet de charge utile avec les propriétés manchetteAIt2 et manchetteBIt2 supprimées.
 */
const removeManchetteRondeIt2 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[MANCHETTE_IT2.description],
      arraySectionExtremite(),
      1
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] !== MANCHETTE_DIAMETRE_IT2.description) return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * Il supprime la propriété "manchetteDiametreIt1" de la charge utile si la propriété "manchetteIt1"
 * est égale à "ronde".
 * @param payload - {
 * @returns L'objet de charge utile avec la propriété manchetteDiametreIt1 supprimée.
 */
const removeManchetteRectangulaireIt1 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[MANCHETTE_IT1.description],
      arraySectionExtremite(),
      0
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] !== MANCHETTE_A_IT1.description &&
            element[0] !== MANCHETTE_B_IT1.description
          )
            return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * Il supprime la propriété "manchetteDiametreIt2" de la charge utile si la propriété "manchetteIt2"
 * est égale à "ronde".
 * @param payload - {
 * @returns L'objet de charge utile avec la propriété manchetteDiametreIt2 supprimée.
 */
const removeManchetteRectangulaireIt2 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[MANCHETTE_IT2.description],
      arraySectionExtremite(),
      0
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] !== MANCHETTE_A_IT2.description &&
            element[0] !== MANCHETTE_B_IT2.description
          )
            return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeBrideRondeIt1 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[BRIDE_IT1.description],
      arraySectionExtremite(),
      1
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] !== BRIDE_DIAMETRE_IT1.description) return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeBrideRondeIt2 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[BRIDE_IT2.description],
      arraySectionExtremite(),
      1
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] !== BRIDE_DIAMETRE_IT2.description) return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeBrideRectangulaireIt1 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[BRIDE_IT1.description],
      arraySectionExtremite(),
      0
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] !== BRIDE_A_IT1.description &&
            element[0] !== BRIDE_B_IT1.description
          )
            return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeBrideRectangulaireIt2 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[BRIDE_IT2.description],
      arraySectionExtremite(),
      0
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] !== BRIDE_A_IT2.description &&
            element[0] !== BRIDE_B_IT2.description
          )
            return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * Si la charge utile a une propriété bridePerceeIt1 qui est fausse, alors supprimez la propriété
 * joindrePlanBridePerceeIt1 de la charge utile.
 * @param payload - {
 * @returns L'objet de charge utile avec la clé "joindrePlanBridePerceeIt1" supprimée.
 */
const removeBridePerceeIt1 = (payload) => {
  if (!equalsBooleanOrStringOrNumber(payload[BRIDE_PERCEE_IT1.description])) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] !== JOINDRE_PLAN_BRIDE_PERCEE_IT1.description)
            return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

/**
 * Si la charge utile a une propriété bridePerceeIt2 qui est fausse, alors supprimez la propriété
 * joindrePlanBridePerceeIt2 de la charge utile.
 * @param payload - {
 * @returns L'objet de charge utile avec la clé "joindrePlanBridePerceeIt2" supprimée.
 */
const removeBridePerceeIt2 = (payload) => {
  if (!equalsBooleanOrStringOrNumber(payload[BRIDE_PERCEE_IT2.description])) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] !== JOINDRE_PLAN_BRIDE_PERCEE_IT2.description)
            return element;
          return null;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const caseRondeDroit = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[SECTION_SOUFFLET.description],
      arraySectionSoufflet(),
      0
    ) &&
    equalsCodeOrLabel(payload[TYPE_CORPS.description], arrayTypeCorps(), 0)
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (arrayCaseRondDroit().includes(element[0])) return null;
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const caseRondeConique = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[SECTION_SOUFFLET.description],
      arraySectionSoufflet(),
      0
    ) &&
    equalsCodeOrLabel(payload[TYPE_CORPS.description], arrayTypeCorps(), 1)
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (arrayCaseRondConique().includes(element[0])) return null;
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const caseRectangulaireDroit = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[SECTION_SOUFFLET.description],
      arraySectionSoufflet(),
      1
    ) &&
    equalsCodeOrLabel(payload[TYPE_CORPS.description], arrayTypeCorps(), 0)
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (arrayCaseRectangulaireDroit().includes(element[0])) return null;
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const caseRectangulaireConique = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[SECTION_SOUFFLET.description],
      arraySectionSoufflet(),
      1
    ) &&
    equalsCodeOrLabel(payload[TYPE_CORPS.description], arrayTypeCorps(), 1)
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (arrayCaseRectangulaireConique().includes(element[0])) return null;
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeQteToDontKeep = (payload) => {
  if (payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.slice(
          0,
          payload?.nbFieldToKeep
        ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};

const removeEmptyQte = (payload) => {
  if (undefined !== payload[QUANTITEE_SOUHAITEE.description]) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.filter(Number),
      },
      {}
    );
  }
  return payload;
};

export const removeCodeAndQteToDontKeepOrEmpty = (payload) => {
  if (payload?.articles !== undefined && payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [ARTICLES.description]: payload.articles
          .slice(0, payload.nbFieldToKeep)
          .filter(
            (value) =>
              value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description] !== "" &&
              value[CAP.description] !== "" &&
              !isNaN(value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description])
          ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};
