import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { setMessage } from "./MessageReducer";
import {
  errorHandlerMessageProvider,
  errorHandlerMessageProviderByCode,
} from "../../../helper/ErrorHandlerMessageProvider";
import { FormulaireActionType } from "../../../constantes/actionType/FormulaireActionType";
import { removeCodeAndQteToDontKeepOrEmpty } from "../../../helper/DataCleaner";
import formularyService from "../../service/FormularyService";
import { LocalStorage } from "../../../constantes/globalName/LocalStorage";
import { serviceCleaner } from "../../../helper/ServiceCleaner";

export const mergeEtape = createAsyncThunk(
  FormulaireActionType.MERGE_ETAPE,
  async (payload, thunkAPI) => {
    try {
      //payload = dataCleaner(payload);
      return payload;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const sendFormulaire = createAsyncThunk(
  FormulaireActionType.SEND_FORMULARY,
  async (payload, thunkAPI) => {
    try {
      const reponse = await formularyService.sendFormulary(payload);
      return { isBackendOk: reponse.data, status: reponse.status };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const sendFormulaireContact = createAsyncThunk(
  FormulaireActionType.SEND_FORMULARY_CONTACT,
  async (payload, thunkAPI) => {
    try {
      const reponse = await formularyService.sendFormularyContact(payload);
      return { isBackendOk: reponse.data, status: reponse.status };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const sendFormulaireContactSOS = createAsyncThunk(
  FormulaireActionType.SEND_FORMULARY_CONTACT_SOS,
  async (payload, thunkAPI) => {
    try {
      const reponse = await formularyService.sendFormularyContactSOS(payload);
      return { isBackendOk: reponse.data, status: reponse.status };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const sendFormulaireRechercher = createAsyncThunk(
  FormulaireActionType.SEND_FORMULARY_RECHERCHER,
  async (payload, thunkAPI) => {
    try {
      payload = removeCodeAndQteToDontKeepOrEmpty(payload);
      const reponse = await formularyService.sendFormularyRechercher(payload);
      return { isBackendOk: reponse.data, status: reponse.status };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllReference = createAsyncThunk(
  FormulaireActionType.SEND_FORMULARY_REFERENCE,
  async (payload, thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = (await formularyService.getAllReference(accessToken))
        .data;
      return { reponse };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllInfoByRef = createAsyncThunk(
  FormulaireActionType.GET_ONE_FORM_BY_REFERENCE,
  async ({ reference }, thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = await formularyService.getAllInfoByRef(
        reference,
        accessToken
      );
      return { recapitulatif: reponse.data, status: reponse.status };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const forceSendMailAndFilesByDDP = createAsyncThunk(
  FormulaireActionType.FORCE_SEND_MAIL_AND_FILES_BY_DDP,
  async ({ reference }, thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = await formularyService.forceSendMailAndFilesByDDP(
        reference,
        accessToken
      );

      switch (reponse.data.code) {
        case 200:
          errorHandlerMessageProvider(
            "Fichiers renvoyés pour " + reference,
            thunkAPI
          );
          break;
        default:
          errorHandlerMessageProviderByCode(reponse.data, thunkAPI);
          break;
      }
      return true;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const triggerForceSendMailAndFilesByDDP = createAsyncThunk(
  FormulaireActionType.TRIGGER_FORCE_SEND_MAIL_AND_FILES_BY_DDP,
  async (thunkAPI) => {
    return false;
  }
);

export const deleteDDPByRef = createAsyncThunk(
  FormulaireActionType.DELETE_DDP_BY_REF,
  async ({ marque, reference }, thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = await formularyService.deleteDDPByRef(
        marque,
        reference,
        accessToken
      );
      return { status: reponse.status };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const setAllInfoByRefNull = createAsyncThunk(
  FormulaireActionType.SEND_FORMULARY_NULL,
  async (thunkAPI) => {
    try {
      return undefined;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const resetIsFormularySent = createAsyncThunk(
  FormulaireActionType.RESET_IS_FORMULARY_SENT,
  async (thunkAPI) => {
    return { isBackendOk: false, status: 200 };
  }
);

export const getOdpById = createAsyncThunk(
  FormulaireActionType.GET_ODP_BY_ID,
  async (id, thunkAPI) => {
    const accessToken = {
      [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
        LocalStorage.ACCESS_TOKEN
      ),
    };
    const reponse = await formularyService.getOdpById(id, accessToken);
    return reponse.data;
  }
);

export const getArtByIdFps = createAsyncThunk(
  FormulaireActionType.GET_ARTICLE_BY_ID_FPS,
  async (id, thunkAPI) => {
    const accessToken = {
      [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
        LocalStorage.ACCESS_TOKEN
      ),
    };
    const reponse = await formularyService.getArtByIdFps(id, accessToken);
    return reponse.data;
  }
);

export const getArtByIdRole = createAsyncThunk(
  FormulaireActionType.GET_ARTICLE_BY_ID_ROLE,
  async ({ art, aff }, thunkAPI) => {
    const reponse = await formularyService.getArtByIdRole(art, aff);
    return reponse.data;
  }
);

const initialState = {
  isFormularySent: {
    isBackendOk: false,
    status: 200,
  },
};

const formulaireSlice = createSlice({
  name: FormulaireActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(mergeEtape.fulfilled, (state, { payload }) => {
        state.formikList = payload;
      })
      .addCase(sendFormulaire.fulfilled, (state, { payload }) => {
        state.isFormularySent = Object.assign(
          {},
          {
            isBackendOk: payload.isBackendOk,
            status: payload.status,
          }
        );
        if (state.isFormularySent.status === 200) {
          state.formikList = {};
          localStorage.removeItem(LocalStorage.FORMULAIRE_FPS);
          localStorage.removeItem(LocalStorage.MAX_REACH_ETAPE);
        }
      })
      .addCase(sendFormulaireContact.fulfilled, (state, { payload }) => {
        state.isFormularySent = Object.assign(
          {},
          {
            isBackendOk: payload.isBackendOk,
            status: payload.status,
          }
        );
      })
      .addCase(sendFormulaireContactSOS.fulfilled, (state, { payload }) => {
        state.isFormularySent = Object.assign(
          {},
          {
            isBackendOk: payload.isBackendOk,
            status: payload.status,
          }
        );
      })
      .addCase(sendFormulaireRechercher.fulfilled, (state, { payload }) => {
        state.isFormularySent = Object.assign(
          {},
          {
            isBackendOk: payload.isBackendOk,
            status: payload.status,
          }
        );
      })
      .addCase(getAllReference.fulfilled, (state, action) => {
        state.referenceList = action.payload.reponse.data;
      })
      .addCase(getAllReference.rejected, (state, action) => {
        state.referenceList = null;
      })
      .addCase(getAllInfoByRef.fulfilled, (state, action) => {
        state.formikList = action.payload.recapitulatif.data;
      })
      .addCase(setAllInfoByRefNull.fulfilled, (state, action) => {
        state.formikList = undefined;
      })
      .addCase(resetIsFormularySent.fulfilled, (state, payload) => {
        state.isFormularySent = payload.payload;
      })
      .addCase(getOdpById.fulfilled, (state, action) => {
        state.formikList = serviceCleaner(action.payload.data);
      })
      .addCase(getOdpById.rejected, (state, action) => {
        state.formikList = undefined;
      })
      .addCase(getArtByIdFps.fulfilled, (state, action) => {
        state.formikList = serviceCleaner(action.payload.data);
      })
      .addCase(getArtByIdFps.rejected, (state, action) => {
        state.formikList = undefined;
      })
      .addCase(getArtByIdRole.fulfilled, (state, action) => {
        state.formikList = serviceCleaner(action.payload.data);
      })
      .addCase(getArtByIdRole.rejected, (state, action) => {
        state.formikList = undefined;
      })
      .addCase(forceSendMailAndFilesByDDP.fulfilled, (state, action) => {
        state.forceSend = action.payload;
      })
      .addCase(triggerForceSendMailAndFilesByDDP.fulfilled, (state, action) => {
        state.forceSend = action.payload;
      });
  },
});

const { reducer } = formulaireSlice;
export default reducer;
