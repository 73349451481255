import {
  equalsCodeOrLabel,
  internalServiceValueInArray,
  notEqualsCodeAndLabel,
} from "../constantes/symbols/HelperSymbolsServices";
import {
  AGRESSIONS,
  ENVIRONNEMENT,
  QUANTITEE_SOUHAITEE,
  AUTRE,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  TEMPERATURE,
} from "../constantes/symbols/SymbolsCommon";
import {
  S08_ANGLE_J,
  S08_ANGLE_K,
  S08_ANGLE_L,
  S08_LONGUEUR_A,
  S08_LONGUEUR_B,
  S08_LONGUEUR_C,
  S08_LONGUEUR_COURSE,
  S08_LONGUEUR_D,
  S08_LONGUEUR_E,
  S08_LONGUEUR_F,
  S08_LONGUEUR_G,
  S08_LONGUEUR_H,
  S08_LONGUEUR_I,
  S08_LONGUEUR_MAX,
  S08_LONGUEUR_MIN,
  S08_SECTION_ENVELOPPE,
  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
  S08_SECTION_ENVELOPPE_PLAT,
  S08_SECTION_ENVELOPPE_S,
  S08_SECTION_ENVELOPPE_TOIT,
  S08_SECTION_ENVELOPPE_TOIT_PLAT,
  S08_TYPE_PENTE,
  S08_TYPE_SECTION_ENVELOPPE,
  S08_TYPE_TOIT,
} from "../constantes/symbols/SymbolsS08";
import {
  arrayEnvironnement,
  ENROULEUR,
} from "../constantes/symbols/SymbolsServicesCommon";
import {
  S08_STEP5_arrayShape_pente_C_descendante,
  S08_STEP5_arrayShape_pente_C_montante,
  S08_STEP5_arrayShape_plat_A,
  S08_STEP5_arrayShape_toitS_D,
  S08_STEP5_arrayShape_toit_B_toit,
  S08_STEP5_arrayShape_toit_B_toit_plat,
  S08_STEP5_arrayTypePente,
  S08_STEP5_arrayTypeSection,
  S08_STEP5_arrayTypeToit,
} from "../constantes/symbols/SymbolsServicesS08";
import {
  CAP,
  QUANTITEE_SOUHAITEE_CODE_ARTICLE,
} from "../redux/vue/ChampsFormulaires/RechercherChamps";
import { ARTICLES } from "../redux/vue/ChampsFormulaires/RechercherChamps";

export const dataCleanerS08 = (payload) => {
  //ETAPE3
  payload = removeForEnvAutre(payload);
  payload = removeTemperature(payload);

  //Etape5_S08
  payload = removeEnveloppeSectionS08(payload);
  payload = removeLongueurS08(payload);

  //Etape6_S08
  // payload = resultLongueurCourseS08(payload);

  //ETAPE 7
  payload = removeQteToDontKeep(payload);
  payload = removeEmptyQte(payload);
  
  return payload;
};

const resultLongueurCourseS08 = (payload) => {
  payload[S08_LONGUEUR_COURSE.description] =
    payload[S08_LONGUEUR_MAX.description] -
    payload[S08_LONGUEUR_MIN.description];
};

const removeTemperature = (payload) => {
  if (
    payload[FAMILLE_PROTECTION_INDUSTRIELLE.description] ===
    ENROULEUR.description
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] === TEMPERATURE.description) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeEnveloppeSectionS08 = (payload) => {
  switch (true) {
    case equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      0
    ):
      return removeEnveloppeSectionS08KeepA(payload);
    case equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      1
    ):
      return removeEnveloppeSectionS08KeepB(payload);
    case equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      2
    ):
      return removeEnveloppeSectionS08KeepC(payload);
    case equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      3
    ):
      return removeEnveloppeSectionS08KeepD(payload);
    case equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      4
    ):
      return removeEnveloppeSectionS08KeepNone(payload);
    default:
      return null;
  }
};

const removeEnveloppeSectionS08KeepA = (payload) => {
  return Object.fromEntries(
    Object.entries(payload)
      .map((element) => {
        if (
          [
            S08_SECTION_ENVELOPPE_TOIT.description,
            S08_SECTION_ENVELOPPE_TOIT_PLAT.description,
            S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description,
            S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description,
            S08_SECTION_ENVELOPPE_S.description,
          ].includes(element[0])
        ) {
          return null;
        }
        return element;
      })
      .filter((element) => element)
  );
};

const removeEnveloppeSectionS08KeepB = (payload) => {
  switch (true) {
    case equalsCodeOrLabel(
      payload[S08_TYPE_TOIT.description],
      S08_STEP5_arrayTypeToit(),
      0
    ):
      return removeEnveloppeSectionS08KeepB0(payload);
    case equalsCodeOrLabel(
      payload[S08_TYPE_TOIT.description],
      S08_STEP5_arrayTypeToit(),
      1
    ):
      return removeEnveloppeSectionS08KeepB1(payload);
    default:
      return null;
  }
};

const removeEnveloppeSectionS08KeepB0 = (payload) => {
  return Object.fromEntries(
    Object.entries(payload)
      .map((element) => {
        if (
          [
            S08_SECTION_ENVELOPPE_PLAT.description,
            S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description,
            S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description,
            S08_SECTION_ENVELOPPE_S.description,
          ].includes(element[0])
        ) {
          return null;
        }
        return element;
      })
      .filter((element) => element)
  );
};
const removeEnveloppeSectionS08KeepB1 = (payload) => {
  return Object.fromEntries(
    Object.entries(payload)
      .map((element) => {
        if (
          [
            S08_SECTION_ENVELOPPE_PLAT.description,
            S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description,
            S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description,
            S08_SECTION_ENVELOPPE_S.description,
          ].includes(element[0])
        ) {
          return null;
        }
        return element;
      })
      .filter((element) => element)
  );
};

const removeEnveloppeSectionS08KeepC = (payload) => {
  switch (true) {
    case equalsCodeOrLabel(
      payload[S08_TYPE_PENTE.description],
      S08_STEP5_arrayTypePente(),
      0
    ):
      return removeEnveloppeSectionS08KeepC0(payload);
    case equalsCodeOrLabel(
      payload[S08_TYPE_PENTE.description],
      S08_STEP5_arrayTypePente(),
      1
    ):
      return removeEnveloppeSectionS08KeepC1(payload);
    default:
      return null;
  }
};

const removeEnveloppeSectionS08KeepC0 = (payload) => {
  return Object.fromEntries(
    Object.entries(payload)
      .map((element) => {
        if (
          [
            S08_SECTION_ENVELOPPE_PLAT.description,
            S08_SECTION_ENVELOPPE_TOIT.description,
            S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description,
            S08_SECTION_ENVELOPPE_S.description,
          ].includes(element[0])
        ) {
          return null;
        }
        return element;
      })
      .filter((element) => element)
  );
};
const removeEnveloppeSectionS08KeepC1 = (payload) => {
  return Object.fromEntries(
    Object.entries(payload)
      .map((element) => {
        if (
          [
            S08_SECTION_ENVELOPPE_PLAT.description,
            S08_SECTION_ENVELOPPE_TOIT.description,
            S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description,
            S08_SECTION_ENVELOPPE_S.description,
          ].includes(element[0])
        ) {
          return null;
        }
        return element;
      })
      .filter((element) => element)
  );
};

const removeEnveloppeSectionS08KeepD = (payload) => {
  return Object.fromEntries(
    Object.entries(payload)
      .map((element) => {
        if (
          [
            S08_SECTION_ENVELOPPE_PLAT.description,
            S08_SECTION_ENVELOPPE_TOIT.description,
            S08_SECTION_ENVELOPPE_TOIT_PLAT.description,
            S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description,
            S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description,
          ].includes(element[0])
        ) {
          return null;
        }
        return element;
      })
      .filter((element) => element)
  );
};

const removeEnveloppeSectionS08KeepNone = (payload) => {
  return Object.fromEntries(
    Object.entries(payload)
      .map((element) => {
        if (
          [
            S08_SECTION_ENVELOPPE_PLAT.description,
            S08_SECTION_ENVELOPPE_TOIT.description,
            S08_SECTION_ENVELOPPE_TOIT_PLAT.description,
            S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description,
            S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description,
            S08_SECTION_ENVELOPPE_S.description,
          ].includes(element[0])
        ) {
          return null;
        }
        return element;
      })
      .filter((element) => element)
  );
};

const removeLongueurS08CaseSectionEnveloppePlat = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      0
    )
  ) {
    if (
      internalServiceValueInArray(
        [S08_STEP5_arrayShape_plat_A()[1], S08_STEP5_arrayShape_plat_A()[2]],
        payload[S08_SECTION_ENVELOPPE_PLAT.description]
      )
    ) {
      payload = Object.fromEntries(
        Object.entries(payload)
          .map((element) => {
            if (
              [
                S08_TYPE_TOIT.description,
                S08_TYPE_PENTE.description,
                S08_LONGUEUR_F.description,
                S08_LONGUEUR_G.description,
                S08_LONGUEUR_H.description,
                S08_LONGUEUR_I.description,
                S08_ANGLE_J.description,
                S08_ANGLE_K.description,
                S08_ANGLE_L.description,
              ].includes(element[0])
            ) {
              return null;
            }
            return element;
          })
          .filter((element) => element)
      );
    } else {
      payload = Object.fromEntries(
        Object.entries(payload)
          .map((element) => {
            if (
              [
                S08_TYPE_TOIT.description,
                S08_TYPE_PENTE.description,
                S08_LONGUEUR_D.description,
                S08_LONGUEUR_E.description,
                S08_LONGUEUR_F.description,
                S08_LONGUEUR_G.description,
                S08_LONGUEUR_H.description,
                S08_LONGUEUR_I.description,
                S08_ANGLE_J.description,
                S08_ANGLE_K.description,
                S08_ANGLE_L.description,
              ].includes(element[0])
            ) {
              return null;
            }
            return element;
          })
          .filter((element) => element)
      );
    }
  }
  return payload;
};


const removeLongueurS08 = (payload) => {
  /*switch(parseInt(payload[S08_TYPE_SECTION_ENVELOPPE.description])){
        case S08_STEP5_arrayTypeSection()[0].code:
            payload = removeLongueurS08CaseSectionEnveloppePlat(payload);
        case S08_STEP5_arrayTypeSection()[1].code:
            payload = removeLongueurS08CaseSectionEnveloppeToit(payload);
        case S08_STEP5_arrayTypeSection()[2].code:

        case S08_STEP5_arrayTypeSection()[3].code:

        case S08_STEP5_arrayTypeSection()[4].code:

        default:
            return null;
    }*/
  payload = removeLongueurS08CaseSectionEnveloppePlat(payload);
  

  //type section "Toit"
  if (
    equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      1
    )
  ) {
    //Type "Toit"
    if (
      equalsCodeOrLabel(
        payload[S08_TYPE_TOIT.description],
        S08_STEP5_arrayTypeToit(),
        0
      )
    ) {
      if (
        internalServiceValueInArray(
          [
            S08_STEP5_arrayShape_toit_B_toit()[1],
            S08_STEP5_arrayShape_toit_B_toit()[2],
          ],
          payload[S08_SECTION_ENVELOPPE_TOIT.description]
        )
      ) {
        payload = Object.fromEntries(
          Object.entries(payload)
            .map((element) => {
              if (
                [
                  S08_TYPE_PENTE.description,
                  S08_LONGUEUR_I.description,
                  S08_ANGLE_J.description,
                  S08_ANGLE_K.description,
                  S08_ANGLE_L.description,
                ].includes(element[0])
              ) {
                return null;
              }
              return element;
            })
            .filter((element) => element)
        );
      } else {
        payload = Object.fromEntries(
          Object.entries(payload)
            .map((element) => {
              if (
                [
                  S08_TYPE_PENTE.description,
                  S08_LONGUEUR_E.description,
                  S08_LONGUEUR_F.description,
                  S08_LONGUEUR_G.description,
                  S08_LONGUEUR_H.description,
                  S08_LONGUEUR_I.description,
                  S08_ANGLE_J.description,
                  S08_ANGLE_K.description,
                  S08_ANGLE_L.description,
                ].includes(element[0])
              ) {
                return null;
              }
              return element;
            })
            .filter((element) => element)
        );
      }
    } else {
      //Type "Toit plat"
      if (
        internalServiceValueInArray(
          [
            S08_STEP5_arrayShape_toit_B_toit_plat()[1],
            S08_STEP5_arrayShape_toit_B_toit_plat()[2],
          ],
          payload[S08_SECTION_ENVELOPPE_TOIT_PLAT.description]
        )
      ) {
        payload = Object.fromEntries(
          Object.entries(payload)
            .map((element) => {
              if (
                [
                  S08_TYPE_PENTE.description,
                  S08_ANGLE_J.description,
                  S08_ANGLE_K.description,
                  S08_ANGLE_L.description,
                ].includes(element[0])
              ) {
                return null;
              }
              return element;
            })
            .filter((element) => element)
        );
      } else {
        payload = Object.fromEntries(
          Object.entries(payload)
            .map((element) => {
              if (
                [
                  S08_TYPE_PENTE.description,
                  S08_LONGUEUR_G.description,
                  S08_LONGUEUR_H.description,
                  S08_LONGUEUR_I.description,
                  S08_ANGLE_J.description,
                  S08_ANGLE_K.description,
                  S08_ANGLE_L.description,
                ].includes(element[0])
              ) {
                return null;
              }
              return element;
            })
            .filter((element) => element)
        );
      }
    }
  }

  //type section "Pente"
  if (
    equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      2
    )
  ) {
    //type descendante
    if (
      equalsCodeOrLabel(
        payload[S08_TYPE_PENTE.description],
        S08_STEP5_arrayTypePente(),
        0
      )
    ) {
      if (
        internalServiceValueInArray(
          [
            S08_STEP5_arrayShape_pente_C_descendante()[1],
            S08_STEP5_arrayShape_pente_C_descendante()[2],
          ],
          payload[S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description]
        )
      ) {
        payload = Object.fromEntries(
          Object.entries(payload)
            .map((element) => {
              if (
                [
                  S08_TYPE_TOIT.description,
                  S08_LONGUEUR_G.description,
                  S08_LONGUEUR_H.description,
                  S08_LONGUEUR_I.description,
                  S08_ANGLE_J.description,
                  S08_ANGLE_K.description,
                  S08_ANGLE_L.description,
                ].includes(element[0])
              ) {
                return null;
              }
              return element;
            })
            .filter((element) => element)
        );
      } else {
        payload = Object.fromEntries(
          Object.entries(payload)
            .map((element) => {
              if (
                [
                  S08_TYPE_TOIT.description,
                  S08_LONGUEUR_F.description,
                  S08_LONGUEUR_G.description,
                  S08_LONGUEUR_H.description,
                  S08_LONGUEUR_I.description,
                  S08_ANGLE_J.description,
                  S08_ANGLE_K.description,
                  S08_ANGLE_L.description,
                ].includes(element[0])
              ) {
                return null;
              }
              return element;
            })
            .filter((element) => element)
        );
      }
    } else {
      //type montante
      if (
        internalServiceValueInArray(
          [
            S08_STEP5_arrayShape_pente_C_montante()[1],
            S08_STEP5_arrayShape_pente_C_montante()[2],
          ],
          payload[S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description]
        )
      ) {
        payload = Object.fromEntries(
          Object.entries(payload)
            .map((element) => {
              if (
                [
                  S08_TYPE_TOIT.description,
                  S08_LONGUEUR_G.description,
                  S08_LONGUEUR_H.description,
                  S08_LONGUEUR_I.description,
                  S08_ANGLE_J.description,
                  S08_ANGLE_K.description,
                  S08_ANGLE_L.description,
                ].includes(element[0])
              ) {
                return null;
              }
              return element;
            })
            .filter((element) => element)
        );
      } else {
        payload = Object.fromEntries(
          Object.entries(payload)
            .map((element) => {
              if (
                [
                  S08_TYPE_TOIT.description,
                  S08_LONGUEUR_F.description,
                  S08_LONGUEUR_G.description,
                  S08_LONGUEUR_H.description,
                  S08_LONGUEUR_I.description,
                  S08_ANGLE_J.description,
                  S08_ANGLE_K.description,
                  S08_ANGLE_L.description,
                ].includes(element[0])
              ) {
                return null;
              }
              return element;
            })
            .filter((element) => element)
        );
      }
    }
  }

  //type section "Toit S"
  if (
    equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      3
    )
  ) {
    if (
      internalServiceValueInArray(
        [S08_STEP5_arrayShape_toitS_D()[0], S08_STEP5_arrayShape_toitS_D()[1]],
        payload[S08_SECTION_ENVELOPPE_S.description]
      )
    ) {
      payload = Object.fromEntries(
        Object.entries(payload)
          .map((element) => {
            if (
              [
                S08_TYPE_TOIT.description,
                S08_TYPE_PENTE.description,
                S08_LONGUEUR_H.description,
                S08_LONGUEUR_I.description,
                S08_ANGLE_J.description,
                S08_ANGLE_K.description,
                S08_ANGLE_L.description,
              ].includes(element[0])
            ) {
              return null;
            }
            return element;
          })
          .filter((element) => element)
      );
    } else {
      payload = Object.fromEntries(
        Object.entries(payload)
          .map((element) => {
            if (
              [S08_TYPE_TOIT.description, S08_TYPE_PENTE.description].includes(
                element[0]
              )
            ) {
              return null;
            }
            return element;
          })
          .filter((element) => element)
      );
    }
  }

  if (
    equalsCodeOrLabel(
      payload[S08_TYPE_SECTION_ENVELOPPE.description],
      S08_STEP5_arrayTypeSection(),
      4
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            [
              S08_TYPE_TOIT.description,
              S08_TYPE_PENTE.description,
              S08_LONGUEUR_A.description,
              S08_LONGUEUR_B.description,
              S08_LONGUEUR_C.description,
              S08_LONGUEUR_D.description,
              S08_LONGUEUR_E.description,
              S08_LONGUEUR_F.description,
              S08_LONGUEUR_G.description,
              S08_LONGUEUR_H.description,
              S08_LONGUEUR_I.description,
              S08_ANGLE_J.description,
              S08_ANGLE_K.description,
              S08_ANGLE_L.description,
            ].includes(element[0])
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeForEnvAutre = (payload) => {
  if (
    notEqualsCodeAndLabel(
      payload[ENVIRONNEMENT.description],
      arrayEnvironnement(),
      3
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload).map((element) => {
        if (element[0] === AGRESSIONS.description) {
          return [[AGRESSIONS.description], []];
        }
        return element;
      })
    );
  }
  return payload;
};

const removeQteToDontKeep = (payload) => {
  if (payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.slice(
          0,
          payload?.nbFieldToKeep
        ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};

const removeEmptyQte = (payload) => {
  if (payload?.champQuantite !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.filter(Number),
      },
      {}
    );
  }
  return payload;
};

export const removeCodeAndQteToDontKeepOrEmpty = (payload) => {
  if (payload?.articles !== undefined && payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [ARTICLES.description]: payload.articles
          .slice(0, payload.nbFieldToKeep)
          .filter(
            (value) =>
              value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description] !== "" &&
              value[CAP.description] !== "" &&
              !isNaN(value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description])
          ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};
