import { Grid } from "@mui/material";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
  StyledDivPhotoPasDessin,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";

import { DessinTechnique } from "../../components/DessinTechnique";
import { importAll, importAllSpec } from "../../../../helper/ImportAll";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import {
  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
  S08_SECTION_ENVELOPPE_PLAT,
  S08_SECTION_ENVELOPPE_S,
  S08_SECTION_ENVELOPPE_TOIT,
  S08_SECTION_ENVELOPPE_TOIT_PLAT,
  S08_TYPE_PENTE,
  S08_TYPE_SECTION_ENVELOPPE,
  S08_TYPE_TOIT,
} from "../../../../constantes/symbols/SymbolsS08";
import {
  S08_STEP5_arrayShape_pente_C_descendante,
  S08_STEP5_arrayShape_pente_C_montante,
  S08_STEP5_arrayShape_plat_A,
  S08_STEP5_arrayShape_toitS_D,
  S08_STEP5_arrayShape_toit_B_toit,
  S08_STEP5_arrayShape_toit_B_toit_plat,
  S08_STEP5_arrayTypePente,
  S08_STEP5_arrayTypeSection,
  S08_STEP5_arrayTypeToit,
} from "../../../../constantes/symbols/SymbolsServicesS08";
import {
  formulaireAngleJ,
  formulaireAngleK,
  formulaireAngleL,
  formulaireLongueurD,
  formulaireLongueurE,
  formulaireLongueurF,
  formulaireLongueurG,
  formulaireLongueurH,
  formulaireLongueurI,
  formulaireQuelconquePlan,
  formulaireSection0,
  formulaireTypePente,
  formulaireTypeSectionS08,
  formulaireTypeToit,
} from "../../ChampsFormulaires/S08/etape5/ChampsFormeS08";
import {
  equalsCodeOrLabel,
  internalServiceValueInArray,
} from "../../../../constantes/symbols/HelperSymbolsServices";
import { useEffect } from "react";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";

export const StepS08Shape = ({
  state: {
    booleanIdentite,
    etape,
    styles,
    styles: {
      cssFontFamily,
      cssButton,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    formik,
  },
  action: { handleRetour },
  nom,
}) => {
  const lang = useTranslations();


  const typeSectionEnveloppe =
    formik.values[S08_TYPE_SECTION_ENVELOPPE.description];
  const typeToit = formik.values[S08_TYPE_TOIT.description];
  const typePente = formik.values[S08_TYPE_PENTE.description];
  const sectionEnveloppePlat =
    formik.values[S08_SECTION_ENVELOPPE_PLAT.description];
  const sectionEnveloppeToit =
    formik.values[S08_SECTION_ENVELOPPE_TOIT.description];
  const sectionEnveloppeToit_Plat =
    formik.values[S08_SECTION_ENVELOPPE_TOIT_PLAT.description];
  const sectionEnveloppePenteDescendante =
    formik.values[S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description];
  const sectionEnveloppePenteMontante =
    formik.values[S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description];
  const sectionEnveloppeS = formik.values[S08_SECTION_ENVELOPPE_S.description];

  // useEffect(() => {
  //   formik.setFieldValue(S08_TYPE_TOIT.description, "");
  //   formik.setFieldValue(S08_TYPE_PENTE.description, "");
  //   formik.setFieldValue(S08_SECTION_ENVELOPPE_PLAT.description, "");
  //   formik.setFieldValue(S08_SECTION_ENVELOPPE_TOIT.description, "");
  //   formik.setFieldValue(S08_SECTION_ENVELOPPE_TOIT_PLAT.description, "");
  //   formik.setFieldValue(S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description, "");
  //   formik.setFieldValue(S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description, "");
  //   formik.setFieldValue(S08_SECTION_ENVELOPPE_S.description, "");
  // }, [typeSectionEnveloppe])

  const afficherLesChampsForme = (t) => {
    switch (true) {
      case equalsCodeOrLabel(
        typeSectionEnveloppe,
        S08_STEP5_arrayTypeSection(t),
        0
      ):
        return afficherSectionPlat();
      case equalsCodeOrLabel(
        typeSectionEnveloppe,
        S08_STEP5_arrayTypeSection(t),
        1
      ):
        return (
          <>
            {afficherTypeToit()}
            {afficherSectionToit()}
          </>
        );
      case equalsCodeOrLabel(
        typeSectionEnveloppe,
        S08_STEP5_arrayTypeSection(t),
        2
      ):
        return (
          <>
            {afficherTypePente()}
            {afficherSectionPente()}
          </>
        );
      case equalsCodeOrLabel(
        typeSectionEnveloppe,
        S08_STEP5_arrayTypeSection(t),
        3
      ):
        return afficherSectionToitS(t);
      case equalsCodeOrLabel(
        typeSectionEnveloppe,
        S08_STEP5_arrayTypeSection(t),
        4
      ):
        return (
          <>{genererFormulaire(formulaireQuelconquePlan(styles, t), formik)}</>
        );
      default:
        return null;
    }
  };

  const afficherTypeToit = () => {
    return <>{genererFormulaire(formulaireTypeToit(styles, t), formik)}</>;
  };

  const afficherTypePente = () => {
    return <>{genererFormulaire(formulaireTypePente(styles, t), formik)}</>;
  };

  const afficherSectionToit = () => {
    switch (true) {
      case equalsCodeOrLabel(typeToit, S08_STEP5_arrayTypeToit(t), 0): //Toit
        if (
          internalServiceValueInArray(
            [
              S08_STEP5_arrayShape_toit_B_toit(t)[1],
              S08_STEP5_arrayShape_toit_B_toit(t)[2],
            ],
            sectionEnveloppeToit
          )
        ) {
          return (
            <>
              {genererFormulaire(
                formulaireSection0(
                  styles,
                  t,
                  S08_SECTION_ENVELOPPE_TOIT,
                  S08_STEP5_arrayShape_toit_B_toit(t),
                  t.SQ0072_Q00050
                ),
                formik
              )}
              {genererFormulaire(formulaireLongueurD(styles, t), formik)}
              {genererFormulaire(formulaireLongueurE(styles, t), formik)}
              {genererFormulaire(formulaireLongueurF(styles, t), formik)}
              {genererFormulaire(formulaireLongueurG(styles, t), formik)}
              {genererFormulaire(formulaireLongueurH(styles, t), formik)}
            </>
          );
        } else {
          //B1
          return (
            <>
              {genererFormulaire(
                formulaireSection0(
                  styles,
                  t,
                  S08_SECTION_ENVELOPPE_TOIT,
                  S08_STEP5_arrayShape_toit_B_toit(t),
                  t.SQ0072_Q00050
                ),
                formik
              )}
              {genererFormulaire(formulaireLongueurD(styles, t), formik)}
            </>
          );
        }
      case equalsCodeOrLabel(typeToit, S08_STEP5_arrayTypeToit(t), 1): //Toit plat
        if (
          internalServiceValueInArray(
            [
              S08_STEP5_arrayShape_toit_B_toit_plat(t)[1],
              S08_STEP5_arrayShape_toit_B_toit_plat(t)[2],
            ],
            sectionEnveloppeToit_Plat
          )
        ) {
          return (
            <>
              {genererFormulaire(
                formulaireSection0(
                  styles,
                  t,
                  S08_SECTION_ENVELOPPE_TOIT_PLAT,
                  S08_STEP5_arrayShape_toit_B_toit_plat(t),
                  t.SQ0072_Q00050
                ),
                formik
              )}
              {genererFormulaire(formulaireLongueurD(styles, t), formik)}
              {genererFormulaire(formulaireLongueurE(styles, t), formik)}
              {genererFormulaire(formulaireLongueurF(styles, t), formik)}
              {genererFormulaire(formulaireLongueurG(styles, t), formik)}
              {genererFormulaire(formulaireLongueurH(styles, t), formik)}
              {genererFormulaire(formulaireLongueurI(styles, t), formik)}
            </>
          );
        } else {
          return (
            <>
              {genererFormulaire(
                formulaireSection0(
                  styles,
                  t,
                  S08_SECTION_ENVELOPPE_TOIT_PLAT,
                  S08_STEP5_arrayShape_toit_B_toit_plat(t),
                  t.SQ0072_Q00050
                ),
                formik
              )}
              {genererFormulaire(formulaireLongueurD(styles, t), formik)}
              {genererFormulaire(formulaireLongueurE(styles, t), formik)}
              {genererFormulaire(formulaireLongueurF(styles, t), formik)}
            </>
          );
        }

      default:
        return null;
    }
  };

  const afficherSectionPente = () => {
    switch (true) {
      case equalsCodeOrLabel(typePente, S08_STEP5_arrayTypePente(t), 0):
        if (
          internalServiceValueInArray(
            [
              S08_STEP5_arrayShape_pente_C_descendante(t)[1],
              S08_STEP5_arrayShape_pente_C_descendante(t)[2],
            ],
            sectionEnveloppePenteDescendante
          )
        ) {
          return (
            <>
              {genererFormulaire(
                formulaireSection0(
                  styles,
                  t,
                  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
                  S08_STEP5_arrayShape_pente_C_descendante(t),
                  t.SQ0072_Q00080
                ),
                formik
              )}
              {genererFormulaire(formulaireLongueurD(styles, t), formik)}
              {genererFormulaire(formulaireLongueurE(styles, t), formik)}
              {genererFormulaire(formulaireLongueurF(styles, t), formik)}
            </>
          );
        } else {
          return (
            <>
              {genererFormulaire(
                formulaireSection0(
                  styles,
                  t,
                  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
                  S08_STEP5_arrayShape_pente_C_descendante(t),
                  t.SQ0072_Q00080
                ),
                formik
              )}
              {genererFormulaire(formulaireLongueurD(styles, t), formik)}
              {genererFormulaire(formulaireLongueurE(styles, t), formik)}
            </>
          );
        }
      case equalsCodeOrLabel(typePente, S08_STEP5_arrayTypePente(t), 1):
        if (
          internalServiceValueInArray(
            [
              S08_STEP5_arrayShape_pente_C_montante(t)[1],
              S08_STEP5_arrayShape_pente_C_montante(t)[2],
            ],
            sectionEnveloppePenteMontante
          )
        ) {
          return (
            <>
              {genererFormulaire(
                formulaireSection0(
                  styles,
                  t,
                  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
                  S08_STEP5_arrayShape_pente_C_montante(t),
                  t.SQ0072_Q00090
                ),
                formik
              )}
              {genererFormulaire(formulaireLongueurD(styles, t), formik)}
              {genererFormulaire(formulaireLongueurE(styles, t), formik)}
              {genererFormulaire(formulaireLongueurF(styles, t), formik)}
            </>
          );
        } else {
          return (
            <>
              {genererFormulaire(
                formulaireSection0(
                  styles,
                  t,
                  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
                  S08_STEP5_arrayShape_pente_C_montante(t),
                  t.SQ0072_Q00090
                ),
                formik
              )}
              {genererFormulaire(formulaireLongueurD(styles, t), formik)}
              {genererFormulaire(formulaireLongueurE(styles, t), formik)}
            </>
          );
        }
      default:
        return null;
    }
  };

  const afficherSectionPlat = () => {
    if (
      internalServiceValueInArray(
        [S08_STEP5_arrayShape_plat_A(t)[1], S08_STEP5_arrayShape_plat_A(t)[2]],
        sectionEnveloppePlat
      )
    ) {
      return (
        <>
          {genererFormulaire(
            formulaireSection0(
              styles,
              t,
              S08_SECTION_ENVELOPPE_PLAT,
              S08_STEP5_arrayShape_plat_A(t),
              t.SQ0072_Q00050
            ),
            formik
          )}
          {genererFormulaire(formulaireLongueurD(styles, t), formik)}
          {genererFormulaire(formulaireLongueurE(styles, t), formik)}
        </>
      );
    } else {
      return (
        <>
          {genererFormulaire(
            formulaireSection0(
              styles,
              t,
              S08_SECTION_ENVELOPPE_PLAT,
              S08_STEP5_arrayShape_plat_A(t),
              t.SQ0072_Q00050
            ),
            formik
          )}
        </>
      );
    }
  };

  const afficherSectionToitS = (t) => {
    if (
      internalServiceValueInArray(
        [
          S08_STEP5_arrayShape_toitS_D(t)[2],
          S08_STEP5_arrayShape_toitS_D(t)[3],
        ],
        sectionEnveloppeS
      )
    ) {
      return (
        <>
          {genererFormulaire(
            formulaireSection0(
              styles,
              t,
              S08_SECTION_ENVELOPPE_S,
              S08_STEP5_arrayShape_toitS_D(t),
              t.SQ0072_Q00100
            ),
            formik
          )}
          {genererFormulaire(formulaireLongueurD(styles, t), formik)}
          {genererFormulaire(formulaireLongueurE(styles, t), formik)}
          {genererFormulaire(formulaireLongueurF(styles, t), formik)}
          {genererFormulaire(formulaireLongueurG(styles, t), formik)}
          {genererFormulaire(formulaireLongueurH(styles, t), formik)}
          {genererFormulaire(formulaireLongueurI(styles, t), formik)}
          {genererFormulaire(formulaireAngleJ(styles, t), formik)}
          {genererFormulaire(formulaireAngleK(styles, t), formik)}
          {genererFormulaire(formulaireAngleL(styles, t), formik)}
        </>
      );
    } else {
      return (
        <>
          {genererFormulaire(
            formulaireSection0(
              styles,
              t,
              S08_SECTION_ENVELOPPE_S,
              S08_STEP5_arrayShape_toitS_D(t),
              t.SQ0072_Q00100
            ),
            formik
          )}
          {genererFormulaire(formulaireLongueurD(styles, t), formik)}
          {genererFormulaire(formulaireLongueurE(styles, t), formik)}
          {genererFormulaire(formulaireLongueurF(styles, t), formik)}
          {genererFormulaire(formulaireLongueurG(styles, t), formik)}
        </>
      );
    }
  };

  const afficherDessinTechnique = () => {
    if (
      equalsCodeOrLabel(typeSectionEnveloppe, S08_STEP5_arrayTypeSection(t), 4)
    ) {
      return (
        <StyledDivPhotoPasDessin
          style={{
            backgroundImage:
              "url(" +
              importAll()["GrandePhotoProtecteurTelescopique.jpg"] +
              ")",
          }}
        />
      );
    } else {
      return (
        <>
          <DessinTechnique
            formik={formik.values}
            styles={styles}
            etape={etape}
            t={t}
          />
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </>
      );
    }
  };

  const afficherSOSetForm = () => {
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyRenseignezConstructionFormeProtecTele}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(formulaireTypeSectionS08(styles, t), formik)}
            {afficherLesChampsForme(t)}

            <StyledDivDessinMobile>
              <StyledDivDessin>{afficherDessinTechnique()}</StyledDivDessin>
              {lang.lang === "FR" && afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={cssButton}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>{afficherDessinTechnique()}</StyledDivDessin>
        {lang.lang === "FR" && afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
