import { Paper } from "@mui/material";
import {
  S08_ANGLE_J,
  S08_ANGLE_K,
  S08_ANGLE_L,
  S08_LONGUEUR_A,
  S08_LONGUEUR_B,
  S08_LONGUEUR_C,
  S08_LONGUEUR_D,
  S08_LONGUEUR_E,
  S08_LONGUEUR_F,
  S08_LONGUEUR_G,
  S08_LONGUEUR_H,
  S08_LONGUEUR_I,
  S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE,
  S08_SECTION_ENVELOPPE_PENTE_MONTANTE,
  S08_SECTION_ENVELOPPE_PLAT,
  S08_SECTION_ENVELOPPE_S,
  S08_SECTION_ENVELOPPE_TOIT,
  S08_SECTION_ENVELOPPE_TOIT_PLAT,
  S08_TYPE_PENTE,
  S08_TYPE_SECTION_ENVELOPPE,
  S08_TYPE_TOIT,
} from "../../../../constantes/symbols/SymbolsS08";
import {
  S08_STEP5_arrayShape_pente_C_descendante,
  S08_STEP5_arrayShape_pente_C_montante,
  S08_STEP5_arrayShape_plat_A,
  S08_STEP5_arrayShape_toitS_D,
  S08_STEP5_arrayShape_toit_B_toit,
  S08_STEP5_arrayShape_toit_B_toit_plat,
  S08_STEP5_arrayTypePente,
  S08_STEP5_arrayTypeSection,
  S08_STEP5_arrayTypeToit,
} from "../../../../constantes/symbols/SymbolsServicesS08";
import {
  checkFocusedAngle,
  checkFocusedArrow,
  checkFocusedEndMarker,
  checkFocusedStartMarker,
  checkFocusedText,
} from "../../../../helper/ConditionnalCssProvider";
import {
  StyledPath2,
  StyledPathCoteDotted,
  StyledPathDotted,
  StyledPathFleche,
} from "../../styledComponents/StyledPath";
import { StyledRect2 } from "../../styledComponents/StyledRect";
import { StyledPolygon } from "../../styledComponents/StyledPolygon";
import { equalsCodeOrLabel } from "../../../../constantes/symbols/HelperSymbolsServices";

export const FormeFaceS08 = ({ styles, formik, t }) => {
  const longueurA = formik[S08_LONGUEUR_A.description] || "";
  const longueurB = formik[S08_LONGUEUR_B.description] || "";
  const longueurC = formik[S08_LONGUEUR_C.description] || "";
  const longueurD = formik[S08_LONGUEUR_D.description] || "";
  const longueurE = formik[S08_LONGUEUR_E.description] || "";
  const longueurF = formik[S08_LONGUEUR_F.description] || "";
  const longueurG = formik[S08_LONGUEUR_G.description] || "";
  const longueurH = formik[S08_LONGUEUR_H.description] || "";
  const longueurI = formik[S08_LONGUEUR_I.description] || "";
  const angleJ = formik[S08_ANGLE_J.description] || "";
  const angleK = formik[S08_ANGLE_K.description] || "";
  const angleL = formik[S08_ANGLE_L.description] || "";
  const typeSectionEnveloppe = formik[S08_TYPE_SECTION_ENVELOPPE.description];
  const sectionEnveloppePlat = formik[S08_SECTION_ENVELOPPE_PLAT.description];
  const sectionEnveloppeToit = formik[S08_SECTION_ENVELOPPE_TOIT.description];
  const sectionEnveloppeToit_Plat = formik[S08_SECTION_ENVELOPPE_TOIT_PLAT.description];
  const sectionEnveloppePenteDescendante =
    formik[S08_SECTION_ENVELOPPE_PENTE_DESCENDANTE.description];
  const sectionEnveloppePenteMontante =
    formik[S08_SECTION_ENVELOPPE_PENTE_MONTANTE.description];
  const sectionEnveloppeS = formik[S08_SECTION_ENVELOPPE_S.description];
  const typeToit = formik[S08_TYPE_TOIT.description];
  const typePente = formik[S08_TYPE_PENTE.description];

  const afficherTexteRacleur = (longueur, hauteur, numero) => {
    return (
      <>
        <text
          fontSize="0.4em"
          x={longueur}
          y={hauteur}
          direction="ltr"
          className={styles.markerSelected}
        >
          {numero}
        </text>
      </>
    );
  };

  const formeA = () => {
    return (
      <>
        <StyledRect2 x="45" y="70" width="15" height="70" />
        <StyledRect2 x="180" y="70" width="15" height="70" />
        <StyledRect2 x="45" y="70" width="135" height="15" />
        <StyledRect2 x="150" y="125" width="30" height="15" />
        <StyledRect2 x="60" y="125" width="30" height="15" />
      </>
    );
  };

  const afficherFormeA = () => {
    switch (true) {
      case equalsCodeOrLabel(
        sectionEnveloppePlat,
        S08_STEP5_arrayShape_plat_A(),
        0
      ):
        return (
          <>
            {formeA()}

            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,76 L 38,133"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="32"
                y="107"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="20"
                  y="107"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 51,145 L 84,145"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="67"
                y="152"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="66"
                  y="153"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}
            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 58,81 L 182,81" className={styles.dotted} />
            {afficherTexteRacleur(118, 79, 2)}
            {/* ligne pointillée gauche */}
            <StyledPathDotted d="M 58,81 L 58,129" className={styles.dotted} />
            {afficherTexteRacleur(52, 107, 1)}
            {/* ligne pointillée droite */}
            <StyledPathDotted
              d="M 182,81 L 182,129"
              className={styles.dotted}
            />
            {afficherTexteRacleur(185, 107, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppePlat,
        S08_STEP5_arrayShape_plat_A(),
        1
      ):
        return (
          <>
            <StyledRect2 x="45" y="70" width="15" height="70" />
            <StyledRect2 x="180" y="70" width="15" height="55" />
            <StyledRect2 x="45" y="70" width="135" height="15" />
            <StyledRect2 x="150" y="110" width="30" height="15" />
            <StyledRect2 x="45" y="125" width="40" height="15" />

            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,76 L 38,134"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="32"
                y="107"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="20"
                  y="107"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 50,145 L 79,145"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="65"
                y="152"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="63"
                  y="152"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 202,76 L 202,119"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="207"
                y="100"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="207"
                  y="100"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 157,130 L 188,130"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="173"
                y="137"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="171"
                  y="137"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 58,81 L 182,81" className={styles.dotted} />
            {afficherTexteRacleur(118, 79, 2)}
            {/* ligne pointillée gauche */}
            <StyledPathDotted d="M 58,81 L 58,125" className={styles.dotted} />
            {afficherTexteRacleur(52, 107, 1)}
            {/* ligne pointillée droite */}
            <StyledPathDotted
              d="M 182,81 L 182,110"
              className={styles.dotted}
            />
            {afficherTexteRacleur(185, 100, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppePlat,
        S08_STEP5_arrayShape_plat_A(),
        2
      ):
        return (
          <>
            <StyledRect2 x="45" y="70" width="15" height="40" />
            <StyledRect2 x="180" y="70" width="15" height="70" />
            <StyledRect2 x="45" y="70" width="135" height="15" />
            <StyledRect2 x="150" y="125" width="30" height="15" />
            <StyledRect2 x="45" y="110" width="40" height="15" />

            <StyledPathFleche
              d="M 50,62 L 190,62"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="120"
                y="60"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="120"
                  y="60"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,76 L 38,118"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="32"
                y="99"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="20"
                  y="92"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 50,130 L 79,130"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="65"
                y="138"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="63"
                  y="138"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 202,76 L 202,133"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="207"
                y="107"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="207"
                  y="107"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 157,145 L 188,145"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="173"
                y="153"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="171"
                  y="153"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 58,81 L 182,81" className={styles.dotted} />
            {afficherTexteRacleur(118, 79, 2)}
            {/* ligne pointillée gauche */}
            <StyledPathDotted d="M 58,81 L 58,110" className={styles.dotted} />
            {afficherTexteRacleur(52, 99, 1)}
            {/* ligne pointillée droite */}
            <StyledPathDotted
              d="M 182,81 L 182,129"
              className={styles.dotted}
            />
            {afficherTexteRacleur(185, 107, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );

      default:
        return <>{formeA()}</>;
    }
  };

  const formeToit_V1 = (hauteurCote) => {
    return (
      <>
        <StyledPolygon
          points={
            "113,62 165,93 165," +
            hauteurCote +
            " 151," +
            hauteurCote +
            " 151,100 113,77.5 75,100 75," +
            hauteurCote +
            " 61," +
            hauteurCote +
            " 61,93 113,62"
          }
        />
      </>
    );
  };

  const formeToit_V2 = () => {
    return (
      <>
        <StyledPolygon
          points="113,62 184,97 184,142 170,142 170,106 113,77.5 75,100 75,125 61,125
         61,93 113,62"
        />
      </>
    );
  };

  const formeToit_V3 = () => {
    return (
      <>
        <StyledPolygon
          points="123,62 52,97 52,142 66,142 66,106 123,77.5 161,100 161,125 175,125 
          175,93 123,62"
        />
      </>
    );
  };

  const afficherFormeToitIt1 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        sectionEnveloppeToit,
        S08_STEP5_arrayShape_toit_B_toit(),
        0
      ):
        return (
          <>
            {formeToit_V1(130)}
            <StyledRect2 x="61" y="130" width="35" height="15" />
            <StyledRect2 x="130" y="130" width="35" height="15" />

            <StyledPathFleche
              d="M 67,148 L 90,148"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="78"
                y="154"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="77"
                  y="154"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 58,99 L 58,140"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="120"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="43"
                  y="120"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 47,69 L 47,114"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathFleche
              d="M 47,122 L 47,140"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="41"
                y="97"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="31"
                  y="97"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 66,55 L 160,55"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="113"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            {/* ligne pointillée membre central droit */}
            <StyledPathDotted d="M 115,75 L 155,99" className={styles.dotted} />
            {afficherTexteRacleur(135, 85, 3)}
            {/* ligne pointillée membre central gauche*/}
            <StyledPathDotted d="M 73,98 L 114,74" className={styles.dotted} />
            {afficherTexteRacleur(85, 85, 2)}
            {/* ligne pointillée membre gauche verticale*/}
            <StyledPathDotted d="M 73,98 L 73,132" className={styles.dotted} />
            {afficherTexteRacleur(67, 119, 1)}
            {/* ligne pointillée membre droit verticale */}
            <StyledPathDotted
              d="M 154,99 L 154,132"
              className={styles.dotted}
            />
            {afficherTexteRacleur(157, 119, 4)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppeToit,
        S08_STEP5_arrayShape_toit_B_toit(),
        1
      ):
        return (
          <>
            {formeToit_V3()}
            <StyledRect2 x="52" y="137" width="40" height="15" />
            <StyledRect2 x="135" y="121" width="40" height="15" />

            <StyledPathFleche
              d="M 58,155 L 86,155"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="72"
                y="162"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="71"
                  y="162"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 48,103 L 48,146"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="41"
                y="120"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="34"
                  y="120"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,67 L 38,114"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathFleche
              d="M 38,121 L 38,146"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="32"
                y="102"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="22"
                  y="102"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,49 L 168,49"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="113"
                y="46"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="111"
                  y="46"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 128,60 L 168,60"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="150"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="81"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 189,69 L 189,106"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            <StyledPathFleche
              d="M 189,114 L 189,131"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="195"
                y="97"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="195"
                  y="97"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 178,99 L 178,131"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_G])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_G])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_G], styles)}
            />
            {longueurG === "" ? (
              <text
                fontSize="0.4em"
                x="184"
                y="112"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
              >
                G
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="181"
                  y="112"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
                >
                  {longueurG}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 141,140 L 169,140"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_H])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_H])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_H], styles)}
            />
            {longueurH === "" ? (
              <text
                fontSize="0.4em"
                x="155"
                y="148"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
              >
                H
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="154"
                  y="148"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
                >
                  {longueurH}
                </text>
              </>
            )}

            {/* ligne pointillée membre central droit */}
            <StyledPathDotted d="M 64,104 L 122,74" className={styles.dotted} />
            {afficherTexteRacleur(148, 87, 3)}
            {/* ligne pointillée membre central gauche*/}
            <StyledPathDotted d="M 122,74 L 165,99" className={styles.dotted} />
            {afficherTexteRacleur(85, 88, 2)}
            {/* ligne pointillée membre gauche verticale*/}
            <StyledPathDotted d="M 64,104 L 64,138" className={styles.dotted} />
            {afficherTexteRacleur(58, 119, 1)}
            {/* ligne pointillée membre droit verticale */}
            <StyledPathDotted
              d="M 165,99 L 165,123"
              className={styles.dotted}
            />
            {afficherTexteRacleur(169, 113, 4)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppeToit,
        S08_STEP5_arrayShape_toit_B_toit(),
        2
      ):
        return (
          <>
            {formeToit_V2()}
            <StyledRect2 x="61" y="121" width="40" height="15" />
            <StyledRect2 x="144" y="137" width="40" height="15" />

            <StyledPathFleche
              d="M 67,140 L 95,140"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="81"
                y="148"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="79"
                  y="148"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 58,99 L 58,131"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="112"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="43"
                  y="112"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 47,69 L 47,106"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathFleche
              d="M 47,114 L 47,131"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="41"
                y="97"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="31"
                  y="97"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,49 L 178,49"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="123"
                y="46"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="121"
                  y="46"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,60 L 108,60"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="83"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="81"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 188,103 L 188,146"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="192"
                y="120"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="191"
                  y="120"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 198,67 L 198,114"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_G])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_G], styles)}
            />
            <StyledPathFleche
              d="M 198,121 L 198,146"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_G])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_G], styles)}
            />
            {longueurG === "" ? (
              <text
                fontSize="0.4em"
                x="203"
                y="102"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
              >
                G
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="202"
                  y="102"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
                >
                  {longueurG}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 150,155 L 178,155"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_H])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_H])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_H], styles)}
            />
            {longueurH === "" ? (
              <text
                fontSize="0.4em"
                x="164"
                y="162"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
              >
                H
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="163"
                  y="162"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
                >
                  {longueurH}
                </text>
              </>
            )}

            {/* ligne pointillée membre central droit */}
            <StyledPathDotted
              d="M 114,73 L 175,104"
              className={styles.dotted}
            />
            {afficherTexteRacleur(141, 85, 3)}
            {/* ligne pointillée membre central gauche*/}
            <StyledPathDotted d="M 73,98 L 114,73" className={styles.dotted} />
            {afficherTexteRacleur(85, 85, 2)}
            {/* ligne pointillée membre gauche verticale*/}
            <StyledPathDotted d="M 73,98 L 73,122" className={styles.dotted} />
            {afficherTexteRacleur(67, 112, 1)}
            {/* ligne pointillée membre droit verticale */}
            <StyledPathDotted
              d="M 175,104 L 175,138"
              className={styles.dotted}
            />
            {afficherTexteRacleur(177, 122, 4)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );

      default:
        return <>{formeToit_V1(130)}</>;
    }
  };

  const formeToit_V4 = () => {
    return (
      <>
        <StyledPolygon
          points="97,70 153,70 198,97 198,145 169,145 169,133 186,133 186,104 148,82
                    100,82 64,104 64,133 81,133 81,145 51,145 51,97 97,70"
        />
      </>
    );
  };

  const formeToit_V5 = () => {
    return (
      <>
        <StyledPolygon
          points="105,70 156,70 198,95 198,141 172,141 172,131 187,131 187,101 153,81
                109,81 59,106 59,144 74,144 74,155 47,155 47,99 105,70"
        />

        {/* ligne pointillée verticale gauche */}
        <StyledPathDotted d="M 57,145 L 57,104" className={styles.dotted} />
        {/* ligne pointillée diagonale gauche */}
        <StyledPathDotted d="M 57,104 L 109,78" className={styles.dotted} />
        {/* ligne pointillée centrale */}
        <StyledPathDotted d="M 109,78 L 152,78" className={styles.dotted} />
        {/* ligne pointillée diagonale droite */}
        <StyledPathDotted d="M 152,78 L 190,101" className={styles.dotted} />
        {/* ligne pointillée verticale droite */}
        <StyledPathDotted d="M 190,103 L 190,135" className={styles.dotted} />
      </>
    );
  };

  const formeToit_V6 = () => {
    return (
      <>
        <StyledPolygon
          points="90,70 141,70 201,100 201,157 174,157 174,145 189,145 189,107 138,82
                93,82 59,102 59,132 74,132 74,142 47,142 47,96 90,70"
        />

        {/* ligne pointillée verticale gauche */}
        <StyledPathDotted d="M 57,101 L 57,130" className={styles.dotted} />
        {/* ligne pointillée diagonale gauche */}
        <StyledPathDotted d="M 57,101 L 93,80" className={styles.dotted} />
        {/* ligne pointillée centrale */}
        <StyledPathDotted d="M 93,80 L 140,80" className={styles.dotted} />
        {/* ligne pointillée diagonale droite */}
        <StyledPathDotted d="M 142,81 L 192,106" className={styles.dotted} />
        {/* ligne pointillée verticale droite */}
        <StyledPathDotted d="M 192,106 L 192,145" className={styles.dotted} />
      </>
    );
  };

  const afficherFormeToitIt2 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        sectionEnveloppeToit_Plat,
        S08_STEP5_arrayShape_toit_B_toit_plat(),
        0
      ):
        return (
          <>
            {formeToit_V4()}

            {/* ligne pointillée verticale gauche */}
            <StyledPathDotted d="M 62,131 L 62,103" className={styles.dotted} />
            {/* ligne pointillée diagonale gauche */}
            <StyledPathDotted d="M 62,103 L 100,80" className={styles.dotted} />
            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 100,80 L 150,80" className={styles.dotted} />
            {/* ligne pointillée diagonale droite */}
            <StyledPathDotted
              d="M 150,80 L 190,103"
              className={styles.dotted}
            />
            {/* ligne pointillée verticale droite */}
            <StyledPathDotted
              d="M 189,103 L 189,135"
              className={styles.dotted}
            />

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>

            <StyledPathFleche
              d="M 57,149 L 76,149"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="66"
                y="156"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="64"
                  y="156"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 47,103 L 47,139"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="44"
                y="120"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="35"
                  y="120"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,75 L 38,114"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathFleche
              d="M 38,122 L 38,139"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="34"
                y="110"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="27"
                  y="110"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 56,49 L 193,49"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="123"
                y="46"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="121"
                  y="46"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 56,60 L 89,60"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="72"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="70"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 102,60 L 145,60"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="123"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="121"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            {afficherTexteRacleur(55, 120, 1)}
            {afficherTexteRacleur(78, 90, 2)}
            {afficherTexteRacleur(125, 78, 3)}
            {afficherTexteRacleur(175, 92, 4)}
            {afficherTexteRacleur(192, 120, 5)}
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppeToit_Plat,
        S08_STEP5_arrayShape_toit_B_toit_plat(),
        1
      ):
        return (
          <>
            {formeToit_V5()}

            <StyledPathFleche
              d="M 53,158 L 68,158"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="61"
                y="164"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="59"
                  y="164"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 45,105 L 45,149"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="41"
                y="128"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="35"
                  y="128"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 38,75 L 38,122"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathFleche
              d="M 38,130 L 38,149"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="34"
                y="110"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="27"
                  y="110"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 53,49 L 193,49"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="123"
                y="46"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="121"
                  y="46"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 53,60 L 97,60"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="75"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="74"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 109,60 L 148,60"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="128"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="127"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 202,102 L 202,135"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_G])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_G])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_G], styles)}
            />
            {longueurG === "" ? (
              <text
                fontSize="0.4em"
                x="206"
                y="120"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
              >
                G
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="205"
                  y="120"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
                >
                  {longueurG}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 210,75 L 210,114"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_H])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_H], styles)}
            />
            <StyledPathFleche
              d="M 210,122 L 210,135"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_H])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_H], styles)}
            />
            {longueurH === "" ? (
              <text
                fontSize="0.4em"
                x="214"
                y="110"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
              >
                H
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="213"
                  y="110"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
                >
                  {longueurH}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 178,145 L 193,145"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_I])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_I])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_I], styles)}
            />
            {longueurI === "" ? (
              <text
                fontSize="0.4em"
                x="186"
                y="152"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_I], styles)}
              >
                I
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="184"
                  y="152"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_I], styles)}
                >
                  {longueurI}
                </text>
              </>
            )}

            {afficherTexteRacleur(52, 128, 1)}
            {afficherTexteRacleur(78, 90, 2)}
            {afficherTexteRacleur(129, 76, 3)}
            {afficherTexteRacleur(177, 92, 4)}
            {afficherTexteRacleur(192, 120, 5)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );

      case equalsCodeOrLabel(
        sectionEnveloppeToit_Plat,
        S08_STEP5_arrayShape_toit_B_toit_plat(),
        2
      ):
        return (
          <>
            {formeToit_V6()}

            <StyledPathFleche
              d="M 53,145 L 68,145"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="60"
                y="151"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="58"
                  y="151"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 45,102 L 45,136"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="41"
                y="118"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="31"
                  y="118"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 33,75 L 33,112"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathFleche
              d="M 33,120 L 33,136"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="27"
                y="110"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="22"
                  y="110"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 53,49 L 193,49"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="123"
                y="46"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="121"
                  y="46"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 53,60 L 82,60"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="67"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="66"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 95,60 L 133,60"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="116"
                y="57"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="114"
                  y="57"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 204,106 L 204,151"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_G])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_G])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_G], styles)}
            />
            {longueurG === "" ? (
              <text
                fontSize="0.4em"
                x="208"
                y="130"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
              >
                G
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="206"
                  y="130"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
                >
                  {longueurG}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 213,75 L 213,123"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_H])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_H], styles)}
            />
            <StyledPathFleche
              d="M 213,132 L 213,151"
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_H])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_H], styles)}
            />
            {longueurH === "" ? (
              <text
                fontSize="0.4em"
                x="218"
                y="110"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
              >
                H
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="216"
                  y="110"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
                >
                  {longueurH}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 180,160 L 195,160"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_I])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_I])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_I], styles)}
            />
            {longueurI === "" ? (
              <text
                fontSize="0.4em"
                x="188"
                y="166"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_I], styles)}
              >
                I
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="186"
                  y="166"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_I], styles)}
                >
                  {longueurI}
                </text>
              </>
            )}

            {afficherTexteRacleur(51, 118, 1)}
            {afficherTexteRacleur(70, 90, 2)}
            {afficherTexteRacleur(115, 78, 3)}
            {afficherTexteRacleur(170, 92, 4)}
            {afficherTexteRacleur(194, 120, 5)}

            <StyledPathDotted
              d="M 115,164 L 135,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="140" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      default:
        return (
          <>
            <StyledPolygon
              points="97,70 153,70 198,97 198,145 186,145 186,104 148,82
                    100,82 64,104 64,145 51,145 51,97 97,70"
            />
          </>
        );
    }
  };

  const formeDescendante = () => {
    return (
      <>
        <StyledPolygon points="57,68 186,102 186,146 168,146 168,115 75,91 75,146 57,146 57,68" />
        {/* <path d="M 57,68 L 186,102 L 186,146 L 168,146 L 168,115 L 75,91 L 75,146 L 57,146 L 57,68" className={styles.path1}/> */}
      </>
    );
  };

  const formePente = (hauteurMembreGauche, hauteurMembreDroit) => {
    return (
      <>
        <StyledPolygon
          points={
            "60,60 170,85 170," +
            hauteurMembreDroit +
            " 155," +
            hauteurMembreDroit +
            " 155,95 75,78 75," +
            hauteurMembreGauche +
            " 60," +
            hauteurMembreGauche +
            " 60,60"
          }
        />
      </>
    );
  };

  const afficherFormePenteIt1 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        sectionEnveloppePenteDescendante,
        S08_STEP5_arrayShape_pente_C_descendante(),
        0
      ):
        return (
          <>
            {formePente(125, 125)}
            <StyledRect2 x="60" y="120" width="40" height="15" />
            <StyledRect2 x="130" y="120" width="40" height="15" />
            <StyledPathFleche
              d="M 65,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathCoteDotted d="M 59,57 L 59,59" />
            <StyledPathCoteDotted d="M 171,57 L 171,83" />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,67 L 55,129"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="45"
                y="100"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="37"
                  y="100"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,140 L 93,140"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="79"
                y="146"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="78"
                  y="146"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,92 L 175,129"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="113"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="113"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 137,140 L 165,140"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="151"
                y="146"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="149"
                  y="146"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 73,75 L 159,93" className={styles.dotted} />
            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 73,75 L 73,120" className={styles.dotted} />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 157,93 L 157,120"
              className={styles.dotted}
            />

            {afficherTexteRacleur(67, 100, 1)}
            {afficherTexteRacleur(115, 80, 2)}
            {afficherTexteRacleur(160, 107, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppePenteDescendante,
        S08_STEP5_arrayShape_pente_C_descendante(),
        1
      ):
        return (
          <>
            {formePente(156, 125)}
            <StyledRect2 x="75" y="141" width="15" height="15" />
            <StyledRect2 x="140" y="115" width="30" height="15" />

            <StyledPathFleche
              d="M 66,159 L 84,159"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="75"
                y="165"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="73"
                  y="165"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,67 L 55,149"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="45"
                y="115"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="37"
                  y="115"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathCoteDotted d="M 59,57 L 59,59" />
            <StyledPathCoteDotted d="M 171,57 L 171,83" />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,92 L 175,124"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="110"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="110"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 146,133 L 164,133"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="155"
                y="140"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="153"
                  y="140"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathCoteDotted d="M 76,130 L 140,130" />
            <StyledPathCoteDotted d="M 93,156 L 170,156" />
            <StyledPathFleche
              d="M 94,136 L 94,150"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="98"
                y="145"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="97"
                  y="145"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 73,75 L 159,93" className={styles.dotted} />
            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 73,75 L 73,140" className={styles.dotted} />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 157,93 L 157,115"
              className={styles.dotted}
            />

            {afficherTexteRacleur(67, 115, 1)}
            {afficherTexteRacleur(115, 80, 2)}
            {afficherTexteRacleur(160, 107, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppePenteDescendante,
        S08_STEP5_arrayShape_pente_C_descendante(),
        2
      ):
        return (
          <>
            {formePente(125, 156)}
            <StyledRect2 x="140.5" y="141" width="15" height="15" />
            <StyledRect2 x="60" y="115" width="30" height="15" />

            <StyledPathFleche
              d="M 66,133 L 84,133"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="75"
                y="139"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="73"
                  y="139"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,67 L 55,123"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="45"
                y="98"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="37"
                  y="98"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathCoteDotted d="M 59,57 L 59,59" />
            <StyledPathCoteDotted d="M 171,57 L 171,83" />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,92 L 175,149"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="122"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="122"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 146,160 L 164,160"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="155"
                y="166"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="153"
                  y="166"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathCoteDotted d="M 91,130 L 155,130" />
            <StyledPathCoteDotted d="M 60,156 L 140,156" />
            <StyledPathFleche
              d="M 136,136 L 136,150"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="130"
                y="145"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="123"
                  y="145"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 73,75 L 159,93" className={styles.dotted} />
            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 73,75 L 73,117" className={styles.dotted} />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 157,93 L 157,142"
              className={styles.dotted}
            />

            {afficherTexteRacleur(67, 98, 1)}
            {afficherTexteRacleur(115, 80, 2)}
            {afficherTexteRacleur(160, 122, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      default:
        return <>{formeDescendante()}</>;
    }
  };

  const formeMontante = (hauteurMembreGauche, hauteurMembreDroit) => {
    return (
      <>
        <StyledPolygon
          points={
            "57,102 189,67 189,146 171,146 171,91 76,115 76,146 57,146 57,102"
          }
        />
        {/* <path d="M 57,102 L 189,67 L 189,146 L 171,146 L 171,91 L 76,115 L 76,146 L 57,146 L 57,102" className={styles.path1}/> */}
      </>
    );
  };

  const formePente_V2 = (hauteurMembreGauche, hauteurMembreDroit) => {
    return (
      <>
        <StyledPolygon
          points={
            "170,60 60,85 60," +
            hauteurMembreGauche +
            " 75," +
            hauteurMembreGauche +
            " 75,95 155,78 155," +
            hauteurMembreDroit +
            " 170," +
            hauteurMembreDroit +
            " 170,60"
          }
        />
      </>
    );
  };

  const afficherFormePenteIt2 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        sectionEnveloppePenteMontante,
        S08_STEP5_arrayShape_pente_C_montante(),
        0
      ):
        return (
          <>
            {formePente_V2(125, 125)}
            <StyledRect2 x="60" y="120" width="40" height="15" />
            <StyledRect2 x="130" y="120" width="40" height="15" />
            <StyledPathFleche
              d="M 66,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathCoteDotted d="M 60,57 L 60,83" />
            <StyledPathCoteDotted d="M 170,57 L 170,59" />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,92 L 55,129"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="45"
                y="113"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="37"
                  y="113"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,140 L 93,140"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="79"
                y="146"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="78"
                  y="146"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,67 L 175,129"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="100"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="100"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 137,140 L 165,140"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="151"
                y="146"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="149"
                  y="146"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 73,93 L 159,74" className={styles.dotted} />
            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 73,93 L 73,120" className={styles.dotted} />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 157,74 L 157,120"
              className={styles.dotted}
            />

            {afficherTexteRacleur(67, 107, 1)}
            {afficherTexteRacleur(115, 80, 2)}
            {afficherTexteRacleur(160, 100, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppePenteMontante,
        S08_STEP5_arrayShape_pente_C_montante(),
        1
      ):
        return (
          <>
            {formePente_V2(156, 125)}
            <StyledRect2 x="75" y="141" width="15" height="15" />
            <StyledRect2 x="140" y="115" width="30" height="15" />

            <StyledPathFleche
              d="M 66,159 L 84,159"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="75"
                y="165"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="73"
                  y="165"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,92 L 55,149"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="45"
                y="120"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="37"
                  y="120"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathCoteDotted d="M 59,57 L 59,83" />
            <StyledPathCoteDotted d="M 171,57 L 171,59" />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,67 L 175,124"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="98"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="98"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 146,133 L 164,133"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="155"
                y="140"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="153"
                  y="140"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathCoteDotted d="M 76,130 L 140,130" />
            <StyledPathCoteDotted d="M 93,156 L 170,156" />
            <StyledPathFleche
              d="M 94,136 L 94,150"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="98"
                y="145"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="97"
                  y="145"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 73,93 L 159,74" className={styles.dotted} />
            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 73,93 L 73,142" className={styles.dotted} />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 157,74 L 157,115"
              className={styles.dotted}
            />

            {afficherTexteRacleur(67, 115, 1)}
            {afficherTexteRacleur(115, 80, 2)}
            {afficherTexteRacleur(160, 107, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppePenteMontante,
        S08_STEP5_arrayShape_pente_C_montante(),
        2
      ):
        return (
          <>
            {formePente_V2(125, 156)}
            <StyledRect2 x="140.5" y="141" width="15" height="15" />
            <StyledRect2 x="60" y="115" width="30" height="15" />

            <StyledPathFleche
              d="M 66,133 L 84,133"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="75"
                y="139"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="73"
                  y="139"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,92 L 55,123"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="45"
                y="108"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="37"
                  y="108"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,55 L 165,55"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            <StyledPathCoteDotted d="M 59,57 L 59,83" />
            <StyledPathCoteDotted d="M 171,57 L 171,59" />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="118"
                y="52"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="112"
                  y="52"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 175,67 L 175,149"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="180"
                y="110"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="178"
                  y="110"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 146,160 L 164,160"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="155"
                y="166"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="153"
                  y="166"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathCoteDotted d="M 91,130 L 155,130" />
            <StyledPathCoteDotted d="M 60,156 L 140,156" />
            <StyledPathFleche
              d="M 136,136 L 136,150"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="130"
                y="145"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="123"
                  y="145"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted d="M 73,93 L 159,74" className={styles.dotted} />
            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 73,93 L 73,115" className={styles.dotted} />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 157,74 L 157,142"
              className={styles.dotted}
            />

            {afficherTexteRacleur(67, 107, 1)}
            {afficherTexteRacleur(115, 80, 2)}
            {afficherTexteRacleur(160, 115, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      default:
        return <>{formeMontante()}</>;
    }
  };

  const formeD1 = () => {
    return (
      <>
        <StyledPath2 d="M 60,67 101,67 101,86 88,86 88,79 73,79 73,102 60,102 60,67" />
        <StyledRect2 x="60" y="102" width="107" height="12" />
        <StyledPath2 d="M 167,114 167,148 134,148 134,129 146,129 146,136 154,136 154,114 167,114" />
      </>
    );
  };

  const formeD2 = () => {
    return (
      <>
        <StyledPath2 d="M 167,67 126,67 126,86 139,86 139,79 154,79 154,102 167,102 167,67" />
        <StyledRect2 x="60" y="102" width="107" height="12" />
        <StyledPath2 d="M 60,114 60,148 93,148 93,129 81,129 81,136 73,136 73,114 60,114" />
      </>
    );
  };

  const formeD3 = () => {
    return (
      <>
        {/* Membre gauche */}
        <StyledPath2 d="M 115,96 76,94 76,69 86,69 86,77 94,77 94,61 68,61 68,102 113,104 115,96" />

        {/* Membre droit */}
        <StyledPath2 d="M 115,96 157,118 157,157 132,157 132,141 140,141 140,149 149,149 149,123 113,104 115,96" />
      </>
    );
  };

  const formeD4 = () => {
    return (
      <>
        {/* Membre gauche */}
        <StyledPath2 d="M 115,96 73,118 73,157 98,157 98,141 90,141 90,149 81,149 81,123 117,104 115,96" />
        {/* Membre droit */}
        <StyledPath2 d="M 115,96 154,94 154,69 144,69 144,77 136,77 136,61 162,61 162,102 117,104 115,96" />
      </>
    );
  };

  const afficherFormeToit_S = () => {
    switch (true) {
      case equalsCodeOrLabel(
        sectionEnveloppeS,
        S08_STEP5_arrayShape_toitS_D(),
        0
      ):
        return (
          <>
            {formeD1()}

            <StyledPathFleche
              d="M 130,135 L 130,142"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="125"
                y="140"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="118"
                  y="140"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 140,152 L 161,152"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="151"
                y="158"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="149"
                  y="158"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 172,109 L 172,142"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="175"
                y="127"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="175"
                  y="127"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,52 L 162,52"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="115"
                y="50"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="115"
                  y="50"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 55,74 L 55,108"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="50"
                y="95"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="43"
                  y="95"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,63 L 95,63"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="80"
                y="62"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="78"
                  y="62"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 105,74 L 105,81"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_G])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_G])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_G], styles)}
            />
            {longueurG === "" ? (
              <text
                fontSize="0.4em"
                x="109"
                y="80"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
              >
                G
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="107"
                  y="80"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
                >
                  {longueurG}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted
              d="M 63,110 L 159,110"
              className={styles.dotted}
            />
            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 63,70 L 63,110" className={styles.dotted} />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 157,110 L 157,142"
              className={styles.dotted}
            />

            {afficherTexteRacleur(67, 94, 3)}
            {afficherTexteRacleur(115, 107, 2)}
            {afficherTexteRacleur(160, 125, 1)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppeS,
        S08_STEP5_arrayShape_toitS_D(),
        1
      ):
        return (
          <>
            {formeD2()}

            <StyledPathFleche
              d="M 97,135 L 97,142"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="101"
                y="140"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="100"
                  y="140"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 66,152 L 87,152"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="77"
                y="158"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="75"
                  y="158"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 57,108 L 57,142"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="52"
                y="127"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="46"
                  y="127"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,52 L 160,52"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="115"
                y="50"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="111"
                  y="50"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 170,73 L 170,108"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="175"
                y="93"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="173"
                  y="93"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 132,63 L 160,63"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="147"
                y="62"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="143"
                  y="62"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 122,73 L 122,81"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_G])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_G])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_G], styles)}
            />
            {longueurG === "" ? (
              <text
                fontSize="0.4em"
                x="115"
                y="79"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
              >
                G
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="109"
                  y="79"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
                >
                  {longueurG}
                </text>
              </>
            )}

            {/* ligne pointillée centrale */}
            <StyledPathDotted
              d="M 72,110 L 164,110"
              className={styles.dotted}
            />
            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 71,110 L 71,137" className={styles.dotted} />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 164,110 L 164,67"
              className={styles.dotted}
            />

            {afficherTexteRacleur(65, 127, 1)}
            {afficherTexteRacleur(115, 107, 2)}
            {afficherTexteRacleur(158, 93, 3)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppeS,
        S08_STEP5_arrayShape_toitS_D(),
        2
      ):
        return (
          <>
            {formeD3()}

            <StyledPathFleche
              d="M 128,147 L 128,151"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="123"
                y="151"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="116"
                  y="151"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 138,160 L 152,160"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="145"
                y="166"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="143"
                  y="166"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 162,125 L 162,151"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="167"
                y="140"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="166"
                  y="140"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 123,96 L 153,112"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="140"
                y="102"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="138"
                  y="102"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 82,92 L 109,93"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="96"
                y="91"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="94"
                  y="91"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 65,67 L 65,96"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="61"
                y="84"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="54"
                  y="84"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 74,58 L 88,58"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_G])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_G])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_G], styles)}
            />
            {longueurG === "" ? (
              <text
                fontSize="0.4em"
                x="81"
                y="56"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
              >
                G
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="79"
                  y="56"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
                >
                  {longueurG}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 97,67 L 97,72"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_H])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_H])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_H], styles)}
            />
            {longueurH === "" ? (
              <text
                fontSize="0.4em"
                x="101"
                y="71.5"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
              >
                H
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="100"
                  y="71.5"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
                >
                  {longueurH}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 74,50 L 152,50"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_I])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_I])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_I], styles)}
            />
            {longueurI === "" ? (
              <text
                fontSize="0.4em"
                x="115"
                y="48"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_I], styles)}
              >
                I
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="113"
                  y="48"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_I], styles)}
                >
                  {longueurI}
                </text>
              </>
            )}

            <path
              d="M 76.5,87 Q 85,85 85,94"
              className={checkFocusedAngle(formik, [S08_ANGLE_J], styles)}
            />
            {angleJ === "" ? (
              <text
                fontSize="0.4em"
                x="83"
                y="85"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_ANGLE_J], styles)}
              >
                J
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="82"
                  y="85"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_ANGLE_J], styles)}
                >
                  {angleJ}
                </text>
              </>
            )}

            <path
              d="M 106,104 Q 110,112 120,108"
              className={checkFocusedAngle(formik, [S08_ANGLE_K], styles)}
            />
            {angleK === "" ? (
              <text
                fontSize="0.4em"
                x="111"
                y="115"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_ANGLE_K], styles)}
              >
                K
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="109"
                  y="116"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_ANGLE_K], styles)}
                >
                  {angleK}
                </text>
              </>
            )}

            <path
              d="M 143,120 Q 141,130 148.5,130"
              className={checkFocusedAngle(formik, [S08_ANGLE_L], styles)}
            />
            {angleL === "" ? (
              <text
                fontSize="0.4em"
                x="139"
                y="130"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_ANGLE_L], styles)}
              >
                L
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="132"
                  y="130"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_ANGLE_L], styles)}
                >
                  {angleL}
                </text>
              </>
            )}

            {/* ligne pointillée gauche verticale*/}
            <StyledPathDotted d="M 69,64 L 69,100" className={styles.dotted} />
            {/* ligne pointillée centrale gauche */}
            <StyledPathDotted
              d="M 113,102 L 65,100"
              className={styles.dotted}
            />
            {/* ligne pointillée centrale droit */}
            <StyledPathDotted
              d="M 151,122 L 113,102"
              className={styles.dotted}
            />
            {/* ligne pointillée droite verticale */}
            <StyledPathDotted
              d="M 151,122 L 151,150"
              className={styles.dotted}
            />

            {afficherTexteRacleur(152, 135, 1)}
            {afficherTexteRacleur(130, 110, 2)}
            {afficherTexteRacleur(95, 100, 3)}
            {afficherTexteRacleur(71, 84, 4)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      case equalsCodeOrLabel(
        sectionEnveloppeS,
        S08_STEP5_arrayShape_toitS_D(),
        3
      ):
        return (
          <>
            {formeD4()}

            <StyledPathFleche
              d="M 102,147 L 102,151"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_A])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_A])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_A], styles)}
            />
            {longueurA === "" ? (
              <text
                fontSize="0.4em"
                x="107"
                y="151"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
              >
                A
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="105"
                  y="151"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_A], styles)}
                >
                  {longueurA}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 78,160 L 92,160"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_B])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_B])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_B], styles)}
            />
            {longueurB === "" ? (
              <text
                fontSize="0.4em"
                x="85"
                y="166"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
              >
                B
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="84"
                  y="166"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_B], styles)}
                >
                  {longueurB}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 69,125 L 69,151"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_C])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_C])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_C], styles)}
            />
            {longueurC === "" ? (
              <text
                fontSize="0.4em"
                x="63"
                y="140"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
              >
                C
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="58"
                  y="140"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_C], styles)}
                >
                  {longueurC}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 107,96 L 77,112"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_D])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_D])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_D], styles)}
            />
            {longueurD === "" ? (
              <text
                fontSize="0.4em"
                x="90"
                y="102"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
              >
                D
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="87"
                  y="102"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_D], styles)}
                >
                  {longueurD}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 121,93 L 148,92"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_E])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_E])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_E], styles)}
            />
            {longueurE === "" ? (
              <text
                fontSize="0.4em"
                x="134"
                y="91"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
              >
                E
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="130"
                  y="91"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_E], styles)}
                >
                  {longueurE}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 165,67 L 165,96"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_F])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_F])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_F], styles)}
            />
            {longueurF === "" ? (
              <text
                fontSize="0.4em"
                x="168"
                y="84"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
              >
                F
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="167"
                  y="84"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_F], styles)}
                >
                  {longueurF}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 142,58 L 156,58"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_G])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_G])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_G], styles)}
            />
            {longueurG === "" ? (
              <text
                fontSize="0.4em"
                x="149"
                y="56.5"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
              >
                G
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="147"
                  y="56.5"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_G], styles)}
                >
                  {longueurG}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 133,67 L 133,72"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_H])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_H])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_H], styles)}
            />
            {longueurH === "" ? (
              <text
                fontSize="0.4em"
                x="129"
                y="71.5"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
              >
                H
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="122"
                  y="71.5"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_H], styles)}
                >
                  {longueurH}
                </text>
              </>
            )}

            <StyledPathFleche
              d="M 78,50 L 156,50"
              markerStart={checkFocusedStartMarker(formik, [S08_LONGUEUR_I])}
              markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_I])}
              className={checkFocusedArrow(formik, [S08_LONGUEUR_I], styles)}
            />
            {longueurI === "" ? (
              <text
                fontSize="0.4em"
                x="119"
                y="48"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_LONGUEUR_I], styles)}
              >
                I
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="117"
                  y="48"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_LONGUEUR_I], styles)}
                >
                  {longueurI}
                </text>
              </>
            )}

            <path
              d="M 153.5,87 Q 145,85 145,94"
              className={checkFocusedAngle(formik, [S08_ANGLE_J], styles)}
            />
            {angleJ === "" ? (
              <text
                fontSize="0.4em"
                x="143"
                y="85"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_ANGLE_J], styles)}
              >
                J
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="138"
                  y="85"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_ANGLE_J], styles)}
                >
                  {angleJ}
                </text>
              </>
            )}

            <path
              d="M 124,104 Q 120,112 110,108"
              className={checkFocusedAngle(formik, [S08_ANGLE_K], styles)}
            />
            {angleK === "" ? (
              <text
                fontSize="0.4em"
                x="119"
                y="115"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_ANGLE_K], styles)}
              >
                K
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="117"
                  y="116"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_ANGLE_K], styles)}
                >
                  {angleK}
                </text>
              </>
            )}

            <path
              d="M 87,120 Q 89,130 81.5,130"
              className={checkFocusedAngle(formik, [S08_ANGLE_L], styles)}
            />
            {angleL === "" ? (
              <text
                fontSize="0.4em"
                x="91"
                y="130"
                textAnchor="middle"
                className={checkFocusedText(formik, [S08_ANGLE_L], styles)}
              >
                L
              </text>
            ) : (
              <>
                <text
                  fontSize="0.4em"
                  x="89"
                  y="130"
                  direction="ltr"
                  className={checkFocusedText(formik, [S08_ANGLE_L], styles)}
                >
                  {angleL}
                </text>
              </>
            )}

            {/* ligne pointillée droite verticale*/}
            <StyledPathDotted
              d="M 161,64 L 161,100"
              className={styles.dotted}
            />
            {/* ligne pointillée centrale droit */}
            <StyledPathDotted
              d="M 117,102 L 161,100"
              className={styles.dotted}
            />
            {/* ligne pointillée centrale gauche */}
            <StyledPathDotted
              d="M 79,122 L 117,102"
              className={styles.dotted}
            />
            {/* ligne pointillée gauche verticale */}
            <StyledPathDotted d="M 79,122 L 79,150" className={styles.dotted} />

            {afficherTexteRacleur(75, 135, 1)}
            {afficherTexteRacleur(97, 110, 2)}
            {afficherTexteRacleur(135, 100, 3)}
            {afficherTexteRacleur(156, 84, 4)}

            <StyledPathDotted
              d="M 185,164 L 205,164"
              className={styles.dotted}
            />
            <text fontSize="0.4em" x="210" y="165" direction="ltr">
              {t?.titreLegendeRacleurs}
            </text>
          </>
        );
      default:
        return <>{formeD2()}</>;
    }
  };

  const afficherFormeFace = (t) => {
    switch (true) {
      case equalsCodeOrLabel(
        typeSectionEnveloppe,
        S08_STEP5_arrayTypeSection(t),
        0
      ):
        return <>{afficherFormeA()}</>;
      case equalsCodeOrLabel(
        typeSectionEnveloppe,
        S08_STEP5_arrayTypeSection(t),
        1
      ):
        switch (true) {
          case equalsCodeOrLabel(typeToit, S08_STEP5_arrayTypeToit(), 0):
            return <>{afficherFormeToitIt1()}</>;
          case equalsCodeOrLabel(typeToit, S08_STEP5_arrayTypeToit(), 1):
            return <>{afficherFormeToitIt2()}</>;
          default:
            return null;
        }
      case equalsCodeOrLabel(
        typeSectionEnveloppe,
        S08_STEP5_arrayTypeSection(t),
        2
      ):
        switch (true) {
          case equalsCodeOrLabel(typePente, S08_STEP5_arrayTypePente(), 0):
            return <>{afficherFormePenteIt1()}</>;
          case equalsCodeOrLabel(typePente, S08_STEP5_arrayTypePente(), 1):
            return <>{afficherFormePenteIt2()}</>;
          default:
            return null;
        }
      case equalsCodeOrLabel(
        typeSectionEnveloppe,
        S08_STEP5_arrayTypeSection(t),
        3
      ):
        return <>{afficherFormeToit_S()}</>;
      default:
        return null;
    }
  };

  return (
    <Paper elevation={0}>
      <svg viewBox="-10 40 255 130">
        <defs>
          <marker
            id="startarrow"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 2, 10 5, 0 3.5" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 2, 10 3.5, 0 5" />
          </marker>
          <marker
            id="startarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="10 2, 10 5, 0 3.5"
              className={styles.markerSelected}
            />
          </marker>
          <marker
            id="endarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon
              points="0 2, 10 3.5, 0 5"
              className={styles.markerSelected}
            />
          </marker>
        </defs>

        <title></title>

        {afficherFormeFace()}
        {/* <image
          href={importAll()["D3.png"]}
          height="100%"
          width="100%"
          x="-14"
          y="40"
        /> */}
      </svg>
    </Paper>
  );
};
