import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import {
  StyledCheckbox,
  StyledCheckboxWithoutAnimation,
} from "../../../styledComponents/StyledCheckbox";
import {
  StyledDivAlignItemsHorizontally,
  StyledDivCheckboxBoxShadow,
  StyledDivCheckboxImage,
  StyledDivFormMarginTitle,
  StyledDivFormSection,
  StyledDivLabelQuestion,
  StyledDivLegend,
} from "../../../styledComponents/StyledDiv";
import { StyledGridCheckbox } from "../../../styledComponents/StyledGrid";
import { HelperText } from "../../HelperText";
import {
  CheckImageDisplayForRadioButton,
  displayErrorForElementsWithoutErrorHandling,
} from "../commonMethods/CommonMethods";
import {
  CONTAINED,
  OUTLINED_PRIMARY_BUTTON_GROUP,
} from "../commonParameters/CommonParameters";

/**
 * It generates a checkbox for each item in the list of checkboxes.
 * @param champ - The field to be rendered.
 * @param formik - The Formik object that contains the form state and the methods to change it.
 * @returns The `genererChampCheckbox` function returns a `Grid` item.
 */
const genererChampCheckbox = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledDivLabelQuestion className={champ.cssFontFamily}>
          {champ.label}
        </StyledDivLabelQuestion>

        <FormGroup
          variant={CONTAINED.description}
          aria-label={OUTLINED_PRIMARY_BUTTON_GROUP.description}
          name={champ.nom.description}
          value={formik.values[champ.nom.description]}
        >
          {champ?.donnees.map((checkbox) => {
            return (
              <StyledDivLegend
                className={champ.cssFontFamily}
                key={
                  checkbox.code.description
                    ? checkbox.code.description
                    : checkbox.code
                }
              >
                <CheckImageDisplayForRadioButton
                  img={checkbox.img}
                  images={champ.images}
                />

                <FormControlLabel
                  name={champ.nom.description}
                  id={champ.nom.description}
                  value={
                    checkbox.code.description
                      ? checkbox.code.description
                      : checkbox.code
                  }
                  control={
                    <StyledCheckbox
                      checked={formik.values[champ.nom.description].includes(
                        checkbox.code.description
                          ? checkbox.code.description
                          : checkbox.code
                      )}
                    />
                  }
                  onChange={formik.handleChange}
                  label={<div>{checkbox.name}</div>}
                />
              </StyledDivLegend>
            );
          })}
        </FormGroup>
        {displayErrorForElementsWithoutErrorHandling(champ, formik)}
      </Grid>
    </StyledDivFormSection>
  );
};

/**
 * It generates a checkbox for each item in the list of checkboxes.
 * @param champ - The field to be rendered.
 * @param formik - The Formik object that contains the form state and the methods to change it.
 * @returns The `genererChampCheckbox` function returns a `Grid` item.
 */
const genererChampCheckboxBoxShadow = (champ, formik) => {
  return (
    <StyledDivFormMarginTitle
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      {champ.label ? <div>{champ.label}</div> : null}
      <StyledGridCheckbox item>
        {champ?.donnees.map((checkbox) => {
          return (
            <StyledDivCheckboxBoxShadow
              key={
                checkbox.code.description
                  ? checkbox.code.description
                  : checkbox.code
              }
              onClick={() => {
                formik.setFieldValue(
                  checkbox.code.description,
                  +!formik.values[checkbox.code.description]
                );
              }}
              className={
                formik.values[checkbox.code.description] === 1
                  ? champ.cssSurroundGridItem
                  : null
              }
            >
              <StyledDivCheckboxImage>
                {checkbox.textInformation?.text ? (
                  <HelperText
                    name={checkbox.code.description}
                    textInformation={checkbox.textInformation}
                    gridStyle={{ position: "absolute", marginLeft: "9.5em" }}
                  />
                ) : null}
                <CheckImageDisplayForRadioButton
                  img={checkbox.img}
                  images={champ.images}
                />
              </StyledDivCheckboxImage>
              <StyledDivAlignItemsHorizontally>
                <StyledCheckboxWithoutAnimation
                  name={champ.nom.description}
                  checked={!!formik.values[checkbox.code.description]}
                  value={
                    checkbox.code.description
                      ? checkbox.code.description
                      : checkbox.code
                  }
                  className={champ.cssCheckbox}
                />
                <StyledDivLegend className={champ.cssFontFamily}>
                  {checkbox.name}
                </StyledDivLegend>
              </StyledDivAlignItemsHorizontally>
            </StyledDivCheckboxBoxShadow>
          );
        })}
        {displayErrorForElementsWithoutErrorHandling(champ, formik)}
      </StyledGridCheckbox>
    </StyledDivFormMarginTitle>
  );
};

const genererChampCheckboxByLabel = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledDivLabelQuestion className={champ.cssFontFamily}>
          {champ.label}
        </StyledDivLabelQuestion>
        <FormGroup
          variant={CONTAINED.description}
          aria-label={OUTLINED_PRIMARY_BUTTON_GROUP.description}
          name={champ.nom.description}
          value={formik.values[champ.nom.description]}
          className={champ.css4}
        >
          {champ?.donnees.map((checkbox) => {
            return (
              <StyledDivLegend
                className={champ.cssFontFamily}
                key={
                  checkbox.nom.description
                    ? checkbox.nom.description
                    : checkbox.nom
                }
              >
                <CheckImageDisplayForRadioButton
                  img={checkbox.img}
                  images={champ.images}
                />
                <FormControlLabel
                  name={champ.nom.description}
                  id={champ.nom.description}
                  value={
                    checkbox.nom.description
                      ? checkbox.label.description
                      : checkbox.label
                  }
                  control={
                    <StyledCheckbox
                      checked={formik.values[champ.nom.description].includes(
                        checkbox.nom.description
                          ? checkbox.label.description
                          : checkbox.label
                      )}
                    />
                  }
                  onChange={formik.handleChange}
                  label={<div>{checkbox.label}</div>}
                />
              </StyledDivLegend>
            );
          })}
        </FormGroup>
        {displayErrorForElementsWithoutErrorHandling(champ, formik)}
      </Grid>
    </StyledDivFormSection>
  );
};

/**
 * It generates a checkbox for each item in the list of checkboxes.
 * @param champ - The field to be rendered.
 * @param formik - The Formik object that contains the form state and the methods to change it.
 * @returns The `genererChampCheckbox` function returns a `Grid` item.
 */
const genererChampCheckboxImageCustom = (
  champ,
  formik,
  useBoxShadowStyle = false
) => {
  const CheckboxComponent = useBoxShadowStyle
    ? StyledDivCheckboxBoxShadow
    : Grid;
  const legendClassName = champ.cssFontFamily ? champ.cssFontFamily : "";

  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledDivLabelQuestion className={legendClassName}>
          {champ.label}
        </StyledDivLabelQuestion>

        <StyledGridCheckbox item
          variant={CONTAINED.description}
          aria-label={OUTLINED_PRIMARY_BUTTON_GROUP.description}
          name={champ.nom.description}
          value={formik.values[champ.nom.description]}
        >
          {champ?.donnees.map((checkbox) => {
            return (
              <StyledDivCheckboxBoxShadow>
                <StyledDivLegend
                  className={legendClassName}
                  key={checkbox.code.description || checkbox.code}
                >
                  <StyledDivCheckboxImage>
                    {checkbox.textInformation?.text ? (
                      <HelperText
                        name={checkbox.code.description}
                        textInformation={checkbox.textInformation}
                        gridStyle={{
                          position: "absolute",
                          marginLeft: "9.5em",
                        }}
                      />
                    ) : null}
                    <CheckImageDisplayForRadioButton
                      img={checkbox.img}
                      images={champ.images}
                    />
                  </StyledDivCheckboxImage>
                  <FormControlLabel
                    name={champ.nom.description}
                    id={champ.nom.description}
                    value={checkbox.code.description || checkbox.code}
                    control={
                      <StyledCheckbox
                        checked={formik.values[champ.nom.description].includes(
                          checkbox.code.description || checkbox.code
                        )}
                      />
                    }
                    onChange={formik.handleChange}
                    label={<div>{checkbox.name}</div>}
                  />
                </StyledDivLegend>
              </StyledDivCheckboxBoxShadow>
            );
          })}
        </StyledGridCheckbox>
        {displayErrorForElementsWithoutErrorHandling(champ, formik)}
      </Grid>
    </StyledDivFormSection>
  );
};

export {
  genererChampCheckbox,
  genererChampCheckboxBoxShadow,
  genererChampCheckboxByLabel,
  genererChampCheckboxImageCustom,
};
