const CONFIGURATEUR = Symbol("Configurateur");
const DEMANDE_DE_PRIX = Symbol("ddp");
const OFFRE_DE_PRIX = Symbol("odp");
const ARTICLE = Symbol("art");
const RECHERCHER = Symbol("Rechercher");
const CONTACT = Symbol("Contact");
const SOS = Symbol("SOS");

export {
  CONFIGURATEUR,
  DEMANDE_DE_PRIX,
  OFFRE_DE_PRIX,
  ARTICLE,
  RECHERCHER,
  CONTACT,
  SOS,
};
