import * as Yup from "yup";
import { DROPZONE_CONTACT } from "../ContactChamps";
import {
  CP,
  DEMANDE,
  EMAIL,
  FONCTION,
  NOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TEL,
} from "../../enum/champs/ChampsCommunFormulairesEnum";
import { PRENOM } from "../../../../constantes/symbols/SymbolsCommon";

/* Setting the maximum size of a file to 10MB. */
const FILE_SIZE = 100000000;
/* A constant that contains the list of supported formats. */
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/bmp",
  "image/png",
];

const phoneRegExp = /^\+|0(?:[0-9] ?){6,14}[0-9]$/;

/* A Yup validation schema. */
export const ValidContact = (secteursList, fonctionsList, paysList, t) => {
  return Yup.object({
    [SOCIETE.description]: Yup.string().required(t.renseignerChamp),
    [DEMANDE.description]: Yup.string(t.demandeDescription).min(
      4,
      t.champPlusDe4Caractere
    ),
    [DROPZONE_CONTACT.description]: Yup.mixed()
      .test(
        "fileSize",
        (fileList) => {
          return (
            t.erreurFichierVolumineux +
            getNameFilesTooBig(fileList.originalValue).map((file) => {
              return file.name + "\n";
            })
          );
        },
        (fileList) => {
          return getNameFilesTooBig(fileList).length === 0;
        }
      )
      .test(
        "fileCount",
        (fileList) => {
          return t.erreurFichierMaxAtteint + fileList.length;
        },
        (fileList) => {
          return fileList.length <= 10;
        }
      )
      .test(
        "fileFormat",
        (fileList) => {
          return (
            t.erreurFormatFichier +
            getNameFileWrongFormat(fileList.originalValue).map((file) => {
              return file.name + "\n";
            })
          );
        },
        (fileList) => {
          return getNameFileWrongFormat(fileList).length === 0;
        }
      ),

    [CP.description]: Yup.string(t.demandeDescription)
      .min(4, t.champPlusDe4Caractere)
      .max(20, t.champMoinsDe20Caractere)
      .required(t.renseignerChamp),

    [NOM.description]: Yup.string(t.demandeDescription)
      .min(4, t.champPlusDe4Caractere)
      .max(20, t.champMoinsDe20Caractere)
      .required(t.renseignerChamp),
    [PRENOM.description]: Yup.string(t.demandeDescription)
      .min(4, t.champPlusDe4Caractere)
      .max(20, t.champMoinsDe20Caractere)
      .required(t.renseignerChamp),
    [EMAIL.description]: Yup.string(t.demandeDescription)
      .min(4, t.champPlusDe4Caractere)
      .email(t.emailNonValide)
      .required(t.renseignerChamp),
    [TEL.description]: Yup.string(t.demandeDescription)
      .matches(phoneRegExp, t.numeroInvalide)
      .required(t.renseignerChamp),

    [SECTEUR.description]: Yup.mixed()
      .test(
        "SecteurActiviteInList",
        () => {
          return t.selectionSecteurActivite;
        },
        (selectedValue) =>
          secteursList.map((value) => value.name).includes(selectedValue?.name)
      )
      .required(t.renseignerChamp),
    [FONCTION.description]: Yup.mixed().test(
      "FonctionInList",
      () => {
        return t.selectionFonction;
      },
      (selectedValue) => {
        if (selectedValue === null || selectedValue === undefined) {
          return true;
        }
        return fonctionsList
          .map((value) => value.name)
          .includes(selectedValue?.name);
      }
    ),
    [PAYS.description]: Yup.mixed()
      .test(
        "PaysInList",
        () => {
          return t.selectionPays;
        },
        (selectedValue) =>
          paysList.map((value) => value.name).includes(selectedValue?.name)
      )
      .required(t.renseignerChamp),
  });
};

/**
 * Given a list of files, return a list of files that are too big
 * @param listFiles - the list of files to be filtered
 * @returns An array of objects.
 */
const getNameFilesTooBig = (listFiles) => {
  return listFiles.filter((file) => file.size > FILE_SIZE);
};

/**
 * Given a list of files, return a list of files that are not of a supported format
 * @param listFiles - The list of files to be filtered.
 * @returns An array of files that are not supported.
 */
const getNameFileWrongFormat = (listFiles) => {
  return listFiles.filter((file) => !SUPPORTED_FORMATS.includes(file.type));
};
