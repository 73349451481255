import { styled } from "@mui/system";

export const StyledRect = styled("rect")({
  stroke: "rgba(30,53,93,1)",
  fill: "none",
});

export const StyledRect2 = styled("rect")({
  stroke: "rgba(30,53,93,1)",
  fill: "rgba(30,53,93,1)",
});

export const StyledRect3 = styled("rect")({
  stroke: "rgba(30,53,93,1)",
  fill: "rgba(178,186,187,1)",
});

export const StyledRect4 = styled("rect")({
  stroke: "rgba(30,53,93,1)",
  fill: "yellow",
});

export const StyledRect5 = styled("rect")({
  stroke: "rgb(204,0,123,1)",
  fill: "none",
});

export const StyledRect6 = styled("rect")({
  stroke: "rgba(30,53,93,0.3)",
  fill: "rgba(30,53,93,0.3)",
});
