import {
  ADRESSE_MAIL,
  CODE_POSTAL,
  FONCTION,
  NOM,
  PAYS,
  PRENOM,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  TEXTFIELD,
  TEXTFIELD_SEARCHBAR,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

export const formulaireCoordonnees = (pays, secteurs, fonctions, styles, t) => [
  {
    nom: SOCIETE,
    label: t.titreSociete+t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
  },
  {
    nom: PAYS,
    label: t.titrePays+t.asterisque,
    type: TEXTFIELD_SEARCHBAR,
    donnees: pays,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
    // textInformation: {
    //   text: t.informationsComplementairesInfo,
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
  {
    nom: CODE_POSTAL,
    label: t.titreCodePostal+t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
  },
  {
    nom: SECTEUR,
    label: t.titreSecteurActivite+t.asterisque,
    type: TEXTFIELD_SEARCHBAR,
    donnees: secteurs,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
  },
  {
    nom: NOM,
    label: t.titreNom+t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
  },
  {
    nom: PRENOM,
    label: t.titrePrenom+t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
  },
  {
    nom: FONCTION,
    label: t.titreFonctionSansObli,
    type: TEXTFIELD_SEARCHBAR,
    donnees: fonctions,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
  },
  {
    nom: ADRESSE_MAIL,
    label: t.titreAdresseMail+t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
  },
  {
    nom: TELEPHONE,
    label: t.titreTelephone+t.asterisque,
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormSansBg,
  },
];
