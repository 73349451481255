import { Grid } from "@mui/material";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
  StyledDivPhotoEnv,
  StyledDivPhotoPasDessin,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import { importAll, importAllSpec } from "../../../../helper/ImportAll";

import { DessinTechnique } from "../../components/DessinTechnique";
import {
  formulaireDimensionsCorps,
  formulaireDimensionsCorpsRectangulaireConique,
  formulaireDimensionsCorpsRectangulaireDroit,
  formulaireDimensionsCorpsRondeConique,
  formulaireDimensionsCorpsRondeDroit,
  formulaireDimensionsCorpsRondeDroitSansSpires,
  formulaireDimensionsCorpsRondeConiqueSansSpires,
  formulaireDimensionsCorpsRectangulaireDroitSansSpires,
} from "../../ChampsFormulaires/S01/etape4/ChampsDimensionsCorps";
import {
  arraySectionSoufflet,
  arrayTypeCorps,
} from "../../../../constantes/symbols/SymbolsServicesS01";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import {
  SECTION_SOUFFLET,
  TYPE_CORPS,
  SOUFFLET_AVEC_SPIRE,
} from "../../../../constantes/symbols/SymbolsS01";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../constantes/symbols/HelperSymbolsServices";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";

export const StepS01BodyDim = ({
  state: {
    booleanIdentite,
    formik,
    styles,
    styles: {
      cssFontFamily,
      cssButton,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
  },
  action: { handleRetour },
  nom,
}) => {
  const lang = useTranslations();
  
  const isSectionSouffletRond = equalsCodeOrLabel(
    formik.values[SECTION_SOUFFLET.description],
    arraySectionSoufflet(),
    0
  );
  const isSectionSouffletRectangulaire = equalsCodeOrLabel(
    formik.values[SECTION_SOUFFLET.description],
    arraySectionSoufflet(),
    1
  );
  const isTypeCorpsDroit = equalsCodeOrLabel(
    formik.values[TYPE_CORPS.description],
    arrayTypeCorps(),
    0
  );
  const isTypeCorpsConique = equalsCodeOrLabel(
    formik.values[TYPE_CORPS.description],
    arrayTypeCorps(),
    1
  );
  const souffletAvecSpire = equalsBooleanOrStringOrNumber(
    formik.values[SOUFFLET_AVEC_SPIRE.description]
  );

  const afficherLesChampsDeDimensions = () => {
    switch (true) {
      case isSectionSouffletRond:
        switch (true) {
          case isTypeCorpsDroit:
            switch (souffletAvecSpire) {
              case 1:
                return genererFormulaire(
                  formulaireDimensionsCorpsRondeDroit(styles, t),
                  formik
                );

              case 0:
                return genererFormulaire(
                  formulaireDimensionsCorpsRondeDroitSansSpires(styles, t),
                  formik
                );

              default:
                return null;
            }

          case isTypeCorpsConique:
            switch (souffletAvecSpire) {
              case 1:
                return genererFormulaire(
                  formulaireDimensionsCorpsRondeConique(styles, t),
                  formik
                );

              case 0:
                return genererFormulaire(
                  formulaireDimensionsCorpsRondeConiqueSansSpires(styles, t),
                  formik
                );

              default:
                return null;
            }

          default:
            return null;
        }
      case isSectionSouffletRectangulaire:
        switch (true) {
          case isTypeCorpsDroit:
            switch (souffletAvecSpire) {
              case 1:
                return genererFormulaire(
                  formulaireDimensionsCorpsRectangulaireDroit(styles, t),
                  formik
                );

              case 0:
                return genererFormulaire(
                  formulaireDimensionsCorpsRectangulaireDroitSansSpires(
                    styles,
                    t
                  ),
                  formik
                );

              default:
                return null;
            }
          case isTypeCorpsConique:
            return genererFormulaire(
              formulaireDimensionsCorpsRectangulaireConique(styles, t),
              formik
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const afficherDessinTechnique = () => {
    if (isSectionSouffletRectangulaire && isTypeCorpsConique) {
      return (
        <StyledDivPhotoPasDessin
          style={{
            backgroundImage:
              "url(" + importAll()["SouffletRectangulaireConique.jpg"] + ")",
          }}
        />
      );
    } else {
      return <DessinTechnique formik={formik.values} styles={styles} t={t} />;
    }
  };

  const afficherSOSetForm = () => {
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyRenseignezToutesLesDimensionsSoufflet}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(
              formulaireDimensionsCorps(styles, t, formik),
              formik
            )}
            {afficherLesChampsDeDimensions()}

            <StyledDivDessinMobile>
              <StyledDivDessin>
                {afficherDessinTechnique()}
                <StyledDivFiligranne
                  style={{
                    backgroundImage:
                      "url(" +
                      importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                      ")",
                  }}
                  className={logoFiligraneDessin}
                />
              </StyledDivDessin>
              {lang.lang === "FR" && afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={cssButton}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
        {lang.lang === "FR" && afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
