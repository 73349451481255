import { FOCUSED } from "../../../../../constantes/symbols/SymbolsCommon";
import {
  ALIGNEMENT_IT1,
  ALIGNEMENT_IT2,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT1,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT2,
  BRIDE_FIXATION_IT1,
  BRIDE_FIXATION_IT2,
  S06_BRIDE_IT1,
  S06_BRIDE_IT2,
  S06_EXTREMITE_IDENTIQUE,
  S06_TYPE_EXTREMITES_IT1,
  S06_TYPE_EXTREMITES_IT2,
  S06_MANCHETTE_A_IT1,
  S06_MANCHETTE_A_IT2,
  S06_MANCHETTE_B_IT1,
  S06_MANCHETTE_B_IT2,
  S06_MANCHETTE_LARGEUR_IT1,
  S06_MANCHETTE_LARGEUR_IT2,
} from "../../../../../constantes/symbols/SymbolsS06";
import {
  initChampsFormikToJsonDefaultArray,
  initChampsFormikToJsonDefaultCustom,
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
  S06_TYPE_EXTREMITES_IT1,
  S06_TYPE_EXTREMITES_IT2,
  S06_MANCHETTE_A_IT1,
  S06_MANCHETTE_A_IT2,
  S06_MANCHETTE_LARGEUR_IT1,
  S06_MANCHETTE_LARGEUR_IT2,
  S06_MANCHETTE_B_IT1,
  S06_MANCHETTE_B_IT2,
  ALIGNEMENT_IT1,
  ALIGNEMENT_IT2,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT1,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT2,
  S06_BRIDE_IT1,
  S06_BRIDE_IT2,
  BRIDE_FIXATION_IT1,
  BRIDE_FIXATION_IT2,
]

export const initialValuesDimensionsExtremitesS06 = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
      ...initChampsFormikToJsonDefaultCustom(
        [BRIDE_EXTREMITE_INSEREE_MATIERE_IT1],
        formulaire,
        0
      ),
      ...initChampsFormikToJsonDefaultCustom(
        [BRIDE_EXTREMITE_INSEREE_MATIERE_IT2],
        formulaire,
        0
      ),
      ...initChampsFormikToJsonDefaultCustom(
        [S06_EXTREMITE_IDENTIQUE],
        formulaire,
        1
      ),
      ...initChampsFormikToJsonDefaultCustom([FOCUSED], formulaire, null),
    },
    {}
  );
};
