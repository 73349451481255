import { Grid } from "@mui/material";
import { importAllSpec } from "../../../../helper/ImportAll";
import { DessinTechnique } from "../../components/DessinTechnique";

import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import {
  formulaireDimensionsAutre,
  formulaireDimensionsCaisson,
  formulaireDimensionsS05,
} from "../../ChampsFormulaires/S05/etape5/ChampsDimensionsS05";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import {
  SECTION_MAX,
  SECTION_MAX_A,
  SECTION_MAX_B,
  SECTION_MAX_C,
  TYPE_ENROULEUR,
} from "../../../../constantes/symbols/SymbolsS05";
import { S05_STEP4_arrayTypeEnrouleur } from "../../../../constantes/symbols/SymbolsServicesS05";
import { useRef } from "react";
import { useEffect } from "react";
import { equalsCodeOrLabel } from "../../../../constantes/symbols/HelperSymbolsServices";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";

export const StepS05BodyDim = ({
  state: {
    booleanIdentite,
    styles,
    styles: {
      cssFontFamily,
      cssButton,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    formik,
  },
  action: { handleRetour },
  nom,
}) => {
  const lang = useTranslations();

  const sectionMaxA = useRef(formik.values[SECTION_MAX_A.description]);
  const sectionMaxB = useRef(formik.values[SECTION_MAX_B.description]);
  // const sectionMaxC = useRef(formik.values[SECTION_MAX_C.description]);

  useEffect(() => {
    const sectionMaxField =
      sectionMaxA.current +
      "X" +
      sectionMaxB.current;
      // +
      // "X" +
      // sectionMaxC.current;
    formik.setFieldValue(SECTION_MAX.description, sectionMaxField);
  }, [sectionMaxA, sectionMaxB]);

  const afficherDessinTechnique = () => {
    return <DessinTechnique formik={formik.values} styles={styles} t={t}/>;
  };

  const afficherFormulaireCaisson = () => {
    if (
      equalsCodeOrLabel(
        formik.values[TYPE_ENROULEUR.description],
        S05_STEP4_arrayTypeEnrouleur(),
        0
      )
    ) {
      return (
        <>{genererFormulaire(formulaireDimensionsCaisson(styles, t), formik)}</>
      );
    } else {
      return (
        <>{genererFormulaire(formulaireDimensionsAutre(styles, t), formik)}</>
      );
    }
  };

  const afficherSOSetForm = () => {
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyRenseignezToutesLesDimensionsEnrouleur}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(formulaireDimensionsS05(styles, t), formik)}
            {afficherFormulaireCaisson()}

            <StyledDivDessinMobile>
              <StyledDivDessin>
                {afficherDessinTechnique()}
                <StyledDivFiligranne
                  style={{
                    backgroundImage:
                      "url(" +
                      importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                      ")",
                  }}
                  className={logoFiligraneDessin}
                />
              </StyledDivDessin>
              {lang.lang === "FR" && afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={cssButton}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>
      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
        {lang.lang === "FR" && afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
