import {
  DIMENSIONS_PLIS,
  NOMBRE_FACE,
  S06_LONGUEUR_A_EXT,
  S06_LONGUEUR_A_INT,
  S06_LONGUEUR_B_EXT,
  S06_LONGUEUR_B_INT,
  S06_LONGUEUR_COMPRIMEE,
  S06_LONGUEUR_C_EXT,
  S06_LONGUEUR_C_INT,
  S06_LONGUEUR_DEPLOYEE,
} from "../../../../../constantes/symbols/SymbolsS06";
import { S06_STEP5_arrayDimensionsPlis, S06_STEP5_arrayDimensionsPlisWithLongueurASup1000, S06_STEP5_arrayNombreFaces } from "../../../../../constantes/symbols/SymbolsServicesS06";
import {
  TEXTFIELD,
  TEXTFIELD_NUMBER,
  TEXTFIELD_SELECT,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

export const formulaireDimensionsPart1S06 = (styles, t) => [
  {
    nom: NOMBRE_FACE,
    label: t.nombreFaces + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: S06_STEP5_arrayNombreFaces(t),
    textInformation: {
      text: t.SQ0102_Q00110,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
 
  {
    nom: S06_LONGUEUR_A_INT,
    label: t.longueurAInt + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0102_Q00060,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S06_LONGUEUR_B_INT,
    label: t.longueurBInt + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0102_Q00080,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurCInt = (styles, t) => [
  {
    nom: S06_LONGUEUR_C_INT,
    label: t.longueurCInt + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    textInformation: {
      text: t.SQ0102_Q00081,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireDimensionsPart2S06 = (styles, t, formik) => [
  {
    nom: DIMENSIONS_PLIS,
    label: t.dimensionPlis + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: formik.values[S06_LONGUEUR_A_INT.description] > 1000 ? S06_STEP5_arrayDimensionsPlisWithLongueurASup1000(t) : S06_STEP5_arrayDimensionsPlis(t),
    textInformation: {
      text: t.SQ0102_Q00041,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
    handleChange: (value) => {
      formik.setFieldValue(DIMENSIONS_PLIS.description, value);
    },
  },

  {
    nom: S06_LONGUEUR_A_EXT,
    label: t.longueurAExt,
    value:
      formik.values[NOMBRE_FACE.description] ===
      S06_STEP5_arrayNombreFaces(t)[1].code
        ? formik.values[S06_LONGUEUR_A_INT.description] +
          2 * (formik.values[DIMENSIONS_PLIS.description]?.value || 0) // Utilisation de la valeur sélectionnée du select
        : formik.values[S06_LONGUEUR_A_INT.description] +
          1 * (formik.values[DIMENSIONS_PLIS.description]?.value || 0), // Utilisation de la valeur sélectionnée du select
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    disabled: true,
    textInformation: {
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S06_LONGUEUR_B_EXT,
    label: t.longueurBExt,
    value:
      formik.values[S06_LONGUEUR_B_INT.description] +
      2 * (formik.values[DIMENSIONS_PLIS.description]?.value || 0), // Utilisation de la valeur sélectionnée du select
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    disabled: true,
    textInformation: {
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireLongueurCExt = (styles, t, formik) => [
  {
    nom: S06_LONGUEUR_C_EXT,
    label: t.longueurCExt,
    value:
      formik.values[S06_LONGUEUR_C_INT.description] +
      1 * (formik.values[DIMENSIONS_PLIS.description]?.value || 0), // Utilisation de la valeur sélectionnée du select
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    disabled: true,
    textInformation: {
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];
