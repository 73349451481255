import { makeStyles } from "@mui/styles";

var WebFont = require("webfontloader");

WebFont.load({
  google: {
    families: ["Abel", "Cabin:400,500,600,700", "Dosis:400,500,600,700,800"],
  },
});

export const useStyles = makeStyles({
  cssCenter: {
    display: "flex",
    alignItems: "center",
  },

  gridRadioExtremites: {
    marginLeft: "0",
    justifyContent: "left",
    "@media (max-width: 790px) and (min-width: 570px)": {
      justifyContent: "center",
      rowGap: "0",
      marginLeft: "0",
    },
    "@media (max-width: 380px)": {
      justifyContent: "center",
      rowGap: "0",
      marginLeft: "0",
    },
  },

  gridRadioExtremitesInRow: {
    marginLeft: "0",
    justifyContent: "left",
    flexDirection: "row",
    "@media (max-width: 790px) and (min-width: 570px)": {
      justifyContent: "center",
      rowGap: "0",
      marginLeft: "0",
    },
    "@media (max-width: 380px)": {
      justifyContent: "center",
      rowGap: "0",
      marginLeft: "0",
    },
    "& img": {
      width: "100%",
      height: "100%",
    },
  },

  descriptionDropzoneEtape4: {
    paddingTop: "0.7em",
  },

  descriptionInDiv: {
    fontSize: "1em",
    color: "#787D98",
  },

  cssLastStep: {
    padding: "1em 2.2em 3em 2.2em",
    "@media (max-width: 600px)": {
      padding: "0.7em 0em 2em 0",
    },
  },

  cssTextfieldQuantiEtCode: {
    minWidth: "110px",
    margin: "10px 0",
  },

  cssPReactualisez: {
    fontSize: "1em",
    color: "#787D98",
    margin: "1em 0 1em 0",
  },

  sectionFormMarginB: {
    margin: "0 0 2em 0",
  },

  sectionFormMarginT: {
    margin: "2em 0 0 0!important",
  },

  sectionFormMarginTB: {
    margin: "2em 0",
  },

  sectionForm0Margin: {
    margin: "0",
  },

  sectionFormMarginDescr: {
    margin: "0 0 -1em 0",
  },

  sectionFormMarginRechercher: {
    backgroundColor: "#F7F8FF",
    width: "92%",
    alignSelf: "center",
    padding: "0 1em",
    marginTop: "2%",
    marginBottom: "-2%",
    alignSelf: "center",
  },

  sectionFormSansBg: {
    width: "80%",
    alignSelf: "center",
    backgroundColor: "white",
  },

  sectionFormSansBg0Margin: {
    width: "80%",
    alignSelf: "center",
    backgroundColor: "white",
    margin: "0",
  },

  noSectionForm: {
    backgroundColor: "inherit",
    width: "100%",
    alignSelf: "inherit",
    padding: "0",
  },

  radioElementExtremites: {
    width: "120px",
    display: "flex",
    flexWrap: "wrap",
    margin: "10px 1px!important",
    textAlign: "center",
  },

  cssLangue: {
    margin: "1em",
    "& .MuiOutlinedInput-root": {
      color: "#F8F9FF",
    },
    "& .MuiSvgIcon-root": {
      color: "#F8F9FF",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },

  cssSurroundGridItem: {
    position: "relative",
    borderRadius: "12px 12px 12px 12px",
    display: "flex",
    flexDirection: "column",
  },

  cssOuRechercher: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "1.5em",
    padding: "0",
    margin: "-0.3em 0",
  },

  //page de confirmation
  labelQuestionConfirmation: {
    fontSize: "2.25em",
    display: "flex",
    justifyContent: "center",
    margin: "2em 0 0 0",
    padding: "0.7em 0 0.7em 0",
    fontWeight: "600",
    fontFamily: "Dosis",
    color: "#2A375C",
  },

  messageConfirmation: {
    fontSize: "1.2em",
    lineHeight: "130%",
    width: "60%",
    display: "inline-block",
    color: "#787D98",
  },

  messageCssQuestionnaire: {
    fontSize: "1.2em",
    lineHeight: "130%",
    width: "60%",
    display: "inline-block",
    color: "#FFFFFF",
  },

  cssTextNewDemande: {
    fontSize: "1.25em",
    display: "inline-block",
    width: "100%",
    color: "#787D98",
  },

  radioColumn: {
    display: "flex",
    flexDirection: "column",
  },

  helperTextIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "30px",
      marginTop: "7px",
    },
  },

  //----------------------Dessin technique
  path: {
    stroke: "grey",
    fill: "none",
  },

  path3: {
    stroke: "#EC8812",
    fill: "none",
  },

  /* inutilisé
        symetrieTrace1: {
        stroke:'black',
        strokeWidth:0.30,
        strokeDasharray:'0.2em,0.2em,0.8em',
    }, */

  /* inutilisé
        symetrieTrace2: {
        stroke:'black',
        strokeWidth:0.30,
        strokeDasharray:'0.6em,0.2em,0.2em',
    }, */

  /*  inutilisé
        dimension: {
        fontSize : '0.5em',
    }, */

  "@media screen and (max-width:767px)": {
    svg: {
      transform: "scale(0.7)",
    },
  },
  //Fin dessin technique

  //------------------------- MEDIA QUERIES --------------------------//

  "@media (max-width: 992px)": {
    //tablette

    messageConfirmation: {
      width: "80%",
    },

    cssTextNewDemande: {
      width: "80%",
    },

    labelQuestionConfirmation: {
      margin: "2em 1em 1em 1em",
    },
  },

  "@media (max-width: 600px)": {
    //mobile
    form: {
      margin: "2em",
    },

    cssPReactualisez: {
      margin: "1em 0",
    },

    sectionFormSansBg: {
      margin: "0em 0.5em",
    },

    messageConfirmation: {
      width: "90%",
    },

    cssTextNewDemande: {
      width: "90%",
    },

    labelQuestionConfirmation: {
      margin: "1em 0.5em 0.5em 0.5em",
    },
  },

  //------------------------- ESPACE CLIENT --------------------------//
  cardContainer: {
    display: "flex",
    flexDirection: "row", // Par défaut : image à droite en mode desktop
    borderRadius: "0.5rem",
    "@media (max-width: 768px)": {
      flexDirection: "column", // En mobile, on empile les éléments
    },
  },

  whoIamCard: {
    "@media (max-width: 1366px)": {
      order: 1,
    },
  },

  publiciteCard: {
    "@media (max-width: 1366px)": {
      order: 2,
    },
  },

  commandeContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "1rem",
    width: "100%",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },

  singleCommandeCard: {
    display: "flex",
    flexDirection: "column",
    width: "calc(50% - 1rem)" /* Deux cartes par ligne par défaut */,
    borderRadius: "8px",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.25) 100%)",
    boxShadow: "0px 0px 4px 2px rgba(255, 255, 255, 0.15)",

    "@media (max-width: 768px)": {
      width:
        "100% !important" /* Chaque carte occupe toute la largeur disponible */,
      marginBottom: "1rem" /* Ajoute un espace entre les cartes */,
    },
  },

  commandeCard: {
    "@media (max-width: 1366px)": {
      order: 3,
    },
  },

  offreContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "1rem",
    width: "100%",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },

  singleOffreCard: {
    display: "flex",
    flexDirection: "column",
    width: "calc(50% - 1rem)" /* Deux cartes par ligne par défaut */,
    borderRadius: "8px",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.25) 100%)",
    boxShadow: "0px 0px 4px 2px rgba(255, 255, 255, 0.15)",

    "@media (max-width: 768px)": {
      width:
        "100% !important" /* Chaque carte occupe toute la largeur disponible */,
      marginBottom: "1rem" /* Ajoute un espace entre les cartes */,
    },
  },

  offreCard: {
    "@media (max-width: 1366px)": {
      order: 4,
    },
  },

  commercialCard: {
    "@media (max-width: 1366px)": {
      order: 5,
    },
  },

  articleCard: {
    borderRadius: "0.5rem",
    "@media (max-width: 1366px)": {
      order: 6,
      flexDirection: "column",
    },
  },

  ncCard: {
    "@media (max-width: 1366px)": {
      order: 7,
    },
  },

  equipeCard: {
    "@media (max-width: 1366px)": {
      order: 8,
    },
  },

  serviceCard: {
    "@media (max-width: 1366px)": {
      order: 9,
    },
  },

  jeConfigureCard: {
    "@media (max-width: 1366px)": {
      order: 10,
    },
  },

  singleArticleCard: {
    width: "auto", // Ensure that the width can be overridden by media query
    "@media (max-width: 768px)": {
      width: "100% !important",
      flexDirection: "column",
    },
  },

  logoMoovEC: {
    padding: "2em",
    maxHeight: "3.5em!important",
    width: "auto!important",
    cursor: "pointer",
  },

  cssBoutonRetour: {
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 4,
    border: `2px #F8F9FF solid`,
    display: "inline-flex",
    marginTop: "1.25rem",
    backgroundColor: "transparent",
    cursor: "pointer",
    alignItems: "end",
  },

  textContainer: {
    order: 1, // Contenu textuel toujours en premier
    flex: 1,
  },
  desktopImage: {
    order: 2, // Image à droite du texte sur desktop
    objectFit: "cover", // Couvre le conteneur tout en préservant les proportions
    width: "auto", // Largeur automatique pour maintenir les proportions
    display: "block", // Assurer que l'image est affichée sur les écrans plus grands
    "@media (max-width: 768px)": {
      display: "none", // Masquer l'image desktop sur mobile
    },
  },
  mobileImage: {
    display: "none",
    order: 3, // Placer l'image mobile après le contenu textuel
    width: "100%",
    objectFit: "fill",
    height: "auto",
    "@media (max-width: 768px)": {
      display: "block", // Afficher l'image mobile uniquement sur mobile
    },
  },

  mobileImageConfigure: {
    height: "100%",
    width: "100%",
    maxWidth: "225px",
    objectFit: "fill",
    "@media (max-width: 768px)": {
      display: "none", // Afficher l'image mobile uniquement sur mobile
    },
  },

  cssGridItem: {
    paddingLeft: "16px",
    "@media (max-width: 768px)": {
      padding: "0",
    },
  },
});
