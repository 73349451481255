import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminRoutes } from "../../constantes/routes/AdminRoutes";
import { CollaborateurRoutes } from "../../constantes/routes/CollaborateurRoutes";
import { StringToRouteInNavigate } from "../../helper/StringParser";
import { deconnexion } from "../model/slice/AuthentificationReducer";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { StyledHeaderCollaborateur } from "./styledComponents/StyledHeaderBackoffice";
import { StyledButtonDeconnexion } from "./styledComponents/StyledButtonBackoffice";
import {
  StyledDivDdpSelected,
  StyledDivGestionSelected,
  StyledDivHeaderCollaborateur,
  StyledDivMenuNotSelected,
  StyledDivTriangleHeader,
} from "./styledComponents/StyledDivBackoffice";
import { importAll } from "../../helper/ImportAll";
import { StyledImgHeaderCollaborateur } from "./styledComponents/StyledImgBackoffice";
import { setMessage } from "../model/slice/MessageReducer";
import { setAllInfoByRefNull } from "../model/slice/FormulaireReducer";

export const HeaderCollaborateur = ({
  role,
  setUpToDateReferenceByIdList,
  selectedPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setRecapToUndefined = () => {
    dispatch(setAllInfoByRefNull());
  };

  const handleDeconnexionClick = (formData) => {
    dispatch(deconnexion(formData))
      .unwrap()
      .catch((e) => {});
  };

  const handleProfilClick = () => {
    dispatch(setMessage(null));
    navigate(StringToRouteInNavigate(CollaborateurRoutes.PROFIL));
    setRecapToUndefined();
  };

  const handleDDPClick = () => {
    dispatch(setMessage(null));
    setUpToDateReferenceByIdList(undefined);
    setUpToDateReferenceByIdList(undefined);
    navigate(StringToRouteInNavigate(CollaborateurRoutes.DDP));
  };

  const handleCompteClick = () => {
    dispatch(setMessage(null));
    navigate(StringToRouteInNavigate(AdminRoutes.GESTION_COMPTES));
    setRecapToUndefined();
  };

  return (
    <StyledHeaderCollaborateur>
      <StyledDivHeaderCollaborateur>
        <StyledImgHeaderCollaborateur src={importAll()["LogoRC.png"]} />
        <StyledImgHeaderCollaborateur src={importAll()["LogoMoov.png"]} />
        <StyledButtonDeconnexion onClick={handleDeconnexionClick}>
          se déconnecter
        </StyledButtonDeconnexion>
      </StyledDivHeaderCollaborateur>
      <StyledDivHeaderCollaborateur>
        <Grid onClick={handleDDPClick}>
          {selectedPage === 1 ? (
            <StyledDivDdpSelected>
              Demandes de prix <StyledDivTriangleHeader />
            </StyledDivDdpSelected>
          ) : (
            <StyledDivMenuNotSelected>
              Demandes de prix
            </StyledDivMenuNotSelected>
          )}
        </Grid>
        {role === "4000" && selectedPage === 2 ? (
          <Grid onClick={handleCompteClick}>
            <StyledDivGestionSelected>
              Gestion des comptes <StyledDivTriangleHeader />
            </StyledDivGestionSelected>
          </Grid>
        ) : role === "4000" ? (
          <Grid onClick={handleCompteClick}>
            <StyledDivMenuNotSelected>
              Gestion des comptes
            </StyledDivMenuNotSelected>
          </Grid>
        ) : null}
        <Grid onClick={handleProfilClick} style={{ width: "25%" }}>
          {selectedPage === 3 ? (
            <StyledDivMenuNotSelected>
              <AccountCircleOutlinedIcon
                sx={{ color: "#EC8812", fontSize: "1.1em" }}
              />
            </StyledDivMenuNotSelected>
          ) : (
            <StyledDivMenuNotSelected>
              <AccountCircleOutlinedIcon sx={{ fontSize: "1.1em" }} />
            </StyledDivMenuNotSelected>
          )}
        </Grid>
      </StyledDivHeaderCollaborateur>
    </StyledHeaderCollaborateur>
  );
};
