import { Paper } from "@mui/material";
import { equalsCodeOrLabel } from "../../../../constantes/symbols/HelperSymbolsServices";
import {
  S08_FIXATION_GROS_ELEMENT,
  S08_FIXATION_PETIT_ELEMENT,
  S08_LONGUEUR_1ER_ELEMENT,
  S08_LONGUEUR_MAX,
  S08_LONGUEUR_MIN,
} from "../../../../constantes/symbols/SymbolsS08";
import {
  S08_STEP7_arrayFixationGros,
  S08_STEP7_arrayFixationPetit,
} from "../../../../constantes/symbols/SymbolsServicesS08";
import {
  checkFocusedArrow,
  checkFocusedEndMarker,
  checkFocusedStartMarker,
  checkFocusedText,
} from "../../../../helper/ConditionnalCssProvider";
import { useStyles } from "../../css/General";
import {
  StyledPath1,
  StyledPathCoteDotted,
  StyledPathDotted,
  StyledPathFleche,
  StyledPathRacleur,
} from "../../styledComponents/StyledPath";
import { StyledRect, StyledRect3 } from "../../styledComponents/StyledRect";

export const ProtecteurTelescopiqueProfil = ({ styles, formik }) => {
  const longueurElement1 = formik[S08_LONGUEUR_1ER_ELEMENT.description] || "";
  const longueurComprimee = formik[S08_LONGUEUR_MIN.description] || "";
  const longueurDeployee = formik[S08_LONGUEUR_MAX.description] || "";
  const fixationGrosElement =
    formik[S08_FIXATION_GROS_ELEMENT.description] || 0;
  const fixationPetitElement =
    formik[S08_FIXATION_PETIT_ELEMENT.description] || 0;

  const afficherVueProfil = () => {
    return (
      <>
        {/* partie haute */}
        {/* ligne verticale */}
        <StyledPath1 d="M 30,90 L 30,150" />
        {/* ligne horizontale */}
        <StyledPath1 d="M 30,90 L 75,90" />
        <StyledPathDotted d="M 75,90 L 120,90" className={styles.dotted} />
        <StyledPath1 d="M 120,90 L 150,90" />
        {/* ligne oblique */}
        <StyledPathRacleur d="M 150,90 L 160,100" />

        {/* partie du milieu */}
        {/* ligne verticale */}
        <StyledPath1 d="M 45,93 L 45,150" />
        {/* ligne horizontale */}
        <StyledPath1 d="M 45,100 L 75,100" />
        <StyledPathDotted d="M 75,100 L 120,100" className={styles.dotted} />
        <StyledPath1 d="M 120,100 L 170,100" />
        {/* ligne oblique */}
        <StyledPathRacleur d="M 170,100 L 180,110" />

        {/* partie du bas */}
        {/* ligne verticale */}
        <StyledPath1 d="M 60,103 L 60,150" />
        {/* ligne horizontale */}
        <StyledPath1 d="M 60,110 L 75,110" />
        <StyledPathDotted d="M 75,110 L 120,110" className={styles.dotted} />
        <StyledPath1 d="M 120,110 L 200,110" />
        

        {/* lignes délimitation flèche largeur */}
        <StyledPathCoteDotted d="M 30,58 L 30,89" />
        <StyledPathCoteDotted d="M 150,73 L 150,89" />

        {/* flèche largeur 1er élément */}
        <StyledPathFleche
          d="M 36,72 L 144,72"
          markerStart={checkFocusedStartMarker(formik, [
            S08_LONGUEUR_1ER_ELEMENT,
          ])}
          markerEnd={checkFocusedEndMarker(formik, [S08_LONGUEUR_1ER_ELEMENT])}
          className={checkFocusedArrow(
            formik,
            [S08_LONGUEUR_1ER_ELEMENT],
            styles
          )}
        />
        {longueurElement1 === "" ? (
          <text
            fontSize="0.4em"
            x="91"
            y="70"
            textAnchor="middle"
            className={checkFocusedText(
              formik,
              [S08_LONGUEUR_1ER_ELEMENT],
              styles
            )}
          >
            L Elément 1
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="87"
              y="70"
              direction="ltr"
              className={checkFocusedText(
                formik,
                [S08_LONGUEUR_1ER_ELEMENT],
                styles
              )}
            >
              {longueurElement1}
            </text>
          </>
        )}

        {/*ligne délimitation droite */}
        <StyledPathCoteDotted d="M 200,58 L 200,110" />
        {/*Fleche Largeur LD/LC*/}
        <StyledPathFleche
          d="M 36,57 L 194,57"
          markerStart={checkFocusedStartMarker(formik, [
            S08_LONGUEUR_MAX,
            S08_LONGUEUR_MIN,
          ])}
          markerEnd={checkFocusedEndMarker(formik, [
            S08_LONGUEUR_MAX,
            S08_LONGUEUR_MIN,
          ])}
          className={checkFocusedArrow(
            formik,
            [S08_LONGUEUR_MAX, S08_LONGUEUR_MIN],
            styles
          )}
        />

        <text
          fontSize="0.4em"
          x={longueurComprimee === "" ? "130" : "115"}
          y="54"
          direction="rtl"
          className={checkFocusedText(
            formik,
            [S08_LONGUEUR_MAX, S08_LONGUEUR_MIN],
            styles
          )}
        >
          LD {longueurDeployee}
        </text>

        {longueurComprimee === "" ? null : (
          <>
            <text
              fontSize="0.4em"
              x="120"
              y="54"
              textAnchor="middle"
              className={checkFocusedText(
                formik,
                [S08_LONGUEUR_MAX, S08_LONGUEUR_MIN],
                styles
              )}
            >
              /
            </text>
            <text
              fontSize="0.4em"
              x="125"
              y="54"
              direction="ltr"
              className={checkFocusedText(
                formik,
                [S08_LONGUEUR_MAX, S08_LONGUEUR_MIN],
                styles
              )}
            >
              LC {longueurComprimee}
            </text>
          </>
        )}
      </>
    );
  };

  const afficherExtremiteGrosElement = () => {
    switch (true) {
      case equalsCodeOrLabel(
        fixationGrosElement,
        S08_STEP7_arrayFixationGros(),
        0
      ):
        return (
          <>
            <StyledPath1 className={styles.extremiteColor} d="M 30,80 L 30,90" />
          </>
        );
      case equalsCodeOrLabel(
        fixationGrosElement,
        S08_STEP7_arrayFixationGros(),
        2
      ):
        return (
          <>
            <StyledPath1 className={styles.extremiteColor} d="M 5,90 L 30,90" />
          </>
        );
      default:
        return <>{afficherVueProfil()}</>;
    }
  };

  const afficherExtremitePetitElement = () => {
    switch (true) {
      case equalsCodeOrLabel(
        fixationPetitElement,
        S08_STEP7_arrayFixationPetit(),
        0
      ):
        return (
          <>
            <StyledPath1 className={styles.extremiteColor} d="M 200,100 L 200,110" />
            <StyledPath1 d="M 200,110 L 200,150" />
          </>
        );
      case equalsCodeOrLabel(
        fixationPetitElement,
        S08_STEP7_arrayFixationPetit(),
        2
      ):
        return (
          <>
            <StyledPath1 d="M 200,110 L 200,150" />
            <StyledPath1 className={styles.extremiteColor} d="M 200,110 L 225,110" />
          </>
        );
      default:
        return (
          <>
            <StyledPath1 d="M 200,110 L 200,150" />
            {afficherVueProfil()}
          </>
        );
    }
  };

  return (
    <Paper elevation={0}>
      <svg viewBox="-10 40 255 130">
        <defs>
          <marker
            id="startarrow"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 2, 10 5, 0 3.5" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 2, 10 3.5, 0 5" />
          </marker>
          <marker
            id="startarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="10 2, 10 5, 0 3.5"
              className={styles.markerSelected}
            />
          </marker>
          <marker
            id="endarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon
              points="0 2, 10 3.5, 0 5"
              className={styles.markerSelected}
            />
          </marker>
        </defs>

        <title></title>
        {/* glissière */}
        <StyledRect3 x="25" y="130" width="200" height="15" />
        {afficherVueProfil()}
        {afficherExtremiteGrosElement()}
        {afficherExtremitePetitElement()}
        {/* <image
              href={importAll()["D3.png"]}
              height="100%"
              width="100%"
              x="-14"
              y="40"
            /> */}
      </svg>
    </Paper>
  );
};
