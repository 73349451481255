import { createTheme } from "@mui/material";

export const ThemeTooltipInformationIcon = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          float: "left",
          div: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
            div: {
              width: "100%",
            },
            ".split": {
              img: {
                maxWidth: "47%",
              },
            },
            img: {
              maxWidth: "100%",
            },
            ul: {
              width: "100%",
            },
          },
        },
        tooltipPlacementBottom: {
          marginTop: "0.5em !important",
        },
      },
    },
  },
});

//Css how to make parent div size to childs size?
