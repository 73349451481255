import {
  CAP,
  QUANTITEE_SOUHAITEE_CODE_ARTICLE,
} from "../redux/vue/ChampsFormulaires/RechercherChamps";
import { ARTICLES } from "../redux/vue/ChampsFormulaires/RechercherChamps";
import {
  AGRESSIONS,
  ENVIRONNEMENT,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  QUANTITEE_SOUHAITEE,
  TEMPERATURE,
} from "../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../constantes/symbols/SymbolsServicesCommon";
import {
  S04_STEP4_arrayFormeCorpsD,
  S04_STEP4_arrayFormeCorpsE,
  S04_STEP4_arrayFormeCorpsF,
  S04_STEP4_arrayFormeCorpsSymA,
  S04_STEP4_arrayFormeCorpsSymD,
  S04_STEP4_arrayFormeCorpsSymE,
  S04_STEP4_arrayFormeCorpsSymF,
  S04_STEP4_arrayTypeCorps,
  S04_STEP6_arrayFixationExtremites,
  S04_STEP7_arrayAccessories,
  S04_STEP7_arrayEcaillesA2S,
  S04_STEP7_arrayEcaillesA3S,
  S04_STEP7_arrayEcaillesB1S,
  S04_STEP7_arrayEcaillesC1S,
  S04_STEP7_arrayEcaillesD1,
  S04_STEP7_arrayEcaillesD1S,
  S04_STEP7_arrayEcaillesD2,
  S04_STEP7_arrayEcaillesD3,
  S04_STEP7_arrayEcaillesD3S,
  S04_STEP7_arrayEcaillesE1,
  S04_STEP7_arrayEcaillesE1S,
  S04_STEP7_arrayEcaillesE2,
  S04_STEP7_arrayEcaillesE2S,
  S04_STEP7_arrayEcaillesF1,
  S04_STEP7_arrayEcaillesF1S,
  S04_STEP7_arrayEcaillesF2,
  S04_STEP7_arrayEcaillesF2S,
} from "../constantes/symbols/SymbolsServicesS04";
import {
  FORME_CORPS_A,
  FORME_CORPS_B,
  FORME_CORPS_C,
  FORME_CORPS_D,
  FORME_CORPS_DS,
  FORME_CORPS_E,
  FORME_CORPS_ES,
  FORME_CORPS_F,
  FORME_CORPS_FS,
  JOINDRE_PLAN_EXTREMITES_IT1,
  JOINDRE_PLAN_EXTREMITES_IT2,
  LONGUEUR_A,
  LONGUEUR_B,
  LONGUEUR_C,
  LONGUEUR_D,
  LONGUEUR_E,
  LONGUEUR_F,
  LONGUEUR_G,
  LONGUEUR_H,
  LONGUEUR_X,
  S04_ACCESSOIRES,
  S04_ECAILLES,
  S04_EXTREMITE_IDENTIQUE,
  S04_FIXATION_EXTREMITES_IT1,
  S04_FIXATION_EXTREMITES_IT2,
  SECTION_SYMETRIQUE,
  TYPE_SECTION,
} from "../constantes/symbols/SymbolsS04";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../constantes/symbols/HelperSymbolsServices";

export const dataCleanerS04 = (payload, t) => {
  //ETAPE3
  payload = removeForEnvAutre(payload);
  payload = removeTemperature(payload);

  //ETAPE4
  payload = removeFormeCorps(payload);
  payload = removeLongueur(payload);
  
  //Etape6_S04
  payload = removeExtremiteIt2S04(payload);
  
  //ETAPE 7
  payload = removeQteToDontKeep(payload);
  payload = removeEmptyQte(payload);
  payload = removeEcaillesNotNeeded(payload, t);
  return payload;
};

const findFormeCorpsFromTypeSection = (payload) => {
  const symetrique = equalsBooleanOrStringOrNumber(
    payload[SECTION_SYMETRIQUE.description]
  );
  switch (true) {
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      0
    ):
      return payload[FORME_CORPS_A.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      1
    ):
      return payload[FORME_CORPS_B.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      2
    ):
      return payload[FORME_CORPS_C.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      3
    ):
      return !!symetrique
        ? payload[FORME_CORPS_DS.description]
        : payload[FORME_CORPS_D.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      4
    ):
      return !!symetrique
        ? payload[FORME_CORPS_ES.description]
        : payload[FORME_CORPS_E.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      5
    ):
      return !!symetrique
        ? payload[FORME_CORPS_FS.description]
        : payload[FORME_CORPS_F.description];
    default:
      return payload[FORME_CORPS_A.description];
  }
};

/**
 * Il prend un tableau et un objet, et renvoie un nouvel objet avec les mêmes clés que l'objet
 * d'origine, mais avec les valeurs des clés qui sont dans le tableau supprimées.
 *
 * Voici un exemple de la façon dont cela fonctionne :
 *
 * charge utile const = {
 *   nom : "Jean",
 *   âge : 30 ans,
 *   genre masculin",
 *   adresse : "123 Main St",
 *   ville : "New York",
 *   indiquer : "NY",
 *   code postal : "10001",
 * } ;
 *
 * tableau const = ["adresse", "ville", "état", "zip"] ;
 *
 * const newPayload = retireElements(tableau, charge utile);
 *
 * console.log(newPayload);
 * // { nom : "Jean", âge : 30 ans, sexe : "homme" }
 *
 * @param array - ['nom Age']
 * @param payload - {
 * @returns Un objet avec les éléments de la charge utile qui ne sont pas dans le tableau.
 */
const withdrawElements = (array, payload) => {
  return Object.fromEntries(
    Object.entries(payload)
      .map((element) => {
        if (array.includes(element[0])) {
          return null;
        }
        return element;
      })
      .filter((element) => element)
  );
};

const removeFormeCorps = (payload) => {
  const typeSection = payload[TYPE_SECTION.description];
  const symetrique = equalsBooleanOrStringOrNumber(
    payload[SECTION_SYMETRIQUE.description]
  );
  switch (true) {
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 0):
      return withdrawElements(
        [
          FORME_CORPS_B.description,
          FORME_CORPS_C.description,
          FORME_CORPS_DS.description,
          FORME_CORPS_D.description,
          FORME_CORPS_ES.description,
          FORME_CORPS_E.description,
          FORME_CORPS_FS.description,
          FORME_CORPS_F.description,
        ],
        payload
      );
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 1):
      return withdrawElements(
        [
          FORME_CORPS_A.description,
          FORME_CORPS_C.description,
          FORME_CORPS_DS.description,
          FORME_CORPS_D.description,
          FORME_CORPS_ES.description,
          FORME_CORPS_E.description,
          FORME_CORPS_FS.description,
          FORME_CORPS_F.description,
        ],
        payload
      );
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 2):
      return withdrawElements(
        [
          FORME_CORPS_A.description,
          FORME_CORPS_B.description,
          FORME_CORPS_DS.description,
          FORME_CORPS_D.description,
          FORME_CORPS_ES.description,
          FORME_CORPS_E.description,
          FORME_CORPS_FS.description,
          FORME_CORPS_F.description,
        ],
        payload
      );
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 3):
      if (!!symetrique) {
        return withdrawElements(
          [
            FORME_CORPS_A.description,
            FORME_CORPS_B.description,
            FORME_CORPS_C.description,
            FORME_CORPS_D.description,
            FORME_CORPS_ES.description,
            FORME_CORPS_E.description,
            FORME_CORPS_FS.description,
            FORME_CORPS_F.description,
          ],
          payload
        );
      } else {
        return withdrawElements(
          [
            FORME_CORPS_A.description,
            FORME_CORPS_B.description,
            FORME_CORPS_C.description,
            FORME_CORPS_DS.description,
            FORME_CORPS_ES.description,
            FORME_CORPS_E.description,
            FORME_CORPS_FS.description,
            FORME_CORPS_F.description,
          ],
          payload
        );
      }
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 4):
      if (!!symetrique) {
        return withdrawElements(
          [
            FORME_CORPS_A.description,
            FORME_CORPS_B.description,
            FORME_CORPS_C.description,
            FORME_CORPS_DS.description,
            FORME_CORPS_D.description,
            FORME_CORPS_E.description,
            FORME_CORPS_FS.description,
            FORME_CORPS_F.description,
          ],
          payload
        );
      } else {
        return withdrawElements(
          [
            FORME_CORPS_A.description,
            FORME_CORPS_B.description,
            FORME_CORPS_C.description,
            FORME_CORPS_DS.description,
            FORME_CORPS_D.description,
            FORME_CORPS_ES.description,
            FORME_CORPS_FS.description,
            FORME_CORPS_F.description,
          ],
          payload
        );
      }
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 5):
      if (!!symetrique) {
        return withdrawElements(
          [
            FORME_CORPS_A.description,
            FORME_CORPS_B.description,
            FORME_CORPS_C.description,
            FORME_CORPS_DS.description,
            FORME_CORPS_D.description,
            FORME_CORPS_ES.description,
            FORME_CORPS_E.description,
            FORME_CORPS_F.description,
          ],
          payload
        );
      } else {
        return withdrawElements(
          [
            FORME_CORPS_A.description,
            FORME_CORPS_B.description,
            FORME_CORPS_C.description,
            FORME_CORPS_DS.description,
            FORME_CORPS_D.description,
            FORME_CORPS_ES.description,
            FORME_CORPS_E.description,
            FORME_CORPS_FS.description,
          ],
          payload
        );
      }

    default:
      return withdrawElements(
        [
          FORME_CORPS_A.description,
          FORME_CORPS_B.description,
          FORME_CORPS_C.description,
          FORME_CORPS_DS.description,
          FORME_CORPS_D.description,
          FORME_CORPS_ES.description,
          FORME_CORPS_E.description,
          FORME_CORPS_FS.description,
          FORME_CORPS_F.description,
        ],
        payload
      );
  }
};

const removeLongueur = (payload) => {
  const typeSection = payload[TYPE_SECTION.description];
  const symetrique = equalsBooleanOrStringOrNumber(
    payload[SECTION_SYMETRIQUE.description]
  );
  const formeCorps = findFormeCorpsFromTypeSection(payload);
  if (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 0)) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] === LONGUEUR_B.description ||
            element[0] === LONGUEUR_C.description ||
            element[0] === LONGUEUR_D.description ||
            element[0] === LONGUEUR_E.description ||
            element[0] === LONGUEUR_F.description ||
            element[0] === LONGUEUR_G.description ||
            element[0] === LONGUEUR_H.description
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }

  if (
    equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 1) ||
    equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 2) ||
    (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 3) &&
      !!symetrique &&
      equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymD(), 0))
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] === LONGUEUR_C.description ||
            element[0] === LONGUEUR_D.description ||
            element[0] === LONGUEUR_E.description ||
            element[0] === LONGUEUR_F.description ||
            element[0] === LONGUEUR_G.description ||
            element[0] === LONGUEUR_H.description
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }

  if (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 3)) {
    if (
      (equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsD(), 0) &&
        !symetrique) ||
      (equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymD(), 1) &&
        !!symetrique)
    ) {
      payload = Object.fromEntries(
        Object.entries(payload)
          .map((element) => {
            if (
              element[0] === LONGUEUR_D.description ||
              element[0] === LONGUEUR_E.description ||
              element[0] === LONGUEUR_F.description ||
              element[0] === LONGUEUR_G.description ||
              element[0] === LONGUEUR_H.description
            ) {
              return null;
            }
            return element;
          })
          .filter((element) => element)
      );
    }
    if (
      equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsD(), 1) &&
      !symetrique
    ) {
      payload = Object.fromEntries(
        Object.entries(payload)
          .map((element) => {
            if (
              element[0] === LONGUEUR_E.description ||
              element[0] === LONGUEUR_F.description ||
              element[0] === LONGUEUR_G.description ||
              element[0] === LONGUEUR_H.description
            ) {
              return null;
            }
            return element;
          })
          .filter((element) => element)
      );
    }
    if (
      equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsD(), 2) &&
      !symetrique
    ) {
      payload = Object.fromEntries(
        Object.entries(payload)
          .map((element) => {
            if (
              element[0] === LONGUEUR_F.description ||
              element[0] === LONGUEUR_G.description ||
              element[0] === LONGUEUR_H.description
            ) {
              return null;
            }
            return element;
          })
          .filter((element) => element)
      );
    }
  }
  if (
    (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 4) &&
      !symetrique &&
      equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsE(), 0)) ||
    (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 5) &&
      !!symetrique &&
      equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymF(), 1))
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] === LONGUEUR_E.description ||
            element[0] === LONGUEUR_F.description ||
            element[0] === LONGUEUR_G.description ||
            element[0] === LONGUEUR_H.description
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  if (
    (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 4) &&
      !symetrique &&
      equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsE(), 1)) ||
    (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 5) &&
      !symetrique &&
      equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsF(), 0))
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] === LONGUEUR_G.description ||
            element[0] === LONGUEUR_H.description
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  if (
    (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 4) &&
      !!symetrique &&
      equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymE(), 0)) ||
    (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 5) &&
      !!symetrique &&
      equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymF(), 0))
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] === LONGUEUR_D.description ||
            element[0] === LONGUEUR_E.description ||
            element[0] === LONGUEUR_F.description ||
            element[0] === LONGUEUR_G.description ||
            element[0] === LONGUEUR_H.description
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }

  if (
    equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 4) &&
    !!symetrique &&
    equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymE(), 1)
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] === LONGUEUR_F.description ||
            element[0] === LONGUEUR_G.description ||
            element[0] === LONGUEUR_H.description
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  if (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 6)) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] === LONGUEUR_A.description ||
            element[0] === LONGUEUR_B.description ||
            element[0] === LONGUEUR_C.description ||
            element[0] === LONGUEUR_D.description ||
            element[0] === LONGUEUR_E.description ||
            element[0] === LONGUEUR_F.description ||
            element[0] === LONGUEUR_G.description ||
            element[0] === LONGUEUR_H.description ||
            element[0] === LONGUEUR_X.description
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeExtremiteIt2S04 = (payload) => {
  if (!!payload[S04_EXTREMITE_IDENTIQUE.description] === true) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] === S04_FIXATION_EXTREMITES_IT2.description) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeTemperature = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[FAMILLE_PROTECTION_INDUSTRIELLE.description],
      arrayProductFamilly(),
      3
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] === TEMPERATURE.description) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeForEnvAutre = (payload) => {
  if (isNaN(parseInt(payload[ENVIRONNEMENT.description]))) {
    return payload;
  }
  if (parseInt(payload[ENVIRONNEMENT.description]) !== 3) {
    payload = Object.fromEntries(
      Object.entries(payload).map((element) => {
        if (element[0] === AGRESSIONS.description) {
          return [[AGRESSIONS.description], []];
        }
        return element;
      })
    );
  }
  return payload;
};

const removeQteToDontKeep = (payload) => {
  if (payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.slice(
          0,
          payload?.nbFieldToKeep
        ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};

const removeUnwantedEcailles = (payload, haystack) => {
  return Object.fromEntries(
    Object.entries(payload).map((element) => {
      if (element[0] === S04_ECAILLES.description) {
        
        const transformedValue = Array.isArray(element[1])
          ? element[1]
          : element[1].split(/(?!\B['\'])/); // Sépare chaque caractère, y compris l'apostrophe

        return [
          [S04_ECAILLES.description],
          transformedValue.filter((el) =>
            haystack.some((e) => e.code === el || e.name === el)
          ),
        ];
      }
      return element;
    })
  );
};


const removeEcaillesNotNeeded = (payload, t) => {
  const typeSection = payload[TYPE_SECTION.description];
  const sectionSymetrique = equalsBooleanOrStringOrNumber(
    payload[SECTION_SYMETRIQUE.description]
  );
  const formeCorps = findFormeCorpsFromTypeSection(payload);
  const isEcailles = payload[S04_ACCESSOIRES.description];

  //Si il ne doit pas y avoir d'ecailles
  if (equalsCodeOrLabel(isEcailles, S04_STEP7_arrayAccessories(t), 1)) {
    return Object.fromEntries(
      Object.entries(payload).map((element) => {
        if (element[0] === S04_ECAILLES.description) {
          return [[S04_ECAILLES.description], []];
        }
        return element;
      })
    );
  }

  switch (true) {
    //A
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 0):
      switch (true) {
        //A2S
        case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymA(), 1):
          return removeUnwantedEcailles(payload, S04_STEP7_arrayEcaillesA2S());
        //A3S
        case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymA(), 2):
          return removeUnwantedEcailles(payload, S04_STEP7_arrayEcaillesA3S());
        default:
          return payload;
      }
    //B
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 1):
      return removeUnwantedEcailles(payload, S04_STEP7_arrayEcaillesB1S());
    //C
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 2):
      return removeUnwantedEcailles(payload, S04_STEP7_arrayEcaillesC1S());
    //D
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 3):
      switch (true) {
        case !!sectionSymetrique:
          switch (true) {
            //si symétrique ET option D1S selectionnée
            case equalsCodeOrLabel(
              formeCorps,
              S04_STEP4_arrayFormeCorpsSymD(),
              0
            ):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesD1S()
              );
            //si symétrique ET option D3S selectionnée
            case equalsCodeOrLabel(
              formeCorps,
              S04_STEP4_arrayFormeCorpsSymD(),
              1
            ):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesD3S()
              );
            default:
              return payload;
          }
        case !sectionSymetrique:
          switch (true) {
            //si non symétrique ET option D1 selectionnée
            case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsD(), 0):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesD1()
              );
            //si non symétrique ET option D2 selectionnée
            case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsD(), 1):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesD2()
              );
            //si non symétrique ET option D3 selectionnée
            case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsD(), 2):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesD3()
              );
            default:
              return payload;
          }
        default:
          return payload;
      }
    //E
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 4):
      switch (true) {
        case !!sectionSymetrique:
          switch (true) {
            //si symétrique ET option E1S selectionnée
            case equalsCodeOrLabel(
              formeCorps,
              S04_STEP4_arrayFormeCorpsSymE(),
              0
            ):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesE1S()
              );
            //si symétrique ET option E2S selectionnée
            case equalsCodeOrLabel(
              formeCorps,
              S04_STEP4_arrayFormeCorpsSymE(),
              1
            ):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesE2S()
              );
            default:
              return payload;
          }
        case !sectionSymetrique:
          switch (true) {
            //si non symétrique ET option E1 selectionnée
            case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsE(), 0):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesE1()
              );
            //si non symétrique ET option E2 selectionnée
            case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsE(), 1):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesE2()
              );
            default:
              return payload;
          }
        default:
          return payload;
      }
    //F
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 5):
      switch (true) {
        case !!sectionSymetrique:
          switch (true) {
            //si symétrique ET option F1S selectionnée
            case equalsCodeOrLabel(
              formeCorps,
              S04_STEP4_arrayFormeCorpsSymF(),
              0
            ):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesF1S()
              );
            //si symétrique ET option F2S selectionnée
            case equalsCodeOrLabel(
              formeCorps,
              S04_STEP4_arrayFormeCorpsSymF(),
              1
            ):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesF2S()
              );
            default:
              return payload;
          }
        case !sectionSymetrique:
          switch (true) {
            //si non symétrique ET option F1 selectionnée
            case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsF(), 0):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesF1()
              );
            //si non symétrique ET option F2 selectionnée
            case equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsF(), 1):
              return removeUnwantedEcailles(
                payload,
                S04_STEP7_arrayEcaillesF2()
              );
            default:
              return payload;
          }
        default:
          return payload;
      }
    case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 6):
      return payload;

    default:
      return payload;
  }
};

const removeEmptyQte = (payload) => {
  if (payload?.champQuantite !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.filter(Number),
      },
      {}
    );
  }
  return payload;
};

export const removeCodeAndQteToDontKeepOrEmpty = (payload) => {
  if (payload?.articles !== undefined && payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [ARTICLES.description]: payload.articles
          .slice(0, payload.nbFieldToKeep)
          .filter(
            (value) =>
              value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description] !== "" &&
              value[CAP.description] !== "" &&
              !isNaN(value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description])
          ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};
