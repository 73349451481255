import axios from "axios";
import { Serveur } from "../../constantes/services/Serveur";
import { EspaceClient } from "../../constantes/services/EspaceClient";

const getAllArticles = async (data) => {
  const GET_ALL_ARTICLES_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_ARTICLES;
  return await axios.post(GET_ALL_ARTICLES_ENDPOINT, data);
};

const getOneArticleEC = async (data) => {
  const GET_ALL_ARTICLES_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_ARTICLES;
  return await axios.post(GET_ALL_ARTICLES_ENDPOINT, data);
};

const getAllCommandes = async (data) => {
  const GET_ALL_COMMANDES_ENDPOINT =
    Serveur.URL + EspaceClient.GET_ALL_COMMANDES;
  return await axios.post(GET_ALL_COMMANDES_ENDPOINT, data);
};

const getAllCommandesByArticleId = async (data) => {
  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_COMMANDES;
  return await axios.post(GET_ALL_OFFRES_ENDPOINT, data);
};

const getTypesCommande = async (data) => {
  const GET_TYPES_COMMANDE_ENDPOINT =
    Serveur.URL + EspaceClient.GET_TYPES_COMMANDE;
  return await axios.post(GET_TYPES_COMMANDE_ENDPOINT, data);
};

const getOneCommande = async (data) => {
  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_COMMANDES;
  return await axios.post(GET_ALL_OFFRES_ENDPOINT, data);
};

const getAllOffres = async (data) => {
  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_OFFRES;
  return await axios.post(GET_ALL_OFFRES_ENDPOINT, data);
};

const getAllOffresByArticleId = async (data) => {
  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_OFFRES;
  return await axios.post(GET_ALL_OFFRES_ENDPOINT, data);
};

const getOneOffreId = async (data) => {
  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_OFFRES;
  return await axios.post(GET_ALL_OFFRES_ENDPOINT, data);
};

const getAllContacts = async (data) => {
  const GET_ALL_CLIENTS_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_CLIENTS;
  return await axios.post(GET_ALL_CLIENTS_ENDPOINT, data);
};

const getDetailsClient = async (data) => {
  const GET_DETAILS_CLIENT_ENDPOINT =
    Serveur.URL + EspaceClient.GET_DETAILS_CLIENT;
  const reponse = await axios.post(GET_DETAILS_CLIENT_ENDPOINT, data);

  if (reponse.data.accessTokenEC) {
    localStorage.setItem("accessTokenEC", reponse.data.accessTokenEC);
  } else {
    localStorage.removeItem("accessTokenEC");
  }

  return reponse;
};

const getOneContact = async (data) => {
  const GET_DETAILS_CONTACT_ENDPOINT =
    Serveur.URL + EspaceClient.GET_DETAILS_CLIENT;
  return await axios.post(GET_DETAILS_CONTACT_ENDPOINT, data);
};

const getAllNC = async (data) => {
  const GET_ALL_NCL_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_NCL;
  return await axios.post(GET_ALL_NCL_ENDPOINT, data);
};

const getOneNC = async (data) => {
  const GET_ALL_NCL_ENDPOINT = Serveur.URL + EspaceClient.GET_ALL_NCL;
  return await axios.post(GET_ALL_NCL_ENDPOINT, data);
};

const getEtatNC = async (data) => {
  const GET_ETAT_NC_ENDPOINT = Serveur.URL + EspaceClient.GET_ETAT_NC;
  return await axios.post(GET_ETAT_NC_ENDPOINT, data);
};

const getAllFamilleArticle = async (data) => {
  const GET_ALL_FAMILLE_ARTICLE_ENDPOINT =
    Serveur.URL + EspaceClient.GET_ALL_FAMILLE_ARTICLE;
  return await axios.post(GET_ALL_FAMILLE_ARTICLE_ENDPOINT, data);
};

const sendMailProgrammerVisite = async (data) => {
  const SEND_MAIL_PROGRAMMER_VISITE_ENDPOINT =
    Serveur.URL + EspaceClient.SEND_MAIL_EC;
  return await axios.post(SEND_MAIL_PROGRAMMER_VISITE_ENDPOINT, data);
};

const sendMailEtreRappeler = async (data) => {
  const SEND_MAIL_ETRE_RAPPELER_ENDPOINT =
    Serveur.URL + EspaceClient.SEND_MAIL_EC;
  return await axios.post(SEND_MAIL_ETRE_RAPPELER_ENDPOINT, data);
};

const sendMailModifContact = async (data) => {
  const SEND_MAIL_MODIF_CONTACT_ENDPOINT =
    Serveur.URL + EspaceClient.SEND_MAIL_EC;
  return await axios.post(SEND_MAIL_MODIF_CONTACT_ENDPOINT, data);
};

const sendStatsToFPS = async () => {
  const SEND_STATS_TO_FPS_ENDPOINT =
    Serveur.URL + EspaceClient.SEND_STATS_TO_FPS;
  return await axios.post(SEND_STATS_TO_FPS_ENDPOINT);
};

const espaceClientService = {
  getAllArticles,
  getOneArticleEC,
  getAllCommandes,
  getTypesCommande,
  getAllOffres,
  getDetailsClient,
  getAllOffresByArticleId,
  getOneOffreId,
  getAllCommandesByArticleId,
  getOneCommande,
  getAllNC,
  getEtatNC,
  getOneNC,
  getAllContacts,
  getOneContact,
  sendMailProgrammerVisite,
  sendMailEtreRappeler,
  sendMailModifContact,
  getAllFamilleArticle,
  sendStatsToFPS,
};

export default espaceClientService;
