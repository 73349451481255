import * as Yup from "yup";
import {
  S08_LONGUEUR_1ER_ELEMENT,
  S08_LONGUEUR_COURSE,
  S08_LONGUEUR_MAX,
  S08_LONGUEUR_MIN,
  S08_NOMBRE_ELEMENTS,
} from "../../../../../constantes/symbols/SymbolsS08";

const otherwiseSchema = Yup.mixed().optional();

const schemaNotZero = (error) => {
  return Yup.number().min(1, error);
};

const yupPlusPetit = (champ, erreur, errorInfZero) => {
  return Yup.number().when(champ.toString(), {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .lessThan(Yup.ref(champ.toString()), erreur),
  });
};

const yupPlusGrand = (champ, erreur, errorInfZero) => {
  return Yup.number().when(champ.toString(), {
    is: undefined || "",
    then: schemaNotZero(errorInfZero),
    otherwise: Yup.number()
      .min(1, errorInfZero)
      .moreThan(Yup.ref(champ.toString()), erreur),
  });
};

/* A Yup validation schema. */
export const validDimensionsS08 = (t, etape) => {
  if (etape !== 6) {
    return Yup.object().shape({});
  }
  return Yup.object({
    [S08_LONGUEUR_MAX.description]: Yup.lazy(() =>
      yupPlusGrand(
        [S08_LONGUEUR_MIN.description],
        t.erreurLongueurComprimeeGrand,
        t.superieurA0
      ).required(t.champRequis)
    ),

    [S08_LONGUEUR_MIN.description]: Yup.lazy(() =>
      yupPlusPetit(
        [S08_LONGUEUR_MAX.description],
        t.erreurLongueurComprimeePetit,
        t.superieurA0
      ).required(t.champRequis)
    ),

    [S08_LONGUEUR_COURSE.description]: Yup.number()
      .positive()
      .min(1, t.superieurA0),

    [S08_NOMBRE_ELEMENTS.description]: Yup.number()
      .positive()
      .min(1, t.superieurA0)
      .required(t.renseignerChamp),

    [S08_LONGUEUR_1ER_ELEMENT.description]: Yup.number()
      .positive()
      .min(1, t.superieurA0)
      .required(t.renseignerChamp),
  });
};
