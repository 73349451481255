import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  textfield: {
    width: "100%",
    marginBottom: "3em",
  },

  textfieldLabelProps: {
    fontFamily: "Cabin",
    fontSize: "1em",
    color: "#575C82",
    "&.Mui-focused": {
      color: "#575C82",
    },
  },

  textfieldProps: {
    fontFamily: "Cabin",
    fontSize: "1.5em",
    color: "#2A375C",
    //fontFeatureSettings: 'ss01',
  },

  iconConnexion: {
    color: "#575C82",
    "&.MuiSvgIcon-root": {
      fontSize: "30px",
    },
  },

});
