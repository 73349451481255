import { Button } from "@mui/material";
import { ChevronDown, ChevronUp } from "./Chevron";
import { useState } from "react";
import { WpfSurvey } from "./SurveyIcon";

export const QuestionnaireComponent = ({ styles }) => {
  const [collapseOpenQuestionnaire, setCollapseOpenQuestionnaire] =
    useState(false);

  const toggleCollapse = () => {
    setCollapseOpenQuestionnaire(!collapseOpenQuestionnaire);
  };

  const handleCallbackQuestionnaire = () => {
    const locationQuestionnaire = "https://docs.google.com/forms/d/1UjOupcj17caO7ukT_yWN8PJohRaPatMttVBlE405Io8/";
    window.open(locationQuestionnaire, "_blank");
  };

  const buttonStyle = {
    padding: collapseOpenQuestionnaire ? "1em 1em 0 1em" : "1em",
  };

  return (
    <>
      <Button
        id="collapseButton"
        onMouseEnter={() => setCollapseOpenQuestionnaire(true)}
        onMouseLeave={() => setCollapseOpenQuestionnaire(false)}
        onClick={toggleCollapse}
        variant="contained"
        style={buttonStyle}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <WpfSurvey styles={styles} />
            <span>Votre avis compte !</span>
          </div>
          {collapseOpenQuestionnaire ? (
            <ChevronDown styles={styles} />
          ) : (
            <ChevronUp styles={styles} />
          )}
        </div>
        {collapseOpenQuestionnaire && (
          <div id="collapseContent">
            <p>
              Répondez à notre questionnaire en ligne pour nous aider à
              améliorer le configurateur.
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation(); // Empêche la propagation de l'événement au collapseButton
                handleCallbackQuestionnaire(); // Exécute le callback pour "Je souhaite être rappelé"
              }}
              className={styles.cssButton}
              style={{ width: buttonStyle.width }} // Utilisation de la largeur du bouton principal
            >
              Je participe
            </Button>
          </div>
        )}
      </Button>
    </>
  );
};
