import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledStack = styled(Stack)({
    color: '#2A375C',
    '& .MuiTypography-root':{
        fontFamily: 'Dosis',
        fontWeight: '500',
        fontSize: '1.125em',
    }
});