import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../../helper/ErrorHandlerMessageProvider";
import { FonctionsActionType } from "../../../constantes/actionType/FonctionsActionType";
import fonctionsService from "../../service/FonctionsService";
import { langJsonFormatter } from "../../../helper/ServiceCleaner";

export const getAllFonctions = createAsyncThunk(
  FonctionsActionType.GET_ALL_FONCTIONS,
  async (lang, thunkAPI) => {
    try {
      const reponse = await fonctionsService.getAllFonctions();
      return langJsonFormatter(lang, thunkAPI, reponse);
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {};

const fonctionsSlice = createSlice({
  name: FonctionsActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllFonctions.fulfilled, (state, action) => {
        state.fonctionsList = action.payload;
      })
      .addCase(getAllFonctions.rejected, (state, action) => {
        state.fonctionsList = null;
      });
  },
});

const { reducer } = fonctionsSlice;
export default reducer;
