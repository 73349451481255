import { TextField } from "@mui/material";
import { styled } from "@mui/system";

export const StyledTextfield = styled(TextField)({
  width: "100%",
  margin: "1em 0",
  "& label": {
    color: "#2A375C",
    fontSize: "1em",
    fontWeight: "600",
  },
  "& label.Mui-focused": {
    color: "#2A375C",
    fontSize: "1.2em",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    "& fieldset": {
      borderColor: "#787D98",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    fontSize: "1.13em",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: "1.13em",
  },
});
