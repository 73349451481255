import * as Yup from "yup";
import {
  S08_FIXATION_GROS_ELEMENT,
  S08_FIXATION_PETIT_ELEMENT,
} from "../../../../../constantes/symbols/SymbolsS08";
import {
  S08_STEP7_arrayFixationGros,
  S08_STEP7_arrayFixationPetit,
} from "../../../../../constantes/symbols/SymbolsServicesS08";

/* A Yup validation schema. */
export const validExtremitesS08 = (t, etape) => {
  if (etape !== 7) {
    return Yup.object().shape({});
  }
  return Yup.object({
    [S08_FIXATION_GROS_ELEMENT.description]: Yup.mixed()
      .test(
        "TypeSelectionInSelect",
        () => {
          return t.erreurSelectionType;
        },
        (value) =>
          S08_STEP7_arrayFixationGros(t).some((e) => e.code === parseInt(value))
      )
      .required(t.erreurSelectionType),

    [S08_FIXATION_PETIT_ELEMENT.description]: Yup.mixed()
      .test(
        "TypeSelectionInSelect",
        () => {
          return t.erreurSelectionType;
        },
        (value) =>
          S08_STEP7_arrayFixationPetit(t).some(
            (e) => e.code === parseInt(value)
          )
      )
      .required(t.erreurSelectionType),
  });
};
