export const GuestRoutes = Object.freeze({
    ACCUEIL: '/',
    AUTHENTIFICATION: 'Authentification',
    CONFIGURATEUR: 'Configurateur',
    NOUS_CONTACTER: 'Contact',
    RECHERCHER: 'Rechercher',
    POLITIQUE_DE_CONFIDENTIALITE: 'PolitiqueConfidentialite',
    MENTIONS_LEGALES: 'MentionsLegales',
    DESSIN_TECHNIQUE: 'DessinTechnique',
    VUE_ARTICLE: 'VueArticle',
    ODP:'OffreDePrixFPS',
    SOS: 'ContactSOS',
})