import { useTranslations } from "./components/hook/useTranslations";
import { StyledPaperPolitiques } from "./styledComponents/StyledPaper";

export const MentionsLegales = () => {
  const { t } = useTranslations();
  return (
    <StyledPaperPolitiques elevation={0} dangerouslySetInnerHTML={{ __html: t.messageMentionsLegalesP1 + t.messageMentionsLegalesP2 }}>
      
    </StyledPaperPolitiques>
  );
};
