//Etape 4
const TYPE_SECTION = Symbol("SQ0037_Q00040");
const FORME_CORPS_A = Symbol("SQ0037_Q00350");
const FORME_CORPS_B = Symbol("SQ0037_Q00360");
const FORME_CORPS_C = Symbol("SQ0037_Q00370");
const FORME_CORPS_DS = Symbol("SQ0037_Q00290");
const FORME_CORPS_D = Symbol("SQ0037_Q00300");
const FORME_CORPS_ES = Symbol("SQ0037_Q00310");
const FORME_CORPS_E = Symbol("SQ0037_Q00320");
const FORME_CORPS_FS = Symbol("SQ0037_Q00330");
const FORME_CORPS_F = Symbol("SQ0037_Q00340");
const LONGUEUR_A = Symbol("SQ0037_Q00050");
const LONGUEUR_B = Symbol("SQ0037_Q00060");
const LONGUEUR_C = Symbol("SQ0037_Q00070");
const LONGUEUR_E = Symbol("SQ0037_Q00090");
const LONGUEUR_F = Symbol("SQ0037_Q00100");
const LONGUEUR_D = Symbol("SQ0037_Q00080");
const LONGUEUR_G = Symbol("SQ0037_Q00110");
const LONGUEUR_H = Symbol("SQ0037_Q00380");
const LONGUEUR_X = Symbol("SQ0037_Q00270");
const SECTION_SYMETRIQUE = Symbol("SQ0037_Q00280");
const JOINDRE_PLAN_FORME_QUELCONQUE = Symbol("formeQuelconqueJoindrePlan");
const TOILE = Symbol("SQ0037_Q00010");

//Etape 5
const S04_LONGUEUR_COMPRIMEE = Symbol("SQ0038_Q00020");
const S04_LONGUEUR_DEPLOYEE = Symbol("SQ0038_Q00010");

//Etape 6
//!!!!!Comme pour le S01, à la demande du client, les extremites sont inversées!!!!!
//!!!!!Les textes sont juste et le nom des variable est faux!!!!!
const S04_EXTREMITE_IDENTIQUE = Symbol("SQ0039_Q00020");
const S04_FIXATION_EXTREMITES_IT1 = Symbol("SQ0039_Q00050$2");
const S04_FIXATION_EXTREMITES_IT2 = Symbol("SQ0039_Q00050$1");
const JOINDRE_PLAN_EXTREMITES_IT1 = Symbol("joindrePlanExtremites$2");
const JOINDRE_PLAN_EXTREMITES_IT2 = Symbol("joindrePlanExtremites$1");

//Etape 7
const S04_ACCESSOIRES = Symbol("SQ0040_Q00150");
const S04_ECAILLES = Symbol("SQ0040_Q00160");

export {
  //Etape4
  TYPE_SECTION,
  FORME_CORPS_A,
  FORME_CORPS_B,
  FORME_CORPS_C,
  FORME_CORPS_DS,
  FORME_CORPS_D,
  FORME_CORPS_ES,
  FORME_CORPS_E,
  FORME_CORPS_FS,
  FORME_CORPS_F,
  LONGUEUR_A,
  LONGUEUR_B,
  LONGUEUR_C,
  LONGUEUR_E,
  LONGUEUR_F,
  LONGUEUR_D,
  LONGUEUR_G,
  LONGUEUR_H,
  LONGUEUR_X,
  SECTION_SYMETRIQUE,
  JOINDRE_PLAN_FORME_QUELCONQUE,
  TOILE,
  //Etape5
  S04_LONGUEUR_COMPRIMEE,
  S04_LONGUEUR_DEPLOYEE,
  //Etape6
  S04_EXTREMITE_IDENTIQUE,
  S04_FIXATION_EXTREMITES_IT1,
  S04_FIXATION_EXTREMITES_IT2,
  JOINDRE_PLAN_EXTREMITES_IT1,
  JOINDRE_PLAN_EXTREMITES_IT2,
  //Etape7
  S04_ACCESSOIRES,
  S04_ECAILLES,
};
