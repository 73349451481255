//Etape 4
const SOUFFLET_AVEC_SPIRE = Symbol("SQ0011_Q00240");
const LONGUEUR_COMPRIMEE = Symbol("SQ0011_Q00020");
const LONGUEUR_DEPLOYEE = Symbol("SQ0011_Q00030");
const SECTION_SOUFFLET = Symbol("SQ0011_Q00060");
const TYPE_CORPS = Symbol("SQ0011_Q00070");
const RONDE_DROIT_INTERIEUR_MIN = Symbol("SQ0011_Q00040");
const RONDE_DROIT_EXTERIEUR_MAX = Symbol("SQ0011_Q00050");
const RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN = Symbol("SQ0011_Q00300");
const RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX = Symbol("SQ0011_Q00310");
const RONDE_CONIQUE_INTERIEUR_DROIT_MIN = Symbol("SQ0011_Q00320");
const RONDE_CONIQUE_EXTERIEUR_DROIT_MAX = Symbol("SQ0011_Q00330");
const RECTANGULAIRE_DROIT_INTERIEUR_A_MIN = Symbol("SQ0011_Q00080");
const RECTANGULAIRE_DROIT_INTERIEUR_B_MIN = Symbol("SQ0011_Q00090");
const RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX = Symbol("SQ0011_Q00100");
const RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX = Symbol("SQ0011_Q00110");
const RECTANGULAIRE_CONIQUE_JOINDRE_PLAN = Symbol(
  "rectangulaireConiqueJoindrePlan"
);

//Etape 5
const TITRE_EXTREMITE_IDENTIQUE = Symbol("text_extremite_identique");
const EXTREMITE_IDENTIQUE = Symbol("SQ0012_Q00170");
//!!!!!Suite à la demande du client, les cotés droits et gauche sont inversés!!!!!
//!!!!!Pour limiter les temps de dev, j'ai simplement changé les intitulés!!!!!
//!!!!!Donc les noms des clés sont juste et le nom des variables sont faux!!!!!
//It2 coté gauche
const TITRE_TYPE_EXTREMITES_IT1 = Symbol("titre_extremite_it1");
const TYPE_EXTREMITES_IT1 = Symbol("SQ0012_Q00040$2");
const BRIDE_PERCEE_IT1 = Symbol("SQ0012_Q00150$2");
const TITRE_MANCHETTE_IT1 = Symbol("titre_manchette_it1");
const MANCHETTE_IT1 = Symbol("SQ0012_Q00020$2");
const MANCHETTE_LARGEUR_IT1 = Symbol("SQ0012_Q00050$2");
const MANCHETTE_DIAMETRE_IT1 = Symbol("SQ0012_Q00080$2");
const MANCHETTE_A_IT1 = Symbol("SQ0012_Q00090$2");
const MANCHETTE_B_IT1 = Symbol("SQ0012_Q00100$2");
const TITRE_BRIDE_IT1 = Symbol("titre_bride_it1");
const BRIDE_IT1 = Symbol("SQ0012_Q00110$2");
const BRIDE_DIAMETRE_IT1 = Symbol("SQ0012_Q00120$2");
const BRIDE_A_IT1 = Symbol("SQ0012_Q00130$2");
const BRIDE_B_IT1 = Symbol("SQ0012_Q00140$2");
const TITRE_BRIDE_PERCEE = Symbol("titre_bride_percee_it1");
const JOINDRE_PLAN_BRIDE_PERCEE_IT1 = Symbol("joindrePlanBridePercee$2");
const TITRE_FIXATION_EXTREMITES_IT1 = Symbol("titre_fixation_extremite_it1");
const FIXATION_EXTREMITES_IT1 = Symbol("SQ0012_Q00200$2");
//It1 - coté droit
const TYPE_EXTREMITES_IT2 = Symbol("SQ0012_Q00040$1");
const BRIDE_PERCEE_IT2 = Symbol("SQ0012_Q00150$1");
const MANCHETTE_IT2 = Symbol("SQ0012_Q00020$1");
const MANCHETTE_LARGEUR_IT2 = Symbol("SQ0012_Q00050$1");
const MANCHETTE_DIAMETRE_IT2 = Symbol("SQ0012_Q00080$1");
const MANCHETTE_A_IT2 = Symbol("SQ0012_Q00090$1");
const MANCHETTE_B_IT2 = Symbol("SQ0012_Q00100$1");
const BRIDE_IT2 = Symbol("SQ0012_Q00110$1");
const BRIDE_DIAMETRE_IT2 = Symbol("SQ0012_Q00120$1");
const BRIDE_A_IT2 = Symbol("SQ0012_Q00130$1");
const BRIDE_B_IT2 = Symbol("SQ0012_Q00140$1");
const JOINDRE_PLAN_BRIDE_PERCEE_IT2 = Symbol("joindrePlanBridePercee$1");
const FIXATION_EXTREMITES_IT2 = Symbol("SQ0012_Q00200$1");

//Etape 6
const ACCESSOIRES = Symbol("accessoires");
const FERMETURE_GLISSIERE = Symbol("SQ0020_Q00010");
const OEILLET_FILTRE = Symbol("SQ0021_Q00010");
const BAGUE_GUIDAGE = Symbol("SQ0022_Q00010");

export {
  //Etape 4
  SOUFFLET_AVEC_SPIRE,
  LONGUEUR_DEPLOYEE,
  LONGUEUR_COMPRIMEE,
  SECTION_SOUFFLET,
  TYPE_CORPS,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
  RONDE_DROIT_INTERIEUR_MIN,
  RONDE_DROIT_EXTERIEUR_MAX,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
  //Etape 5
  //General
  TITRE_EXTREMITE_IDENTIQUE,
  EXTREMITE_IDENTIQUE,
  //IT1
  TITRE_TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT1,
  //Manchette
  TITRE_MANCHETTE_IT1,
  MANCHETTE_IT1,
  MANCHETTE_LARGEUR_IT1,
  MANCHETTE_DIAMETRE_IT1,
  MANCHETTE_A_IT1,
  MANCHETTE_B_IT1,
  //Bride
  TITRE_BRIDE_IT1,
  BRIDE_IT1,
  BRIDE_DIAMETRE_IT1,
  BRIDE_A_IT1,
  BRIDE_B_IT1,
  //Commun Manchette et Bride
  TITRE_BRIDE_PERCEE,
  BRIDE_PERCEE_IT1,
  JOINDRE_PLAN_BRIDE_PERCEE_IT1,
  TITRE_FIXATION_EXTREMITES_IT1,
  FIXATION_EXTREMITES_IT1,
  //IT2
  TYPE_EXTREMITES_IT2,
  //Manchette
  MANCHETTE_IT2,
  MANCHETTE_LARGEUR_IT2,
  MANCHETTE_DIAMETRE_IT2,
  MANCHETTE_A_IT2,
  MANCHETTE_B_IT2,
  //Bride
  BRIDE_IT2,
  BRIDE_DIAMETRE_IT2,
  BRIDE_A_IT2,
  BRIDE_B_IT2,
  //Commun Manchette et Bride
  BRIDE_PERCEE_IT2,
  JOINDRE_PLAN_BRIDE_PERCEE_IT2,
  FIXATION_EXTREMITES_IT2,
  //Etape 6
  ACCESSOIRES,
  FERMETURE_GLISSIERE,
  OEILLET_FILTRE,
  BAGUE_GUIDAGE,
};
