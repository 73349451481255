import { COTE_AGRESSE_TOILE, DIMENSIONS_EXTREMITE, FIXATION_CAISSON, MATIERE_EXTREMITE, POSITION_EXTREMITE, POSITION_FIXATION_CAISSON } from "../../../../../constantes/symbols/SymbolsS05";
import { initChampsFormikToJsonDefaultCustom, initChampsFormikToJsonDefaultString } from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
    DIMENSIONS_EXTREMITE,
    POSITION_EXTREMITE,
    FIXATION_CAISSON,
    POSITION_FIXATION_CAISSON,
    COTE_AGRESSE_TOILE,
];

export const initialValuesExtremitesS05 = (formulaire) => {
    return Object.assign({
        ...initChampsFormikToJsonDefaultString(champsString, formulaire),
        ...initChampsFormikToJsonDefaultCustom([MATIERE_EXTREMITE], formulaire, 1),
        ...initChampsFormikToJsonDefaultCustom([FIXATION_CAISSON], formulaire, 1),
        ...initChampsFormikToJsonDefaultCustom([POSITION_FIXATION_CAISSON], formulaire, 0),
    }, {});
}