import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../../constantes/symbols/HelperSymbolsServices";
import {
  ADRESSE_MAIL,
  AXE_EMPLACEMENT,
  CODE_POSTAL,
  COMMENTAIRE,
  ENVIRONNEMENT,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  FONCTION,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  NOM,
  NUMERO_DE_PLAN,
  PAYS,
  POSITION_DE_TRAVAIL,
  PRENOM,
  QUANTITEE_SOUHAITEE,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
  TEMPERATURE,
  TYPE_DE_MACHINE,
  VITESSE_DEPLACEMENT,
} from "../../../../../constantes/symbols/SymbolsCommon";
import {
  LONGUEUR_A,
  LONGUEUR_B,
  LONGUEUR_C,
  LONGUEUR_D,
  LONGUEUR_E,
  LONGUEUR_F,
  LONGUEUR_G,
  LONGUEUR_H,
  LONGUEUR_X,
  S04_EXTREMITE_IDENTIQUE,
  S04_FIXATION_EXTREMITES_IT1,
  S04_FIXATION_EXTREMITES_IT2,
  S04_LONGUEUR_COMPRIMEE,
  S04_LONGUEUR_DEPLOYEE,
  TOILE,
  TYPE_SECTION,
  S04_ACCESSOIRES,
  SECTION_SYMETRIQUE,
  FORME_CORPS_A,
  FORME_CORPS_B,
  FORME_CORPS_C,
  FORME_CORPS_DS,
  FORME_CORPS_D,
  FORME_CORPS_ES,
  FORME_CORPS_E,
  FORME_CORPS_FS,
  FORME_CORPS_F,
  S04_ECAILLES,
} from "../../../../../constantes/symbols/SymbolsS04";
import { S04_STEP4_arrayTypeCorps } from "../../../../../constantes/symbols/SymbolsServicesS04";
import { RECAP_S04 } from "../../../components/generateFormulary/types/TypeFieldsFormulary";
import { CODE_ARTICLE } from "../../RechercherChamps";
import {
  FICHIERS,
  FICHIER_RECAPITULATIF,
} from "../../S01/recapitulatif/ChampsRecapitulatif";

export const formulaireRecapitulatifTypeProtection = (t) => [
  {
    nom: FAMILLE_PROTECTION_INDUSTRIELLE,
    label: t.champTypeDeProtection,
    type: RECAP_S04,
  },
];

export const formulaireConstruction = (formulaire, t) => [
  {
    nom: TYPE_SECTION,
    label: t.titreSection,
    type: RECAP_S04,
  },
  {
    nom: findFormeCorpsFromTypeSection(formulaire),
    label: t.titreFormeCorps,
    type: RECAP_S04,
  },
  {
    nom: TOILE,
    label: t.champToile,
    type: RECAP_S04,
  },
];

export const formulaireConstructionSection = (t) => [
  {
    nom: LONGUEUR_A,
    label: t.champLongueurA,
    type: RECAP_S04,
  },
  {
    nom: LONGUEUR_B,
    label: t.champLongueurB,
    type: RECAP_S04,
  },
  {
    nom: LONGUEUR_C,
    label: t.champLongueurC,
    type: RECAP_S04,
  },
  {
    nom: LONGUEUR_D,
    label: t.champLongueurD,
    type: RECAP_S04,
  },
  {
    nom: LONGUEUR_E,
    label: t.champLongueurE,
    type: RECAP_S04,
  },
  {
    nom: LONGUEUR_F,
    label: t.champLongueurF,
    type: RECAP_S04,
  },
  {
    nom: LONGUEUR_G,
    label: t.champLongueurG,
    type: RECAP_S04,
  },
  {
    nom: LONGUEUR_H,
    label: t.champLongueurH,
    type: RECAP_S04,
  },
  {
    nom: LONGUEUR_X,
    label: t.champLongueurX,
    type: RECAP_S04,
  },
];

export const formulaireDimensionsCorps = (t) => [
  {
    nom: S04_LONGUEUR_DEPLOYEE,
    label: t.champLongueurDeployee,
    type: RECAP_S04,
  },
  {
    nom: S04_LONGUEUR_COMPRIMEE,
    label: t.champLongueurComprimee,
    type: RECAP_S04,
  },
];

export const formulaireExtremitesIdentiques = (t) => [
  {
    nom: S04_EXTREMITE_IDENTIQUE,
    label: t.champExtremitesIdentiques,
    type: RECAP_S04,
  },
];

export const formulaireExtremiteDroite = (t) => [
  {
    nom: S04_FIXATION_EXTREMITES_IT1,
    label: t.champTypeExtremite,
    type: RECAP_S04,
  },
];

export const formulaireExtremiteGauche = (t) => [
  {
    nom: S04_FIXATION_EXTREMITES_IT2,
    label: t.champTypeExtremite,
    type: RECAP_S04,
  },
];

export const formulaireAccessoiresS04 = (t) => [
  {
    nom: S04_ACCESSOIRES,
    label: t.ecailles,
    type: RECAP_S04,
  },
  {
    nom: S04_ECAILLES,
    label: t.cotesAvecEcailles,
    type: RECAP_S04,
  },
];

export const formulaireInformationsMachine = (
  t,
  serviceTypeMachine,
  serviceMarqueMachine
) => [
  {
    nom: TYPE_DE_MACHINE,
    label: t.champTypeDeMachine,
    type: RECAP_S04,
    service: serviceTypeMachine,
  },
  {
    nom: MARQUE_MACHINE,
    label: t.champMarqueDeMachine,
    type: RECAP_S04,
    service: serviceMarqueMachine,
  },
  {
    nom: MODELE_MACHINE,
    label: t.champModeleDeMachine,
    type: RECAP_S04,
  },
  {
    nom: AXE_EMPLACEMENT,
    label: t.champAxeEmplacement,
    type: RECAP_S04,
  },
  {
    nom: POSITION_DE_TRAVAIL,
    label: t.champPositionDeTravail,
    type: RECAP_S04,
  },
  {
    nom: VITESSE_DEPLACEMENT,
    label: t.champVitesseDeplacement,
    type: RECAP_S04,
  },
  {
    nom: NUMERO_DE_PLAN,
    label: t.champNDePlan,
    type: RECAP_S04,
  },
  {
    nom: CODE_ARTICLE,
    label: t.champCodeArticle,
    type: RECAP_S04,
  },
];

export const formulaireAggressions = (t, agressionsList) => [
  {
    nom: TEMPERATURE,
    label: t.champTemperature,
    type: RECAP_S04,
  },
  {
    nom: ENVIRONNEMENT,
    label: t.champEnvironnement,
    type: RECAP_S04,
    nomChamps: agressionsList,
  },
];

export const formulaireQuantités = () => [
  {
    nom: QUANTITEE_SOUHAITEE,
    type: RECAP_S04,
  },
];

export const formulaireCoordonnees = (
  t,
  upToDatePaysList,
  upToDateSecteursList,
  upToDateFonctionsList
) => [
  {
    nom: SOCIETE,
    label: t.champSociete,
    type: RECAP_S04,
  },
  {
    nom: PAYS,
    label: t.champPays,
    type: RECAP_S04,
    service: upToDatePaysList,
  },
  {
    nom: CODE_POSTAL,
    label: t.champCodePostal,
    type: RECAP_S04,
  },
  {
    nom: SECTEUR,
    label: t.champSecteurActivite,
    type: RECAP_S04,
    service: upToDateSecteursList,
  },
  {
    nom: NOM,
    label: t.champNom,
    type: RECAP_S04,
  },
  {
    nom: PRENOM,
    label: t.champPrenom,
    type: RECAP_S04,
  },
  {
    nom: FONCTION,
    label: t.champFonction,
    type: RECAP_S04,
    service: upToDateFonctionsList,
  },
  {
    nom: ADRESSE_MAIL,
    label: t.champAdresseMail,
    type: RECAP_S04,
  },
  {
    nom: TELEPHONE,
    label: t.champTelephone,
    type: RECAP_S04,
  },
];

export const formulaireCommentaire = (t, booleanIdentite) => [
  {
    nom: COMMENTAIRE,
    label: t.champCommentaire,
    type: RECAP_S04,
  },
];

export const formulaireFichiers = () => [
  {
    nom: FICHIERS,
    type: RECAP_S04,
  },
];

export const formulaireFichiersRecapitulatif = () => [
  {
    nom: FICHIER_RECAPITULATIF,
    type: RECAP_S04,
  },
];

const findFormeCorpsFromTypeSection = (payload) => {
  const symetrique = equalsBooleanOrStringOrNumber(
    payload[SECTION_SYMETRIQUE.description]
  );
  switch (true) {
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      0
    ):
      return FORME_CORPS_A;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      1
    ):
      return FORME_CORPS_B;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      2
    ):
      return FORME_CORPS_C;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      3
    ):
      return !!symetrique ? FORME_CORPS_DS : FORME_CORPS_D;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      4
    ):
      return !!symetrique ? FORME_CORPS_ES : FORME_CORPS_E;
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      5
    ):
      return !!symetrique ? FORME_CORPS_FS : FORME_CORPS_F;
    default:
      return FORME_CORPS_A;
  }
};
