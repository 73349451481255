import {
  LONGUEUR_COMPRIMEE,
  LONGUEUR_DEPLOYEE,
  RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
  RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
  RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
  RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
  RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
  RONDE_DROIT_EXTERIEUR_MAX,
  RONDE_DROIT_INTERIEUR_MIN,
  SECTION_SOUFFLET,
  SOUFFLET_AVEC_SPIRE,
  TYPE_CORPS,
} from "../../../../../constantes/symbols/SymbolsS01";
import {
  arraySectionSoufflet,
  arrayTypeCorps,
} from "../../../../../constantes/symbols/SymbolsServicesS01";
import {
  TEXTFIELD_NUMBER,
  TEXT,
  BUTTON_GROUP,
  DROPZONE,
  SWITCH,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const LONGUEUR_CORPS = Symbol("longueurCorps");
const TITRE_SECTION_SOUFFLET = Symbol("titreSectionSoufflet");
const TITRE_TYPE_CORPS_S01 = Symbol("titreTypeCorpsS01");
const TITRE_SOUFFLET_AVEC_SPIRE = Symbol("titreSouffletAvecSpire");
export const formulaireDimensionsCorps = (styles, t, formik) => [
  {
    nom: TITRE_SOUFFLET_AVEC_SPIRE,
    label: t.souffletAvecSpire,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00240,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: SOUFFLET_AVEC_SPIRE,
    type: SWITCH,
    cssFontFamily: styles.cssFontFamily,
    cssSwitch: styles.cssSwitch,
    cssMarginFormSection: styles.sectionFormMarginB,
    false: t.non,
    true: t.oui,
  },
  {
    nom: LONGUEUR_CORPS,
    label: t.longueurCorpsSoufflet,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
  },
  {
    nom: LONGUEUR_DEPLOYEE,
    label: t.longueurDeployee + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00030,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  formik.values[SOUFFLET_AVEC_SPIRE.description]
    ? {
        nom: LONGUEUR_COMPRIMEE,
        label: t.longueurComprimee + t.asterisque,
        type: TEXTFIELD_NUMBER,
        cssTextfield: styles.cssTextfield,
        cssMarginFormSection: styles.sectionForm0Margin,
        textInformation: {
          text: t.SQ0011_Q00020,
          cssIcon: styles.helperTextIcon,
          // images: getImageInformation(),
        },
      }
    : null,
  {
    nom: TITRE_SECTION_SOUFFLET,
    label: t.sectionSoufflet + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0011_Q00060,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: SECTION_SOUFFLET,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: arraySectionSoufflet(t),
  },
  {
    nom: TITRE_TYPE_CORPS_S01,
    label: t.typeCorps + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0011_Q00070,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: TYPE_CORPS,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginB,
    donnees: arrayTypeCorps(t),
  },
];

export const formulaireDimensionsCorpsRondeDroit = (styles, t) => [
  {
    nom: RONDE_DROIT_EXTERIEUR_MAX,
    label: t.rondDroitExterieur + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    helper: t.helperRondDroitExterieur,
    textInformation: {
      text: t.SQ0011_Q00050,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: RONDE_DROIT_INTERIEUR_MIN,
    label: t.rondDroitInterieur + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    helper: t.helperRondDroitInterieur,
    textInformation: {
      text: t.SQ0011_Q00040,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireDimensionsCorpsRondeDroitSansSpires = (styles, t) => [
  {
    nom: RONDE_DROIT_INTERIEUR_MIN,
    label: t.rondDroitInterieur + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    helper: t.helperRondDroitInterieur,
    textInformation: {
      text: t.SQ0011_Q00040,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireDimensionsCorpsRondeConique = (styles, t) => [
  {
    nom: RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
    label: t.rondConiqueExterieurDroit + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00330,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
    label: t.rondConiqueExterieurGauche + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00310,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
    label: t.rondConiqueInterieurGauche + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00300,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
    label: t.rondConiqueInterieurDroit + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    textInformation: {
      text: t.SQ0011_Q00320,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireDimensionsCorpsRondeConiqueSansSpires = (styles, t) => [
  {
    nom: RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
    label: t.rondConiqueInterieurGauche + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00300,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
    label: t.rondConiqueInterieurDroit + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    textInformation: {
      text: t.SQ0011_Q00320,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireDimensionsCorpsRectangulaireDroit = (styles, t) => [
  {
    nom: RECTANGULAIRE_DROIT_EXTERIEUR_A_MAX,
    label: t.rectangulaireDroitExterieurA + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00100,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: RECTANGULAIRE_DROIT_EXTERIEUR_B_MAX,
    label: t.rectangulaireDroitExterieurB + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00110,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
    label: t.rectangulaireDroitInterieurA + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00080,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
    label: t.rectangulaireDroitInterieurB + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    textInformation: {
      text: t.SQ0011_Q00090,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireDimensionsCorpsRectangulaireDroitSansSpires = (
  styles,
  t
) => [
  {
    nom: RECTANGULAIRE_DROIT_INTERIEUR_A_MIN,
    label: t.rectangulaireDroitInterieurA + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0011_Q00080,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: RECTANGULAIRE_DROIT_INTERIEUR_B_MIN,
    label: t.rectangulaireDroitInterieurB + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginB,
    textInformation: {
      text: t.SQ0011_Q00090,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireDimensionsCorpsRectangulaireConique = (styles, t) => [
  {
    nom: RECTANGULAIRE_CONIQUE_JOINDRE_PLAN,
    label: t.joindreUnPlan,
    type: DROPZONE,
    description: t.descriptionRectangulaireConiqueSoufflet,
    styles: styles,
    cssMarginFormSection: styles.sectionFormMarginB,
    cssFontFamily: styles.cssFontFamily,
    cssPaddingTop: styles.descriptionDropzoneEtape4,
    textInformation: {
      text: t.rectangulaireConiqueJoindrePlan,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];
