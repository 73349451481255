import {
  COTE_AGRESSE_TOILE,
  DIMENSIONS_EXTREMITE,
  FIXATION_CAISSON,
  MATIERE_EXTREMITE,
  POSITION_EXTREMITE,
  POSITION_FIXATION_CAISSON,
} from "../../../../../constantes/symbols/SymbolsS05";
import {
  S05_STEP6_arrayCoteAgresseToile,
  S05_STEP6_arrayDimensionExtremiteIt1,
  S05_STEP6_arrayDimensionExtremiteIt2,
  S05_STEP6_arrayMatiereExtremite,
  S05_STEP6_arrayPositionExtremite,
  S05_STEP6_arrayPositionFixationCaisson,
  S05_STEP6_arraySystemeFixationCaisson,
} from "../../../../../constantes/symbols/SymbolsServicesS05";
import { importAll } from "../../../../../helper/ImportAll";
import { BUTTON_GROUP_BOX_SHADOW, TEXT, TEXTFIELD_SELECT } from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_FIXATION_CAISSON = Symbol("titre fixation caisson");

export const formulaireExtremites = (styles, t, formik) => [
  {
    nom: MATIERE_EXTREMITE,
    label: t.matiereExtremite+t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: S05_STEP6_arrayMatiereExtremite(t),
    textInformation: {
      text: t?.SQ0079_Q00020,
      cssIcon: styles?.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: POSITION_EXTREMITE,
    label: t.positionnementExtremite+t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: S05_STEP6_arrayPositionExtremite(t),
    textInformation: {
      text: t?.SQ0079_Q00040,
      cssIcon: styles?.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  [
    S05_STEP6_arrayPositionExtremite()[0].code,
    S05_STEP6_arrayPositionExtremite()[1].code,
    S05_STEP6_arrayPositionExtremite()[2].code,
  ].includes(formik.values[POSITION_EXTREMITE.description])
    ? {
        nom: DIMENSIONS_EXTREMITE,
        label: t.dimensionExtremite+t.asterisque,
        type: TEXTFIELD_SELECT,
        cssTextfield: styles.cssTextfield,
        cssMarginFormSection: styles.sectionForm0Margin,
        donnees: S05_STEP6_arrayDimensionExtremiteIt1(t),
        textInformation: {
          text: t?.SQ0079_Q00030,
          cssIcon: styles?.helperTextIcon,
          // images: getImageInformation(),
        },
      }
    : {
        nom: DIMENSIONS_EXTREMITE,
        label: t.dimensionExtremite+t.asterisque,
        type: TEXTFIELD_SELECT,
        cssTextfield: styles.cssTextfield,
        cssMarginFormSection: styles.sectionForm0Margin,
        donnees: S05_STEP6_arrayDimensionExtremiteIt2(t),
        textInformation: {
          text: t?.SQ0079_Q00030,
          cssIcon: styles?.helperTextIcon,
          // images: getImageInformation(),
        },
      },
];

export const formulaireFixationExtremiteCaisson = (styles, t) => [
  {
    nom: TITRE_FIXATION_CAISSON,
    label: t.systemeFixationExtremite,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
        text: t?.SQ0079_Q00050,
        cssIcon: styles.helperTextIcon,
        // images: getImageInformation(),
      },
},
  {
    nom: FIXATION_CAISSON,
    type: BUTTON_GROUP_BOX_SHADOW,
    cssFontFamily: styles.cssFontFamily,
    cssRadioPhoto: styles.cssRadioPhoto,
    cssRadioElementExtremites: styles.radioElementExtremites,
    cssMarginFormSection: styles.sectionForm0Margin,
    cssSurroundGridItem: styles.cssSurroundGridItem,
    cssGridRadio: styles.gridRadioExtremitesInRow,
    images: importAll(), 
    donnees: S05_STEP6_arraySystemeFixationCaisson(t),
  },
  {
    nom: POSITION_FIXATION_CAISSON,
    label: t.positionFixationExtremite,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: S05_STEP6_arrayPositionFixationCaisson(t),
    textInformation: {
      text: t?.SQ0079_Q00050,
      cssIcon: styles?.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireCoteAgresseToile = (styles, t) => [
  {
    nom: COTE_AGRESSE_TOILE,
    label: t.coteAgresseToile+t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: S05_STEP6_arrayCoteAgresseToile(t),
    textInformation: {
      text: t?.SQ0079_Q00070,
      cssIcon: styles?.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];
