import * as Yup from "yup";
import {
  DIMENSIONS_PLIS,
  NOMBRE_FACE,
  S06_LONGUEUR_A_EXT,
  S06_LONGUEUR_A_INT,
  S06_LONGUEUR_B_EXT,
  S06_LONGUEUR_B_INT,
  S06_LONGUEUR_COMPRIMEE,
  S06_LONGUEUR_C_EXT,
  S06_LONGUEUR_C_INT,
  S06_LONGUEUR_DEPLOYEE,
} from "../../../../../constantes/symbols/SymbolsS06";
import {
  S06_STEP5_arrayDimensionsPlis,
  S06_STEP5_arrayDimensionsPlisWithLongueurASup1000,
  S06_STEP5_arrayNombreFaces,
} from "../../../../../constantes/symbols/SymbolsServicesS06";

const otherwiseSchema = Yup.mixed().optional();

/* A Yup validation schema. */
export const validDimensionsCorpsS06 = (t, etape) => {
  if (etape !== 5) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({

    [S06_LONGUEUR_A_INT.description]: Yup.number()
      .positive()
      .min(1, t.superieurA0)
      .required(t.champRequis),

    [S06_LONGUEUR_B_INT.description]: Yup.number()
      .positive()
      .min(1, t.superieurA0)
      .required(t.champRequis),

      
    [S06_LONGUEUR_C_INT.description]: Yup.mixed().when(
      [NOMBRE_FACE.description].toString(),
      {
        is: (value) => value === S06_STEP5_arrayNombreFaces(t)[0].code,
        then: Yup.number()
          .positive()
          .min(1, t.superieurA0)
          .required(t.champRequis),
        otherwise: otherwiseSchema,
      }
    ),

    [DIMENSIONS_PLIS.description]: Yup.mixed().when(
      [S06_LONGUEUR_A_INT.description].toString(),
      {
        is: (value) => value > 1000,
        then: Yup.mixed().test(
          "dimensionPlisArray1",
          t.erreurSelectionDimensionPlis,
          (value) => {
            return S06_STEP5_arrayDimensionsPlisWithLongueurASup1000(t).some(
              (e) => e.code === value
            );
          }
        ),
        otherwise: Yup.mixed().test(
          "dimensionPlisArray2",
          t.erreurSelectionDimensionPlis,
          (value) => {
            return S06_STEP5_arrayDimensionsPlis(t).some(
              (e) => e.code === value
            );
          }
        ),
      }
    ),

    [NOMBRE_FACE.description]: Yup.mixed().test(
      "fixationExtremites",
      () => {
        return t.erreurSelectionFixation;
      },
      (value) => S06_STEP5_arrayNombreFaces(t).some((e) => e.code === value)
    ),
  });
};
