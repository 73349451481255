import { arrayAccessoires } from "../../../../../constantes/symbols/SymbolsServicesS01";
import { initChampsFormikToJsonDefaultBoolean } from "../../../../../helper/InitialisationChampsFormik";

export const initialValuesAccessoires = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultBoolean(
        arrayAccessoires().map((element) => element.code),
        formulaire
      ),
    },
    {}
  );
};
