import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../../../constantes/symbols/SymbolsServicesCommon";
import { ResumeS01 } from "../formulaires/S01/ResumeS01";
import { ResumeS04 } from "../formulaires/S04/ResumeS04";
import { ResumeS06 } from "../formulaires/S04_06/ResumeS06";
import { ResumeS05 } from "../formulaires/S05/ResumeS05";
import { ResumeS08 } from "../formulaires/S08/ResumeS08";

export const controllerResumeDisplay = (state, action, isFrom) => {
  switch (state.formik[FAMILLE_PROTECTION_INDUSTRIELLE.description]) {
    case arrayProductFamilly()[2].code:
      return renderS04(state, action, isFrom);
    case arrayProductFamilly()[4].code:
      return renderS05(state, action, isFrom);
    case arrayProductFamilly()[5].code:
      return renderS08(state, action, isFrom);
    case arrayProductFamilly()[3].code:
      return renderS06(state, action, isFrom);
    default:
      return renderS01(state, action, isFrom);
  }
};

const renderS01 = (state, action, isFrom) => {
  return <ResumeS01 state={state} action={action} isFrom={isFrom} />;
};

const renderS04 = (state, action, isFrom) => {
  return <ResumeS04 state={state} action={action} isFrom={isFrom} />;
};

const renderS05 = (state, action, isFrom) => {
  return <ResumeS05 state={state} action={action} isFrom={isFrom} />;
};

const renderS06 = (state, action, isFrom) => {
  return <ResumeS06 state={state} action={action} isFrom={isFrom} />;
};

const renderS08 = (state, action, isFrom) => {
  return <ResumeS08 state={state} action={action} isFrom={isFrom} />;
};

