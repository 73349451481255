import { useSelector, useDispatch } from "react-redux";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Formulaire } from "../components/Formulaire";
import { formModifierCollaborateur } from "../../../constantes/champsFormulaires/FormModifierCollaborateur";
import { validModifierCollaborateur } from "../ChampsFormulaires/validationsFormulaires/ValidModifierCollaborateur";
import {
  getAllUtilisateur,
  modifyOneUtilisateur,
} from "../../model/slice/UtilisateurReducer";
import { AdminRoutes } from "../../../constantes/routes/AdminRoutes";
import { StringToRouteInNavigate } from "../../../helper/StringParser";
import {
  StyledDivBackoffice,
  StyledDivButtonValiderAnnuler,
} from "../styledComponents/StyledDivBackoffice";
import {
  StyledH1Orange,
  StyledP,
} from "../styledComponents/StyledTextBackoffice";
import {
  StyledButtonAnnulerOrange,
  StyledButtonOrange,
} from "../styledComponents/StyledButtonBackoffice";

export const ModifierCollaborateur = () => {
  const [styles, t, setRecapitulatif, recapitulatif, setSelectedPage] =
    useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { utilisateurList } = useSelector((state) => state.utilisateur);
  const [utilisateur, setUtilisateur] = useState(null);

  useEffect(() => {
    dispatch(getAllUtilisateur());
  }, [dispatch]);

  useEffect(() => {
    if (!utilisateurList || !params.id) {
      return;
    }
    setUtilisateur(
      Object.assign(
        {},
        utilisateurList.find((utilisateur) => {
          return utilisateur.id === params.id;
        }),
        { role: "" }
      )
    );
  }, [params.id, utilisateurList]);

  const handleModifierCollaborateur = (formData) => {
    dispatch(modifyOneUtilisateur(formData))
      .unwrap()
      .then((e) => {
        if (e.code !== 400 && e.code !== 500) {
          navigate(StringToRouteInNavigate(AdminRoutes.GESTION_COMPTES));
        }
      })
      .catch((e) => {});
  };

  const handleAnnuler = () => {
    navigate(StringToRouteInNavigate(AdminRoutes.GESTION_COMPTES));
  };

  const outletContext = {
    t,
    styles,
  };

  return utilisateur ? (
    <Paper>
      <StyledDivBackoffice>
        <StyledH1Orange>Modification d’un profil utilisateur</StyledH1Orange>
        <StyledP>
          Modifiez les champs que vous souhaitez puis validez. Les informations
          des champs non renseignés seront conservées.
        </StyledP>
        {utilisateur !== null ? (
          <Formulaire
            handleInteractionWithBdd={handleModifierCollaborateur}
            initialValues={utilisateur}
            formType={formModifierCollaborateur()}
            schemaDeValidation={validModifierCollaborateur}
            outletContext={outletContext}
            from="Modifier"
          />
        ) : null}
        <StyledDivButtonValiderAnnuler>
          <StyledButtonAnnulerOrange onClick={handleAnnuler}>
            Annuler
          </StyledButtonAnnulerOrange>
          <StyledButtonOrange form="utilisateurFormulaire" type="submit">
            Valider les modifications
          </StyledButtonOrange>
        </StyledDivButtonValiderAnnuler>
      </StyledDivBackoffice>
    </Paper>
  ) : null;
};
