import axios from 'axios';
import { Serveur } from '../../constantes/services/Serveur';


const getAllFonctions = async () => {
    
    const config = {
        headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    };

    return await axios.get(Serveur.URL_FONCTIONS, config);

}

const fonctionsService = {
    getAllFonctions,
}

export default fonctionsService;

