export const formModifierCollaborateur = () =>
  Object.freeze([
    {
      ref: "nom",
      nom: "Nom",
      type: "text",
    },
    {
      ref: "prenom",
      nom: "Prénom",
      type: "text",
    },
    {
      ref: "email",
      nom: "Adresse mail",
      type: "text",
    },
    {
      ref: "motDePasse",
      nom: "Mot de passe",
      type: "password",
    },
  ]);
