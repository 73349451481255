import { usedForDisplay } from "./HelperSymbolsServices";
import { BUTEE_CAOUTCHOUC, PANTOGRAPHE, ROULETTES, TOLE_LARMEE } from "./SymbolsS08";

//Etape 4
const S08_STEP4_arrayMatiere = (t) => [
  {
    code: 0,
    label: "Acier",
    ...usedForDisplay(t, { name: t?.acier })
  },
  {
    code: 1,
    label: "Inox",
    ...usedForDisplay(t, { name: t?.inox })
  }
];

//Etape 5
const S08_STEP5_arrayTypeSection = (t) => [
    {
        code: 0,
        label: "Plat",
        ...usedForDisplay(t, { name: t?.plat })
    },
    {
        code: 1,
        label: "Toit",
        ...usedForDisplay(t, { name: t?.toit })
    },
    {
        code: 2,
        label: "Pente",
        ...usedForDisplay(t, { name: t?.pente })
    },
    {
        code: 3,
        label: "S (ou toit S)",
        ...usedForDisplay(t, { name: t?.toitS })
    },
    {
        code: 4,
        label: "Quelconque",
        ...usedForDisplay(t, { name: t?.quelconque })
    },
];

const S08_STEP5_arrayTypeToit = (t) => [
    {
        code: 0,
        label: "Toit",
        ...usedForDisplay(t, { name: t?.toit })
    },
    {
        code: 1,
        label: "Toit plat",
        ...usedForDisplay(t, { name: t?.toitPlat })
    },
];

const S08_STEP5_arrayTypePente = (t) => [
    {
        code: 0,
        label: "Descendante",
        ...usedForDisplay(t, { name: t?.descendante })
    },
    {
        code: 1,
        label: "Montante",
        ...usedForDisplay(t, { name: t?.montante })
    },
];

const S08_STEP5_arrayShape_plat_A = (t) => [
    {
        code: 0,
        label: "Forme A1",
        ...usedForDisplay(t, { name: t?.formeA1 })
    },
    {
        code: 1,
        label: "Forme A2",
        ...usedForDisplay(t, { name: t?.formeA2 })
    },
    {
        code: 2,
        label: "Forme A3",
        ...usedForDisplay(t, { name: t?.formeA3 })
    },
];

const S08_STEP5_arrayShape_toit_B_toit = (t) => [
    {
        code: 0,
        label: "Forme B1",
        ...usedForDisplay(t, { name: t?.formeB1 })
    },
    {
        code: 1,
        label: "Forme B2",
        ...usedForDisplay(t, { name: t?.formeB2 })
    },
    {
        code: 2,
        label: "Forme B3",
        ...usedForDisplay(t, { name: t?.formeB3 })
    },
];

const S08_STEP5_arrayShape_toit_B_toit_plat = (t) => [
    {
        code: 0,
        label: "Forme B4",
        ...usedForDisplay(t, { name: t?.formeB4 })
    },
    {
        code: 1,
        label: "Forme B5",
        ...usedForDisplay(t, { name: t?.formeB5 })
    },
    {
        code: 2,
        label: "Forme B6",
        ...usedForDisplay(t, { name: t?.formeB6 })
    },
];

const S08_STEP5_arrayShape_pente_C_descendante = (t) => [
    {
        code: 0,
        label: "Forme C1",
        ...usedForDisplay(t, { name: t?.formeC1 })
    },
    {
        code: 1,
        label: "Forme C2",
        ...usedForDisplay(t, { name: t?.formeC2 })
    },
    {
        code: 2,
        label: "Forme C3",
        ...usedForDisplay(t, { name: t?.formeC3 })
    },
];

const S08_STEP5_arrayShape_pente_C_montante = (t) => [
    {
        code: 0,
        label: "Forme C4",
        ...usedForDisplay(t, { name: t?.formeC4 })
    },
    {
        code: 1,
        label: "Forme C5",
        ...usedForDisplay(t, { name: t?.formeC5 })
    },
    {
        code: 2,
        label: "Forme C6",
        ...usedForDisplay(t, { name: t?.formeC6 })
    },
];

const S08_STEP5_arrayShape_toitS_D = (t) => [
    {
        code: 0,
        label: "Forme D1",
        ...usedForDisplay(t, { name: t?.formeD1 })
    },
    {
        code: 1,
        label: "Forme D2",
        ...usedForDisplay(t, { name: t?.formeD2 })
    },
    {
        code: 2,
        label: "Forme D3",
        ...usedForDisplay(t, { name: t?.formeD3 })
    },
    {
        code: 3,
        label: "Forme D4",
        ...usedForDisplay(t, { name: t?.formeD4 })
    },
];

//Etape 7
const S08_STEP7_arrayFixationGros = (t) => [
    {
        code: 0,
        label: "Bride",
        ...usedForDisplay(t, { name: t?.bride })
    },
    {
        code: 1,
        label: "Plaque pleine",
        ...usedForDisplay(t, { name: t?.plaquePleine })
    },
    {
        code: 2,
        label: "Manchette",
        ...usedForDisplay(t, { name: t?.manchette })
    },
];

const S08_STEP7_arrayFixationPetit = (t) => [
    {
        code: 0,
        label: "Bride",
        ...usedForDisplay(t, { name: t?.bride })
    },
    {
        code: 1,
        label: "Plaque pleine",
        ...usedForDisplay(t, { name: t?.plaquePleine }),
    },
    {
        code: 2,
        label: "Manchette",
        ...usedForDisplay(t, { name: t?.manchette }),
    },
];

//Etape 8
const arrayAccessoiresS08 = (t, styles) => [
    {
      code: BUTEE_CAOUTCHOUC,
      ...usedForDisplay(t, { name: t?.buteeCaoutchouc }),
      img: "butee-caoutchouc.jpg",
      textInformation: {
        text: t?.SQ0075_Q00050,
        cssIcon: styles?.helperTextIcon,
        // images: getImageInformation(),
      },
    },
    {
      code: ROULETTES,
      ...usedForDisplay(t, { name: t?.roulettes }),
      img: "roulettes.jpg",
      textInformation: {
        text: t?.SQ0075_Q00040,
        cssIcon: styles?.helperTextIcon,
        // images: getImageInformation(),
      },
    },
    {
      code: PANTOGRAPHE,
      ...usedForDisplay(t, { name: t?.pantographe }),
      img: "pantographe.jpg",
      textInformation: {
        text: t?.SQ0075_Q00170,
        cssIcon: styles?.helperTextIcon,
        // images: getImageInformation(),
      },
    },
    {
      code: TOLE_LARMEE,
      ...usedForDisplay(t, { name: t?.toleLarmee }),
      img: "tole_larmee.jpg",
      textInformation: {
        text: t?.SQ0075_Q00180,
        cssIcon: styles?.helperTextIcon,
        // images: getImageInformation(),
      },
    },
  ];

export {
  //Etape 4
  S08_STEP4_arrayMatiere,
  //Etape 5
  S08_STEP5_arrayTypeSection,
  S08_STEP5_arrayTypeToit,
  S08_STEP5_arrayTypePente,
  S08_STEP5_arrayShape_plat_A,
  S08_STEP5_arrayShape_toit_B_toit,
  S08_STEP5_arrayShape_toit_B_toit_plat,
  S08_STEP5_arrayShape_pente_C_descendante,
  S08_STEP5_arrayShape_pente_C_montante,
  S08_STEP5_arrayShape_toitS_D,
  //Etape 7
  S08_STEP7_arrayFixationGros,
  S08_STEP7_arrayFixationPetit,
  //Etape 8 
  arrayAccessoiresS08,
};
