import { Paper } from "@mui/material";
import {
  arraySectionExtremite,
  arrayTypeExtremites,
} from "../../../constantes/symbols/SymbolsServicesS01";
import {
  StyledPath1,
  StyledPathAxeSymetrie,
  StyledPathDotted,
  StyledPathFleche,
  StyledPathCote,
} from "../styledComponents/StyledPath";
import {
  checkFocusedArrow,
  checkFocusedArrowWithDependance,
  checkFocusedEndMarker,
  checkFocusedEndMarkerWithDependance,
  checkFocusedStartMarker,
  checkFocusedStartMarkerWithDependance,
  checkFocusedText,
  checkFocusedTextWithDependance,
  coefAffichageBrideIT1,
  coefAffichageBrideIT2,
  coefAffichageManchette,
} from "../../../helper/ConditionnalCssProvider";
import {
  LONGUEUR_COMPRIMEE,
  LONGUEUR_DEPLOYEE,
  RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
  RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
  RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
  RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
  SOUFFLET_AVEC_SPIRE,
  BRIDE_A_IT1,
  BRIDE_A_IT2,
  BRIDE_B_IT1,
  BRIDE_B_IT2,
  BRIDE_DIAMETRE_IT1,
  BRIDE_DIAMETRE_IT2,
  BRIDE_IT1,
  BRIDE_IT2,
  EXTREMITE_IDENTIQUE,
  MANCHETTE_A_IT1,
  MANCHETTE_A_IT2,
  MANCHETTE_B_IT1,
  MANCHETTE_B_IT2,
  MANCHETTE_DIAMETRE_IT1,
  MANCHETTE_DIAMETRE_IT2,
  MANCHETTE_IT1,
  MANCHETTE_IT2,
  MANCHETTE_LARGEUR_IT1,
  MANCHETTE_LARGEUR_IT2,
  TYPE_EXTREMITES_IT1,
  TYPE_EXTREMITES_IT2,
} from "../../../constantes/symbols/SymbolsS01";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../constantes/symbols/HelperSymbolsServices";

export const RondConique = ({ styles, formik }) => {
  const longueurComprimee = formik[LONGUEUR_COMPRIMEE.description] || "";
  const longueurDeployee = formik[LONGUEUR_DEPLOYEE.description] || "";
  const interieurGaucheMin =
    formik[RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN.description] || "";
  const interieurDroitMin =
    formik[RONDE_CONIQUE_INTERIEUR_DROIT_MIN.description] || "";
  const exterieurGaucheMax =
    formik[RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX.description] || "";
  const exterieurDroitMax =
    formik[RONDE_CONIQUE_EXTERIEUR_DROIT_MAX.description] || "";
  const spire =
    formik[SOUFFLET_AVEC_SPIRE.description] !== undefined
      ? equalsBooleanOrStringOrNumber(formik[SOUFFLET_AVEC_SPIRE.description])
      : "";

  const afficherManchetteDroite = (largeur, aire) => {
    const coefManchette =
      20 *
      coefAffichageManchette(
        MANCHETTE_LARGEUR_IT1,
        MANCHETTE_LARGEUR_IT2,
        formik
      );
    return (
      <>
        {/*Fleche taille ouverture droite*/}
        <StyledPathFleche
          d="M 190,140 L 190,107"
          markerEnd={checkFocusedEndMarker(formik, [
            MANCHETTE_DIAMETRE_IT1,
            MANCHETTE_A_IT1,
            MANCHETTE_B_IT1,
          ])}
          className={checkFocusedArrow(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            styles
          )}
        />
        {/*Ligne pour taille ouverture droite */}
        <StyledPathCote
          d={"M " + (160 + coefManchette + 4) + ",100 L 193,100"}
        />

        {/*Fleche Largeur droite*/}
        <StyledPathFleche
          d={"M 155,62 L" + (160 + coefManchette + 4) + ",62"}
          markerStart={checkFocusedEndMarker(formik, [MANCHETTE_LARGEUR_IT1])}
          markerEnd={checkFocusedStartMarker(formik, [MANCHETTE_LARGEUR_IT1])}
          className={checkFocusedArrow(formik, [MANCHETTE_LARGEUR_IT1], styles)}
        />

        {/*Largeur droite text*/}
        <text
          fontSize="0.4em"
          x="168"
          y="60"
          className={checkFocusedText(formik, [MANCHETTE_LARGEUR_IT1], styles)}
        >
          {largeur}
        </text>
        {/*Diametre extremite droite*/}
        <text
          fontSize="0.4em"
          x="192"
          y="135"
          className={checkFocusedText(formik, [MANCHETTE_DIAMETRE_IT1], styles)}
        >
          {aire}
        </text>
        {/*Cote largeur droit*/}
        <StyledPathCote
          d={
            "M " +
            (160 + coefManchette) +
            ",60 L" +
            (160 + coefManchette) +
            " ,100"
          }
        />

        {/*Extremité droite pour manchette*/}
        <StyledPath1 d={"M 160,100 L" + (160 + coefManchette) + ",100"} />

        {/*Cote largeur LDLC droit*/}
        <StyledPathCote d={"M 160,100 L 160,50"} />
      </>
    );
  };

  const afficherManchetteGauche = (largeur, aire) => {
    const coefManchette =
      20 *
      coefAffichageManchette(
        MANCHETTE_LARGEUR_IT2,
        MANCHETTE_LARGEUR_IT1,
        formik
      );
    return (
      <>
        {/*Fleche taille ouverture gauche*/}
        <StyledPathFleche
          d="M 50,140 L 50,87"
          markerEnd={checkFocusedEndMarkerWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            [MANCHETTE_DIAMETRE_IT2, MANCHETTE_A_IT2, MANCHETTE_B_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            [MANCHETTE_DIAMETRE_IT2, MANCHETTE_A_IT2, MANCHETTE_B_IT2],
            styles
          )}
        />
        {/*Ligne pour taille ouverture gauche */}
        <StyledPathCote d={"M 47,80 L " + (80 - coefManchette - 3) + ",80"} />
        {/*Extremité gauche pour manchette*/}
        <StyledPath1 d={"M " + (80 - coefManchette) + ",80 L 80,80"} />
        {/*Fleche Largeur gauche*/}
        <StyledPathFleche
          d={"M " + (80 - coefManchette - 4) + ",62 L 84,62"}
          markerStart={checkFocusedEndMarkerWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2]
          )}
          markerEnd={checkFocusedStartMarkerWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2],
            styles
          )}
        />
        {/*Ligne pour mesure largeur manchette gauche*/}
        <StyledPathCote
          d={
            "M " +
            (80 - coefManchette) +
            ",60 L " +
            (80 - coefManchette) +
            ",80"
          }
        />
        {/*Largeur gauche text*/}
        <text
          fontSize="0.4em"
          x="68"
          y="60"
          className={checkFocusedTextWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2],
            styles
          )}
        >
          {largeur}
        </text>
        {/*Diametre extremite gauche*/}
        <text
          fontSize="0.4em"
          x="32"
          y="135"
          className={checkFocusedTextWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1],
            [MANCHETTE_DIAMETRE_IT2],
            styles
          )}
        >
          {aire}
        </text>

        {/*Cote largeur gauche*/}
        <StyledPathCote d="M 80,80 L 80,50" />
      </>
    );
  };

  const afficherBrideDroite = (aire) => {
    const coefBride =
      20 *
      coefAffichageBrideIT1(
        [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1],
        [RONDE_CONIQUE_INTERIEUR_DROIT_MIN, RONDE_CONIQUE_INTERIEUR_DROIT_MIN],
        RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
        formik
      );
    return (
      <>
        {/*Cote largeur droit*/}
        <StyledPathCote d="M 160,50 L 160,80" />
        {/*Fleche taille ouverture droite haut*/}
        <StyledPathFleche
          d={"M 181,140 L 181," + (107 - coefBride)}
          markerEnd={checkFocusedEndMarker(formik, [
            BRIDE_DIAMETRE_IT1,
            BRIDE_A_IT1,
            BRIDE_B_IT1,
          ])}
          className={checkFocusedArrow(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            styles
          )}
        />
        {/*Ligne pour taille ouverture droite haut*/}
        <StyledPathCote
          d={"M 163," + (100 - coefBride) + " L 183," + (100 - coefBride)}
        />
        {/*Extremité droite pour bride*/}
        <StyledPath1 d={"M 160," + (100 - coefBride) + " L 160,100"} />
        {/*Diametre extremite droite*/}
        <text
          fontSize="0.4em"
          x="195"
          y="135"
          className={checkFocusedText(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            styles
          )}
        >
          {aire}
        </text>
      </>
    );
  };

  const afficherBrideGauche = (aire) => {
    const coefBride =
      20 *
      coefAffichageBrideIT2(
        [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2],
        [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1],
        [
          [
            RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
            RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
          ],
          [
            RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
            RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
          ],
        ],
        RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
        formik
      );

    return (
      <>
        {/*Cote largeur gauche*/}
        <StyledPathCote d="M 80,50 L 80,60" />
        {/*Fleche taille ouverture gauche haut*/}
        <StyledPathFleche
          d={"M 59,140 L 59," + (80 - coefBride + 7)}
          markerEnd={checkFocusedEndMarkerWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
            styles
          )}
        />
        {/*Ligne pour taille ouverture gauche haut*/}
        <StyledPathCote
          d={"M 55," + (80 - coefBride) + " L 77," + (80 - coefBride)}
        />

        {/*Extremité gauche pour bride*/}
        <StyledPath1 d={"M 80," + (80 - coefBride) + " L 80,80"} />

        {/*Diametre extremite gauche*/}
        <text
          fontSize="0.4em"
          x="37"
          y="135"
          className={checkFocusedTextWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
            styles
          )}
        >
          {aire}
        </text>
      </>
    );
  };

  const afficherManchetteEtBrideDroite = (
    aireManchette,
    aireBride,
    largeurManchette
  ) => {
    const coefManchette =
      10 *
      coefAffichageManchette(
        MANCHETTE_LARGEUR_IT1,
        MANCHETTE_LARGEUR_IT2,
        formik
      );
    const coefBride =
      20 *
      coefAffichageBrideIT1(
        [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1],
        [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1],
        RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
        formik
      );

    return (
      <>
        {/*Cote bride droit*/}
        <StyledPathCote
          d={
            "M " +
            (165 + coefManchette) +
            ",60 L " +
            (165 + coefManchette) +
            ",80"
          }
        />

        {/*Fleche cote bride droite bas*/}
        <StyledPathFleche
          d={"M 155,62 L " + (165 + coefManchette + 5) + ",62"}
          markerStart={checkFocusedEndMarker(formik, [MANCHETTE_LARGEUR_IT1])}
          markerEnd={checkFocusedStartMarker(formik, [MANCHETTE_LARGEUR_IT1])}
          className={checkFocusedArrow(formik, [MANCHETTE_LARGEUR_IT1], styles)}
        />
        <text
          fontSize="0.4em"
          x="165"
          y="60"
          className={checkFocusedText(formik, [MANCHETTE_LARGEUR_IT1], styles)}
        >
          {largeurManchette}
        </text>

        {/*Fleche taille ouverture droite haut*/}
        <StyledPathFleche
          d={"M 191,140 L 191," + (100 - coefBride + 7)}
          markerEnd={checkFocusedEndMarker(formik, [
            BRIDE_DIAMETRE_IT1,
            BRIDE_A_IT1,
            BRIDE_B_IT1,
          ])}
          className={checkFocusedArrow(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="167"
          y="125"
          className={checkFocusedText(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            styles
          )}
        >
          {aireManchette}
        </text>
        {/*Ligne pour taille ouverture droite haut*/}
        <StyledPathCote
          d={
            "M " +
            (170 + coefManchette - 3) +
            "," +
            (100 - coefBride) +
            " L 193," +
            (100 - coefBride)
          }
        />

        {/*Fleche taille ouverture droite*/}
        <StyledPathFleche
          d="M 185,119 L 185,107"
          markerEnd={checkFocusedEndMarker(formik, [
            MANCHETTE_DIAMETRE_IT1,
            MANCHETTE_A_IT1,
            MANCHETTE_B_IT1,
          ])}
          className={checkFocusedArrow(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            styles
          )}
        />
        <StyledPathFleche
          d={"M 185,127 L 185,140"}
          className={checkFocusedArrow(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            styles
          )}
        />
        {/*Ligne pour taille ouverture droite */}
        <StyledPathCote
          d={"M " + (170 + coefManchette - 3) + ",100 L 187,100"}
        />

        {/*Ligne pour mesure largeur manchette droite*/}
        <StyledPathCote d="M 160,50 L 160,100" />

        {/*Extremité droite pour bride*/}
        <StyledPath1
          d={
            "M 160,100 L " +
            (165 + coefManchette) +
            ",100 L " +
            (165 + coefManchette) +
            "," +
            (100 - coefBride)
          }
        />
        {/*Diametre extremite droite*/}
        <text
          fontSize="0.4em"
          x="193"
          y="135"
          className={checkFocusedText(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            styles
          )}
        >
          {aireBride}
        </text>
      </>
    );
  };

  const afficherManchetteEtBrideGauche = (
    aireManchette,
    aireBride,
    largeurManchette
  ) => {
    const coefManchette =
      10 *
      coefAffichageManchette(
        MANCHETTE_LARGEUR_IT1,
        MANCHETTE_LARGEUR_IT2,
        formik
      );
    const coefBride =
      20 *
      coefAffichageBrideIT2(
        [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2],
        [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1],
        [
          [MANCHETTE_DIAMETRE_IT1, MANCHETTE_DIAMETRE_IT2],
          [MANCHETTE_A_IT1, MANCHETTE_A_IT2],
        ],
        RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
        formik
      );

    return (
      <>
        {/*Cote bride soufflet gauche*/}
        <StyledPathCote d={"M 80,50 L 80,80"} />
        {/*Fleche cote bride gauche bas*/}
        <StyledPathFleche
          d={"M " + (65 + coefManchette - 5) + ",53 L 85,53"}
          markerStart={checkFocusedEndMarkerWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2]
          )}
          markerEnd={checkFocusedStartMarkerWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="70"
          y="51"
          className={checkFocusedTextWithDependance(
            formik,
            [MANCHETTE_LARGEUR_IT1],
            [MANCHETTE_LARGEUR_IT2],
            styles
          )}
        >
          {largeurManchette}
        </text>

        {/*Fleche taille ouverture gauche haut*/}
        <StyledPathFleche
          d={"M 45,129 L 45," + (80 - coefBride + 7)}
          markerEnd={checkFocusedEndMarkerWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
            styles
          )}
        />
        <StyledPathFleche
          d={"M 45,137 L 45,140"}
          className={checkFocusedArrowWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="56"
          y="125"
          className={checkFocusedTextWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            [MANCHETTE_DIAMETRE_IT2, MANCHETTE_A_IT2, MANCHETTE_B_IT2],
            styles
          )}
        >
          {aireManchette}
        </text>
        {/*Ligne pour taille ouverture gauche haut*/}
        <StyledPathCote
          d={
            "M 43," +
            (80 - coefBride) +
            " L " +
            (68 + coefManchette - 5) +
            "," +
            (80 - coefBride)
          }
        />

        {/*Fleche taille ouverture gauche*/}
        <StyledPathFleche
          d="M 55,140 L 55,87"
          markerEnd={checkFocusedEndMarkerWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            [MANCHETTE_DIAMETRE_IT2, MANCHETTE_A_IT2, MANCHETTE_B_IT2]
          )}
          className={checkFocusedArrowWithDependance(
            formik,
            [MANCHETTE_DIAMETRE_IT1, MANCHETTE_A_IT1, MANCHETTE_B_IT1],
            [MANCHETTE_DIAMETRE_IT2, MANCHETTE_A_IT2, MANCHETTE_B_IT2],
            styles
          )}
        />
        {/*Ligne pour taille ouverture gauche */}
        <StyledPathCote d={"M 53,80 L " + (60 + coefManchette) + ",80"} />

        {/*Ligne pour mesure largeur manchette gauche*/}
        <StyledPathCote
          d={
            "M " +
            (65 + coefManchette) +
            ",50 L " +
            (65 + coefManchette) +
            ",60"
          }
        />
        {/*Extremité gauche pour bride*/}
        <StyledPath1
          d={
            "M " +
            (65 + coefManchette) +
            "," +
            (80 - coefBride) +
            " L " +
            (65 + coefManchette) +
            ",80 L 80,80"
          }
        />
        {/*Diametre extremite gauche*/}
        <text
          fontSize="0.4em"
          x="27"
          y="135"
          className={checkFocusedTextWithDependance(
            formik,
            [BRIDE_DIAMETRE_IT1, BRIDE_A_IT1, BRIDE_B_IT1],
            [BRIDE_DIAMETRE_IT2, BRIDE_A_IT2, BRIDE_B_IT2],
            styles
          )}
        >
          {aireBride}
        </text>
      </>
    );
  };

  const afficherIT1 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT1.description],
        arrayTypeExtremites(),
        0
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT1.description],
            arraySectionExtremite(),
            0
          ):
            return (
              <>
                {afficherManchetteDroite(
                  formik[MANCHETTE_LARGEUR_IT1.description],
                  "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description]
                )}
              </>
            );
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT1.description],
            arraySectionExtremite(),
            1
          ):
            return (
              <>
                {afficherManchetteDroite(
                  formik[MANCHETTE_LARGEUR_IT1.description],
                  formik[MANCHETTE_A_IT1.description] +
                    "X" +
                    formik[MANCHETTE_B_IT1.description]
                )}
              </>
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT1.description],
        arrayTypeExtremites(),
        1
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[BRIDE_IT1.description],
            arraySectionExtremite(),
            0
          ):
            return (
              <>
                {afficherBrideDroite(
                  "Ø" + formik[BRIDE_DIAMETRE_IT1.description]
                )}
              </>
            );
          case equalsCodeOrLabel(
            formik[BRIDE_IT1.description],
            arraySectionExtremite(),
            1
          ):
            return (
              <>
                {afficherBrideDroite(
                  formik[BRIDE_A_IT1.description] +
                    "X" +
                    formik[BRIDE_B_IT1.description]
                )}
              </>
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT1.description],
        arrayTypeExtremites(),
        2
      ):
        switch (formik[MANCHETTE_IT1.description]) {
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT1.description],
            arraySectionExtremite(),
            0
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteEtBrideDroite(
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                      "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                      formik[MANCHETTE_LARGEUR_IT1.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteEtBrideDroite(
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                      formik[BRIDE_A_IT1.description] +
                        "X" +
                        formik[BRIDE_B_IT1.description],
                      formik[MANCHETTE_LARGEUR_IT1.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT1.description],
            arraySectionExtremite(),
            1
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteEtBrideDroite(
                      formik[MANCHETTE_A_IT1.description] +
                        "X" +
                        formik[MANCHETTE_B_IT1.description],
                      "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                      formik[MANCHETTE_LARGEUR_IT1.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteEtBrideDroite(
                      formik[MANCHETTE_A_IT1.description] +
                        "X" +
                        formik[MANCHETTE_B_IT1.description],
                      formik[BRIDE_A_IT1.description] +
                        "X" +
                        formik[BRIDE_B_IT1.description],
                      formik[MANCHETTE_LARGEUR_IT1.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const afficherIT2 = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT2.description],
        arrayTypeExtremites(),
        0
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT2.description],
            arraySectionExtremite(),
            0
          ):
            return (
              <>
                {afficherManchetteGauche(
                  formik[MANCHETTE_LARGEUR_IT2.description],
                  "Ø" + formik[MANCHETTE_DIAMETRE_IT2.description]
                )}
              </>
            );
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT2.description],
            arraySectionExtremite(),
            1
          ):
            return (
              <>
                {afficherManchetteGauche(
                  formik[MANCHETTE_LARGEUR_IT2.description],
                  formik[MANCHETTE_A_IT2.description] +
                    "X" +
                    formik[MANCHETTE_B_IT2.description]
                )}
              </>
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT2.description],
        arrayTypeExtremites(),
        1
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[BRIDE_IT2.description],
            arraySectionExtremite(),
            0
          ):
            return (
              <>
                {afficherBrideGauche(
                  "Ø" + formik[BRIDE_DIAMETRE_IT2.description]
                )}
              </>
            );
          case equalsCodeOrLabel(
            formik[BRIDE_IT2.description],
            arraySectionExtremite(),
            1
          ):
            return (
              <>
                {afficherBrideGauche(
                  formik[BRIDE_A_IT2.description] +
                    "X" +
                    formik[BRIDE_B_IT2.description]
                )}
              </>
            );
          default:
            return null;
        }
      case equalsCodeOrLabel(
        formik[TYPE_EXTREMITES_IT2.description],
        arrayTypeExtremites(),
        2
      ):
        switch (true) {
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT2.description],
            arraySectionExtremite(),
            0
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT2.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteEtBrideGauche(
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT2.description],
                      "Ø" + formik[BRIDE_DIAMETRE_IT2.description],
                      formik[MANCHETTE_LARGEUR_IT2.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT2.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteEtBrideGauche(
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT2.description],
                      formik[BRIDE_A_IT2.description] +
                        "X" +
                        formik[BRIDE_B_IT2.description],
                      formik[MANCHETTE_LARGEUR_IT2.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          case equalsCodeOrLabel(
            formik[MANCHETTE_IT2.description],
            arraySectionExtremite(),
            1
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT2.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteEtBrideGauche(
                      formik[MANCHETTE_A_IT2.description] +
                        "X" +
                        formik[MANCHETTE_B_IT2.description],
                      "Ø" + formik[BRIDE_DIAMETRE_IT2.description],
                      formik[MANCHETTE_LARGEUR_IT2.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT2.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteEtBrideGauche(
                      formik[MANCHETTE_A_IT2.description] +
                        "X" +
                        formik[MANCHETTE_B_IT2.description],
                      formik[BRIDE_A_IT2.description] +
                        "X" +
                        formik[BRIDE_B_IT2.description],
                      formik[MANCHETTE_LARGEUR_IT2.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const afficherExtremitesIdentiques = () => {
    switch (
      equalsBooleanOrStringOrNumber(formik[EXTREMITE_IDENTIQUE.description])
    ) {
      case 1:
        switch (true) {
          case equalsCodeOrLabel(
            formik[TYPE_EXTREMITES_IT1.description],
            arrayTypeExtremites(),
            0
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[MANCHETTE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherManchetteDroite(
                      formik[MANCHETTE_LARGEUR_IT1.description],
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description]
                    )}
                    {afficherManchetteGauche(
                      formik[MANCHETTE_LARGEUR_IT1.description],
                      "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[MANCHETTE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherManchetteDroite(
                      formik[MANCHETTE_LARGEUR_IT1.description],
                      formik[MANCHETTE_A_IT1.description] +
                        "X" +
                        formik[MANCHETTE_B_IT1.description]
                    )}
                    {afficherManchetteGauche(
                      formik[MANCHETTE_LARGEUR_IT1.description],
                      formik[MANCHETTE_A_IT1.description] +
                        "X" +
                        formik[MANCHETTE_B_IT1.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          case equalsCodeOrLabel(
            formik[TYPE_EXTREMITES_IT1.description],
            arrayTypeExtremites(),
            1
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                return (
                  <>
                    {afficherBrideDroite(
                      "Ø" + formik[BRIDE_DIAMETRE_IT1.description]
                    )}
                    {afficherBrideGauche(
                      "Ø" + formik[BRIDE_DIAMETRE_IT1.description]
                    )}
                  </>
                );
              case equalsCodeOrLabel(
                formik[BRIDE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                return (
                  <>
                    {afficherBrideDroite(
                      formik[BRIDE_A_IT1.description] +
                        "X" +
                        formik[BRIDE_B_IT1.description]
                    )}
                    {afficherBrideGauche(
                      formik[BRIDE_A_IT1.description] +
                        "X" +
                        formik[BRIDE_B_IT1.description]
                    )}
                  </>
                );
              default:
                return null;
            }
          case equalsCodeOrLabel(
            formik[TYPE_EXTREMITES_IT1.description],
            arrayTypeExtremites(),
            2
          ):
            switch (true) {
              case equalsCodeOrLabel(
                formik[MANCHETTE_IT1.description],
                arraySectionExtremite(),
                0
              ):
                switch (true) {
                  case equalsCodeOrLabel(
                    formik[BRIDE_IT1.description],
                    arraySectionExtremite(),
                    0
                  ):
                    return (
                      <>
                        {afficherManchetteEtBrideDroite(
                          "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                          "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                        {afficherManchetteEtBrideGauche(
                          "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                          "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                      </>
                    );
                  case equalsCodeOrLabel(
                    formik[BRIDE_IT1.description],
                    arraySectionExtremite(),
                    1
                  ):
                    return (
                      <>
                        {afficherManchetteEtBrideDroite(
                          "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                          formik[BRIDE_A_IT1.description] +
                            "X" +
                            formik[BRIDE_B_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                        {afficherManchetteEtBrideGauche(
                          "Ø" + formik[MANCHETTE_DIAMETRE_IT1.description],
                          formik[BRIDE_A_IT1.description] +
                            "X" +
                            formik[BRIDE_B_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                      </>
                    );
                  default:
                    return null;
                }
              case equalsCodeOrLabel(
                formik[MANCHETTE_IT1.description],
                arraySectionExtremite(),
                1
              ):
                switch (true) {
                  case equalsCodeOrLabel(
                    formik[BRIDE_IT1.description],
                    arraySectionExtremite(),
                    0
                  ):
                    return (
                      <>
                        {afficherManchetteEtBrideDroite(
                          formik[MANCHETTE_A_IT1.description] +
                            "X" +
                            formik[MANCHETTE_B_IT1.description],
                          "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                        {afficherManchetteEtBrideGauche(
                          formik[MANCHETTE_A_IT1.description] +
                            "X" +
                            formik[MANCHETTE_B_IT1.description],
                          "Ø" + formik[BRIDE_DIAMETRE_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                      </>
                    );
                  case equalsCodeOrLabel(
                    formik[BRIDE_IT1.description],
                    arraySectionExtremite(),
                    1
                  ):
                    return (
                      <>
                        {afficherManchetteEtBrideDroite(
                          formik[MANCHETTE_A_IT1.description] +
                            "X" +
                            formik[MANCHETTE_B_IT1.description],
                          formik[BRIDE_A_IT1.description] +
                            "X" +
                            formik[BRIDE_B_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                        {afficherManchetteEtBrideGauche(
                          formik[MANCHETTE_A_IT1.description] +
                            "X" +
                            formik[MANCHETTE_B_IT1.description],
                          formik[BRIDE_A_IT1.description] +
                            "X" +
                            formik[BRIDE_B_IT1.description],
                          formik[MANCHETTE_LARGEUR_IT1.description]
                        )}
                      </>
                    );
                  default:
                    return null;
                }
              default:
                return null;
            }
          default:
            return null;
        }
      case 0:
        return (
          <>
            {afficherIT1()}
            {afficherIT2()}
          </>
        );

      default:
        return null;
    }
  };

  const displayRefWidthLine = () => {
    return (
      <>
        {/*Manchette gauche par defaut*/}
        {/* <path d="M 60,80 L 80,80" className={styles.path1} /> */}
        {/*Manchette droite par defaut*/}
        {/* <path d="M 160,100 L 180,100" className={styles.path1} /> */}

        {/*Ligne debut gauche*/}
        {/* <StyledPathFleche d="M 60,140 L 60,80" /> */}

        {/*Ligne fin droit*/}
        {/* <StyledPathFleche d="M 180,140 L 180,100" /> */}

        {/*Cote largeur gauche*/}
        <StyledPathCote d="M 80,80 L 80,50" />
        {/*Cote largeur droit*/}
        <StyledPathCote d="M 160,100 L 160,50" />
      </>
    );
  };

  const afficherRondConiqueSansSpire = () => {
    return (
      <>
        {/*Membre gauche*/}
        <StyledPath1 d="M 80,80 L 90,60" />
        {/*Barre pointillée*/}
        <StyledPathDotted d="M 90,60 L 150,80" className={styles.dotted} />
        {/*Membre droit*/}
        <StyledPath1 d="M 150,80 L 160,100" />

        {/*Axe de symétrie*/}
        <StyledPathAxeSymetrie d="M 15,140 L 225,140" />

        {/*Trait fleche exterieur premier cone*/}
        <StyledPathCote d="M 83,80 L 103,80" />
        {/*Fleche interieur premier cone*/}
        <StyledPathFleche
          d="M 100,128 L 100,87"
          markerEnd={checkFocusedEndMarker(formik, [
            RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
          ])}
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN],
            styles
          )}
        />
        <StyledPathFleche
          d="M 100,140 L 100,137"
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="103"
          y="125"
          className={checkFocusedText(
            formik,
            [RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN],
            styles
          )}
        >
          Ø{interieurGaucheMin}
        </text>

        {/*Fleche exterieur premier cone*/}
        <StyledPathFleche
          d="M 90,128 L 90,67"
          markerEnd={checkFocusedEndMarker(formik, [
            RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX,
          ])}
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX],
            styles
          )}
        />
        <StyledPathFleche
          d="M 90,140 L 90,137"
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="78"
          y="135"
          className={checkFocusedText(
            formik,
            [RONDE_CONIQUE_EXTERIEUR_GAUCHE_MAX],
            styles
          )}
        >
          Ø{exterieurGaucheMax}
        </text>

        {/*Fleche interieur second cone*/}
        <StyledPathFleche
          d="M 150,128 L 150,87"
          markerEnd={checkFocusedEndMarker(formik, [
            RONDE_CONIQUE_EXTERIEUR_DROIT_MAX,
          ])}
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_EXTERIEUR_DROIT_MAX],
            styles
          )}
        />
        <StyledPathFleche
          d="M 150,140 L 150,137"
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_EXTERIEUR_DROIT_MAX],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="150"
          y="135"
          className={checkFocusedText(
            formik,
            [RONDE_CONIQUE_EXTERIEUR_DROIT_MAX],
            styles
          )}
        >
          Ø{exterieurDroitMax}
        </text>

        {/*Trait fleche exterieur second cone*/}
        <StyledPathCote d="M 137,100 L 157,100" />
        {/*Fleche exterieur droit second cone*/}
        <StyledPathFleche
          d="M 140,128 L 140,107"
          markerEnd={checkFocusedEndMarker(formik, [
            RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
          ])}
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_INTERIEUR_DROIT_MIN],
            styles
          )}
        />
        <StyledPathFleche
          d="M 140,140 L 140,137"
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_INTERIEUR_DROIT_MIN],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="125"
          y="135"
          className={checkFocusedText(
            formik,
            [RONDE_CONIQUE_INTERIEUR_DROIT_MIN],
            styles
          )}
        >
          Ø{interieurDroitMin}
        </text>
      </>
    );
  };

  const afficherCompensateurRondConique = () => {
    return (
      <>
        {/*Membre gauche*/}
        <StyledPath1 d="M 80,80 L 90,82" />
        {/*Barre pointillée*/}
        <StyledPathDotted d="M 90,82 L 150,98" className={styles.dotted} />
        {/*Membre droit*/}
        <StyledPath1 d="M 150,98 L 160,100" />

        {/*Axe de symétrie*/}
        <StyledPathAxeSymetrie d="M 15,140 L 225,140" />

        {/*Fleche interieur premier cone*/}
        <StyledPathFleche
          d="M 85,128 L 85,88"
          markerEnd={checkFocusedEndMarker(formik, [
            RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN,
          ])}
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN],
            styles
          )}
        />
        <StyledPathFleche
          d="M 85,140 L 85,137"
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="80"
          y="135"
          className={checkFocusedText(
            formik,
            [RONDE_CONIQUE_INTERIEUR_GAUCHE_MIN],
            styles
          )}
        >
          Ø{interieurGaucheMin}
        </text>

        {/*Fleche exterieur droit second cone*/}
        <StyledPathFleche
          d="M 155,128 L 155,106"
          markerEnd={checkFocusedEndMarker(formik, [
            RONDE_CONIQUE_INTERIEUR_DROIT_MIN,
          ])}
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_INTERIEUR_DROIT_MIN],
            styles
          )}
        />
        <StyledPathFleche
          d="M 155,140 L 155,137"
          className={checkFocusedArrow(
            formik,
            [RONDE_CONIQUE_INTERIEUR_DROIT_MIN],
            styles
          )}
        />
        <text
          fontSize="0.4em"
          x="150"
          y="135"
          className={checkFocusedText(
            formik,
            [RONDE_CONIQUE_INTERIEUR_DROIT_MIN],
            styles
          )}
        >
          Ø{interieurDroitMin}
        </text>
      </>
    );
  };

  const afficherDessinRondConique = () => {
    if (!!spire) {
      return <>{afficherRondConiqueSansSpire()}</>;
    } else {
      return <>{afficherCompensateurRondConique()}</>;
    }
  };

  return (
    <Paper elevation={0}>
      <svg viewBox="-10 40 255 120">
        <defs>
          <marker
            id="startarrow"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 2, 10 5, 0 3.5" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 2, 10 3.5, 0 5" />
          </marker>
          <marker
            id="startarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="10 2, 10 5, 0 3.5"
              className={styles.markerSelected}
            />
          </marker>
          <marker
            id="endarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon
              points="0 2, 10 3.5, 0 5"
              className={styles.markerSelected}
            />
          </marker>
        </defs>

        <title></title>

        {afficherDessinRondConique()}

        {/*Fleche Largeur*/}
        <StyledPathFleche
          d="M 85,53 L 155,53"
          markerStart={checkFocusedStartMarker(formik, [
            LONGUEUR_DEPLOYEE,
            LONGUEUR_COMPRIMEE,
          ])}
          markerEnd={checkFocusedEndMarker(formik, [
            LONGUEUR_DEPLOYEE,
            LONGUEUR_COMPRIMEE,
          ])}
          className={checkFocusedArrow(
            formik,
            [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
            styles
          )}
        />
        {!!spire ? (
          <text
            fontSize="0.4em"
            x={longueurComprimee === "" ? "130" : "115"}
            y="60"
            direction="rtl"
            className={checkFocusedText(
              formik,
              [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
              styles
            )}
          >
            LD {longueurDeployee}
          </text>
        ) : (
          <text
            fontSize="0.4em"
            x="130"
            y="60"
            direction="rtl"
            className={checkFocusedText(
              formik,
              [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
              styles
            )}
          >
            LD {longueurDeployee}
          </text>
        )}
        {longueurComprimee === "" || !spire ? null : (
          <>
            <text
              fontSize="0.4em"
              x="120"
              y="60"
              textAnchor="middle"
              className={checkFocusedText(
                formik,
                [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              /
            </text>
            <text
              fontSize="0.4em"
              x="125"
              y="60"
              direction="ltr"
              className={checkFocusedText(
                formik,
                [LONGUEUR_DEPLOYEE, LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              LC {longueurComprimee}
            </text>
          </>
        )}
        {displayRefWidthLine()}
        {afficherExtremitesIdentiques()}
      </svg>
    </Paper>
  );
};
