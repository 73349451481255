import {
  ALIGNEMENT_IT1,
  ALIGNEMENT_IT2,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT1,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT2,
  BRIDE_FIXATION_IT1,
  BRIDE_FIXATION_IT2,
  S06_BRIDE_IT1,
  S06_BRIDE_IT2,
  S06_EXTREMITE_IDENTIQUE,
  S06_TYPE_EXTREMITES_IT1,
  S06_TYPE_EXTREMITES_IT2,
  S06_LONGUEUR_A_EXT,
  S06_LONGUEUR_A_INT,
  S06_LONGUEUR_B_EXT,
  S06_LONGUEUR_B_INT,
  S06_MANCHETTE_A_IT1,
  S06_MANCHETTE_A_IT2,
  S06_MANCHETTE_B_IT1,
  S06_MANCHETTE_B_IT2,
  S06_MANCHETTE_LARGEUR_IT1,
  S06_MANCHETTE_LARGEUR_IT2,
} from "../../../../../constantes/symbols/SymbolsS06";
import {
  S06_STEP6_arrayAlignement,
  S06_STEP6_arrayExtremiteInsereeMatiere,
  S06_STEP6_arrayFixationBride,
  S06_STEP6_arrayFixationBrideTypeA,
  S06_STEP6_arrayFixationBrideTypeAEtAcier,
  S06_STEP6_arrayTypeExtremites,
  S06_STEP6_arrayTypeBride,
} from "../../../../../constantes/symbols/SymbolsServicesS06";
import {
  BUTTON_GROUP,
  SWITCH,
  TEXT,
  TEXTFIELD,
  TEXTFIELD_NUMBER,
  TEXTFIELD_SELECT,
} from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const S06_TITRE_EXTREMITE_IDENTIQUE = Symbol("s06_titre_extremite_identique");
const S06_TITRE_MANCHETTE_HAUT = Symbol("s06_titre_manchette_haut");
const S06_TITRE_MANCHETTE_IT1 = Symbol("s06_titre_manchette_it1");
const S06_TITRE_MANCHETTE_IT2 = Symbol("s06_titre_manchette_it2");

export const formulaireDimensionsExtremitesBassesS06 = (styles, t) => [
  {
    nom: S06_TITRE_EXTREMITE_IDENTIQUE,
    label: t.extremiteIdentique + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00170,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S06_EXTREMITE_IDENTIQUE,
    type: SWITCH,
    cssFontFamily: styles.cssFontFamily,
    cssSwitch: styles.cssSwitch,
    cssMarginFormSection: styles.sectionForm0Margin,
    false: t.non,
    true: t.oui,
  },
];

//-----------Deux fois le même formulaire - itération 1----------//
export const formulaireFixationExtremitesIt1 = (styles, t) => [
  {
    nom: S06_TITRE_MANCHETTE_IT1,
    label: t.sectionManchette + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0103_Q00300$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S06_TYPE_EXTREMITES_IT1,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: S06_STEP6_arrayTypeExtremites(t),
  },
];

export const formulaireFixationExtremitesIt2 = (styles, t) => [
  {
    nom: S06_TITRE_MANCHETTE_IT2,
    label: t.sectionManchette + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0103_Q00300$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S06_TYPE_EXTREMITES_IT2,
    type: BUTTON_GROUP,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionForm0Margin,
    donnees: S06_STEP6_arrayTypeExtremites(t),
  },
];

export const formulaireSectionDeLaManchetteIt1 = (styles, t, formik) => [
  {
    nom: S06_TITRE_MANCHETTE_IT1,
    label: t.sectionManchette + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00020$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  // {
  //   nom: MANCHETTE_IT1,
  //   type: BUTTON_GROUP,
  //   cssFontFamily: styles.cssFontFamily,
  //   cssMarginFormSection: styles.sectionForm0Margin,
  //   donnees: arraySectionExtremite(t),
  // },
  {
    nom: S06_MANCHETTE_LARGEUR_IT1,
    label: t.manchetteLargeur + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0103_Q00320$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: ALIGNEMENT_IT1,
    label: t.alignement + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: S06_STEP6_arrayAlignement(t),
    // textInformation: {
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
  {
    nom: S06_MANCHETTE_A_IT1,
    label: t.manchetteAInt,
    value: parseInt(formik.values[ALIGNEMENT_IT1.description]) === 1 ? formik.values[S06_LONGUEUR_A_EXT.description] : formik.values[S06_LONGUEUR_A_INT],
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    disabled: true,
    textInformation: {
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S06_MANCHETTE_B_IT1,
    label: t.manchetteBInt,
    value: (formik.values[ALIGNEMENT_IT1.description] === S06_STEP6_arrayAlignement(t)[1].code) ? formik.values[S06_LONGUEUR_B_EXT.description] : formik.values[S06_LONGUEUR_B_INT.description],
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    disabled: true,
    textInformation: {
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaManchetteIt2 = (styles, t, formik) => [
  {
    nom: S06_TITRE_MANCHETTE_IT2,
    label: t.sectionManchette + t.asterisque,
    type: TEXT,
    cssFontFamily: styles.cssFontFamily,
    cssMarginFormSection: styles.sectionFormMarginT,
    textInformation: {
      text: t.SQ0012_Q00020$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S06_MANCHETTE_LARGEUR_IT2,
    label: t.manchetteLargeur + t.asterisque,
    type: TEXTFIELD_NUMBER,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    textInformation: {
      text: t.SQ0103_Q00320$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: ALIGNEMENT_IT2,
    label: t.alignement + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: S06_STEP6_arrayAlignement(t),
    // textInformation: {
    //   cssIcon: styles.helperTextIcon,
    //   // images: getImageInformation(),
    // },
  },
  {
    nom: S06_MANCHETTE_A_IT2,
    label: t.manchetteAInt,
    value: parseInt(formik.values[ALIGNEMENT_IT2.description]) === 0 ? formik.values[S06_LONGUEUR_A_INT.description] : formik.values[S06_LONGUEUR_A_EXT.description],
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    disabled: true,
    textInformation: {
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: S06_MANCHETTE_B_IT2,
    label: t.manchetteBInt,
    value: parseInt(formik.values[ALIGNEMENT_IT2.description]) === 0 ? formik.values[S06_LONGUEUR_B_INT.description] : formik.values[S06_LONGUEUR_B_EXT.description],
    type: TEXTFIELD,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionForm0Margin,
    disabled: true,
    textInformation: {
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaBrideIt1 = (styles, t, formik) => [
  {
    nom: S06_BRIDE_IT1,
    label: t.brideTypeS06 + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: S06_STEP6_arrayTypeBride(t),
    textInformation: {
      text: t.SQ0103_Q00040$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_EXTREMITE_INSEREE_MATIERE_IT1,
    label: t.brideExtremiteInsereeMatiere + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: S06_STEP6_arrayExtremiteInsereeMatiere(t),
    textInformation: {
      text: t.SQ0103_Q00160$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_FIXATION_IT1,
    label: t.brideFixation + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: formik.values[S06_BRIDE_IT1.description] === 0
      ? (formik.values[BRIDE_EXTREMITE_INSEREE_MATIERE_IT1.description] === 1
        ? S06_STEP6_arrayFixationBrideTypeAEtAcier(t)
        : S06_STEP6_arrayFixationBrideTypeA(t))
      : S06_STEP6_arrayFixationBride(t),
    textInformation: {
      text: t.SQ0103_Q00110$1,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

export const formulaireSectionDeLaBrideIt2 = (styles, t, formik) => [
  {
    nom: S06_BRIDE_IT2,
    label: t.brideTypeS06 + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: S06_STEP6_arrayTypeBride(t),
    textInformation: {
      text: t.SQ0103_Q00040$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_EXTREMITE_INSEREE_MATIERE_IT2,
    label: t.brideExtremiteInsereeMatiere + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: S06_STEP6_arrayExtremiteInsereeMatiere(t),
    textInformation: {
      text: t.SQ0103_Q00160$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
  {
    nom: BRIDE_FIXATION_IT2,
    label: t.brideFixation + t.asterisque,
    type: TEXTFIELD_SELECT,
    cssTextfield: styles.cssTextfield,
    cssMarginFormSection: styles.sectionFormMarginT,
    donnees: formik.values[S06_BRIDE_IT2.description] === 0
      ? (formik.values[BRIDE_EXTREMITE_INSEREE_MATIERE_IT2.description] === 1
        ? S06_STEP6_arrayFixationBrideTypeAEtAcier(t)
        : S06_STEP6_arrayFixationBrideTypeA(t))
      : S06_STEP6_arrayFixationBride(t),
    textInformation: {
      text: t.SQ0103_Q00110$2,
      cssIcon: styles.helperTextIcon,
      // images: getImageInformation(),
    },
  },
];

