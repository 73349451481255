import { styled } from "@mui/system";

export const StyledSpanHeader = styled("span")({
  margin: "1em",
  "& .MuiOutlinedInput-root": {
    color: "#F8F9FF",
  },
  "& .MuiSvgIcon-root": {
    color: "#F8F9FF",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "0px",
  },
});

export const StyledSpanHeaderECActive = styled("span")({
  color: "#EC8812",
  textAlign: "center",
  fontFamily: "Dosis",
  fontSize: "1rem",
  fontWeight: 800,
  margin: "1em",
  '@media (max-width: 768px)': { // Adaptation pour tablette et mobile moyen
    fontSize: '0.8rem',
    margin: '0.5em',
  },
  '@media (max-width: 600px)': { // Adaptation pour mobile très petit
    fontSize: '0.7rem',
    margin: '0.3em',
  },
});

export const StyledSpanHeaderEC = styled("span")({
  color: "white",
  textAlign: "center",
  fontFamily: "Dosis",
  fontSize: "1rem",
  margin: "1em",
  '@media (max-width: 768px)': { // Adaptation pour tablette et mobile moyen
    fontSize: '0.8rem',
    margin: '0.5em',
  },
  '@media (max-width: 600px)': { // Adaptation pour mobile très petit
    fontSize: '0.7rem',
    margin: '0.3em',
  },
});

export const Container = styled("div")({
  margin: "1em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  '@media (max-width: 768px)': { // Adaptation pour tablette et mobile moyen
    flexDirection: 'column',
    margin: '0.5em',
  },
  '@media (max-width: 600px)': { // Adaptation pour mobile très petit
    flexDirection: 'column',
    margin: '0.3em',
  },
});



export const StyledSpanDescriptionDropzone = styled("span")({
  color: "#2A375C",
});

export const StyledSpanSubtitlesRecap = styled("span")({
  fontSize: "1.25em",
  fontWeight: "600",
  width: "60%",
  "@media (max-width: 992px)": {
    width: "80%",
  },
});

export const StyledSpanTitleStep = styled("span")({
  paddingLeft: "1.1em",
});

export const StyledSpanIconStep = styled("span")({
  paddingRight: "1.1em",
});

export const StyledSpanTitleVueDetailEC = styled("span")({
  fontFamily: "Dosis",
  fontSize: "1.5rem",
  fontWeight: "600",
  lineHeight: "120%",
});

export const StyledContentVueDetailsEC = styled("span")({
  fontFamily: "Cabin",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "120%",
});
