import { Grid } from "@mui/material";
import {
  StyledDivImageLabel,
  StyledDivOverlay,
} from "../../../styledComponents/StyledDiv";
import { CheckImageDisplayForRadioButton } from "../commonMethods/CommonMethods";

const genererChampImagesGridSelectable = (champ, formik) => {
  return champ?.donnees.map((gridItem) => {
    return (
      <Grid
        item
        key={gridItem.code + "Grid"}
        xs={4}
        style={{ minWidth: "145px" }}
        onClick={() => {
          let tmp = [];
          if (!formik.values[champ.nom.description].includes(gridItem.code)) {
            tmp = [...formik.values[champ.nom.description], gridItem.code];
          } else {
            tmp = [...formik.values[champ.nom.description]];
            tmp.splice(
              formik.values[champ.nom.description].indexOf(gridItem.code),
              1
            );
          }
          formik.setFieldValue(champ.nom.description, tmp);
        }}
      >
        <div
          className={
            formik.values[champ.nom.description].includes(
              gridItem.code.description
                ? gridItem.code.description
                : gridItem.code
            )
              ? champ.cssSurroundGridItem
              : null
          }
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <CheckImageDisplayForRadioButton
            img={gridItem.img}
            images={champ.images}
            style={{
              maxWidth: "100%",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          />
          <StyledDivImageLabel className={champ.cssFontFamily}>
            {gridItem.name}
          </StyledDivImageLabel>
          <StyledDivOverlay
            style={
              formik.values[champ.nom.description].includes(
                gridItem.code.description
                  ? gridItem.code.description
                  : gridItem.code
              )
                ? null
                : { backgroundColor: "rgba(231,234,255,0.5)" }
            }
          />
        </div>
      </Grid>
    );
  });
};

export { genererChampImagesGridSelectable };
