import { makeStyles } from "@mui/styles";
const couleur = "#CC007B";
const transparentBackgroundColor = "#CC007B54";
const fontFamily = "Abel";
export const useStylesMoov = makeStyles({
  transparentBackgroundColor: {
    backgroundColor: transparentBackgroundColor,
  },

  circularProgressColor: {
    color: couleur,
  },

  cssButton: {
    backgroundColor: couleur,
    "&:hover": {
      backgroundColor: couleur,
    },
  },

  cssButtonPrec: {
    borderColor: couleur,
    color: couleur,
    "&:hover": {
      borderColor: couleur,
      color: couleur,
    },
  },

  cssFontFamily: {
    fontFamily: fontFamily,
    "& textarea": {
      fontFamily: fontFamily,
    },
    "& .MuiTypography-root": {
      fontFamily: fontFamily,
    },
    "& span": {
      fontFamily: fontFamily,
    },
  },

  activeStepTitle: {
    backgroundColor: couleur,
  },

  cssSwitch: {
    "& .MuiSwitch-switchBase": {
      color: couleur,
      "&:hover": {
        backgroundColor: "rgba(250,229,242,0.15)",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: couleur,
      "&:hover": {
        backgroundColor: "rgba(250,229,242,0.15)",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#ffbde5",
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#ffbde5",
    },
  },

  cssRadioPhoto: {
    "&.Mui-checked": {
      color: couleur + "!important",
    },
  },

  cssTextfield: {
    "& label": {
      fontFamily: fontFamily,
    },
    "& label.Mui-focused": {
      fontFamily: fontFamily,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: couleur,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: couleur,
      },
      "&:hover fieldset": {
        borderColor: couleur,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      fontSize: "1em",
    },
  },

  subtitleExtremites: {
    color: couleur,
    fontFamily: fontFamily,
    borderTopColor: couleur,
  },

  cssH2Home: {
    borderBottom: "1px solid " + couleur,
  },

  underlineH1: {
    borderColor: couleur,
  },

  sousTitreRecap: {
    borderBottom: "1px solid " + couleur,
  },

  cssCheckboxAccessoires: {
    "&.Mui-checked": {
      color: couleur,
    },
  },

  previousStepsTitle: {
    backgroundColor: "#FAE5F2",
  },

  iconeRecap: {
    color: couleur,
    marginLeft: "1em",
  },

  cssPContact: {
    fontFamily: fontFamily,
    color: couleur,
  },

  filigraneRecap: {
    backgroundImage: "url(/frontend/src/images/moovprotect/LogoFiligrane.jpg)",
  },

  cssTypeProtec: {
    "& span": {
      color: couleur,
    },
  },
  cssSurbrillanceTextDessinTechnique: {
    fill: couleur,
  },

  cssSurbrillanceFlecheDessinTechnique: {
    stroke: couleur,
    strokeWidth: 0.6,
  },

  cssSurroundGridItem: {
    border: "0.18em solid " + couleur + "!important",
  },

  logo: {
    padding: "1em 2em 1em 2em",
    maxHeight: "3.5em!important",
    width: "auto!important",
    cursor: "pointer",
  },

  logoFiligrane: {
    top: "-1500px",
    left: "-640px",
    right: "0px",
    backgroundSize: "18%",
    height: "250%",
    width: "300%",
    opacity: "8%",
    "@media (max-width: 992px)": {
      top: "-2000px",
      left: "-500px",
      width: "400%",
      transform: "rotate(-45deg)",
      backgroundSize: "20%",
    },
    "@media (max-width: 576px)": {
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      backgroundSize: "100%",
      transform: "rotate(-0deg)",
    },
  },

  logoFiligraneDessin: {
    top: "-300px",
    left: "-100px",
    backgroundSize: "25%",
    /* opacity:'8%',
        '@media (max-width: 950px)': {
            top:'-135px',
            left:'-75px', 
        } */
  },

  sousTitreExtremitesRecap: {
    fontFamily: fontFamily,
    color: couleur,
  },

  couleurPath: {
    stroke: couleur,
  },

  couleurRect: {
    stroke: couleur,
    fill: couleur,
  },

  couleurDotted: {
    stroke: couleur,
  },

  rectFill: {
    fill: couleur,
  },

  markerSelected: {
    fill: couleur,
  },

  extremiteColor: {
    stroke: couleur,
  },
  couleurEcaille: {
    stroke: "#067F36",
    strokeWidth:1.5,
  },
  couleurFondSOS: {
    fill: couleur,
  },
});
