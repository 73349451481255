import React, { useEffect, useState } from "react";
import { StyledGridLignesTabRoseEC } from "../../styledComponents/StyledGridBackoffice";
import { ArticleItem } from "./ArticleItem";
import { useSelector } from "react-redux";
import { StyledDivPagination } from "../../styledComponents/StyledDivBackoffice";
import { CircularProgress, Pagination } from "@mui/material";

export const ListeArticle = ({ actions, state }) => {
  const { familleArticleList } = useSelector((state) => state.espaceClient);

  const [searchBarContentCodeClient, setSearchBarContentCodeClient] =
    useState();
  const [searchBarContentCodeFPS, setSearchBarContentCodeFPS] = useState();
  const [searchBarContentNomArticle, setSearchBarContentNomArticle] =
    useState();
  const [searchBarContentModeleMachine, setSearchBarContentModeleMachine] =
    useState();
  const [
    searchBarContentEmplacementPiece,
    setSearchBarContentEmplacementPiece,
  ] = useState();
  const [searchBarContentFamille, setSearchBarContentFamille] = useState();

  const [sortedDate, setSortedDate] = useState("down");
  const [sortedCodeClient, setSortedCodeClient] = useState();
  const [sortedCodeFPS, setSortedCodeFPS] = useState();
  const [sortedNomArticle, setSortedNomArticle] = useState();
  const [sortedModeleMachine, setSortedModeleMachine] = useState();
  const [sortedFamille, setSortedFamille] = useState();
  const [sortedEmplacementPiece, setSortedEmplacementPiece] = useState();

  const { articleList } = useSelector((state) => state.espaceClient);
  const [filteredList, setFilteredList] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  const defineFilteredList = (searchBarContent, key) => {
    return articleList.filter((item) => {
      if (searchBarContent === undefined) {
        return item;
      } else {
        if (item[key].toLowerCase().includes(searchBarContent.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      }
    });
  };

  const defineFilteredFamilleCode = (searchBarContent) => {
    return articleList.filter((item) => {
      if (!searchBarContent) {
        return item;
      } else {
        const familleCode = item["familleCode"];
        const familleArticle = familleArticleList.find(
          (famille) => famille.code === familleCode
        );

        if (familleArticle) {
          const keys = ["FR", "EN", "DE", "IT", "ES"];

          for (let key of keys) {
            if (
              familleArticle[key] &&
              familleArticle[key]
                .toLowerCase()
                .includes(searchBarContent.toLowerCase())
            ) {
              return item;
            }
          }
        }
        return null;
      }
    });
  };

  const defineSortedList = (sortedDownOrUp, key) => {
    if (sortedDownOrUp === "up") {
      setFilteredList(
        [...articleList].sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    }
    if (sortedDownOrUp === "down") {
      setFilteredList(
        [...articleList].sort((a, b) => (b[key] > a[key] ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    if (!articleList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentCodeClient, "articleReferenceClient")
    );
  }, [searchBarContentCodeClient, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentCodeFPS, "articleCode"));
  }, [searchBarContentCodeFPS, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentNomArticle, "articleDesignation")
    );
  }, [searchBarContentNomArticle, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    setFilteredList(defineFilteredFamilleCode(searchBarContentFamille));
  }, [searchBarContentFamille, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentModeleMachine, "modeleMachine")
    );
  }, [searchBarContentModeleMachine, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentEmplacementPiece, "emplacementPiece")
    );
  }, [searchBarContentEmplacementPiece, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    defineSortedList(sortedCodeClient, "articleReferenceClient");
  }, [sortedCodeClient, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    defineSortedList(sortedCodeFPS, "articleCode");
  }, [sortedCodeFPS, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    defineSortedList(sortedNomArticle, "articleDesignation");
  }, [sortedNomArticle, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    defineSortedList(sortedFamille, "familleCode");
  }, [sortedFamille, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    defineSortedList(sortedModeleMachine, "modeleMachine");
  }, [sortedModeleMachine, articleList]);

  useEffect(() => {
    if (!articleList) {
      return;
    }
    defineSortedList(sortedEmplacementPiece, "emplacementPiece");
  }, [sortedEmplacementPiece, articleList]);

  useEffect(() => {
    if (!filteredList) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const handlePageClick = (event, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const afficherListeArticle = pagination.currentData.map((item) => {
    return (
      <ArticleItem
        key={item.articleId}
        id={item.articleId}
        state={state}
        {...item}
        searchBarAction={{
          setSearchBarContentCodeClient,
          setSearchBarContentCodeFPS,
          setSearchBarContentNomArticle,
          setSearchBarContentModeleMachine,
          setSearchBarContentEmplacementPiece,
          setSearchBarContentFamille,
        }}
        sortAction={{
          setSortedDate,
          setSortedCodeClient,
          setSortedCodeFPS,
          setSortedNomArticle,
          setSortedModeleMachine,
          setSortedFamille,
          setSortedEmplacementPiece,
        }}
        modale={actions}
      />
    );
  });

  return (
    <div>
      <StyledGridLignesTabRoseEC
        container
        style={{
          boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        }}
      >
        <ArticleItem
          key={0}
          id={0}
          action={state.t.titleAction}
          articleReferenceClient={state.t.titleCodeClient}
          articleCode={state.t.titleCodeFPS}
          articleDesignation={state.t.titleNomArticle}
          familleCode={state.t.titleFamille}
          modeleMachine={state.t.titleModeleMachine}
          emplacementPiece={state.t.emplacementPiece}
          nbOffrePrix={state.t.titleOffrePrix}
          nbCommande={state.t.titleCommande}
          articleId={"articleId"}
          articleEtatValidation={"articleEtatValidation"}
          state={state}
          searchBarAction={{
            setSearchBarContentCodeClient,
            setSearchBarContentCodeFPS,
            setSearchBarContentNomArticle,
            setSearchBarContentModeleMachine,
            setSearchBarContentEmplacementPiece,
            setSearchBarContentFamille,
          }}
          sortAction={{
            setSortedDate,
            setSortedCodeClient,
            setSortedCodeFPS,
            setSortedNomArticle,
            setSortedModeleMachine,
            setSortedFamille,
            setSortedEmplacementPiece,
          }}
          modale={actions}
        />
        {articleList.length === 0 ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1rem",
            }}
          >
            <CircularProgress style={{ color: "#2A375C" }} />
          </div>
        ) : (
          afficherListeArticle
        )}
      </StyledGridLignesTabRoseEC>
      <StyledDivPagination>
        <Pagination
          size="large"
          count={pagination.pageCount}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={0}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPagination>
    </div>
  );
};
