import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Routes, Route, useLocation } from "react-router-dom";

// Vues
import { Accueil } from "../redux/vue/Accueil";
import { Profil } from "../redux/vue/Profil";
import { Authentification } from "../redux/vue/Authentification";
import { AjouterUtilisateur } from "../redux/vue/controllerFormulaires/AjouterUtilisateur";
import { ModifierCollaborateur } from "../redux/vue/controllerFormulaires/ModifierCollaborateur";
import { ModifierSonProfil } from "../redux/vue/controllerFormulaires/ModifierSonProfil";

// Logique d'authentification
import { AuthentificationRoute } from "./component(HOC)/AuthentificationRoute";

// Constantes
import { GuestRoutes } from "../constantes/routes/GuestRoutes";
import { CollaborateurRoutes } from "../constantes/routes/CollaborateurRoutes";
import { AdminRoutes } from "../constantes/routes/AdminRoutes";
import { Permissions } from "../constantes/routes/Permissions";
import { GestionDesComptes } from "../redux/vue/GestionDesComptes";
import { Contact } from "../redux/vue/Contact";
import { Rechercher } from "../redux/vue/Rechercher";
import { Configurateur } from "../redux/vue/formulaires/Configurateur";
import { PolitiqueDeConfidentialite } from "../redux/vue/PolitiqueDeConfidentialite";
import { MentionsLegales } from "../redux/vue/MentionsLegales";
import { MainWrapper } from "./component(HOC)/MainWrapper";
import { EmployeeWrapper } from "./component(HOC)/EmployeeWrapper";
import { DemandeDePrix } from "../redux/vue/DemandeDePrix";
import { OffreDePrix } from "../redux/vue/OffreDePrix";
import { Article } from "../redux/vue/Article";
import { ContactSOS } from "../redux/vue/ContactSOS";
import { ClientWrapper } from "./component(HOC)/ClientWrapper";
import { ClientRoutes } from "../constantes/routes/ClientRoutes";
import { AccueilEC } from "../redux/vue/espaceClient/AccueilEC";
import { VueArticle } from "../redux/vue/espaceClient/vueArticle/VueArticle";
import { VueOffre } from "../redux/vue/espaceClient/vueOffre/VueOffre";
import { VueCommande } from "../redux/vue/espaceClient/vueCommande/VueCommande";
import { VueContact } from "../redux/vue/espaceClient/vueContact/VueContact";
import { VueNC } from "../redux/vue/espaceClient/vueNC/VueNC";
import { VueService } from "../redux/vue/espaceClient/vueService/VueService";
import { AuthentificationEC } from "../redux/vue/espaceClient/AuthentificationEC";
import { AuthentificationECRoute } from "./component(HOC)/AuthentificationECRoute";

export const App = () => {
  const location = useLocation();

  const [trackingId, setTrackingId] = useState();
  const [urlHistory, setUrlHistory] = useState([]);

  useEffect(() => {
    setUrlHistory([...urlHistory, location]);
  }, [location]);

  useEffect(() => {
    if (!trackingId) {
      return;
    }
    ReactGA.initialize([
      {
        trackingId: trackingId,
        gaOptions: {
          siteSpeedSampleRate: 100,
        },
      },
    ]);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [trackingId, location]);

  return (
    <Routes>
      <Route path="/*" element={<MainWrapper setTrackingId={setTrackingId} />}>
        {/* public routes */}
        <Route index element={<Accueil />} />
        <Route
          path={GuestRoutes.MENTIONS_LEGALES}
          element={<MentionsLegales />}
        />
        <Route path={GuestRoutes.NOUS_CONTACTER} element={<Contact />} />
        <Route path={GuestRoutes.SOS} element={<ContactSOS />} />
        <Route path={GuestRoutes.RECHERCHER} element={<Rechercher />} />
        <Route path={GuestRoutes.CONFIGURATEUR} element={<Configurateur />} />
        <Route
          path={GuestRoutes.CONFIGURATEUR + "/:famille"}
          element={<Configurateur />}
        />
        <Route
          path={GuestRoutes.CONFIGURATEUR + "/:value"}
          element={<Configurateur />}
        />
        <Route
          path={GuestRoutes.POLITIQUE_DE_CONFIDENTIALITE}
          element={<PolitiqueDeConfidentialite />}
        />
        <Route path={GuestRoutes.VUE_ARTICLE} element={<Article />} />
      </Route>

      <Route path="/*" element={<EmployeeWrapper />}>
        {/* protected routes collaborateur minimum level */}
        <Route
          element={
            <AuthentificationRoute
              permissions={[Permissions.COLLABORATEUR, Permissions.ADMIN]}
            />
          }
        >
          <Route path={CollaborateurRoutes.PROFIL} element={<Profil />} />
          <Route
            path={CollaborateurRoutes.MODIFIER_SON_PROFIL}
            element={<ModifierSonProfil />}
          />
          <Route path={CollaborateurRoutes.DDP} element={<DemandeDePrix />} />
          <Route path={CollaborateurRoutes.ODP} element={<OffreDePrix />} />
          <Route path={CollaborateurRoutes.ART} element={<Article />} />
        </Route>

        {/* protected routes admin only */}
        <Route
          element={<AuthentificationRoute permissions={[Permissions.ADMIN]} />}
        >
          <Route
            path={AdminRoutes.INSCRIPTION}
            element={<AjouterUtilisateur />}
          />
          <Route
            path={AdminRoutes.GESTION_COMPTES}
            element={<GestionDesComptes />}
          />
          <Route
            path={AdminRoutes.MODIFIER_COLLABORATEUR + "/:id"}
            element={<ModifierCollaborateur />}
          />
        </Route>
      </Route>

      <Route path="/*">
        <Route
          path={GuestRoutes.AUTHENTIFICATION}
          element={<Authentification history={urlHistory} />}
        />
      </Route>
      <Route path="/EspaceClient/*">
        <Route
          path={ClientRoutes.AUTHENTIFICATION}
          element={<AuthentificationEC history={urlHistory} />}
        />

        {/* Nouvelle route pour l'authentification avec login et password */}
        <Route
          path={ClientRoutes.AUTHENTIFICATION + "/:login/:password"}
          element={<AuthentificationEC history={urlHistory} />}
        />
      </Route>

      <Route element={<ClientWrapper setTrackingId={setTrackingId} />}>
        {/* protected routes minimum level */}
        <Route element={<AuthentificationECRoute />}>
          <Route path="/EspaceClient/" element={<AccueilEC />} />
          <Route path="/EspaceClient/VueArticle/" element={<VueArticle />} />
          <Route path="/EspaceClient/VueOffre/" element={<VueOffre />} />
          <Route path="/EspaceClient/VueCommande/" element={<VueCommande />} />
          <Route path="/EspaceClient/VueContact/" element={<VueContact />} />
          <Route path="/EspaceClient/VueNC/" element={<VueNC />} />
          <Route path="/EspaceClient/VueService/" element={<VueService />} />
        </Route>
      </Route>
    </Routes>
  );
};
