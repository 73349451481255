import clsx from "clsx";

import { useStylesRC } from "../redux/vue/css/RC";
import { useStyles } from "../redux/vue/css/General";
import { useStylesMoov } from "../redux/vue/css/Moov";

export const GetStyle = (booleanIdentite) => {
  const rc = useStylesRC();
  const moov = useStylesMoov();
  const custom = booleanIdentite ? moov : rc;
  const general = useStyles();

  return {
    cssButton: clsx(custom.cssButton),
    cssButtonPrec: clsx(custom.cssButtonPrec),
    activeStepTitle: clsx(custom.activeStepTitle),

    cssFontFamily: clsx(custom.cssFontFamily),
    labelQuestionConfirmation: clsx(general.labelQuestionConfirmation),
    logoWaiting: clsx(
      custom.circularProgressColor,
      custom.transparentBackgroundColor
    ),

    messageConfirmation: clsx(
      custom.cssFontFamily,
      general.messageConfirmation
    ),
    messageCssQuestionnaire: clsx(general.messageCssQuestionnaire),
    cssTextNewDemande: clsx(custom.cssFontFamily, general.cssTextNewDemande),
    cssSwitch: clsx(custom.cssSwitch),

    sectionFormMarginB: clsx(general.sectionFormMarginB),
    sectionFormMarginT: clsx(general.sectionFormMarginT),
    sectionFormMarginTB: clsx(general.sectionFormMarginTB),
    sectionFormMarginDescr: clsx(general.sectionFormMarginDescr),

    sectionForm0Margin: clsx(general.sectionForm0Margin),
    sectionFormMarginRechercher: clsx(general.sectionFormMarginRechercher),
    sectionFormSansBg: clsx(general.sectionFormSansBg),
    sectionFormSansBg0Margin: clsx(general.sectionFormSansBg0Margin),
    noSectionForm: clsx(general.noSectionForm),

    cssTextfieldQuantiEtCode: clsx(
      general.cssTextfieldQuantiEtCode,
      custom.cssTextfield
    ),
    cssTextfield: clsx(custom.cssTextfield),
    subtitleExtremites: clsx(custom.subtitleExtremites),

    cssRadioPhoto: clsx(custom.cssRadioPhoto),
    cssH2Home: clsx(custom.cssH2Home),
    underlineH1: clsx(custom.underlineH1),

    sousTitreRecap: clsx(custom.sousTitreRecap),
    cssTypeProtec: clsx(custom.cssTypeProtec),
    cssCenter: clsx(general.cssCenter),
    radioElementExtremites: clsx(general.radioElementExtremites),

    cssCheckboxAccessoires: clsx(custom.cssCheckboxAccessoires),
    previousStepsTitle: clsx(custom.previousStepsTitle),
    iconeRecap: clsx(custom.iconeRecap),

    cssSurroundGridItem: clsx(
      general.cssSurroundGridItem,
      custom.cssSurroundGridItem
    ),
    logo: clsx(custom.logo),
    logoFiligrane: clsx(custom.logoFiligrane),
    logoFiligraneEC: clsx(custom.logoFiligraneEC),

    logoFiligraneDessin: clsx(custom.logoFiligraneDessin),
    cssLangue: clsx(general.cssLangue),
    cssPContact: clsx(custom.cssPContact),

    cssOuRechercher: clsx(general.cssOuRechercher),
    filigraneRecap: clsx(custom.filigraneRecap),
    filigraneDessin: clsx(general.filigraneDessin, custom.filigraneRecap),

    sousTitreExtremitesRecap: clsx(custom.sousTitreExtremitesRecap),
    cssPReactualisez: clsx(general.cssPReactualisez, custom.cssFontFamily),
    gridRadioExtremites: clsx(general.gridRadioExtremites),
    gridRadioExtremitesInRow: clsx(general.gridRadioExtremitesInRow),

    path: clsx(general.path),
    couleurChartePath: clsx(custom.couleurPath),
    couleurCharteRect: clsx(custom.couleurRect),
    dotted: clsx(custom.couleurDotted),
    couleurEcaille: clsx(custom.couleurEcaille),
    rectFill: clsx(custom.rectFill),
    extremiteColor: clsx(custom.extremiteColor),

    /* symetrieTrace1:clsx(general.symetrieTrace1),
        dimension:clsx(general.dimension),
        symetrieTrace2:clsx(general.symetrieTrace2), */

    svg: clsx(general.svg),
    cssSurbrillanceTextDessinTechnique: clsx(
      custom.cssSurbrillanceTextDessinTechnique
    ),

    cssSurbrillanceFlecheDessinTechnique: clsx(
      custom.cssSurbrillanceFlecheDessinTechnique
    ),
    markerSelected: clsx(custom.markerSelected),
    loadingDiv: clsx(custom.transparentBackgroundColor),

    cssLastStep: clsx(general.cssLastStep),
    radioColumn: clsx(general.radioColumn),
    descriptionDropzoneEtape4: clsx(general.descriptionDropzoneEtape4),
    descriptionInDiv: clsx(general.descriptionInDiv),

    helperTextIcon: clsx(general.helperTextIcon),
    helperTextImages: clsx(general.helperTextImages),

    cssSOS: clsx(custom.couleurFondSOS),

    cardContainer: clsx(general.cardContainer),
    publiciteCard: clsx(general.publiciteCard),
    commandeCard: clsx(general.commandeCard),
    offreCard: clsx(general.offreCard),
    commercialCard: clsx(general.commercialCard),
    articleCard: clsx(general.articleCard),
    singleArticleCard: clsx(general.singleArticleCard),
    articlesContainer: clsx(general.articlesContainer),
    logoMoovEC: clsx(general.logoMoovEC),
    cssBoutonRetourEC: clsx(general.cssBoutonRetour),
    cardWrapper: clsx(general.cardWrapper),
    card: clsx(general.card),
    whoIamCard: clsx(general.whoIamCard),
    desktopImage: clsx(general.desktopImage),
    mobileImage: clsx(general.mobileImage),
    textContainer: clsx(general.textContainer),
    mobileImageConfigure: clsx(general.mobileImageConfigure),
    ncCard: clsx(general.ncCard),
    equipeCard: clsx(general.equipeCard),
    serviceCard: clsx(general.serviceCard),
    jeConfigureCard: clsx(general.jeConfigureCard),
    cssGridItem: clsx(general.cssGridItem),
    commandeContainer: clsx(general.commandeContainer),
    singleCommandeCard: clsx(general.singleCommandeCard),
    offreContainer: clsx(general.offreContainer),
    singleOffreCard: clsx(general.singleOffreCard),
  };
};
