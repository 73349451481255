import { equalsCodeOrLabel } from "../../../constantes/symbols/HelperSymbolsServices";
import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../constantes/symbols/SymbolsCommon";
import {
  SECTION_SOUFFLET,
  TYPE_CORPS,
} from "../../../constantes/symbols/SymbolsS01";
import { TYPE_SECTION } from "../../../constantes/symbols/SymbolsS04";
import { arrayProductFamilly } from "../../../constantes/symbols/SymbolsServicesCommon";
import {
  arraySectionSoufflet,
  arrayTypeCorps,
} from "../../../constantes/symbols/SymbolsServicesS01";
import { RectangulaireDroit } from "../DessinTechnique/RectangulaireDroit";
import { RondConique } from "../DessinTechnique/RondConique";
import { RondDroit } from "../DessinTechnique/RondDroit";
import { FormeConstruction } from "../DessinTechnique/S04/FormeConstruction";
import { FormeConstructionFace } from "../DessinTechnique/S04/FormeConstructionFace";
import { Enrouleur } from "../DessinTechnique/S05/Enrouleur";
import { FormeConstructionS06Face } from "../DessinTechnique/S06/FormeConstructionS06Face";
import { TableElevatrice } from "../DessinTechnique/S06/TableElevatrice";
import { FormeFaceS08 } from "../DessinTechnique/S08/FormeFaceS08";
import { ProtecteurTelescopiqueProfil } from "../DessinTechnique/S08/ProtecteurTelescopiqueProfil";

export const DessinTechnique = ({ etape, formik, styles, t, isFrom }) => {
  const isSouffletPlisse = equalsCodeOrLabel(
    formik[FAMILLE_PROTECTION_INDUSTRIELLE.description],
    arrayProductFamilly(),
    2
  );

  const isEnrouleur = equalsCodeOrLabel(
    formik[FAMILLE_PROTECTION_INDUSTRIELLE.description],
    arrayProductFamilly(),
    4
  );

  const isProtecteurTelescopique = equalsCodeOrLabel(
    formik[FAMILLE_PROTECTION_INDUSTRIELLE.description],
    arrayProductFamilly(),
    5
  );

  const isTableElevatrice = equalsCodeOrLabel(
    formik[FAMILLE_PROTECTION_INDUSTRIELLE.description],
    arrayProductFamilly(),
    3
  );

  switch (true) {
    case isSouffletPlisse:
      return renderS04(styles, formik, etape, t);
    case isEnrouleur:
      return renderS05(styles, formik, t);
    case isProtecteurTelescopique:
      return renderS08(styles, formik, etape, t);
    case isTableElevatrice:
      return renderS06(styles, formik, etape, t, isFrom);
    default:
      return renderS01(styles, formik, t);
  }
};

const renderS01 = (styles, formik, t) => {
  const section = formik[SECTION_SOUFFLET.description] || "";
  const typeCorps = formik[TYPE_CORPS.description] || "";
  const isSectionSouffletRond = equalsCodeOrLabel(
    section,
    arraySectionSoufflet(),
    0
  );
  const isSectionSouffletRectangulaire = equalsCodeOrLabel(
    section,
    arraySectionSoufflet(),
    1
  );
  const isTypeCorpsDroit = equalsCodeOrLabel(typeCorps, arrayTypeCorps(), 0);
  const isTypeCorpsConique = equalsCodeOrLabel(typeCorps, arrayTypeCorps(), 1);

  switch (true) {
    case isSectionSouffletRectangulaire && isTypeCorpsDroit:
      return <RectangulaireDroit styles={styles} formik={formik} t={t} />;
    case isSectionSouffletRond && isTypeCorpsDroit:
      return <RondDroit styles={styles} formik={formik} t={t} />;
    case isSectionSouffletRond && isTypeCorpsConique:
      return <RondConique styles={styles} formik={formik} />;
    default:
      return null;
  }
};

const renderS04 = (styles, formik, etape, t) => {
  if (formik[TYPE_SECTION.description] && (etape === 4 || etape === 7)) {
    return (
      <>
        <FormeConstructionFace styles={styles} formik={formik} t={t} />
      </>
    );
  }
  if (etape !== 4) {
    return <FormeConstruction styles={styles} formik={formik} />;
  }
  return null;
};

const renderS05 = (styles, formik, t) => {
  return <Enrouleur styles={styles} formik={formik} t={t} />;
};

const renderS08 = (styles, formik, etape, t) => {
  if (etape === 5) {
    return <FormeFaceS08 styles={styles} formik={formik} t={t} />;
  }
  if (etape !== 5) {
    return <ProtecteurTelescopiqueProfil styles={styles} formik={formik} />;
  }
};

const renderS06 = (styles, formik, etape, t, isFrom) => {
  if (etape === 5) {
    return (
      <>
        <FormeConstructionS06Face styles={styles} formik={formik} t={t} />
      </>
    );
  }
  if (etape !== 5) {
    return <TableElevatrice styles={styles} formik={formik} t={t} isFrom={isFrom}/>;
  }
  return null;
};
