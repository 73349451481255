import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../../../../constantes/symbols/SymbolsServicesCommon";
import { validFamilleProtectionIndustrielle } from "../../ChampsFormulaires/S01/etape1/ValidFamilleProtectionIndustrielle";
import { validInformationsMachine } from "../../ChampsFormulaires/S01/etape2/ValidInformationsMachine";
import { validAgressions } from "../../ChampsFormulaires/S01/etape3/ValidEnvironnementMachine";
import { validDimensionsCorps } from "../../ChampsFormulaires/S01/etape4/ValidDimensionsCorps";
import { validDimensionsExtremites } from "../../ChampsFormulaires/S01/etape5/ValidDimensionsExtremites";
import { validAccessoires } from "../../ChampsFormulaires/S01/etape6/ValidAccessoires";
import { validInformationsComplementaires } from "../../ChampsFormulaires/S01/etape7/ValidInformationsComplementaires";
import { validCoordonnees } from "../../ChampsFormulaires/S01/etape8/ValidCoordonnees";
import { validConstruction } from "../../ChampsFormulaires/S04/etape4/ValidConstruction";
import { validDimensionsCorpsS04 } from "../../ChampsFormulaires/S04/etape5/ValidDimensionsCorpsS04";
import { validDimensionsExtremitesS04 } from "../../ChampsFormulaires/S04/etape6/ValidDimensionsExtremitesS04";
import { validAccessoiresS04 } from "../../ChampsFormulaires/S04/etape7/ValidAccessoiresS04";
import { validConstructionS06 } from "../../ChampsFormulaires/S04_06/etape4/ValidConstructionS06";
import { validDimensionsCorpsS06 } from "../../ChampsFormulaires/S04_06/etape5/ValidDimensionsCorpsS06";
import { validDimensionsExtremitesS06 } from "../../ChampsFormulaires/S04_06/etape6/ValidDimensionsExtremitesS06";
import { validAccessoiresS06 } from "../../ChampsFormulaires/S04_06/etape7/ValidAccessoiresS06";
import { validConstructionS05 } from "../../ChampsFormulaires/S05/etape4/ValidConstructionS05";
import { validDimensionsS05 } from "../../ChampsFormulaires/S05/etape5/ValidDimensionsS05";
import { validExtremitesS05 } from "../../ChampsFormulaires/S05/etape6/ValidExtremitesS05";
import { validMatiereS08 } from "../../ChampsFormulaires/S08/etape4/ValidMatiereS08";
import { validFormeS08 } from "../../ChampsFormulaires/S08/etape5/ValidFormeS08";
import { validDimensionsS08 } from "../../ChampsFormulaires/S08/etape6/ValidDimensionsS08";
import { validExtremitesS08 } from "../../ChampsFormulaires/S08/etape7/ValidExtremitesS08";
import { validS08Accessoires } from "../../ChampsFormulaires/S08/etape8/ValidS08Accessoires";

export const validationProvider = (formulary, props, etape) => {
  switch (formulary[FAMILLE_PROTECTION_INDUSTRIELLE.description]) {
    case arrayProductFamilly()[2].code:
      return commonValidation(formulary, props, etape).concat(
        validationS04(props, etape)
      );
    case arrayProductFamilly()[4].code:
      return commonValidation(formulary, props, etape).concat(
        validationS05(props, etape)
      );
    case arrayProductFamilly()[5].code:
      return commonValidation(formulary, props, etape).concat(
        validationS08(props, etape)
      );
    case arrayProductFamilly()[3].code:
      return commonValidation(formulary, props, etape).concat(
        validationS06(props, etape, formulary)
      );
    default:
      return commonValidation(formulary, props, etape).concat(
        validationS01(props, etape)
      );
  }
};

const commonValidation = (
  formulary,
  { t, upToDateSecteursList, upToDateFonctionsList, upToDatePaysList, upToDateTypeMachineList },
  etape
) => {
  return validFamilleProtectionIndustrielle(t)
    .concat(validInformationsMachine(formulary, t, etape))
    .concat(validAgressions(t, formulary, etape))
    .concat(validInformationsComplementaires(t, formulary, etape))
    .concat(
      validCoordonnees(
        upToDateSecteursList,
        upToDateFonctionsList,
        upToDatePaysList,
        t,
        formulary,
        etape
      )
    );
};

const validationS01 = ({ t }, etape) => {
  return validDimensionsCorps(t, etape)
    .concat(validDimensionsExtremites(t, etape))
    .concat(validAccessoires(t, etape));
};

const validationS04 = ({ t }, etape) => {
  return validConstruction(t, etape)
    .concat(validDimensionsCorpsS04(t, etape))
    .concat(validDimensionsExtremitesS04(t, etape))
    .concat(validAccessoiresS04(t, etape));
};

const validationS05 = ({ t }, etape) => {
  return validConstructionS05(t, etape)
    .concat(validDimensionsS05(t, etape))
    .concat(validExtremitesS05(t, etape));
};

const validationS06 = ({ t }, etape, formulary) => {
  return validConstructionS06(t, etape)
    .concat(validDimensionsCorpsS06(t, etape))
    .concat(validDimensionsExtremitesS06(t, etape, formulary))
    .concat(validAccessoiresS06(t, etape));
};

const validationS08 = ({ t }, etape) => {
  return validMatiereS08(t, etape)
    .concat(validFormeS08(t, etape))
    .concat(validDimensionsS08(t, etape))
    .concat(validExtremitesS08(t, etape))
    .concat(validS08Accessoires(t, etape));
};
