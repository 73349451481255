import {
  ALIGNEMENT_IT1,
  ALIGNEMENT_IT2,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT1,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT2,
  BRIDE_FIXATION_IT1,
  BRIDE_FIXATION_IT2,
  S06_BRIDE_IT1,
  S06_BRIDE_IT2,
  S06_MANCHETTE_A_IT1,
  S06_MANCHETTE_A_IT2,
  S06_MANCHETTE_B_IT1,
  S06_MANCHETTE_B_IT2,
  S06_MANCHETTE_LARGEUR_IT1,
  S06_MANCHETTE_LARGEUR_IT2,
} from "./SymbolsS06";

const S06_arrayRemoveManchetteIt1 = () => [
  S06_MANCHETTE_LARGEUR_IT1.description,
  ALIGNEMENT_IT1.description,
  S06_MANCHETTE_A_IT1.description,
  S06_MANCHETTE_B_IT1.description,
];

const S06_arrayRemoveManchetteIt2 = () => [
  S06_MANCHETTE_LARGEUR_IT2.description,
  ALIGNEMENT_IT2.description,
  S06_MANCHETTE_A_IT2.description,
  S06_MANCHETTE_B_IT2.description,
];

const S06_arrayRemoveBrideIt1 = () => [
  S06_BRIDE_IT1.description,
  S06_BRIDE_IT1.description,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT1.description,
  BRIDE_FIXATION_IT1.description,
];

const S06_arrayRemoveBrideIt2 = () => [
  S06_BRIDE_IT2.description,
  BRIDE_EXTREMITE_INSEREE_MATIERE_IT2.description,
  BRIDE_FIXATION_IT2.description,
];

export {
  S06_arrayRemoveManchetteIt1,
  S06_arrayRemoveManchetteIt2,
  S06_arrayRemoveBrideIt1,
  S06_arrayRemoveBrideIt2,
};
