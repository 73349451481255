import {
  ADRESSE_MAIL,
  AXE_EMPLACEMENT,
  CODE_POSTAL,
  COMMENTAIRE,
  ENVIRONNEMENT,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  FONCTION,
  MARQUE_MACHINE,
  MODELE_MACHINE,
  NOM,
  NUMERO_DE_PLAN,
  PAYS,
  POSITION_DE_TRAVAIL,
  PRENOM,
  QUANTITEE_SOUHAITEE,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
  TEMPERATURE,
  TYPE_DE_MACHINE,
  VITESSE_DEPLACEMENT,
} from "../../../../../constantes/symbols/SymbolsCommon";
import { ALIGNEMENT_IT1, ALIGNEMENT_IT2, BRIDE_EXTREMITE_INSEREE_MATIERE_IT1, BRIDE_EXTREMITE_INSEREE_MATIERE_IT2, BRIDE_FIXATION_IT1, BRIDE_FIXATION_IT2, DIMENSIONS_PLIS, NOMBRE_FACE, OUVETRURE, S06_ACCESSOIRE, S06_BRIDE_IT1, S06_BRIDE_IT2, S06_EXTREMITE_IDENTIQUE, S06_TYPE_EXTREMITES_IT1, S06_TYPE_EXTREMITES_IT2, S06_LONGUEUR_A_EXT, S06_LONGUEUR_A_INT, S06_LONGUEUR_B_EXT, S06_LONGUEUR_B_INT, S06_LONGUEUR_COMPRIMEE, S06_LONGUEUR_C_EXT, S06_LONGUEUR_C_INT, S06_LONGUEUR_DEPLOYEE, S06_MANCHETTE_A_IT1, S06_MANCHETTE_A_IT2, S06_MANCHETTE_B_IT1, S06_MANCHETTE_B_IT2, S06_MANCHETTE_LARGEUR_IT1, S06_MANCHETTE_LARGEUR_IT2, S06_TOILE } from "../../../../../constantes/symbols/SymbolsS06";
import { RECAP_S06 } from "../../../components/generateFormulary/types/TypeFieldsFormulary";
import { CODE_ARTICLE } from "../../RechercherChamps";
import {
  FICHIERS,
  FICHIER_RECAPITULATIF,
} from "../../S01/recapitulatif/ChampsRecapitulatif";

export const formulaireRecapitulatifTypeProtectionS06 = (t) => [
  {
    nom: FAMILLE_PROTECTION_INDUSTRIELLE,
    label: t.champTypeDeProtection,
    type: RECAP_S06,
  },
];

export const formulaireConstructionS06 = (t) => [
  {
    nom: S06_LONGUEUR_DEPLOYEE,
    label: t.champLongueurDeployee,
    type: RECAP_S06,
  },
  {
    nom: S06_LONGUEUR_COMPRIMEE,
    label: t.champLongueurComprimee,
    type: RECAP_S06,
  },
  {
    nom: S06_TOILE,
    label: t.toile,
    type: RECAP_S06,
  },
];

export const formulaireDimensionsCorpsS06 = (t) => [
  {
    nom: NOMBRE_FACE,
    label: t.nombreFaces,
    type: RECAP_S06,
  },
  {
    nom: DIMENSIONS_PLIS,
    label: t.dimensionPlis,
    type: RECAP_S06,
  },
  {
    nom: S06_LONGUEUR_A_INT,
    label: t.longueurAInt,
    type: RECAP_S06,
  },
  {
    nom: S06_LONGUEUR_B_INT,
    label: t.longueurBInt,
    type: RECAP_S06,
  },
  {
    nom: S06_LONGUEUR_C_INT,
    label: t.longueurCInt,
    type: RECAP_S06,
  },
  {
    nom: S06_LONGUEUR_A_EXT,
    label: t.longueurAExt,
    type: RECAP_S06,
  },
  {
    nom: S06_LONGUEUR_B_EXT,
    label: t.longueurBExt,
    type: RECAP_S06,
  },
  {
    nom: S06_LONGUEUR_C_EXT,
    label: t.longueurCExt,
    type: RECAP_S06,
  },
];

export const formulaireExtremitesIdentiquesS06 = (t) => [
  {
    nom: S06_EXTREMITE_IDENTIQUE,
    label: t.champExtremitesIdentiques,
    type: RECAP_S06,
  },
];

export const formulaireExtremiteS06It2 = (t) => [
  {
    nom: S06_TYPE_EXTREMITES_IT2,
    label: t.champTypeExtremite,
    type: RECAP_S06,
  },
  {
    nom: S06_MANCHETTE_LARGEUR_IT2,
    label: t.manchetteLargeur,
    type: RECAP_S06,
  },
  {
    nom: ALIGNEMENT_IT2,
    label: t.alignement,
    type: RECAP_S06,
  },
  {
    nom: S06_MANCHETTE_A_IT2,
    label: t.manchetteAInt,
    type: RECAP_S06,
  },
  {
    nom: S06_MANCHETTE_B_IT2,
    label: t.manchetteBInt,
    type: RECAP_S06,
  },
  {
    nom: S06_BRIDE_IT2,
    label: t.brideTypeS06,
    type: RECAP_S06,
  },
  {
    nom: BRIDE_EXTREMITE_INSEREE_MATIERE_IT2,
    label: t.brideExtremiteInsereeMatiere,
    type: RECAP_S06,
  },
  {
    nom: BRIDE_FIXATION_IT2,
    label: t.brideFixation,
    type: RECAP_S06,
  },
];

export const formulaireExtremiteS06It1 = (t) => [
  {
    nom: S06_TYPE_EXTREMITES_IT1,
    label: t.champTypeExtremite,
    type: RECAP_S06,
  },
  {
    nom: S06_MANCHETTE_LARGEUR_IT1,
    label: t.manchetteLargeur,
    type: RECAP_S06,
  },
  {
    nom: ALIGNEMENT_IT1,
    label: t.alignement,
    type: RECAP_S06,
  },
  {
    nom: S06_MANCHETTE_A_IT1,
    label: t.manchetteAInt,
    type: RECAP_S06,
  },
  {
    nom: S06_MANCHETTE_B_IT1,
    label: t.manchetteBInt,
    type: RECAP_S06,
  },
  {
    nom: S06_BRIDE_IT1,
    label: t.brideTypeS06,
    type: RECAP_S06,
  },
  {
    nom: BRIDE_EXTREMITE_INSEREE_MATIERE_IT1,
    label: t.brideExtremiteInsereeMatiere,
    type: RECAP_S06,
  },
  {
    nom: BRIDE_FIXATION_IT1,
    label: t.brideFixation,
    type: RECAP_S06,
  },
];

export const formulaireAccessoiresS06 = (t) => [
  {
    nom: OUVETRURE,
    label: t.ouvertureS06,
    type: RECAP_S06,
  },
];

export const formulaireInformationsMachine = (
  t,
  serviceTypeMachine,
  serviceMarqueMachine
) => [
  {
    nom: TYPE_DE_MACHINE,
    label: t.champTypeDeMachine,
    type: RECAP_S06,
    service: serviceTypeMachine,
  },
  {
    nom: MARQUE_MACHINE,
    label: t.champMarqueDeMachine,
    type: RECAP_S06,
    service: serviceMarqueMachine,
  },
  {
    nom: MODELE_MACHINE,
    label: t.champModeleDeMachine,
    type: RECAP_S06,
  },
  {
    nom: AXE_EMPLACEMENT,
    label: t.champAxeEmplacement,
    type: RECAP_S06,
  },
  {
    nom: POSITION_DE_TRAVAIL,
    label: t.champPositionDeTravail,
    type: RECAP_S06,
  },
  {
    nom: NUMERO_DE_PLAN,
    label: t.champNDePlan,
    type: RECAP_S06,
  },
  {
    nom: CODE_ARTICLE,
    label: t.champCodeArticle,
    type: RECAP_S06,
  },
];

export const formulaireAggressions = (t, agressionsList) => [
  {
    nom: TEMPERATURE,
    label: t.champTemperature,
    type: RECAP_S06,
  },
  {
    nom: ENVIRONNEMENT,
    label: t.champEnvironnement,
    type: RECAP_S06,
    nomChamps: agressionsList,
  },
];

export const formulaireQuantités = () => [
  {
    nom: QUANTITEE_SOUHAITEE,
    type: RECAP_S06,
  },
];

export const formulaireCoordonnees = (
  t,
  upToDatePaysList,
  upToDateSecteursList,
  upToDateFonctionsList
) => [
  {
    nom: SOCIETE,
    label: t.champSociete,
    type: RECAP_S06,
  },
  {
    nom: PAYS,
    label: t.champPays,
    type: RECAP_S06,
    service: upToDatePaysList,
  },
  {
    nom: CODE_POSTAL,
    label: t.champCodePostal,
    type: RECAP_S06,
  },
  {
    nom: SECTEUR,
    label: t.champSecteurActivite,
    type: RECAP_S06,
    service: upToDateSecteursList,
  },
  {
    nom: NOM,
    label: t.champNom,
    type: RECAP_S06,
  },
  {
    nom: PRENOM,
    label: t.champPrenom,
    type: RECAP_S06,
  },
  {
    nom: FONCTION,
    label: t.champFonction,
    type: RECAP_S06,
    service: upToDateFonctionsList,
  },
  {
    nom: ADRESSE_MAIL,
    label: t.champAdresseMail,
    type: RECAP_S06,
  },
  {
    nom: TELEPHONE,
    label: t.champTelephone,
    type: RECAP_S06,
  },
];

export const formulaireCommentaire = (t, booleanIdentite) => [
  {
    nom: COMMENTAIRE,
    label: t.champCommentaire,
    type: RECAP_S06,
  },
];

export const formulaireFichiers = () => [
  {
    nom: FICHIERS,
    type: RECAP_S06,
  },
];

export const formulaireFichiersRecapitulatif = () => [
  {
    nom: FICHIER_RECAPITULATIF,
    type: RECAP_S06,
  },
];