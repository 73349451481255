import { LARGEUR_TOILE, L_MAX, MAXIMUM_ENROULE, SECTION_MAX_A, SECTION_MAX_B, SECTION_MAX_C } from "../../../../../constantes/symbols/SymbolsS05";
import { TEXTFIELD_NUMBER } from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const FOCUSED = Symbol('focused');

export{
    FOCUSED,
}

export const formulaireDimensionsS05 = (styles, t) =>
    [
        {
            nom: L_MAX,
            label: t.longueurDeployee+t.asterisque,
            type: TEXTFIELD_NUMBER,
            cssTextfield: styles.cssTextfield,
            cssMarginFormSection: styles.sectionForm0Margin,
            textInformation: {
                text: t.SQ0077_Q00020,
                cssIcon: styles.helperTextIcon,
                // images: getImageInformation(),
              },

        },
        {
            nom: LARGEUR_TOILE,
            label: t.largeurToile+t.asterisque,
            type: TEXTFIELD_NUMBER,
            cssTextfield: styles.cssTextfield,
            cssMarginFormSection: styles.sectionFormMarginB,
            textInformation: {
                text: t.SQ0077_Q00030,
                cssIcon: styles.helperTextIcon,
                // images: getImageInformation(),
              },
        },
    ];

export const formulaireDimensionsCaisson = (styles, t) =>
    [
        {
            nom: SECTION_MAX_A,
            label: t.sectionMaxA,
            type: TEXTFIELD_NUMBER,
            cssTextfield: styles.cssTextfield,
            cssMarginFormSection: styles.sectionForm0Margin,
            textInformation: {
                text: t.SQ0077_Q00060,
                cssIcon: styles.helperTextIcon,
                // images: getImageInformation(),
              },

        },
        {
            nom: SECTION_MAX_B,
            label: t.sectionMaxB,
            type: TEXTFIELD_NUMBER,
            cssTextfield: styles.cssTextfield,
            cssMarginFormSection: styles.sectionForm0Margin,
            textInformation: {
                text: t.SQ0077_Q00060,
                cssIcon: styles.helperTextIcon,
                // images: getImageInformation(),
              },
        },
        // {
        //     nom: SECTION_MAX_C,
        //     label: t.sectionMaxC,
        //     type: TEXTFIELD_NUMBER,
        //     cssTextfield: styles.cssTextfield,
        //     cssMarginFormSection: styles.sectionFormMarginB,
        // },
    ];

export const formulaireDimensionsAutre = (styles, t) =>
    [
        {
            nom: MAXIMUM_ENROULE,
            label: t.maxEnroule,
            type: TEXTFIELD_NUMBER,
            cssTextfield: styles.cssTextfield,
            cssMarginFormSection: styles.sectionForm0Margin,
            textInformation: {
                text: t.SQ0077_Q00040,
                cssIcon: styles.helperTextIcon,
                // images: getImageInformation(),
              },

        },
    ];
    
