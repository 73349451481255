import { ENVELOPPE_MATIERE } from "../../../../../constantes/symbols/SymbolsS08";
import { initChampsFormikToJsonDefaultString } from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
	ENVELOPPE_MATIERE
];

export const initialValuesMatiereS08 = (formulaire) => {
    return Object.assign({
        ...initChampsFormikToJsonDefaultString(champsString, formulaire),
    }, {});
}