import * as Yup from "yup";
import { OUVETRURE } from "../../../../../constantes/symbols/SymbolsS06";
import { S06_STEP7_arrayAccessories } from "../../../../../constantes/symbols/SymbolsServicesS06";

/* A Yup validation schema. */
export const validAccessoiresS06 = (t, etape) => {
  if (etape !== 7) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [OUVETRURE.description]: Yup.mixed()
      .test(
        "TypeSelectionInSelect",
        () => {
          return t.erreurSelectionType;
        },
        (value) =>
          S06_STEP7_arrayAccessories(t).some((e) => e.code === parseInt(value))
      )
      .required(t.erreurSelectionType),
  });
};
