import * as Yup from "yup";

export const validModifierCollaborateur = (t, roleList) =>
  Yup.object({
    prenom: Yup.string(),
    nom: Yup.string(),
    email: Yup.string(),
    motDePasse: Yup.string(),
    role: Yup.mixed()
      .test(
        "roleInRoleList",
        () => {
          return "La valeur selectionnée n'est pas dans la liste";
        },
        (value) => roleList.some((e) => parseInt(e.code) === parseInt(value))
      )
      .required(t.erreurSelectionType),
  });
