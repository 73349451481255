import {
  CAP,
  QUANTITEE_SOUHAITEE_CODE_ARTICLE,
} from "../redux/vue/ChampsFormulaires/RechercherChamps";
import { ARTICLES } from "../redux/vue/ChampsFormulaires/RechercherChamps";
import {
  AGRESSIONS,
  ENVIRONNEMENT,
  QUANTITEE_SOUHAITEE,
  //AUTRE,
  FAMILLE_PROTECTION_INDUSTRIELLE,
  TEMPERATURE,
} from "../constantes/symbols/SymbolsCommon";
import { S05_STEP4_arrayTypeEnrouleur } from "../constantes/symbols/SymbolsServicesS05";
import {
  MAXIMUM_ENROULE,
  SECTION_MAX,
  SECTION_MAX_A,
  SECTION_MAX_B,
  //SECTION_MAX_C,
  TYPE_ENROULEUR,
} from "../constantes/symbols/SymbolsS05";
import {
  arrayProductFamilly,
  arrayEnvironnement,
} from "../constantes/symbols/SymbolsServicesCommon";
import {
  equalsCodeOrLabel,
  notEqualsCodeAndLabel,
} from "../constantes/symbols/HelperSymbolsServices";

export const dataCleanerS05 = (payload) => {
  //ETAPE3
  payload = removeForEnvAutre(payload);
  payload = removeTemperature(payload);

  //ETAPE4
  payload = addSectionMaximaleCaisson(payload);

  //Etape5
  // payload = removeChampDimensionsS05(payload);
  payload = removeSectionMaxDimensionsS05(payload);
  
  payload = removeMaximumEnrouleDimensionS05(payload);

  //ETAPE 7
  payload = removeQteToDontKeep(payload);
  payload = removeEmptyQte(payload);

  return payload;
};

const addSectionMaximaleCaisson = (payload) => {
  if (
    payload[SECTION_MAX_A.description] !== undefined &&
    payload[SECTION_MAX_B.description] !== undefined 
    // && payload[SECTION_MAX_C.description] !== undefined
  ) {
    return {
      ...payload,
      [SECTION_MAX.description]: [
        payload[SECTION_MAX_A.description],
        payload[SECTION_MAX_B.description],
        // payload[SECTION_MAX_C.description],
      ].join("X"),
    };
  } else if (
    equalsCodeOrLabel(
      payload[TYPE_ENROULEUR.description],
      S05_STEP4_arrayTypeEnrouleur(),
      0
    ) &&
    payload[SECTION_MAX.description] !== undefined &&
    payload[SECTION_MAX.description].includes("X")
  ) {
    const splittedSectionMaxCaisson =
      payload[SECTION_MAX.description].split("X");
    return {
      ...payload,
      [SECTION_MAX_A.description]: splittedSectionMaxCaisson[0],
      [SECTION_MAX_B.description]: splittedSectionMaxCaisson[1],
      // [SECTION_MAX_C.description]: splittedSectionMaxCaisson[2],
    };
  }
  return payload;
};

const removeSectionMaxDimensionsS05 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[TYPE_ENROULEUR.description],
      S05_STEP4_arrayTypeEnrouleur(),
      1
    ) ||
    equalsCodeOrLabel(
      payload[TYPE_ENROULEUR.description],
      S05_STEP4_arrayTypeEnrouleur(),
      2
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (
            element[0] === SECTION_MAX_A.description ||
            element[0] === SECTION_MAX_B.description ||
            // element[0] === SECTION_MAX_C.description ||
            element[0] === SECTION_MAX.description
          ) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeMaximumEnrouleDimensionS05 = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[TYPE_ENROULEUR.description],
      S05_STEP4_arrayTypeEnrouleur(),
      0
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] === MAXIMUM_ENROULE.description) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }

  return payload;
};

const removeForEnvAutre = (payload) => {
  if (
    notEqualsCodeAndLabel(
      payload[ENVIRONNEMENT.description],
      arrayEnvironnement(),
      3
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload).map((element) => {
        if (element[0] === AGRESSIONS.description) {
          return [[AGRESSIONS.description], []];
        }
        return element;
      })
    );
  }
  return payload;
};

const removeTemperature = (payload) => {
  if (
    equalsCodeOrLabel(
      payload[FAMILLE_PROTECTION_INDUSTRIELLE.description],
      arrayProductFamilly(),
      2
    )
  ) {
    payload = Object.fromEntries(
      Object.entries(payload)
        .map((element) => {
          if (element[0] === TEMPERATURE.description) {
            return null;
          }
          return element;
        })
        .filter((element) => element)
    );
  }
  return payload;
};

const removeQteToDontKeep = (payload) => {
  if (payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.slice(
          0,
          payload?.nbFieldToKeep
        ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};

const removeEmptyQte = (payload) => {
  if (payload?.champQuantite !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [QUANTITEE_SOUHAITEE.description]: payload.champQuantite.filter(Number),
      },
      {}
    );
  }
  return payload;
};

export const removeCodeAndQteToDontKeepOrEmpty = (payload) => {
  if (payload?.articles !== undefined && payload?.nbFieldToKeep !== undefined) {
    payload = Object.assign(
      {
        ...payload,
        [ARTICLES.description]: payload.articles
          .slice(0, payload.nbFieldToKeep)
          .filter(
            (value) =>
              value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description] !== "" &&
              value[CAP.description] !== "" &&
              !isNaN(value[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description])
          ),
      },
      {}
    );
    delete payload.nbFieldToKeep;
  }
  return payload;
};
