import * as Yup from "yup";
import {
  FAMILLE_PROTECTION_INDUSTRIELLE,
  FICHIERS_SUPP,
  QUANTITEE_SOUHAITEE,
} from "../../../../../constantes/symbols/SymbolsCommon";
import { trimFileName } from "../../../components/DropzoneContact";
import { controllerMenuStep } from "../../../formulaires/ControllerMenuStep";

/* Setting the maximum size of a file to 10MB. */
const FILE_SIZE = 10000000;
/* A constant that contains the list of supported formats. */
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/bmp",
  "image/png",
];

/* A Yup validation schema. */
export const validInformationsComplementaires = (t, formulary, etape) => {
  if (
    etape !==
    controllerMenuStep(
      formulary[FAMILLE_PROTECTION_INDUSTRIELLE.description],
      t
    ).length -
      2
  ) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [QUANTITEE_SOUHAITEE.description]: Yup.array().of(
      Yup.number().positive().min(1, t.quantiteSupA0)
    ),
    [FICHIERS_SUPP.description]: Yup.mixed()
      .test(
        "fileSize",
        (fileList) => {
          return (
            t.erreurFichierVolumineux +
            getNameFilesTooBig(fileList.originalValue).map((file) => {
              return trimFileName(file.name) + "\n";
            })
          );
        },
        (fileList) => {
          return getNameFilesTooBig(fileList).length === 0;
        }
      )
      .test(
        "fileCount",
        (fileList) => {
          return t.erreurFichierMaxAtteint + fileList.length;
        },
        (fileList) => {
          return fileList.length <= 10;
        }
      )
      .test(
        "fileFormat",
        (fileList) => {
          return (
            t.erreurFormatFichier +
            getNameFileWrongFormat(fileList.originalValue).map((file) => {
              return trimFileName(file.name) + "\n";
            })
          );
        },
        (fileList) => {
          return getNameFileWrongFormat(fileList).length === 0;
        }
      ),
  });
};

/**
 * Given a list of files, return a list of files that are too big
 * @param listFiles - the list of files to be filtered
 * @returns An array of objects.
 */
const getNameFilesTooBig = (listFiles) => {
  return listFiles.filter((file) => file.size > FILE_SIZE);
};

/**
 * Given a list of files, return a list of files that are not of a supported format
 * @param listFiles - The list of files to be filtered.
 * @returns An array of files that are not supported.
 */
const getNameFileWrongFormat = (listFiles) => {
  return listFiles.filter((file) => !SUPPORTED_FORMATS.includes(file.type));
};
