import { S06_TOILE } from "../../../../../constantes/symbols/SymbolsS06";
import {
  initChampsFormikToJsonDefaultString,
} from "../../../../../helper/InitialisationChampsFormik";

const champsString = [
  S06_TOILE,
];

export const initialValuesConstructionS06 = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultString(champsString, formulaire),
    },
    {}
  );
};
