import axios from "axios";
import { Serveur } from "../../constantes/services/Serveur";
import { Authentification } from "../../constantes/services/Authentification";
import { LocalStorage } from "../../constantes/globalName/LocalStorage";

const connexionEC = async (clientCode, clientPassword) => {
  const LOGIN_ENDPOINT = Serveur.URL + Authentification.CONNEXION_EC;
  const data = {
    clientCode,
    clientPassword,
  };
  const reponse = await axios.post(LOGIN_ENDPOINT, data);
  if (reponse.data.accessTokenEC) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN_EC, reponse.data.accessTokenEC);
  }
  return reponse;
};

const connexion = async (email, motDePasse) => {
  const LOGIN_ENDPOINT = Serveur.URL + Authentification.CONNEXION;
  const data = {
    email,
    motDePasse,
  };
  const reponse = await axios.post(LOGIN_ENDPOINT, data);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  }
  return reponse;
};

const rafraichissement = async (data) => {
  const REFRESH_ENDPOINT = Serveur.URL + Authentification.RAFRAICHISSEMENT;
  const reponse = await axios.post(REFRESH_ENDPOINT, data);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }
  return reponse;
};

const rafraichissementEC = async (data) => {
  const REFRESH_ENDPOINT = Serveur.URL + Authentification.RAFRAICHISSEMENT_EC;
  const reponse = await axios.post(REFRESH_ENDPOINT, data);
  
  if (reponse.data.accessTokenEC) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN_EC, reponse.data.accessTokenEC);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN_EC);
  }
  return reponse;
};

const deconnexion = () => {
  localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
};

const deconnexionEC = () => {
  localStorage.removeItem(LocalStorage.ACCESS_TOKEN_EC);
  localStorage.removeItem("clientId");
}

const authentificationService = {
  connexion,
  rafraichissement,
  deconnexion,
  connexionEC,
  rafraichissementEC,
  deconnexionEC,
};

export default authentificationService;
