import { Checkbox } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCheckboxWithoutAnimation = styled(Checkbox)({
    pointerEvents: "none",
});

export const StyledCheckbox = styled(Checkbox)({
    '&.Mui-checked': {
        color: '#2A375C',
      },
});