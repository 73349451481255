import * as Yup from "yup";
import { ENVELOPPE_MATIERE } from "../../../../../constantes/symbols/SymbolsS08";
import { S08_STEP4_arrayMatiere } from "../../../../../constantes/symbols/SymbolsServicesS08";

/* A Yup validation schema. */
export const validMatiereS08 = (t, etape) => {
  if (etape !== 4) {
    return Yup.object().shape({});
  }
  return Yup.object().shape({
    [ENVELOPPE_MATIERE.description]: Yup.mixed()
      .test(
        "typeEnrouleurInSelect",
        () => {
          return t.erreurSelectionType;
        },
        (value) =>
          S08_STEP4_arrayMatiere(t).some((e) => e.code === parseInt(value))
      )
      .required(t.erreurSelectionType),
  });
};
