import { styled } from "@mui/system";
import Sticky from "react-sticky-el";

export const StyledSticky = styled(Sticky)({
    backgroundColor: 'transparent',
    width: '55%',
    '@media (max-width: 992px)': {
        width: '46%',
    },
    '@media (max-width: 600px)': {
        display: 'none',
    },
});