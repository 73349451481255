import { arrayAccessoiresS08 } from "../../../../../constantes/symbols/SymbolsServicesS08";
import { initChampsFormikToJsonDefaultBoolean } from "../../../../../helper/InitialisationChampsFormik";

export const initialValuesS08Accessoires = (formulaire) => {
  return Object.assign(
    {
      ...initChampsFormikToJsonDefaultBoolean(
        arrayAccessoiresS08().map((element) => element.code),
        formulaire
      ),
    },
    {}
  );
};
