import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "@mui/material";
import { getAllReference } from "../model/slice/FormulaireReducer";
import { StyledDivPagination } from "./styledComponents/StyledDivBackoffice";
import { StyledGridLignesTab } from "./styledComponents/StyledGridBackoffice";
import { DdpItem } from "./DdpItem";
import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../constantes/symbols/SymbolsCommon";

export const ListeDdp = ({ actions, state }) => {
  const [searchBarContentNDDP, setSearchBarContentNDDP] = useState();
  const [searchBarContentMarque, setSearchBarContentMarque] = useState();
  const [searchBarContentFamille, setSearchBarContentFamille] = useState();
  const [searchBarContentSociete, setSearchBarContentSociete] = useState();
  const [searchBarContentNom, setSearchBarContentNom] = useState();
  const [searchBarContentPrenom, setSearchBarContentPrenom] = useState();
  const [searchBarContentDate, setSearchBarContentDate] = useState();
  const [searchBarContentOdp, setSearchBarContentOdp] = useState();
  const [searchBarContentPub, setSearchBarContentPub] = useState();

  const [sortedDate, setSortedDate] = useState("down");
  const [sortedMarque, setSortedMarque] = useState();
  const [sortedFamille, setSortedFamille] = useState();
  const [sortedSociete, setSortedSociete] = useState();
  const [sortedNom, setSortedNom] = useState();
  const [sortedPrenom, setSortedPrenom] = useState();
  const [sortedOdp, setSortedOdp] = useState();
  const [sortedPub, setSortedPub] = useState();

  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const { referenceList } = useSelector((state) => state.reference);
  const [filteredList, setFilteredList] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  useEffect(() => {
    dispatch(getAllReference());
  }, []);

  const defineFilteredList = (searchBarContent, key) => {
    return referenceList.filter((item) => {
      if (searchBarContent === undefined) {
        return item;
      } else {
        if (item[key].toLowerCase().includes(searchBarContent.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      }
    });
  };

  const defineSortedList = (sortedDownOrUp, key) => {
    if (sortedDownOrUp === "up") {
      setFilteredList(
        [...referenceList].sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    }
    if (sortedDownOrUp === "down") {
      setFilteredList(
        [...referenceList].sort((a, b) => (b[key] > a[key] ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentNDDP, "reference"));
  }, [searchBarContentNDDP, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentMarque, "marque"));
  }, [searchBarContentMarque, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    setFilteredList(
      defineFilteredList(
        searchBarContentFamille,
        FAMILLE_PROTECTION_INDUSTRIELLE.description
      )
    );
  }, [searchBarContentFamille, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentSociete, "societe"));
  }, [searchBarContentSociete, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentNom, "nom"));
  }, [searchBarContentNom, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentDate, "date"));
  }, [searchBarContentDate, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentPrenom, "prenom"));
  }, [searchBarContentPrenom, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentOdp, "odp"));
  }, [searchBarContentOdp, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentPub, "pub"));
  }, [searchBarContentPub, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    defineSortedList(sortedMarque, "marque");
  }, [sortedMarque, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    defineSortedList(
      sortedFamille,
      FAMILLE_PROTECTION_INDUSTRIELLE.description
    );
  }, [sortedFamille, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    defineSortedList(sortedSociete, "societe");
  }, [sortedSociete, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    defineSortedList(sortedNom, "nom");
  }, [sortedNom, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    defineSortedList(sortedPrenom, "prenom");
  }, [sortedPrenom, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    defineSortedList(sortedOdp, "odp");
  }, [sortedOdp, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    defineSortedList(sortedPub, "pub");
  }, [sortedPub, referenceList]);

  useEffect(() => {
    if (!referenceList) {
      return;
    }
    if (sortedDate === "up") {
      setFilteredList(
        [...referenceList].sort((a, b) => new Date(a.date) - new Date(b.date))
      );
    }
    if (sortedDate === "down") {
      setFilteredList(
        [...referenceList].sort((a, b) => new Date(b.date) - new Date(a.date))
      );
    }
  }, [sortedDate, referenceList]);

  useEffect(() => {
    if (!filteredList) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const afficherListeDdp = pagination.currentData.map((item) => {
    return (
      <DdpItem
        key={item.id}
        id={item.id}
        state={state}
        {...item}
        searchBarAction={{
          setSearchBarContentDate,
          setSearchBarContentFamille,
          setSearchBarContentMarque,
          setSearchBarContentSociete,
          setSearchBarContentNDDP,
          setSearchBarContentNom,
          setSearchBarContentPrenom,
          setSearchBarContentOdp,
          setSearchBarContentPub,
        }}
        sortAction={{
          setSortedDate,
          setSortedMarque,
          setSortedSociete,
          setSortedFamille,
          setSortedNom,
          setSortedPrenom,
          setSortedOdp,
          setSortedPub,
        }}
        modale={actions}
      />
    );
  });

  const handlePageClick = (event, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  return (
    <div>
      <StyledGridLignesTab container>
        <DdpItem
          key={0}
          id={0}
          reference={"NUMÉRO DE DDP"}
          marque={"ENTITÉ"}
          familleDeProtectionIndustrielle={"FAMILLE DE PRODUITS"}
          societe={"SOCIÉTÉ"}
          date={"DATE DE CRÉATION"}
          nom={"NOM"}
          prenom={"PRENOM"}
          odp={"O. D. P."}
          pub={"PUBLIEE"}
          action={"ACTION"}
          state={state}
          searchBarAction={{
            setSearchBarContentDate,
            setSearchBarContentFamille,
            setSearchBarContentMarque,
            setSearchBarContentSociete,
            setSearchBarContentNDDP,
            setSearchBarContentNom,
            setSearchBarContentPrenom,
            setSearchBarContentOdp,
            setSearchBarContentPub,
          }}
          sortAction={{
            setSortedDate,
            setSortedMarque,
            setSortedSociete,
            setSortedFamille,
            setSortedNom,
            setSortedPrenom,
            setSortedOdp,
            setSortedPub,
          }}
          modale={actions}
        />
        {afficherListeDdp}
      </StyledGridLignesTab>
      <StyledDivPagination>
        <Pagination
          size="large"
          count={pagination.pageCount}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={0}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPagination>
    </div>
  );
};
