/* Getter */
import { Stack, Switch, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { importAllSpec } from "../../helper/ImportAll";

/* Fonctionnal component */
import { LangSwitcher } from "../vue/components/LangSwitcher";

/* Styled components */
import { StyledHeaderHeader } from "./styledComponents/StyledHeader";
import { StyledImgHeader } from "./styledComponents/StyledImg";
import {
  Container,
  StyledSpanHeader,
  StyledSpanHeaderEC,
  StyledSpanHeaderECActive,
} from "./styledComponents/StyledSpan";
import {
  FONCTION,
  NOM,
  PAYS,
  PRENOM,
  SECTEUR,
  SOCIETE,
} from "./enum/champs/ChampsCommunFormulairesEnum";
import {
  ADRESSE_MAIL,
  CODE_POSTAL,
  TELEPHONE,
} from "../../constantes/symbols/SymbolsCommon";
import { LocalStorage } from "../../constantes/globalName/LocalStorage";
import { StringToRouteInNavigate } from "../../helper/StringParser";
import { ClientRoutes } from "../../constantes/routes/ClientRoutes";

export const HeaderInternaute = ({
  booleanIdentite,
  setBooleanIdentite,
  styles,
  isMobileFormat,
  t,
}) => {
  
  const navigate = useNavigate();

  const handleConfigurer = () => {
    // Définir les fausses valeurs dans le local storage
    localStorage.setItem(
      LocalStorage.INFORMATIONS_CLIENT,
      btoa(
        JSON.stringify({
          [SOCIETE.description]: "test",
          [PAYS.description]: "FR",
          [CODE_POSTAL.description]: "54000",
          [SECTEUR.description]: "",
          [NOM.description]: "test",
          [PRENOM.description]: "test",
          [FONCTION.description]: "test",
          [ADRESSE_MAIL.description]: "test@test.com",
          [TELEPHONE.description]: "0101010101",
        })
      )
    );

    const url = StringToRouteInNavigate(GuestRoutes.CONFIGURATEUR);
    window.open(url, "_blank");
  };

  const handleEspaceClient = () => {
    const url = StringToRouteInNavigate("EspaceClient");
    window.open(url, "_blank");
  };

  const handleAccueilClick = () => {
    navigate(GuestRoutes.ACCUEIL);
  };
  return (
    <StyledHeaderHeader>
      {!isMobileFormat ? (
        <Stack
          style={{
            margin: "1rem",
            spacing: 1,
            alignItems: "center",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <img
            src={importAllSpec(false)["LogoMobile.png"]}
            alt="Logo RC"
            style={{ cursor: "pointer" }}
            onClick={handleAccueilClick}
          />
        </Stack>
      ) : (
        <StyledImgHeader
          src={importAllSpec(booleanIdentite)["Logo.png"]}
          style={{ left: "0" }}
          className={styles.logo}
          onClick={handleAccueilClick}
        />
      )}

      <div
        style={{
          right: "0",
          position: "absolute",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <div style={{ display: "flex", margin: "1em" }}>
          <Container>
            <StyledSpanHeaderEC onClick={handleEspaceClient}>
            {t.espaceClient}
            </StyledSpanHeaderEC>
            <StyledSpanHeaderECActive onClick={handleConfigurer}>
            {t.configurateur}
            </StyledSpanHeaderECActive>
            {/* <LangSwitcher /> */}
          </Container>
          <LangSwitcher />
        </div>

        {/* <StyledSpanHeader style={{ 'display':"flex"}}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography key="falseCase" style={{'textTransform':'uppercase'}}>RC</Typography>
                        <Switch 
                            key={"SwitchStyle"}
                            name={"SwitchStyle"}
                            onChange={HandleChange}
                            checked={booleanIdentite}
                        />
                        <Typography key="trueCase" style={{'textTransform':'uppercase'}}>MOOV</Typography>
                    </Stack>
                </StyledSpanHeader> */}
      </div>
    </StyledHeaderHeader>
  );
};
