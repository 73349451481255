import { Modal, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { StyledButtonAnnulerRose, StyledButtonRose } from "../styledComponents/StyledButtonBackoffice"
import { StyledDivButtonValiderAnnuler } from "../styledComponents/StyledDivBackoffice"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: '24px 24px 24px',
    p: 4,
};

const ModalBackoffice = ({open, handleClose, message, handleNoModale, non, handleYesModale, oui}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                '.MuiTypography-root':{fontFamily: 'Cabin'},
                '.MuiBox-root': {
                    border: 'none',
                    '@media (max-width: 600px)': {width: '240px'},
                },
            }}
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                {message}
                </Typography>
                <StyledDivButtonValiderAnnuler className="modal">
                    <StyledButtonAnnulerRose onClick={handleNoModale}>{non}</StyledButtonAnnulerRose>
                    <StyledButtonRose className="ouiModal" onClick={handleYesModale}>{oui}</StyledButtonRose>
                </StyledDivButtonValiderAnnuler>
            </Box>
        </Modal>
    )
}

export {
    ModalBackoffice
}