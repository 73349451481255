import { Paper } from "@mui/material";
import { styled } from "@mui/system";

export const StyledPaperFooter = styled(Paper)({
    backgroundColor: '#2A375C',
    fontFamily: 'Dosis',
    color: 'white',
    fontSize: '0.9em',
    borderRadius: '0',
    margin: '0',
    padding: '1em 2em',
    '& span:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    '& a': {
        color: 'white',
    },
});

export const StyledPaperBody = styled(Paper)({
    backgroundColor: '#EEF0F9',
    borderRadius: '0',
});

export const StyledPaperBodyEC = styled(Paper)({
    backgroundColor: '#EEF0F9',
    borderRadius: "0px 8px 8px 8px"
});

export const StyledPaperBodyAccueil = styled(Paper)({
    backgroundColor: '#EEF0F9',
    borderRadius: '0',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
});

export const StyledPaperPolitiques = styled(Paper)({
    fontFamily: 'Dosis',
    padding: '3em',
    fontSize: '1.2em',
});

export const StyledPaperConfirmation = styled(Paper)({
    textAlign: 'center',
    minHeight: '72vh'
});