import { Grid } from "@mui/material"
import { StyledDivFormSection } from "../../../styledComponents/StyledDiv"
import { StyledSpanDescriptionDropzone } from "../../../styledComponents/StyledSpan"
import { DropzoneContact } from "../../DropzoneContact"
import { displayErrorForElementsDropzone } from "../commonMethods/CommonMethods"

/**
 * It creates a DropzoneContact component and passes the formik object to it.
 * @param champ - The field that we want to render.
 * @param formik - The formik object.
 * @returns A grid item with a DropzoneContact component.
 */
 const genererChampDropZone = (champ, formik) => {
    return (
        <StyledDivFormSection className={champ.cssMarginFormSection + ' ' + champ.cssPaddingTop} key={champ.nom.description}>
            <Grid item>
            <StyledSpanDescriptionDropzone className={champ.cssFontFamily}>{champ.description}</StyledSpanDescriptionDropzone>
                <DropzoneContact 
                    setFieldValue={formik.setFieldValue} 
                    getFieldValue={formik.values[champ.nom.description]}
                    label={champ.label}
                    fieldName={champ.nom.description}
                    className={champ.css}
                    styles={champ.styles}
                    textInformation={champ.textInformation}
                />
                {displayErrorForElementsDropzone(champ, formik)}
            </Grid>
        </StyledDivFormSection>
    )
}

export {
    genererChampDropZone
};