import { Grid, InputAdornment } from "@mui/material";
import {
  StyledDivFormSection,
  StyledDivTextAndTextfieldAlign,
} from "../../../styledComponents/StyledDiv";
import { StyledTextfield } from "../../../styledComponents/StyledTextfield";
import { HelperText } from "../../HelperText";
import { OUTLINED, ROW } from "../commonParameters/CommonParameters";

const genererChampStyledTextfield = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledTextfield
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label ? champ.label : ""}
          variant={OUTLINED.description}
          value={formik.values[champ.nom.description]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={champ.disabled ? champ.disabled : false}
          error={Boolean(
            formik.touched[champ.nom.description] &&
              formik.errors[champ.nom.description]
          )}
          helperText={
            formik.touched[champ.nom.description]
              ? formik.errors[champ.nom.description]
              : null
          }
          InputProps={{
            className: champ.textInformation?.cssIcon,
            endAdornment: champ.textInformation?.text ? (
              <InputAdornment position="end">
                <HelperText
                  name={champ.nom.description}
                  textInformation={champ.textInformation}
                />
              </InputAdornment>
            ) : null,
            startAdornment: null,
          }}
          size="small"
        />
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampStyledTextfieldReturnArray = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description + champ.positionInArray}
    >
      <Grid item>
        <StyledTextfield
          type={champ.dataType}
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label}
          variant={OUTLINED.description}
          value={formik.values[champ.nom.description][champ.positionInArray]}
          onChange={(event) => {
            const newArray = formik.values[champ.nom.description].map(
              (value, key) =>
                key === champ.positionInArray ? event.target.value : value
            );
            formik.setFieldValue(champ.nom.description, newArray);
          }}
          onBlur={formik.handleBlur}
          disabled={champ.disabled ? champ.disabled : false}
          error={Boolean(
            formik.touched[champ?.nom.description] &&
              formik.errors[champ?.nom.description] !== undefined &&
              formik.errors[champ?.nom.description][champ?.positionInArray]
          )}
          helperText={
            formik.touched[champ?.nom.description] &&
            formik.errors[champ?.nom.description] !== undefined &&
            formik.errors[champ?.nom.description][champ?.positionInArray]
              ? formik.errors[champ?.nom.description][champ?.positionInArray]
              : null
          }
          size="small"
        />
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampStyledTextfieldReturnArrayMulti = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description + champ.positionInArray}
    >
      <Grid item>
        <StyledTextfield
          type={champ.dataType}
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label}
          variant={OUTLINED.description}
          value={
            formik.values[champ.value.description][champ.positionInArray][
              champ.nom.description
            ]
          }
          onChange={(event) => {
            const newArray = formik.values[champ.value.description].map(
              (value, key) =>
                key === champ.positionInArray
                  ? { ...value, [champ.nom.description]: event.target.value }
                  : { ...value }
            );
            formik.setFieldValue(champ.value.description, newArray);
          }}
          onBlur={formik.handleBlur}
          disabled={champ.disabled ? champ.disabled : false}
          error={Boolean(
            formik.touched[champ?.nom.description] &&
              formik.errors[champ?.value.description] !== undefined &&
              Object.keys(formik.values[champ?.value.description]).includes(
                (champ?.positionInArray).toString()
              ) &&
              formik.errors[champ?.value.description]
          )}
          helperText={
            formik.touched[champ?.nom.description] &&
            formik.errors[champ?.value.description] !== undefined &&
            Object.keys(formik.values[champ?.value.description]).includes(
              (champ?.positionInArray).toString()
            ) &&
            formik.errors[champ?.value.description]
              ? formik.errors[champ?.value.description]
              : null
          }
          size="small"
        />
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampTextAndTextfieldAlign = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledDivTextAndTextfieldAlign>
          <div className={champ.cssFontFamily} id={champ.nom.description}>
            {champ.label}
          </div>
          <StyledTextfield
            className={champ.cssTextfield}
            name={champ.nom.description}
            variant={OUTLINED.description}
            value={formik.values[champ.nom.description]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched[champ.nom.description] &&
                formik.errors[champ.nom.description]
            )}
            helperText={
              formik.touched[champ.nom.description]
                ? formik.errors[champ.nom.description]
                : null
            }
            size="small"
          />
        </StyledDivTextAndTextfieldAlign>
      </Grid>
    </StyledDivFormSection>
  );
};

/**
 * Generate a text field multiline attribute for a given field
 * @param champ - The field to be rendered.
 * @param formik - The formik object that contains the form data and the methods to update it.
 * @returns A grid item with a text field.
 */
const genererChampStyledTextfieldZone = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledTextfield
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label}
          multiline
          rows={ROW.description}
          variant={OUTLINED.description}
          value={formik.values[champ.nom.description]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          helperText={
            formik.touched[champ.nom.description]
              ? formik.errors[champ.nom.description]
              : null
          }
        />
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampStyledTextfieldWithoutFormik = (champ, object, key) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledTextfield
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label ? champ.label : ""}
          variant={OUTLINED.description}
          value={champ.value !== undefined && champ.value !== null ? champ.value : ""}
          onChange={object.handleChange}
          onBlur={object.handleBlur}
          disabled={champ.disabled ? champ.disabled : false}
          InputProps={{
            className: champ.textInformation?.cssIcon,
            endAdornment: champ.textInformation?.text ? (
              <InputAdornment position="end">
                <HelperText
                  name={champ.nom.description}
                  textInformation={champ.textInformation}
                />
              </InputAdornment>
            ) : null,
            startAdornment: null,
          }}
          size="small"
        />
      </Grid>
    </StyledDivFormSection>
  );
};

export {
  genererChampStyledTextfield,
  genererChampStyledTextfieldReturnArray,
  genererChampTextAndTextfieldAlign,
  genererChampStyledTextfieldZone,
  genererChampStyledTextfieldReturnArrayMulti,
  genererChampStyledTextfieldWithoutFormik
};
