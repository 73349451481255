import { Paper } from "@mui/material";
import { equalsCodeOrLabel } from "../../../../constantes/symbols/HelperSymbolsServices";
import { SOUFFLET_AVEC_SPIRE } from "../../../../constantes/symbols/SymbolsS01";
import {
  S04_EXTREMITE_IDENTIQUE,
  S04_FIXATION_EXTREMITES_IT1,
  S04_FIXATION_EXTREMITES_IT2,
  S04_LONGUEUR_COMPRIMEE,
  S04_LONGUEUR_DEPLOYEE,
} from "../../../../constantes/symbols/SymbolsS04";
import { S04_STEP6_arrayFixationExtremites} from "../../../../constantes/symbols/SymbolsServicesS04";
import {
  checkFocusedArrow,
  checkFocusedEndMarker,
  checkFocusedStartMarker,
  checkFocusedText,
} from "../../../../helper/ConditionnalCssProvider";
import {
  StyledPath1,
  StyledPathAxeSymetrie,
  StyledPathAxeSymetrie2,
  StyledPathCoteDotted,
  StyledPathDotted,
  StyledPathFleche,
} from "../../styledComponents/StyledPath";
import { StyledRect2 } from "../../styledComponents/StyledRect";

export const FormeConstruction = ({ styles, formik, t }) => {
  const longueurComprimee = formik[S04_LONGUEUR_COMPRIMEE.description] || "";
  const longueurDeployee = formik[S04_LONGUEUR_DEPLOYEE.description] || "";
  const extremitesIdentiques =
    parseInt(formik[S04_EXTREMITE_IDENTIQUE.description]) || 0;
    const fixationIt1 =
    !isNaN(parseInt(formik[S04_FIXATION_EXTREMITES_IT1.description])) ?
    parseInt(formik[S04_FIXATION_EXTREMITES_IT1.description]) :
    S04_STEP6_arrayFixationExtremites(t).find(item => item.label === formik[S04_FIXATION_EXTREMITES_IT1.description])?.code || 0;
  
  const fixationIt2 =
    !isNaN(parseInt(formik[S04_FIXATION_EXTREMITES_IT2.description])) ?
    parseInt(formik[S04_FIXATION_EXTREMITES_IT2.description]) :
    S04_STEP6_arrayFixationExtremites(t).find(item => item.label === formik[S04_FIXATION_EXTREMITES_IT2.description])?.code || 0;
  

  const afficherFixationExtremiteIt1 = (fixation, t) => {
    switch (true) {
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 0):
        return (
          <>
            {afficherFlecheLargeur(65, 175)}
            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100 " />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 1):
        return (
          <>
            {afficherFlecheLargeur(56, 184)}
            <StyledPath1 d="M 191,140 L 191,80" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100 L 190,80 L 190,140" />
            <StyledPathFleche d="M 180,140 L 180,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 2):
        return (
          <>
            {afficherFlecheLargeur(56, 184)}
            <StyledPath1 d="M 190,80 L 198,80" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,85 L 198,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,90 L 198,90" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,95 L 198,95" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,100 L 198,100" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,105 L 198,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,110 L 198,110" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,115 L 198,115" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,120 L 198,120" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,125 L 198,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,130 L 198,130" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,135 L 198,135" className={styles.couleurChartePath}/>

            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100 L 190,80 L 190,140" />
            <StyledPathFleche d="M 180,140 L 180,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 3):
        return (
          <>
            {afficherFlecheLargeur(56, 184)}
            <StyledPath1 d="M 190,80 L 198,80" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,85 L 198,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,90 L 198,90" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,95 L 198,95" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,100 L 198,100" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,105 L 198,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,110 L 198,110" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,115 L 198,115" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,120 L 198,120" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,125 L 198,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,130 L 198,130" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 190,135 L 198,135" className={styles.couleurChartePath}/>

            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100 L 190,80 L 190,140" />
            <StyledPathFleche d="M 180,140 L 180,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 4):
        return (
          <>
            {afficherFlecheLargeur(56, 184)}
            <StyledPath1 d="M 191,85 L 191,95" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 191,105 L 191,115" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 191,125 L 191,135" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100 L 190,80 L 190,140" />
            <StyledPathFleche d="M 180,140 L 180,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 5):
        return (
          <>
            {afficherFlecheLargeur(56, 184)}
            <StyledRect2 x="191" y="70" width="4" height="69" className={styles.couleurCharteRect}/>
            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100 L 190,80 L 190,140" />
            <StyledPathFleche d="M 180,140 L 180,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 6):
        return (
          <>
            {afficherFlecheLargeur(56, 184)}
            <StyledRect2 x="191" y="70" width="30" height="69" className={styles.couleurCharteRect}/>
            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100 L 190,80 L 190,140" />
            <StyledPathFleche d="M 180,140 L 180,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 7):
        return (
          <>
            {afficherFlecheLargeur(66, 174)}
            {/*Membre gauche*/}
            <StyledPath1 d="M 176,85 L 180,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 183,85 L 186,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 188,85 L 199,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 176,125 L 180,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 183,125 L 186,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 188,125 L 199,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100" />
            <StyledPath1 d="M 180,80 L 180,140" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 8):
        return (
          <>
            {afficherFlecheLargeur(56, 184)}
            <StyledPath1 d="M 197,105 L 210,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 192,105 L 195,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 184,105 L 190,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 197,125 L 210,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 192,125 L 195,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 184,125 L 190,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100 L 190,80 L 190,140" />
            <StyledPathFleche d="M 180,140 L 180,100" />
          </>
        );
      default:
        return (
          <>
            <StyledPathFleche d="M 60,140 L 60,100" />

            <StyledPath1 d="M 150,80 L 160,100 L 170,80 L 180,100" />
            <StyledPathFleche d="M 180,140 L 180,100" />
          </>
        );
    }
  };

  const afficherFixationExtremiteIt2 = (fixation, t) => {
    switch (true) {
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 0):
        return (
          <>
            <StyledPath1 d="M 60,100 L 70,80 L 80,100 L 90,80" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 1):
        return (
          <>
            <StyledPath1 d="M 49,140 L 49,80" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 50,140 L 50,80 L 60,100 L 70,80 L 80,100 L 90,80" />
            <StyledPathFleche d="M 60,140 L 60,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 2):
        return (
          <>
            <StyledPath1 d="M 42,80 L 50,80" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,85 L 50,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,90 L 50,90" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,95 L 50,95" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,100 L 50,100" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,105 L 50,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,110 L 50,110" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,115 L 50,115" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,120 L 50,120" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,125 L 50,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,130 L 50,130" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,135 L 50,135" className={styles.couleurChartePath}/>

            <StyledPath1 d="M 50,140 L 50,80 L 60,100 L 70,80 L 80,100 L 90,80" />
            <StyledPathFleche d="M 60,140 L 60,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 3):
        return (
          <>
            <StyledPath1 d="M 42,80 L 50,80" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,85 L 50,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,90 L 50,90" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,95 L 50,95" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,100 L 50,100" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,105 L 50,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,110 L 50,110" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,115 L 50,115" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,120 L 50,120" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,125 L 50,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,130 L 50,130" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,135 L 50,135" className={styles.couleurChartePath}/>

            <StyledPath1 d="M 50,140 L 50,80 L 60,100 L 70,80 L 80,100 L 90,80" />
            <StyledPathFleche d="M 60,140 L 60,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 4):
        return (
          <>
            <StyledPath1 d="M 49,85 L 49,95" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 49,105 L 49,115" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 49,125 L 49,135" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 50,140 L 50,80 L 60,100 L 70,80 L 80,100 L 90,80" />
            <StyledPathFleche d="M 60,140 L 60,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 5):
        return (
          <>
            <StyledRect2 x="45" y="70" width="4" height="69" className={styles.couleurCharteRect}/>
            <StyledPath1 d="M 50,140 L 50,80 L 60,100 L 70,80 L 80,100 L 90,80" />
            <StyledPathFleche d="M 60,140 L 60,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 6):
        return (
          <>
            <StyledRect2 x="19" y="70" width="30" height="69" className={styles.couleurCharteRect}/>
            <StyledPath1 d="M 50,140 L 50,80 L 60,100 L 70,80 L 80,100 L 90,80" />
            <StyledPathFleche d="M 60,140 L 60,100" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 7):
        return (
          <>
            {/*Membre gauche*/}
            <StyledPath1 d="M 60,85 L 64,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 55,85 L 58,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,85 L 53,85" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 60,125 L 64,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 55,125 L 58,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 42,125 L 53,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 60,140 L 60,80 L 60,100 L 70,80 L 80,100 L 90,80" />
          </>
        );
      case equalsCodeOrLabel(fixation, S04_STEP6_arrayFixationExtremites(t), 8):
        return (
          <>
            <StyledPath1 d="M 50,105 L 58,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 45,105 L 48,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 32,105 L 43,105" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 50,125 L 58,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 45,125 L 48,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 32,125 L 43,125" className={styles.couleurChartePath}/>
            <StyledPath1 d="M 50,140 L 50,80 L 60,100 L 70,80 L 80,100 L 90,80" />
            <StyledPathFleche d="M 60,140 L 60,100" />
          </>
        );
      default:
        return (
          <>
            {afficherFlecheLargeur()}
            <StyledPath1 d="M 60,100 L 70,80 L 80,100 L 90,80" />
          </>
        );
    }
  };

  const afficherExtremitesIdentiques = (t) => {
    switch (extremitesIdentiques) {
      case 1:
        return (
          <>
            {afficherFixationExtremiteIt1(
              formik[S04_FIXATION_EXTREMITES_IT1.description],
              t
            )}
            {afficherFixationExtremiteIt2(
              formik[S04_FIXATION_EXTREMITES_IT1.description],
              t
            )}
          </>
        );
      case 0:
        return (
          <>
            {afficherFixationExtremiteIt1(
              formik[S04_FIXATION_EXTREMITES_IT1.description],
              t
            )}
            {afficherFixationExtremiteIt2(
              formik[S04_FIXATION_EXTREMITES_IT2.description],
              t
            )}
          </>
        );
      default:
        return (
          <>
            {afficherFlecheLargeur()}
            {/*Membre gauche*/}
            <StyledPathFleche d="M 50,140 L 50,80 L 60,100 L 70,80 L 80,100 L 90,80" />
            {/*Membre droit*/}
            <StyledPathFleche d="M 150,80 L 160,100 L 170,80 L 180,100 L 190,80 L 190,140" />
            <StyledPathFleche d="M 60,140 L 60,100" />
            <StyledPathFleche d="M 180,140 L 180,100" />
          </>
        );
    }
  };

  const afficherFlecheLargeur = (t) => {
    const setLongueurGaucheIt1 =
      equalsCodeOrLabel(fixationIt1, S04_STEP6_arrayFixationExtremites(t), 0) ||
      equalsCodeOrLabel(fixationIt1, S04_STEP6_arrayFixationExtremites(t), 7)
        ? 66
        : 56;
    const setLongueurGaucheIt2 =
      equalsCodeOrLabel(fixationIt2, S04_STEP6_arrayFixationExtremites(t), 0) ||
      equalsCodeOrLabel(fixationIt2, S04_STEP6_arrayFixationExtremites(t), 7)
        ? 66
        : 56;
    const setLongueurDroite =
      equalsCodeOrLabel(fixationIt1, S04_STEP6_arrayFixationExtremites(t), 0) ||
      equalsCodeOrLabel(fixationIt1, S04_STEP6_arrayFixationExtremites(t), 7)
        ? 174
        : 184;
    const longueurGauche = extremitesIdentiques
      ? setLongueurGaucheIt1
      : setLongueurGaucheIt2;
    const longueurDroite = extremitesIdentiques
      ? setLongueurDroite
      : setLongueurDroite;
    const setHauteur =
      equalsCodeOrLabel(fixationIt1, S04_STEP6_arrayFixationExtremites(t), 0) ||
      equalsCodeOrLabel(fixationIt2, S04_STEP6_arrayFixationExtremites(t), 0)
        ? 99
        : 80;
    return (
      <>
        {/* LG=56 / LDroit=184 */}
        {/*Fleche Largeur*/}
        <StyledPathCoteDotted
          d={
            "M " +
            (longueurGauche - 6) +
            ",62 L " +
            (longueurGauche - 6) +
            "," +
            setHauteur
          }
        />
        <StyledPathCoteDotted
          d={
            "M " +
            (longueurDroite + 6) +
            ",62 L " +
            (longueurDroite + 6) +
            "," +
            setHauteur
          }
        />
        <StyledPathFleche
          d={"M " + longueurGauche + ",62 L " + longueurDroite + ",62"}
          markerStart={checkFocusedStartMarker(formik, [
            S04_LONGUEUR_DEPLOYEE,
            S04_LONGUEUR_COMPRIMEE,
          ])}
          markerEnd={checkFocusedEndMarker(formik, [
            S04_LONGUEUR_DEPLOYEE,
            S04_LONGUEUR_COMPRIMEE,
          ])}
          className={checkFocusedArrow(
            formik,
            [S04_LONGUEUR_DEPLOYEE, S04_LONGUEUR_COMPRIMEE],
            styles
          )}
        />

        {longueurComprimee ? (
          <text
            fontSize="0.4em"
            x={"115"}
            y="60"
            direction="rtl"
            className={checkFocusedText(
              formik,
              [S04_LONGUEUR_DEPLOYEE, S04_LONGUEUR_COMPRIMEE],
              styles
            )}
          >
            LD {longueurDeployee}
          </text>
        ) : (
          <text
            fontSize="0.4em"
            x="130"
            y="60"
            direction="rtl"
            className={checkFocusedText(
              formik,
              [S04_LONGUEUR_DEPLOYEE, S04_LONGUEUR_COMPRIMEE],
              styles
            )}
          >
            LD {longueurDeployee}
          </text>
        )}
        {!longueurComprimee ? null : (
          <>
            <text
              fontSize="0.4em"
              x="120"
              y="60"
              textAnchor="middle"
              className={checkFocusedText(
                formik,
                [S04_LONGUEUR_DEPLOYEE, S04_LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              /
            </text>
            <text
              fontSize="0.4em"
              x="125"
              y="60"
              direction="ltr"
              className={checkFocusedText(
                formik,
                [S04_LONGUEUR_DEPLOYEE, S04_LONGUEUR_COMPRIMEE],
                styles
              )}
            >
              LC {longueurComprimee}
            </text>
          </>
        )}
      </>
    );
  };

  return (
    <Paper elevation={0}>
      <svg viewBox="-10 40 255 120">
        <defs>
          <marker
            id="startarrow"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 2, 10 5, 0 3.5" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 2, 10 3.5, 0 5" />
          </marker>
          <marker
            id="startarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="10 2, 10 5, 0 3.5"
              className={styles.markerSelected}
            />
          </marker>
          <marker
            id="endarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon
              points="0 2, 10 3.5, 0 5"
              className={styles.markerSelected}
            />
          </marker>
        </defs>

        <title></title>
        {afficherExtremitesIdentiques(t)}
        <>
          {/*Barre pointillée*/}
          <StyledPathDotted d="M 90,80 L 150,80 " className={styles.dotted} />

          {/*Axe de symétrie*/}
          <StyledPathAxeSymetrie2 d="M 15,140 L 225,140" />

          {/*Ligne avant pointillets 1*/}
          <StyledPathFleche d="M 70,140 L 70,82" />

          {/*Ligne avant pointillets 2*/}
          <StyledPathFleche d="M 80,140 L 80,100" />
          {/*Ligne avant pointillets 3*/}
          <StyledPathFleche d="M 90,140 L 90,80" />

          {/*Ligne apres pointillets*/}
          <StyledPathFleche d="M 150,140 L 150,80" />

          {/*Ligne exterieur gauche second cone*/}
          <StyledPathFleche d="M 160,140 L 160,100" />

          {/*Ligne interieur second cone*/}
          <StyledPathFleche d="M 170,140 L 170,80" />
        </>
      </svg>
    </Paper>
  );
};
