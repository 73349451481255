export const ContactIcon = ({ styles, activePage }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 682.667 682.667">
      <g>
        <defs>
          <clipPath id="a" clipPathUnits="userSpaceOnUse">
            <path
              d="M0 512h512V0H0Z"
              fill={activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c"}
              opacity="1"
            ></path>
          </clipPath>
        </defs>
        <path
          d="M0 0v64"
          style={{
            fill: "none",
            stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
            strokeWidth: "20",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeDasharray: "none",
            strokeOpacity: "",
          }}
          transform="matrix(1.33333 0 0 -1.33333 66.667 528)"
        ></path>
        <g
          transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        >
          <path
            d="M0 0v22c0 22.091 17.909 40 40 40h312c22.091 0 40-17.909 40-40v-412c0-22.092-17.909-40-40-40H40c-22.091 0-40 17.908-40 40v21.999"
            transform="translate(50 440)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h-44c-12.15 0-22 9.85-22 22v0c0 12.15 9.85 22 22 22H0c12.15 0 22-9.85 22-22v0C22 9.85 12.15 0 0 0Z"
            transform="translate(76 396)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h-44c-12.15 0-22 9.85-22 22v0c0 12.15 9.85 22 22 22H0c12.15 0 22-9.85 22-22v0C22 9.85 12.15 0 0 0Z"
            transform="translate(76 288)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h-44c-12.15 0-22 9.85-22 22v0c0 12.15 9.85 22 22 22H0c12.15 0 22-9.85 22-22v0C22 9.85 12.15 0 0 0Z"
            transform="translate(76 180)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h-44c-12.15 0-22 9.85-22 22v0c0 12.15 9.85 22 22 22H0c12.15 0 22-9.85 22-22v0C22 9.85 12.15 0 0 0Z"
            transform="translate(76 72)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h88c11.046 0 20-8.954 20-20v-58.4c0-11.046-8.954-20-20-20H48"
            transform="translate(394 502)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h40c11.046 0 20-8.954 20-20v-58.4c0-11.046-8.954-20-20-20H0"
            transform="translate(442 403.6)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h40c11.046 0 20-8.954 20-20v-58.4c0-11.046-8.954-20-20-20H0"
            transform="translate(442 305.2)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h40c11.046 0 20-8.954 20-20v-58.4c0-11.046-8.954-20-20-20H0"
            transform="translate(442 206.8)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h40c11.046 0 20-8.954 20-20v-58.4c0-11.046-8.954-20-20-20h-88"
            transform="translate(442 108.4)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0h38.873v16c0 39.764-32.235 72-72 72h-24c-39.764 0-72-32.236-72-72V0H-90"
            transform="translate(301.127 168)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0v0c-22.091 0-40-17.909-40-40v-8c0-22.091 17.909-40 40-40v0c22.091 0 40 17.909 40 40v8C40-17.909 22.091 0 0 0Z"
            transform="translate(256 344)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0v0"
            transform="translate(256.127 168)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0v-64"
            transform="translate(50 288)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0v64"
            transform="translate(50 332)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
          <path
            d="M0 0v-23.167"
            transform="translate(256 253)"
            style={{
              fill: "none",
              stroke: activePage === "/EspaceClient/VueContact/" ? "#EC8812" : "#2a375c",
              strokeWidth: "20",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeDasharray: "none",
              strokeOpacity: "",
            }}
          ></path>
        </g>
      </g>
    </svg>
  );
};
