import { Paper } from "@mui/material";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../constantes/symbols/HelperSymbolsServices";
import {
  FORME_CORPS_A,
  FORME_CORPS_B,
  FORME_CORPS_C,
  FORME_CORPS_D,
  FORME_CORPS_DS,
  FORME_CORPS_E,
  FORME_CORPS_ES,
  FORME_CORPS_F,
  FORME_CORPS_FS,
  LONGUEUR_A,
  LONGUEUR_B,
  LONGUEUR_C,
  LONGUEUR_X,
  S04_ECAILLES,
  SECTION_SYMETRIQUE,
  TYPE_SECTION,
} from "../../../../constantes/symbols/SymbolsS04";
import { S04_STEP4_arrayTypeCorps } from "../../../../constantes/symbols/SymbolsServicesS04";
import {
  checkFocusedArrow,
  checkFocusedEndMarker,
  checkFocusedStartMarker,
  checkFocusedText,
} from "../../../../helper/ConditionnalCssProvider";

import {
  StyledPath1,
  StyledPath2,
  StyledPathCote,
  StyledPathCoteDotted,
  StyledPathDotted,
  StyledPathEcaille,
  StyledPathFleche,
} from "../../styledComponents/StyledPath";
import { StyledPolygon } from "../../styledComponents/StyledPolygon";
import { StyledRect2 } from "../../styledComponents/StyledRect";
import {
  DIMENSIONS_PLIS,
  NOMBRE_FACE,
  S06_LONGUEUR_A_EXT,
  S06_LONGUEUR_A_INT,
  S06_LONGUEUR_B_EXT,
  S06_LONGUEUR_B_INT,
  S06_LONGUEUR_C_EXT,
  S06_LONGUEUR_C_INT,
} from "../../../../constantes/symbols/SymbolsS06";
import { S06_STEP5_arrayDimensionsPlis, S06_STEP5_arrayNombreFaces } from "../../../../constantes/symbols/SymbolsServicesS06";

export const FormeConstructionS06Face = ({ styles, formik, t }) => {
  const code = formik[DIMENSIONS_PLIS.description];
  const dimensionsPlis = parseInt(S06_STEP5_arrayDimensionsPlis().find(item => item.label === code)?.code) || parseInt(code);

  const longueurAInt = parseInt(formik[S06_LONGUEUR_A_INT.description]) || 0;
  const longueurBInt = parseInt(formik[S06_LONGUEUR_B_INT.description]) || 0;
  const longueurCInt = parseInt(formik[S06_LONGUEUR_C_INT.description]) || 0;

  const longueurAExt =
    formik[S06_LONGUEUR_A_EXT.description] ||
    (equalsCodeOrLabel(
      formik[NOMBRE_FACE.description],
      S06_STEP5_arrayNombreFaces(),
      1
    )
      ? longueurAInt + 2 * (dimensionsPlis || 0)
      : longueurAInt + 1 * (dimensionsPlis || 0));
  const longueurBExt =
    formik[S06_LONGUEUR_B_EXT.description] ||
    longueurBInt + 2 * (dimensionsPlis || 0);
  const longueurCExt =
    formik[S06_LONGUEUR_C_EXT.description] ||
    longueurCInt + 1 * (dimensionsPlis || 0);

  const afficherForme3Faces = () => {
    return (
      <>
        <StyledRect2 x="45" y="70" width="15" height="70" />
        <StyledRect2 x="180" y="70" width="15" height="40" />
        <StyledRect2 x="45" y="70" width="135" height="15" />

        {/* Longueurs Intérieurs */}
        <StyledPathFleche
          d="M 67,88 L 173,88"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_B_INT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_B_INT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_B_INT], styles)}
        />
        {longueurBInt === "" ? (
          <text
            fontSize="0.4em"
            x="120"
            y="95"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_B_INT], styles)}
          >
            B int
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="120"
              y="95"
              direction="ltr"
              className={checkFocusedText(formik, [S06_LONGUEUR_B_INT], styles)}
            >
              {longueurBInt}
            </text>
          </>
        )}

        <StyledPathCoteDotted d={"M 62,140.5 L 73,140.5"} />
        <StyledPathFleche
          d="M 72,91 L 72,134"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_A_INT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_A_INT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_A_INT], styles)}
        />
        {longueurAInt === "" ? (
          <text
            fontSize="0.4em"
            x="82"
            y="110"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_A_INT], styles)}
          >
            A int
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="82"
              y="110"
              direction="rtl"
              className={checkFocusedText(formik, [S06_LONGUEUR_A_INT], styles)}
            >
              {longueurAInt}
            </text>
          </>
        )}

        <StyledPathCoteDotted d={"M 165,110 L 179,110"} />
        <StyledPathFleche
          d="M 165,91 L 165,104"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_C_INT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_C_INT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_C_INT], styles)}
        />
        {longueurCInt === "" ? (
          <text
            fontSize="0.4em"
            x="156"
            y="100"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_C_INT], styles)}
          >
            C int
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="162"
              y="100"
              direction="rtl"
              className={checkFocusedText(formik, [S06_LONGUEUR_C_INT], styles)}
            >
              {longueurCInt}
            </text>
          </>
        )}

        {/* Fin longueurs intérieurs */}
        <StyledPathFleche
          d="M 50,62 L 190,62"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_B_EXT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_B_EXT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_B_EXT], styles)}
        />
        {longueurBExt === "" ? (
          <text
            fontSize="0.4em"
            x="120"
            y="60"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_B_EXT], styles)}
          >
            B ext
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="120"
              y="60"
              direction="ltr"
              className={checkFocusedText(formik, [S06_LONGUEUR_B_EXT], styles)}
            >
              {longueurBExt}
            </text>
          </>
        )}

        <StyledPathFleche
          d="M 38,76 L 38,133"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_A_EXT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_A_EXT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_A_EXT], styles)}
        />
        {longueurAExt === "" ? (
          <text
            fontSize="0.4em"
            x="32"
            y="105"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_A_EXT], styles)}
          >
            A ext
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="33"
              y="105"
              direction="rtl"
              className={checkFocusedText(formik, [S06_LONGUEUR_A_EXT], styles)}
            >
              {longueurAExt}
            </text>
          </>
        )}

        <StyledPathFleche
          d="M 202,76 L 202,104"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_C_EXT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_C_EXT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_C_EXT], styles)}
        />
        {longueurCExt === "" ? (
          <text
            fontSize="0.4em"
            x="207"
            y="92"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_C_EXT], styles)}
          >
            C ext
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="207"
              y="92"
              direction="ltr"
              className={checkFocusedText(formik, [S06_LONGUEUR_C_EXT], styles)}
            >
              {longueurCExt}
            </text>
          </>
        )}
      </>
    );
  };

  const afficherForme4Faces = () => {
    return (
      <>
        <StyledRect2 x="45" y="70" width="15" height="70" />
        <StyledRect2 x="180" y="70" width="15" height="70" />
        <StyledRect2 x="45" y="70" width="135" height="15" />
        <StyledRect2 x="45" y="125" width="135" height="15" />

        {/* Longueurs Intérieurs */}
        <StyledPathFleche
          d="M 67,120 L 173,120"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_B_INT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_B_INT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_B_INT], styles)}
        />
        {longueurBInt === "" ? (
          <text
            fontSize="0.4em"
            x="120"
            y="116"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_B_INT], styles)}
          >
            B int
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="116"
              y="116"
              direction="ltr"
              className={checkFocusedText(formik, [S06_LONGUEUR_B_INT], styles)}
            >
              {longueurBInt}
            </text>
          </>
        )}

        <StyledPathFleche
          d="M 165,91 L 165,119"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_A_INT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_A_INT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_A_INT], styles)}
        />
        {longueurAInt === "" ? (
          <text
            fontSize="0.4em"
            x="157"
            y="105"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_A_INT], styles)}
          >
            A int
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="161"
              y="105"
              direction="rtl"
              className={checkFocusedText(formik, [S06_LONGUEUR_A_INT], styles)}
            >
              {longueurAInt}
            </text>
          </>
        )}

        {/* Fin longueurs intérieurs */}
        <StyledPathFleche
          d="M 50,145 L 190,145"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_B_EXT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_B_EXT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_B_EXT], styles)}
        />
        {longueurBExt === "" ? (
          <text
            fontSize="0.4em"
            x="120"
            y="152"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_B_EXT], styles)}
          >
            B ext
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="116"
              y="152"
              direction="ltr"
              className={checkFocusedText(formik, [S06_LONGUEUR_B_EXT], styles)}
            >
              {longueurBExt}
            </text>
          </>
        )}

        <StyledPathFleche
          d="M 38,76 L 38,133"
          markerStart={checkFocusedStartMarker(formik, [S06_LONGUEUR_A_EXT])}
          markerEnd={checkFocusedEndMarker(formik, [S06_LONGUEUR_A_EXT])}
          className={checkFocusedArrow(formik, [S06_LONGUEUR_A_EXT], styles)}
        />
        {longueurAExt === "" || parseInt(longueurAExt) === 0 ? (
          <text
            fontSize="0.4em"
            x="29"
            y="105"
            textAnchor="middle"
            className={checkFocusedText(formik, [S06_LONGUEUR_A_EXT], styles)}
          >
            A ext
          </text>
        ) : (
          <>
            <text
              fontSize="0.4em"
              x="33"
              y="105"
              direction="rtl"
              className={checkFocusedText(formik, [S06_LONGUEUR_A_EXT], styles)}
            >
              {longueurAExt}
            </text>
          </>
        )}
      </>
    );
  };

  const afficherFormeFace = () => {
    switch (true) {
      case equalsCodeOrLabel(
        formik[NOMBRE_FACE.description],
        S06_STEP5_arrayNombreFaces(),
        0
      ):
        return <>{afficherForme3Faces()}</>;
      case equalsCodeOrLabel(
        formik[NOMBRE_FACE.description],
        S06_STEP5_arrayNombreFaces(),
        1
      ):
        return <>{afficherForme4Faces()}</>;
      default:
        break;
    }
  };

  return (
    <Paper elevation={0}>
      <svg viewBox="-10 40 255 130">
        <defs>
          <marker
            id="startarrow"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 2, 10 5, 0 3.5" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 2, 10 3.5, 0 5" />
          </marker>
          <marker
            id="startarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="10"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="10 2, 10 5, 0 3.5"
              className={styles.markerSelected}
            />
          </marker>
          <marker
            id="endarrowSelectedRC"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon
              points="0 2, 10 3.5, 0 5"
              className={styles.markerSelected}
            />
          </marker>
        </defs>

        <title></title>

        {afficherFormeFace()}
        {/* <image href={importAll()["F1S_SVG.png"]} height="90%" width="100%" x="-15" y="45"/> */}
      </svg>
    </Paper>
  );
};

const findFormeCorpsFromTypeSection = (payload) => {
  const symetrique = equalsBooleanOrStringOrNumber(
    payload[SECTION_SYMETRIQUE.description]
  );
  switch (true) {
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      0
    ):
      return payload[FORME_CORPS_A.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      1
    ):
      return payload[FORME_CORPS_B.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      2
    ):
      return payload[FORME_CORPS_C.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      3
    ):
      return !!symetrique
        ? payload[FORME_CORPS_DS.description]
        : payload[FORME_CORPS_D.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      4
    ):
      return !!symetrique
        ? payload[FORME_CORPS_ES.description]
        : payload[FORME_CORPS_E.description];
    case equalsCodeOrLabel(
      payload[TYPE_SECTION.description],
      S04_STEP4_arrayTypeCorps(),
      5
    ):
      return !!symetrique
        ? payload[FORME_CORPS_FS.description]
        : payload[FORME_CORPS_F.description];
    default:
      return payload[FORME_CORPS_A.description];
  }
};
