import { styled } from "@mui/system";

export const StyledCardContainer = styled("div")({
  flex: 1,
  display: "flex",
  gap: "1.5rem",
  flexWrap: "wrap",

  "@media (max-width: 768px)": {
    "& #whoIam-card": { order: 1 },
    "& #publicite-card": { order: 2 },
    "& #commande-card": { order: 3 },
    "& #offre-card": { order: 4 },
    "& #commercial-card": { order: 5 },
    "& #article-card": { order: 6 },
    "& #nc-card": { order: 7 },
    "& #contact-card": { order: 8 },
    "& #service-card": { order: 9 },
    "& #configurateur-card": { order: 10 },
  },
});
