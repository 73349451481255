import React from "react";

export const ChevronDown = ({ styles }) => {
  const cssColor = styles.cssFontFamily;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6z"
      ></path>
    </svg>
  );
};

export const ChevronUp = ({ styles }) => {
  const cssColor = styles.cssFontFamily;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6l-6 6z"
      ></path>
    </svg>
  );
};
